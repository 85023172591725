import { Button } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { Typography } from '@components/text/text'

type ComponentProps = {
	onConfirm: () => Promise<void>
	onClose: () => void
	zIndex?: number
	escapable?: boolean
	header: string
	confirmLabel: string
}

export function GenericConfirmationModal(props: ComponentProps) {
	function hideModal(): void {
		props.onClose()
	}

	return (
		<Modal
			maxWidth={500}
			maxHeight={400}
			fixedHeight={false}
			onClose={hideModal}
			zIndex={props.zIndex}
			escapable={props.escapable}
		>
			<ModalBody>
				<Typography type="h4" variant={['center']}>
					{props.header}
				</Typography>
			</ModalBody>
			<ModalFooter center={true} gap={true}>
				<Button variant="outlined" size="lg" primary={false} label="Cancel" onClick={hideModal} />
				<Button variant="contained" size="lg" label={props.confirmLabel} onClick={props.onConfirm} />
			</ModalFooter>
		</Modal>
	)
}
