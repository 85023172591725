import { Box } from '@components/box/box'
import { Button } from '@components/button/button'
import { Caret } from '@components/caret/caret'
import { Typography } from '@components/text/text'
import { useState } from 'react'

import { CollapsibleContentService } from './collapsible-content.service'
import { CollapsibleContentTypes } from './collapsible-content.types'

export function CollapsibleContent__Controlled(props: CollapsibleContentTypes.ControlledComponent) {
	const [state, setState] = useState<CollapsibleContentTypes.State>(getInitialState())

	function getInitialState(): CollapsibleContentTypes.State {
		try {
			const collapsibleState = localStorage.getItem(`collapsible__${props.id}`)
			if (typeof collapsibleState === 'string') {
				if (collapsibleState === 'OPEN') {
					return 'OPEN'
				}
				return 'CLOSED'
			}
		} catch (err) {
			return 'OPEN'
		}
		return 'OPEN'
	}

	function setStateInStorage(newState: CollapsibleContentTypes.State): void {
		try {
			localStorage.setItem(`collapsible__${props.id}`, newState)
		} catch (err) {
			return
		}
		return
	}

	return (
		<div className={CollapsibleContentService.getWrapperClass({ state })}>
			<div
				className={CollapsibleContentService.getHeaderClass({ headerPadding: props.headerPadding })}
				onClick={() => {
					if (state === 'CLOSED') {
						setState('OPEN')
						setStateInStorage('OPEN')
					} else {
						setState('CLOSED')
						setStateInStorage('CLOSED')
					}
				}}
			>
				<Typography type={props.labelSize ? props.labelSize : 'strong'} color="secondary">
					{props.label}
				</Typography>
				<Box flex="row" alignItems="center" gap={true} className="flex-noShrink">
					{props.buttons?.map((button) => {
						return <Button {...button} />
					})}
					<Caret state={state === 'OPEN' ? 'opened' : 'closed'} />
				</Box>
			</div>
			{state === 'OPEN' && <div className="px-10 pt-5 pb-10">{props.children}</div>}
		</div>
	)
}
