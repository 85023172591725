import { Checkbox } from '@components/checkbox/checkbox'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownTypes } from '@components/dropdown/dropdown.types'
import { IconButton } from '@components/icon-button/icon-button'
import { Popover } from '@components/popover/popover'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'

import { CustomFormsService } from '../../../../../services/custom-forms/custom-forms.service'
import { CustomForms } from '../../../../../services/custom-forms/custom-forms.types'
import { UsePopoverOnEvent } from '../../../../../services/hooks/use-popover-on-event'
import { Utilities } from '../../../../../services/utilities.service'

interface SortableFieldCardProps {
	field: CustomForms.Field
	onDelete: () => void
	onUpdate: (key: keyof CustomForms.StandardField | keyof CustomForms.SelectField, value: unknown) => void
}

export function SortableFieldCard(props: SortableFieldCardProps) {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const fieldPropsBtnRef = useRef<HTMLDivElement>(null)
	const popoverProps = UsePopoverOnEvent({
		isPopoverOpen: isDropdownOpen,
		setIsPopoverOpen: setIsDropdownOpen,
		delay: 0,
	})

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.field.formFieldId })

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	}

	function getFieldTypeOptions(): DropdownTypes.Option<CustomForms.FieldTypes>[] {
		const options: DropdownTypes.Option<CustomForms.FieldTypes>[] = []

		options.push({
			label: 'String',
			value: 'STRING',
		})

		options.push({
			label: 'Number',
			value: 'NUMBER',
		})

		options.push({
			label: 'Phone number',
			value: 'PHONE',
		})

		options.push({
			label: 'Checkbox',
			value: 'CHECKBOX',
		})

		options.push({
			label: 'Email address',
			value: 'EMAIL',
		})

		options.push({
			label: 'Radio Selector',
			value: 'SINGLE_SELECT_RADIO',
		})

		options.push({
			label: 'Dropdown Selector',
			value: 'SINGLE_SELECT_DROPDOWN',
		})

		return options
	}

	function formatOptionsForString(options: CustomForms.FieldOption[] | undefined): string {
		let string = ''
		if (Array.isArray(options)) {
			options.forEach((option, index) => {
				const isLast = index === options.length - 1
				string += `${option.label}${!isLast ? ',' : ''}`
			})
		}
		return string
	}

	function formatStringForOptions(optionsString: string): CustomForms.FieldOption[] {
		const options: CustomForms.FieldOption[] = []

		optionsString.split(',').forEach((string) => {
			options.push({
				label: string,
				value: string,
			})
		})

		return options
	}

	const fieldLabel = props.field.fieldLabel ? (
		<strong>{Utilities.truncateString(props.field.fieldLabel, 100)}</strong>
	) : (
		<em className="opacity-50">Untitled</em>
	)

	return (
		<>
			<div className="featured-hub__card" ref={setNodeRef} style={style}>
				<div className="flex flex-alignItems-center">
					<div>
						{fieldLabel} ({props.field.fieldKey})
					</div>
					<div className="ml-20 opacity-50">
						{CustomFormsService().getLabelForFieldType(props.field.fieldType)}
					</div>
				</div>
				<div className="flex flex-alignItems-center">
					<IconButton
						icon={'trash'}
						className="mr-20"
						onClick={() => {
							props.onDelete()
						}}
					/>
					<div ref={fieldPropsBtnRef}>
						<IconButton
							icon={'ellipsis'}
							className="mr-20"
							onClick={() => {
								setIsDropdownOpen(true)
							}}
						/>
					</div>
					<div {...attributes} {...listeners} style={{ cursor: 'grab' }}>
						<FontAwesomeIcon icon={['fas', 'grip-lines']} />
					</div>
				</div>
			</div>
			{fieldPropsBtnRef.current && (
				<Popover
					hideOnMouseOut={false}
					hideOnClickOutside={true}
					isScrimVisible={true}
					{...popoverProps}
					refElement={fieldPropsBtnRef.current}
					setShowPopover={(newState) => {
						setIsDropdownOpen(newState)
					}}
					style={{ maxHeight: '600px' }}
					options={{}}
				>
					<div className="standard__popover">
						<Typography type="h3" margins={['bottom']}>
							Field Options
						</Typography>
						<TextInput
							dataType="text"
							type="text"
							margins={['bottom']}
							value={props.field.fieldLabel}
							label="Label"
							width={'100%'}
							onChange={(value) => {
								props.onUpdate('fieldLabel', value)
							}}
						/>
						<TextInput
							dataType="text"
							type="text"
							margins={['bottom']}
							value={props.field.fieldKey}
							label="Key"
							width={'100%'}
							onChange={(value) => {
								props.onUpdate('fieldKey', value)
							}}
						/>
						<Dropdown<CustomForms.FieldTypes>
							label="Type"
							width={`100%`}
							margins={['bottom']}
							value={[props.field.fieldType]}
							options={getFieldTypeOptions()}
							onSelect={(selectedOptions) => {
								if (selectedOptions.length > 0) {
									props.onUpdate('fieldType', selectedOptions[0])
								}
							}}
						/>
						{(props.field.fieldType === 'SINGLE_SELECT_DROPDOWN' ||
							props.field.fieldType === 'SINGLE_SELECT_RADIO') && (
							<TextInput
								dataType="text"
								type="text"
								margins={['bottom']}
								value={formatOptionsForString(props.field.options)}
								label="Options (Comma seperated)"
								width={'100%'}
								onChange={(value) => {
									if (
										props.field.fieldType === 'SINGLE_SELECT_DROPDOWN' ||
										props.field.fieldType === 'SINGLE_SELECT_RADIO'
									) {
										props.onUpdate('options', formatStringForOptions(value))
									}
								}}
							/>
						)}

						<Checkbox
							checked={props.field.required}
							onChange={(state) => {
								props.onUpdate('required', state)
							}}
						>
							Is Required
						</Checkbox>
					</div>
				</Popover>
			)}
		</>
	)
}
