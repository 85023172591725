import { Button } from '@components/button/button'

import { TransactionMgmt__NotificationCard } from '../../shared/notification-card/notification-card'

type ComponentProps = {
	notificationIds: number[]
}

export function TransactionAdmin__ChecklistEditor__ChildNotifications(props: ComponentProps) {
	return (
		<>
			{props.notificationIds.map((notificationId) => {
				return <TransactionMgmt__NotificationCard notificationId={notificationId} />
			})}
			<Button
				variant="outlined"
				size="md"
				label="Link Notification"
				onClick={() => {
					alert(`Link Notification`)
				}}
			/>
		</>
	)
}
