import { Box } from '@components/box/box'
import { Button } from '@components/button/button'
import { Typography } from '@components/text/text'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { EmailNotificationAPI } from '../../services/email-notification/email-notification.api'
import { EndUserProps } from '../../services/user/user.types'
import { PaperlessPipelineSSOCards } from '../paperless-pipeline/paperless-pipeline-cards'

interface ManageTransactionsProps {
	currentUser: EndUserProps | null
}

interface State {
	paperlessPipline: boolean
	newTransaction: boolean
	transactionType: 'listingSideSale' | 'buySideSale' | 'rental' | 'other' | null
	closingTeam: 'Yes' | 'No' | 'NotApplicable' | null
	isItFinanced: 'Yes' | 'No' | null
	transactionCoordinator: boolean
	makeEscrowDeposit: boolean
	skip: boolean
	isSubmitted: boolean
	emailSubmittedMessage: boolean
}

const initialState: State = {
	paperlessPipline: false,
	newTransaction: false,
	transactionType: null,
	closingTeam: 'NotApplicable',
	isItFinanced: null,
	transactionCoordinator: false,
	makeEscrowDeposit: false,
	skip: false,
	isSubmitted: false,
	emailSubmittedMessage: false,
}

function ManageTransactionsPrototype({ currentUser }: ManageTransactionsProps) {
	const [state, setState] = useState<State>(initialState)
	const [floridaLicense, setFloridaLicense] = useState(false)

	const handleTransactionType = (type: State['transactionType']) => {
		setState((prevState) => ({
			...prevState,
			transactionType: type,
			paperlessPipline: false,
		}))
	}

	useEffect(() => {
		if (state.closingTeam != null && state.isItFinanced != null) {
			EmailNotificationAPI.manageTransactionsNotification(state.closingTeam, state.isItFinanced)
				.then((res) => {
					console.log('email sent')
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}, [state.closingTeam, state.isItFinanced])

	useEffect(() => {
		const isFloridaLicense =
			currentUser?.licenseInformation.some((license) => license.licensedState.abbreviation === 'FL') || false
		setFloridaLicense(isFloridaLicense)
	}, [currentUser])

	return (
		<div className="flex flex-column flex-alignItems-center overflow-y__scroll" style={{ width: '100%' }}>
			{!state.isSubmitted && (
				<>
					<Typography type="h4" margins={['bottom']}>
						Let's get started! What would you like to achieve today?
					</Typography>
					<Box
						flex="row"
						justifyContent="center"
						alignItems="center"
						gap={true}
						margins={['bottom']}
						marginSize="section"
					>
						<Button
							variant="outlined"
							size="lg"
							primary={true}
							label={`Add New Transaction`}
							onClick={() => {
								setState((prevState) => ({
									...prevState,
									newTransaction: true,
									paperlessPipline: false,
								}))
							}}
							disabled={state.newTransaction}
						/>

						<Button
							variant="outlined"
							size="lg"
							primary={true}
							label={`Manage Existing Transaction`}
							onClick={() => {
								setState((prevState) => ({
									...prevState,
									newTransaction: false,
									paperlessPipline: true,
								}))
							}}
							disabled={state.paperlessPipline}
						/>
					</Box>
				</>
			)}

			{state.newTransaction && (
				<>
					<Typography type="h4" variant={['center']} margins={['top', 'bottom']}>
						What type of Transaction are you submitting?
					</Typography>

					<Box
						flex="row"
						justifyContent="center"
						alignItems="center"
						gap={true}
						marginSize="section"
						margins={['bottom']}
					>
						<Button
							variant="outlined"
							size="lg"
							primary={true}
							label={`Listing Side Sale`}
							onClick={() => handleTransactionType('listingSideSale')}
							disabled={state.transactionType === 'listingSideSale'}
						/>
						<Button
							variant="outlined"
							size="lg"
							primary={true}
							label={`Buy Side Sale`}
							onClick={() => handleTransactionType('buySideSale')}
							disabled={state.transactionType === 'buySideSale'}
						/>
						<Button
							variant="outlined"
							size="lg"
							primary={true}
							label={`Rental`}
							onClick={() => handleTransactionType('rental')}
							disabled={state.transactionType === 'rental'}
						/>
						<Button
							variant="outlined"
							size="lg"
							primary={true}
							label={`Other`}
							onClick={() => {
								setState((prevState) => ({
									...prevState,
									paperlessPipline: true,
									transactionType: 'other',
								}))
							}}
							disabled={state.transactionType === 'other'}
						/>
					</Box>
					{/* render parts depending on the transaction type */}
					{state.transactionType === 'listingSideSale' && (
						<>
							<Typography type="normal" margins={['bottom']} variant={['center']}>
								<strong>Empower Your Seller</strong> - Our Closing Team can advocate for your Seller,
								even if they lack the authority to select the Closing Agent in the Contract. Curious
								about a quote for our in-house team to stand up for your Seller?
							</Typography>

							<Box
								flex="row"
								justifyContent="center"
								alignItems="center"
								gap={true}
								marginSize="section"
								margins={['bottom']}
							>
								<Button
									variant="outlined"
									size="lg"
									primary={true}
									label={`Yes`}
									onClick={() => {
										EmailNotificationAPI.freeTransactionCoordinator()
											.then((res) => {
												setState((prevState) => ({
													...prevState,
													isSubmitted: true,
													newTransaction: false,
													paperlessPipline: false,
												}))
											})
											.catch((error) => {
												console.log(error)
											})
									}}
									// disabled={newTransactionState}
								/>

								<Button
									variant="outlined"
									size="lg"
									primary={true}
									label={`Skip This Step`}
									onClick={() => {
										setState((prevState) => ({
											...prevState,
											paperlessPipline: true,
										}))
									}}
									disabled={state.paperlessPipline}
								/>
							</Box>
						</>
					)}
					{state.transactionType === 'buySideSale' && (
						<>
							{floridaLicense && (
								<>
									<Typography type="h4" variant={['center']} margins={['bottom', 'top']}>
										Are you using The Closing Team / in-house Title Team?
									</Typography>
									<Box
										flex="row"
										justifyContent="center"
										alignItems="center"
										gap={true}
										marginSize="section"
										margins={['bottom']}
									>
										<Button
											variant="outlined"
											size="lg"
											primary={true}
											label={`Yes`}
											onClick={() => {
												setState((prevState) => ({
													...prevState,
													emailSubmittedMessage: true,
													closingTeam: 'Yes',
												}))
											}}
											disabled={state.closingTeam === 'Yes'}
										/>
										<Button
											variant="outlined"
											size="lg"
											primary={true}
											label={`No`}
											onClick={() => {
												setState((prevState) => ({
													...prevState,
													closingTeam: 'No',
												}))
											}}
											disabled={state.closingTeam === 'No'}
										/>
									</Box>
								</>
							)}
							{(state.closingTeam !== 'NotApplicable' ||
								(state.closingTeam === 'NotApplicable' && !floridaLicense)) && (
								<>
									<Typography type="h4" variant={['center']} margins={['top', 'bottom']}>
										Is this transaction being financed?
									</Typography>
									<Box
										flex="row"
										justifyContent="center"
										alignItems="center"
										gap={true}
										marginSize="section"
										margins={['bottom']}
									>
										<Button
											variant="outlined"
											size="lg"
											primary={true}
											label={`Yes`}
											onClick={() => {
												setState((prevState) => ({
													...prevState,
													isSubmitted: true,
													emailSubmittedMessage: true,
													newTransaction: false,
													isItFinanced: 'Yes',
													paperlessPipline: true,
												}))
											}}
											disabled={state.isItFinanced === 'Yes'}
										/>
										<Button
											variant="outlined"
											size="lg"
											primary={true}
											label={`No`}
											onClick={() => {
												setState((prevState) => ({
													...prevState,
													paperlessPipline: true,
													isItFinanced: 'No',
												}))
											}}
											disabled={state.isItFinanced === 'No'}
										/>
									</Box>
								</>
							)}
						</>
					)}
					{state.transactionType === 'rental' && (
						<>
							<Typography type="normal" variant={['center']} margins={['top', 'bottom']}>
								<strong>
									Discover the Convenience of The Closing Team's Online Escrow Payment Solution
								</strong>{' '}
								– Enable your customers to swiftly submit their deposit, at just a third of the cost of
								a bank wire transfer. Click below to access the Escrow portal and initiate a request to
								your customer for their Escrow Deposit transfer.
							</Typography>
							<Box
								flex="row"
								justifyContent="center"
								alignItems="center"
								gap={true}
								marginSize="section"
								margins={['bottom']}
							>
								<Button
									variant="outlined"
									size="lg"
									primary={true}
									label={`Make Escrow Deposit`}
									onClick={() => {
										setState((prevState) => ({
											...prevState,
											paperlessPipline: false,
										}))
										window.open('https://www.depositlink.com/pay/the-closing-team', '_blank')
									}}
									// disabled={makeEscrowDepositState}
								/>
								<Button
									variant="outlined"
									size="lg"
									primary={true}
									label={`Skip This Step`}
									onClick={() => {
										setState((prevState) => ({
											...prevState,
											paperlessPipline: true,
											skip: true,
										}))
									}}
									disabled={state.paperlessPipline}
								/>
							</Box>
						</>
					)}
				</>
			)}

			{(state.isSubmitted || state.paperlessPipline) && (
				<Box flex="row" justifyContent="center" wrap={true}>
					<Typography
						type="normal"
						variant={['center']}
						className="col-xs-12"
						clampWidth={false}
						margins={['bottom']}
					>
						Access your Paperless Pipeline account quickly and securely through the Single Sign-On (SSO)
						feature. Choose your licensed state below to sign on:
					</Typography>
					<PaperlessPipelineSSOCards />
				</Box>
			)}
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const ManageTransactionsRoute = connect(mapStateToProps)(ManageTransactionsPrototype)
