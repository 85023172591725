import { Button } from '@components/button/button'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import { TitleCaptureAPI } from '../../services/title-capture/title-capture.api'
import { EndUserProps } from '../../services/user/user.types'

interface TitleCaptureProps {
	currentUser: EndUserProps | null
}

function TitleCapturePrototype({ currentUser }: TitleCaptureProps) {
	const [externalLinkForSso, setExternalLinkForSso] = useState('')
	async function getSSO() {
		try {
			const res = await TitleCaptureAPI.getSsoUrl()
			console.log(res)
			if (res) {
				const ssoUrl = res.data.redirect_url
				setExternalLinkForSso(ssoUrl)
				window.open(ssoUrl, '_blank')
			} else {
				console.log('No SSO URL was returned')
			}
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		if (externalLinkForSso) {
			setTimeout(() => {
				setExternalLinkForSso('')
			}, 3000)
		}
	}, [externalLinkForSso])

	useEffect(() => {
		getSSO()
	}, [])

	return (
		<div className="flex-lg overflow-y__scroll" style={{ width: '100%' }}>
			<div className="col-xs-12 col-lg-12 pr-20-sm flex flex-wrap flex-alignItems-start flex-justifyContent-center overflow-y__scroll">
				<Paper bgColor="primary" padding={['all']} margins={['bottom', 'right']}>
					<div className="flex flex-column flex-alignItems-center">
						<Typography type="h5">Login to Title Capture</Typography>
						<p className="mb-20">
							Access your Title Capture account quickly and securely through the Single Sign-On (SSO)
							feature.
						</p>
						<Button variant="contained" size="lg" primary={true} label={`Login`} onClick={() => getSSO()} />
						{externalLinkForSso && (
							<>
								{ReactDOM.createPortal(
									<div className="modal__scrim">
										<div className="external-site-msg__wrapper">
											<FontAwesomeIcon
												icon={['far', 'globe']}
												size={`5x`}
												className={`mb-20 opacity-50`}
											/>
											<Typography type="h5">Loading External Website</Typography>
											<div className="mt-10">
												If you are not redirected within 5 seconds,{' '}
												<a href={externalLinkForSso} target="_blank" rel="noreferrer">
													click here
												</a>
												.
											</div>
										</div>
									</div>,
									document.body,
								)}
							</>
						)}
					</div>
				</Paper>
			</div>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const TitleCaptureRoute = connect(mapStateToProps)(TitleCapturePrototype)
