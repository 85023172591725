import { Box } from '@components/box/box'
import { Button } from '@components/button/button'
import { Caret } from '@components/caret/caret'
import { Typography } from '@components/text/text'

import { CollapsibleContentService } from './collapsible-content.service'
import { CollapsibleContentTypes } from './collapsible-content.types'

export function CollapsibleContent__Uncontrolled(props: CollapsibleContentTypes.UncontrolledComponent) {
	return (
		<div
			className={CollapsibleContentService.getWrapperClass({
				state: props.state,
			})}
		>
			<div
				className={CollapsibleContentService.getHeaderClass({ headerPadding: props.headerPadding })}
				onClick={() => {
					if (props.state === 'CLOSED') {
						props.setState('OPEN')
					} else {
						props.setState('CLOSED')
					}
				}}
			>
				<Typography type={props.labelSize ? props.labelSize : 'strong'} color="secondary">
					{props.label}
				</Typography>
				<Box flex="row" alignItems="center" gap={true} className="flex-noShrink">
					{props.buttons?.map((button) => {
						return <Button {...button} />
					})}
					<Caret state={props.state === 'OPEN' ? 'opened' : 'closed'} />
				</Box>
			</div>
			{props.state === 'OPEN' && <div className="px-10 pt-5 pb-10">{props.children}</div>}
		</div>
	)
}
