import './administration.scss'

import { LandingPageRouteCard } from '@components/landing-page-route-card/landing-page-route-card'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { RootState } from '@redux/store'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'

import { UserService } from '../../services/user/user.service'
import { EndUserProps } from '../../services/user/user.types'
import { AdministrationRouting } from './admin.routing'
import { BackToHomeButton } from './components/back-to-home-button'

function AdministrationHomeRoutePrototype(props: { user: EndUserProps | null }) {
	const isUserAdmin = props.user ? UserService.isUserAdmin(props.user) : false

	const navigate = useNavigate()

	useEffect(() => {
		if (!isUserAdmin) {
			navigate('/hubs')
		}
	}, [isUserAdmin])

	return (
		<RouteWrapperV2>
			<RouteBodyV2 className="flex-column">
				<BackToHomeButton />
				<Typography type="h1" margins={['bottom']}>
					Administration
				</Typography>

				<div className="flex flex-wrap">
					{AdministrationRouting.getRouteProperties({ user: props.user }).map((route) => {
						if (route.visible) {
							return <LandingPageRouteCard route={route} />
						} else {
							return <React.Fragment key={route.label}></React.Fragment>
						}
					})}
				</div>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}

function mapStateToProps(state: RootState) {
	return {
		user: state.user,
	}
}

export const AdministrationHomeRoute = connect(mapStateToProps)(AdministrationHomeRoutePrototype)
