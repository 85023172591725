import { Button } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalHeader } from '@components/modal/modal-header'
import { Paper } from '@components/paper/paper'
import { TextInput } from '@components/text-input/text-input'
import { useState } from 'react'
import { EmailNotificationAPI } from 'src/services/email-notification/email-notification.api'
import { EmailNotificationTypes } from 'src/services/email-notification/email-notification.types'
import { ToastService } from 'src/services/toast/toast.service'
import { Utilities } from 'src/services/utilities.service'

interface FeedbackModalProps {
	dismissModal: () => void
}

function AdPromotionEmailModal(props: FeedbackModalProps) {
	const [submitted, setSubmitted] = useState(false)
	const [formFields, setFormFields] = useState<EmailNotificationTypes.EmailAdPromotion>({
		email: '',
	})

	const [errors, setErrors] = useState({
		email: '',
	})

	function validateEmail(value: string): boolean {
		return Utilities.isEmailValid(value)
	}

	// Validation function
	const validateFormFields = () => {
		let formErrors = { ...errors }

		formErrors.email = validateEmail(formFields.email) ? '' : 'You must enter an email address'

		setErrors(formErrors)
		return Object.values(formErrors).every((x) => x === '')
	}

	async function sendNotification() {
		if (!validateFormFields()) {
			ToastService.create({
				type: 'ERROR',
				body: 'Please fill out all fields',
			})
			return
		}

		const formData: EmailNotificationTypes.EmailAdPromotion = {
			email: formFields.email,
		}

		console.log(formData)

		EmailNotificationAPI.sendPromotionalEmail(formData)
			.then((res) => {
				setSubmitted(true)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleChange = (name: string) => (value: string | number | null) => {
		setFormFields((prevState) => ({ ...prevState, [name]: value ? value.toString() : '' }))
	}
	return (
		<>
			<Modal onClose={props.dismissModal} maxWidth={750} maxHeight={800} fixedHeight={false}>
				<ModalHeader title="Submit Advertising Info" />
				<ModalBody>
					<Paper bgColor="primary" padding={['all']} marginSize="section" margins={['bottom']}>
						<div className="col-12 flex flex-column-xs-down flex-columnReverse-xs-down">
							<div className="col-12">
								<>
									{!submitted ? (
										<>
											<div className="registration__field-wrapper flex-justifyContent-center">
												<div className="col-xs-12 mb-20" style={{ gridColumn: '1 / span 2' }}>
													<TextInput
														label={'Provide a vendor email address'}
														dataType="text"
														value={formFields.email}
														width="100%"
														onChange={handleChange('email')}
													/>
												</div>
												<div>
													<Button
														variant="contained"
														size="lg"
														primary={true}
														label={'Submit'}
														onClick={sendNotification}
														margins={['bottom']}
													/>
												</div>
											</div>
										</>
									) : (
										<div className="mb-20">Your request has been submitted.</div>
									)}
								</>
							</div>
						</div>
					</Paper>
				</ModalBody>
			</Modal>
		</>
	)
}

export default AdPromotionEmailModal
