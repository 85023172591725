/* eslint-disable react/jsx-pascal-case */
import { store } from '@redux/store'
import { Route } from 'react-router'
import { RouteProperties } from 'src/services/routing/routing.types'
import { UserService } from 'src/services/user/user.service'

import { RoutingService } from '../../services/routing/routing.service'
import { PrimaryRootRoute } from '../authenticated-root/authenticated-root'
import { ErrorPage } from '../error.page'
import { TransactionMgmt__AdminLanding__Route } from './admin/admin-landing.route'
import { TransactionAdmin__Route__ChecklistEditor } from './admin/checklist-editor/checklist-editor.route'
import { TransactionAdmin__Route__ChecklistsAdmin } from './admin/checklists-admin/checklists-admin.route'
import { TransactionAdmin__Route__LocationAdmin } from './admin/location-admin/location-admin.route'
import { TransactionAdmin__Route__NotificationAdmin } from './admin/notification-admin/notification-admin.route'
import { TransactionAdmin__Route__TaskAdmin } from './admin/task-admin/task-admin.route'
import { TransactionMgmt__Forms__Route } from './documents/documents.route'
import { TransactionMgmt__Landing__Route } from './landing-page/transaction-mgmt.route'
import { TransactionMgmt__Reports__Route } from './reports/reports.route'
import { TransactionMgmt__Tasks__Route } from './tasks/tasks.route'
import { TransactionMgmt__Transaction__Commissions__Route } from './transaction/routes/commissions/commissions.route'
import { TransactionMgmt__Transaction__Overview__Route } from './transaction/routes/overview/overview.route'
import { TransactionMgmt__Transaction__ContextWrapper } from './transaction/transaction.context-wrapper'
import { TransactionMgmt__Transactions__Route } from './transactions/transactions.route'

export namespace TransactionMgmtRouting {
	/** All of the top-level routes inside Transaction Management */
	export function getRouteProperties(): RouteProperties[] {
		return [
			{
				label: 'Transactions',
				description: 'View all transactions lorem ipsum',
				href: `${RoutingService.transactionMgmtRoutePath}/transactions`,
				icon: 'file',
				routeBody: <TransactionMgmt__Transactions__Route />,
				visible: true,
			},
			{
				label: 'Forms / Documents',
				description: 'Lorem ipsum more text goes here',
				href: `${RoutingService.transactionMgmtRoutePath}/forms`,
				icon: 'file',
				routeBody: <TransactionMgmt__Forms__Route />,
				visible: true,
			},
			{
				label: 'Tasks',
				description: 'Lorem ipsum more text goes here',
				href: `${RoutingService.transactionMgmtRoutePath}/tasks`,
				icon: 'file',
				routeBody: <TransactionMgmt__Tasks__Route />,
				visible: true,
			},
			{
				label: 'Commission Report',
				description: 'View all transactions lorem ipsum',
				href: `${RoutingService.transactionMgmtRoutePath}/reports`,
				icon: 'file',
				routeBody: <TransactionMgmt__Reports__Route />,
				visible: true,
			},
			{
				label: 'Administration',
				description: 'View all transactions lorem ipsum',
				href: `${RoutingService.transactionMgmtRoutePath}/admin`,
				icon: 'file',
				routeBody: <TransactionMgmt__AdminLanding__Route />,
				visible: () => {
					const user = store.getState().user
					return user ? UserService.isUserAdmin(user) : false
				},
			},
		]
	}

	/** All of the routes within a single transaction */
	export function getTransactionRouteProperties(): RouteProperties[] {
		return [
			{
				label: 'Overview',
				description: 'Lorem ipsum',
				href: ``,
				icon: 'file',
				routeBody: <TransactionMgmt__Transaction__Overview__Route />,
				visible: true,
			},
			{
				label: 'Commissions',
				description: 'Lorem ipsum',
				href: `commissions`,
				icon: 'file',
				routeBody: <TransactionMgmt__Transaction__Commissions__Route />,
				visible: true,
			},
		]
	}

	export function getTransactionAdminRouteProperties(): RouteProperties[] {
		return [
			{
				label: `Locations`,
				description: `Create and edit real estate brokerages that transactions can be assigned to`,
				icon: 'location-pin',
				href: `${RoutingService.transactionMgmtAdminPath}/locations`,
				routeBody: <TransactionAdmin__Route__LocationAdmin />,
				visible: true,
			},
			{
				label: 'Checklists',
				description: `Create packages containing tasks and notifications that can be added to a transaction`,
				href: `${RoutingService.transactionMgmtAdminPath}/checklists`,
				icon: 'check-square',
				routeBody: <TransactionAdmin__Route__ChecklistsAdmin />,
				visible: true,
			},
			{
				label: 'Tasks',
				description: `Create and edit tasks that can be added to a transaction`,
				href: `${RoutingService.transactionMgmtAdminPath}/tasks`,
				icon: 'list',
				routeBody: <TransactionAdmin__Route__TaskAdmin />,
				visible: true,
			},
			{
				label: 'Notifications',
				description: `Create and edit notificagtyions that can be added to a transaction`,
				href: `${RoutingService.transactionMgmtAdminPath}/notifications`,
				icon: 'alarm-clock',
				routeBody: <TransactionAdmin__Route__NotificationAdmin />,
				visible: true,
			},
		]
	}

	/** Tree defining all of the routes for react-router */
	export function getRoutes(): React.ReactElement {
		return (
			<Route
				path={RoutingService.transactionMgmtRoutePath}
				element={<PrimaryRootRoute routeSection="TRANSACTION_MGMT" />}
				errorElement={<ErrorPage />}
			>
				<Route index={true} path="" element={<TransactionMgmt__Landing__Route />}></Route>
				<Route path="transactions" element={<TransactionMgmt__Transactions__Route />}></Route>
				<Route path="forms" element={<TransactionMgmt__Forms__Route />}></Route>
				<Route path="tasks" element={<TransactionMgmt__Tasks__Route />}></Route>
				<Route path="reports" element={<TransactionMgmt__Reports__Route />}></Route>
				<Route path="admin" element={<TransactionMgmt__AdminLanding__Route />}>
					<>
						{getTransactionAdminRouteProperties().map((route) => {
							const href = typeof route.href === 'function' ? route.href() : route.href
							return <Route path={href} element={route.routeBody} key={href}></Route>
						})}
					</>
				</Route>
				<Route path="transaction/:transactionId" element={<TransactionMgmt__Transaction__ContextWrapper />}>
					<>
						{getTransactionRouteProperties().map((route) => {
							const href = typeof route.href === 'function' ? route.href() : route.href
							return <Route path={href} element={route.routeBody} key={href}></Route>
						})}
					</>
				</Route>
				<Route path="checklist/:checklistId" element={<TransactionAdmin__Route__ChecklistEditor />} />
			</Route>
		)
	}
}
