import { DragAndDropFileUploader } from '@components/drag-and-drop-file-uploader/drag-and-drop-file-uploader'
import { EndUserChip } from '@components/end-user-chip/end-user-chip'
import { SideSheet__Row } from '@components/side-sheet/side-sheet__row'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { DateService } from 'src/services/date.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { TransactionMgmt__Document__SideSheetSection__Status } from './document__section__status'

export type ComponentProps = {
	onUpdate: (updatedProps: Partial<TransactionMgmtTypes.DocumentTypes.OnlineFormDocument>) => void
	document: TransactionMgmtTypes.DocumentTypes.OnlineFormDocument
	showParentTransaction: boolean
	parentTransaction: TransactionMgmtTypes.TransactionTypes.Transaction
}

export function TransactionMgmt__Document__Section__OnlineForm(props: ComponentProps) {
	return (
		<>
			<SideSheet__Section label="Form" icon={'cog'} mode="expandable" sectionKey="document__form">
				<DragAndDropFileUploader
					style={{ width: '100%', minHeight: '75px' }}
					heightVariant="SHORT"
					allowMultiple={false}
					className="mb-10"
					onFileAdded={(files) => {
						// FileManagementService.convertFileToBase64(files[0]).then((res) => {
						// 	const updatedAdImage: AdvertisingTypes.AdvertisementImage = {
						// 		...props.adImage,
						// 		base64Image: res,
						// 	}
						// 	adEditorDispatch({ type: 'update-ad-image', payload: updatedAdImage })
						// })
					}}
					isFilePreviouslyUploaded={false}
					supportedFormats={['pdf', 'doc', 'docx']}
				/>

				<SideSheet__Row label="Signature Date" direction="row">
					{props.document.completedDate
						? DateService.getFormattedDateFromDateObj(new Date(props.document.completedDate))
						: `—`}
				</SideSheet__Row>
				<SideSheet__Row label="Signed By" direction="row">
					{props.document.completedBy && <EndUserChip endUserId={props.document.completedBy} />}
					{!props.document.completedBy && <>—</>}
				</SideSheet__Row>
			</SideSheet__Section>

			<TransactionMgmt__Document__SideSheetSection__Status<TransactionMgmtTypes.DocumentTypes.OnlineFormDocument>
				status={props.document.status}
				isEditable={true}
				onUpdateDocument={(updatedProps) => {
					props.onUpdate(updatedProps)
				}}
			/>
		</>
	)
}
