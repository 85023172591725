import { Button } from '@components/button/button'
import { MultiColumnTableTypes } from '@components/multi-column-table/multi-column-table.types'
import { MultiColumnTable } from '@components/multi-column-table/multi-column-table__wrapper'
import { Typography } from '@components/text/text'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { RoutingService } from 'src/services/routing/routing.service'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

export function TransactionAdmin__Route__ChecklistsAdmin() {
	const navigate = useNavigate()
	const [allChecklists, setAllChecklists] = useState<TransactionMgmtTypes.ChecklistTypes.Checklist[] | null>(null)

	useEffect(() => {
		TransactionManagementAPI.findChecklists().then((res) => {
			setAllChecklists(res.data)
		})
	}, [])

	function getTransactionTableColumns(): MultiColumnTableTypes.Column<TransactionMgmtTypes.ChecklistTypes.Checklist>[] {
		const columns: MultiColumnTableTypes.Column<TransactionMgmtTypes.ChecklistTypes.Checklist>[] = []

		columns.push({
			label: 'Name',
			columnId: 'name',
			value: 'name',
			displayIndex: 0,
			visible: true,
			width: 'fill',
		})

		return columns
	}

	return (
		<>
			<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween col-xs-12">
				<Typography type="h2">Checklists</Typography>
				<Button variant="outlined" label="Create Checklist" size="md" />
			</div>
			<MultiColumnTable<TransactionMgmtTypes.ChecklistTypes.Checklist>
				columns={getTransactionTableColumns()}
				idKey={'checklistId'}
				className="col-xs-12"
				items={allChecklists}
				isConfigurable={false}
				onSelect={(checklist) => {
					navigate(`${RoutingService.transactionMgmtRoutePath}/checklist/${checklist[0].checklistId}`)
				}}
				selectBehavior={'single'}
				selectedItems={[]}
				sortBehavior={{
					sortMethod: 'column-value',
					columnId: 'name',
				}}
				tableId={''}
			/>
		</>
	)
}
