import './multiselect-dropdown.scss'

import { faAngleDown, faAngleUp, faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'

import { useDebounce } from '../../services/hooks/use-debounce'
import { UsePopoverOnEvent } from '../../services/hooks/use-popover-on-event'
import { CollapsibleContent } from '../collapsible-content/collapsible-content'
import { Popover } from '../popover/popover'
import { TextInput } from '../text-input/text-input'
import { MultiSelectOption } from './multiselect-dropdown-option'

export interface MultiSelectDropdownProps<T> {
	selectedOptions: Array<T>
	options: T[]
	onSelect: (selectedOptions: T[]) => void
	optionLabelKey: keyof T
	optionIdKey: keyof T & (string | number)
	disabled?: boolean
	style?: React.CSSProperties
}

export function MultiSelectDropdown<T>(props: MultiSelectDropdownProps<T>) {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const [searchString, setSearchString] = useState('')
	const [searchResults, setSearchResults] = useState<T[]>(props.options)
	const dropdownEltRef = useRef<HTMLDivElement>(null)
	const popoverProps = UsePopoverOnEvent({
		isPopoverOpen: isDropdownOpen,
		setIsPopoverOpen: setIsDropdownOpen,
		delay: 0,
	})

	useEffect(() => {
		setSearchString('')
		setSearchResults(props.options)
	}, [isDropdownOpen])

	/** Search for options */
	useEffect(() => {
		if (searchString.length > 0) {
			const matchingOptions = props.options.filter((option) => {
				const optionLabel = option[props.optionLabelKey]
				if (
					typeof optionLabel === 'string' &&
					optionLabel.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
				) {
					return true
				}
				return false
			})
			setSearchResults(matchingOptions)
		} else {
			setSearchResults(props.options)
		}
	}, [searchString])

	function getDisplayedValue(): string {
		if (props.selectedOptions.length === 0) {
			return `Select one`
		}
		if (props.selectedOptions.length === 1) {
			const optionLabel = props.selectedOptions[0][props.optionLabelKey]
			if (typeof optionLabel === 'string') {
				return optionLabel
			} else {
				return `Not found`
			}
		}
		if (props.selectedOptions.length > 1) {
			return `${props.selectedOptions.length} selected`
		}
		return ``
	}

	function getIsOptionSelected(thisOption: T): boolean {
		const selectedOptionIds = props.selectedOptions.map((selectedTag) => {
			const optionId = selectedTag[props.optionIdKey]
			if (typeof optionId === 'string' || typeof optionId === 'number') {
				return optionId
			} else {
				throw new Error(`Option does not contain valid ID`)
			}
		})

		const thisOptionId = thisOption[props.optionIdKey]
		if (typeof thisOptionId === 'string' || typeof thisOptionId === 'number') {
			return selectedOptionIds.includes(thisOptionId)
		} else {
			return false
		}
	}

	const handleUpdateSearch = useDebounce((value: string) => {
		setSearchString(value)
	}, 1000)

	function createOptionElt(option: T): JSX.Element {
		const isSelected = getIsOptionSelected(option)
		const optionLabel = option[props.optionLabelKey]

		if (typeof optionLabel !== 'string') {
			throw new Error(`Option does not contain valid string`)
		}

		return (
			<MultiSelectOption
				key={String(option[props.optionIdKey])}
				label={optionLabel}
				option={option}
				isSelected={isSelected}
				onClick={(option) => {
					handleClickTag(option, !isSelected)
				}}
			/>
		)
	}

	function handleClickTag(option: T, isSelected: boolean): void {
		let updatedSelection = _.cloneDeep(props.selectedOptions)

		if (isSelected) {
			updatedSelection.push(option)
		} else {
			updatedSelection = updatedSelection.filter((thisTag) => {
				const thisTagId = thisTag[props.optionIdKey]
				const clickedOptionId = option[props.optionIdKey]
				return thisTagId !== clickedOptionId
			})
		}
		props.onSelect(updatedSelection)
	}

	return (
		<>
			<div
				ref={dropdownEltRef}
				className={`text-input ${props.disabled ? 'disabled opacity-50' : ''}`}
				style={props.style}
				onClick={() => {
					setIsDropdownOpen(!isDropdownOpen)
				}}
			>
				<div className="text-input__inner-content-wrapper flex flex-alignItems-center flex-justifyContent-spaceBetween">
					{getDisplayedValue()}
					<FontAwesomeIcon icon={isDropdownOpen ? faAngleUp : faAngleDown} />
				</div>
			</div>
			{dropdownEltRef.current && (
				<Popover
					hideOnMouseOut={false}
					hideOnClickOutside={true}
					isScrimVisible={true}
					{...popoverProps}
					refElement={dropdownEltRef.current}
					setShowPopover={(newState) => {
						setIsDropdownOpen(newState)
					}}
					options={{}}
				>
					<div className="multiselect-dropdown__popover">
						<div className=" col-xs-12 border-bottom-thin">
							<TextInput
								dataType="text"
								variant="unframed"
								margins={['top', 'bottom']}
								value={''}
								width={'100%'}
								placeholder={`Search`}
								prepend={<FontAwesomeIcon icon={faSearch} className="pl-10" />}
								onChange={(updatedValue) => {
									handleUpdateSearch(updatedValue)
								}}
							/>
						</div>

						<CollapsibleContent
							label="Selected Options"
							controlled={true}
							id={`${props.optionIdKey}__selected-options`}
							headerPadding={['all']}
						>
							<>
								{props.selectedOptions.map((option) => {
									return createOptionElt(option)
								})}
							</>
						</CollapsibleContent>

						<CollapsibleContent
							label="Unselected Options"
							controlled={true}
							id={`${props.optionIdKey}__unselected-options`}
							headerPadding={['all']}
						>
							<>
								{searchString.length > 0 && (
									<>
										{searchResults.length === 0 && (
											<>
												<div className="p-10">No items found</div>
											</>
										)}
										{searchResults.length > 0 && (
											<>
												{searchResults.map((option) => {
													return createOptionElt(option)
												})}
											</>
										)}
									</>
								)}
								{searchString.length === 0 && (
									<>
										{searchResults.map((option) => {
											return createOptionElt(option)
										})}
									</>
								)}
							</>
						</CollapsibleContent>
					</div>
				</Popover>
			)}
		</>
	)
}
