import _ from 'lodash'
import { createContext, useContext, useReducer } from 'react'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { TransactionState } from './transaction__state.types'

function initialState(originalState: TransactionMgmtTypes.TransactionTypes.Transaction): TransactionState.LocalState {
	return { transaction: originalState, unmodifiedTransaction: originalState }
}

const TransactionContext = createContext(initialState(TransactionManagementService.getDefaultTransactionProps()))
const TransactionDispatchContext = createContext({} as TransactionState.DispatchParams)

function TransactionReducer(state: TransactionState.LocalState, action: TransactionState.Action) {
	switch (action.type) {
		case 'update-transaction': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction = { ...updatedState.transaction, ...action.payload }
			return updatedState
		}

		/** Contacts */

		case 'add-contact': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction.contacts.push(action.payload)
			return updatedState
		}
		case 'update-contact': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction.contacts = updatedState.transaction.contacts.filter(
				(contact) => contact.contactId !== action.payload.contactId,
			)
			updatedState.transaction.contacts.push(action.payload)
			return updatedState
		}
		case 'remove-contact': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction.contacts = updatedState.transaction.contacts.filter(
				(contact) => contact.contactId !== action.payload,
			)
			return updatedState
		}

		/** Tasks */

		case 'add-task': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction.tasks.push(action.payload)
			return updatedState
		}
		case 'update-task': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction.tasks = updatedState.transaction.tasks.filter(
				(task) => task.taskId !== action.payload.taskId,
			)
			updatedState.transaction.tasks.push(action.payload)
			return updatedState
		}
		case 'remove-task': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction.tasks = updatedState.transaction.tasks.filter(
				(task) => task.taskId !== action.payload,
			)
			return updatedState
		}

		/** Documents */

		case 'add-document': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction.documents.push(action.payload)
			return updatedState
		}
		case 'update-document': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction.documents = updatedState.transaction.documents.filter(
				(document) => document.documentId !== action.payload.documentId,
			)
			updatedState.transaction.documents.push(action.payload)
			return updatedState
		}
		case 'remove-document': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction.documents = updatedState.transaction.documents.filter(
				(document) => document.documentId !== action.payload,
			)

			/** Remove any reference to this document from tasks */
			updatedState.transaction.tasks.forEach((task) => {
				task.childDocIds = task.childDocIds.filter((docId) => docId !== action.payload)
			})

			return updatedState
		}

		/** Notifications */

		case 'add-notification': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction.notifications.push(action.payload)
			return updatedState
		}
		case 'update-notification': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction.notifications = updatedState.transaction.notifications.filter(
				(notification) => notification.notificationId !== action.payload.notificationId,
			)
			updatedState.transaction.notifications.push(action.payload)
			return updatedState
		}
		case 'remove-notification': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction.notifications = updatedState.transaction.notifications.filter(
				(notification) => notification.notificationId !== action.payload,
			)
			return updatedState
		}

		/** Updates */

		case 'add-update-to-transaction': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction.updates.push(action.payload)
			return updatedState
		}

		case 'add-update-to-task': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction.tasks.forEach((task, taskIndex) => {
				if (task.taskId === action.payload.taskId) {
					updatedState.transaction.tasks[taskIndex].updates.push(action.payload.update)
				}
			})
			return updatedState
		}

		case 'add-update-to-document': {
			const updatedState = _.cloneDeep(state)
			updatedState.transaction.documents.forEach((doc, docIndex) => {
				if (doc.documentId === action.payload.documentId) {
					updatedState.transaction.documents[docIndex].updates.push(action.payload.update)
				}
			})
			return updatedState
		}
	}
}

export function TransactionProvider(props: {
	transaction: TransactionMgmtTypes.TransactionTypes.Transaction
	children: React.ReactNode
}) {
	const [state, dispatch] = useReducer(TransactionReducer, initialState(props.transaction))

	return (
		<TransactionContext.Provider value={state}>
			<TransactionDispatchContext.Provider value={dispatch}>{props.children}</TransactionDispatchContext.Provider>
		</TransactionContext.Provider>
	)
}

export function useTransaction() {
	return useContext(TransactionContext)
}

export function useTransactionDispatch() {
	return useContext(TransactionDispatchContext)
}
