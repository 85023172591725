import { Button } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'
import _ from 'lodash'
import { useState } from 'react'
import { ToastService } from 'src/services/toast/toast.service'

import { CustomFormsAPI } from '../../../services/custom-forms/custom-forms.api'
import { CustomFormsService } from '../../../services/custom-forms/custom-forms.service'
import { CustomForms } from '../../../services/custom-forms/custom-forms.types'
import { AdminItemEditorProps } from '../../../services/utility.types'
import { ValidationService } from '../../../services/validation/validation.service'
import { AdminEditorTitle, AdminEditorTitleProps } from '../components/editor-title'
import { EmailAddressEditor } from './components/email-address-editor/email-address-editor'
import { FormFieldEditor } from './components/form-field-editor/form-field-editor'

export function EditFormRoute(props: AdminItemEditorProps<CustomForms.Form>) {
	const [modifiedFormProps, setModifiedFAQProps] = useState<Partial<CustomForms.Form>>({})
	const mergedFormProps = getMergedFormProps()

	function getMergedFormProps(): Omit<CustomForms.Form, 'formId'> | CustomForms.Form {
		let form = getOriginalFormProps()
		Object.keys(modifiedFormProps).forEach((key) => {
			if (typeof key === 'string') {
				// @ts-ignore, I'm not sure how to tell typescript that the keys are always going to be keys of the form :(
				form[key] = modifiedFormProps[key]
			}
		})
		return form
	}

	function getOriginalFormProps(): CustomForms.Form | Omit<CustomForms.Form, 'formId'> {
		return props.item ? props.item : newFAQProps()
	}

	function updateFormProp(key: keyof CustomForms.Form, value: any): void {
		let updatedState = _.cloneDeep(modifiedFormProps)
		updatedState[key] = value
		setModifiedFAQProps(updatedState)
	}

	function newFAQProps(): Omit<CustomForms.Form, 'formId'> {
		return {
			formName: '',
			displayLabel: '',
			submitEmail: [],
			submitLabel: 'Submit',
			fields: [],
		}
	}

	function createForm(): void {
		CustomFormsAPI.createForm(mergedFormProps).then((newFormRes) => {
			ToastService.create({ type: 'SUCCESS', body: `Form has been created` })
			if (props.onCreate) {
				props.onCreate(newFormRes.data)
			}
			props.dismissModal()
		})
	}

	function updateFAQ(): void {
		if ('formId' in mergedFormProps) {
			const formId = mergedFormProps.formId
			CustomFormsAPI.updateForm(formId, modifiedFormProps).then((updatedFormRes) => {
				ToastService.create({ type: 'SUCCESS', body: `Form has been updated` })
				if (props.onUpdate) {
					props.onUpdate(updatedFormRes.data)
				}
				props.dismissModal()
			})
		}
	}

	function getEditorTitleProps(): AdminEditorTitleProps<CustomForms.Form> {
		return {
			itemLabel: 'Form',
			item: props.item,
			itemIdKey: 'formId',
			deleteItemFunction: CustomFormsAPI.deleteForm,
			onDelete: (item) => {
				if (props.onDelete) {
					props.onDelete(item)
				}
				props.dismissModal()
			},
		}
	}

	return (
		<Modal
			maxWidth={1000}
			maxHeight={800}
			fixedHeight={true}
			className="flex flex-column"
			onClose={props.dismissModal}
		>
			<>
				<AdminEditorTitle {...getEditorTitleProps()} />

				<ModalBody>
					<TextInput
						label="Form name"
						dataType="text"
						value={mergedFormProps.formName}
						width="100%"
						margins={['bottom']}
						onChange={(updatedValue) => {
							updateFormProp('formName', updatedValue)
						}}
						validation={{
							isValid: (value) => {
								return {
									isValid: value.length > 0,
									showValidationFlag: true,
								}
							},
							message: `Cannot be empty`,
						}}
					/>

					<TextInput
						label="Displayed Title"
						dataType="text"
						value={mergedFormProps.displayLabel}
						width="100%"
						margins={['bottom']}
						onChange={(updatedValue) => {
							updateFormProp('displayLabel', updatedValue)
						}}
						validation={{
							isValid: (value) => {
								return {
									isValid: value.length > 0,
									showValidationFlag: true,
								}
							},
							message: `Cannot be empty`,
						}}
					/>

					<Typography type="h5">Email addresses</Typography>
					<EmailAddressEditor
						className="mb-20"
						emailAddresses={mergedFormProps.submitEmail}
						updateAddresses={(addresses) => {
							updateFormProp('submitEmail', addresses)
						}}
					/>

					<Typography type="h5">Fields</Typography>
					<FormFieldEditor
						fields={mergedFormProps.fields}
						updateFields={(fields) => {
							updateFormProp('fields', fields)
						}}
					/>
				</ModalBody>

				<ModalFooter>
					<Button
						variant="outlined"
						size="md"
						primary={false}
						label="Cancel"
						margins={['right']}
						onClick={props.dismissModal}
					/>
					{props.item && (
						<Button
							variant="contained"
							size="md"
							label="Update"
							onClick={() => {
								const validationResults = CustomFormsService().validate(mergedFormProps)
								if (validationResults.isValid) {
									updateFAQ()
								} else {
									ValidationService.showValidationErrors(validationResults)
								}
							}}
						/>
					)}
					{!props.item && (
						<Button
							variant="contained"
							size="md"
							label="Create"
							onClick={() => {
								const validationResults = CustomFormsService().validate(mergedFormProps)
								if (validationResults.isValid) {
									createForm()
								} else {
									ValidationService.showValidationErrors(validationResults)
								}
							}}
						/>
					)}
				</ModalFooter>
			</>
		</Modal>
	)
}
