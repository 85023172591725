import { Chip } from '@components/chip/chip'
import { IconButton } from '@components/icon-button/icon-button'
import { MultiColumnCard } from '@components/multi-column-card/multi-column-card'
import { MultiColumnCard__Column } from '@components/multi-column-card/multi-column-card__column'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { GenericDeleteConfirmationModal } from 'src/modals/generic-delete-confirmation/generic-delete-confirmation'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

namespace NotificationCardTypes {
	export type Component = StandardCard | RemovableCard

	type BaseComponent = {
		notification: TransactionMgmtTypes.NotificationTypes.Notification
		handleSelectItem: (notification: TransactionMgmtTypes.NotificationTypes.Notification) => void
		removable: boolean
		className?: string
		/** Adds a flag to indicate this document is new */
		showNewFlag: boolean
	}

	type StandardCard = BaseComponent & {
		removable: false
	}
	type RemovableCard = BaseComponent & {
		removable: true
		onRemove: () => Promise<void>
	}
}

/** Creates a card for a single task */
export function Transaction__NotificationCard(props: NotificationCardTypes.Component) {
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)

	const numCommentUpdates = 0

	return (
		<>
			<MultiColumnCard
				size="md"
				className={props.className}
				onClick={() => {
					props.handleSelectItem(props.notification)
				}}
			>
				<MultiColumnCard__Column width="fill" align="left">
					<div style={{ width: '25px' }} className="flex flex-alignItems-center"></div>
					{props.notification.name}
					{props.showNewFlag && (
						<Chip
							label="NEW"
							color="var(--colorAccent)"
							className="ml-10"
							style={{ minWidth: 'inherit' }}
						/>
					)}
				</MultiColumnCard__Column>
				<MultiColumnCard__Column width={50} align="right" className="opacity-50">
					{numCommentUpdates > 0 && (
						<>
							<FontAwesomeIcon icon={['far', 'comment']} />
							<div className="ml-5">{numCommentUpdates}</div>
						</>
					)}
				</MultiColumnCard__Column>
				{props.removable && (
					<MultiColumnCard__Column width={50} align="right">
						<IconButton
							icon="times"
							variant="inline"
							onClick={(evt) => {
								evt.stopPropagation()
								setShowDeleteConfirmationModal(true)
							}}
						/>
					</MultiColumnCard__Column>
				)}
			</MultiColumnCard>

			{showDeleteConfirmationModal && (
				<GenericDeleteConfirmationModal
					itemLabel={props.notification.name}
					onDelete={async () => {
						if (!props.removable) {
							return
						}
						await props.onRemove()
						setShowDeleteConfirmationModal(false)
					}}
					onClose={() => {
						setShowDeleteConfirmationModal(false)
					}}
				/>
			)}
		</>
	)
}
