import { Button } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { PhoneInput } from '@components/text-input/phone-input'
import { TextInput } from '@components/text-input/text-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIState } from '@redux/reducers/ui-reducer'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { LeadAPI } from 'src/services/lead/lead.api'
import { FindLeadsInDomainQueryFilters, LeadProps } from 'src/services/lead/lead.types'

import useAdminRedirect from '../../../services/hooks/user-admin-redirect'
import { LeadExport } from '../../../services/lead-capture-export/lead-capture-export.api'
import { EndUserProps } from '../../../services/user/user.types'
import { useItemAdminHook } from '../use-item-admin-hook'
import { EditLeadSideSheet } from './edit-lead__side-sheet'
import { LeadAdminCard } from './lead-admin__card/lead-admin__card'

type ConnectedProps = {
	userProfile: EndUserProps | null
	ui: UIState
}

function LeadsAdministrationRoute__Prototype(props: ConnectedProps) {
	const navigate = useNavigate()
	const [searchFirstName, setSearchFirstName] = useState('')
	const [searchLastName, setSearchLastName] = useState('')
	const [searchPhone, setSearchPhone] = useState<number | null>(null)
	const [searchEmail, setSearchEmail] = useState('')
	const [searchState, setSearchState] = useState('')
	const [leadToEdit, setLeadToEdit] = useState('')

	useAdminRedirect(props.userProfile)
	const [leadButtonLabel, setLeadButtonLabel] = useState('Download Lead List')

	// const navigate = useNavigate()

	const [isPending, setIsPending] = useState(true)
	const userAdminHook = useItemAdminHook<LeadProps, FindLeadsInDomainQueryFilters>({
		getItemsFunction: (page, size, sort, searchParams) => {
			console.log(searchParams)
			return LeadAPI.findLeadsInDomain({
				query: {
					firstName: searchParams.firstName ? searchParams.firstName : undefined,
					lastName: searchParams.lastName ? searchParams.lastName : undefined,
					state: searchParams.state ? searchParams.state : undefined,
					phone: searchParams.phone ? searchParams.phone : undefined,
					email: searchParams.email ? searchParams.email : undefined,
				},
				page,
				size,
				sort: [{ property: 'firstName', direction: 'asc' }],
			})
		},
		searchParams: {
			firstName: searchFirstName,
			lastName: searchLastName,
			state: searchState,
			phone: searchPhone ? String(searchPhone) : undefined,
			email: searchEmail,
		},
		itemIdKey: 'leadId',
		itemTitleKey: 'firstName',
		setIsPending: (state) => {
			setIsPending(state)
		},
	})

	async function getLeadsExport() {
		try {
			setLeadButtonLabel('Generating...')
			await LeadExport.getLeadExport()
		} catch (error) {
			console.error(error)
		} finally {
			setLeadButtonLabel('Download Lead List')
		}
	}

	useEffect(() => {
		if (leadToEdit === '') {
			userAdminHook.reset()
		}
	}, [leadToEdit])

	return (
		<>
			<RouteWrapperV2>
				<RouteSubnavigation
					title={`Leads Administration`}
					backButton={{
						label: 'Administration',
						onClick: () => {
							navigate('/administration')
						},
					}}
					isCustom={true}
				>
					<TextInput
						dataType="text"
						label="First name"
						value={searchFirstName}
						onChange={(updatedValue) => {
							setSearchFirstName(updatedValue)
						}}
						width={'100%'}
						margins={['bottom']}
					/>
					<TextInput
						dataType="text"
						label="Last name"
						value={searchLastName}
						onChange={(updatedValue) => {
							setSearchLastName(updatedValue)
						}}
						width={'100%'}
						margins={['bottom']}
					/>
					<TextInput
						dataType="text"
						label="Email"
						value={searchEmail}
						onChange={(updatedValue) => {
							setSearchEmail(updatedValue)
						}}
						width={'100%'}
						margins={['bottom']}
					/>
					<PhoneInput
						label="Phone"
						dataType="number"
						value={searchPhone}
						onChange={(updatedValue) => {
							setSearchPhone(updatedValue)
						}}
						width={'100%'}
						margins={['bottom']}
					/>

					<TextInput
						dataType="text"
						label="State"
						value={searchState}
						onChange={(updatedValue) => {
							setSearchState(updatedValue)
						}}
						width={'100%'}
						margins={['bottom']}
					/>
				</RouteSubnavigation>

				<RouteBodyV2 className="col-xs-12" id="lead-admin__route-body">
					<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center flex-column-md-down flex-alignItems-start-md-down mb-10">
						<Typography type="h1">Leads Administration</Typography>

						<Button
							variant="contained"
							label={leadButtonLabel}
							size={'md'}
							onClick={() => {
								getLeadsExport()
							}}
						/>
					</div>
					<div className="mb-20 opacity-50">{userAdminHook.totalElements} Results</div>
					<div className="flex flex-column-md-down">
						<div className="flex flex-column flex-fillSpace mt-20-md-down">
							{!isPending && !userAdminHook.hasMoreItems && userAdminHook.items.length === 0 && (
								<div className="flex flex-column flex-fillSpace flex-justifyContent-center flex-alignItems-center">
									<FontAwesomeIcon icon={['far', 'newspaper']} size="4x" className="mb-20" />
									<Typography type="h3">
										We cannot find any resources matching your criteria
									</Typography>
								</div>
							)}

							<InfiniteScroll
								dataLength={userAdminHook.items.length}
								next={userAdminHook.loadNextPageOfItems}
								hasMore={userAdminHook.hasMoreItems}
								scrollableTarget={props.ui.viewMode === 'FULL' ? `lead-admin__route-body` : undefined}
								loader={
									<>
										<GenericContentLoader width={'fill'} height={70} className="mb-10" />
										<GenericContentLoader width={'fill'} height={70} className="mb-10" />
										<GenericContentLoader width={'fill'} height={70} className="mb-10" />
									</>
								}
							>
								{userAdminHook.items &&
									userAdminHook.items.map((lead) => {
										return (
											<LeadAdminCard
												key={lead.leadId}
												onClick={() => {
													setLeadToEdit(lead.email)
												}}
												lead={lead}
											/>
										)
									})}
							</InfiniteScroll>
						</div>
					</div>
				</RouteBodyV2>
			</RouteWrapperV2>
			{leadToEdit !== '' && (
				<EditLeadSideSheet
					leadToEdit={leadToEdit}
					onClose={() => {
						setLeadToEdit('')
					}}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
		ui: state.ui,
	}
}

export const LeadsAdministrationRoute = connect(mapStateToProps)(LeadsAdministrationRoute__Prototype)
