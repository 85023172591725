import { AxiosResponse } from 'axios'
import { cloneDeep, merge } from 'lodash'

import { Utilities } from '../utilities.service'
import {
	getTransactionMgmt__Checklists,
	getTransactionMgmt__Documents,
	getTransactionMgmt__Notifications,
	getTransactionMgmt__Tasks,
	getTransactionMgmt__Transactions,
	TransactionMgmt__Locations,
} from './transaction-mgmt.static-data'
import { TransactionMgmtTypes } from './transaction-mgmt.types'

const TransactionMgmtState = getTransactionMgmt__Transactions()
const TransactionmgmtChecklists = getTransactionMgmt__Checklists()
const TransactionMgmtLibrary__Tasks = getTransactionMgmt__Tasks()
const TransactionMgmtLibrary__Notifications = getTransactionMgmt__Notifications()
const TransactionMgmtLibrary__Documents = getTransactionMgmt__Documents()

function getFakeTimeoutMs(): number {
	return Utilities.generateRandomInt(400, 700)
}

export namespace TransactionManagementAPI {
	/** ====================================== */
	/** Transactions */

	export function findTransactions(): Promise<AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction[]>> {
		return new Promise((resolve, reject) => {
			let allTransactions: TransactionMgmtTypes.TransactionTypes.Transaction[] = [...TransactionMgmtState]

			const response: AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction[]> = {
				status: 200,
				statusText: '',
				headers: {},
				data: allTransactions,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function createTransaction(
		transaction: TransactionMgmtTypes.TransactionTypes.Transaction,
	): Promise<AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction>> {
		return new Promise((resolve, reject) => {
			const newTransactionWithId: TransactionMgmtTypes.TransactionTypes.Transaction = {
				...transaction,
				transactionId: Utilities.generateRandomInt(1, 1000000),
			}

			/** Traverse all checklists to find those that should be added to this transaction */
			TransactionmgmtChecklists.forEach((checklist) => {
				if (!checklist.addToTransactionIfConditionsMet) {
					return
				}

				/** Check if the checklist either has NO "side" requirements, OR the "side" of this transaction is included in the checklists "sides" */
				let isRequirmentMet__side = false
				if (
					checklist.addToTransactionSide.length === 0 ||
					checklist.addToTransactionSide.includes(transaction.side)
				) {
					isRequirmentMet__side = true
				}

				/** Check if the checklist either has NO "status" requirements, OR the "status" of this transaction is included in the checklists "status" */
				let isRequirmentMet__status = false
				if (
					checklist.addToTransactionStatus.length === 0 ||
					checklist.addToTransactionStatus.includes(transaction.status)
				) {
					isRequirmentMet__status = true
				}

				/** Check if the checklist either has NO "type" requirements, OR the "type" of this transaction is included in the checklists "type" */
				let isRequirmentMet__type = false
				if (
					checklist.addToTransactionType.length === 0 ||
					checklist.addToTransactionType.includes(transaction.type)
				) {
					isRequirmentMet__type = true
				}

				/** Check if this transaction meets the conditions required to add all components of this checklist to it */
				if (isRequirmentMet__side && isRequirmentMet__type && isRequirmentMet__status) {
					/** Add all child notifications of this checklist */
					checklist.childNotificationIds.forEach((notificationId) => {
						const thisNotification = TransactionMgmtLibrary__Notifications.find(
							(notification) => notification.notificationId === notificationId,
						)
						if (!thisNotification) {
							throw new Error(`Cannot find notification`)
						}
						newTransactionWithId.notifications.push(cloneDeep(thisNotification))
					})

					/** Add all child tasks of this checklist */
					checklist.childTaskIds.forEach((taskId) => {
						const thisTask = TransactionMgmtLibrary__Tasks.find((task) => task.taskId === taskId)

						if (!thisTask) {
							throw new Error(`Cannot find task`)
						}

						newTransactionWithId.tasks.push(cloneDeep(thisTask))

						/** Add all child documents of this task */
						thisTask.childDocIds.forEach((docId) => {
							const thisDoc = TransactionMgmtLibrary__Documents.find((doc) => doc.documentId === docId)
							if (!thisDoc) {
								throw new Error(`Cannot find document`)
							}
							newTransactionWithId.documents.push(cloneDeep(thisDoc))
						})
					})
				}
			})

			TransactionMgmtState.push(newTransactionWithId)

			const response: AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction> = {
				status: 200,
				statusText: '',
				headers: {},
				data: newTransactionWithId,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function getTransactionById(
		transactionId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction>> {
		return new Promise((resolve, reject) => {
			const thisTransaction = TransactionMgmtState.find(
				(transaction) => transaction.transactionId === transactionId,
			)

			if (!thisTransaction) {
				reject()
				return
			}

			const response: AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction> = {
				status: 200,
				statusText: '',
				headers: {},
				data: thisTransaction,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function getParentTransactionOfTask(
		taskId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction | null>> {
		return new Promise((resolve, reject) => {
			const thisTransaction = TransactionMgmtState.find((transaction) => {
				return transaction.tasks.find((task) => task.taskId === taskId)
			})

			const response: AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction | null> = {
				status: 200,
				statusText: '',
				headers: {},
				data: thisTransaction ? thisTransaction : null,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function getParentTransactionOfDocument(
		documentId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction | null>> {
		return new Promise((resolve, reject) => {
			const thisTransaction = TransactionMgmtState.find((transaction) => {
				return transaction.documents.find((document) => document.documentId === documentId)
			})

			const response: AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction | null> = {
				status: 200,
				statusText: '',
				headers: {},
				data: thisTransaction ? thisTransaction : null,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function getParentTransactionOfNotification(
		notificationId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction | null>> {
		return new Promise((resolve, reject) => {
			const thisTransaction = TransactionMgmtState.find((transaction) => {
				return transaction.notifications.find((notification) => notification.notificationId === notificationId)
			})

			const response: AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction | null> = {
				status: 200,
				statusText: '',
				headers: {},
				data: thisTransaction ? thisTransaction : null,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	/** Patches the general transaction properties. Does not patch any properties that are arrays (they have their own endpoints) */
	export function patchTransaction(
		transactionId: number,
		updatedTransactionProps: Partial<TransactionMgmtTypes.TransactionTypes.Transaction>,
	): Promise<AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction>> {
		return new Promise((resolve, reject) => {
			let updatedCompleteTransaction: TransactionMgmtTypes.TransactionTypes.Transaction | null = null

			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					TransactionMgmtState[transactionIndex] = merge(transaction, updatedTransactionProps)
					updatedCompleteTransaction = cloneDeep(TransactionMgmtState[transactionIndex])
				}
			})

			if (!updatedCompleteTransaction) {
				reject()
				return
			}

			const response: AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction> = {
				status: 200,
				statusText: '',
				headers: {},
				data: updatedCompleteTransaction,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	/** ==================================== */
	/** Contacts */

	export function addContactToTransaction(
		transactionId: number,
		newContact: TransactionMgmtTypes.TransactionTypes.Contact,
	): Promise<AxiosResponse<TransactionMgmtTypes.TransactionTypes.Contact>> {
		return new Promise((resolve, reject) => {
			const newContactWithId: TransactionMgmtTypes.TransactionTypes.Contact = {
				...newContact,
				contactId: Utilities.generateRandomInt(1, 1000000),
			}

			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					TransactionMgmtState[transactionIndex].contacts.push(newContactWithId)
				}
			})

			const response: AxiosResponse<TransactionMgmtTypes.TransactionTypes.Contact> = {
				status: 200,
				statusText: '',
				headers: {},
				data: newContactWithId,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function updateContactOnTransaction(
		transactionId: number,
		updatedContact: TransactionMgmtTypes.TransactionTypes.Contact,
	): Promise<AxiosResponse<TransactionMgmtTypes.TransactionTypes.Contact>> {
		return new Promise((resolve, reject) => {
			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					transaction.contacts.forEach((contact, contactIndex) => {
						if (contact.contactId === updatedContact.contactId) {
							TransactionMgmtState[transactionIndex].contacts[contactIndex] = updatedContact
						}
					})
				}
			})

			const response: AxiosResponse<TransactionMgmtTypes.TransactionTypes.Contact> = {
				status: 200,
				statusText: '',
				headers: {},
				data: updatedContact,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function deleteContactFromTransaction(
		transactionId: number,
		contactId: number,
	): Promise<AxiosResponse<null>> {
		return new Promise((resolve, reject) => {
			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					transaction.contacts.forEach((contact, contactIndex) => {
						if (contact.contactId === contactId) {
							TransactionMgmtState[transactionIndex].contacts.splice(contactIndex, 1)
						}
					})
				}
			})

			const response: AxiosResponse<null> = {
				status: 200,
				statusText: '',
				headers: {},
				data: null,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	/** ===================================== */
	/** Tasks */

	export function addTaskToTransaction(
		transactionId: number,
		newTask: TransactionMgmtTypes.TaskTypes.Task,
	): Promise<AxiosResponse<TransactionMgmtTypes.TaskTypes.Task>> {
		return new Promise((resolve, reject) => {
			const newTaskWithId: TransactionMgmtTypes.TaskTypes.Task = {
				...newTask,
				taskId: Utilities.generateRandomInt(1, 1000000),
			}

			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					TransactionMgmtState[transactionIndex].tasks.push(newTaskWithId)
				}
			})

			const response: AxiosResponse<TransactionMgmtTypes.TaskTypes.Task> = {
				status: 200,
				statusText: '',
				headers: {},
				data: newTaskWithId,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function updateTaskOnTransaction(
		transactionId: number,
		updatedTask: TransactionMgmtTypes.TaskTypes.Task,
	): Promise<AxiosResponse<TransactionMgmtTypes.TaskTypes.Task>> {
		return new Promise((resolve, reject) => {
			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					transaction.tasks.forEach((task, taskIndex) => {
						if (task.taskId === updatedTask.taskId) {
							TransactionMgmtState[transactionIndex].tasks[taskIndex] = updatedTask
						}
					})
				}
			})

			const response: AxiosResponse<TransactionMgmtTypes.TaskTypes.Task> = {
				status: 200,
				statusText: '',
				headers: {},
				data: updatedTask,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function deleteTaskFromTransaction(
		transactionId: number,
		taskId: number,
		deleteChildDocs: boolean,
	): Promise<AxiosResponse<null>> {
		return new Promise((resolve, reject) => {
			let thisTaskProps: TransactionMgmtTypes.TaskTypes.Task | null = null
			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					/** Remove this task from the transaction */
					transaction.tasks.forEach((task, taskIndex) => {
						if (task.taskId === taskId) {
							thisTaskProps = cloneDeep(task)
							TransactionMgmtState[transactionIndex].tasks.splice(taskIndex, 1)
						}
					})

					/** Either remove the document from the transaction, or disconnect it from its parent task */
					transaction.documents.forEach((doc, docIndex) => {
						if (thisTaskProps && thisTaskProps.childDocIds.includes(doc.documentId)) {
							if (deleteChildDocs) {
								TransactionMgmtState[transactionIndex].documents.splice(docIndex, 1)
							}
						}
					})
				}
			})

			const response: AxiosResponse<null> = {
				status: 200,
				statusText: '',
				headers: {},
				data: null,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	/** ========================================= */
	/** Documents */

	export function addDocumentToTransaction(
		transactionId: number,
		newDocument: TransactionMgmtTypes.DocumentTypes.Document,
	): Promise<AxiosResponse<TransactionMgmtTypes.DocumentTypes.Document>> {
		return new Promise((resolve, reject) => {
			const newDocumentWithId: TransactionMgmtTypes.DocumentTypes.Document = {
				...newDocument,
				documentId: Utilities.generateRandomInt(1, 1000000),
			}

			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					TransactionMgmtState[transactionIndex].documents.push(newDocumentWithId)
				}
			})

			const response: AxiosResponse<TransactionMgmtTypes.DocumentTypes.Document> = {
				status: 200,
				statusText: '',
				headers: {},
				data: newDocumentWithId,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function updateDocumentOnTransaction(
		transactionId: number,
		updatedDocument: TransactionMgmtTypes.DocumentTypes.Document,
	): Promise<AxiosResponse<TransactionMgmtTypes.DocumentTypes.Document>> {
		return new Promise((resolve, reject) => {
			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					transaction.documents.forEach((documents, documentsIndex) => {
						if (documents.documentId === updatedDocument.documentId) {
							TransactionMgmtState[transactionIndex].documents[documentsIndex] = updatedDocument
						}
					})
				}
			})

			const response: AxiosResponse<TransactionMgmtTypes.DocumentTypes.Document> = {
				status: 200,
				statusText: '',
				headers: {},
				data: updatedDocument,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function deleteDocumentFromTransaction(
		transactionId: number,
		documentId: number,
	): Promise<AxiosResponse<null>> {
		return new Promise((resolve, reject) => {
			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					/** Delete the document from the transaction */
					transaction.documents.forEach((document, documentIndex) => {
						if (document.documentId === documentId) {
							TransactionMgmtState[transactionIndex].documents.splice(documentIndex, 1)
						}
					})

					/** Remove a reference to this document from its parent task */
					transaction.tasks.forEach((task, taskIndex) => {
						if (task.childDocIds.includes(documentId)) {
							TransactionMgmtState[transactionIndex].tasks[taskIndex].childDocIds = TransactionMgmtState[
								transactionIndex
							].tasks[taskIndex].childDocIds.filter((docId) => docId !== documentId)
						}
					})
				}
			})

			const response: AxiosResponse<null> = {
				status: 200,
				statusText: '',
				headers: {},
				data: null,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	/** ==================================== */
	/** Notifications */

	export function addNotificationToTransaction(
		transactionId: number,
		newNotification: TransactionMgmtTypes.NotificationTypes.Notification,
	): Promise<AxiosResponse<TransactionMgmtTypes.NotificationTypes.Notification>> {
		return new Promise((resolve, reject) => {
			const newNotificationWithId: TransactionMgmtTypes.NotificationTypes.Notification = {
				...newNotification,
				notificationId: Utilities.generateRandomInt(1, 1000000),
			}

			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					TransactionMgmtState[transactionIndex].notifications.push(newNotificationWithId)
				}
			})

			const response: AxiosResponse<TransactionMgmtTypes.NotificationTypes.Notification> = {
				status: 200,
				statusText: '',
				headers: {},
				data: newNotificationWithId,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function updateNotificationOnTransaction(
		transactionId: number,
		updatedNotification: TransactionMgmtTypes.NotificationTypes.Notification,
	): Promise<AxiosResponse<TransactionMgmtTypes.NotificationTypes.Notification>> {
		return new Promise((resolve, reject) => {
			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					transaction.notifications.forEach((notification, notificationIndex) => {
						if (notification.notificationId === updatedNotification.notificationId) {
							TransactionMgmtState[transactionIndex].notifications[notificationIndex] =
								updatedNotification
						}
					})
				}
			})

			const response: AxiosResponse<TransactionMgmtTypes.NotificationTypes.Notification> = {
				status: 200,
				statusText: '',
				headers: {},
				data: updatedNotification,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function deleteNotificationFromTransaction(
		transactionId: number,
		notificationId: number,
	): Promise<AxiosResponse<null>> {
		return new Promise((resolve, reject) => {
			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					transaction.notifications.forEach((notification, notificationIndex) => {
						if (notification.notificationId === notificationId) {
							TransactionMgmtState[transactionIndex].notifications.splice(notificationIndex, 1)
						}
					})
				}
			})

			const response: AxiosResponse<null> = {
				status: 200,
				statusText: '',
				headers: {},
				data: null,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	/** ===================================== */
	/** Checklists */

	export function findChecklists(): Promise<AxiosResponse<TransactionMgmtTypes.ChecklistTypes.Checklist[]>> {
		return new Promise((resolve, reject) => {
			let allChecklists: TransactionMgmtTypes.ChecklistTypes.Checklist[] = [...TransactionmgmtChecklists]

			const response: AxiosResponse<TransactionMgmtTypes.ChecklistTypes.Checklist[]> = {
				status: 200,
				statusText: '',
				headers: {},
				data: allChecklists,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function addChecklistToTransaction(
		parentTransactionId: number,
		checklistId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction>> {
		return new Promise((resolve, reject) => {
			let allTransactions: TransactionMgmtTypes.TransactionTypes.Transaction[] = [...TransactionMgmtState]
			let allChecklists: TransactionMgmtTypes.ChecklistTypes.Checklist[] = [...TransactionmgmtChecklists]

			const indexOfTransaction = allTransactions.findIndex((thisTransaction, transactionIndex) => {
				return thisTransaction.transactionId === parentTransactionId
			})

			const checklistToAdd = allChecklists.find((thisChecklist) => {
				return thisChecklist.checklistId === checklistId
			})

			if (indexOfTransaction < 0 || !checklistToAdd) {
				throw new Error(`Cannot add checklist to transction`)
			}

			/** Add all tasks to transaction */
			checklistToAdd.childTaskIds.forEach((taskId) => {
				const taskProps = TransactionMgmtLibrary__Tasks.find((thisTask) => thisTask.taskId === taskId)
				if (taskProps) {
					allTransactions[indexOfTransaction].tasks.push(taskProps)

					/** Add all documents associated with this task to transaction */
					taskProps.childDocIds.forEach((docId) => {
						const docProps = cloneDeep(
							TransactionMgmtLibrary__Documents.find((thisDoc) => thisDoc.documentId === docId),
						)
						if (docProps) {
							allTransactions[indexOfTransaction].documents.push(docProps)
						}
					})
				}
			})

			/** Add all notifications to transaction */
			checklistToAdd.childNotificationIds.forEach((notificationId) => {
				const notificationProps = TransactionMgmtLibrary__Notifications.find(
					(thisNotification) => thisNotification.notificationId === notificationId,
				)
				if (notificationProps) {
					allTransactions[indexOfTransaction].notifications.push(notificationProps)
				}
			})

			const response: AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction> = {
				status: 200,
				statusText: '',
				headers: {},
				data: allTransactions[indexOfTransaction],
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	/** ==================================== */
	/** Comments */

	export function addCommentToTransaction(
		transactionId: number,
		commentBody: string,
		taggedUsers: number[],
		visibility: TransactionMgmtTypes.UpdateTypes.Visibility,
		senderUserId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.UpdateTypes.CommentUpdate>> {
		return new Promise((resolve, reject) => {
			const newUpdateDTO: TransactionMgmtTypes.UpdateTypes.CommentUpdate = {
				type: 'COMMENT',
				createTimestamp: Date.now(),
				senderEndUserId: senderUserId,
				visibility,
				body: commentBody,
				taggedEndUserIds: taggedUsers,
				updateId: Utilities.generateRandomInt(1, 1000000),
			}

			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					TransactionMgmtState[transactionIndex].updates.push(newUpdateDTO)
				}
			})

			const response: AxiosResponse<TransactionMgmtTypes.UpdateTypes.CommentUpdate> = {
				status: 200,
				statusText: '',
				headers: {},
				data: newUpdateDTO,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function addCommentToTask(
		transactionId: number,
		taskId: number,
		commentBody: string,
		taggedUsers: number[],
		visibility: TransactionMgmtTypes.UpdateTypes.Visibility,
		senderUserId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.UpdateTypes.CommentUpdate>> {
		return new Promise((resolve, reject) => {
			const newUpdateDTO: TransactionMgmtTypes.UpdateTypes.CommentUpdate = {
				type: 'COMMENT',
				createTimestamp: Date.now(),
				senderEndUserId: senderUserId,
				visibility,
				body: commentBody,
				taggedEndUserIds: taggedUsers,
				updateId: Utilities.generateRandomInt(1, 1000000),
			}

			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					transaction.tasks.forEach((task, taskIndex) => {
						if (task.taskId === taskId) {
							TransactionMgmtState[transactionIndex].tasks[taskIndex].updates.push(newUpdateDTO)
						}
					})
				}
			})

			const response: AxiosResponse<TransactionMgmtTypes.UpdateTypes.CommentUpdate> = {
				status: 200,
				statusText: '',
				headers: {},
				data: newUpdateDTO,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function addCommentToDocument(
		transactionId: number,
		documentId: number,
		commentBody: string,
		taggedUsers: number[],
		visibility: TransactionMgmtTypes.UpdateTypes.Visibility,
		senderUserId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.UpdateTypes.CommentUpdate>> {
		return new Promise((resolve, reject) => {
			const newUpdateDTO: TransactionMgmtTypes.UpdateTypes.CommentUpdate = {
				type: 'COMMENT',
				createTimestamp: Date.now(),
				senderEndUserId: senderUserId,
				visibility,
				body: commentBody,
				taggedEndUserIds: taggedUsers,
				updateId: Utilities.generateRandomInt(1, 1000000),
			}

			TransactionMgmtState.forEach((transaction, transactionIndex) => {
				if (transaction.transactionId === transactionId) {
					transaction.documents.forEach((document, docIndex) => {
						if (document.documentId === documentId) {
							TransactionMgmtState[transactionIndex].documents[docIndex].updates.push(newUpdateDTO)
						}
					})
				}
			})

			const response: AxiosResponse<TransactionMgmtTypes.UpdateTypes.CommentUpdate> = {
				status: 200,
				statusText: '',
				headers: {},
				data: newUpdateDTO,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	/** ==================================== */
	/** ==================================== */
	/** ==================================== */

	export function getLocationById(
		locationId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.LocationTypes.Location>> {
		return new Promise((resolve, reject) => {
			const thisLocation = TransactionMgmt__Locations.find((location) => location.locationId === locationId)

			if (!thisLocation) {
				reject()
				return
			}

			const response: AxiosResponse<TransactionMgmtTypes.LocationTypes.Location> = {
				status: 200,
				statusText: '',
				headers: {},
				data: thisLocation,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function findLocations(): Promise<AxiosResponse<TransactionMgmtTypes.LocationTypes.Location[]>> {
		return new Promise((resolve, reject) => {
			let allLocations: TransactionMgmtTypes.LocationTypes.Location[] = [...TransactionMgmt__Locations]

			const response: AxiosResponse<TransactionMgmtTypes.LocationTypes.Location[]> = {
				status: 200,
				statusText: '',
				headers: {},
				data: allLocations,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function findTasks(): Promise<AxiosResponse<TransactionMgmtTypes.TaskTypes.Task[]>> {
		return new Promise((resolve, reject) => {
			let allTasks: TransactionMgmtTypes.TaskTypes.Task[] = []

			TransactionMgmtState.forEach((transaction) => {
				allTasks = [...allTasks, ...transaction.tasks]
			})

			const response: AxiosResponse<TransactionMgmtTypes.TaskTypes.Task[]> = {
				status: 200,
				statusText: '',
				headers: {},
				data: allTasks,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function getTaskById(
		taskId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.TaskTypes.Task | undefined>> {
		return new Promise((resolve, reject) => {
			let allTasks: TransactionMgmtTypes.TaskTypes.Task[] = TransactionMgmtLibrary__Tasks

			TransactionMgmtState.forEach((transaction) => {
				allTasks = [...allTasks, ...transaction.tasks]
			})

			const thisTask = allTasks.find((task) => task.taskId === taskId)

			const response: AxiosResponse<TransactionMgmtTypes.TaskTypes.Task | undefined> = {
				status: 200,
				statusText: '',
				headers: {},
				data: thisTask,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function findLibraryTasks(): Promise<AxiosResponse<TransactionMgmtTypes.TaskTypes.Task[]>> {
		return new Promise((resolve, reject) => {
			const response: AxiosResponse<TransactionMgmtTypes.TaskTypes.Task[]> = {
				status: 200,
				statusText: '',
				headers: {},
				data: cloneDeep(TransactionMgmtLibrary__Tasks),
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function findLibraryNotifications(): Promise<
		AxiosResponse<TransactionMgmtTypes.NotificationTypes.Notification[]>
	> {
		return new Promise((resolve, reject) => {
			const response: AxiosResponse<TransactionMgmtTypes.NotificationTypes.Notification[]> = {
				status: 200,
				statusText: '',
				headers: {},
				data: cloneDeep(TransactionMgmtLibrary__Notifications),
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function getNotifictionById(
		notificationId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.NotificationTypes.Notification | undefined>> {
		return new Promise((resolve, reject) => {
			let allNotifications: TransactionMgmtTypes.NotificationTypes.Notification[] = [
				...TransactionMgmtLibrary__Notifications,
			]

			TransactionMgmtState.forEach((transaction) => {
				allNotifications = [...allNotifications, ...transaction.notifications]
			})

			const thisNotification = allNotifications.find(
				(notification) => notification.notificationId === notificationId,
			)

			const response: AxiosResponse<TransactionMgmtTypes.NotificationTypes.Notification | undefined> = {
				status: 200,
				statusText: '',
				headers: {},
				data: thisNotification,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function getDocumentById(
		documentId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.DocumentTypes.Document | undefined>> {
		return new Promise((resolve, reject) => {
			let allDocuments: TransactionMgmtTypes.DocumentTypes.Document[] = []

			TransactionMgmtState.forEach((transaction) => {
				allDocuments = [...allDocuments, ...transaction.documents]
			})

			const thisDocument = allDocuments.find((document) => document.documentId === documentId)

			const response: AxiosResponse<TransactionMgmtTypes.DocumentTypes.Document | undefined> = {
				status: 200,
				statusText: '',
				headers: {},
				data: thisDocument,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function findDocuments(): Promise<AxiosResponse<TransactionMgmtTypes.DocumentTypes.Document[]>> {
		return new Promise((resolve, reject) => {
			let allDocuments: TransactionMgmtTypes.DocumentTypes.Document[] = []

			TransactionMgmtState.forEach((transaction) => {
				allDocuments = [...allDocuments, ...transaction.documents]
			})

			const response: AxiosResponse<TransactionMgmtTypes.DocumentTypes.Document[]> = {
				status: 200,
				statusText: '',
				headers: {},
				data: allDocuments,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function getChecklistById(
		checklistId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.ChecklistTypes.Checklist>> {
		return new Promise((resolve, reject) => {
			const thisTransaction = TransactionmgmtChecklists.find((checklist) => checklist.checklistId === checklistId)

			if (!thisTransaction) {
				reject()
				return
			}

			const response: AxiosResponse<TransactionMgmtTypes.ChecklistTypes.Checklist> = {
				status: 200,
				statusText: '',
				headers: {},
				data: thisTransaction,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function findAllParentChecklistsOfTask(
		taskId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.ChecklistTypes.Checklist[]>> {
		return new Promise((resolve, reject) => {
			let allChecklists: TransactionMgmtTypes.ChecklistTypes.Checklist[] = TransactionmgmtChecklists

			allChecklists = allChecklists.filter((checklist) => {
				if (checklist.childTaskIds.includes(taskId)) {
					return true
				}
				return false
			})

			const response: AxiosResponse<TransactionMgmtTypes.ChecklistTypes.Checklist[]> = {
				status: 200,
				statusText: '',
				headers: {},
				data: allChecklists,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}

	export function findAllParentChecklistsOfNotification(
		notificationId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.ChecklistTypes.Checklist[]>> {
		return new Promise((resolve, reject) => {
			let allChecklists: TransactionMgmtTypes.ChecklistTypes.Checklist[] = TransactionmgmtChecklists

			allChecklists = allChecklists.filter((checklist) => {
				if (checklist.childNotificationIds.includes(notificationId)) {
					return true
				}
				return false
			})

			const response: AxiosResponse<TransactionMgmtTypes.ChecklistTypes.Checklist[]> = {
				status: 200,
				statusText: '',
				headers: {},
				data: allChecklists,
				config: {},
			}

			setTimeout(() => {
				resolve(response)
			}, getFakeTimeoutMs())
		})
	}
}
