import { useEffect, useState } from 'react'
import { AdvertisementImageAPI } from 'src/services/advertising/advertisement-image.api'
import { AdvertisingService } from 'src/services/advertising/advertising.service'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'

import { AdvertisingAccount__Ad__SideSheet__Inner } from './ad__side-sheet'
import { AdEditorProvider } from './state/ad-editor__state'

type AdvertisingAccountSideSheetProps = {
	account: AdvertisingTypes.Account
	advertisement: AdvertisingTypes.Advertisement | 'NEW'
	onClose: () => void
	onUpdate: (updatedAd: AdvertisingTypes.Advertisement) => void
	onDelete: () => void
}

export function AdvertisingAccount__Ad__SideSheet__Wrapper(props: AdvertisingAccountSideSheetProps) {
	const [modifiedAd, setModifiedAd] = useState<AdvertisingTypes.Advertisement | null>(null)
	const [adImages, setAdImages] = useState<AdvertisingTypes.AdvertisementImage[] | null>(null)

	useEffect(() => {
		if (props.advertisement === 'NEW') {
			const newAd = AdvertisingService.getDefaultAdProps(props.account.accountId)
			setModifiedAd(newAd)
			setAdImages([])
		} else {
			setModifiedAd(props.advertisement)

			AdvertisementImageAPI.getAdImages(props.advertisement.advertisementId, { page: 0, size: 20 }).then(
				(res) => {
					setAdImages(res.data)
				},
			)
		}
	}, [])

	if (!modifiedAd || !adImages) {
		return <></>
	}

	return (
		<AdEditorProvider advertisementToEdit={modifiedAd} account={props.account} adImagesToEdit={adImages}>
			<AdvertisingAccount__Ad__SideSheet__Inner
				onClose={props.onClose}
				onUpdate={props.onUpdate}
				onDelete={props.onDelete}
			/>
		</AdEditorProvider>
	)
}
