import { Button } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'
import { YouTubeEmbed } from '@components/youtube-embed/youtube-embed'

type ComponentProps = {
	onHide: () => void
	title: string
	url: string
	description: string
}

/** Modal for displaying a YouTube video */
export function YouTubeModal(props: ComponentProps) {
	return (
		<Modal
			maxHeight={800}
			maxWidth={800}
			fixedHeight={false}
			className="flex flex-column"
			onClose={() => {
				props.onHide()
			}}
		>
			<>
				<ModalHeader title={props.title} />

				<ModalBody>
					<YouTubeEmbed videoUrl={props.url} />
					<div className="mt-20">{props.description}</div>
				</ModalBody>
				<ModalFooter>
					<Button
						variant="contained"
						size="md"
						label="Close"
						onClick={() => {
							props.onHide()
						}}
					/>
				</ModalFooter>
			</>
		</Modal>
	)
}
