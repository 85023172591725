import { LanguageSpecialtyFilter } from '@components/language-specialty-filter/language-specialty-filter'
import { LocationFilter } from '@components/location-filter/location-filter'
import { useGetDebouncedValue } from '@components/popover/use-get-debounced-value'
import { RegionFilter } from '@components/region-filter/region-filter'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { SpecialtyFilter } from '@components/specialty-filter/specialty-filter'
import { StateFilter } from '@components/state-filter/state-filter'
import { Switch } from '@components/switch/switch'
import { Typography } from '@components/text/text'
import { PhoneInput } from '@components/text-input/phone-input'
import { TextInput } from '@components/text-input/text-input'
import { ToggleButton } from '@components/toggle-button/toggle-button'
import { RootState } from '@redux/store'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { UserService } from 'src/services/user/user.service'

import { LicensedStatesService } from '../../services/licensed-states/licensed-states.service'
import { LicensedState } from '../../services/licensed-states/licenses-states.types'
import { LicensedRegionsService } from '../../services/regions/regions.service'
import { UserAPI } from '../../services/user/user.api'
import { EndUserProps } from '../../services/user/user.types'
import { AgentSearchMapView } from './agent-search__map-view'
import { AgentSearchResults } from './agent-search__results'
import { AgentSearchActiveFilters } from './agent-search-active-filters'
import { useAgentSearch, useAgentSearchDispatch } from './state/agent-search__state'
import { AgentSearchState } from './state/agent-search__state.types'

const AGENT_SEARCH_PAGE_SIZE = 20

type ConnectedProps = {
	licensedStates: LicensedState[]
	currentUser: EndUserProps | null
}

function AgentSearchRouteInnerPrototype(props: ConnectedProps) {
	const searchState = useAgentSearch()
	const searchDispatch = useAgentSearchDispatch()
	const selectedLicensedState: LicensedState | undefined = searchState.searchParams.licensedStateId
		? LicensedStatesService.getStateFromId(props.licensedStates, searchState.searchParams.licensedStateId)
		: undefined
	const regionsUnderSelectedState = selectedLicensedState
		? LicensedRegionsService.getRegionsOfState(selectedLicensedState.licensedStateId)
		: []
	const selectedRegion = regionsUnderSelectedState.find(
		(region) => region.regionId === searchState.searchParams.regionId,
	)

	const showDisabledUsers = searchState.searchParams.showDisabled

	/** Note: We capture the search params that were used last so we can determine when a new search needs to begin */
	const [previousSearchParams, setPreviousSearchParams] = useState<AgentSearchState.SearchParams | null>(null)
	const debouncedSearchParams = useGetDebouncedValue(searchState.searchParams, 1000)

	const areAnyFiltersActive = getAreAnyFiltersActive(searchState.searchParams)

	/** ============================================== */
	/** Effect */

	/** Start a new search whenever the user changes any search params */
	useEffect(() => {
		if (!_.isEqual(previousSearchParams, debouncedSearchParams)) {
			console.log('Previous Params: ', previousSearchParams)
			startNewSearch(searchState)
			setPreviousSearchParams(debouncedSearchParams)
		}
	}, [debouncedSearchParams])

	useEffect(() => {
		if (!searchState.searchParams.geography.srcLat && !searchState.searchParams.geography.srcLong) {
			// If both srcLat and srcLong are null, set viewMode to LIST
			searchDispatch([{ type: 'update-view-mode', payload: 'LIST' }])
		}
	}, [searchState.searchParams.geography.srcLat, searchState.searchParams.geography.srcLong, searchDispatch])

	useEffect(() => {
		if (searchState.viewMode === 'LIST') {
			// Reset pagination and start new search for the LIST view
			startNewSearch(searchState)
		}
	}, [searchState.viewMode])

	useEffect(() => {
		if (selectedLicensedState?.licensedStateId !== 1) {
			searchDispatch([{ type: 'set-licensed-region-id', payload: undefined }])
		}
	}, [selectedLicensedState])

	/** ============================================== */
	/** Methods */

	function getAreAnyFiltersActive(params: AgentSearchState.SearchParams): boolean {
		let numActiveFilters = 0
		Object.keys(params).forEach((key) => {
			/** Parameter "Geography" will never be empty. We need to check whether it has valid lat/long coordinates */
			if (key === 'geography') {
				if (params[key].srcLat !== null && params[key].srcLong !== null) {
					numActiveFilters++
				}
				return
			}

			// @ts-ignore
			const paramValue = params[key]
			if (paramValue !== undefined && paramValue !== '') {
				numActiveFilters++
			}
		})

		return numActiveFilters > 0
	}

	function startNewSearch(searchParams: AgentSearchState.LocalState): void {
		searchDispatch([
			{
				type: 'reset-end-users',
				payload: null,
			},
			{
				type: 'reset-search-page',
				payload: null,
			},
			{
				type: 'set-has-more',
				payload: true,
			},
		])

		searchUsers(searchParams.searchParams, 0)
	}

	/** Note: We have to pass in all the parameters for search here. This is because the function is debounced, which makes us unable to read from the state inside this component */
	function searchUsers(params: AgentSearchState.SearchParams, searchPage: number): void {
		new Promise<EndUserProps[]>((resolve) => {
			UserAPI.findUsersInDomain({
				query: {
					first: params.firstName,
					last: params.lastName,
					phone: params.phone,
					email: params.email,
					licensedStateId: params.licensedStateId,
					regionId: params.regionId,
					specialtyIds: params.specialties
						? params.specialties.map((specialty) => specialty.specialtyId)
						: undefined,
					languageSpecialtyIds: params.languageSpecialties
						? params.languageSpecialties.map((languageSpecialty) => languageSpecialty.languageSpecialtyId)
						: undefined,
					geography:
						typeof params.geography.srcLat === 'number' && typeof params.geography.srcLong === 'number'
							? params.geography
							: undefined,
					showDisabled: params.showDisabled,
				},
				page: searchPage,
				size: AGENT_SEARCH_PAGE_SIZE,
				sort: [{ property: 'registrationTimestamp', direction: 'desc' }],
			}).then((res) => {
				resolve(res.data.items)
			})
		}).then((additionalEndUsers) => {
			const dispatchEvents: AgentSearchState.Action[] = []
			dispatchEvents.push({ type: 'add-end-users', payload: additionalEndUsers })

			/** Tell the application to stop searching for more users if the results of the last search request did not return as many users as possible */
			if (additionalEndUsers.length < AGENT_SEARCH_PAGE_SIZE) {
				dispatchEvents.push({ type: 'set-has-more', payload: false })
			}
			searchDispatch(dispatchEvents)
		})
	}

	/** ============================================== */
	/** Render Component */

	return (
		<RouteWrapperV2>
			<RouteSubnavigation title={`Agent Referral Network`} isCustom={true}>
				<TextInput
					dataType="text"
					label="First name"
					labelPlacement="top"
					value={searchState.searchParams.firstName ? searchState.searchParams.firstName : ''}
					onChange={(updatedValue) => {
						searchDispatch([{ type: 'set-first-name', payload: updatedValue }])
					}}
					width={'100%'}
					margins={['bottom']}
				/>
				<TextInput
					dataType="text"
					label="Last name"
					labelPlacement="top"
					value={searchState.searchParams.lastName ? searchState.searchParams.lastName : ''}
					onChange={(updatedValue) => {
						searchDispatch([{ type: 'set-last-name', payload: updatedValue }])
					}}
					width={'100%'}
					margins={['bottom']}
				/>
				<TextInput
					dataType="text"
					label="Email"
					labelPlacement="top"
					value={searchState.searchParams.email ? searchState.searchParams.email : ''}
					onChange={(updatedValue) => {
						searchDispatch([{ type: 'set-email', payload: updatedValue }])
					}}
					width={'100%'}
					margins={['bottom']}
				/>
				<PhoneInput
					label="Phone"
					dataType="number"
					labelPlacement="top"
					value={searchState.searchParams.phone ? parseFloat(searchState.searchParams.phone) : null}
					onChange={(updatedValue) => {
						if (typeof updatedValue === 'number') {
							searchDispatch([{ type: 'set-phone', payload: String(updatedValue) }])
						} else {
							searchDispatch([{ type: 'set-phone', payload: undefined }])
						}
					}}
					width={'100%'}
					margins={['bottom']}
				/>

				<StateFilter
					selectedStates={selectedLicensedState ? [selectedLicensedState] : []}
					onSelect={(selectedStates) => {
						if (selectedStates && selectedStates.length > 0) {
							searchDispatch([
								{ type: 'set-licensed-state-id', payload: selectedStates[0].licensedStateId },
							])
						} else {
							searchDispatch([{ type: 'set-licensed-state-id', payload: undefined }])
						}
					}}
					isMulti={false}
					className="mb-20"
				/>

				{selectedLicensedState?.licensedStateId === 1 && (
					<RegionFilter
						selectedRegions={selectedRegion ? [selectedRegion] : []}
						selectedStateId={selectedLicensedState?.licensedStateId}
						onSelect={(selectedRegions) => {
							if (selectedRegions && selectedRegions.length > 0) {
								searchDispatch([
									{ type: 'set-licensed-region-id', payload: selectedRegions[0].regionId },
								])
							} else {
								searchDispatch([{ type: 'set-licensed-region-id', payload: undefined }])
							}
						}}
						isMulti={false}
						className="mb-20"
					/>
				)}

				<SpecialtyFilter
					selectedSpecialties={
						searchState.searchParams.specialties ? searchState.searchParams.specialties : []
					}
					onSelect={(selectedSpecialties) => {
						searchDispatch([{ type: 'set-specialties', payload: selectedSpecialties }])
					}}
					isMulti={true}
					className="mb-20"
				/>

				<LanguageSpecialtyFilter
					selectedLanguageSpecialties={
						searchState.searchParams.languageSpecialties ? searchState.searchParams.languageSpecialties : []
					}
					onSelect={(selectedLanguageSpecialties) => {
						console.log('Selected: ', selectedLanguageSpecialties)
						searchDispatch([{ type: 'set-languageSpecialties', payload: selectedLanguageSpecialties }])
					}}
					isMulti={true}
					className="mb-20"
				/>

				<LocationFilter
					enabled={false}
					locationSearchParams={searchState.searchParams.geography}
					onChangeLocation={(locationParams) => {
						searchDispatch([{ type: 'set-geography', payload: locationParams }])
					}}
				/>
				{props.currentUser && UserService.isUserAdmin(props.currentUser) && (
					<div className="flex flex-alignItems-center mt-20">
						<Switch
							className="mx-5"
							checked={showDisabledUsers}
							onChange={(updatedValue) => {
								searchDispatch([{ type: 'set-show-disabled', payload: updatedValue }])
							}}
						/>
						<span>{showDisabledUsers ? 'Showing Disabled Users' : 'Hiding Disabled Users'}</span>
					</div>
				)}
			</RouteSubnavigation>

			<RouteBodyV2 id="agent-search__body" className="flex flex-column">
				<Typography type="h1" margins={['bottom']}>
					Agent Referral Network
				</Typography>
				<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-10">
					{areAnyFiltersActive && <Typography type="h5">Results</Typography>}
					{!areAnyFiltersActive && <Typography type="h5">All Agents</Typography>}
					<ToggleButton<AgentSearchState.ViewMode>
						options={[
							{ label: 'List', value: 'LIST' },
							{ label: 'Map', value: 'MAP' },
						]}
						selectedOption={searchState.viewMode}
						onSelect={(selectedOption) => {
							searchDispatch([{ type: 'update-view-mode', payload: selectedOption }])
						}}
					/>
				</div>
				{areAnyFiltersActive && <AgentSearchActiveFilters searchParams={searchState.searchParams} />}
				{searchState.viewMode === 'LIST' && (
					<AgentSearchResults
						params={searchState.searchParams}
						endUsers={searchState.endUsers}
						clearSearch={() => {
							searchDispatch([{ type: 'clear-all-search-params', payload: null }])
						}}
						hasMore={searchState.hasMore}
						loadNextPageOfResults={() => {
							const nextPageIndex = searchState.resultsPage ? searchState.resultsPage + 1 : 1
							searchUsers(searchState.searchParams, nextPageIndex)
							searchDispatch([{ type: 'set-search-page', payload: nextPageIndex }])
						}}
					/>
				)}
				{searchState.viewMode === 'MAP' && searchState.endUsers && searchState.hasMore && (
					<AgentSearchMapView
						isSearchComplete={false}
						params={searchState.searchParams}
						onCompleteSearch={(endUsers) => {
							searchDispatch([
								{ type: 'set-end-users', payload: endUsers },
								{ type: 'set-has-more', payload: false },
							])
						}}
					/>
				)}
				{searchState.viewMode === 'MAP' && searchState.endUsers && !searchState.hasMore && (
					<AgentSearchMapView
						isSearchComplete={true}
						endUsers={searchState.endUsers}
						params={searchState.searchParams}
					/>
				)}
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}

function mapStateToProps(state: RootState) {
	return {
		licensedStates: state.licensedStates,
		currentUser: state.user,
	}
}

export const AgentSearchRouteInner = connect(mapStateToProps)(AgentSearchRouteInnerPrototype)
