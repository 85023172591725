import { Button } from '@components/button/button'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { RootState } from '@redux/store'
import { useState } from 'react'
import { connect } from 'react-redux'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'
import { EndUserProps } from 'src/services/user/user.types'

import { TransactionMgmt__AddCommentModal } from '../../shared/add-comment-modal/add-comment-modal'
import { UpdateCard } from '../../transaction/routes/overview/updates/update-card'
import { useTransactionDispatch } from '../../transaction/state/transaction__state'

type ComponentProps = {
	updates: TransactionMgmtTypes.UpdateTypes.Update[]
	parentTaskId: number | null
	parentDocumentId: number | null
	parentTransaction: TransactionMgmtTypes.TransactionTypes.Transaction
	onAddComment: (commentUpdate: TransactionMgmtTypes.UpdateTypes.CommentUpdate) => void
}

type ConnectedProps = {
	currentUser: EndUserProps | null
}

/** Displays all of the updates related to a task / document in a side sheet */
function TransactionMgmt__SideSheet__Section__UpdatesPrototype(props: ComponentProps & ConnectedProps) {
	const transactionDispatch = useTransactionDispatch()
	const [showAddCommentModal, setShowAddCommentModal] = useState(false)
	function sortUpdates(
		updates: TransactionMgmtTypes.UpdateTypes.Update[],
	): TransactionMgmtTypes.UpdateTypes.Update[] {
		return updates.sort((a, b) => (a.createTimestamp > b.createTimestamp ? -1 : 1))
	}

	return (
		<>
			<SideSheet__Section label={`Updates`} icon={`timeline`} mode="expandable" sectionKey="task__updates">
				{sortUpdates(props.updates).map((update) => {
					let parentTaskId: number | null = null
					if ('taskId' in update) {
						parentTaskId = update.taskId
					}

					return (
						<UpdateCard
							update={update}
							parentTaskId={parentTaskId}
							parentDocumentId={null}
							parentTransaction={props.parentTransaction}
							condensed={true}
						/>
					)
				})}
				<Button
					label="Add Comment"
					variant="outlined"
					size="md"
					onClick={() => {
						setShowAddCommentModal(true)
					}}
				/>
			</SideSheet__Section>
			{showAddCommentModal && (
				<TransactionMgmt__AddCommentModal
					onClose={() => {
						setShowAddCommentModal(false)
					}}
					onAddComment={async (commentBody, taggedUsers, visibility) => {
						if (!props.currentUser) {
							return
						}
						if (props.parentTaskId) {
							const res = await TransactionManagementAPI.addCommentToTask(
								props.parentTransaction.transactionId,
								props.parentTaskId,
								commentBody,
								taggedUsers,
								visibility,
								props.currentUser.endUserId,
							)
							transactionDispatch({
								type: 'add-update-to-task',
								payload: { taskId: props.parentTaskId, update: res.data },
							})
							setShowAddCommentModal(false)
							props.onAddComment(res.data)
						}

						if (props.parentDocumentId) {
							const res = await TransactionManagementAPI.addCommentToDocument(
								props.parentTransaction.transactionId,
								props.parentDocumentId,
								commentBody,
								taggedUsers,
								visibility,
								props.currentUser.endUserId,
							)
							transactionDispatch({
								type: 'add-update-to-document',
								payload: { documentId: props.parentDocumentId, update: res.data },
							})
							setShowAddCommentModal(false)
							props.onAddComment(res.data)
						}
					}}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const TransactionMgmt__SideSheet__Section__Updates = connect(mapStateToProps)(
	TransactionMgmt__SideSheet__Section__UpdatesPrototype,
)
