import { Button } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { SideSheet } from '@components/side-sheet/side-sheet'
import { SideSheetServices } from '@components/side-sheet/side-sheet.service'
import { SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import { SideSheet__Row } from '@components/side-sheet/side-sheet__row'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { TextInput } from '@components/text-input/text-input'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { GenericDeleteConfirmationModal } from 'src/modals/generic-delete-confirmation/generic-delete-confirmation'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { TransactionManagementTransactionChip } from '../../shared/transaction-chip/transaction-chip'
import { TransactionMgmt__SideSheet__Section__Updates } from '../shared/side-sheet-section__updates'
import { TransactionMgmt__Document__Section__FileUpload } from './document__file-upload.section'
import { TransactionMgmt__Document__Section__OnlineForm } from './document__online-form.section'

export type ComponentProps = {
	onClose: () => void
	onUpdate: (updatedDocument: TransactionMgmtTypes.DocumentTypes.Document) => void
	onDelete: (documentId: number) => void
	document: TransactionMgmtTypes.DocumentTypes.Document | null
	showParentTransaction: boolean
	parentTransaction?: TransactionMgmtTypes.TransactionTypes.Transaction
}

export function TransactionMgmt__Document__SideSheet(props: ComponentProps) {
	const [originalDocument] = useState(getDefaultDocProperties())
	const [modifiedDocument, setModifiedDocument] = useState(getDefaultDocProperties())
	const [parentTransaction, setParentTransaction] =
		useState<TransactionMgmtTypes.TransactionTypes.Transaction | null>(
			props.parentTransaction ? props.parentTransaction : null,
		)
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
	const didUserModifyProps = !isEqual(modifiedDocument, originalDocument)

	useEffect(() => {
		if (!props.parentTransaction && modifiedDocument) {
			TransactionManagementAPI.getParentTransactionOfDocument(modifiedDocument.documentId).then((res) => {
				if (res.data) {
					setParentTransaction(res.data)
				}
			})
		}
	}, [])

	function handleSaveDocument(): Promise<TransactionMgmtTypes.DocumentTypes.Document> {
		return new Promise((resolve, reject) => {
			if (!parentTransaction) {
				throw new Error(`Cannot find parent transaction`)
			}

			if (modifiedDocument.documentId < 0) {
				TransactionManagementAPI.addDocumentToTransaction(
					parentTransaction.transactionId,
					modifiedDocument,
				).then((res) => {
					resolve(res.data)
				})
			} else {
				TransactionManagementAPI.updateDocumentOnTransaction(
					parentTransaction.transactionId,
					modifiedDocument,
				).then((res) => {
					resolve(res.data)
				})
			}
		})
	}
	function updateDocument(updatedProps: Partial<TransactionMgmtTypes.DocumentTypes.Document>): void {
		/** @ts-ignore */
		const updatedDocument: TransactionMgmtTypes.DocumentTypes.Document = {
			...modifiedDocument,
			...updatedProps,
		}
		setModifiedDocument(updatedDocument)
	}

	function changeDocType(docType: TransactionMgmtTypes.DocumentTypes.DocType): void {
		const updatedDocProps: TransactionMgmtTypes.DocumentTypes.BaseDocument = {
			documentId: modifiedDocument.documentId,
			type: docType,
			completedBy: modifiedDocument.completedBy,
			completedDate: modifiedDocument.completedDate,
			createdDate: modifiedDocument.createdDate,
			lastUpdatedDate: modifiedDocument.lastUpdatedDate,
			description: modifiedDocument.description,
			name: modifiedDocument.name,
			reviewedByUserId: modifiedDocument.reviewedByUserId,
			reviewedDate: modifiedDocument.reviewedDate,
			status: modifiedDocument.status,
			updates: modifiedDocument.updates,
		}

		switch (docType) {
			case 'FILE_UPLOAD': {
				const fileUploadProps: TransactionMgmtTypes.DocumentTypes.FileUploadDocument = {
					...updatedDocProps,
					type: 'FILE_UPLOAD',
				}
				setModifiedDocument(fileUploadProps)
				break
			}
			case 'ONLINE_FORM': {
				const onlineFormProps: TransactionMgmtTypes.DocumentTypes.OnlineFormDocument = {
					...updatedDocProps,
					type: 'ONLINE_FORM',
					formUrl: '',
				}
				setModifiedDocument(onlineFormProps)
				break
			}
		}
	}

	/** If no task is passed in, assume that the user is creating a new task and provide default properties for it  */
	function getDefaultDocProperties(): TransactionMgmtTypes.DocumentTypes.Document {
		return props.document ? props.document : TransactionManagementService.getDefaultDocumentProps()
	}

	function createFooter(): React.ReactNode {
		return (
			<>
				<Button
					variant={'outlined'}
					label={'Cancel'}
					size={'md'}
					className="mr-10"
					onClick={async () => {
						props.onClose()
					}}
				/>
				<Button
					variant={'contained'}
					label={'Save and Close'}
					size={'md'}
					onClick={async () => {
						const res = await handleSaveDocument()
						props.onClose()
						props.onUpdate(res)
					}}
				/>
			</>
		)
	}

	function createActions(): SideSheetTypes.Action[] {
		const actions: SideSheetTypes.Action[] = []

		actions.push({
			label: `Delete`,
			icon: 'trash',
			onClick: () => {
				setShowDeleteConfirmationModal(true)
			},
		})

		return actions
	}

	function onScrimClick(): void {
		if (!didUserModifyProps) {
			props.onClose()
			return
		}

		SideSheetServices.showDismissConfirmationToast({
			onDismiss: () => {
				return new Promise((resolve) => {
					resolve()
					props.onClose()
				})
			},
			onSaveAndClose: () => {
				return handleSaveDocument().then(() => {
					props.onClose()
					props.onUpdate(modifiedDocument)
				})
			},
		})
	}

	if (!parentTransaction) {
		return <></>
	}

	return (
		<>
			<SideSheet
				title={'Document'}
				actions={createActions()}
				onClose={props.onClose}
				persistent={false}
				footer={createFooter()}
				preventDefaultScrimClick={didUserModifyProps}
				onScrimClick={onScrimClick}
			>
				<SideSheet__Section label="Details" mode="expandable" sectionKey="document__details">
					<SideSheet__Row label="Name" direction="column">
						<TextInput
							type="text"
							dataType="text"
							value={modifiedDocument.name}
							width={'100%'}
							onChange={(updatedValue) => {
								updateDocument({ name: updatedValue })
							}}
						/>
					</SideSheet__Row>
					<SideSheet__Row label="Description" direction="column">
						<TextInput
							type="text"
							dataType="text"
							rows={3}
							value={modifiedDocument.description}
							width={'100%'}
							onChange={(updatedValue) => {
								updateDocument({ description: updatedValue })
							}}
						/>
					</SideSheet__Row>
					<SideSheet__Row label="Type" direction="row">
						<Dropdown<TransactionMgmtTypes.DocumentTypes.DocType>
							value={[modifiedDocument.type]}
							width={200}
							options={[
								{
									label: 'File Upload',
									value: 'FILE_UPLOAD',
								},
								{
									label: 'Online Form',
									value: 'ONLINE_FORM',
								},
							]}
							onSelect={(selectedOptions) => {
								if (modifiedDocument.documentId < 0) {
									changeDocType(selectedOptions[0])
								} else {
									throw new Error(`Cannot change the 'type' of an existing document`)
								}
							}}
							disabled={modifiedDocument.documentId > 0}
						/>
					</SideSheet__Row>
					{props.showParentTransaction && parentTransaction && (
						<SideSheet__Row label="Parent Transaction" direction="row">
							<TransactionManagementTransactionChip transactionId={parentTransaction.transactionId} />
						</SideSheet__Row>
					)}
				</SideSheet__Section>

				{modifiedDocument.type === 'FILE_UPLOAD' && (
					<TransactionMgmt__Document__Section__FileUpload
						onUpdate={updateDocument}
						document={modifiedDocument}
						showParentTransaction={props.showParentTransaction}
						parentTransaction={parentTransaction}
					/>
				)}
				{modifiedDocument.type === 'ONLINE_FORM' && (
					<TransactionMgmt__Document__Section__OnlineForm
						onUpdate={updateDocument}
						document={modifiedDocument}
						showParentTransaction={props.showParentTransaction}
						parentTransaction={parentTransaction}
					/>
				)}

				{props.parentTransaction && (
					<TransactionMgmt__SideSheet__Section__Updates
						parentTaskId={null}
						parentDocumentId={modifiedDocument.documentId}
						parentTransaction={props.parentTransaction}
						updates={modifiedDocument.updates}
						onAddComment={(newComment) => {
							updateDocument({ updates: [...modifiedDocument.updates, newComment] })
						}}
					/>
				)}
			</SideSheet>
			{showDeleteConfirmationModal && (
				<GenericDeleteConfirmationModal
					itemLabel={modifiedDocument.name}
					onDelete={async () => {
						await TransactionManagementAPI.deleteDocumentFromTransaction(
							parentTransaction.transactionId,
							modifiedDocument.documentId,
						)
						props.onDelete(modifiedDocument.documentId)
						setShowDeleteConfirmationModal(false)
					}}
					onClose={() => {
						setShowDeleteConfirmationModal(false)
					}}
				/>
			)}
		</>
	)
}
