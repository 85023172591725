import './login.scss'

import { Button } from '@components/button/button'
import { LoginRouteWrapper } from '@components/login-route-wrapper/login-route-wrapper'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'
import { useAppDispatch } from '@redux/hooks'
import { getUserProps } from '@redux/reducers/user-reducer'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'

import { Domain } from '../../services/domain/domain.types'
import { UserAPI } from '../../services/user/user.api'
import { EndUserProps } from '../../services/user/user.types'
import { Utilities } from '../../services/utilities.service'

function LoginRoutePrototype(props: { domain: Domain.Properties | null }) {
	const dispatch = useAppDispatch()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [isLoginPending, setIsLoginPending] = useState(false)

	const navigate = useNavigate()

	function logUserIn(): Promise<void> {
		return new Promise((resolve, reject) => {
			UserAPI.login({
				email,
				password,
			})
				.then((res) => {
					localStorage.removeItem('registrationState')
					const accountStatus = res.headers['x-account-status']
					if (res.headers.token) {
						const parsedToken = Utilities.parseJwt(res.headers.token)
						if (parsedToken && 'id' in parsedToken && typeof parsedToken.id === 'number') {
							const userId = parsedToken.id
							/** Get User info */
							dispatch(getUserProps({ userId })).then((action: { type: string; payload: any }) => {
								if (action.type === 'user/get/fulfilled') {
									const payload = action.payload as EndUserProps
									if (payload.roles.some((role) => role.roleType === 'USER')) {
										const redirectURL = localStorage.getItem('redirectAfterLogin') || '/hubs'
										localStorage.removeItem('redirectAfterLogin') // Clear saved URL
										navigate(redirectURL) // Redirect user to the original URL
									} else if (payload.roles.some((role) => role.roleType === 'PENDING_PAYMENT')) {
										if (!payload.enabled) {
											navigate('/rejoin')
										} else if (payload.enabled) {
											navigate('/registration')
										}
									}
								}
								resolve()
								// navigate(`${hubRoutePath}/`);
							})
						}
					} else if (accountStatus === 'DISABLED' && res.status === 403) {
						console.log('not active')
					}
				})
				.catch(() => {
					reject()
				})
		})
	}

	useEffect(() => {
		const removeStoredRegState = localStorage.getItem('registrationState')
		const removeStoredPromoCode = localStorage.getItem('promotionCode')
		if (removeStoredPromoCode != null) {
			localStorage.removeItem('promotionCode')
		}

		if (removeStoredRegState != null) {
			localStorage.removeItem('registrationState')
		}
	}, [])

	return (
		<LoginRouteWrapper
			isLoginRoute={true}
			bodyClassName="flex flex-column flex-justifyContent-center flex-fillSpace flex-alignItems-center col-xs-12"
		>
			<>
				{props.domain && (
					<div className="clamp-width-for-login-routes">
						<Typography type="h3" margins={['bottom']}>
							Login to {props.domain.label}
						</Typography>
						<p>
							If you're a current LoKation agent visiting the new Sphere for the first time, please click
							"Forgot Password" below.
						</p>
						<TextInput
							width={`100%`}
							dataType="text"
							placeholder="Email"
							margins={['bottom']}
							value={email}
							debounceChangeEvt={false}
							onChange={(updatedValue) => {
								setEmail(String(updatedValue))
							}}
							inputStyle={{ width: '500px' }}
							autoComplete={'email'}
							name={'email'}
						/>
						<TextInput
							width={`100%`}
							dataType="text"
							placeholder="Password"
							margins={['bottom']}
							value={password}
							debounceChangeEvt={false}
							onChange={(updatedValue) => {
								setPassword(String(updatedValue))
							}}
							onKeyDown={(updatedValue) => {
								if (updatedValue.key === 'Enter') {
									setIsLoginPending(true)
									logUserIn().finally(() => {
										setIsLoginPending(false)
									})
								}
							}}
							inputStyle={{ width: '500px' }}
							type={'password'}
							autoComplete="password"
						/>

						<Button
							label="Login"
							variant="contained"
							size={'lg'}
							onClick={() => {
								return new Promise((resolve) => {
									logUserIn().finally(() => {
										resolve()
									})
								})
							}}
							className="col-xs-12"
							isPending={isLoginPending}
						/>
						<div className="mt-40 flex flex-justifyContent-end">
							{/* <Button variant="text" label="Register for an account" to="/registration" size="sm" /> */}
							<Button variant="text" label="Forgot Password?" to="/forgot-password" size="md" />
						</div>
					</div>
				)}
			</>
		</LoginRouteWrapper>
	)
}

function mapStateToProps(state: RootState) {
	return {
		domain: state.domain,
	}
}

export const LoginRoute = connect(mapStateToProps)(LoginRoutePrototype)
