import '../luxury-branding-styles.scss'

import { Button } from '@components/button/button'
import { Typography } from '@components/text/text'
import { CopyToClipboard } from 'src/services/copy-to-clipboard/copy-to-clipboard'

interface ColorBoxProps {
	color: LuxuryBrandingColorsProps
}
interface LuxuryBrandingColorsProps {
	name: string
	code: string
	fontColor: string
}

export function LuxuryBrandingColors() {
	const colors: LuxuryBrandingColorsProps[] = [
		{ name: 'Navy', code: '#011B29', fontColor: '#fff' },
		{ name: 'Off-White', code: '#F2EEE8', fontColor: '#000' },
		{ name: 'Tranquil Blue', code: '#66A5B4', fontColor: '#fff' },
		{ name: 'Neutral Beige', code: '#BDBEAF', fontColor: '#000' },
	]

	const ColorBox = ({ color }: ColorBoxProps) => (
		<div>
			<Button
				size="lg"
				variant="contained"
				label={color.code}
				margins={['right']}
				style={{ background: color.code, color: color.fontColor }}
				onClick={() => {
					CopyToClipboard(color.code, 'STRING', `Copied: ${color.code}`)
				}}
			/>
		</div>
	)

	return (
		<div className="flex-lg overflow-y__scroll flex-justifyContent-center">
			<div className="col-xs-12 col-lg-8 pr-20-sm mb-20 flex flex-column flex-alignItems-start overflow-y__scroll flex-justifyContent-center">
				<div className="col-12 flex flex-justifyContent-center">
					<Typography type="h3">LoKation Luxury's Palette: Our Signature Brand Colors</Typography>
				</div>
				<p>
					LoKation Luxury proudly presents its signature color palette, meticulously selected to embody the
					essence of luxury and elegance. This palette features a deep and commanding navy, providing a
					foundation of sophistication; a soft and serene off-white, offering a subtle contrast; a refreshing
					and tranquil blue, reminiscent of coastal elegance; and an understated yet chic neutral beige,
					balancing the palette with warmth. These colors are more than just shades; they represent the
					LoKation Luxury brand's commitment to quality, style, and distinction in the luxury real estate
					market.
				</p>

				<div className="col-12 p-20 font-wrapper flex flex-wrap">
					<div className="col-12 flex flex-justifyContent-center">
						<Typography type="h4">Use these HEX codes for marketing and luxury assets</Typography>
					</div>
					<div className="col-12 flex flex-justifyContent-center mb-20">
						<p>Click on the colors to copy the hex code and easily use them in your marketing materials.</p>
					</div>

					<div className="col-12 flex flex-justifyContent-spaceAround">
						{colors.map((color) => (
							<ColorBox key={color.code} color={color} />
						))}
					</div>
				</div>
			</div>
		</div>
	)
}
