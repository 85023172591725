import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'
import { Typography } from '@components/text/text'

// import Logo from './cinch-logo.jpg'
import warrantyImg from './warranty_plans.png'

export function LoKationResourceHomeWarrantyFl() {
	return (
		<div className="flex-lg overflow-y__scroll">
			<div className="flex flex-column flex-alignItems-start col-xs-12">
				<div className="text-center col-12">
					<Typography type="h2" margins={['bottom']}>
						Complete Home Protection Plan
					</Typography>
					<div className="flex flex-column flex-alignItems-center col-12">
						<div className="col-12 col-md-6">
							<img src={warrantyImg} style={{ maxWidth: '500px' }} />
						</div>
					</div>
				</div>
				<p>
					Protect your investment in your Florida home with Complete Home Protection. Cinch covers over 20
					major home appliances and systems for mechanical breakdowns from normal wear and tear. Complete
					Peace of Mind is our Goal!
				</p>
				<HorizontalRule className="my-20" />
				<div className="col-12 flex flex-justifyContent-spaceAround">
					<div className="col-4">
						<div className="flex flex-justifyContent-center">
							<strong>Option 1</strong>
						</div>
						<ol>
							<li>Download Application Below</li>
							<li>Email to Your Client or Complete Yourself</li>
							<li>Send Completed Application to enroll@cinchhs.com</li>
						</ol>
						<button
							className="button contained lg primary mt-10"
							onClick={() => {
								window.open('/pdf/FL-Application-Editable.pdf', '_blank')
							}}
						>
							{' '}
							Download Application
						</button>
					</div>
					<div className="col-4">
						<div className="flex flex-justifyContent-center">
							<strong>Option 2</strong>
						</div>

						<ol>
							<li>Enroll</li>
							<li>Choose "Agent" </li>
							<li>Add Member Broker Number on Step 2 of: 142451317</li>
							<li>
								Complete Rest of Form; Last Step is to Invoice Directly to Email Recipient of Your
								Choice
							</li>
						</ol>
						<button
							className="button contained lg primary mt-10"
							onClick={() => {
								window.open('https://enroll.cinchhomeservices.com/real-estate/', '_blank')
							}}
						>
							Start Enrollment
						</button>
					</div>
				</div>
				<HorizontalRule className="my-20" />
				<p>
					<strong>QUESTIONS? Contact Stephanie Now!</strong>
					<br />
					Stephanie Ramirez
					<br />
					<a href="mailto:sramirez@cinchhs.com">sramirez@cinchhs.com</a>
					<br />
					<a href="tel:219.682.7910">219.682.7910 </a>
				</p>
				<p>
					<strong>Our Coverage Includes:</strong>
					<ul>
						<li>Industry leading 180-day workmanship guarantee</li>
						<li>24/7 customer service - your way - online, via phone or even Alexa</li>
						<li>Cinch First Year Preventive Maintenance (Claim-No-Claim)</li>
						<li>Discounts on brand name appliances</li>
						<li>Locksmith service</li>
					</ul>
				</p>
				<p>
					<strong>Additional Values Include:</strong>
					<ul>
						<li>
							Emergency Lodging Reimbursement covering hotel stays should your HVAC fail<sup>+, 1, 2</sup>
						</li>
						<li>
							Homeowners Insurance Deductible Reimbursement<sup>+, 2, 3</sup>
						</li>
					</ul>
				</p>
				<p>
					We offer pricing options to fit sellers and buyers needs including our Cinch Preferred Plan with our
					most comprehensive coverage and a $50 discount.4
				</p>
				<p>
					Visit{' '}
					<a href="http://cinchrealestate.com/" target="_blank" rel="noreferrer">
						cinchrealestate.com
					</a>{' '}
					to learn more or to{' '}
					<a href="/pdf/FloridaLokationBrochure.pdf" target="_blank" rel="noreferrer">
						download a brochure
					</a>
				</p>
				<p style={{ fontSize: '75%' }}>
					+This benefit is provided by an unaffiliated third party under an insurance policy issued to Cinch
					Home Services, Inc., for the benefit of customers enrolled by Cinch Insurance Agency, Inc., an
					affiliate of HomeSure Services, Inc. and Cinch Home Services, Inc. 1Reimburses you up to $1,200 if
					(a) your only cooling or heating system is non-operational for 24 hours or more from the time of the
					first service visit should there be a delay in acquiring repair parts, or (b) a sudden break in a
					water pipe results in flooding and the removal of water from the residence by a water removal
					company is delayed by 24 hours. Only 1 claim, per member per 12-month period. Additional terms and
					conditions apply, which will be included in your plan materials you receive after enrollment. 2Buyer
					only coverage.3 Reimburses you up to $500 when you have to pay a deductible on a covered homeowners
					insurance claim. Only 1 claim per member per 12-month period. Additional terms and conditions apply,
					which will be included in your plan materials you receive after enrollment. 4Cinch Preferred Plan
					not available in all states. Excludes multi-family, multi-year plans and renewals. $50 discount
					applies to year one only and requires $200 deductible and includes the Premium Upgrade Package.
				</p>
			</div>
		</div>
	)
}
