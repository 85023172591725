import { useEffect, useState } from 'react'
import { Registration } from 'src/routes/registration/registration.types'
import { ChatGptSettingsAPI } from 'src/services/chat-gpt-settings/chat-gpt-settings.api'
import { ChatGptSettings } from 'src/services/chat-gpt-settings/chat-gpt-settings.types'
import { DOMAIN_ID, DomainAPI } from 'src/services/domain/domain.api'
import { Domain } from 'src/services/domain/domain.types'
import { HomepageModalSettingsAPI } from 'src/services/homepage-modal-settings/homepage-modal-settings.api'
import { HomepageModalSettings } from 'src/services/homepage-modal-settings/homepage-modal-settings.types'
import { LandingPageAPI } from 'src/services/landing-page/landing-page.api'
import { LoginCarouselAPI } from 'src/services/login-carousel/login-carousel.api'
import { LoginCarouselNode } from 'src/services/login-carousel/login-carousel.types'
import { MLSBoardAPI } from 'src/services/mls-board/mls-board.api'
import { MLSBoard } from 'src/services/mls-board/mls-board.types'
import { ReferralTypeAPI } from 'src/services/registration/referral-type.api'

import { DomainAdminInner } from './domain-inner'
import { DomainAdminProvider } from './state/domain-admin__state'
import { DomainAdminState } from './state/domain-admin__state.types'

export function DomainAdminProviderWrapper() {
	const [initialLocalState, setInitialLocalState] = useState<DomainAdminState.DomainAdminData | null>(null)

	useEffect(() => {
		loadDomainAdminState().then((res) => {
			setInitialLocalState(res)
		})
	}, [])

	function loadDomainAdminState(): Promise<DomainAdminState.DomainAdminData> {
		return new Promise<DomainAdminState.DomainAdminData>((resolve) => {
			let general: Domain.Properties | undefined = undefined
			let loginCarousel: LoginCarouselNode[] = []
			let mlsBoardOptions: MLSBoard.Complete[] = []
			let referralTypeOptions: Registration.ReferralTypeOption[] = []
			let landingPageResources: Domain.ResourceRef[] = []
			let landingPageHubs: Domain.HubRef[] = []
			let chatGptSettings: ChatGptSettings.CompleteResponse
			let homepageModalSettings: HomepageModalSettings.CompleteResponse

			const requestPromises: Promise<any>[] = []

			async function getAllMlsBoardOptions(page: number = 0, size: number = 20): Promise<MLSBoard.Complete[]> {
				const response = await MLSBoardAPI().getAllOptions({
					page,
					size,
					sort: [{ property: 'displayValue', direction: 'asc' }],
				})
				if (response.data.totalPages > page + 1) {
					return response.data.items.concat(await getAllMlsBoardOptions(page + 1, size))
				}
				return response.data.items
			}

			const mlsBoardPromise = getAllMlsBoardOptions().then((items: MLSBoard.Complete[]) => {
				mlsBoardOptions = items
			})
			requestPromises.push(mlsBoardPromise)

			const referralTypePromise = ReferralTypeAPI()
				.getAllOptions({ page: 0, size: 20, sort: [{ property: 'displayValue', direction: 'asc' }] })
				.then((res) => {
					referralTypeOptions = res.data.items
				})
			requestPromises.push(referralTypePromise)

			const loginCarouselPromise = LoginCarouselAPI.getNodes({ page: 0, size: 20 }).then((res) => {
				loginCarousel = res.data.items
			})
			requestPromises.push(loginCarouselPromise)

			const generalPropsPromise = DomainAPI.get(DOMAIN_ID).then((res) => {
				general = res.data
			})
			requestPromises.push(generalPropsPromise)

			const landingPageHubPromise = LandingPageAPI.getHubs(DOMAIN_ID).then((res) => {
				landingPageHubs = res.data.items
			})
			requestPromises.push(landingPageHubPromise)

			const landingPageResourcePromise = LandingPageAPI.getResources(DOMAIN_ID).then((res) => {
				landingPageResources = res.data.items
			})
			requestPromises.push(landingPageResourcePromise)

			const chatGptSettingsPromise = ChatGptSettingsAPI.get(DOMAIN_ID).then((res) => {
				chatGptSettings = res.data
			})
			requestPromises.push(chatGptSettingsPromise)

			const homepageModalSettingsPromise = HomepageModalSettingsAPI.get(DOMAIN_ID).then((res) => {
				console.log('Modal: ', res)
				homepageModalSettings = res.data
			})
			requestPromises.push(homepageModalSettingsPromise)

			Promise.all(requestPromises).then((res) => {
				if (general) {
					const state: DomainAdminState.DomainAdminData = {
						general,
						loginCarousel,
						mlsBoardOptions,
						referralTypeOptions,
						landingPageHubs,
						landingPageResources,
						chatGptSettings,
						homepageModalSettings,
					}

					resolve(state)
				}
			})
		})
	}

	if (!initialLocalState) {
		return <></>
	}

	return (
		<DomainAdminProvider initialLocalState={initialLocalState}>
			<DomainAdminInner />
		</DomainAdminProvider>
	)
}
