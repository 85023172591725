import { Button } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { SideSheet } from '@components/side-sheet/side-sheet'
import { SideSheetServices } from '@components/side-sheet/side-sheet.service'
import { SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import { SideSheet__Row } from '@components/side-sheet/side-sheet__row'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { PhoneInput } from '@components/text-input/phone-input'
import { TextInput } from '@components/text-input/text-input'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { GenericDeleteConfirmationModal } from 'src/modals/generic-delete-confirmation/generic-delete-confirmation'
import { LicensedStatesService } from 'src/services/licensed-states/licensed-states.service'
import { LicensedStateEnums } from 'src/services/licensed-states/licenses-states.types'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

export type ComponentProps = {
	onClose: () => void
	onUpdate: (updatedLocation: TransactionMgmtTypes.LocationTypes.Location) => void
	onDelete: () => void
	locationId: number | 'NEW'
	showParentTransaction: boolean
	mode: 'EDITOR' | 'END_USER'
}

export function TransactionMgmt__Location__SideSheet(props: ComponentProps) {
	const [originalLocation, setOriginalLocation] = useState<TransactionMgmtTypes.LocationTypes.Location | null>(null)
	const [modifiedLocation, setModifiedLocation] = useState<TransactionMgmtTypes.LocationTypes.Location | null>(null)
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)

	const isReadOnly = props.mode === 'END_USER'
	const didUserModifyProps = !_.isEqual(modifiedLocation, originalLocation)

	useEffect(() => {
		if (props.locationId === 'NEW') {
			setModifiedLocation(TransactionManagementService.getDefaultLocationProps())
		} else if (typeof props.locationId === 'number') {
			TransactionManagementAPI.getLocationById(props.locationId).then((res) => {
				if (res.data) {
					setOriginalLocation(res.data)
					setModifiedLocation(res.data)
				}
			})
		}
	}, [])

	function createFooter(): React.ReactNode {
		return (
			<>
				<Button
					variant={'outlined'}
					label={'Cancel'}
					size={'md'}
					className="mr-10"
					onClick={() => {
						props.onClose()
					}}
				/>
				<Button
					variant={'contained'}
					label={'Save and Close'}
					size={'md'}
					onClick={() => {
						handleSaveLocation().then(() => {
							if (modifiedLocation) {
								props.onClose()
								props.onUpdate(modifiedLocation)
							}
						})
					}}
				/>
			</>
		)
	}

	function createActions(): SideSheetTypes.Action[] {
		const actions: SideSheetTypes.Action[] = []

		actions.push({
			label: `Delete`,
			icon: 'trash',
			onClick: () => {
				setShowDeleteConfirmationModal(true)
			},
		})

		return actions
	}

	function onScrimClick(): void {
		if (!didUserModifyProps) {
			props.onClose()
			return
		}

		SideSheetServices.showDismissConfirmationToast({
			onDismiss: () => {
				return new Promise((resolve) => {
					resolve()
					props.onClose()
				})
			},
			onSaveAndClose: () => {
				return handleSaveLocation().then(() => {
					if (modifiedLocation) {
						props.onClose()
						props.onUpdate(modifiedLocation)
					}
				})
			},
		})
	}

	function handleSaveLocation(): Promise<void> {
		return new Promise((resolve) => {
			if (modifiedLocation && modifiedLocation.locationId < 0) {
				/** Create New Location */
			} else {
				console.log(modifiedLocation)
				/** Update Existing Location */
			}
		})
	}

	function updateLocation(updatedProps: Partial<TransactionMgmtTypes.LocationTypes.Location>): void {
		if (!modifiedLocation) {
			return
		}
		const updatedLocation: TransactionMgmtTypes.LocationTypes.Location = {
			...modifiedLocation,
			...updatedProps,
		}
		setModifiedLocation(updatedLocation)
	}

	return (
		<>
			<SideSheet
				title={'Location'}
				actions={createActions()}
				onClose={props.onClose}
				persistent={false}
				footer={createFooter()}
				preventDefaultScrimClick={didUserModifyProps}
				onScrimClick={onScrimClick}
			>
				{modifiedLocation && (
					<>
						<SideSheet__Section
							label="Details"
							icon={'cog'}
							mode="expandable"
							sectionKey="location__general"
						>
							<SideSheet__Row label="Name" direction="row">
								<TextInput
									type="text"
									dataType="text"
									value={modifiedLocation.name}
									disabled={isReadOnly}
									width={'300px'}
									onChange={(updatedValue) => {
										updateLocation({ name: updatedValue })
									}}
								/>
							</SideSheet__Row>
						</SideSheet__Section>
						<SideSheet__Section
							label="Address"
							icon={'location'}
							mode="expandable"
							sectionKey="location__address"
						>
							<SideSheet__Row label="Street Address" direction="column">
								<TextInput
									type="text"
									dataType="text"
									value={modifiedLocation.address1}
									disabled={isReadOnly}
									width={'375px'}
									onChange={(updatedValue) => {
										updateLocation({ address1: updatedValue })
									}}
								/>
							</SideSheet__Row>

							<SideSheet__Row label="Address 2" direction="column">
								<TextInput
									type="text"
									dataType="text"
									value={modifiedLocation.address2}
									disabled={isReadOnly}
									width={'375px'}
									onChange={(updatedValue) => {
										updateLocation({ address2: updatedValue })
									}}
								/>
							</SideSheet__Row>

							<SideSheet__Row label="City" direction="column">
								<TextInput
									type="text"
									dataType="text"
									value={modifiedLocation.city}
									disabled={isReadOnly}
									width={'375px'}
									onChange={(updatedValue) => {
										updateLocation({ city: updatedValue })
									}}
								/>
							</SideSheet__Row>

							<SideSheet__Row label="State" direction="column">
								<Dropdown<LicensedStateEnums>
									width={375}
									options={LicensedStatesService.getStateOptions()}
									value={modifiedLocation.state ? [modifiedLocation.state] : []}
									disabled={isReadOnly}
									onSelect={(options) => {
										if (options && options.length > 0) {
											updateLocation({ state: options[0] })
										}
									}}
								/>
							</SideSheet__Row>

							<SideSheet__Row label="ZIP Code" direction="column">
								<TextInput
									type="text"
									dataType="number"
									value={modifiedLocation.zipCode}
									disabled={isReadOnly}
									width={'375px'}
									onChange={(updatedValue) => {
										if (typeof updatedValue !== 'number') {
											return
										}
										updateLocation({ zipCode: updatedValue })
									}}
								/>
							</SideSheet__Row>
						</SideSheet__Section>

						<SideSheet__Section
							label="Broker Information"
							icon={'person'}
							mode="expandable"
							sectionKey="location__broker"
						>
							<SideSheet__Row label="Full Name" direction="column">
								<TextInput
									type="text"
									dataType="text"
									value={modifiedLocation.brokerFullName}
									disabled={isReadOnly}
									width={'375px'}
									onChange={(updatedValue) => {
										updateLocation({ brokerFullName: updatedValue })
									}}
								/>
							</SideSheet__Row>

							<SideSheet__Row label="Phone Number" direction="column">
								<PhoneInput
									type="text"
									dataType="number"
									value={modifiedLocation.brokerPhone}
									disabled={isReadOnly}
									width={'375px'}
									onChange={(updatedValue) => {
										if (typeof updatedValue !== 'number') {
											return
										}
										updateLocation({ brokerPhone: updatedValue })
									}}
								/>
							</SideSheet__Row>

							<SideSheet__Row label="Email" direction="column">
								<TextInput
									type="text"
									dataType="text"
									value={modifiedLocation.brokerEmail}
									disabled={isReadOnly}
									width={'375px'}
									onChange={(updatedValue) => {
										updateLocation({ brokerEmail: updatedValue })
									}}
								/>
							</SideSheet__Row>
						</SideSheet__Section>
					</>
				)}
			</SideSheet>

			{showDeleteConfirmationModal && modifiedLocation && (
				<GenericDeleteConfirmationModal
					itemLabel={modifiedLocation.name}
					onDelete={async () => {
						/** Delete Location */
					}}
					onClose={() => {
						setShowDeleteConfirmationModal(false)
					}}
				/>
			)}
		</>
	)
}
