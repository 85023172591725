import { Button } from '@components/button/button'
import { LoginRouteWrapper } from '@components/login-route-wrapper/login-route-wrapper'
import { Typography } from '@components/text/text'
import { getUserProps } from '@redux/reducers/user-reducer'
import { RootState, store } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'

import { LeadCaptureAPI } from '../../services/lead-capture/lead-capture.api'
import { EndUserProps } from '../../services/user/user.types'

function CreateAccountSuccessRoutePrototype(props: { userProfile: EndUserProps | null }) {
	const navigate = useNavigate()

	localStorage.removeItem(`promotionCode`)

	function handleClick(): Promise<void> {
		return new Promise<void>((resolve) => {
			if (props.userProfile) {
				store.dispatch(getUserProps({ userId: props.userProfile?.endUserId })).then(() => {
					resolve()
					navigate('/hubs')
				})
			}
		})
	}

	const [completedSteps, setCompletedSteps] = useState({
		firstName: '',
		lastName: '',
		email: '',
		state: '',
		personalInfoCompleted: false,
		agentInfoCompleted: false,
		contractCompleted: false,
		optionalServiceCompleted: false,
		paymentCompleted: false,
	})

	useEffect(() => {
		let newCompletedSteps = { ...completedSteps }
		const currentUserProps = props.userProfile

		if (currentUserProps) {
			newCompletedSteps.firstName = currentUserProps.firstName
			newCompletedSteps.lastName = currentUserProps.lastName
			newCompletedSteps.email = currentUserProps.email
			newCompletedSteps.state = currentUserProps.licenseInformation[0].licensedState.abbreviation
			newCompletedSteps.personalInfoCompleted = true
			newCompletedSteps.agentInfoCompleted = true
			newCompletedSteps.contractCompleted = true
			newCompletedSteps.optionalServiceCompleted = true
			newCompletedSteps.paymentCompleted = true
		}

		// Update local state
		setCompletedSteps(newCompletedSteps)

		// Send to API
		LeadCaptureAPI.capturelead(newCompletedSteps)
			.then(() => {
				// Handle success, maybe navigate to a "Thank You" page
			})
			.catch((error) => {
				console.log(error)
			})
	}, [])

	return (
		<LoginRouteWrapper
			isLoginRoute={true}
			bodyClassName="flex flex-column flex-justifyContent-center flex-fillSpace flex-alignItems-center col-xs-12"
		>
			<div className="flex flex-alignItems-center flex-column clamp-width-for-login-routes">
				<div className="flex-fillSpace flex flex-column flex-alignItems-start flex-justifyContent-center">
					<Typography type="h1">
						Congratulations on joining the Sphere
						{props.userProfile ? ', ' + props.userProfile.firstName + '!' : '!'}
					</Typography>
					<p className="mb-40">
						Your account has been successfully created. You can now access your Hub and start utilizing our
						services.
					</p>
					<Button label="Enter the Sphere" variant="contained" size={'lg'} onClick={handleClick} />
				</div>
			</div>
		</LoginRouteWrapper>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
	}
}

export default connect(mapStateToProps)(CreateAccountSuccessRoutePrototype)
