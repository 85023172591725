import { CollapsibleContent } from '@components/collapsible-content/collapsible-content'
import { MultiColumnTableTypes } from '@components/multi-column-table/multi-column-table.types'
import { MultiColumnTable } from '@components/multi-column-table/multi-column-table__wrapper'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { useState } from 'react'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'
import { Utilities } from 'src/services/utilities.service'

import { TransactionMgmt__TransactionContact__SideSheet } from '../../components/contact-side-sheet/contact.side-sheet'
import { useTransaction, useTransactionDispatch } from '../../state/transaction__state'

export function TransactionMgmt__Transaction__Overview__Contacts() {
	const transactionState = useTransaction()
	const transactionDispatch = useTransactionDispatch()

	const [showExistingContactSideSheet, setShowExistingContactSideSheet] = useState(false)
	const [selectedContact, setSelectedContact] = useState<TransactionMgmtTypes.TransactionTypes.Contact | null>(null)
	const [showNewContactSideSheet, setShowNewContactSideSheet] = useState(false)

	function getTableColumns(): MultiColumnTableTypes.Column<TransactionMgmtTypes.TransactionTypes.Contact>[] {
		const columns: MultiColumnTableTypes.Column<TransactionMgmtTypes.TransactionTypes.Contact>[] = []

		columns.push({
			label: `Name`,
			value: `fullName`,
			columnId: `name`,
			width: `fill`,
			displayIndex: 0,
			visible: true,
		})

		columns.push({
			label: `Email`,
			value: `email`,
			columnId: `email`,
			width: 300,
			align: 'left',
			displayIndex: 1,
			visible: true,
		})

		columns.push({
			label: `Phone`,
			value: `phone`,
			displayedValue: (contact) => {
				return Utilities.formatPhoneNum(String(contact.phone))
			},
			columnId: `phone`,
			width: 200,
			align: 'left',
			displayIndex: 2,
			visible: true,
		})

		columns.push({
			label: `Role`,
			value: `type`,
			displayedValue: (contact) => {
				return TransactionManagementService.getLabelForContactType(contact.type)
			},
			columnId: `type`,
			width: 100,
			align: 'left',
			displayIndex: 3,
			visible: true,
		})

		return columns
	}

	function handleSelectItem(item: TransactionMgmtTypes.TransactionTypes.Contact[]): void {
		if (item.length === 0) {
			return
		}
		setSelectedContact(item[0])
		setShowExistingContactSideSheet(true)
	}

	return (
		<>
			<CollapsibleContent
				label="Contacts"
				labelSize="h5"
				controlled={true}
				id={`transaction__contacts`}
				headerPadding={[]}
				buttons={[
					{
						variant: 'outlined',
						size: 'sm',
						label: 'Add Contact',
						onClick: () => {
							setSelectedContact(null)
							setShowNewContactSideSheet(true)
						},
					},
				]}
			>
				<Paper elevate={true} bgColor="white" padding={['all']} className="col-xs-12 flex flex-wrap ">
					{transactionState.transaction.contacts.length === 0 && (
						<Typography type="semibold" variant={['italic']} color="disabled">
							This transaction has no contacts
						</Typography>
					)}
					{transactionState.transaction.contacts.length > 0 && (
						<MultiColumnTable<TransactionMgmtTypes.TransactionTypes.Contact>
							columns={getTableColumns()}
							idKey={'contactId'}
							className="col-xs-12"
							items={transactionState.transaction.contacts}
							isConfigurable={false}
							onSelect={handleSelectItem}
							selectBehavior={'single'}
							selectedItems={[]}
							sortBehavior={{
								sortMethod: 'column-value',
								columnId: 'name',
							}}
							tableId={''}
						/>
					)}
				</Paper>
			</CollapsibleContent>

			{/* Side Sheet for creating new contact */}
			{!selectedContact && showNewContactSideSheet && (
				<TransactionMgmt__TransactionContact__SideSheet
					onClose={() => {
						setShowNewContactSideSheet(false)
					}}
					onUpdate={(newContact) => {
						transactionDispatch({ type: 'add-contact', payload: newContact })
						setShowNewContactSideSheet(false)
					}}
					onDelete={() => {
						setShowNewContactSideSheet(false)
					}}
					contact={TransactionManagementService.generateNewContactProps('BUYER')}
					parentTransactionId={transactionState.transaction.transactionId}
					key={'NEW'}
				/>
			)}

			{/* Side sheet for updating an existing contact */}
			{selectedContact && showExistingContactSideSheet && (
				<TransactionMgmt__TransactionContact__SideSheet
					onClose={() => {
						setShowExistingContactSideSheet(false)
					}}
					onUpdate={(updatedContact) => {
						transactionDispatch({ type: 'update-contact', payload: updatedContact })
						setShowExistingContactSideSheet(false)
					}}
					onDelete={(contactId) => {
						transactionDispatch({ type: 'remove-contact', payload: contactId })
						setShowExistingContactSideSheet(false)
					}}
					contact={selectedContact}
					parentTransactionId={transactionState.transaction.transactionId}
					key={selectedContact.contactId}
				/>
			)}
		</>
	)
}
