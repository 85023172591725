import { MultiColumnTableTypes } from '@components/multi-column-table/multi-column-table.types'
import { MultiColumnTable } from '@components/multi-column-table/multi-column-table__wrapper'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { RoutingService } from 'src/services/routing/routing.service'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { EndUserProps } from 'src/services/user/user.types'

import { TransactionMgmtTypes } from './../../../services/transaction-mgmt/transaction-mgmt.types'
import { TransactionMgmt__Transactions__SearchFilters } from './search-filters'
import { TransactionMgmt__Transactions__Types } from './transactions.types'

function TransactionMgmt__Transactions__RoutePrototype(props: { userProfile: EndUserProps | null }) {
	const navigate = useNavigate()

	const [searchFilters, setSearchFilters] = useState<TransactionMgmt__Transactions__Types.SearchFilters>({
		side: [],
		licensedStateIds: [],
		status: [],
		searchString: '',
		type: [],
		includeCloseDate: false,
		closeDateEnd: null,
		closeDateStart: null,
		includeDateAdded: false,
		dateAddedEnd: null,
		dateAddedStart: null,
	})

	const [allTransactions, setAllTransactions] = useState<TransactionMgmtTypes.TransactionTypes.Transaction[] | null>(
		null,
	)

	useEffect(() => {
		TransactionManagementAPI.findTransactions().then((res) => {
			setAllTransactions(res.data)
		})
	}, [])

	function getTransactionTableColumns(): MultiColumnTableTypes.Column<TransactionMgmtTypes.TransactionTypes.Transaction>[] {
		const columns: MultiColumnTableTypes.Column<TransactionMgmtTypes.TransactionTypes.Transaction>[] = []

		columns.push({
			label: 'Name',
			columnId: 'name',
			value: 'name',
			displayIndex: 0,
			visible: true,
			width: 'fill',
		})

		columns.push({
			label: 'Type',
			columnId: 'type',
			value: 'type',
			displayedValue: (item) => {
				return TransactionManagementService.getLabelForTransactionType(item.type)
			},
			displayIndex: 1,
			visible: true,
			width: 200,
		})

		columns.push({
			label: 'Status',
			columnId: 'status',
			value: 'status',
			displayedValue: (item) => {
				return TransactionManagementService.getLabelForTransactionStatus(item.status)
			},
			displayIndex: 2,
			visible: true,
			width: 200,
		})

		columns.push({
			label: 'Sale Price',
			columnId: 'salePrice',
			value: 'salePrice',
			displayIndex: 3,
			visible: true,
			width: 200,
		})

		columns.push({
			label: 'Close Date',
			columnId: 'closeDate',
			value: 'closeDate',
			displayIndex: 4,
			visible: true,
			width: 200,
		})

		return columns
	}

	return (
		<RouteWrapperV2>
			<RouteSubnavigation
				title={`Transactions`}
				backButton={{
					label: 'Transaction Management',
					onClick: () => {
						navigate(`${RoutingService.transactionMgmtRoutePath}`)
					},
				}}
				isCustom={true}
			>
				<TransactionMgmt__Transactions__SearchFilters
					searchFilters={searchFilters}
					setSearchFilters={setSearchFilters}
				/>
			</RouteSubnavigation>

			<RouteBodyV2 className="flex flex-column flex-alignItems-start">
				<MultiColumnTable<TransactionMgmtTypes.TransactionTypes.Transaction>
					columns={getTransactionTableColumns()}
					idKey={'transactionId'}
					className="col-xs-12"
					items={allTransactions}
					isConfigurable={false}
					onSelect={(transaction) => {
						/** empty */
					}}
					onDoubleClick={(transaction) => {
						navigate(`${RoutingService.transactionMgmtRoutePath}/transaction/${transaction.transactionId}`)
					}}
					selectBehavior={'single'}
					selectedItems={[]}
					sortBehavior={{
						sortMethod: 'column-value',
						columnId: 'name',
					}}
					tableId={''}
				/>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
	}
}

export const TransactionMgmt__Transactions__Route = connect(mapStateToProps)(
	TransactionMgmt__Transactions__RoutePrototype,
)
