import { Button } from '@components/button/button'
import { Checkbox } from '@components/checkbox/checkbox'
import { IconButton } from '@components/icon-button/icon-button'
import { Paper } from '@components/paper/paper'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { RootState } from '@redux/store'
import _ from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { GenericItemSelectorModal } from 'src/modals/generic-item-selector/generic-item-selector'
import { LocationSelectorModal } from 'src/modals/location-selector/location-selector'
import { LicensedStatesService } from 'src/services/licensed-states/licensed-states.service'
import { LicensedState } from 'src/services/licensed-states/licenses-states.types'
import { LicensedRegionsService } from 'src/services/regions/regions.service'
import { LicensedRegion } from 'src/services/regions/regions.types'
import { SpecialtyService } from 'src/services/specialties/specialty.service'
import { LokationSpecialty } from 'src/services/specialties/specialty.types'

import { useAdEditor, useAdEditorDispatch } from './state/ad-editor__state'

type ConnectedProps = {
	specialties: LokationSpecialty[]
	licensedStates: LicensedState[]
	licensedRegions: LicensedRegion[]
}

function Ad__SideSheet__Tab__AudiencePrototype(props: ConnectedProps) {
	const adEditorState = useAdEditor()
	const adEditorDispatch = useAdEditorDispatch()
	const [showSpecialtyModal, setShowSpecialtyModal] = useState(false)
	const [showLocationModal, setShowLocationModal] = useState(false)
	const selectedAdLocations = LicensedStatesService.getStatesFromIds(
		props.licensedStates,
		adEditorState.modifiedAd.audienceLocations,
	)
	const selectedAdRegions = LicensedRegionsService.getRegionsFromIds(
		props.licensedRegions,
		adEditorState.modifiedAd.audienceRegions,
	)
	const selectedAdSpecialties = SpecialtyService.getSpecialtiesFromIds(
		props.specialties,
		adEditorState.modifiedAd.audienceSpecialties,
	)

	/** ================================ */
	/** Methods */

	function createLocationListItem(item: LicensedState): React.ReactNode {
		return (
			<Paper
				bgColor="primary"
				padding={['all']}
				key={`state__${item.licensedStateId}`}
				className="flex flex-alignItems-center flex-justifyContent-spaceBetween col-xs-12"
			>
				<strong>{item.stateName}</strong>
				<IconButton
					icon="times"
					onClick={() => {
						removeLocation(item)
					}}
				/>
			</Paper>
		)
	}

	function createRegionListItem(item: LicensedRegion): React.ReactNode {
		return (
			<Paper
				bgColor="primary"
				padding={['all']}
				key={`region__${item.regionName}`}
				className="flex flex-alignItems-center flex-justifyContent-spaceBetween col-xs-12"
			>
				<strong>{item.regionName}</strong>
				<IconButton
					icon="times"
					onClick={() => {
						removeRegion(item)
					}}
				/>
			</Paper>
		)
	}

	/** Removes a single location from the selected locations list */
	function removeLocation(location: LicensedState): void {
		let updatedLocations = _.cloneDeep(adEditorState.modifiedAd.audienceLocations)
		updatedLocations = updatedLocations.filter((thisLocation) => thisLocation !== location.licensedStateId)
		adEditorDispatch({ type: 'update-property', payload: { audienceLocations: updatedLocations } })
	}

	/** Removes a single region from the selected locations list */
	function removeRegion(region: LicensedRegion): void {
		let updatedRegions = _.cloneDeep(adEditorState.modifiedAd.audienceRegions)
		updatedRegions = updatedRegions.filter((thisRegion) => thisRegion !== region.regionId)
		adEditorDispatch({ type: 'update-property', payload: { audienceRegions: updatedRegions } })
	}

	function createSpecialtyListItem(item: LokationSpecialty, disabled?: boolean): React.ReactNode {
		return (
			<Paper
				bgColor="primary"
				padding={['all']}
				key={`specialty__${item.specialtyId}`}
				className={`flex flex-alignItems-center flex-justifyContent-spaceBetween col-xs-12 ${disabled ? 'is-disabled' : ''} `}
			>
				<strong>{item.specialtyName}</strong>
				<IconButton
					icon="times"
					onClick={() => {
						removeSpecialty(item)
					}}
				/>
			</Paper>
		)
	}

	/** Removes a single specialty from the selected specialties list */
	function removeSpecialty(specialty: LokationSpecialty): void {
		let updatedSpecialties = _.cloneDeep(adEditorState.modifiedAd.audienceSpecialties)
		updatedSpecialties = updatedSpecialties.filter((thisSpecialty) => thisSpecialty !== specialty.specialtyId)
		adEditorDispatch({ type: 'update-property', payload: { audienceSpecialties: updatedSpecialties } })
		if (updatedSpecialties.length === 0) {
			adEditorDispatch({
				type: 'update-property',
				payload: { specialtyInclusion: 'ALL' },
			})
		}
	}

	/** ================================ */
	/** Render Component */

	return (
		<>
			<strong>Target your audience by defining who will see your ads</strong>
			<SideSheet__Section label="Location" icon="map-location" mode="expandable" sectionKey="account__location">
				<div className="mb-10">
					<strong>Select each location that this ad will appear in</strong>
				</div>
				{adEditorState.modifiedAd.audienceLocations.length === 0 &&
					adEditorState.modifiedAd.audienceRegions.length === 0 && <em>No locations have been selected</em>}
				{selectedAdLocations.map((location) => {
					return createLocationListItem(location)
				})}
				{adEditorState.modifiedAd.audienceRegions.length > 0 && (
					<div className="mt-10">
						<strong>Regions</strong>
					</div>
				)}
				{selectedAdRegions.map((region) => {
					return createRegionListItem(region)
				})}
				<Button
					variant="outlined"
					label="Add Location"
					size="md"
					margins={['top']}
					className="col-xs-12"
					onClick={() => {
						setShowLocationModal(true)
					}}
				/>
			</SideSheet__Section>
			<SideSheet__Section label="Specialty" icon="star" mode="expandable" sectionKey="account__specialty">
				<div className="mb-10">
					<strong>Select specialties to filter this ad based off the user's own specialties</strong>
				</div>
				<Checkbox
					checked={adEditorState.modifiedAd.specialtyInclusion !== 'ALL'}
					onChange={(checked) => {
						adEditorDispatch({
							type: 'update-property',
							payload: {
								specialtyInclusion: checked ? 'INCLUSIVE' : 'ALL',
							},
						})
					}}
					style={{ marginBottom: '8px' }}
				>
					Filter Based on Specialty
				</Checkbox>
				{adEditorState.modifiedAd.audienceSpecialties.length === 0 && (
					<em>No specialties have been selected</em>
				)}
				{selectedAdSpecialties.map((specialty) => {
					return createSpecialtyListItem(specialty, adEditorState.modifiedAd.specialtyInclusion === 'ALL')
				})}
				<Button
					variant="outlined"
					label="Add Specialty"
					size="md"
					margins={['top']}
					className="col-xs-12"
					onClick={() => {
						setShowSpecialtyModal(true)
						adEditorDispatch({
							type: 'update-property',
							payload: {
								specialtyInclusion:
									adEditorState.modifiedAd.specialtyInclusion === 'ALL'
										? 'INCLUSIVE'
										: adEditorState.modifiedAd.specialtyInclusion,
							},
						})
					}}
				/>
			</SideSheet__Section>

			{showSpecialtyModal && (
				<GenericItemSelectorModal<LokationSpecialty>
					title={`Select Specialities`}
					description={`Select which specialties you would like your ad to target`}
					zIndex={1000}
					items={props.specialties}
					selectedItems={selectedAdSpecialties}
					itemLabelKey={'specialtyName'}
					itemIdKey={'specialtyId'}
					onSelect={(selectedItems) => {
						const selectedSpecialtyIds = selectedItems.map((location) => location.specialtyId)
						adEditorDispatch({
							type: 'update-property',
							payload: {
								audienceSpecialties: selectedSpecialtyIds,
							},
						})
					}}
					radioGroupOptions={{
						options: SpecialtyService.getFilterOptions(),
						selectedVisibility: adEditorState.modifiedAd.specialtyInclusion,
						onVisibilitySelect: (selectedVisibility) => {
							adEditorDispatch({
								type: 'update-property',
								payload: { specialtyInclusion: selectedVisibility },
							})
						},
					}}
					onClose={() => {
						setShowSpecialtyModal(false)
					}}
				/>
			)}

			{showLocationModal && (
				<LocationSelectorModal
					title={`Select Locations`}
					description={`Select which locations you would like your ad to target`}
					zIndex={1000}
					selectedStates={selectedAdLocations}
					selectedRegions={selectedAdRegions}
					onSelect={(selectedStates, selectedRegions) => {
						const selectedLocationIds = selectedStates.map((location) => location.licensedStateId)
						const selectedRegionIds = selectedRegions.map((region) => region.regionId)
						adEditorDispatch({
							type: 'update-property',
							payload: { audienceLocations: selectedLocationIds, audienceRegions: selectedRegionIds },
						})
					}}
					onClose={() => {
						setShowLocationModal(false)
					}}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		specialties: state.specialties,
		licensedStates: state.licensedStates,
		licensedRegions: state.licensedRegions,
	}
}

export const Ad__SideSheet__Tab__Audience = connect(mapStateToProps)(Ad__SideSheet__Tab__AudiencePrototype)
