import { Checkbox } from '@components/checkbox/checkbox'
import { ColorChip } from '@components/color-chip/color-chip'
import { FontAwesomeIconSelectorTile } from '@components/fa-icon-selector-tile/fa-icon-selector-tile'
import { StateSelectorDropdown } from '@components/state-selector-dropdown/state-selector-dropdown'
import { Switch } from '@components/switch/switch'
import { TagSelectorDropdown } from '@components/tag-selector-dropdown/tag-selector-dropdown'
import { TextInput } from '@components/text-input/text-input'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { store } from '@redux/store'
import { useState } from 'react'

import { EditHubSectionProps } from './edit-hub'

export function EditHubGeneralSection(props: EditHubSectionProps) {
	const [isGroupedByTag, setIsGroupedByTag] = useState(props.mergedHubProps.groupByTag ?? false)
	const [isPublished, setIsPublished] = useState(props.mergedHubProps.published ?? false)
	const [isPublic, setIsPublic] = useState(props.mergedHubProps.isPublic ?? false)

	const handleSwitchChange = () => {
		setIsGroupedByTag((prevState) => !prevState)
		props.updateHubProp('groupByTag', !isGroupedByTag)
	}

	const handlePublishedChange = () => {
		setIsPublished((prevState) => !prevState)
		props.updateHubProp('published', !isPublished)
	}

	const handleIsPublicChange = () => {
		setIsPublic((prevState) => !prevState)
		props.updateHubProp('isPublic', !isPublic)
	}

	return (
		<>
			<TextInput
				label="Title"
				dataType="text"
				value={props.mergedHubProps.title}
				width="100%"
				onChange={(updatedValue) => {
					props.updateHubProp('title', updatedValue)
				}}
				margins={['bottom']}
			/>

			<TextInput
				label="Description"
				dataType="text"
				value={props.mergedHubProps.description}
				width="100%"
				rows={3}
				margins={['bottom']}
				onChange={(updatedValue) => {
					props.updateHubProp('description', updatedValue)
				}}
			/>

			<div className="mb-20 flex flex-alignItems-center">
				<strong className="mr-10">Color</strong>
				<ColorChip
					color={props.mergedHubProps.color}
					handleChange={(color) => {
						props.updateHubProp('color', color)
					}}
					showSuggestions={true}
				/>
			</div>

			<TextInput
				label="Hub URL Slug"
				dataType="text"
				value={props.mergedHubProps.slug}
				width="100%"
				rows={1}
				margins={['bottom']}
				onChange={(updatedValue) => {
					props.updateHubProp('slug', updatedValue)
				}}
			/>

			<TextInput
				label="Header Background Image"
				dataType="text"
				value={props.mergedHubProps.headerBgImage}
				width="100%"
				rows={1}
				margins={['bottom']}
				onChange={(updatedValue) => {
					props.updateHubProp('headerBgImage', updatedValue)
				}}
			/>

			<strong>Icon</strong>
			<FontAwesomeIconSelectorTile
				className="mb-20 mt-5"
				icon={props.mergedHubProps.icon ? (props.mergedHubProps.icon as IconName) : null}
				onSelect={(icon) => {
					props.updateHubProp('icon', icon)
				}}
				onRemove={() => {
					props.updateHubProp('icon', '')
				}}
			/>

			<TextInput
				label="Display Order"
				dataType="number"
				value={props.mergedHubProps.displayOrder}
				width={100}
				rows={1}
				margins={['bottom']}
				onChange={(updatedValue) => {
					props.updateHubProp('displayOrder', updatedValue)
				}}
			/>

			<div className="flex flex-alignItems-center mb-20">
				<Switch className="mx-5" checked={isPublished} onChange={handlePublishedChange} />
				<span>
					<strong>{isPublished ? 'Published' : 'Unpublished'}</strong>
				</span>
			</div>

			<div className="flex flex-alignItems-center mb-20">
				<Switch className="mx-5" checked={isGroupedByTag} onChange={handleSwitchChange} />
				<span>
					<strong>{isGroupedByTag ? 'Grouped by tag' : 'Not grouped by tag'}</strong>
				</span>
			</div>

			<div className="flex flex-alignItems-center mb-20">
				<Switch className="mx-5" checked={isPublic} onChange={handleIsPublicChange} />
				<span>
					<strong>{isPublic ? 'Public' : 'Private'}</strong>
				</span>
			</div>

			<div className="mb-20 flex flex-column">
				<strong className="mb-5">Applicable States</strong>
				<Checkbox
					checked={props.mergedHubProps.stateAgnostic}
					onChange={(checked) => {
						props.updateHubProp('stateAgnostic', checked)
					}}
					style={{ marginBottom: '8px' }}
				>
					This is applicable to users in all states
				</Checkbox>
				<StateSelectorDropdown
					multiselect={true}
					width={`100%`}
					selectedStates={props.mergedHubProps.licensedStates}
					options={store.getState().licensedStates}
					onSelect={(selectedStates) => {
						props.updateHubProp('licensedStates', selectedStates)
					}}
					disabled={props.mergedHubProps.stateAgnostic}
				/>
			</div>

			<div className="mb-20 flex flex-column pb-20">
				<strong className="mb-5">Tags</strong>
				<TagSelectorDropdown
					selectedTags={props.mergedHubProps.tags}
					options={store.getState().tags}
					onSelect={(selectedTags) => {
						props.updateHubProp('tags', selectedTags)
					}}
				/>
			</div>
		</>
	)
}
