import { Button } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'
import { useAppDispatch } from '@redux/hooks'
import { getUserProps } from '@redux/reducers/user-reducer'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { UserService } from 'src/services/user/user.service'

import { StripeAPI } from '../../../../../services/stripe/stripe.api'
import { EndUserProps } from '../../../../../services/user/user.types'
import { useUserProfile } from '../../state/user-profile__state'

interface SubscribeToServiceProps {
	modalId: string
	productPriceIds: string[]
	title: string
	price: string
	initialTotal?: number
	initialRecurring?: number
	subscriptionType: string
	dismissModal: () => void
	userToEdit?: EndUserProps
	currentUser?: EndUserProps | null
}

function SubscribeToServicePrototype(props: SubscribeToServiceProps) {
	const dispatch = useAppDispatch()
	const userProfileState = useUserProfile()
	const canUserEdit = props.currentUser ? UserService.isUserAdmin(props.currentUser) : false
	const [endUserId, setEndUserId] = useState<number | undefined>(undefined)

	const {
		productPriceIds: [recurringPriceId, optionalRecurringPriceId, initialPriceId],
	} = props

	let totalDue = 0
	const initialTotal = props.initialTotal
	const recurringCost = props.initialRecurring
	let recurringMonthlyTotal = 0
	let recurringYearlyTotal = 0
	const subscriptionType = props.subscriptionType

	if (initialTotal) {
		totalDue += initialTotal
	}

	if (subscriptionType === 'monthly' && recurringCost) {
		recurringMonthlyTotal += recurringCost
		totalDue += recurringCost
	}

	if (subscriptionType === 'yearly' && recurringCost) {
		recurringYearlyTotal += recurringCost
		totalDue += recurringCost
	}

	const recurringPriceIdArray = recurringPriceId
		? Array.isArray(recurringPriceId)
			? recurringPriceId
			: [recurringPriceId]
		: []
	const optionalRecurringPriceIdArray = optionalRecurringPriceId
		? Array.isArray(optionalRecurringPriceId)
			? optionalRecurringPriceId
			: [optionalRecurringPriceId]
		: []
	const initialPriceIdArray = initialPriceId
		? Array.isArray(initialPriceId)
			? initialPriceId
			: [initialPriceId]
		: []
	const futurePriceIdsArray: string[] = []

	const combinedPriceIdArray = [...recurringPriceIdArray, ...optionalRecurringPriceIdArray]

	async function runSubscription() {
		try {
			const currentUserId = props.currentUser?.endUserId
			if (combinedPriceIdArray.length > 0) {
				await StripeAPI.createNewSubscription(combinedPriceIdArray, futurePriceIdsArray, endUserId)
				if (currentUserId === userProfileState.endUserId) {
					dispatch(getUserProps({ userId: userProfileState.endUserId }))
				}

				console.log('Subscription was created')
			}
			if (initialPriceIdArray.length > 0) {
				await runOneTimePayment()
			}
			props.dismissModal()
		} catch (error) {
			console.log('Error in runSubscription:', error)
		}
	}

	async function runOneTimePayment() {
		try {
			await StripeAPI.createOneTimePayment(initialPriceIdArray)
			console.log('One-time payment was successful')
		} catch (error) {
			console.log('Error in runOneTimePayment:', error)
		}
	}

	useEffect(() => {
		if (canUserEdit && props.userToEdit !== props.currentUser) {
			setEndUserId(props.userToEdit?.endUserId)
		} else {
			setEndUserId(undefined)
		}
	}, [props.userToEdit])

	return (
		<>
			<Modal onClose={props.dismissModal} maxWidth={800} maxHeight={800} fixedHeight={false}>
				<ModalHeader title={'Subscribe to ' + props.title} />

				<ModalBody>
					<div className="p-20 mt-10">
						<>
							<div className="col-xs-12 border-bottom-thin py-10">
								<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween">
									<div>
										<strong>{props.title}</strong>
									</div>
									<div>
										<em>{props.price}</em>
									</div>
								</div>
							</div>
							{initialTotal
								? initialTotal > 0 && (
										<div className="flex flex-justifyContent-end border-bottom-thin my-10">
											<strong className="mr-10">Inital setup cost:</strong>{' '}
											<div className="cost-num">${initialTotal}</div>
										</div>
									)
								: null}

							{recurringMonthlyTotal
								? recurringMonthlyTotal > 0 && (
										<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
											<strong className="mr-10">Monthly cost:</strong>{' '}
											<div className="cost-num">${recurringMonthlyTotal}</div>
										</div>
									)
								: null}

							{recurringYearlyTotal
								? recurringYearlyTotal > 0 && (
										<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
											<strong className="mr-10">Annual cost:</strong>{' '}
											<div className="cost-num">${recurringYearlyTotal}</div>
										</div>
									)
								: null}

							<div className="flex flex-justifyContent-end py-10">
								<strong className="mr-10">Total due:</strong>{' '}
								<div className="cost-num">${totalDue}</div>
							</div>
						</>
					</div>
				</ModalBody>
				<ModalFooter gap={true}>
					<Button variant="outlined" size="md" primary={false} label="Cancel" onClick={props.dismissModal} />
					<Button
						variant="contained"
						size="md"
						primary={true}
						label="Subscribe and close"
						onClick={runSubscription}
					/>
				</ModalFooter>
			</Modal>
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const SubscribeToService = connect(mapStateToProps)(SubscribeToServicePrototype)
