/* eslint-disable react/jsx-pascal-case */
import { Box } from '@components/box/box'
import { Button } from '@components/button/button'
import { Checkbox } from '@components/checkbox/checkbox'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownTypes } from '@components/dropdown/dropdown.types'
import { IconButton } from '@components/icon-button/icon-button'
import { SideSheet } from '@components/side-sheet/side-sheet'
import { SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'
import { useEffect, useState } from 'react'
import { GenericDeleteConfirmationModal } from 'src/modals/generic-delete-confirmation/generic-delete-confirmation'
import { DateService } from 'src/services/date.service'
import { StripeCouponAPI } from 'src/services/stripe/stripe.coupon.api'
import { StripeService } from 'src/services/stripe/stripe.service'
import { StripeTypes } from 'src/services/stripe/stripe.types'

import { PromotionCodeStatus } from './promotion-code-status'

interface EditCouponSideSheetProps {
	couponToEdit: StripeTypes.CouponData | 'NEW'
	onClose: () => void
	onSave: () => void
}

export function Stripe__Coupon__SideSheet(props: EditCouponSideSheetProps) {
	const [modifiedCoupon, setModifiedCoupon] = useState<StripeTypes.CouponData | null>(null)
	const [modifiedPromotionCode, setModifiedPromotionCode] = useState<StripeTypes.PromotionCodeCreation | null>(null)
	// const [modifiedPromoCode, setModifiedPromoCode] = useState<string | null>(null)
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
	const [promotionCodes, setPromotionCodes] = useState<StripeTypes.Promotion[] | null>(null)
	const [showPromotionCodeModal, setShowPromotionCodeModal] = useState(false)
	const [discountType, setDiscountType] = useState<StripeTypes.CouponDiscountType>('PERCENT')
	const [displayMaxRedemptions, setDisplayMaxRedemptions] = useState<boolean>(false)

	useEffect(() => {
		if (props.couponToEdit === 'NEW') {
			const newCoupon = StripeService.getDefaultCouponProps()
			const newPromoCode = StripeService.getDefaultPromotionCodeProps()
			setModifiedPromotionCode(newPromoCode)
			setModifiedCoupon(newCoupon)
			setDiscountType('FIXED') // Default discount type for new coupons
		} else {
			const existingCoupon = props.couponToEdit
			setModifiedCoupon(existingCoupon)
			const newPromoCode = StripeService.getDefaultPromotionCodeProps(props.couponToEdit.id)
			setModifiedPromotionCode(newPromoCode)
			// Infer the discount type based on amountOff or percentOff
			if (existingCoupon.amountOff && existingCoupon.amountOff > 0) {
				setDiscountType('FIXED')
			} else if (existingCoupon.percentOff && existingCoupon.percentOff > 0) {
				setDiscountType('PERCENT')
			}
			fetchPromoCodes()
		}
	}, [props.couponToEdit])

	async function handleCouponCreation() {
		if (props.couponToEdit === 'NEW' && modifiedCoupon) {
			StripeCouponAPI.createCoupon(modifiedCoupon).then((res) => {
				const couponId = res.id
				if (couponId && modifiedPromotionCode) {
					const updatedPromotionCode = { ...modifiedPromotionCode, couponId: res.id }
					StripeCouponAPI.createPromotionCode(updatedPromotionCode)
				}
			})
		}
		if (props.couponToEdit !== 'NEW' && modifiedPromotionCode && modifiedPromotionCode.code) {
			console.log(modifiedPromotionCode)
			StripeCouponAPI.createPromotionCode(modifiedPromotionCode)
			console.log('TEST')
		}
	}

	function renderFooter(): React.ReactNode {
		return (
			<>
				<Button
					variant={'outlined'}
					label={'Cancel'}
					size={'md'}
					margins={['right']}
					onClick={() => {
						props.onClose()
					}}
				/>
				<Button
					variant={'contained'}
					label={'Save and Close'}
					size={'md'}
					// disabled={isAdValid.length > 0}
					onClick={() => {
						handleCouponCreation().then(() => {
							props.onSave()
							// props.onUpdate(adEditorState.modifiedAd)
						})
					}}
				/>
			</>
		)
	}

	function getActions(): SideSheetTypes.Action[] {
		const actions: SideSheetTypes.Action[] = []

		actions.push({
			label: 'Delete',
			icon: 'trash',
			onClick: () => {
				setShowDeleteConfirmationModal(true)
			},
		})

		return actions
	}

	function fetchPromoCodes() {
		if (props.couponToEdit !== 'NEW') {
			StripeCouponAPI.getAssociatedPromotions(props.couponToEdit.id).then((res) => {
				setPromotionCodes(res)
			})
		}
	}

	function getDiscountType(): DropdownTypes.Option<StripeTypes.CouponDiscountType>[] {
		return [
			{ value: 'PERCENT', label: 'Percentage Discount' },
			{ value: 'FIXED', label: 'Fixed Amount Discount' },
		]
	}

	function getDiscountDurationType(): DropdownTypes.Option<StripeTypes.CouponDurationType>[] {
		return [
			{ value: 'once', label: 'Once' },
			{ value: 'forever', label: 'Forever' },
		]
	}

	if (!modifiedCoupon) {
		return <></>
	}

	return (
		<>
			<SideSheet
				title={props.couponToEdit === 'NEW' ? 'Create Coupon' : `${modifiedCoupon.name}`}
				actions={getActions()}
				onClose={props.onClose}
				persistent={false}
				width="wide"
				footer={renderFooter()}
			>
				<Box flex="row" wrap={true} gap={true}>
					<Typography type="h3" margins={['bottom']} className="col-xs-12">
						Coupon Information
					</Typography>

					<TextInput
						width={300}
						dataType="text"
						label="Name"
						disabled={props.couponToEdit === 'NEW' ? false : true}
						value={modifiedCoupon.name}
						onChange={(updatedValue) => {
							setModifiedCoupon({ ...modifiedCoupon, name: updatedValue })
						}}
					/>

					<Dropdown<StripeTypes.CouponDiscountType>
						width={300}
						label="Discount Type"
						value={[discountType]} // Always show the current discount type
						options={getDiscountType()}
						onSelect={(selectedValues) => {
							setDiscountType(selectedValues[0])
							console.log(selectedValues[0])

							// Reset corresponding fields when discount type changes
							if (selectedValues[0] === 'FIXED') {
								setModifiedCoupon({ ...modifiedCoupon, amountOff: 0, percentOff: null })
							} else if (selectedValues[0] === 'PERCENT') {
								setModifiedCoupon({ ...modifiedCoupon, amountOff: null, percentOff: 0 })
							}
						}}
						disabled={props.couponToEdit !== 'NEW'}
					/>

					{discountType === 'FIXED' && modifiedCoupon.amountOff !== null && (
						<TextInput
							width={300}
							dataType="text"
							label="Discount amount (in dollars)"
							disabled={props.couponToEdit === 'NEW' ? false : true}
							value={(modifiedCoupon.amountOff / 100).toFixed(2)}
							onChange={(updatedValue) => {
								const input = updatedValue.replace(/\D/g, '') // Only allow digits
								const amountInCents = parseInt(input, 10) * 100 || 0 // Convert dollars to cents
								setModifiedCoupon({
									...modifiedCoupon,
									amountOff: amountInCents, // Store value in cents for API
									percentOff: null,
								})
							}}
						/>
					)}

					{discountType === 'PERCENT' && (
						<TextInput
							width={300}
							dataType="number"
							label="Percentage off"
							disabled={props.couponToEdit === 'NEW' ? false : true}
							value={modifiedCoupon.percentOff}
							onChange={(updatedValue) => {
								setModifiedCoupon({ ...modifiedCoupon, amountOff: null, percentOff: updatedValue })
							}}
						/>
					)}

					<Dropdown<StripeTypes.CouponDurationType>
						width={300}
						label="Discount Type"
						value={[modifiedCoupon.duration]} // Always show the current discount type
						options={getDiscountDurationType()}
						onSelect={(selectedValues) => {
							setModifiedCoupon({ ...modifiedCoupon, duration: selectedValues[0] })
						}}
						disabled={props.couponToEdit !== 'NEW'}
					/>

					{props.couponToEdit !== 'NEW' && (
						<TextInput
							width={300}
							dataType="text"
							label="Created"
							disabled={true}
							value={DateService.convertTimestampToDate(modifiedCoupon.created)}
						/>
					)}

					{props.couponToEdit !== 'NEW' && (
						<TextInput
							width={300}
							dataType="text"
							label="Valid"
							value={modifiedCoupon.valid ? 'Yes' : 'No'}
							disabled={true}
						/>
					)}

					<Box
						flex="row"
						marginSize="section"
						margins={['top']}
						justifyContent="space-between"
						className="col-xs-12"
					>
						<Typography type="h3">Promotion Codes</Typography>

						{props.couponToEdit !== 'NEW' && (
							<IconButton
								tooltip="Add Promotion Code"
								tooltipPlacement="bottom"
								icon={'plus'}
								className="ml-10"
								onClick={() => {
									setShowPromotionCodeModal(true)
								}}
							/>
						)}
					</Box>

					{modifiedPromotionCode && (props.couponToEdit === 'NEW' || showPromotionCodeModal) ? (
						<div className="flex flex-wrap mb-20 mt-10">
							<TextInput
								width={300}
								dataType="text"
								label="Promotion Code"
								value={modifiedPromotionCode.id}
								margins={['bottom', 'right']}
								onChange={(updatedValue) => {
									setModifiedPromotionCode({ ...modifiedPromotionCode, code: updatedValue })
								}}
							/>

							<div className="flex flex-alignItems-center">
								<div className="mb-20-xs-down" style={{ width: '300px' }}>
									<Checkbox
										checked={displayMaxRedemptions}
										className="mt-5"
										onChange={(newState) => {
											setDisplayMaxRedemptions(newState)
										}}
									>
										Limit the number of times this code can be redeemed
									</Checkbox>
								</div>
							</div>

							{displayMaxRedemptions && (
								<TextInput
									width={300}
									dataType="number"
									label="Max redemptions"
									value={modifiedPromotionCode.maxRedemptions}
									margins={['bottom', 'right']}
									onChange={(updatedValue) => {
										setModifiedPromotionCode({
											...modifiedPromotionCode,
											maxRedemptions: updatedValue,
										})
									}}
								/>
							)}
						</div>
					) : null}

					{props.couponToEdit !== 'NEW' && (
						<div className="col-12">
							{promotionCodes ? (
								<div>
									{promotionCodes.map((promotionCode) => {
										return (
											<div key={promotionCode.id}>
												<PromotionCodeStatus
													promotionCode={promotionCode.code}
													promotionId={promotionCode.id}
													archiveStatus={promotionCode.active}
												/>
											</div>
										)
									})}
								</div>
							) : (
								<div>There are currently no promotion codes</div>
							)}
						</div>
					)}
				</Box>
			</SideSheet>

			{showDeleteConfirmationModal && (
				<GenericDeleteConfirmationModal
					itemLabel={`${modifiedCoupon.name} coupon`}
					onDelete={() => {
						return new Promise((resolve) => {
							if (props.couponToEdit !== 'NEW')
								StripeCouponAPI.deleteCoupon(props.couponToEdit.id).then(() => {
									setShowDeleteConfirmationModal(false)
									props.onClose()
									resolve()
								})
						})
					}}
					onClose={() => {
						setShowDeleteConfirmationModal(false)
					}}
				/>
			)}
		</>
	)
}
