import { Box } from '@components/box/box'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateService } from 'src/services/date.service'

import { useTransaction } from '../../state/transaction__state'
import { TransactionMgmt__Transaction__Overview__Contacts } from './contacts.section'
import { TransactionMgmt__Transaction__Overview__General } from './general.section'
import { TransactionMgmt__Transaction__Overview__OwnersWatchers } from './owners-watchers.section'
import { TransactionMgmt__Transaction__Overview__Tasks } from './tasks.section'
import { TransactionMgmt__Transaction__Overview__Updates } from './updates/updates.section'

export function TransactionMgmt__Transaction__Overview__Route() {
	const transactionState = useTransaction()

	return (
		<div className="col-xs-12" style={{ maxWidth: '1200px' }}>
			<Typography type="h1" clampWidth={false}>
				{transactionState.transaction.name}
			</Typography>

			<Box flex="row" alignItems="center" margins={['bottom']}>
				<Typography type="semibold" color="secondary">
					Created on{' '}
					{DateService.getFormattedDateFromDateObj(new Date(transactionState.transaction.createdDate))}
				</Typography>
				<FontAwesomeIcon
					icon={['fas', 'circle']}
					className="mx-10 text__color__secondary"
					style={{ width: '6px', height: '6px' }}
				/>
				<Typography type="semibold" color="secondary">
					Last updated{' '}
					{DateService.getFormattedDateFromDateObj(new Date(transactionState.transaction.lastUpdatedDate))}
				</Typography>
			</Box>
			<Box flex="column" gap={true}>
				<TransactionMgmt__Transaction__Overview__General />
				<TransactionMgmt__Transaction__Overview__Contacts />
				<TransactionMgmt__Transaction__Overview__OwnersWatchers />
				<TransactionMgmt__Transaction__Overview__Tasks />
				<TransactionMgmt__Transaction__Overview__Updates />
			</Box>
		</div>
	)
}
