import { Button } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { PhoneInput } from '@components/text-input/phone-input'
import { TextInput } from '@components/text-input/text-input'
import { useState } from 'react'
import { ToastService } from 'src/services/toast/toast.service'

import { EmailNotificationAPI } from '../../services/email-notification/email-notification.api'
import { EmailNotificationTypes } from '../../services/email-notification/email-notification.types'
import { Utilities } from '../../services/utilities.service'
import CoverImg from './agent-referral.jpg'

export function LoKationReferAgent() {
	const [submitted, setSubmitted] = useState(false)
	const [formFields, setFormFields] = useState<EmailNotificationTypes.EmailReferAnAgent>({
		referredName: '',
		referredPhone: null,
		referredEmail: '',
		referredState: '',
	})

	const [errors, setErrors] = useState({
		referredName: '',
		referredPhone: '',
		referredEmail: '',
		referredState: '',
	})

	function validateSimpleString(value: string): boolean {
		return value.length > 0
	}

	function validatePhone(value: number | null): boolean {
		if (value === null) {
			return false
		}
		return Utilities.isPhoneNumberValid(value, 'us')
	}

	function validateEmail(value: string): boolean {
		return Utilities.isEmailValid(value)
	}

	// Validation function
	const validateFormFields = () => {
		let formErrors = { ...errors }

		formErrors.referredName = validateSimpleString(formFields.referredName) ? '' : 'Name is required'
		formErrors.referredPhone = validatePhone(formFields.referredPhone) ? '' : 'Phone number is not valid'
		formErrors.referredEmail = validateEmail(formFields.referredEmail) ? '' : 'Email is not valid'
		formErrors.referredState = validateSimpleString(formFields.referredState) ? '' : 'State is required'

		setErrors(formErrors)
		return Object.values(formErrors).every((x) => x === '')
	}

	const handleChange = (name: string) => (value: string | number | null) => {
		if ((name === 'askingPrice' || name === 'amountToFinance') && typeof value === 'string') {
			// Strip out any non-digit characters and convert to a number
			const numericValue = Number(value.replace(/[^\d]/g, ''))

			// Format as a dollar amount
			value = isNaN(numericValue)
				? ''
				: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(numericValue / 100)
		}
		setFormFields((prevState) => ({ ...prevState, [name]: value ? value.toString() : '' }))
	}

	async function sendNotification() {
		if (!validateFormFields()) {
			ToastService.create({
				type: 'ERROR',
				body: 'Please fill out all fields',
			})
			return
		}

		const formData: EmailNotificationTypes.EmailReferAnAgent = {
			referredName: formFields.referredName,
			referredPhone: formFields.referredPhone,
			referredEmail: formFields.referredEmail,
			referredState: formFields.referredState,
		}

		console.log(formData)

		EmailNotificationAPI.referAgentNotification(formData)
			.then((res) => {
				setSubmitted(true)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const licensedStates = [
		{ stateName: 'Florida' },
		{ stateName: 'Georgia' },
		{ stateName: 'Colorado' },
		{ stateName: 'Alabama' },
		{ stateName: 'Texas' },
		{ stateName: 'South Carolina' },
	]

	function getStateOptions() {
		return [
			{ label: 'Select one', value: '' },
			...licensedStates.map((state) => ({
				label: state.stateName,
				value: state.stateName,
			})),
		]
	}

	return (
		<div className="flex flex-column overflow-y__scroll">
			<div className="flex flex-wrap">
				<div className="col-xs-12 pr-20-sm flex flex-column flex-alignItems-start">
					{/* <h2>Refer an Agent to LoKation</h2> */}
				</div>
			</div>
			<>
				<Paper bgColor="primary" padding={['all']} margins={['top', 'bottom']}>
					<div className="col-12 flex flex-column-xs-down flex-columnReverse-xs-down">
						<div className="col-12 col-md-7">
							<Typography type="h2" margins={['bottom']}>
								Refer an Agent
							</Typography>
							<>
								{!submitted ? (
									<>
										<div className="registration__field-wrapper">
											<TextInput
												label={'Full Name'}
												dataType="text"
												value={formFields.referredName}
												width="100%"
												margins={['bottom']}
												onChange={handleChange('referredName')}
											/>

											<PhoneInput
												width={`100%`}
												dataType={'number'}
												label="Phone number"
												placeholder="Phone number"
												value={formFields.referredPhone}
												onChange={handleChange('referredPhone')}
											/>

											<TextInput
												label={'Email'}
												dataType="text"
												value={formFields.referredEmail}
												width="100%"
												margins={['bottom']}
												onChange={handleChange('referredEmail')}
											/>

											<Dropdown
												label="State"
												width={`100%`}
												value={[formFields.referredState]} // Ensuring it's an array of strings
												options={getStateOptions()}
												onSelect={(selectedValues) => {
													setFormFields((prevState) => ({
														...prevState,
														referredState: selectedValues[0],
													}))
												}}
											/>
										</div>
										<div>
											<Button
												variant="contained"
												size="lg"
												primary={true}
												label={'Submit'}
												onClick={sendNotification}
												margins={['bottom']}
											/>
										</div>
									</>
								) : (
									<div className="mb-20">Your request has been submitted.</div>
								)}
							</>
						</div>
						<div className="col-12 col-md-5">
							<img src={CoverImg} className="mb-20 col-xs-12" />
						</div>
					</div>
				</Paper>
			</>
		</div>
	)
}
