import { IconButton } from '@components/icon-button/icon-button'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { useEffect, useState } from 'react'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

type ComponentProps = {
	taskId: number
}

export function TransactionMgmt__TaskCard(props: ComponentProps) {
	const [taskProps, setTaskProps] = useState<TransactionMgmtTypes.TaskTypes.Task | null>(null)

	useEffect(() => {
		TransactionManagementAPI.getTaskById(props.taskId).then((res) => {
			if (res.data) {
				setTaskProps(res.data)
			}
		})
	}, [])

	return (
		<Paper
			bgColor="white"
			className="flex flex-alignItems-center flex-justifyContent-spaceBetween bg-color__adjust-alpha-5 col-xs-12"
			padding={['all']}
			onClick={() => {
				/** empty */
			}}
			interactive={true}
		>
			<Typography type="strong">{taskProps ? taskProps.name : 'Unknown'}</Typography>
			<IconButton
				icon={'trash'}
				onClick={() => {
					alert(`DELETE`)
				}}
			/>
		</Paper>
	)
}
