/* eslint-disable react-hooks/rules-of-hooks */
import { Checkbox } from '@components/checkbox/checkbox'
import { MultiColumnCard } from '@components/multi-column-card/multi-column-card'
import { MultiColumnCard__Column } from '@components/multi-column-card/multi-column-card__column'
import { useDraggable, useDroppable } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLongPress } from '@uidotdev/usehooks'
import React from 'react'

import { MultiColumnTableService } from '../multi-column-table.service'
import { MultiColumnTableTypes } from '../multi-column-table.types'
import { useMultiColumnTable } from '../state/multi-column-table__state'

export function MultiColumnTableOption<T extends Object>(props: MultiColumnTableTypes.Option<T>) {
	const newItemTableState = useMultiColumnTable()
	const longPressAttrs = useLongPress(
		() => {
			if (props.onLongPress) {
				props.onLongPress(props.item)
			}
		},
		{
			threshold: 500,
		},
	)

	/** =================================== */
	/** Methods */

	function getDisplayedValue(column: MultiColumnTableTypes.Column<T>): React.ReactNode {
		/** If 'displayedValue' is defined, the value shown to the user should come from this property */
		let displayedValue: string | React.ReactNode = ''
		if (column.displayedValue) {
			displayedValue = column.displayedValue(props.item)
		} else {
			/** If 'value' is a function, the function needs to be resolved */
			if (typeof column.value === 'function') {
				displayedValue = column.value(props.item)
			} else {
				if (props.item[column.value] !== null && props.item[column.value] !== undefined) {
					displayedValue = String(props.item[column.value])
				}
			}
		}

		if (typeof displayedValue === 'string') {
			return (
				<div
					className={`multi-column-table__column-body ${column.displayIndex === 0 ? 'is-first-column' : ''}`}
					dangerouslySetInnerHTML={{ __html: displayedValue }}
				/>
			)
		} else {
			return displayedValue
		}
	}

	function getClass(isActiveDropTarget: boolean): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`fs-unmask`)

		if (isActiveDropTarget) {
			classes.push(`is-active-drop-target`)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	function renderItemInterior(): React.ReactNode {
		return (
			<>
				{props.includeCheckbox && (
					<Checkbox checked={props.isSelected} style={{ pointerEvents: 'none' }} className="mr-20" />
				)}
				{props.sortedColumns.map((column, index) => {
					const isColumnVisible = MultiColumnTableService.isColumnVisible(
						column,
						newItemTableState.columnConfigOverrides,
					)
					if (!isColumnVisible) {
						return <React.Fragment key={index}></React.Fragment>
					}
					if (!props.showAllColumns && index > 0) {
						return <React.Fragment key={index}></React.Fragment>
					}

					return (
						<MultiColumnCard__Column
							width={column.width}
							align={column.align ? column.align : 'left'}
							key={column.columnId ? column.columnId : index}
						>
							{getDisplayedValue(column)}
						</MultiColumnCard__Column>
					)
				})}
			</>
		)
	}

	/** =================================== */
	/** Render Component */

	if (props.isSortable) {
		/** =================================== */
		/** DND Kit */

		const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
			id: String(props.item[props.idKey]),
		})

		const style = {
			...props.style,
			transform: CSS.Transform.toString(transform),
			transition,
		}

		/** =================================== */

		return (
			<MultiColumnCard
				ref={setNodeRef}
				style={style}
				className={getClass(false)}
				disabled={props.disabled}
				onDoubleClick={(evt) => {
					if (props.onDoubleClick) {
						props.onDoubleClick(props.item)
					}
				}}
				isSelected={props.isSelected}
				size="md"
				onClick={props.onClick}
			>
				<div className="col-xs-12 flex flex-alignItems-center" {...longPressAttrs}>
					{renderItemInterior()}
					<FontAwesomeIcon
						icon={['fas', 'grip-dots']}
						className="ml-20"
						{...attributes}
						{...listeners}
						style={{ cursor: 'grab' }}
					/>
				</div>
			</MultiColumnCard>
		)
	}

	if (props.isDraggable || props.isDroppable) {
		/** =================================== */
		/** DND Kit */

		const {
			attributes,
			listeners,
			setNodeRef: setDraggableNodeRef,
			transform,
			isDragging,
		} = useDraggable({
			id: String(props.item[props.idKey]),
			disabled: !props.isDraggable,
			data: props.item,
		})

		const { isOver, setNodeRef: setDroppableNodeRef } = useDroppable({
			id: String(props.item[props.idKey]),
			disabled: !props.isDroppable,
			data: props.item,
		})

		let opacity = 1
		if (props.isDragActive) {
			if (!props.isDroppable && !isDragging) {
				opacity = 0.35
			}
		}

		const style = {
			...props.style,
			opacity,
			transform: CSS.Transform.toString(transform),
		}

		/** =================================== */

		return (
			<MultiColumnCard
				ref={props.isDroppable ? setDroppableNodeRef : setDraggableNodeRef}
				style={style}
				className={getClass(isOver)}
				disabled={props.disabled}
				onDoubleClick={(evt) => {
					if (props.onDoubleClick) {
						props.onDoubleClick(props.item)
					}
				}}
				size="md"
				isSelected={props.isSelected}
				{...attributes}
				{...listeners}
				onClick={props.onClick}
			>
				<div className="col-xs-12 flex flex-alignItems-center" {...longPressAttrs}>
					{renderItemInterior()}
				</div>
			</MultiColumnCard>
		)
	}

	return (
		<MultiColumnCard
			className={getClass(false)}
			disabled={props.disabled}
			onDoubleClick={(evt) => {
				if (props.onDoubleClick) {
					props.onDoubleClick(props.item)
				}
			}}
			isSelected={props.isSelected}
			style={props.style}
			size="md"
			onClick={props.onClick}
		>
			<div className="col-xs-12 flex flex-alignItems-center" {...longPressAttrs}>
				{renderItemInterior()}
			</div>
		</MultiColumnCard>
	)
}
