import { Button } from '@components/button/button'
import { ModalFooter } from '@components/modal/modal-footer'
import { ToastService } from 'src/services/toast/toast.service'
import { ValidationService } from 'src/services/validation/validation.service'

import { EditCalendarEventService } from '../edit-calendar.event.service'
import { EditCalendarEventInnerProps } from '../edit-calendar-event__inner'
import { useEditCalendarEvent } from '../state/edit-calendar-event__state'

export function EditCalendarEventFooter(props: EditCalendarEventInnerProps) {
	const editCalendarState = useEditCalendarEvent()

	return (
		<ModalFooter>
			<Button
				variant="outlined"
				size="md"
				primary={false}
				label="Cancel"
				margins={['right']}
				onClick={props.dismissModal}
			/>
			{editCalendarState.calendarEventId > 0 && (
				<Button
					variant="contained"
					size="md"
					label="Update"
					onClick={() => {
						const validationResults = EditCalendarEventService().validate(editCalendarState)
						if (validationResults.isValid) {
							EditCalendarEventService()
								.updateCalendarEvent(editCalendarState)
								.then((updatedEvent) => {
									ToastService.create({ type: 'SUCCESS', body: `Calendar Event has been updated` })
									if (props.onUpdate) {
										props.onUpdate(updatedEvent)
									}
									props.dismissModal()
								})
						} else {
							ValidationService.showValidationErrors(validationResults)
						}
					}}
				/>
			)}
			{editCalendarState.calendarEventId === -1 && (
				<Button
					variant="contained"
					size="md"
					label="Create"
					onClick={() => {
						const validationResults = EditCalendarEventService().validate(editCalendarState)
						if (validationResults.isValid) {
							EditCalendarEventService()
								.createCalendarEvent(editCalendarState)
								.then((newEvent) => {
									ToastService.create({ type: 'SUCCESS', body: `Calendar Event has been created` })
									if (props.onCreate) {
										props.onCreate(newEvent)
									}
									props.dismissModal()
								})
						} else {
							ValidationService.showValidationErrors(validationResults)
						}
					}}
				/>
			)}
		</ModalFooter>
	)
}
