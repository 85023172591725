import { Button } from '@components/button/button'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { UIState } from '@redux/reducers/ui-reducer'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { RoutingService } from 'src/services/routing/routing.service'
import { TeamsAPI } from 'src/services/teams/teams.api'
import { TeamTypes } from 'src/services/teams/teams.types'

import useAdminRedirect from '../../../services/hooks/user-admin-redirect'
import { EndUserProps } from '../../../services/user/user.types'
import { TeamsSideSheet } from './side-sheets/teams__side-sheet'
import { TeamCard } from './teams-card/teams___card'

type ConnectedProps = {
	userProfile: EndUserProps | null
	ui: UIState
}

function TeamsAdministrationRoute__Prototype(props: ConnectedProps) {
	const navigate = useNavigate()
	// const [accountSearchString] = useState('')
	const [teams, setTeams] = useState<TeamTypes.Team[]>()
	const [selectedTeam, setSelectedTeam] = useState<TeamTypes.Team | 'NEW' | null>(null)
	const [showTeamSideSheet, setShowTeamSideSheet] = useState(false)
	// const [isPending, setIsPending] = useState(true)

	function getTeams() {
		TeamsAPI.findTeams()
			.then((res) => {
				setTeams(res.data)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	useAdminRedirect(props.userProfile)

	useEffect(() => {
		getTeams()
	}, [])

	return (
		<>
			<RouteWrapperV2>
				<RouteSubnavigation
					title={`Teams`}
					backButton={{
						label: 'Administration',
						onClick: () => {
							navigate('/administration')
						},
					}}
					isCustom={false}
					routes={[
						{
							label: 'View Analytics',
							onClick: () => {
								alert(`Connect to Google Analytics`)
							},
							icon: 'chart-line',
							id: `analytics`,
							isActive: false,
						},
					]}
				/>
				<RouteBodyV2 className="col-xs-12" id="advertising-admin__route-body">
					<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center mb-10">
						<Typography type="h1">Teams</Typography>
						<Button
							variant="outlined"
							label="Create Team"
							size={'md'}
							onClick={() => {
								setSelectedTeam('NEW')
								setShowTeamSideSheet(true)
							}}
						/>
					</div>
					<div className="flex flex-column-md-down">
						<div className="flex flex-column flex-fillSpace">
							{/* {!isPending && accountAdminHook.items.length === 0 && (
								<div className="flex flex-column flex-fillSpace flex-justifyContent-center flex-alignItems-center">
									<FontAwesomeIcon icon={['far', 'newspaper']} size="4x" className="mb-20" />
									<h3>We cannot find any teams matching your criteria</h3>
								</div>
							)} */}

							{teams &&
								teams.map((team) => {
									return (
										// <AccountCard
										// 	{...account}
										// 	key={account.accountId}
										// 	onClick={() => {
										// 		setSelectedAccount(account)
										// 		setShowAccountSideSheet(true)
										// 	}}
										// 	onDoubleClick={() => {
										// 		navigate(`${settingsRoutePath}/ad-account/${account.accountId}/general`)
										// 	}}
										// />
										<TeamCard
											{...team}
											key={team.realEstateTeamId}
											onClick={() => {
												setSelectedTeam(team)
												setShowTeamSideSheet(true)
											}}
											onDoubleClick={() => {
												navigate(
													`${RoutingService.settingsRoutePath}/team/${team.realEstateTeamId}`,
												)
											}}
										/>
									)
								})}
						</div>
					</div>
				</RouteBodyV2>
			</RouteWrapperV2>
			{selectedTeam && showTeamSideSheet && (
				<TeamsSideSheet
					team={selectedTeam}
					onClose={() => {
						setShowTeamSideSheet(false)
					}}
					onDelete={() => {
						getTeams()
					}}
					onUpdate={() => {
						getTeams()
					}}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
		ui: state.ui,
	}
}

export const TeamsAdministrationRoute = connect(mapStateToProps)(TeamsAdministrationRoute__Prototype)
