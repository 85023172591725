import './collapsible-content.scss'

import { CollapsibleContentTypes } from './collapsible-content.types'
import { CollapsibleContent__Controlled } from './collapsible-content__controlled'
import { CollapsibleContent__Uncontrolled } from './collapsible-content__uncontrolled'

export function CollapsibleContent(props: CollapsibleContentTypes.Component) {
	switch (props.controlled) {
		case true:
			return <CollapsibleContent__Controlled {...props} />
		case false:
			return <CollapsibleContent__Uncontrolled {...props} />
	}
}
