import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownTypes } from '@components/dropdown/dropdown.types'
import { useEffect, useState } from 'react'

import { CalendarAPI } from '../../../services/calendar/calendar.api'
import { Calendar } from '../../../services/calendar/calendar.types'

interface CalendarSelectorDropdownProps {
	selectedCalendarId: number | null
	onSelect: (value: number | null) => void
}

export function CalendarSelectorDropdown(props: CalendarSelectorDropdownProps) {
	const [allCalendars, setAllCalendars] = useState<Calendar.Calendar[]>([])

	useEffect(() => {
		CalendarAPI.getCalendars({ page: 0, size: 100 }).then((res) => {
			setAllCalendars(res.data.items)
		})
	}, [])

	function getOptions(): DropdownTypes.Option<number | null>[] {
		const options: DropdownTypes.Option<number | null>[] = []

		options.push({
			label: `All Calendars`,
			value: null,
		})

		allCalendars.forEach((calendar) => {
			options.push({
				label: calendar.name,
				value: calendar.calendarId,
			})
		})

		return options
	}

	return (
		<>
			<Dropdown<number | null>
				value={[props.selectedCalendarId]}
				options={getOptions()}
				onSelect={(value) => {
					if (value && value.length > 0) {
						props.onSelect(value[0])
					}
				}}
				maximizeHeight={true}
				width={`100%`}
				margins={['bottom']}
			/>
		</>
	)
}
