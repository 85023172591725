import { Box } from '@components/box/box'
import { Button } from '@components/button/button'
import { CollapsibleContent } from '@components/collapsible-content/collapsible-content'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownTypes } from '@components/dropdown/dropdown.types'
import { Typography } from '@components/text/text'
import { RootState } from '@redux/store'
import { useState } from 'react'
import { connect } from 'react-redux'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'
import { EndUserProps } from 'src/services/user/user.types'

import { TransactionMgmt__AddCommentModal } from '../../../../shared/add-comment-modal/add-comment-modal'
import { useTransaction, useTransactionDispatch } from '../../../state/transaction__state'
import { UpdateCard } from './update-card'

type UpdateDTO = TransactionUpdateDTO | TaskUpdateDTO | DocumentUpdateDTO

type UpdateContextEnum = 'TRANSACTION' | 'TASK' | 'DOCUMENT'

type BaseUpdateDTO = {
	context: UpdateContextEnum
	update: TransactionMgmtTypes.UpdateTypes.Update
}

type TransactionUpdateDTO = BaseUpdateDTO & {
	context: `TRANSACTION`
	parentTransaction: TransactionMgmtTypes.TransactionTypes.Transaction
}

type TaskUpdateDTO = BaseUpdateDTO & {
	context: `TASK`
	parentTask: TransactionMgmtTypes.TaskTypes.Task
}

type DocumentUpdateDTO = BaseUpdateDTO & {
	context: `DOCUMENT`
	parentDocument: TransactionMgmtTypes.DocumentTypes.Document
}

type ConnectedProps = {
	currentUser: EndUserProps | null
}

function TransactionMgmt__Transaction__Overview__UpdatesPrototype(props: ConnectedProps) {
	const transactionState = useTransaction()
	const transactionDispatch = useTransactionDispatch()
	const [selectedFilter, setSelectedFilter] = useState<UpdateContextEnum | 'ALL'>('ALL')
	const [showAddCommentModal, setShowAddCommentModal] = useState(false)
	const allVisibleUpdates = filterUpdateByType(selectedFilter)

	function getFilterTypeOptions(): DropdownTypes.Option<UpdateContextEnum | 'ALL'>[] {
		const options: DropdownTypes.Option<UpdateContextEnum | 'ALL'>[] = []

		options.push({
			label: `All`,
			value: `ALL`,
		})

		options.push({
			label: `Transaction Updates`,
			value: `TRANSACTION`,
		})

		options.push({
			label: `Document Updates`,
			value: `DOCUMENT`,
		})

		options.push({
			label: `Task Updates`,
			value: `TASK`,
		})

		return options
	}

	function sortUpdates(updates: UpdateDTO[]): UpdateDTO[] {
		return updates.sort((a, b) => (a.update.createTimestamp > b.update.createTimestamp ? -1 : 1))
	}

	function filterUpdateByType(type: UpdateContextEnum | 'ALL'): UpdateDTO[] {
		let allUpdates: UpdateDTO[] = []

		transactionState.transaction.updates.forEach((transactionUpdate) => {
			allUpdates.push({
				context: 'TRANSACTION',
				parentTransaction: transactionState.transaction,
				update: transactionUpdate,
			})
		})

		transactionState.transaction.tasks.forEach((taskProps) => {
			taskProps.updates.forEach((taskUpdate) => {
				allUpdates.push({
					context: 'TASK',
					parentTask: taskProps,
					update: taskUpdate,
				})
			})
		})

		transactionState.transaction.documents.forEach((docProps) => {
			docProps.updates.forEach((docUpdate) => {
				allUpdates.push({
					context: 'DOCUMENT',
					parentDocument: docProps,
					update: docUpdate,
				})
			})
		})

		if (type === 'ALL') {
			return allUpdates
		}

		return allUpdates.filter((update) => update.context === type)
	}

	return (
		<CollapsibleContent
			label="Updates"
			labelSize="h5"
			controlled={true}
			id={`transaction__updates`}
			headerPadding={[]}
		>
			<Box flex="row" alignItems="center" margins={['bottom']}>
				<Typography type="semibold" margins={['right']}>
					Filter
				</Typography>
				<Dropdown<UpdateContextEnum | 'ALL'>
					value={[selectedFilter]}
					width={'200px'}
					options={getFilterTypeOptions()}
					onSelect={(selectedOptions) => {
						if (selectedOptions) {
							setSelectedFilter(selectedOptions[0])
						}
					}}
				/>

				<Button
					variant="outlined"
					label="Add Comment"
					size="md"
					margins={['left']}
					onClick={(evt) => {
						setShowAddCommentModal(true)
					}}
				/>
			</Box>

			<div className="col-xs-12" style={{ maxWidth: '1200px' }}>
				<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween"></div>
				{sortUpdates(allVisibleUpdates).map((updateDTO) => {
					return (
						<UpdateCard
							update={updateDTO.update}
							key={updateDTO.update.updateId}
							parentTaskId={updateDTO.context === 'TASK' ? updateDTO.parentTask.taskId : null}
							parentDocumentId={
								updateDTO.context === 'DOCUMENT' ? updateDTO.parentDocument.documentId : null
							}
							parentTransaction={transactionState.transaction}
							condensed={false}
						/>
					)
				})}
				{showAddCommentModal && (
					<TransactionMgmt__AddCommentModal
						onClose={() => {
							setShowAddCommentModal(false)
						}}
						onAddComment={async (commentToAdd, taggedUsers, visibility) => {
							if (!props.currentUser) {
								return
							}
							const res = await TransactionManagementAPI.addCommentToTransaction(
								transactionState.transaction.transactionId,
								commentToAdd,
								taggedUsers,
								visibility,
								props.currentUser.endUserId,
							)
							transactionDispatch({ type: 'add-update-to-transaction', payload: res.data })
							setShowAddCommentModal(false)
						}}
					/>
				)}
			</div>
		</CollapsibleContent>
	)
}

function mapStateToProps(state: RootState, ownProps: {}) {
	return {
		currentUser: state.user,
	}
}

export const TransactionMgmt__Transaction__Overview__Updates = connect(mapStateToProps)(
	TransactionMgmt__Transaction__Overview__UpdatesPrototype,
)
