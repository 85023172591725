import './text-input.scss'

import { useGetDebouncedValue } from '@components/popover/use-get-debounced-value'
import { faLock } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'

import { IconButton } from '../icon-button/icon-button'
import { Tooltip } from '../tooltip/tooltip'
import { TextInputService } from './text-input.service'
import { TextInputTypes } from './text-input.types'

export const TextInput = React.forwardRef(
	(props: TextInputTypes.Component, ref: React.ForwardedRef<HTMLInputElement>) => {
		const [isUserInteracted, setIsUserInteracted] = useState(false)
		const [modifiedValue, setModifiedValue] = useState(props.value)
		const [commitTimeout, setCommitTimeout] = useState<NodeJS.Timeout | null>(null)
		const [isValid, setIsValid] = useState(true)
		const [showValidationError, setShowValidationError] = useState(true)

		const debouncedModifiedValue = useGetDebouncedValue(
			modifiedValue,
			props.debounceChangeEvt === true || props.debounceChangeEvt === undefined ? 200 : 5,
		)

		/** ============================================= */
		/** Effects */

		useEffect(() => {
			if (modifiedValue !== null) {
				const updatedIsValid = isValueValid(modifiedValue, isUserInteracted)
				if (updatedIsValid !== isValid) {
					setIsValid(updatedIsValid)
				}
			}
		}, [props.forceRevalidation, modifiedValue])

		/** Reset the value of this text input when it is changed externally */
		useEffect(() => {
			setModifiedValue(props.value)
		}, [props.value])

		/** Wait until user stops typing before triggering on onChange event */
		useEffect(() => {
			if (props.value === debouncedModifiedValue) {
				return
			}

			if (typeof debouncedModifiedValue === 'string' && props.dataType === 'text' && props.onChange) {
				props.onChange(debouncedModifiedValue)
			}
			if (typeof debouncedModifiedValue === 'number' && props.dataType === 'number' && props.onChange) {
				props.onChange(debouncedModifiedValue)
			}
			if (typeof debouncedModifiedValue === 'number' && props.dataType === 'currency' && props.onChange) {
				props.onChange(debouncedModifiedValue)
			}
		}, [debouncedModifiedValue])

		/** ============================================= */
		/** Methods */

		function getInputProps() {
			return {
				value: modifiedValue === null ? '' : modifiedValue,
				placeholder: props.placeholder,
				type: props.type ? props.type : 'text',
				disabled: typeof props.disabled === 'boolean' ? props.disabled : false,
				style: TextInputService.getInputStyle({ inputStyle: props.inputStyle, align: props.align }),
				rows: props.rows,
				maxLength: props.maxLength,
				autoComplete: props.autoComplete,
				name: props.name,
			}
		}

		function commitValue(valueToSet: number | string): void {
			if (isUserInteracted) {
				setIsValid(isValueValid(valueToSet, true))
			}

			if (props.dataType === 'number') {
				valueToSet = typeof valueToSet === 'string' ? parseFloat(valueToSet) : valueToSet
				if (isNaN(valueToSet)) {
					valueToSet = 0
				}
				if ('minValue' in props && props.minValue !== undefined && valueToSet < props.minValue) {
					valueToSet = props.minValue
				}

				if ('maxValue' in props && props.maxValue !== undefined && valueToSet > props.maxValue) {
					valueToSet = props.maxValue
				}
			}

			setModifiedValue(valueToSet)
		}

		function getInputChangeEvent(value: string | number): void {
			if (!isUserInteracted) {
				setIsUserInteracted(true)
			}

			let valueToSet = value
			let waitToCommitValue = false
			if (typeof valueToSet === 'number') {
				if (isNaN(valueToSet)) {
					valueToSet = 0
				}

				/** Determine whether to give the user a few milliseconds before setting the value or not */
				if ('minValue' in props && props.minValue !== undefined && valueToSet < props.minValue) {
					waitToCommitValue = true
				}

				if ('maxValue' in props && props.maxValue !== undefined && valueToSet > props.maxValue) {
					waitToCommitValue = true
				}
			}

			setModifiedValue(valueToSet)

			/** Clear the last timeout */
			if (commitTimeout) {
				clearTimeout(commitTimeout)
			}

			/** If there is an invalid input, set a short timer to allow the user to correct it themselves before it gets corrected for them */
			if (waitToCommitValue) {
				const newTimer = setTimeout(() => {
					commitValue(valueToSet)
				}, 1000)
				setCommitTimeout(newTimer)
			} else {
				/** If input is valid, clear any timeout that may exist and immediately commit the value */
				if (commitTimeout) {
					setCommitTimeout(null)
				}
				commitValue(valueToSet)
			}
		}

		function isKeyValidForNumberFormat(key: string): boolean {
			if (key === 'Backspace' || key === 'Delete') {
				return true
			} else if (Number.isFinite(parseFloat(key))) {
				return true
			}
			return false
		}

		function getInputKeyDownEvent(evt: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): void {
			if (evt.code === 'Enter' && props.onEnterKeyDown) {
				props.onEnterKeyDown()
				return
			}

			if (props.dataType === 'number') {
				if (!isKeyValidForNumberFormat(evt.key) && evt.key !== 'Tab') {
					evt.preventDefault()
					return
				}
			}

			if (props.onKeyDown) {
				props.onKeyDown(evt)
			}
		}

		function getInputBlurEvent(evt: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>): void {
			if (!isUserInteracted) {
				setIsUserInteracted(true)
			}
			setIsValid(isValueValid(evt.target.value, true))
		}

		function isValueValid(value: string | number, hasUserInteracted: boolean): boolean {
			if (props.validation) {
				if (typeof value === 'string' && props.dataType === 'text') {
					const validationResult = props.validation.isValid(value, hasUserInteracted)

					if (typeof validationResult.showValidationFlag === 'boolean') {
						setShowValidationError(validationResult.showValidationFlag)
					}

					return validationResult.isValid
				}
				if (typeof value === 'number' && props.dataType === 'number') {
					const validationResult = props.validation.isValid(value, hasUserInteracted)

					if (typeof validationResult.showValidationFlag === 'boolean') {
						setShowValidationError(validationResult.showValidationFlag)
					}

					return validationResult.isValid
				}
			}
			return true
		}

		function renderInput(): JSX.Element {
			if (typeof props.rows === 'number' && props.rows > 1) {
				return (
					<textarea
						{...getInputProps()}
						className={TextInputService.getInputClassNames({
							dataType: props.dataType,
							disabled: props.disabled,
							isValid,
							showValidationError,
							variant: props.variant,
							rows: props.rows,
						})}
						onChange={(evt) => {
							getInputChangeEvent(evt.target.value)
						}}
						onKeyDown={getInputKeyDownEvent}
						onBlur={getInputBlurEvent}
					></textarea>
				)
			} else {
				return (
					<div
						className={TextInputService.getInputClassNames({
							dataType: props.dataType,
							disabled: props.disabled,
							variant: props.variant,
							isValid,
							showValidationError,
							rows: props.rows,
						})}
					>
						<div className="text-input__inner-content-wrapper flex flex-alignItems-center">
							{props.prepend && <div className="flex-noShrink">{props.prepend}</div>}

							{props.dataType === 'currency' && (
								<NumericFormat
									{...{ ...getInputProps(), type: 'text' }}
									prefix="$"
									thousandSeparator=","
									decimalSeparator="."
									onValueChange={(evt) => {
										if (typeof evt.floatValue === 'number') {
											getInputChangeEvent(evt.floatValue)
										}
									}}
									onKeyDown={getInputKeyDownEvent}
									onBlur={getInputBlurEvent}
								/>
							)}
							{(props.dataType === 'number' || props.dataType === 'text') && (
								<input
									{...getInputProps()}
									ref={ref}
									onChange={(evt) => {
										if (props.dataType === 'number') {
											getInputChangeEvent(parseFloat(evt.target.value))
										} else {
											getInputChangeEvent(evt.target.value)
										}
									}}
									onKeyDown={getInputKeyDownEvent}
									onBlur={getInputBlurEvent}
								/>
							)}
							{props.disabled && <FontAwesomeIcon icon={faLock} />}
							{props.icon && (
								<IconButton
									icon={props.icon.name}
									variant="inline"
									onClick={() => {
										if (props.icon && props.icon.onClick) {
											props.icon.onClick()
										}
									}}
								/>
							)}
							{(props.postpend || !isValid) && (
								<div className="flex-noShrink non-disabled">
									{props.postpend && <>{props.postpend}</>}
									{!isValid && props.validation && showValidationError && (
										<Tooltip
											icon="warning"
											className="color__warning ml-10"
											body={props.validation.message}
										/>
									)}
								</div>
							)}
						</div>
					</div>
				)
			}
		}

		/** ============================================= */
		/** Render Component */

		if (props.label) {
			switch (props.labelPlacement) {
				case 'left':
					return (
						<div
							className={`flex flex-alignItems-center flex-justifyContent-spaceBetween ${TextInputService.getWrapperClass(
								{
									margins: props.margins,
									marginSize: props.marginSize,
								},
							)}`}
							style={TextInputService.getWrapperStyle({ style: props.style, width: props.width })}
						>
							<div className="flex flex-alignItems-center pb-5">
								<strong>{props.label}</strong>
								{props.tooltip && <Tooltip className="ml-5" body={props.tooltip} />}
							</div>
							{renderInput()}
						</div>
					)
				case 'top':
				default:
					return (
						<div
							className={`flex flex-column flex-alignItems-start ${TextInputService.getWrapperClass({
								margins: props.margins,
								marginSize: props.marginSize,
							})}`}
							style={TextInputService.getWrapperStyle({ style: props.style, width: props.width })}
						>
							<div className="flex flex-alignItems-center pb-5">
								<strong>{props.label}</strong>
								{props.tooltip && <Tooltip className="ml-5" body={props.tooltip} />}
							</div>
							{renderInput()}
						</div>
					)
			}
		} else {
			return (
				<div
					className={TextInputService.getWrapperClass({
						margins: props.margins,
						marginSize: props.marginSize,
					})}
					style={TextInputService.getWrapperStyle({ style: props.style, width: props.width })}
				>
					{renderInput()}
				</div>
			)
		}
	},
)
