import { Box } from '@components/box/box'
import React from 'react'
import { ExternalApiDetailsTypes } from 'src/services/external-api-details/external-api.types'

import { Button } from '../button/button'
import { CheckboxList } from '../checkbox-list/checkbox-list'
import { CollapsibleContent } from '../collapsible-content/collapsible-content'

export interface ItemSearchStatusCodeFilterProps {
	selectedStatusCodes: ExternalApiDetailsTypes.StatusCode[]
	onChange: (selectedTags: ExternalApiDetailsTypes.StatusCode[]) => void
	style?: React.CSSProperties
	className?: string
}

export function ItemSearchStatusCodeFilter(props: ItemSearchStatusCodeFilterProps) {
	const statusCodes: ExternalApiDetailsTypes.StatusCode[] = [
		{ id: 200, label: '200 - OK' },
		{ id: 400, label: '400 - Bad Request' },
		{ id: 404, label: '404 - Not Found' },
		{ id: 500, label: '500 - Internal Server Error' },
	]

	return (
		<CollapsibleContent label="Status Codes" controlled={true} id={`Status Codes`} headerPadding={['all']}>
			<>
				<div className={`base-search-filter ${props.className ? props.className : ''}`} style={props.style}>
					<CheckboxList
						onChange={props.onChange}
						selectedItems={props.selectedStatusCodes}
						allItems={statusCodes}
						itemIdKey="id"
						itemLabelKey="label"
						optionClassName="col-xs-12"
					/>
				</div>
				<Box flex="row" gap={true}>
					<Button
						variant="text"
						size="md"
						label="Select None"
						onClick={() => {
							props.onChange([])
						}}
					/>
					<Button
						variant="text"
						size="md"
						label="Select All"
						onClick={() => {
							props.onChange(statusCodes)
						}}
					/>
				</Box>
			</>
		</CollapsibleContent>
	)
}
