import './text-input.scss'

import { faLock } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

import { Utilities } from '../../services/utilities.service'
import { Tooltip } from '../tooltip/tooltip'
import { TextInputService } from './text-input.service'
import { TextInputTypes } from './text-input.types'

export function PhoneInput(props: TextInputTypes.NumberTypeProps) {
	const [isUserInteracted, setIsUserInteracted] = useState(false)
	const [isValid, setIsValid] = useState(true)
	const [inputValue, setInputValue] = useState(Utilities.formatPhoneNum(String(props.value)))
	const [showValidationError, setShowValidationError] = useState(true)

	/** This forces validation to occur when the 'forceRevalidation' is changed */
	useEffect(() => {
		const updatedIsValid = isValueValid(props.value, isUserInteracted)
		if (updatedIsValid !== isValid) {
			setIsValid(updatedIsValid)
		}
	}, [props.forceRevalidation, inputValue])

	useEffect(() => {
		setInputValue(Utilities.formatPhoneNum(String(props.value)))
	}, [props.value])

	function getInputProps() {
		return {
			value: inputValue,
			placeholder: `(123) 456-7890`,
			type: 'text',
			disabled: typeof props.disabled === 'boolean' ? props.disabled : false,
			style: TextInputService.getInputStyle({ inputStyle: props.inputStyle, align: 'left' }),
			rows: props.rows,
			autoComplete: props.autoComplete,
		}
	}

	const getInputChangeEvent = (evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setIsUserInteracted(true)

		const formattedPhoneNumber = Utilities.formatPhoneNum(evt.target.value)
		setInputValue(formattedPhoneNumber)

		let rawValue: number | null = parseFloat(formattedPhoneNumber.replace(/[^0-9]/g, ''))

		if (isNaN(rawValue)) {
			rawValue = null
		}

		setIsValid(isValueValid(rawValue, true))

		if (props.onChange) {
			props.onChange(rawValue)
		}
	}

	const getInputKeyDownEvent = (evt: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (props.onKeyDown) {
			props.onKeyDown(evt)
		}
	}

	const getInputBlurEvent = (evt: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setIsUserInteracted(true)
	}

	function renderInput(): JSX.Element {
		return (
			<div
				className={TextInputService.getInputClassNames({
					dataType: props.dataType,
					disabled: props.disabled,
					variant: props.variant,
					isValid,
					showValidationError,
				})}
			>
				<div className="text-input__inner-content-wrapper">
					<input
						{...getInputProps()}
						onChange={getInputChangeEvent}
						onKeyDown={getInputKeyDownEvent}
						onBlur={getInputBlurEvent}
					/>
					{props.disabled && <FontAwesomeIcon icon={faLock} />}
					{!isValid && props.validation && showValidationError && (
						<Tooltip icon="warning" className="color__warning ml-10" body={props.validation.message} />
					)}
				</div>
			</div>
		)
	}

	function isValueValid(value: number | null, hasUserInteracted: boolean): boolean {
		if (props.validation) {
			const validationResult = props.validation.isValid(value, hasUserInteracted)

			if (typeof validationResult.showValidationFlag === 'boolean') {
				setShowValidationError(validationResult.showValidationFlag)
			}

			return validationResult.isValid
		}
		return true
	}

	if (props.label) {
		switch (props.labelPlacement) {
			case 'left':
				return (
					<div
						className={`flex flex-alignItems-center flex-justifyContent-spaceBetween ${TextInputService.getWrapperClass(
							{
								margins: props.margins,
								marginSize: props.marginSize,
							},
						)}`}
						style={TextInputService.getWrapperStyle({ style: props.style, width: props.width })}
					>
						<strong className="mr-20 flex-noShrink">{props.label}</strong>
						{renderInput()}
					</div>
				)
			case 'top':
			default:
				return (
					<div
						className={`flex flex-column flex-alignItems-start ${TextInputService.getWrapperClass({
							margins: props.margins,
							marginSize: props.marginSize,
						})}`}
						style={TextInputService.getWrapperStyle({ style: props.style, width: props.width })}
					>
						<strong className="pb-5">{props.label}</strong>
						{renderInput()}
					</div>
				)
		}
	} else {
		return (
			<div
				className={TextInputService.getWrapperClass({
					margins: props.margins,
					marginSize: props.marginSize,
				})}
				style={TextInputService.getWrapperStyle({ style: props.style, width: props.width })}
			>
				{renderInput()}
			</div>
		)
	}
}
