import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useNavigate } from 'react-router'
import { RoutingService } from 'src/services/routing/routing.service'

import { Hub } from '../../services/hubs/hubs.types'
import { Paper } from '../paper/paper'

type ComponentProps = {
	hub: Hub
	style?: React.CSSProperties
}

export function HubTile(props: ComponentProps) {
	const navigate = useNavigate()

	function getStyle(): React.CSSProperties {
		const style: React.CSSProperties = { ...props.style }

		style.width = `150px`
		style.height = `150px`
		style.backgroundColor = props.hub.color

		style.color = `#fff`
		return style
	}

	return (
		<Paper
			interactive={true}
			style={getStyle()}
			bgColor="primary"
			padding={['all']}
			margins={['top', 'bottom', 'right']}
			className="flex flex-column flex-justifyContent-center flex-alignItems-center text-center mr-0-xs-down"
			onClick={() => {
				navigate(`${RoutingService.hubRoutePath}/${props.hub.slug}`)
			}}
		>
			<FontAwesomeIcon icon={['fas', props.hub.icon as IconName]} size="2x" />
			<div className="mt-10" style={{ fontSize: '90%' }}>
				{props.hub.title}
			</div>
		</Paper>
	)
}
