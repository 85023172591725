import './side-sheet.scss'

import { IconButton } from '@components/icon-button/icon-button'
import { Scrim } from '@components/scrim/scrim'
import { SideSheetProps, SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import { Tabs } from '@components/tabs/tabs'
import { Typography } from '@components/text/text'
import React from 'react'
import ReactDOM from 'react-dom'

const SHOW_CLICK_INDICATOR = false

/** Overlays content on the right side of the page */
export function SideSheet(props: SideSheetProps) {
	/** ===================================== */
	/** Methods */

	function getStyle(): React.CSSProperties {
		const style: React.CSSProperties = {}

		if (typeof props.zIndex === 'number') {
			style.zIndex = props.zIndex
		} else {
			style.zIndex = 9000
		}

		return style
	}

	function onClickScrim(evt: React.MouseEvent<HTMLDivElement>): void {
		if (props.onScrimClick) {
			props.onScrimClick()
		}

		if (props.preventDefaultScrimClick) {
			return
		}

		if (props.persistent) {
			return
		}

		/** Call the onClose callback */
		props.onClose()

		/** Trigger a click event for the top element under this scrim */
		const eltsUnderScrim = document.elementsFromPoint(evt.clientX, evt.clientY)
		const topEltUnderScrim = eltsUnderScrim[1]
		if (topEltUnderScrim && topEltUnderScrim instanceof HTMLElement) {
			const simulatedEvent = new MouseEvent('click', {
				view: window,
				bubbles: evt.bubbles,
				cancelable: evt.cancelable,
				clientX: evt.clientX,
				clientY: evt.clientY,
				shiftKey: evt.shiftKey,
				ctrlKey: evt.ctrlKey,
				metaKey: evt.metaKey,
			})

			topEltUnderScrim.dispatchEvent(simulatedEvent)
		}

		/** Note: This is used for debugging the side sheet. It shows the user where the application perceives the click event to have occurred */
		if (SHOW_CLICK_INDICATOR) {
			if (topEltUnderScrim instanceof HTMLElement) {
				topEltUnderScrim.classList.add('scrim__click-indicator__elt')
			}
			const clickIndicator = document.createElement('div')
			clickIndicator.classList.add('scrim__click-indicator')
			clickIndicator.style.top = `${evt.clientY}px`
			clickIndicator.style.left = `${evt.clientX}px`
			document.body.appendChild(clickIndicator)
			setTimeout(() => {
				clickIndicator.remove()
				if (topEltUnderScrim instanceof HTMLElement) {
					topEltUnderScrim.classList.remove('scrim__click-indicator__elt')
				}
			}, 2000)
		}
	}

	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`side-sheet__component`)

		switch (props.width) {
			case 'normal':
				classes.push(`width-normal`)
				break
			case 'wide':
				classes.push(`width-wide`)
				break
			default:
				classes.push(`width-normal`)
				break
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	/** ===================================== */
	/** Render Component */

	function renderAction(action: SideSheetTypes.Action): React.ReactNode {
		return (
			<IconButton
				key={action.label}
				icon={typeof action.icon === 'string' ? action.icon : '0'}
				onClick={action.onClick}
				className="mr-5"
				disabled={typeof action.disabled === 'boolean' ? action.disabled : undefined}
				ref={action.ref}
			/>
		)
	}

	return (
		<>
			{ReactDOM.createPortal(
				<Scrim onClick={onClickScrim} isVisible={!props.persistent} zIndex={props.zIndex}>
					<div
						className={getClass()}
						onClick={(evt) => {
							evt.stopPropagation()
						}}
						style={getStyle()}
					>
						<div className="side-sheet__sticky-header">
							<div className={`title-bar ${props.tabs ? 'includes-tabs' : ''}`}>
								<div className="flex flex-alignItems-center">
									<IconButton
										icon={'times'}
										onClick={() => {
											props.onClose()
										}}
										className="mr-5"
									/>
									<Typography type="h5">{props.title}</Typography>
								</div>
								<div className="flex flex-alignItems-center">
									{props.actions.map((action) => {
										return renderAction(action)
									})}
								</div>
							</div>
							{props.tabs && <Tabs {...props.tabs} />}
						</div>
						<div className="side-sheet__body">
							<div className="flex-fillSpace">{props.children}</div>
						</div>
						{props.footer && (
							<div className="side-sheet__footer">
								<div className="flex-fillSpace">{props.footer}</div>
							</div>
						)}
					</div>
				</Scrim>,
				document.body,
			)}
		</>
	)
}
