import { Button } from '@components/button/button'
import { MultiColumnTableTypes } from '@components/multi-column-table/multi-column-table.types'
import { MultiColumnTable } from '@components/multi-column-table/multi-column-table__wrapper'
import { Typography } from '@components/text/text'
import { useEffect, useState } from 'react'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { TransactionMgmt__Notification__SideSheet } from '../../side-sheets/notification/notification.side-sheet'

export function TransactionAdmin__Route__NotificationAdmin() {
	const [selectedNotification, setSelectedNotification] = useState<
		TransactionMgmtTypes.NotificationTypes.Notification | 'NEW' | null
	>(null)
	const [showSideSheet, setShowSideSheet] = useState(false)
	const [allNotifications, setAllNotifications] = useState<
		TransactionMgmtTypes.NotificationTypes.Notification[] | null
	>(null)

	useEffect(() => {
		TransactionManagementAPI.findLibraryNotifications().then((res) => {
			setAllNotifications(res.data)
		})
	}, [])

	function getTransactionTableColumns(): MultiColumnTableTypes.Column<TransactionMgmtTypes.NotificationTypes.Notification>[] {
		const columns: MultiColumnTableTypes.Column<TransactionMgmtTypes.NotificationTypes.Notification>[] = []

		columns.push({
			label: 'Name',
			columnId: 'name',
			value: 'name',
			displayIndex: 0,
			visible: true,
			width: 'fill',
		})

		return columns
	}

	return (
		<>
			<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween col-xs-12">
				<Typography type="h2">Notifications</Typography>
				<Button variant="outlined" label="Create Notification" size="md" />
			</div>
			<MultiColumnTable<TransactionMgmtTypes.NotificationTypes.Notification>
				columns={getTransactionTableColumns()}
				idKey={'notificationId'}
				className="col-xs-12"
				items={allNotifications}
				isConfigurable={false}
				onSelect={(notification) => {
					setSelectedNotification(notification[0])
					setShowSideSheet(true)
				}}
				selectBehavior={'single'}
				selectedItems={[]}
				sortBehavior={{
					sortMethod: 'column-value',
					columnId: 'name',
				}}
				tableId={''}
			/>

			{showSideSheet && selectedNotification && (
				<TransactionMgmt__Notification__SideSheet
					mode={'EDITOR'}
					notification={typeof selectedNotification !== 'string' ? selectedNotification : null}
					showParentTransaction={false}
					onClose={() => {
						setShowSideSheet(false)
					}}
					onUpdate={() => {
						/** empty */
					}}
					onDelete={() => {
						/** empty */
					}}
				/>
			)}
		</>
	)
}
