/* eslint-disable react/jsx-pascal-case */
import { Button } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownTypes } from '@components/dropdown/dropdown.types'
import { SideSheet } from '@components/side-sheet/side-sheet'
import { SideSheetServices } from '@components/side-sheet/side-sheet.service'
import { SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import { SideSheet__Row } from '@components/side-sheet/side-sheet__row'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { PhoneInput } from '@components/text-input/phone-input'
import { TextInput } from '@components/text-input/text-input'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { GenericDeleteConfirmationModal } from 'src/modals/generic-delete-confirmation/generic-delete-confirmation'
import { AdAccountAPI } from 'src/services/advertising/ad-account.api'
import { AdvertisementAPI } from 'src/services/advertising/advertisement.api'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'
import { RoutingService } from 'src/services/routing/routing.service'
import { usStatesList } from 'src/services/state-list'
import { ToastService } from 'src/services/toast/toast.service'
import { Utilities } from 'src/services/utilities.service'

interface AdvertisingAccountSideSheetProps {
	account: AdvertisingTypes.Account | 'NEW'
	onClose: () => void
	onDelete: () => void
	onUpdate: (updatedAccount: AdvertisingTypes.Account) => void
}

export function AdvertisingAccountSideSheet(props: AdvertisingAccountSideSheetProps) {
	const navigate = useNavigate()
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
	const [modifiedState, setModifiedState] = useState(getInitialState())
	const [adsOwnedByAccount, setAdsOwnedByAccount] = useState<AdvertisingTypes.Advertisement[] | null>(null)
	const didUserModifyProps = !_.isEqual(props.account, modifiedState)
	const isAccountValid = getIsAccountValid()

	useEffect(() => {
		if (props.account === 'NEW') {
			setAdsOwnedByAccount([])
			return
		}

		AdvertisementAPI.getAds(props.account.accountId, { page: 0, size: 20 }).then((res) => {
			setAdsOwnedByAccount(res.data)
		})
	}, [])

	function getInitialState(): AdvertisingTypes.Account {
		if (props.account === 'NEW') {
			const defaultAccount: AdvertisingTypes.Account = {
				organizationName: '',
				address1: '',
				city: '',
				mailingState: '',
				contactFullname: '',
				contactPhone: '',
				contactEmail: '',
				stripeAccountId: '',
				address2: '',
				zip: '',
				accountId: -1,
			}
			return defaultAccount
		}
		return props.account
	}

	function createFooter(): React.ReactNode {
		return (
			<>
				<Button
					variant={'outlined'}
					label={'Cancel'}
					size={'md'}
					margins={['right']}
					onClick={() => {
						props.onClose()
					}}
				/>
				<Button
					variant={'contained'}
					label={'Save and Close'}
					disabled={!isAccountValid}
					size={'md'}
					onClick={() => {
						handleSaveAccount().then(() => {
							props.onClose()
							props.onUpdate(modifiedState)
						})
					}}
				/>
			</>
		)
	}

	function createActions(): SideSheetTypes.Action[] {
		const actions: SideSheetTypes.Action[] = []

		if (props.account !== 'NEW') {
			actions.push({
				label: `Delete Account`,
				icon: 'trash',
				onClick: () => {
					if (adsOwnedByAccount && adsOwnedByAccount.length > 0) {
						ToastService.create({
							type: 'WARNING',
							body: `This account cannot be deleted until all ads inside the account are removed`,
						})
						return
					}
					setShowDeleteConfirmationModal(true)
				},
			})
		}

		return actions
	}

	function onScrimClick(): void {
		if (!didUserModifyProps) {
			props.onClose()
			return
		}

		SideSheetServices.showDismissConfirmationToast({
			onDismiss: () => {
				return new Promise((resolve) => {
					resolve()
					props.onClose()
				})
			},
			onSaveAndClose: () => {
				return handleSaveAccount().then(() => {
					props.onClose()
					props.onUpdate(modifiedState)
				})
			},
		})
	}

	function handleSaveAccount(): Promise<void> {
		return new Promise((resolve) => {
			if (modifiedState.accountId < 0) {
				AdAccountAPI.createAccount(modifiedState).then(() => {
					resolve()
				})
			} else {
				AdAccountAPI.updateAccount(modifiedState.accountId, modifiedState).then(() => {
					resolve()
				})
			}
		})
	}

	function getMailingStateOptions(): DropdownTypes.Option<string>[] {
		return usStatesList.map((state) => {
			return {
				value: state.abbreviation,
				label: state.name,
			}
		})
	}

	function updateProperties(changes: Partial<AdvertisingTypes.Account>): void {
		const updatedProps: AdvertisingTypes.Account = { ...modifiedState, ...changes }
		setModifiedState(updatedProps)
	}

	function getIsAccountValid(): boolean {
		if (modifiedState.address1.length === 0) {
			return false
		}
		if (modifiedState.mailingState.length !== 2) {
			return false
		}
		if (!Utilities.isEmailValid(modifiedState.contactEmail)) {
			return false
		}
		if (modifiedState.organizationName.length === 0) {
			return false
		}
		if (modifiedState.zip.length < 5) {
			return false
		}
		if (modifiedState.city.length === 0) {
			return false
		}
		if (modifiedState.stripeAccountId.length === 0) {
			return false
		}
		return true
	}

	return (
		<>
			<SideSheet
				title={'Account'}
				actions={createActions()}
				onClose={props.onClose}
				persistent={false}
				footer={createFooter()}
				preventDefaultScrimClick={didUserModifyProps}
				onScrimClick={onScrimClick}
			>
				<SideSheet__Section label="General" mode="expandable" sectionKey="account__general">
					<SideSheet__Row direction="column" label="Name">
						<TextInput
							type="text"
							dataType="text"
							value={modifiedState.organizationName}
							width={'100%'}
							onChange={(updatedValue) => {
								updateProperties({ organizationName: updatedValue })
							}}
						/>
					</SideSheet__Row>
					<SideSheet__Row direction="column" label="Address">
						<div className="col-xs-12">
							<TextInput
								type="text"
								dataType="text"
								value={modifiedState.address1}
								placeholder="Address"
								width={'100%'}
								onChange={(updatedValue) => {
									updateProperties({ address1: updatedValue })
								}}
								margins={['bottom']}
							/>
							<TextInput
								type="text"
								dataType="text"
								value={modifiedState.address2}
								placeholder="Address"
								width={'100%'}
								onChange={(updatedValue) => {
									updateProperties({ address2: updatedValue })
								}}
								margins={['bottom']}
							/>
							<TextInput
								type="text"
								dataType="text"
								value={modifiedState.city}
								placeholder="City"
								width={'100%'}
								onChange={(updatedValue) => {
									updateProperties({ city: updatedValue })
								}}
								margins={['bottom']}
							/>
							<Dropdown<string>
								value={modifiedState.mailingState ? [modifiedState.mailingState] : []}
								width={'100%'}
								margins={['bottom']}
								searchable={true}
								options={getMailingStateOptions()}
								onSelect={(selectedValues) => {
									if (selectedValues.length > 0) {
										updateProperties({ mailingState: selectedValues[0] })
									}
								}}
							/>
							<TextInput
								type="text"
								dataType="text"
								value={modifiedState.zip}
								placeholder="ZIP"
								width={'100%'}
								onChange={(updatedValue) => {
									updateProperties({ zip: updatedValue })
								}}
							/>
						</div>
					</SideSheet__Row>
					{modifiedState.accountId > 0 && (
						<SideSheet__Row direction="row" label="Account ID">
							<div>{modifiedState.accountId}</div>
						</SideSheet__Row>
					)}
				</SideSheet__Section>
				<SideSheet__Section label="Contact Information" mode="expandable" sectionKey="account__contact-info">
					<SideSheet__Row direction="column" label="Full Name">
						<TextInput
							type="text"
							dataType="text"
							value={modifiedState.contactFullname}
							width={'100%'}
							onChange={(updatedValue) => {
								updateProperties({ contactFullname: updatedValue })
							}}
						/>
					</SideSheet__Row>
					<SideSheet__Row direction="column" label="Email Address">
						<TextInput
							type="text"
							dataType="text"
							value={modifiedState.contactEmail}
							width={'100%'}
							onChange={(updatedValue) => {
								updateProperties({ contactEmail: updatedValue })
							}}
						/>
					</SideSheet__Row>
					<SideSheet__Row direction="column" label="Phone Number">
						<PhoneInput
							dataType={'number'}
							value={parseFloat(modifiedState.contactPhone)}
							width={'100%'}
							onChange={(updatedValue) => {
								updateProperties({ contactPhone: String(updatedValue) })
							}}
						/>
					</SideSheet__Row>
					<SideSheet__Row direction="column" label="Stripe">
						<TextInput
							type="text"
							dataType="text"
							value={modifiedState.stripeAccountId}
							width={'100%'}
							onChange={(updatedValue) => {
								updateProperties({ stripeAccountId: updatedValue })
							}}
						/>
					</SideSheet__Row>
				</SideSheet__Section>

				<Button
					className="col-xs-12"
					variant={'contained'}
					label={'Open Account'}
					disabled={modifiedState.accountId < 0}
					size={'lg'}
					onClick={() => {
						navigate(`${RoutingService.settingsRoutePath}/ad-account/${modifiedState.accountId}/general`)
					}}
				/>
			</SideSheet>
			{showDeleteConfirmationModal && (
				<GenericDeleteConfirmationModal
					itemLabel={modifiedState.organizationName}
					onDelete={async () => {
						await AdAccountAPI.deleteAccount(modifiedState)
						setShowDeleteConfirmationModal(false)
						props.onDelete()
						props.onClose()
					}}
					onClose={() => {
						setShowDeleteConfirmationModal(false)
					}}
				/>
			)}
		</>
	)
}
