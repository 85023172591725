import './home.scss'

import { Button } from '@components/button/button'
import { EventCalendar } from '@components/event-calendar/event-calendar'
import { GetSupportSection } from '@components/get-support/get-support'
import { InstagramFeed } from '@components/instagram-feed/instagram-feed'
import { NewsFeedStoryList } from '@components/news-feed__story-list/news-feed__story-list'
import { Paper } from '@components/paper/paper'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { RootState } from '@redux/store'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { DOMAIN_ID } from 'src/services/domain/domain.api'
import { HomepageModalSettingsAPI } from 'src/services/homepage-modal-settings/homepage-modal-settings.api'
import { HomepageModalSettings } from 'src/services/homepage-modal-settings/homepage-modal-settings.types'

import { NewsFeedAPI } from '../../services/news-feed/news-feed.api'
import { NewsFeed } from '../../services/news-feed/news-feed.types'
import { StripeAPI } from '../../services/stripe/stripe.api'
import { EndUserProps } from '../../services/user/user.types'
import { CalendarSelectorDropdown } from './components/calendar-selector-dropdown'
import { DownloadCalendarButton } from './components/download-calendar-button/download-calendar-button'
import { FeaturedHubs } from './components/featured-hubs/featured-hubs'
import { FeaturedResources } from './components/featured-resources/featured-resources'
import { HomeRouteTutorial } from './components/home-tutorial'
import LegacySubscriberWelcomeModal from './legacy-subscriber-welcome-modal'
import { HomepageModal } from './modal-notices/homepage.modal'
// import { HomepageModal } from './modal-notices/homepage.modal'

function HomeRoutePrototype(props: { user: EndUserProps | null }) {
	const navigate = useNavigate()
	const [newsFeedStories, setNewsFeedStories] = useState<NewsFeed.Story[] | null>(null)
	const [selectedCalendar, setSelectedCalendar] = useState<null | number>(null)
	const [isUserAdmin, setIsUserAdmin] = useState(false)
	const isUserLegacy = props.user?.legacySubscriber
	const [showLegacyModal, setShowLegacyModal] = useState(false)
	const [showHomepageModal, setShowHomepageModal] = useState(false)
	const userSuperAdmin = props.user?.roles.some((role) => role.roleId === 4)

	const [modalProps, setmodalProps] = useState<HomepageModalSettings.CompleteResponse | null>(null)
	const currentTimestamp = new Date().toISOString()

	useEffect(() => {
		HomepageModalSettingsAPI.getForUser(DOMAIN_ID).then((res) => {
			setmodalProps(res.data)
		})
	}, [])

	useEffect(() => {
		// Check if user is admin
		if (props.user?.roles.some((role) => role.roleId === 1)) {
			setIsUserAdmin(true)
		}

		if (isUserLegacy && !isUserAdmin) {
			// Only check for legacy if the user is not an admin
			StripeAPI.fetchCustomerPaymentMethods()
				.then((res) => {
					if (res) {
						const cardCount = res.filter((method) => method.type === 'card').length

						if (cardCount < 1) {
							const hasSeenModal = localStorage.getItem('hasSeenLegacyModal')
							if (!hasSeenModal) {
								setShowLegacyModal(true)
							}
						}
					}
				})
				.catch((error) => {
					console.log('Stripe API Error:', error)
				})
		}
	}, [props.user, isUserLegacy])

	useEffect(() => {
		if (modalProps && modalProps.modalEnabled) {
			const homeModalData = localStorage.getItem('homeModal')

			if (homeModalData) {
				const { hasSeenHomeModal, timestamp } = JSON.parse(homeModalData)

				if (!hasSeenHomeModal || modalProps.lastUpdated > timestamp) {
					setShowHomepageModal(true)
				} else {
					console.log('Do not show the modal')
				}
			} else {
				setShowHomepageModal(true)
				const newTimestamp = new Date().toISOString()
				localStorage.setItem('homeModal', JSON.stringify({ hasSeenHomeModal: true, timestamp: newTimestamp }))
			}
		}
	}, [modalProps])

	/** Retrieve recent news feed stories */
	useEffect(() => {
		NewsFeedAPI.getStories({
			page: 0,
			size: 3,
			sort: [{ property: 'createdTimestamp', direction: 'desc' }],
		}).then((getStoriesRes) => {
			setNewsFeedStories(getStoriesRes.data.items)
		})
	}, [])

	/** ============================================ */
	/** Methods */

	function getRecentNews(): React.ReactNode {
		if (!newsFeedStories) {
			return <></>
		} else {
			return (
				<>
					<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-10">
						<Typography type="h5" color="secondary">
							Recent News
						</Typography>
						<Button variant="text" size={'lg'} label="See All" to="/news-feed" />
					</div>
					<Paper bgColor="primary" marginSize="section" margins={['bottom']} elevate={true}>
						{newsFeedStories.length === 0 && (
							<Typography type="h6" className="p-10">
								No stories found
							</Typography>
						)}
						<NewsFeedStoryList
							stories={newsFeedStories}
							style={{ height: '400px' }}
							className="p-10"
							viewMode="SMALL"
						/>
					</Paper>
				</>
			)
		}
	}

	function getSocialMediaFeed(): React.ReactNode {
		return (
			<>
				<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-10">
					<Typography type="h5" color="secondary">
						Social Media Feed
					</Typography>
				</div>
				<Paper bgColor="primary" elevate={true}>
					<InstagramFeed />
				</Paper>
			</>
		)
	}

	function getLicensedStateIdForCurrentUser(): number[] {
		if (!props.user) {
			return []
		}

		return props.user.licenseInformation.map((license) => {
			return license.licensedState.licensedStateId
		})
	}

	function getCalendar(): React.ReactNode {
		return (
			<>
				<div className="mb-10">
					<Typography type="h5" color="secondary" className="mr-20">
						Calendar
					</Typography>
				</div>
				<Paper bgColor="primary" marginSize="section" margins={['bottom']} padding={['all']} elevate={true}>
					<CalendarSelectorDropdown
						selectedCalendarId={selectedCalendar}
						onSelect={(value) => {
							setSelectedCalendar(value)
						}}
					/>
					<EventCalendar
						initialView="listWeek"
						style={{ height: '400px' }}
						calendarId={typeof selectedCalendar === 'number' ? selectedCalendar : 'all'}
						filterByTags={[]}
						filterByLicensedStates={getLicensedStateIdForCurrentUser()}
						onEventClick="open-details"
					/>
					<div className="flex flex-justifyContent-spaceBetween">
						<DownloadCalendarButton selectedCalendarId={selectedCalendar} />
						<Button
							margins={['top']}
							variant="text"
							size="md"
							label="View Full Calendar"
							onClick={() => {
								navigate('/calendar')
							}}
						/>
					</div>
				</Paper>
			</>
		)
	}

	function getTimeOfDay(): string {
		const date = new Date()
		const current_hour = date.getHours()

		if (current_hour < 12) {
			return 'morning'
		} else if (current_hour >= 12 && current_hour < 17) {
			return 'afternoon'
		} else {
			return 'evening'
		}
	}

	function getSupport(): React.ReactNode {
		return (
			<>
				<div className="mb-10">
					<Typography type="h5" color="secondary" className="mr-20">
						Get Support
					</Typography>
				</div>
				<Paper bgColor="primary" marginSize="section" margins={['bottom']} elevate={true}>
					<GetSupportSection />
				</Paper>
			</>
		)
	}

	/** ============================================ */
	/** Render Component */

	return (
		<>
			<RouteWrapperV2>
				<RouteBodyV2 className="flex-lg pb-40">
					<div className="hub__column__main">
						<Typography type="h1" margins={['bottom']} className="show-md">
							Good {getTimeOfDay()}, {props.user?.firstName}
						</Typography>

						<div className="flex flex-alignItems-center mb-20">
							<Typography type="h5" color="secondary" className="mr-20">
								Popular Hubs
							</Typography>
							<Button variant="text" size="md" label="See all" to="/hubs/explore" />
						</div>
						<div className="col-xs-12 mb-50 flex flex-wrap flex-justifyContent-spaceBetween-xs-down">
							<FeaturedHubs />
						</div>

						<Typography type="h4" color="secondary" className="mb-20">
							Popular Resources
						</Typography>
						<div className="col-xs-12 mb-50 flex flex-wrap">
							<FeaturedResources showHiddenResources={userSuperAdmin} />
						</div>

						{getSocialMediaFeed()}
					</div>

					<div className="hub__column__sidebar">
						{getSupport()}
						{getCalendar()}
						{getRecentNews()}
					</div>
					{showLegacyModal && !isUserAdmin && (
						<>
							<LegacySubscriberWelcomeModal
								dismissModal={() => {
									setShowLegacyModal(false)
									localStorage.setItem('hasSeenLegacyModal', 'true')
								}}
								currentUser={props.user}
							/>
						</>
					)}
					{showHomepageModal && modalProps && (
						<HomepageModal
							dismissModal={() => {
								setShowHomepageModal(false)
								localStorage.setItem(
									'homeModal',
									JSON.stringify({ hasSeenHomeModal: true, timestamp: currentTimestamp }),
								)
							}}
							modalProps={modalProps}
						/>
					)}
				</RouteBodyV2>
			</RouteWrapperV2>

			<HomeRouteTutorial />
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		user: state.user,
	}
}

export const HomeRoute = connect(mapStateToProps)(HomeRoutePrototype)
