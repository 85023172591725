import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'
import { Typography } from '@components/text/text'

import HomeVisitLogo from './homevisit_white.webp'

export function LoKationResourcePhotographerRoute() {
	return (
		<div className="flex flex-column overflow-y__scroll">
			<div className="flex flex-wrap">
				<div className="col-xs-12 col-sm-6 pr-20-sm">
					<div className="text-center col-xs-12">
						<img src={HomeVisitLogo} style={{ height: '80px' }} />
						<Typography type="h5">Your Photos. Perfected.</Typography>
					</div>
					<HorizontalRule className="mt-20" />
					<p>
						HomeVisit™ provides integrated solutions that help real estate professionals do more with their
						marketing. With solutions spanning photography, video, print, 3D modeling, virtual tours, direct
						mail and single property websites, HomeVisit provides agents and brokers with the tools they
						need in a single workflow so they can get more leads and win more listings.
					</p>
					<p>
						Use stunning visual media to re-energize an existing listing or showcase a new one. HomeVisit
						offers all the tools you need to make listings stand out - all in one place! Integration with
						Matrix™ and Realist® makes it even more convenient to add more quality and value to your
						listings.
					</p>
				</div>
				<div className="col-xs-12 col-sm-6 pl-20-sm">
					<Typography type="h5" className="mb-10">
						HomeVisit Promo Codes
					</Typography>
					<div className="bg-color__adjust-alpha-10 border-radius__std p-10 mb-10">
						<Typography type="h6">Florida</Typography>
						<ul>
							<li>10% OFF ON ALL ORDERS</li>
							<li>MIAMI MLS: LOKATIONSEF</li>
							<li>BEACHES: LOKATIONBEACHES</li>
							<li>ALL OTHER AREAS: LOKATION10</li>
						</ul>
					</div>

					<div className="bg-color__adjust-alpha-10 border-radius__std p-10 mb-10">
						<Typography type="h6">Colorado</Typography>
						<ul>
							<li>10% OFF ALL ORDERS</li>
							<li>PPAR MLS: LOKATIONPPAR</li>
							<li>ALL OTHER AREAS: LOKATION10 </li>
						</ul>
					</div>

					<div className="bg-color__adjust-alpha-10 border-radius__std p-10 mb-10">
						<Typography type="h6">Georgia</Typography>
						<ul>
							<li>10% OFF ALL ORDERS: LOKATION10</li>
						</ul>
					</div>

					<div className="bg-color__adjust-alpha-10 border-radius__std p-10 mb-10">
						<Typography type="h6">Texas</Typography>
						<ul>
							<li>10% OFF ALL ORDERS: LOKATION10</li>
						</ul>
					</div>
				</div>

				<div className="flex mt-40">
					<button
						className="button contained lg primary mr-10"
						onClick={() => {
							window.open('https://app.homevisit.com/login', '_blank')
						}}
					>
						Go To HomeVisit Portal
					</button>
					<button
						className="button contained lg primary"
						onClick={() => {
							window.open('https://lokation.thinkific.com/courses/homevisit-by-corelogic', '_blank')
						}}
					>
						Overview Training
					</button>
				</div>
			</div>
		</div>
	)
}
