import { BoxTypes } from '@components/box/box.types'

import { SharedElementTypes } from './shared-element.types'

export namespace SharedElementService {
	/** Returns class names for the amount of padding to be applied to the element */
	export function getPaddingClass(props: { padding: SharedElementTypes.Directions[] }): string[] {
		const classes: string[] = []

		if (props.padding?.includes('all')) {
			classes.push(`p-10`)
		}

		if (props.padding?.includes('top')) {
			classes.push(`pt-10`)
		}

		if (props.padding?.includes('bottom')) {
			classes.push(`pb-10`)
		}

		if (props.padding?.includes('left')) {
			classes.push(`pl-10`)
		}

		if (props.padding?.includes('right')) {
			classes.push(`pr-10`)
		}

		return classes
	}

	/** Get classes that are used to construct a flex container */
	export function getFlexClasses(
		props: Pick<BoxTypes.FlexComponent, 'flex' | 'gap' | 'wrap' | 'justifyContent' | 'alignItems'>,
	): string[] {
		const classes: string[] = []

		if (props.flex) {
			classes.push(`flex`)
			switch (props.flex) {
				case 'column':
					classes.push(`flex-column`)
					break
				case 'row':
					classes.push(`flex-row`)
					break
			}

			if (props.gap) {
				classes.push(`std__gap`)
			}

			if (props.wrap) {
				classes.push(`flex-wrap`)
			}

			switch (props.justifyContent) {
				case 'start':
					classes.push(`flex-justifyContent-start`)
					break
				case 'end':
					classes.push(`flex-justifyContent-end`)
					break
				case 'space-between':
					classes.push(`flex-justifyContent-spaceBetween`)
					break
				case 'center':
					classes.push(`flex-justifyContent-center`)
			}

			switch (props.alignItems) {
				case 'start':
					classes.push(`flex-alignItems-center`)
					break
				case 'end':
					classes.push(`flex-alignItems-end`)
					break
				case 'center':
					classes.push(`flex-alignItems-center`)
					break
			}
		}
		return classes
	}

	/** Returns class names for the amount of margins to be applied to the element */
	export function getMarginClass(props: {
		margins: SharedElementTypes.Directions[]
		marginSize?: SharedElementTypes.MarginSizing
	}): string[] {
		const classes: string[] = []

		if (props.margins) {
			props.margins.forEach((direction) => {
				switch (direction) {
					case 'top':
						switch (props.marginSize) {
							case 'section':
								classes.push('mt-30')
								break
							case 'element':
							default:
								classes.push('mt-10')
								break
						}
						break
					case 'bottom':
						switch (props.marginSize) {
							case 'section':
								classes.push('mb-30')
								break
							case 'element':
							default:
								classes.push('mb-10')
								break
						}
						break
					case 'left':
						switch (props.marginSize) {
							case 'section':
								classes.push('ml-30')
								break
							case 'element':
							default:
								classes.push('ml-10')
								break
						}
						break
					case 'right':
						switch (props.marginSize) {
							case 'section':
								classes.push('mr-30')
								break
							case 'element':
							default:
								classes.push('mr-10')
								break
						}
						break
					case 'all':
						switch (props.marginSize) {
							case 'section':
								classes.push('m-30')
								break
							case 'element':
							default:
								classes.push('m-10')
								break
						}
						break
				}
			})
		}

		return classes
	}
}
