import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { useEffect, useState } from 'react'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { TransactionMgmt__Document__SideSheet } from '../side-sheets/document/document.side-sheet'

export function TransactionMgmt__Landing__Section__RecentlyViewed() {
	const [recentlyViewedItems, setRecentlyViewedItems] = useState<
		TransactionMgmtTypes.DocumentTypes.Document[] | null
	>(null)
	const [selectedItem, setSelectedItem] = useState<
		TransactionMgmtTypes.TaskTypes.Task | TransactionMgmtTypes.DocumentTypes.Document | null
	>(null)
	const [showItemSideSheet, setShowItemSideSheet] = useState(false)

	useEffect(() => {
		TransactionManagementAPI.findDocuments().then((res) => {
			setRecentlyViewedItems(res.data.slice(0, 5))
		})
	}, [])

	return (
		<>
			<Typography type="h6" margins={['bottom']}>
				Recently Viewed
			</Typography>
			{!recentlyViewedItems && <GenericContentLoader height={55} width={'fill'} />}
			{recentlyViewedItems &&
				recentlyViewedItems.map((item) => {
					return (
						<Paper
							bgColor="primary"
							elevate={true}
							padding={['all']}
							margins={['bottom']}
							className="flex flex-alignItems-center flex-justifyContent-spaceBetween"
							interactive={true}
							onClick={() => {
								setSelectedItem(item)
								setShowItemSideSheet(true)
							}}
							key={item.documentId}
						>
							<Typography type="semibold">{item.name}</Typography>
						</Paper>
					)
				})}
			{showItemSideSheet && selectedItem && 'documentId' in selectedItem && (
				<TransactionMgmt__Document__SideSheet
					document={selectedItem}
					showParentTransaction={true}
					onClose={() => {
						setShowItemSideSheet(false)
					}}
					onUpdate={(savedTask) => {
						setShowItemSideSheet(false)
					}}
					onDelete={(taskId) => {
						setShowItemSideSheet(false)
					}}
				/>
			)}
		</>
	)
}
