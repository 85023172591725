import { Button } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from '@redux/store'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useSearchParams } from 'react-router'
import { DateService } from 'src/services/date.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'
import { UserAPI } from 'src/services/user/user.api'
import { UserService } from 'src/services/user/user.service'
import { EndUserProps } from 'src/services/user/user.types'

import { UpdateBody__AddContact } from './update-body__add-contact'
import { UpdateBody__Task } from './update-body__task'

type ComponentProps = {
	update: TransactionMgmtTypes.UpdateTypes.Update
	parentTaskId: number | null
	parentDocumentId: number | null
	parentTransaction: TransactionMgmtTypes.TransactionTypes.Transaction
	condensed: boolean
}

type ConnectedProps = {
	currentUser: EndUserProps | null
}

/** Creates a card to show an update to a task / document / transaction / etc. */
function UpdateCardPrototype(props: ComponentProps & ConnectedProps) {
	const [searchParams, setSearchParams] = useSearchParams()
	const [senderEndUserProps, setSenderEndUserProps] = useState<EndUserProps | null>(null)
	const updateTimestamp = DateService.getFormattedDateFromDateObj(new Date(props.update.createTimestamp))

	useEffect(() => {
		UserAPI.getUserDetails(props.update.senderEndUserId).then((res) => {
			if (res.data) {
				setSenderEndUserProps(res.data)
			}
		})
	}, [])

	function getHeader(): React.ReactElement {
		if (!senderEndUserProps) {
			return <GenericContentLoader width={'fill'} height={15} />
		}

		let headerMessage: React.ReactNode = <></>

		switch (props.update.type) {
			case 'COMMENT':
				if (props.parentTaskId) {
					headerMessage = <>added a comment to task {props.parentTaskId}</>
					break
				}
				if (props.parentDocumentId) {
					headerMessage = <>added a comment to a document {props.parentDocumentId}</>
					break
				}
				headerMessage = `added a comment`
				break
			case 'CONTACT_ADDED':
				headerMessage = <UpdateBody__AddContact update={props.update} />
				break
			case 'EMAIL_SENT':
				headerMessage = `sent an email to ${props.update.recipientEmail}`
				break
			case 'TASK_ADDED':
				headerMessage = (
					<UpdateBody__Task
						update={props.update}
						taskId={props.update.taskId}
						parentTransaction={props.parentTransaction}
					/>
				)
				break
			case 'TASK_COMPLETED':
			case 'TASK_REASSIGNED':
				if (!props.parentTaskId) {
					throw new Error(`Cannot get parent task ID`)
				}
				headerMessage = (
					<UpdateBody__Task
						update={props.update}
						taskId={props.parentTaskId}
						parentTransaction={props.parentTransaction}
					/>
				)
				break
		}

		return (
			<div className="flex flex-justifyContent-spaceBetween">
				<div className={props.condensed ? 'flex flex-column' : 'flex'}>
					<Typography type="strong" margins={['right']}>
						<Button
							variant="text"
							size="md"
							label={`${senderEndUserProps.firstName} ${senderEndUserProps.lastName}`}
							onClick={() => {
								searchParams.set('user', String(props.update.senderEndUserId))
								setSearchParams(searchParams)
							}}
						/>
						{` `}
						{headerMessage}
					</Typography>
					<Typography type="normal" variant={['italic']} color="secondary">
						{updateTimestamp}
					</Typography>
				</div>
				{props.update.visibility === 'ADMIN' &&
					props.currentUser &&
					UserService.isUserAdmin(props.currentUser) && (
						<div className="flex flex-alignItems-center opacity-60">
							<FontAwesomeIcon icon={['far', 'lock']} />
							<Typography type="normal" margins={['left']} variant={['italic']} color="primary">
								Only visible to admins
							</Typography>
						</div>
					)}
			</div>
		)
	}

	function getBody(): React.ReactElement {
		const updateTypesWithEmptyBody: TransactionMgmtTypes.UpdateTypes.Type[] = [
			'CONTACT_ADDED',
			'EMAIL_SENT',
			'TASK_ADDED',
			'TASK_COMPLETED',
			'TASK_REASSIGNED',
		]

		if (props.update.type === 'COMMENT') {
			return <div>{props.update.body}</div>
		}

		if (updateTypesWithEmptyBody.includes(props.update.type)) {
			return <></>
		}
		return <>ERROR</>
	}

	return (
		<Paper
			bgColor="white"
			padding={['all']}
			margins={['bottom']}
			className="flex flex-column"
			style={{ gap: '10px' }}
		>
			{getHeader()}
			{getBody()}
		</Paper>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const UpdateCard = connect(mapStateToProps)(UpdateCardPrototype)
