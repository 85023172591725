import { Button } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { ItemSearchTagFilter } from '@components/item-search__tag-filter/item-search__tag-filter'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIState } from '@redux/reducers/ui-reducer'
import { RootState, store } from '@redux/store'
import { AxiosResponse } from 'axios'
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'

import { EditHubRoute } from '../../../modals/admin-editors/edit-hub/edit-hub'
import useAdminRedirect from '../../../services/hooks/user-admin-redirect'
import { HubsAPI } from '../../../services/hubs/hubs.api'
import { HubsService } from '../../../services/hubs/hubs.service'
import { Hub, HubSearchParams } from '../../../services/hubs/hubs.types'
import { LokationTag } from '../../../services/tags/tag.types'
import { EndUserProps } from '../../../services/user/user.types'
import { GetItemsResponse, SortParam } from '../../../services/utility.types'
import { useItemAdminHook } from '../use-item-admin-hook'
import { HubAdminCard } from './hub-admin__card/hub-admin__card'

type ConnectedProps = {
	userProfile: EndUserProps | null
	ui: UIState
}

function HubAdministrationRoute__Prototype(props: ConnectedProps) {
	const navigate = useNavigate()
	const [selectedTags, updateSelectedTags] = useState<LokationTag[]>(store.getState().tags)
	const [selectedHub, updateSelectedHub] = useState<Hub | 'NEW_HUB' | null>(null)
	const [isPending, setIsPending] = useState(true)

	useAdminRedirect(props.userProfile)

	function getHubs(
		page: number,
		size: number,
		sort: SortParam<Hub>[],
		filterProps: HubSearchParams,
	): Promise<AxiosResponse<GetItemsResponse<Hub>>> {
		const getHubsPromise = HubsAPI.findHubs({ page, size, sort }, { tags: filterProps.tags })

		return getHubsPromise
	}

	const hubAdminHook = useItemAdminHook<Hub, HubSearchParams>({
		getItemsFunction: getHubs,
		searchParams: { tags: selectedTags.map((tag) => tag.tagId) },
		itemIdKey: 'hubId',
		itemTitleKey: 'title',
		setIsPending: (state) => {
			setIsPending(state)
		},
		isItemShownInList: (item) => {
			return HubsService.doesHubIncludeTag(item, selectedTags)
		},
	})

	return (
		<>
			<RouteWrapperV2>
				<RouteSubnavigation
					title={`Hub Administration`}
					backButton={{
						label: `Administration`,
						onClick: () => {
							navigate('/administration')
						},
					}}
					isCustom={true}
				>
					<ItemSearchTagFilter
						selectedTags={selectedTags}
						onChange={(value) => {
							updateSelectedTags(value)
						}}
					/>
				</RouteSubnavigation>

				<RouteBodyV2 id="hub-admin__route-body">
					<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-10">
						<Typography type="h1">Hub Administration</Typography>
						<Button
							variant="outlined"
							label="Create Hub"
							size={'md'}
							onClick={() => {
								updateSelectedHub('NEW_HUB')
							}}
						/>
					</div>
					<div className="mb-20 opacity-50">{hubAdminHook.totalElements} Results</div>
					{!isPending && !hubAdminHook.hasMoreItems && hubAdminHook.items.length === 0 && (
						<div className="flex flex-column flex-fillSpace flex-justifyContent-center flex-alignItems-center">
							<FontAwesomeIcon icon={['far', 'newspaper']} size="4x" className="mb-20" />
							<Typography type="h3">We cannot find any hubs matching your criteria</Typography>
						</div>
					)}
					<InfiniteScroll
						dataLength={hubAdminHook.items.length}
						next={hubAdminHook.loadNextPageOfItems}
						hasMore={hubAdminHook.hasMoreItems}
						scrollableTarget={props.ui.viewMode === 'FULL' ? `hub-admin__route-body` : undefined}
						loader={
							<>
								<GenericContentLoader width={'fill'} height={70} className="mb-10" />
								<GenericContentLoader width={'fill'} height={70} className="mb-10" />
								<GenericContentLoader width={'fill'} height={70} className="mb-10" />
							</>
						}
					>
						{hubAdminHook.items.map((hub) => {
							return (
								<HubAdminCard
									hub={hub}
									key={hub.title}
									onClick={() => {
										updateSelectedHub(hub)
									}}
								/>
							)
						})}
					</InfiniteScroll>
				</RouteBodyV2>
			</RouteWrapperV2>

			{selectedHub === 'NEW_HUB' && (
				<EditHubRoute
					dismissModal={() => {
						updateSelectedHub(null)
					}}
					onCreate={(hub) => {
						hubAdminHook.handleCreateItem(hub)
					}}
				/>
			)}

			{selectedHub && typeof selectedHub !== 'string' && (
				<EditHubRoute
					item={selectedHub}
					dismissModal={() => {
						updateSelectedHub(null)
					}}
					onUpdate={(hub) => {
						hubAdminHook.handleUpdateItem(hub)
					}}
					onDelete={(hub) => {
						hubAdminHook.handleDeleteItem(hub)
					}}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
		ui: state.ui,
	}
}

export const HubAdministrationRoute = connect(mapStateToProps)(HubAdministrationRoute__Prototype)
