import { Button } from '@components/button/button'
import { Checkbox } from '@components/checkbox/checkbox'
import { CollapsibleContent } from '@components/collapsible-content/collapsible-content'
import { DropdownTypes } from '@components/dropdown/dropdown.types'
import _ from 'lodash'
import React from 'react'

import { TransactionMgmt__Tasks__Types } from '../tasks.types'

export type ComponentProps = {
	selectedTypes: TransactionMgmt__Tasks__Types.TaskType[]
	onSelect: (selectedTypes: TransactionMgmt__Tasks__Types.TaskType[]) => void
	style?: React.CSSProperties
	className?: string
	isMulti: boolean
	expanded?: true
}

export function TaskTypeFilter(props: ComponentProps) {
	function handleUpdateSelectedTags(
		newState: TransactionMgmt__Tasks__Types.TaskType,
		isStateSelected: boolean,
	): void {
		let updatedState = _.cloneDeep(props.selectedTypes)
		if (isStateSelected === true) {
			if (props.isMulti) {
				updatedState.push(newState)
				props.onSelect(updatedState)
			} else {
				props.onSelect([newState])
			}
		}
		if (isStateSelected === false) {
			if (props.isMulti) {
				props.onSelect(
					updatedState.filter((existingTag) => {
						return existingTag !== newState
					}),
				)
			} else {
				props.onSelect([])
			}
		}
	}

	function createFilterSide(typeLabel: string, typeValue: TransactionMgmt__Tasks__Types.TaskType): JSX.Element {
		const isChecked = props.selectedTypes.includes(typeValue)
		return (
			<div className="search-filter__option" key={typeValue}>
				<Checkbox
					checked={isChecked}
					onChange={(checkedState) => {
						handleUpdateSelectedTags(typeValue, checkedState)
					}}
				>
					{typeLabel}
				</Checkbox>
			</div>
		)
	}

	function getAllTaskTypes(): DropdownTypes.ChildOption<TransactionMgmt__Tasks__Types.TaskType>[] {
		return [
			{
				label: `Task`,
				value: 'TASK',
			},
			{
				label: 'Notification',
				value: 'NOTIFICATION',
			},
		]
	}

	return (
		<div>
			<CollapsibleContent label="Side" controlled={true} id={`tasks__side`} headerPadding={['all']}>
				<>
					<div className={`base-search-filter ${props.className ? props.className : ''}`} style={props.style}>
						{getAllTaskTypes().map((side) => {
							return createFilterSide(side.label, side.value)
						})}
						{props.isMulti && (
							<div>
								<Button
									variant="text"
									size="md"
									label="Select None"
									className="mr-20"
									onClick={() => {
										props.onSelect([])
									}}
								/>
								<Button
									variant="text"
									size="md"
									label="Select All"
									onClick={() => {
										props.onSelect(getAllTaskTypes().map((type) => type.value))
									}}
								/>
							</div>
						)}
					</div>
				</>
			</CollapsibleContent>
		</div>
	)
}
