import { AgentLicenseCardService } from '@components/agent-license-card/agent-license-card.service'
import { Button } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownTypes } from '@components/dropdown/dropdown.types'
import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'
import { ReferralSearchAutocomplete } from '@components/referral-search-autocomplete/referral-search-autocomplete'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { LeadCaptureAPI } from 'src/services/lead-capture/lead-capture.api'
import { LeadCaptureTypes } from 'src/services/lead-capture/lead-capture.types'

import { MLSBoard } from '../../../../services/mls-board/mls-board.types'
import { LicensedRegionsService } from '../../../../services/regions/regions.service'
import { AgentLicense, EndUserFirstLast } from '../../../../services/user/user.types'
import { useFormValidation, UseFormValidationField } from '../../../../services/validation/use-form-validation'
import { RegistrationAgentLicense } from '../../components/agent-license/agent-license'
import { RegistrationService } from '../../registration.service'
import { Registration } from '../../registration.types'
import { useRegistration, useRegistrationDispatch } from '../../state/registration__state'

export function RegistrationAgentInfo() {
	/** ================================================= */
	/** Props, State, and Hooks */

	const regState = useRegistration()
	const regDispatch = useRegistrationDispatch()
	const totalServicesCosts = RegistrationService().getCosts(regState.fields)
	const selectedMLSOption = getSelectedMLSOptions()
	const selectedReferralTypeOption = getSelectedReferralTypeOption()
	const returnedMlsBoardOptions = getMLSBoardOptions()
	const isOnlyParent = returnedMlsBoardOptions.length === 1
	const isUserLicensedInSameStateMoreThanOnce = getIsUserLicensedInSameStateMoreThanOnce()
	const isUserLicensedInNoStates = regState.fields.licenseInfo.length === 0
	const [proratedCost, setProratedCost] = useState(0)
	let hasBluePlan = false
	let monthlyCost = 0
	let quarterlyCost = 0
	let totalDue = 0
	const [listVisible, setListVisible] = useState(true)
	const [referralSearchValue, setReferralSearchValue] = useState<string>('')

	const promotionCode = localStorage.getItem('promotionCode')

	// Get today's date
	const today = new Date()

	// Add 60 days
	const futureDate = new Date(today)
	futureDate.setDate(futureDate.getDate() + 60)

	// Format the date as MM-DD-YYYY
	const futureDateString =
		(futureDate.getMonth() + 1).toString().padStart(2, '0') +
		'-' +
		futureDate.getDate().toString().padStart(2, '0') +
		'-' +
		futureDate.getFullYear()

	const { getField, isFormValid, updateField } = useFormValidation<string>({
		fields: getFieldsForValidation(),
	})

	/** ================================================= */
	/** Effects */

	/** Whenever a user interacts with a field, state 'invalidFields' is updated. Here, we push the overall validation of the tab to the registration route */
	useEffect(() => {
		if (isFormValid) {
			regDispatch({ type: 'set-validation-state', payload: true })
		} else {
			regDispatch({ type: 'set-validation-state', payload: false })
		}
	}, [isFormValid])

	useEffect(() => {
		if (regState.fields.licenseInfo.length === 0) {
			addAgentLicense()
		}
	}, [])

	useEffect(() => {
		calculateProratedCost()
	}, [quarterlyCost, regState.fields.licenseInfo])

	/** ================================================= */
	/** Methods */

	function getFieldsForValidation(): UseFormValidationField<string, any>[] {
		const fields: UseFormValidationField<string, any>[] = []

		fields.push({
			name: 'multiple-states',
			validation: (value) => {
				return isUserLicensedInSameStateMoreThanOnce === false
			},
			message: 'Cannot be empty',
			value: isUserLicensedInSameStateMoreThanOnce,
		})
		fields.push({
			name: 'no-states',
			validation: (value) => {
				return isUserLicensedInNoStates === false
			},
			message: 'Cannot be empty',
			value: isUserLicensedInNoStates,
		})
		fields.push({
			name: 'numYearsLicensed',
			validation: (value) => {
				return value.length > 0
			},
			message: 'Cannot be empty',
			value: regState.fields.numYearsLicensed,
		})
		fields.push({
			name: 'mlsBoard',
			validation: (value) => {
				return value.length > 0
			},
			message: 'Cannot be empty',
			value: regState.fields.mlsBoard,
		})
		fields.push({
			name: 'referralTypeId',
			validation: (value) => {
				return typeof value === 'number'
			},
			message: 'Cannot be empty',
			value: regState.fields.referralTypeId,
		})
		fields.push({
			name: 'referralAdditionalInformation',
			validation: (value) => {
				if (value.requireAdditionalInfo) {
					return value.referralAdditionalInformation.length > 0
				} else {
					return true
				}
			},
			message: 'Cannot be empty',
			value: {
				requireAdditionalInfo: selectedReferralTypeOption?.requireAdditionalInfo,
				referralAdditionalInformation: regState.fields.referralAdditionalInformation,
			},
		})

		regState.fields.licenseInfo.forEach((license, index) => {
			fields.push({
				name: `${index}/licenseNumber`,
				validation: (value) => {
					return value.length > 0
				},
				value: license.licenseNumber,
				message: `License number is required`,
			})
			fields.push({
				name: `${index}/plan`,
				validation: (value) => {
					return value !== null
				},
				value: license.plan,
				message: `Plan is required`,
			})
			fields.push({
				name: `${index}/licensedStateId`,
				validation: (value) => {
					return value !== null
				},
				value: license.licensedState,
				message: `Licensed State ID is required`,
			})
			fields.push({
				name: `${index}/regionId`,
				validation: (value) => {
					const regionsUnderSelectedState = value.selectedState
						? LicensedRegionsService.getRegionsOfState(value.selectedState.licensedStateId)
						: []

					/** Do not require region to be selected if state has no regions */
					if (regionsUnderSelectedState.length === 0) {
						return true
					}

					return value.region && typeof value.region.regionId === 'number'
				},
				value: { selectedState: regState.fields.licenseInfo[index], regionId: license.region?.regionId },
				message: `Region ID is required`,
			})
		})

		return fields
	}

	function getIsUserLicensedInSameStateMoreThanOnce(): boolean {
		let statesLicensedIn: number[] = []
		let isLicensedMoreThanOnce = false
		regState.fields.licenseInfo.forEach((license) => {
			if (license.licensedState) {
				if (statesLicensedIn.includes(license.licensedState.licensedStateId)) {
					isLicensedMoreThanOnce = true
				} else {
					statesLicensedIn.push(license.licensedState.licensedStateId)
				}
			}
		})
		return isLicensedMoreThanOnce
	}

	function updateAgentLicense(updatedLicense: AgentLicense.Entry, index: number): void {
		let updatedLicenses = _.cloneDeep(regState.fields.licenseInfo)
		updatedLicenses[index] = updatedLicense

		// if Florida is not selected, default region id back to null
		// in case Florida/region was selected previously
		if (updatedLicense.licensedState?.licensedStateId !== 1) {
			updatedLicenses[index].region = null
		}

		regDispatch({ type: 'update-fields', payload: [{ key: 'licenseInfo', value: updatedLicenses }] })
	}

	function removeAgentLicense(index: number): void {
		let updatedLicenses = _.cloneDeep(regState.fields.licenseInfo)
		updatedLicenses.splice(index, 1)
		regDispatch({ type: 'update-fields', payload: [{ key: 'licenseInfo', value: updatedLicenses }] })
	}

	function addAgentLicense(): void {
		let updatedLicenses = _.cloneDeep(regState.fields.licenseInfo)
		const newLicense = AgentLicenseCardService().createNewLicenseProps({
			endUserId: regState.fields.endUserId ? regState.fields.endUserId : undefined,
		})
		updatedLicenses.push(newLicense)
		regDispatch({ type: 'update-fields', payload: [{ key: 'licenseInfo', value: updatedLicenses }] })
	}

	function getSelectedMLSOptions(): MLSBoard.Complete[] {
		const selectedOptions: MLSBoard.Complete[] = []
		regState.mlsOptions.forEach((option) => {
			const isInSelectedOptions = regState.fields.mlsBoard.includes(option.mlsBoardId)
			if (isInSelectedOptions) {
				selectedOptions.push(option)
			}
		})
		return selectedOptions
	}

	function getSelectedReferralTypeOption(): Registration.ReferralTypeOption | undefined {
		const referralTypeId = regState.fields.referralTypeId

		if (referralTypeId !== null) {
			return regState.referralOptions.find((option) => option.referralTypeId === referralTypeId)
		}

		return undefined
	}

	function getMLSBoardOptions(): DropdownTypes.Option<MLSBoard.Complete>[] {
		const licensedStateIds: number[] = []
		regState.fields.licenseInfo.forEach((license) => {
			if (license.licensedState) {
				licensedStateIds.push(license.licensedState.licensedStateId)
			}
		})

		// Group by state
		const groupedByState = regState.mlsOptions.reduce<
			Record<string, DropdownTypes.ParentOption<MLSBoard.Complete>>
		>((acc, mlsOption) => {
			if (licensedStateIds.includes(mlsOption.licensedState.licensedStateId)) {
				// Check if the state exists in the accumulator
				if (!acc[mlsOption.licensedState.abbreviation]) {
					acc[mlsOption.licensedState.abbreviation] = {
						label: mlsOption.licensedState.stateName,
						value: mlsOption.licensedState.abbreviation,
						children: [],
					} as DropdownTypes.ParentOption<MLSBoard.Complete> // Type assertion here
				}

				// Push the MLS board into the state's children
				;(
					acc[mlsOption.licensedState.abbreviation] as DropdownTypes.ParentOption<MLSBoard.Complete>
				).children.push({
					label: mlsOption.displayValue,
					value: mlsOption,
				})
			}
			return acc
		}, {})

		return Object.values(groupedByState)
	}

	function getUTCTimeMinus10Hours() {
		const nowUTC = new Date()
		const tenHoursInMilliseconds = 10 * 60 * 60 * 1000 // 10 hours in milliseconds
		return new Date(nowUTC.getTime() - tenHoursInMilliseconds)
	}
	function calculateProratedCost() {
		let quarterlyCostForProration = quarterlyCost

		const startDateUTC = getUTCTimeMinus10Hours()

		const billingDates = [
			new Date(Date.UTC(startDateUTC.getUTCFullYear(), 0, 15)),
			new Date(Date.UTC(startDateUTC.getUTCFullYear(), 3, 15)),
			new Date(Date.UTC(startDateUTC.getUTCFullYear(), 6, 15)),
			new Date(Date.UTC(startDateUTC.getUTCFullYear(), 9, 15)),
		]
		// Find the next billing date
		let nextBillingDate = billingDates.find((date) => date > startDateUTC)
		if (!nextBillingDate) {
			// If there's no next billing date this year, use next year
			nextBillingDate = new Date(Date.UTC(startDateUTC.getUTCFullYear() + 1, 0, 15))
		}
		const oneDay = 24 * 60 * 60 * 1000 // milliseconds in a day
		const daysUntilNextBilling = (nextBillingDate.getTime() - startDateUTC.getTime()) / oneDay
		const daysInQuarter = 365 / 4 // Approximate days in a quarter
		if (quarterlyCost > 50) {
			quarterlyCostForProration = 50
		}
		const initialProration = Number(((quarterlyCostForProration / daysInQuarter) * daysUntilNextBilling).toFixed(2))
		setProratedCost(initialProration)
	}

	const handleUserSelected = (user: EndUserFirstLast) => {
		setListVisible(false)
		regDispatch({
			type: 'update-fields',
			payload: [{ key: 'referralAdditionalInformation', value: `${user.firstName} ${user.lastName}` }],
		})

		const formData: LeadCaptureTypes.LeadCaptureInfo = {
			email: regState.fields.email,
			firstName: regState.fields.firstName,
			lastName: regState.fields.lastName,
			state: regState.fields.state,
			phone: regState.fields.phone ? String(regState.fields.phone) : undefined,
			referrerId: user.endUserId ? String(user.endUserId) : undefined,
		}
		LeadCaptureAPI.capturelead(formData)
			.then(() => {})
			.catch((error) => {
				console.log(error)
			})
	}

	/** ================================================= */
	/** Render Component */

	return (
		<div className="registration__field-wrapper">
			<div className="registration__agent-licenses-wrapper">
				<Typography type="h5" margins={['bottom']}>
					Agent Licenses
				</Typography>
				{regState.fields.licenseInfo.length === 0 && (
					<div className="mt-10">
						<em>No licenses have been added</em>
					</div>
				)}
				{regState.fields.licenseInfo.length > 0 && (
					<>
						{regState.fields.licenseInfo.map((license, index) => {
							return (
								<RegistrationAgentLicense
									key={license.licenseInformationId}
									license={license}
									onUpdate={(key, value) => {
										const updatedLicense = { ...license, [key]: value }
										updateField(`${index}/${String(key)}`, 'value', value)
										updateAgentLicense(updatedLicense, index)
									}}
									onRemove={() => {
										removeAgentLicense(index)
									}}
									getField={getField}
									hasUserClickedNavButton={regState.hasUserClickedNavButton}
									index={index}
								/>
							)
						})}
					</>
				)}
				{isUserLicensedInSameStateMoreThanOnce && (
					<div className="flex flex-alignItems-center color__warning">
						<FontAwesomeIcon icon={['fas', 'triangle-exclamation']} className="mr-10" />
						<strong>You cannot have multiple licenses within the same state</strong>
					</div>
				)}
				{isUserLicensedInNoStates && (
					<div className="flex flex-alignItems-center color__warning">
						<FontAwesomeIcon icon={['fas', 'triangle-exclamation']} className="mr-10" />
						<strong>You must have at least one license</strong>
					</div>
				)}
				<Button
					icon={'plus'}
					size="md"
					variant={'outlined'}
					label="Add Additional License"
					margins={['top']}
					onClick={() => {
						addAgentLicense()
					}}
				/>

				<HorizontalRule className="mt-20 mb-20" />
			</div>

			<Dropdown<string>
				width={`100%`}
				label="Number of years licensed"
				options={[
					{
						label: 'Less than 1 year',
						value: 'Less than 1 year',
					},
					{
						label: '1-3 years',
						value: '1-3 years',
					},
					{
						label: 'More than 3 years',
						value: 'More than 3 years',
					},
				]}
				value={regState.fields.numYearsLicensed ? [regState.fields.numYearsLicensed] : []}
				onSelect={(updatedValue) => {
					if (updatedValue && updatedValue.length > 0) {
						regDispatch({
							type: 'update-fields',
							payload: [{ key: 'numYearsLicensed', value: updatedValue[0] }],
						})
					}
				}}
				validation={{
					isValid: (value, isUserInteracted) => {
						return {
							isValid: getField('numYearsLicensed').isValid,
							showValidationFlag: isUserInteracted || regState.hasUserClickedNavButton,
						}
					},

					message: `You must select an option`,
				}}
				forceRevalidation={regState.hasUserClickedNavButton}
			/>

			<Dropdown<MLSBoard.Complete>
				width={`100%`}
				label="MLS Board"
				multiselect={true}
				options={getMLSBoardOptions()}
				onlyParent={isOnlyParent}
				value={selectedMLSOption}
				onSelect={(updatedValue) => {
					const selectedMLSBoards = updatedValue.map((mlsBoard) => mlsBoard.mlsBoardId)
					regDispatch({ type: 'update-fields', payload: [{ key: 'mlsBoard', value: selectedMLSBoards }] })
				}}
				validation={{
					isValid: (value, isUserInteracted) => {
						return {
							isValid: getField('mlsBoard').isValid,
							showValidationFlag: isUserInteracted || regState.hasUserClickedNavButton,
						}
					},

					message: `You must select an option`,
				}}
				forceRevalidation={regState.hasUserClickedNavButton}
			/>

			<Dropdown<Registration.ReferralTypeOption>
				width={`100%`}
				label="How did you hear about us?"
				options={regState.referralOptions.map((option) => {
					return {
						label: option.displayValue,
						value: option,
					}
				})}
				value={selectedReferralTypeOption ? [selectedReferralTypeOption] : []}
				onSelect={(updatedValue) => {
					if (updatedValue && updatedValue.length > 0) {
						regDispatch({
							type: 'update-fields',
							payload: [{ key: 'referralTypeId', value: updatedValue[0].referralTypeId }],
						})
					}
				}}
				validation={{
					isValid: (value, isUserInteracted) => {
						return {
							isValid: getField('referralTypeId').isValid,
							showValidationFlag: isUserInteracted || regState.hasUserClickedNavButton,
						}
					},

					message: `You must select an option`,
				}}
				forceRevalidation={regState.hasUserClickedNavButton}
			/>

			{selectedReferralTypeOption?.requireAdditionalInfo && (
				<div>
					<TextInput
						width={`100%`}
						dataType={'text'}
						label={
							selectedReferralTypeOption.additionalInfoLabel
								? selectedReferralTypeOption.additionalInfoLabel
								: 'Please explain'
						}
						value={
							regState.fields.referralAdditionalInformation
								? regState.fields.referralAdditionalInformation
								: ''
						}
						onChange={(updatedValue) => {
							setListVisible(true)
							setReferralSearchValue(updatedValue)
							regDispatch({
								type: 'update-fields',
								payload: [{ key: 'referralAdditionalInformation', value: updatedValue }],
							})
						}}
						validation={{
							isValid: (value, isUserInteracted) => {
								return {
									isValid: getField('referralAdditionalInformation').isValid,
									showValidationFlag: isUserInteracted || regState.hasUserClickedNavButton,
								}
							},

							message: `Please provide additional information`,
						}}
						forceRevalidation={regState.hasUserClickedNavButton}
					/>

					{listVisible && (
						<ReferralSearchAutocomplete
							onUserSelected={handleUserSelected}
							searchValue={referralSearchValue}
						/>
					)}
				</div>
			)}

			<div className="registration__agent-licenses-wrapper">
				<HorizontalRule className="mt-20" />
				<div className="flex flex-column mb-10">
					{
						<>
							<Typography type="h5" margins={['top', 'bottom']}>
								Agent License Summary
							</Typography>
							{promotionCode === 'LOKATION60' && (
								<>
									<div className="mb-5">
										Promo code{' '}
										<span className="promoCode">
											{' '}
											<FontAwesomeIcon icon="tag" /> LOKATION60
										</span>{' '}
										applied
									</div>
									<em>You will not be billed for your Lokation Plan until {futureDateString}</em>
								</>
							)}
							{regState.fields.licenseInfo.map((license) => {
								if (!license.licensedState) {
									return (
										<div key={license.licenseInformationId}>
											<em>You have not added a license plan</em>
										</div>
									)
								}
								const stateEnum = license.licensedState
								if (!license.plan) {
									return (
										<div key={license.licenseInformationId}>
											<em>You have not added a license plan</em>
										</div>
									)
								}
								const licenseProps = RegistrationService().findLicensePlans(
									stateEnum.abbreviation,
									license.plan,
								)
								if (!licenseProps) {
									return (
										<div key={license.licenseInformationId}>
											<em>You have not added a license plan</em>
										</div>
									)
								}

								if (licenseProps.plan === 'BLUE') {
									if (hasBluePlan) {
										licenseProps.price = '$20/month'
										licenseProps.recurringCost = 20
									} else {
										hasBluePlan = true
									}
								}

								if (licenseProps.subscriptionType === 'monthly') {
									monthlyCost += licenseProps.recurringCost
								}

								if (quarterlyCost < 50) {
									quarterlyCost += licenseProps.complianceFeeRecurringCost
								} else {
									quarterlyCost = 50
								}

								totalDue += licenseProps.recurringCost

								// List added license plans
								return (
									<div
										className="col-xs-12 border-bottom-thin py-10"
										key={license.licenseInformationId}
									>
										<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween">
											<div>
												<strong>{licenseProps.label}</strong>
											</div>
											<div>
												<em>{licenseProps.price}</em>
											</div>
										</div>
										{/* <div className='flex flex-alignItems-center flex-justifyContent-spaceBetween'>
                                                <div>{licenseProps.complianceFeeTitle}</div>
                                                <div>
                                                    <em>{licenseProps.complianceFeePrice}</em>
                                                </div>
                                            </div> */}
									</div>
								)
							})}
						</>
					}
				</div>

				{regState.fields.licenseInfo.some((license) => license.licensedState?.licensedStateId) && (
					<>
						{/* <div className='flex flex-justifyContent-end mt-10'>
						<strong className='mr-10'>Inital setup cost:</strong>{' '}
						<div className='cost-num'>${totalServicesCosts.licensePlansCosts.initialTotal}</div>
					</div> */}
						{totalServicesCosts.licensePlansCosts.initialMonthly > 0 && (
							<div className="flex flex-justifyContent-end col-xs-12 pt-10">
								<strong className="mr-10">Monthly cost:</strong>{' '}
								<div className="cost-num">${monthlyCost}</div>
							</div>
						)}

						{totalServicesCosts.licensePlansCosts.initialYearly > 0 && (
							<div className="flex flex-justifyContent-end col-xs-12  pt-10">
								<strong className="mr-10">Annual cost:</strong>{' '}
								<div className="cost-num">${totalServicesCosts.licensePlansCosts.initialYearly}</div>
							</div>
						)}

						{/* <div className='flex flex-justifyContent-end col-xs-12 py-10'>
						<div className='mr-10'><strong>Quarterly Compliance Fee:</strong> </div> {' '}
						<div className='cost-num'>{quarterlyCost > 50 ? "$50" : "$" + quarterlyCost}</div>
						
					</div> */}

						<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin pb-10">
							<div className="mr-10">
								<strong>Prorated Quarterly Compliance Fee:</strong>{' '}
							</div>{' '}
							<div className="cost-num">{'$' + proratedCost.toFixed(2)}</div>
						</div>

						<div className="flex flex-justifyContent-end pt-10">
							<strong className="mr-10">Total due:</strong>{' '}
							<div className="cost-num">${(totalDue + proratedCost).toFixed(2)}</div>
						</div>

						<div className="flex flex-justifyContent-end col-xs-12 py-10">
							<em>
								* The quarterly compliance fee is billed on January 15, April 15, July 15, and October
								15. If you join the company mid-quarter, the first charge is prorated based on the
								remaining days until the next billing date.
							</em>{' '}
						</div>
					</>
				)}
			</div>
		</div>
	)
}
