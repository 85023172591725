import { RootState } from '@redux/store'
import { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'

import { LicensedStateEnums } from '../../services/licensed-states/licenses-states.types'
import { EndUserProps } from '../../services/user/user.types'
import { Button } from '../button/button'
import { CollapsibleContent } from '../collapsible-content/collapsible-content'

interface GetSupportProps {
	currentUser: EndUserProps | null
}

export function GetSupportSectionPrototype({ currentUser }: GetSupportProps) {
	const navigate = useNavigate()
	const [selectedItemId, setSelectedItemId] = useState<number | null>(null)

	const handleToggleCollapsible = (itemId: number) => {
		if (selectedItemId === itemId) {
			setSelectedItemId(null)
		} else {
			setSelectedItemId(itemId)
		}
	}

	const handleClick = (stateAbbreviation: string) => {
		navigate(`/support`, {
			state: { selectedState: stateAbbreviation },
		})
	}

	const licenseInfoArray = currentUser ? [...currentUser.licenseInformation] : []

	// Sort the array alphabetically, keeping Florida at the top
	licenseInfoArray.sort((a, b) => {
		if (a.licensedState.abbreviation === 'FL') return -1
		if (b.licensedState.abbreviation === 'FL') return 1
		return a.licensedState.stateName.localeCompare(b.licensedState.stateName)
	})

	const LIVE_ZOOM_SUPPORT_BUTTON = (
		<Button
			variant="outlined"
			size="md"
			margins={['bottom']}
			label="Live Zoom Support"
			onClick={() => {
				window.open(`https://lokationsupport.com/`, `_blank`)
			}}
		/>
	)

	function getAlabamaInfo() {
		return (
			<>
				<div className="flex flex-column pt-20">
					{LIVE_ZOOM_SUPPORT_BUTTON}

					<Button
						variant="outlined"
						size="md"
						margins={['bottom']}
						label="Set an Appointment"
						onClick={() => {
							window.open(`https://appt.link/dir-of-operations-se-region-ga-al-sc`, `_blank`)
						}}
					/>

					<Button
						variant="outlined"
						size="md"
						margins={['bottom']}
						icon={'phone'}
						iconPosition="left"
						label={'404-348-0420'}
						onClick={() => {
							window.open(`tel:404-348-0420`, `_blank`)
						}}
					/>
					<Button
						variant="outlined"
						label="Department Contacts"
						size={'md'}
						onClick={() => {
							handleClick('AL')
						}}
						margins={['bottom']}
					/>
				</div>
			</>
		)
	}

	function getColoradoInfo() {
		return (
			<>
				<div className="flex flex-column pt-20">
					{LIVE_ZOOM_SUPPORT_BUTTON}

					<Button
						variant="outlined"
						size="md"
						margins={['bottom']}
						label="Set an Appointment"
						onClick={() => {
							window.open(`https://appt.link/colorado-employing-broker`, `_blank`)
						}}
					/>

					<Button
						variant="outlined"
						size="md"
						margins={['bottom']}
						icon={'phone'}
						iconPosition="left"
						label={'720-370-3878'}
						onClick={() => {
							window.open(`tel:720-370-3878`, `_blank`)
						}}
					/>

					<Button
						variant="outlined"
						label="Department Contacts"
						size={'md'}
						onClick={() => {
							handleClick('CO')
						}}
						margins={['bottom']}
					/>
				</div>
			</>
		)
	}

	function getFloridaInfo() {
		return (
			<>
				<div className="flex flex-column pt-20">
					{LIVE_ZOOM_SUPPORT_BUTTON}

					<Button
						variant="outlined"
						size="md"
						margins={['bottom']}
						label="Set an Appointment"
						onClick={() => {
							window.open(`https://appt.link/agent-support-session`, `_blank`)
						}}
					/>

					<Button
						variant="outlined"
						size="md"
						margins={['bottom']}
						icon={'phone'}
						iconPosition="left"
						label={'954-743-4981'}
						onClick={() => {
							window.open(`tel:954-743-4981`, `_blank`)
						}}
					/>

					<Button
						variant="outlined"
						size="md"
						margins={['bottom']}
						icon={'message'}
						iconPosition="left"
						label={'954-231-1377'}
						onClick={() => {
							window.open(`sms:954-231-1377`, `_blank`)
						}}
					/>

					<Button
						variant="outlined"
						label="Department Contacts"
						size={'md'}
						onClick={() => {
							handleClick('FL')
						}}
						margins={['bottom']}
					/>
				</div>
			</>
		)
	}

	function getGeorgiaInfo() {
		return (
			<>
				<div className="flex flex-column pt-20">
					{LIVE_ZOOM_SUPPORT_BUTTON}

					<Button
						variant="outlined"
						size="md"
						margins={['bottom']}
						label="Set an Appointment"
						onClick={() => {
							window.open(`https://appt.link/dir-of-operations-se-region-ga-al-sc`, `_blank`)
						}}
					/>

					<Button
						variant="outlined"
						size="md"
						margins={['bottom']}
						icon={'phone'}
						iconPosition="left"
						label={'404-348-0420'}
						onClick={() => {
							window.open(`tel:404-348-0420`, `_blank`)
						}}
					/>

					<Button
						variant="outlined"
						label="Department Contacts"
						size={'md'}
						onClick={() => {
							handleClick('GA')
						}}
						margins={['bottom']}
					/>
				</div>
			</>
		)
	}

	function getSouthCarolinaInfo() {
		return (
			<>
				<div className="flex flex-column pt-20">
					{LIVE_ZOOM_SUPPORT_BUTTON}

					<Button
						variant="outlined"
						size="md"
						margins={['bottom']}
						label="Set an Appointment"
						onClick={() => {
							window.open(`https://appt.link/dir-of-operations-se-region-ga-al-sc`, `_blank`)
						}}
					/>

					<Button
						variant="outlined"
						size="md"
						margins={['bottom']}
						icon={'phone'}
						iconPosition="left"
						label={'404-348-0420'}
						onClick={() => {
							window.open(`tel:404-348-0420`, `_blank`)
						}}
					/>
					<Button
						variant="outlined"
						label="Department Contacts"
						size={'md'}
						onClick={() => {
							handleClick('SC')
						}}
						margins={['bottom']}
					/>
				</div>
			</>
		)
	}

	function getTexasInfo() {
		return (
			<>
				<div className="flex flex-column pt-20">
					{LIVE_ZOOM_SUPPORT_BUTTON}

					<Button
						variant="outlined"
						size="md"
						margins={['bottom']}
						label="Set an Appointment"
						onClick={() => {
							window.open(`https://appt.link/texas-employing-broker`, `_blank`)
						}}
					/>

					<Button
						variant="outlined"
						label="Department Contacts"
						size={'md'}
						onClick={() => {
							handleClick('TX')
						}}
						margins={['bottom']}
					/>
				</div>
			</>
		)
	}

	function getDefaultInfo() {
		return (
			<>
				<div className="flex flex-column pt-20">
					<Button
						variant="outlined"
						size="md"
						margins={['bottom']}
						icon={'envelope'}
						iconPosition="left"
						label={'support@lokationre.com'}
						onClick={() => {
							window.open(`mailto:support@lokationre.com`, `_blank`)
						}}
					/>
				</div>
			</>
		)
	}

	function getStateComponent(abbreviation: LicensedStateEnums) {
		switch (abbreviation) {
			case 'AL':
				return getAlabamaInfo
			case 'CO':
				return getColoradoInfo
			case 'FL':
				return getFloridaInfo
			case 'GA':
				return getGeorgiaInfo
			case 'SC':
				return getSouthCarolinaInfo
			case 'TX':
				return getTexasInfo
			default:
				return getDefaultInfo
		}
	}

	return (
		<>
			{licenseInfoArray.length > 0 ? (
				licenseInfoArray.map((license) => {
					const stateAbbreviation = license.licensedState.abbreviation
					const stateName = license.licensedState.stateName
					const stateComponent = getStateComponent(stateAbbreviation)
					const isOpen = licenseInfoArray.length === 1 || selectedItemId === license.licenseInformationId

					return (
						<CollapsibleContent
							label={stateName}
							state={isOpen ? 'OPEN' : 'CLOSED'}
							setState={() => handleToggleCollapsible(license.licenseInformationId)}
							key={license.licenseInformationId}
							controlled={false}
							headerPadding={['all']}
						>
							<>{stateComponent && stateComponent()}</>
						</CollapsibleContent>
					)
				})
			) : (
				<div className="mt-10 px-10">{getDefaultInfo()}</div>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const GetSupportSection = connect(mapStateToProps)(GetSupportSectionPrototype)
