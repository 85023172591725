import { Typography } from '@components/text/text'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'
import { AddOnServiceList } from 'src/routes/administration/user-profile/components/subscription-components/add-on-service-list'
import { EndUserProps } from 'src/services/user/user.types'

import Logo from './tkcma.png'

interface ToolkitCMAProps {
	currentUser: EndUserProps | null
}

function ToolkitCMAPrototype({ currentUser }: ToolkitCMAProps) {
	return (
		<div className="flex-lg flex-alignItems-center flex-justifyContent-center overflow-y__scroll">
			<div className="col-xs-12 col-lg-8 pr-40-md-down pr-20-lg">
				<div className="col-12 flex flex-justifyContent-center mb-20">
					<img src={Logo} style={{ width: '400px' }} />
				</div>
				<p>
					Compelling presentations that provide you with attractive and effective, branded marketing material
					for every listing and selling occasion - created on demand in mere minutes via the Internet.
				</p>

				<div className=" my-20">
					<div className="col-12 mb-20">
						<Typography type="h5">
							<strong>Benefits ToolkitCMA provides:</strong>
						</Typography>
						<ul>
							<li>Consistency and quality marketing presentations branded for LoKation Real Estate.</li>
							<li>
								You will have a competitive advantage with exceptionally great looking presentations.
							</li>
							<li>
								Flexible and convenient. Work from any Internet-connected PC - at home, in the office,
								or anywhere.
							</li>
							<li>
								Automatically merges comparable property and picture content downloaded from your MLS
								into CMA documents
							</li>
							<li>Convenient option to email a link for presentations and property flyers.</li>
							<li>
								You spend less time putting together presentations and more time getting prospects,
								closing sales, and making money!
							</li>
						</ul>
					</div>

					<div className="col-12">
						<Typography type="h5">Run a Demo of ToolkitCMA</Typography>
						<p>
							If you would like to run the program to experience the intuitive screens and overall ease of
							use{' '}
							<a
								href="http://www.toolkitcma.com/mylogin/mylogin.php?username=demo@lokatrealestatpompabeachfl24825.com&password=toolkit&skipinfo=1"
								target="_blank"
								rel="noreferrer"
							>
								CLICK HERE
							</a>{' '}
							to automatically login to the ToolkitCMA demo account for LoKation Real Estate.
						</p>
					</div>
				</div>

				<AddOnServiceList serviceIds={['TOOLKIT_CMA']} />
			</div>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const ToolkitCMARoute = connect(mapStateToProps)(ToolkitCMAPrototype)
