import { Typography } from '@components/text/text'

import { DotloopIconGrid } from '../../../../../lokation-routes/dotloop/dotloop-grid'
import dotloopLogo from './dotloop-blue.png'

export function RegistrationDotloopDesc() {
	return (
		<div className="col-xs-12">
			<img src={dotloopLogo} style={{ width: '200px' }} className="mb-20" />
			<Typography type="h6">For Real Estate Agents Who Want to Accelerate Transactions</Typography>
			<p>
				Dotloop is a complete real estate transaction management software that enables brokers, agents,
				transaction coordinators and clients to collaborate virtually. It handles the entire deal from offer to
				close.
			</p>
			<p>
				Empower your agents with software that provides eSignatures, forms, sharing, text tracking, storage &
				more!
			</p>
			<DotloopIconGrid />
		</div>
	)
}
