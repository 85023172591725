import { CollapsibleContent } from '@components/collapsible-content/collapsible-content'
import { ContextMenu } from '@components/context-menu/context-menu'
import { ContextMenuTypes } from '@components/context-menu/context-menu.types'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { useState } from 'react'
import React from 'react'
import { Transaction__AddChecklistModal } from 'src/routes/transaction-mgmt/shared/add-checklist-modal/add-checklist-modal'
import { TransactionMgmt__Document__SideSheet } from 'src/routes/transaction-mgmt/side-sheets/document/document.side-sheet'
import { TransactionMgmt__Notification__SideSheet } from 'src/routes/transaction-mgmt/side-sheets/notification/notification.side-sheet'
import { TransactionMgmt__Task__SideSheet } from 'src/routes/transaction-mgmt/side-sheets/task/task.side-sheet'
import { useContextMenu } from 'src/services/hooks/use-context-menu'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { useTransaction, useTransactionDispatch } from '../../state/transaction__state'
import { Transaction__DocumentCard } from './components/document-card'
import { Transaction__NotificationCard } from './components/notification-card'
import { Transaction__TaskCard } from './components/task-card'

export function TransactionMgmt__Transaction__Overview__Tasks() {
	const transactionState = useTransaction()
	const transactionDispatch = useTransactionDispatch()

	const { contextMenu } = useContextMenu()

	const [showTaskSideSheet, setShowTaskSideSheet] = useState(false)
	const [selectedTask, setSelectedTask] = useState<TransactionMgmtTypes.TaskTypes.Task | 'NEW' | null>(null)

	const [showDocumentSideSheet, setShowDocumentSideSheet] = useState(false)
	const [selectedDocument, setSelectedDocument] = useState<
		TransactionMgmtTypes.DocumentTypes.Document | 'NEW' | null
	>(null)

	const [showNotificationSideSheet, setShowNotificationSideSheet] = useState(false)
	const [selectedNotification, setSelectedNotification] = useState<
		TransactionMgmtTypes.NotificationTypes.Notification | 'NEW' | null
	>(null)

	const [showAddChecklistModal, setShowAddChecklistModal] = useState(false)

	const orphanedDocuments = TransactionManagementService.getOrphanedDocuments(transactionState.transaction)

	function handleSelectItem(
		item:
			| TransactionMgmtTypes.TaskTypes.Task
			| TransactionMgmtTypes.DocumentTypes.Document
			| TransactionMgmtTypes.NotificationTypes.Notification,
	): void {
		if ('documentId' in item) {
			setSelectedDocument(item)
			setShowDocumentSideSheet(true)
		}
		if ('taskId' in item) {
			setSelectedTask(item)
			setShowTaskSideSheet(true)
		}
		if ('notificationId' in item) {
			setSelectedNotification(item)
			setShowNotificationSideSheet(true)
		}
	}

	function getContextMenuOptions(): ContextMenuTypes.Option[] {
		const CREATE_TASK: ContextMenuTypes.Option = {
			label: 'Task',
			desc: `Forms that must be completed and approved before closing`,
			onClick: () => {
				setShowTaskSideSheet(true)
				setSelectedTask('NEW')
			},
		}

		const CREATE_NOTIFICATION: ContextMenuTypes.Option = {
			label: 'Notification',
			desc: `An important deadline that cannot be completed inside Sphere`,
			onClick: () => {
				setShowNotificationSideSheet(true)
				setSelectedNotification('NEW')
			},
		}

		const CREATE_DOCUMENT: ContextMenuTypes.Option = {
			label: 'Document',
			desc: `A file that must be uploaded and/or signed`,
			onClick: () => {
				setShowDocumentSideSheet(true)
				setSelectedDocument('NEW')
			},
		}

		const CREATE_CHECKLIST: ContextMenuTypes.Option = {
			label: 'Checklist',
			desc: `Group of tasks, documents, and notifications`,
			primary: true,
			onClick: () => {
				setShowAddChecklistModal(true)
			},
		}

		return [CREATE_CHECKLIST, CREATE_TASK, CREATE_NOTIFICATION, CREATE_DOCUMENT]
	}

	return (
		<>
			<CollapsibleContent
				label={`Tasks / Notifications`}
				labelSize="h5"
				buttons={[
					{
						variant: 'outlined',
						label: 'Add New',
						size: 'sm',
						icon: 'angle-down',
						iconPosition: 'right',
						onClick: (evt) => {
							contextMenu.set({ x: evt.pageX, y: evt.pageY, isVisible: true })
						},
					},
				]}
				controlled={true}
				id="transactions__tasks"
				headerPadding={[]}
			>
				<Paper elevate={true} bgColor="white" padding={['all']} className="col-xs-12 flex flex-wrap">
					{transactionState.transaction.tasks.length === 0 &&
						transactionState.transaction.notifications.length === 0 &&
						transactionState.transaction.documents.length === 0 && (
							<Typography type="semibold" variant={['italic']} color="disabled">
								This transaction has no tasks or notifications
							</Typography>
						)}

					{transactionState.transaction.tasks
						.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1))
						.map((task) => {
							return (
								<>
									<Transaction__TaskCard
										key={task.taskId}
										task={task}
										handleSelectItem={handleSelectItem}
										showNewFlag={TransactionManagementService.isThisTaskNewSinceSessionStarted({
											originalTransaction: transactionState.unmodifiedTransaction,
											modifiedTransaction: transactionState.transaction,
											taskId: task.taskId,
										})}
									/>
									{task.childDocIds.map((docId) => {
										const documentProps = transactionState.transaction.documents.find(
											(doc) => doc.documentId === docId,
										)
										if (!documentProps) {
											return <React.Fragment key={docId}>CANNOT FIND DOCUMENT</React.Fragment>
										}
										return (
											<Transaction__DocumentCard
												key={documentProps.documentId}
												document={documentProps}
												handleSelectItem={handleSelectItem}
												removable={false}
												className="ml-20"
												showNewFlag={TransactionManagementService.isThisDocumentNewSinceSessionStarted(
													{
														originalTransaction: transactionState.unmodifiedTransaction,
														modifiedTransaction: transactionState.transaction,
														documentId: documentProps.documentId,
													},
												)}
											/>
										)
									})}
								</>
							)
						})}
					{orphanedDocuments.length > 0 && (
						<>
							<Typography type="strong" margins={['top']}>
								Other Documents
							</Typography>
							{orphanedDocuments.map((document) => {
								return (
									<Transaction__DocumentCard
										document={document}
										key={document.documentId}
										handleSelectItem={handleSelectItem}
										removable={false}
										showNewFlag={TransactionManagementService.isThisDocumentNewSinceSessionStarted({
											originalTransaction: transactionState.unmodifiedTransaction,
											modifiedTransaction: transactionState.transaction,
											documentId: document.documentId,
										})}
									/>
								)
							})}
						</>
					)}
					{transactionState.transaction.notifications.length > 0 && (
						<>
							<Typography type="strong" margins={['top']}>
								Notifications
							</Typography>
							{transactionState.transaction.notifications.map((notification) => {
								return (
									<Transaction__NotificationCard
										notification={notification}
										key={notification.notificationId}
										handleSelectItem={handleSelectItem}
										removable={false}
										showNewFlag={TransactionManagementService.isThisNotificationNewSinceSessionStarted(
											{
												originalTransaction: transactionState.unmodifiedTransaction,
												modifiedTransaction: transactionState.transaction,
												notificationId: notification.notificationId,
											},
										)}
									/>
								)
							})}
						</>
					)}
				</Paper>
			</CollapsibleContent>

			{showTaskSideSheet && selectedTask && (
				<TransactionMgmt__Task__SideSheet
					mode={'EDITOR'}
					task={typeof selectedTask !== 'string' ? selectedTask : null}
					key={typeof selectedTask !== 'string' ? selectedTask.taskId : 'NEW'}
					showParentTransaction={false}
					parentTransaction={transactionState.transaction}
					onClose={() => {
						setShowTaskSideSheet(false)
					}}
					onUpdate={(savedTask) => {
						if (selectedTask === 'NEW') {
							transactionDispatch({ type: 'add-task', payload: savedTask })
						} else {
							transactionDispatch({ type: 'update-task', payload: savedTask })
						}
					}}
					onDelete={(taskId) => {
						transactionDispatch({ type: 'remove-task', payload: taskId })
						setShowTaskSideSheet(false)
						setSelectedTask(null)

						/** Fetch an updated transaction (Because there are side effects from deleting a task, such as changes to documents) */
						TransactionManagementAPI.getTransactionById(transactionState.transaction.transactionId).then(
							(res) => {
								transactionDispatch({ type: 'update-transaction', payload: res.data })
							},
						)
					}}
				/>
			)}

			{showNotificationSideSheet && selectedNotification && (
				<TransactionMgmt__Notification__SideSheet
					mode={'EDITOR'}
					notification={typeof selectedNotification !== 'string' ? selectedNotification : null}
					key={typeof selectedNotification !== 'string' ? selectedNotification.notificationId : 'NEW'}
					showParentTransaction={false}
					parentTransaction={transactionState.transaction}
					onClose={() => {
						setShowNotificationSideSheet(false)
					}}
					onUpdate={(savedNotification) => {
						if (selectedTask === 'NEW') {
							transactionDispatch({ type: 'add-notification', payload: savedNotification })
						} else {
							transactionDispatch({ type: 'update-notification', payload: savedNotification })
						}
					}}
					onDelete={(notificationId) => {
						transactionDispatch({ type: 'remove-notification', payload: notificationId })
						setShowNotificationSideSheet(false)
						setSelectedNotification(null)
					}}
				/>
			)}

			{showDocumentSideSheet && selectedDocument && (
				<TransactionMgmt__Document__SideSheet
					document={typeof selectedDocument !== 'string' ? selectedDocument : null}
					key={typeof selectedDocument !== 'string' ? selectedDocument.documentId : 'NEW'}
					showParentTransaction={false}
					parentTransaction={transactionState.transaction}
					onClose={() => {
						setShowDocumentSideSheet(false)
					}}
					onUpdate={(savedDoc) => {
						if (selectedTask === 'NEW') {
							transactionDispatch({ type: 'add-document', payload: savedDoc })
						} else {
							transactionDispatch({ type: 'update-document', payload: savedDoc })
						}
					}}
					onDelete={(docId) => {
						transactionDispatch({ type: 'remove-document', payload: docId })
						setShowDocumentSideSheet(false)
						setSelectedDocument(null)
					}}
				/>
			)}

			{showAddChecklistModal && (
				<Transaction__AddChecklistModal
					parentTransactionId={transactionState.transaction.transactionId}
					onCancel={() => {
						setShowAddChecklistModal(false)
					}}
					onAddChecklist={(updatedTransaction) => {
						transactionDispatch({ type: 'update-transaction', payload: updatedTransaction })
						setShowAddChecklistModal(false)
					}}
				/>
			)}

			<ContextMenu
				position={`absolute`}
				x={contextMenu.x}
				y={contextMenu.y}
				visible={contextMenu.isVisible}
				options={getContextMenuOptions()}
				onDismiss={() => {
					contextMenu.setIsVisible(false)
				}}
			/>
		</>
	)
}
