import { LicensedState } from '../../services/licensed-states/licenses-states.types'
import { Dropdown } from '../dropdown/dropdown'
import { MultiSelectDropdown } from '../multiselect-dropdown/multiselect-dropdown'

export interface StateSelectorDropdownProps {
	selectedStates: Array<LicensedState>
	options: LicensedState[]
	onSelect: (selectedOptions: LicensedState[]) => void
	disabled?: boolean
	multiselect: boolean
	style?: React.CSSProperties
	width: string | number
}

export function StateSelectorDropdown(props: StateSelectorDropdownProps) {
	if (props.multiselect) {
		return (
			<MultiSelectDropdown<LicensedState>
				style={props.style}
				selectedOptions={props.selectedStates}
				options={props.options}
				onSelect={props.onSelect}
				optionIdKey={'licensedStateId'}
				optionLabelKey={'stateName'}
				disabled={props.disabled}
			/>
		)
	} else {
		return (
			<Dropdown<LicensedState>
				style={props.style}
				width={props.width}
				value={props.selectedStates}
				options={props.options.map((option) => {
					return {
						label: option.stateName,
						value: option,
					}
				})}
				onSelect={props.onSelect}
				disabled={props.disabled}
			/>
		)
	}
}
