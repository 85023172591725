import { Button } from '@components/button/button'
import { ListItem } from '@components/list-item/list-item'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'
import { RootState } from '@redux/store'
import _ from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { LicensedState } from 'src/services/licensed-states/licenses-states.types'
import { LicensedRegion } from 'src/services/regions/regions.types'

type ComponentProps = {
	title: string
	description: string
	selectedRegions: LicensedRegion[]
	selectedStates: LicensedState[]
	/** Event to call when the user clicks the modals "Select" button */
	onSelect: (selectedStates: LicensedState[], selectedRegions: LicensedRegion[]) => void
	/** Event to call when the modal is closed (via cancellation OR selection) */
	onClose: () => void
	/** Manual z-index override of the modal */
	zIndex?: number
}

type ConnectedProps = {
	licensedRegions: LicensedRegion[]
	licensedStates: LicensedState[]
}

/** Modal for selecting states and regions from a list */
function LocationSelectorModalPrototype(props: ComponentProps & ConnectedProps) {
	const [selectedStates, setSelectedStates] = useState<LicensedState[]>(props.selectedStates)
	const [selectedRegions, setSelectedRegions] = useState<LicensedRegion[]>(props.selectedRegions)
	const selectedStateIds = selectedStates.map((state) => state.licensedStateId)
	const selectedRegionIds = selectedRegions.map((region) => region.regionId)

	/** ========================= */
	/** Methods */

	function toggleStateInclusion(state: LicensedState, action: 'ADD' | 'REMOVE'): void {
		let updatedStates = _.cloneDeep(selectedStates)
		if (action === 'ADD') {
			updatedStates.push(state)
		} else {
			updatedStates = updatedStates.filter((thisItem) => thisItem.licensedStateId !== state.licensedStateId)
		}
		setSelectedStates(updatedStates)
	}

	function toggleRegionInclusion(region: LicensedRegion, action: 'ADD' | 'REMOVE'): void {
		let updatedRegions = _.cloneDeep(selectedRegions)
		if (action === 'ADD') {
			updatedRegions.push(region)
		} else {
			updatedRegions = updatedRegions.filter((thisItem) => thisItem.regionId !== region.regionId)
		}
		setSelectedRegions(updatedRegions)
	}

	function createStateSelector(state: LicensedState): React.ReactNode {
		const isSelected = selectedStateIds.includes(state.licensedStateId)

		return (
			<>
				<ListItem
					key={state.licensedStateId}
					body={state.stateName}
					onClick={(updatedState) => {
						toggleStateInclusion(state, updatedState ? 'ADD' : 'REMOVE')
					}}
					selected={isSelected}
					includeCheckbox={true}
				/>
				{props.licensedRegions.map((region) => {
					if (region.licensedStateId !== state.licensedStateId) {
						return <React.Fragment key={region.regionId}></React.Fragment>
					}

					return createRegionSelector(region)
				})}
			</>
		)
	}

	function createRegionSelector(region: LicensedRegion): React.ReactNode {
		const isRegionSelected = selectedRegionIds.includes(region.regionId)
		const isParentStateSelected = selectedStateIds.includes(region.licensedStateId)

		return (
			<ListItem
				className="ml-30"
				disabled={isParentStateSelected}
				key={region.licensedStateId}
				body={region.regionName}
				onClick={(updatedState) => {
					toggleRegionInclusion(region, updatedState ? 'ADD' : 'REMOVE')
				}}
				selected={isRegionSelected || isParentStateSelected}
				includeCheckbox={true}
			/>
		)
	}

	/** ========================= */
	/** Return Component */

	return (
		<Modal
			maxWidth={600}
			maxHeight={600}
			fixedHeight={false}
			onClose={props.onClose}
			zIndex={props.zIndex}
			className="flex flex-column"
		>
			<ModalHeader title={props.title} />
			<ModalBody className="overflow-y__scroll">
				<strong>{props.description}</strong>
				{props.licensedStates.map((state) => {
					return createStateSelector(state)
				})}
			</ModalBody>
			<ModalFooter>
				<Button
					variant="outlined"
					size="md"
					primary={false}
					label="Cancel"
					margins={['right']}
					onClick={props.onClose}
				/>
				<Button
					variant="contained"
					size="md"
					label="Select"
					disabled={selectedStates.length === 0 && selectedRegions.length === 0}
					onClick={() => {
						props.onSelect(selectedStates, selectedRegions)
						props.onClose()
					}}
				/>
			</ModalFooter>
		</Modal>
	)
}

function mapStateToProps(state: RootState) {
	return {
		licensedRegions: state.licensedRegions,
		licensedStates: state.licensedStates,
	}
}

export const LocationSelectorModal = connect(mapStateToProps)(LocationSelectorModalPrototype)
