import { useSearchParams } from 'react-router'

import { Paper } from '../paper/paper'

export interface SearchResultProps {
	title: string
	description: string
	to?: string
	onClick?: () => void
}

export function SearchResult(props: SearchResultProps) {
	const [searchParams, setSearchParams] = useSearchParams()

	function handleClick(): void {
		if (props.to) {
			const isExternalUrl = typeof props.to === 'string' && props.to.includes(`http`)

			if (isExternalUrl && props.to) {
				window.open(props.to, '_blank')
			}

			if (!isExternalUrl && props.to) {
				searchParams.set('resource', String(props.to))
				setSearchParams(searchParams)
			}
		} else if (props.onClick) {
			props.onClick()
		}
	}

	return (
		<Paper bgColor="primary" margins={['bottom']} padding={['all']} interactive={true}>
			<div onClick={handleClick}>
				<div className="flex flex-column">
					<strong className="mb-5">{props.title}</strong>
					<div>{props.description}</div>
				</div>
			</div>
		</Paper>
	)
}
