import { Button } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { ToastService } from 'src/services/toast/toast.service'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

type ComponentProps = {
	onCancel: () => void
	onAddChecklist: (updatedTransaction: TransactionMgmtTypes.TransactionTypes.Transaction) => void
	parentTransactionId: number
}

export function Transaction__AddChecklistModal(props: ComponentProps) {
	const [allChecklists, setAllChecklists] = useState<TransactionMgmtTypes.ChecklistTypes.Checklist[] | null>(null)
	const [selectedChecklist, setSelectedChecklist] = useState<TransactionMgmtTypes.ChecklistTypes.Checklist | null>(
		null,
	)

	useEffect(() => {
		TransactionManagementAPI.findChecklists().then((res) => {
			setAllChecklists(res.data)
		})
	}, [])

	function addChecklistToTransaction(): Promise<void> {
		return new Promise((resolve) => {
			if (!selectedChecklist) {
				return
			}
			TransactionManagementAPI.addChecklistToTransaction(
				props.parentTransactionId,
				selectedChecklist.checklistId,
			).then((res) => {
				ToastService.create({ type: 'SUCCESS', body: `This checklist has been added to your transaction` })
				props.onAddChecklist(res.data)
				resolve()
			})
		})
	}

	return (
		<>
			<Modal
				maxHeight={800}
				maxWidth={800}
				fixedHeight={true}
				className="flex flex-column"
				onClose={() => {
					props.onCancel()
				}}
			>
				<>
					<ModalHeader
						title={`Add Checklist`}
						description="Collections of tasks, documents, and notifications related to a specific outcome"
						icon={'list-check'}
					/>
					<ModalBody>
						<div className="flex flex-column" style={{ rowGap: '10px' }}>
							{!allChecklists && <GenericContentLoader width={'fill'} height={100} />}
							{allChecklists && (
								<>
									{allChecklists.map((checklist) => {
										return (
											<Paper
												bgColor="white"
												padding={['all']}
												onClick={() => {
													setSelectedChecklist(checklist)
												}}
												interactive={true}
												selected={selectedChecklist?.checklistId === checklist.checklistId}
											>
												<Typography type="strong">{checklist.name}</Typography>
												<Typography type="normal" margins={['bottom']}>
													{checklist.description}
												</Typography>
												<div className="flex flex-alignItems-center opacity-50">
													<Typography
														type="normal"
														variant={['italic']}
														className="flex flex-alignItems-center mr-40"
													>
														<FontAwesomeIcon icon={['fas', 'list']} className="mr-5" />
														{checklist.childTaskIds.length} tasks
													</Typography>

													<Typography
														type="normal"
														variant={['italic']}
														className="flex flex-alignItems-center mr-20"
													>
														<FontAwesomeIcon
															icon={['fas', 'alarm-clock']}
															className="mr-5"
														/>
														{checklist.childNotificationIds.length} notifications
													</Typography>
												</div>
											</Paper>
										)
									})}
								</>
							)}
						</div>
					</ModalBody>
					<ModalFooter>
						<Button
							variant="outlined"
							size="md"
							primary={false}
							label="Cancel"
							className="mr-10"
							onClick={props.onCancel}
						/>
						<Button
							variant="contained"
							size="md"
							primary={true}
							label="Add Checklist"
							disabled={!selectedChecklist}
							onClick={addChecklistToTransaction}
						/>
					</ModalFooter>
				</>
			</Modal>
		</>
	)
}
