import { Chip } from '@components/chip/chip'
import { Popover } from '@components/popover/popover'
import { TooltipPopover } from '@components/tooltip/tooltip-popover'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { UsePopoverOnEvent } from 'src/services/hooks/use-popover-on-event'
import { RoutingService } from 'src/services/routing/routing.service'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

type ComponentProps = {
	transactionId: number
	style?: React.CSSProperties
}

/** Chip that receives the ID of a task. */
export function TransactionManagementTransactionChip(props: ComponentProps) {
	const navigate = useNavigate()
	const [isPopoverOpen, setIsPopoverOpen] = useState(false)
	const popoverRef = useRef<HTMLDivElement>(null)
	const [transactionProps, setTransactionProps] = useState<
		null | TransactionMgmtTypes.TransactionTypes.Transaction | 'NOT_FOUND'
	>(null)
	const popoverProps = UsePopoverOnEvent({ isPopoverOpen, setIsPopoverOpen, delay: 250 })

	useEffect(() => {
		TransactionManagementAPI.getTransactionById(props.transactionId).then((res) => {
			if (res.data) {
				setTransactionProps(res.data)
			} else {
				setTransactionProps('NOT_FOUND')
			}
		})
	}, [])

	if (transactionProps === 'NOT_FOUND') {
		return <Chip label={`Not Found`} color="var(--colorAdjust50)" />
	}

	return (
		<>
			<Chip
				ref={popoverRef}
				label={transactionProps ? `${transactionProps.name}` : `...`}
				color="var(--colorAdjust50)"
				onClick={() => {
					if (!transactionProps) {
						return
					}
					navigate(`${RoutingService.transactionMgmtRoutePath}/transaction/${transactionProps.transactionId}`)
				}}
				onMouseEnter={() => {
					setIsPopoverOpen(true)
				}}
				onMouseLeave={() => {
					setIsPopoverOpen(false)
				}}
				style={props.style}
			/>
			{popoverRef.current && (
				<Popover
					hideOnMouseOut={true}
					hideOnClickOutside={true}
					isScrimVisible={false}
					{...popoverProps}
					refElement={popoverRef.current}
					setShowPopover={(newState) => {
						setIsPopoverOpen(newState)
					}}
					options={{}}
				>
					<TooltipPopover width={'min-content'}>
						<div className="col-xs-12 text-center">{transactionProps?.name}</div>
					</TooltipPopover>
				</Popover>
			)}
		</>
	)
}
