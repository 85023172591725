import { Checkbox } from '@components/checkbox/checkbox'
import { Dropdown } from '@components/dropdown/dropdown'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'
import { Calendar } from 'src/services/calendar/calendar.types'
import { DateService } from 'src/services/date.service'

import { EditCalendarEventService } from '../edit-calendar.event.service'
import { useEditCalendarEvent, useEditCalendarEventDispatch } from '../state/edit-calendar-event__state'

export function EditCalendarEventRecurringConfig() {
	const editCalendarState = useEditCalendarEvent()
	const editCalendarDispatch = useEditCalendarEventDispatch()

	const localStartTime = EditCalendarEventService().convertUTCDateToLocal({
		utcDate: editCalendarState.startDate,
		utcTime: editCalendarState.startTime,
	})
	const localEndTime = EditCalendarEventService().convertUTCDateToLocal({
		utcDate: editCalendarState.endDate,
		utcTime: editCalendarState.endTime,
	})

	/** ================================== */
	/** Methods*/

	/** Update the weekly recurrence array of a calendar event */
	function updateCalendarByWeekdayRecurrence(weekday: Calendar.Days, isIncluded: boolean): void {
		let weeklyRecurrence: Calendar.Days[] = editCalendarState.days ? editCalendarState.days : []
		if (isIncluded && !weeklyRecurrence.includes(weekday)) {
			weeklyRecurrence.push(weekday)
		}
		if (!isIncluded) {
			weeklyRecurrence = weeklyRecurrence.filter((thisDay) => thisDay !== weekday)
		}

		editCalendarDispatch([{ type: 'update-event-props', payload: { days: weeklyRecurrence } }])
	}

	/** ================================== */
	/** Render Component*/

	if (!localStartTime || !localEndTime) {
		return <></>
	}

	return (
		<>
			<Typography type="h6" margins={['bottom']}>
				Recurring event setup
			</Typography>
			<Paper bgColor="primary" padding={['all']} margins={['bottom']}>
				<Checkbox
					className="mr-20 mb-10"
					checked={editCalendarState.hasEndDate}
					onChange={(newState) => {
						editCalendarDispatch([{ type: 'update-event-props', payload: { hasEndDate: newState } }])
					}}
				>
					This event has an end date
				</Checkbox>

				<div className="flex flex-alignItems-center mb-20">
					<div className="mr-10">Repeat every</div>
					<TextInput
						dataType="number"
						value={
							typeof editCalendarState.recurranceInterval === 'number'
								? editCalendarState.recurranceInterval
								: 1
						}
						width="50px"
						onChange={(updatedValue) => {
							if (typeof updatedValue !== 'number') {
								return
							}
							editCalendarDispatch([
								{
									type: 'update-event-props',
									payload: { recurranceInterval: updatedValue },
								},
							])
						}}
						margins={['right']}
					/>

					<Dropdown<Calendar.Frequency>
						width={200}
						options={[
							{
								label: 'Weeks',
								value: 'WEEKLY',
							},
							{
								label: 'Months',
								value: 'MONTHLY',
							},
						]}
						value={editCalendarState.frequency ? [editCalendarState.frequency] : []}
						onSelect={(selectedOptions) => {
							editCalendarDispatch([
								{ type: 'update-event-props', payload: { frequency: selectedOptions[0] } },
							])
						}}
					/>
				</div>

				<div className="flex flex-alignItems-center mb-20">
					<div className="mr-10">Start after</div>
					<input
						type="date"
						className="text-input"
						value={localStartTime.localDate}
						onChange={(evt) => {
							const utcTime = EditCalendarEventService().convertLocalDateToUTC({
								localDate: evt.target.value,
								localTime: localStartTime.localTime,
							})
							if (!utcTime) {
								return
							}
							editCalendarDispatch([
								{ type: 'update-event-props', payload: { startDate: utcTime.utcDate } },
							])
						}}
					/>
					{editCalendarState.hasEndDate && (
						<>
							<div className="mr-10 ml-20">End before</div>
							<input
								type="date"
								className="text-input"
								value={
									editCalendarState.recurrenceEndDate
										? editCalendarState.recurrenceEndDate
										: undefined
								}
								onChange={(evt) => {
									editCalendarDispatch([
										{
											type: 'update-event-props',
											payload: { recurrenceEndDate: evt.target.value },
										},
									])
								}}
							/>
						</>
					)}
				</div>

				{!editCalendarState.allDay && (
					<div className="flex flex-alignItems-center mb-20">
						<div className="mr-10">Meeting time</div>
						<input
							type="time"
							className="ml-5 text-input"
							value={localStartTime.localTime}
							onChange={(evt) => {
								const utcTime = EditCalendarEventService().convertLocalDateToUTC({
									localDate: localStartTime.localDate,
									localTime: evt.target.value,
								})
								if (!utcTime) {
									return
								}
								editCalendarDispatch([
									{
										type: 'update-event-props',
										payload: { startDate: utcTime.utcDate, startTime: utcTime.utcTime },
									},
								])
							}}
						/>
						<div className="mx-10">to</div>
						<input
							type="time"
							className="text-input"
							value={localEndTime.localTime}
							onChange={(evt) => {
								const utcTime = EditCalendarEventService().convertLocalDateToUTC({
									localDate: localStartTime.localDate,
									localTime: evt.target.value,
								})
								if (!utcTime) {
									return
								}
								editCalendarDispatch([
									{
										type: 'update-event-props',
										payload: { endDate: utcTime.utcDate, endTime: utcTime.utcTime },
									},
								])
							}}
						/>
						<div className="ml-10">{DateService.getUserTimeZone(true)}</div>
					</div>
				)}

				{editCalendarState.frequency === 'WEEKLY' && (
					<>
						<Typography type="strong" margins={['bottom']}>
							Repeats on
						</Typography>
						<div className="flex flex-alignItems-center bg-color__adjust-0 p-10 border-radius__std">
							<Checkbox
								className="mr-20"
								checked={editCalendarState.days && editCalendarState.days.includes('MO') ? true : false}
								onChange={(newState) => {
									updateCalendarByWeekdayRecurrence('MO', newState)
								}}
							>
								Monday
							</Checkbox>

							<Checkbox
								className="mr-20"
								checked={editCalendarState.days && editCalendarState.days.includes('TU') ? true : false}
								onChange={(newState) => {
									updateCalendarByWeekdayRecurrence('TU', newState)
								}}
							>
								Tuesday
							</Checkbox>

							<Checkbox
								className="mr-20"
								checked={editCalendarState.days && editCalendarState.days.includes('WE') ? true : false}
								onChange={(newState) => {
									updateCalendarByWeekdayRecurrence('WE', newState)
								}}
							>
								Wednesday
							</Checkbox>

							<Checkbox
								className="mr-20"
								checked={editCalendarState.days && editCalendarState.days.includes('TH') ? true : false}
								onChange={(newState) => {
									updateCalendarByWeekdayRecurrence('TH', newState)
								}}
							>
								Thursday
							</Checkbox>

							<Checkbox
								className="mr-20"
								checked={editCalendarState.days && editCalendarState.days.includes('FR') ? true : false}
								onChange={(newState) => {
									updateCalendarByWeekdayRecurrence('FR', newState)
								}}
							>
								Friday
							</Checkbox>

							<Checkbox
								className="mr-20"
								checked={editCalendarState.days && editCalendarState.days.includes('SA') ? true : false}
								onChange={(newState) => {
									updateCalendarByWeekdayRecurrence('SA', newState)
								}}
							>
								Saturday
							</Checkbox>

							<Checkbox
								className="mr-20"
								checked={editCalendarState.days && editCalendarState.days.includes('SU') ? true : false}
								onChange={(newState) => {
									updateCalendarByWeekdayRecurrence('SU', newState)
								}}
							>
								Sunday
							</Checkbox>
						</div>
					</>
				)}
			</Paper>
		</>
	)
}
