import './registration.scss'

import { Button } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { LoginRouteWrapper } from '@components/login-route-wrapper/login-route-wrapper'
import { useGetDebouncedValue } from '@components/popover/use-get-debounced-value'
import { Stepper } from '@components/stepper/stepper'
import { Typography } from '@components/text/text'
import { CurrentSessionSliceProps } from '@redux/reducers/current-session-reducer'
import { RootState } from '@redux/store'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { ToastService } from 'src/services/toast/toast.service'

import { AnalyticsService } from '../../services/analytics/analytics.service'
import { Domain } from '../../services/domain/domain.types'
import { useUserAuthentication } from '../../services/hooks/use-user-authentication'
import { LeadCaptureAPI } from '../../services/lead-capture/lead-capture.api'
import { StripeAPI } from '../../services/stripe/stripe.api'
import { StripePublicKey } from '../../services/stripe/stripeConfig'
import { UserAPI } from '../../services/user/user.api'
import { EndUserProps } from '../../services/user/user.types'
import { RegistrationService } from './registration.service'
import { RegistrationEmailExistsModal } from './registration-email-modal'
import { RegistrationAgentInfo } from './routes/agent-info/registration__agent-info'
import { RegistrationContracts } from './routes/contracts/registration__contracts'
import CheckoutForm from './routes/payment/CheckoutForm'
import { RegistrationPersonalInfo } from './routes/personal-info/registration__personal-info'
import { useRegistration, useRegistrationDispatch } from './state/registration__state'

const stripePromise = loadStripe(StripePublicKey)

interface ConnectedProps {
	currentSession: CurrentSessionSliceProps
	user: EndUserProps | null
	domain: Domain.Properties
}

function RegistrationRoutePrototype(props: ConnectedProps) {
	const regState = useRegistration()
	const navigate = useNavigate()
	const regDispatch = useRegistrationDispatch()
	const totalServicesCosts = RegistrationService().getCosts(regState.fields)
	const nextStep = RegistrationService().getRegistrationSteps()[regState.activeStep + 1]
	const previousStep = RegistrationService().getRegistrationSteps()[regState.activeStep - 1]
	const currentUserProps = props.user
	const [prevRegistered, setPrevRegistered] = useState(false)
	const [prevRegisteredActive, setPrevRegisteredActive] = useState(false)
	const [isLicenseInfoInitialized, setIsLicenseInfoInitialized] = useState(false)
	const debouncedHasUserClickedNavButton = useGetDebouncedValue(regState.hasUserClickedNavButton, 500)
	const loading = true
	const [paymentElementKey, setPaymentElementKey] = useState(0)

	const [completedSteps, setCompletedSteps] = useState({
		firstName: regState.fields.firstName,
		lastName: regState.fields.lastName,
		email: regState.fields.email,
		state: regState.fields.state,
		phone: '',
		personalInfoCompleted: false,
		agentInfoCompleted: false,
		contractCompleted: false,
		optionalServiceCompleted: false,
		paymentCompleted: false,
		referrerId: regState.fields.referrerId,
		utmSource: regState.utmParameters.utmSource,
		utmMedium: regState.utmParameters.utmMedium,
		utmCampaign: regState.utmParameters.utmCampaign,
		utmContent: regState.utmParameters.utmContent,
		utmTerm: regState.utmParameters.utmTerm,
	})

	useEffect(() => {
		setCompletedSteps((prevSteps) => ({
			...prevSteps,
			firstName: regState.fields.firstName,
			lastName: regState.fields.lastName,
			email: regState.fields.email,
			state: regState.fields.state,
			referrerId: regState.fields.referrerId,
		}))
	}, [regState.fields])

	const { userIsLoggedIn } = useUserAuthentication({ token: props.currentSession.token })

	useEffect(() => {
		document.body.scrollIntoView()
		regDispatch({ type: 'set-has-user-clicked-nav-button', payload: false })
	}, [regState.activeStep])

	useEffect(() => {
		if (userIsLoggedIn && currentUserProps) {
			if (
				currentUserProps.roles.some((role) => role.roleType === 'USER') ||
				currentUserProps.roles.some((role) => role.roleType === 'ADMIN')
			)
				// User is logged in, redirect to "/hubs" page
				navigate('/hubs')
		}
	}, [userIsLoggedIn])

	useEffect(() => {
		if (regState.activeStep === 3) {
			setPrevRegisteredActive(true)
		}
	})

	const [backToLogin, setBackToLogin] = useState<'EmailExists' | null>(null)

	function dismissModal() {
		setBackToLogin(null)
	}

	function regStateValidationSuccess() {
		if (regState.hasUserClickedNavButton) {
			if (regState.isSectionValidated || prevRegistered) {
				if (regState.activeStep === 0) {
					UserAPI.checkForEmail(regState.fields.email).then((res) => {
						if (res.data.emailExists === false) {
							regDispatch({ type: 'show-page', payload: regState.activeStep + 1 })
						} else {
							setBackToLogin('EmailExists')
						}
					})
				} else {
					regDispatch({ type: 'show-page', payload: regState.activeStep + 1 })
				}
			} else {
				console.log(regState)
				ToastService.create({ type: 'ERROR', body: `Please fill out all required information` })
			}
		} else {
			regDispatch({ type: 'set-has-user-clicked-nav-button', payload: true })
		}
	}

	useEffect(() => {
		if (!debouncedHasUserClickedNavButton) {
			return
		}

		regStateValidationSuccess()
	}, [debouncedHasUserClickedNavButton])

	useEffect(() => {
		let page_title: string | undefined
		let page_location: string | undefined

		switch (regState.activeStep) {
			case 0:
				page_title = `Registration — Personal Info`
				page_location = `/registration/personal-info`
				break
			case 1:
				page_title = `Registration — Agent Info`
				page_location = `/registration/agent-info`
				break
			case 2:
				page_title = `Registration — Contracts`
				page_location = `/registration/contracts-info`
				break
			// case 3:
			// 	page_title = `Registration — Optional Services`
			// 	page_location = `/registration/optional-services`
			// 	break
			case 3:
				page_title = `Registration — Checkout`
				page_location = `/registration/checkout`
				break
		}

		if (page_title && page_location) {
			AnalyticsService().pushEvent({
				event_category: 'navigation',
				event_label: 'page_view',
				value: {
					page_title: page_title,
					page_location: page_location,
				},
			})
		}
	}, [regState.activeStep])

	useEffect(() => {
		if (userIsLoggedIn && currentUserProps) {
			console.log('Logged in and is a current user')
			if (currentUserProps.roles.some((role) => role.roleType === 'PENDING_PAYMENT')) {
				console.log('PENDING_PAYMENT is their role')
				setPrevRegistered(true)
				console.info(`User role includes PENDING_PAYMENT, set prevRegistered to true`)
			}
		}

		if (prevRegistered && !prevRegisteredActive) {
			localStorage.removeItem('registrationState')
			console.log('Logged in, skip ahead')
			regDispatch({ type: 'show-page', payload: 3 })
		}
	}, [userIsLoggedIn, prevRegistered])

	useEffect(() => {
		if (currentUserProps && !isLicenseInfoInitialized) {
			if (currentUserProps.roles.some((role) => role.roleType === 'PENDING_PAYMENT')) {
				regDispatch({
					type: 'update-fields',
					payload: [
						{ key: 'licenseInfo', value: currentUserProps.licenseInformation },
						{ key: 'numYearsLicensed', value: currentUserProps.numYearsLicensed },
					],
				})

				setIsLicenseInfoInitialized(true)
			}
		}
		if (currentUserProps && isLicenseInfoInitialized) {
			console.log('Props: ', currentUserProps)
		}
	}, [prevRegistered, isLicenseInfoInitialized])

	useEffect(() => {
		if (
			totalServicesCosts.optionalServicesCosts.initialTotal > 0 &&
			(regState.isAccountCreated === true || prevRegistered === true) &&
			regState.activeStep > 2
		) {
			console.log(totalServicesCosts.optionalServicesCosts.initialTotal)

			const pricingIds = RegistrationService().getInitialPriceIds(regState.fields)

			const allInitialPriceIdsArray = pricingIds.initialPriceIds
			console.log('Reg page ', allInitialPriceIdsArray)

			// Create a payment intent
			StripeAPI.getPaymentIntent(allInitialPriceIdsArray)
				.then((res) => {
					console.log('Payment Intent was created')
					regDispatch({ type: 'set-stripe-client-secret', payload: res.clientSecret })
				})
				.catch((error) => {
					console.log('there was an error creating the payment intent')
					console.log(error)
				})
		}
		if (
			(totalServicesCosts.optionalServicesCosts.initialTotal < 1 && regState.isAccountCreated === true) ||
			(prevRegistered && regState.activeStep > 1)
		) {
			handleAsyncTasks()
		}
	}, [
		totalServicesCosts.optionalServicesCosts.initialTotal,
		regState.isAccountCreated,
		regState.activeStep,
		prevRegistered,
	])

	useEffect(() => {
		if (regState.isPaymentSuccessful === 'failed') {
			console.log('Reg state updated: ', regState.isPaymentSuccessful)
			handleAsyncTasks()
			resetPaymentElement()
		}
	}, [regState.isPaymentSuccessful])

	/** Update user identification in analytics once the users account has been created */
	useEffect(() => {
		if (regState.isAccountCreated) {
			identifyUserForAnalytics()
		}
	}, [regState.isAccountCreated])

	function identifyUserForAnalytics() {
		if (typeof regState.fields.endUserId === 'number') {
			AnalyticsService().identifyUser(props.domain.domainId, {
				firstName: regState.fields.firstName,
				lastName: regState.fields.lastName,
				email: regState.fields.email,
				endUserId: regState.fields.endUserId,
			})
		} else {
			console.error(`Could not identify user for registration. EndUserId missing`)
		}
	}

	const handleAsyncTasks = async () => {
		// Create a setup intent
		try {
			const res = await StripeAPI.getSetupIntent()
			console.log('Setup Intent was created')
			console.log('Secret Info: ', res.clientSecret)
			regDispatch({ type: 'set-stripe-client-secret', payload: res.clientSecret })
		} catch (error) {
			console.log('There was an error creating the setup intent')
			console.log(error)
		}
	}

	const resetPaymentElement = () => {
		setPaymentElementKey((prevKey) => prevKey + 1)
	}

	return (
		<LoginRouteWrapper isLoginRoute={false} bodyClassName="flex flex-column flex-fillSpace col-xs-12">
			<>
				<div className="flex-fillSpace col-xs-12">
					<Typography type="h3" margins={['top']}>
						Register for an account
					</Typography>
					<Stepper
						steps={RegistrationService().getRegistrationSteps()}
						variant="buttons"
						activeStep={regState.activeStep}
						className="mt-10 show-md"
					/>

					<div className="my-40">
						{regState.activeStep === 0 && <RegistrationPersonalInfo />}
						{regState.activeStep === 1 && <RegistrationAgentInfo />}
						{regState.activeStep === 2 && <RegistrationContracts />}
						{/* {regState.activeStep === 3 && (
							<>
								<RegistrationOptionalServices />

							</>
						)} */}
						{regState.activeStep === 3 && (
							<>
								{regState.clientSecret ? (
									<Elements
										key={paymentElementKey}
										stripe={stripePromise}
										options={{ clientSecret: regState.clientSecret }}
									>
										<CheckoutForm />
									</Elements>
								) : loading ? (
									<div className="mb-10">
										<GenericContentLoader width={'fill'} height={260} />
									</div>
								) : (
									!regState.isAccountCreated &&
									!prevRegistered && (
										<>
											<div className="mb-10">
												<GenericContentLoader width={'fill'} height={260} />
											</div>
										</>
									)
								)}
							</>
						)}
					</div>
				</div>

				<div className="flex flex-alignItems-center">
					{previousStep && (
						<Button
							variant={'contained'}
							size={'lg'}
							label="Back"
							onClick={() => {
								regDispatch({ type: 'show-page', payload: regState.activeStep - 1 })
							}}
							className="mr-10"
							icon={'arrow-left'}
							iconPosition="left"
							disabled={
								regState.activeStep > 2 &&
								regState.activeStep < 4 &&
								(regState.isAccountCreated || prevRegistered)
							}
						/>
					)}
					{nextStep && (
						<Button
							variant={'contained'}
							size={'lg'}
							//disabled={!regState.isSectionValidated && !prevRegistered || regState.activeStep === 3 && (!regState.isAccountCreated && !prevRegistered)}
							label={nextStep.label}
							onClick={() => {
								if (regState.isSectionValidated || prevRegistered) {
									let newCompletedSteps = { ...completedSteps }
									// Check if user is previously registered and update accordingly
									if (prevRegistered && currentUserProps) {
										newCompletedSteps.firstName = currentUserProps.firstName
										newCompletedSteps.lastName = currentUserProps.lastName
										newCompletedSteps.email = currentUserProps.email
										newCompletedSteps.phone = currentUserProps.phone
										newCompletedSteps.state =
											currentUserProps.licenseInformation[0].licensedState.toString()
										newCompletedSteps.personalInfoCompleted = true
										newCompletedSteps.agentInfoCompleted = true
										newCompletedSteps.contractCompleted = true
										newCompletedSteps.optionalServiceCompleted = true
										newCompletedSteps.utmSource = completedSteps.utmSource || ''
										newCompletedSteps.utmMedium = completedSteps.utmMedium || ''
										newCompletedSteps.utmCampaign = completedSteps.utmCampaign || ''
										newCompletedSteps.utmContent = completedSteps.utmContent || ''
										newCompletedSteps.utmTerm = completedSteps.utmTerm || ''
									}

									// Update steps based on the current active step
									if (regState.activeStep === 0) {
										console.log('STEP 0')
										newCompletedSteps.personalInfoCompleted = true
										if (regState.fields.phone) {
											const phoneString = regState.fields.phone.toString()
											newCompletedSteps.phone = phoneString
										}
										console.log('Fields: ', regState.fields)
										if (regState.fields.referrerId) {
											newCompletedSteps.referrerId = regState.fields.referrerId
										}
									}
									if (regState.activeStep === 1) {
										if (currentUserProps?.licenseInformation) {
											newCompletedSteps.state =
												currentUserProps.licenseInformation[0].licensedState.toString()
										}

										let stateLicenseAbbreviation = ''

										switch (regState.fields.licenseInfo[0].licensedState?.licensedStateId) {
											case 1:
												stateLicenseAbbreviation = 'FL'
												break
											case 2:
												stateLicenseAbbreviation = 'GA'
												break
											case 3:
												stateLicenseAbbreviation = 'CO'
												break
											case 4:
												stateLicenseAbbreviation = 'AL'
												break
											case 5:
												stateLicenseAbbreviation = 'TX'
												break
											case 6:
												stateLicenseAbbreviation = 'SC'
												break
										}

										newCompletedSteps.state = stateLicenseAbbreviation
										newCompletedSteps.agentInfoCompleted = true
									}
									if (regState.activeStep === 2) newCompletedSteps.contractCompleted = true
									// if (regState.activeStep === 3) newCompletedSteps.optionalServiceCompleted = true;

									setCompletedSteps(newCompletedSteps)

									LeadCaptureAPI.capturelead(newCompletedSteps)
										.then(() => {})
										.catch((error) => {
											console.log(error)
										})
								}

								regStateValidationSuccess()

								if (
									regState.activeStep === 2 &&
									regState.isSectionValidated &&
									regState.isAccountCreated === false &&
									prevRegistered === false
								) {
									const performRegistration = () => {
										RegistrationService()
											.attachContractToLicense(regState.fields, regState.signature)
											.then(() => {
												RegistrationService()
													.submitRegistration(regState.fields, regState.profilePhoto)
													.then((res) => {
														regDispatch({
															type: 'update-fields',
															payload: [{ key: 'endUserId', value: res.endUserId }],
														})
														regDispatch({ type: 'set-is-account-created', payload: true })
														console.log('Registration worked')
														localStorage.removeItem('registrationState')
													})
													.catch((error) => {
														console.error('Registration error:', error)
													})
											})
									}
									performRegistration()
								}
							}}
							style={{ minWidth: '200px' }}
							icon={'arrow-right'}
							iconPosition="right"
						/>
					)}
					{!nextStep && (
						<>
							{/* <Button
								variant={'contained'}
								size={'lg'}
								label={
									regState.paymentFormSubmit && regState.isPaymentSuccessful !== 'success'
										? 'Completing Registration...'
										: 'Complete Registration'
								}
								disabled={
									(regState.paymentFormSubmit && regState.isPaymentSuccessful !== 'success') ||
									(regState.activeStep === 3 && !regState.isAccountCreated && !prevRegistered)
								}
								onClick={() => {
									regDispatch({ type: 'set-payment-form-submit', payload: true })
								}}
								style={{ minWidth: '200px' }}
							/>
							{regState.paymentFormSubmit && regState.isPaymentSuccessful !== 'success' && (
								<FontAwesomeIcon icon={faSpinner} spin={true} size={'1x'} className="mr-2 ml-10" />
							)} */}
						</>
					)}

					{backToLogin === 'EmailExists' && (
						<>
							<RegistrationEmailExistsModal dismissModal={dismissModal} />
						</>
					)}
				</div>
			</>
		</LoginRouteWrapper>
	)
}

function mapStateToProps(state: RootState) {
	return {
		user: state.user,
		domain: state.domain,
		currentSession: state.currentSession,
	}
}

export const RegistrationRoute = connect(mapStateToProps)(RegistrationRoutePrototype)
