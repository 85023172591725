import { Divider } from '@components/divider/divider'
import { Typography } from '@components/text/text'

import { NUORouteImage } from '../../components/route-image/route-image'

export function NUOWelcomeRoute() {
	return (
		<>
			<NUORouteImage imageUrl="https://images.pexels.com/photos/3768914/pexels-photo-3768914.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
			<div className="flex-fillSpace flex flex-column flex-justifyContent-center">
				<Typography type="h1">Welcome</Typography>
				<div>
					The Sphere, our cutting-edge agent dashboard, serves as the gateway to efficient solutions. Agents
					can manage real-time updates, accelerate document reviews, and access a full suite of marketing
					tools, all through a single sign-on. Bid farewell to unnecessary expenses — at LoKation, you control
					your resources and shape your success.
				</div>
				<Divider width={100} className="my-20" />
				<div>
					<strong>
						Before we get started, we would like to ask a few questions to understand how we can best serve
						you
					</strong>
				</div>
			</div>
		</>
	)
}
