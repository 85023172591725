import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function LoKationResourceLuxuryMembershipDetails() {
	return (
		<div className="flex flex-column overflow-y__scroll">
			<div className="flex flex-wrap">
				<div className="col-xs-12 pr-20-sm flex flex-column flex-alignItems-start">
					<Typography type="h2" margins={['bottom']}>
						Luxury Membership Details
					</Typography>

					<div className="flex flex-wrap">
						<div className="col-xs-12 col-lg-8 pr-40-lg">
							<Typography type="h3">Overview</Typography>
							<p>
								As a LoKation Luxury® agent, you have access to an array of specialized resources that
								are tailored to meet the demands of high-end clientele. These include exclusive use of
								our Lokation Luxury® logos followed by a personal onboarding call with a full-service
								marketing firm to develop your upscale Listing & Branding package. You will receive
								comprehensive marketing and professionally designed branding materials. Luxury agents
								also receive a cutting-edge technology platform complete with personalized websites,
								CRM, and AI-driven lead nurturing resources. There is a separate a world-class marketing
								platform to directly print and ship to an area as well as on-the-spot social media
								templates in seconds. As a member of LoKation Luxury, you will have access to global
								real estate-specific websites syndicated to over 750 websites in 44 countries. You can
								further attract buyers and sellers both domestically and internationally with our full
								lead generation white-glove services amplifying your reach in luxury geographies. The
								Luxury program requires a 12-month commitment and is to be used only with properties
								above $1,000,000 (otherwise marketed as LoKation Real Estate).
							</p>
							<p>
								Upon enrollment in the Luxury Division, an additional fee of $400 will be required at
								the time of registration, as well as an additional $100 per month for the next 11
								months. This division offers exclusive access to luxury products and services, making it
								an ideal choice for those looking to elevate their lifestyle. Join us and experience the
								ultimate in luxury and sophistication.
							</p>
							<p>
								As an alternative option, you have the possibility to make an upfront payment for the
								entire year and benefit from a discount of 10%, bringing the cost down from $1,500 to
								$1,350.
							</p>

							<Typography type="h5">Includes:</Typography>
							<ol>
								<li>
									kvCORE's All-in-One Business Platform (CRM, Website, Lead Gen and Email / Texting)
								</li>
								<li>
									Exclusive Usage of LoKation Luxury Logos in authorized variations in accordance with
									Restrictions on Logo Usage
								</li>
								<li>5 Luxury Branded Folders & Company Overview Brochures </li>
								<li>Custom LoKation Luxury Branded Quick Social Boomerang Templates</li>
								<li>
									Marketing Consultation with LoKation Luxury Provider for Listing Package Creation
								</li>
								<ol>
									<li>Single Page - Custom Realtor Bio</li>
									<li>Single Page - Just Listed / Just Sold</li>
									<li>Single Page - Past Years Sales</li>
									<li>Single Page - Testimonials </li>
									<li>Single Page - Family of Services (FL)</li>
								</ol>
							</ol>
						</div>

						<div
							className="col-xs-12 col-lg-4"
							style={{
								backgroundImage:
									'url(https://images.pexels.com/photos/323780/pexels-photo-323780.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)',
								backgroundSize: 'cover',
							}}
						></div>
					</div>

					<div className="flex flex-wrap mt-40">
						<Typography type="h3" margins={['bottom']} className="col-xs-12">
							Packages
						</Typography>
						<div className="col-xs-12 col-lg-4 pr-40-lg">
							<FontAwesomeIcon
								icon={['fas', 'print']}
								style={{ height: '30px' }}
								className="mb-10 opacity-30"
							/>
							<Typography type="h6">Option 1 - Print Package - $500/month</Typography>
							<strong>*8% discount if paid six months upfront</strong>
							<ul>
								<li>
									Strategy, design, and copy for a direct mail farming campaign consisting of 6 total
									pieces (sizes: either 8.5"x11" or 6.5"x11")*{' '}
								</li>
								<li>
									Marketing consultation (up to 2-hour initial call. 1 hour/week/y after first month)
								</li>
								<li>Design one additional piece of collateral per month (by request)</li>
							</ul>
							<div style={{ fontSize: '85%' }}>*printing, mailing, postage not included</div>
						</div>

						<div className="col-xs-12 col-lg-4 px-20-lg">
							<FontAwesomeIcon
								icon={['fas', 'computer']}
								style={{ height: '30px' }}
								className="mb-10 opacity-30"
							/>
							<Typography type="h6">Option 2 - Digital Package - $500/month</Typography>
							<strong>*8% discount if paid six months upfront</strong>
							<ul>
								<li>
									Marketing consultation (up to 2-hour initial call. 1 hour/week after the first
									month)
								</li>
								<li>Strategy, design, and copy for between 5-8 branded social media posts per month</li>
								<li>
									Creation and basic optimization of Google My Business account and review of all web
									and social media pages
								</li>
								<li>
									Design branded social media image templates every quarter (seasonal or by request)
								</li>
								<li>Creation of up to 2 additional social media posts per month (by request)</li>
							</ul>
						</div>

						<div className="col-xs-12 col-lg-4 pl-40-lg">
							<FontAwesomeIcon
								icon={['fas', 'stars']}
								style={{ height: '30px' }}
								className="mb-10 opacity-30"
							/>
							<Typography type="h6">Option 3- The Works (print+email+digital) - $850/month</Typography>
							<strong>*8% discount if paid six months upfront</strong>
							<ul>
								<li>
									Marketing consultation (up to 2-hour initial call. 1 hour/week after the first
									month)
								</li>
								<li>
									Strategy, design, and copy for a direct mail farming campaign consisting of 6 total
									pieces (sizes: either 8.5"x11" or 6.5"x11")*
								</li>
								<li>One monthly email blast (requires ESP) - copy, design, and blast</li>
								<li>
									Social media management | -2 to 3 posts a week on FB + Instagram, one post on
									LinkedIn, engagement on Instagram and Facebook (like, comment, and interact with a
									social community,) creation of branded templates for sharing images and quotes and
									increasing followers on both platforms.
								</li>
								<li>
									Design branded social media image templates every quarter (seasonal or by request)
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
