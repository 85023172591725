import { Button } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'

interface GenericDeleteConfirmationModalProps {
	itemLabel: string
	onDelete: () => Promise<void>
	onClose: () => void
	zIndex?: number
	escapable?: boolean
}

export function GenericDeleteConfirmationModal(props: GenericDeleteConfirmationModalProps) {
	function hideModal(): void {
		props.onClose()
	}

	return (
		<Modal
			maxWidth={500}
			maxHeight={400}
			fixedHeight={false}
			onClose={hideModal}
			zIndex={props.zIndex}
			escapable={props.escapable}
		>
			<ModalHeader center={true} title={`Are you sure you want to delete ${props.itemLabel}?`} />
			<ModalFooter center={true} gap={true}>
				<Button variant="outlined" size="lg" primary={false} label="Cancel" onClick={hideModal} />
				<Button variant="contained" size="lg" label="Delete" onClick={props.onDelete} />
			</ModalFooter>
		</Modal>
	)
}
