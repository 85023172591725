import { MultiColumnTableTypes } from '@components/multi-column-table/multi-column-table.types'
import { MultiColumnTable } from '@components/multi-column-table/multi-column-table__wrapper'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { RoutingService } from 'src/services/routing/routing.service'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'
import { EndUserProps } from 'src/services/user/user.types'

import { TransactionMgmt__Document__SideSheet } from '../side-sheets/document/document.side-sheet'
import { TransactionMgmt__Documents__Types } from './documents.types'
import { TransactionMgmt__Forms__SearchFilters } from './search-filters'

function TransactionMgmt__Documents__RoutePrototype(props: { userProfile: EndUserProps | null }) {
	const navigate = useNavigate()
	const [searchFilters, setSearchFilters] = useState<TransactionMgmt__Documents__Types.SearchFilters>({
		licensedStateIds: [],
		status: [],
		searchString: '',
		includeCloseDate: false,
		closeDateEnd: null,
		closeDateStart: null,
		includeDateAdded: false,
		dateAddedEnd: null,
		dateAddedStart: null,
	})
	const [documents, setDocuments] = useState<TransactionMgmtTypes.DocumentTypes.Document[] | null>(null)
	const [showDocumentSideSheet, setShowDocumentSideSheet] = useState(false)
	const [selectedDocument, setSelectedDocument] = useState<TransactionMgmtTypes.DocumentTypes.Document | null>(null)

	useEffect(() => {
		TransactionManagementAPI.findDocuments().then((res) => {
			setDocuments(res.data)
		})
	}, [])

	function getTableColumns(): MultiColumnTableTypes.Column<TransactionMgmtTypes.DocumentTypes.Document>[] {
		const columns: MultiColumnTableTypes.Column<TransactionMgmtTypes.DocumentTypes.Document>[] = []

		columns.push({
			label: 'Name',
			columnId: 'name',
			value: 'name',
			displayIndex: 0,
			visible: true,
			width: 'fill',
		})

		return columns
	}

	return (
		<>
			<RouteWrapperV2>
				<RouteSubnavigation
					isCustom={true}
					title={`Forms / Documents`}
					backButton={{
						label: 'Transaction Management',
						onClick: () => {
							navigate(`${RoutingService.transactionMgmtRoutePath}`)
						},
					}}
				>
					<TransactionMgmt__Forms__SearchFilters
						searchFilters={searchFilters}
						setSearchFilters={setSearchFilters}
					/>
				</RouteSubnavigation>

				<RouteBodyV2 className="flex flex-column flex-alignItems-start">
					<MultiColumnTable<TransactionMgmtTypes.DocumentTypes.Document>
						columns={getTableColumns()}
						idKey={`documentId`}
						className="col-xs-12"
						items={documents}
						isConfigurable={false}
						onSelect={(document) => {
							setSelectedDocument(document[0])
							setShowDocumentSideSheet(true)
						}}
						onDoubleClick={(transaction) => {
							/** empty */
						}}
						selectBehavior={'single'}
						selectedItems={[]}
						sortBehavior={{
							sortMethod: 'column-value',
							columnId: 'name',
						}}
						tableId={''}
					/>
				</RouteBodyV2>
			</RouteWrapperV2>
			{showDocumentSideSheet && selectedDocument && (
				<TransactionMgmt__Document__SideSheet
					document={typeof selectedDocument !== 'string' ? selectedDocument : null}
					showParentTransaction={false}
					onClose={() => {
						setShowDocumentSideSheet(false)
					}}
					onUpdate={(savedDoc) => {
						setShowDocumentSideSheet(false)
					}}
					onDelete={(docId) => {
						setShowDocumentSideSheet(false)
					}}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
	}
}

export const TransactionMgmt__Forms__Route = connect(mapStateToProps)(TransactionMgmt__Documents__RoutePrototype)
