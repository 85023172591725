import './desktop-app-header.scss'

import { RootState } from '@redux/store'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { NavLink, useNavigate } from 'react-router'

import { UserDropdownMenu } from '../../routes/shared/components/user-dropdown-menu/user-dropdown-menu'
import { ColorService } from '../../services/color/color.service'
import { DomainTheme } from '../../services/theme/theme.types'
import { EndUserProps } from '../../services/user/user.types'
import { IconButton } from '../icon-button/icon-button'
import { NotificationsButton } from './components/notifications-button/notifications-button'

export type AppHeaderStyle = 'FULL' | 'MOBILE'

type ConnectedProps = {
	user: EndUserProps | null
	theme: DomainTheme.CompleteTheme
	isTrayDocked: boolean
}

function DesktopAppHeaderPrototype(props: ConnectedProps) {
	const navigate = useNavigate()
	const showLogo = getShowLogo()

	function getShowLogo(): boolean {
		if (!props.user || !props.isTrayDocked) {
			return true
		}
		return false
	}

	useEffect(() => {
		if (props.isTrayDocked) {
			document.documentElement.style.setProperty('--app-tray__padding-offset', '300px')
			document.documentElement.style.setProperty('--subroute-navigation__width', '600px')
		} else {
			document.documentElement.style.setProperty('--app-tray__padding-offset', '120px')
			document.documentElement.style.setProperty('--subroute-navigation__width', '400px')
		}
	}, [props.isTrayDocked])

	return (
		<div className="desktop-app-header">
			<div className="desktop-app-header__inner">
				<div className="flex-fillSpace flex flex-alignItems-center">
					{showLogo && (
						<>
							{ColorService.getColorTone(props.theme.appHeaderBgColor) === 'dark' && (
								<img
									onClick={() => {
										if (props.user) {
											navigate(`/hubs`)
										} else {
											navigate(`/`)
										}
									}}
									src={props.theme.logoWhite}
									className="App-logo"
									alt="logo"
									height="20"
									style={{ marginRight: '50px' }}
								/>
							)}
							{ColorService.getColorTone(props.theme.appHeaderBgColor) === 'light' && (
								<img
									onClick={() => {
										if (props.user) {
											navigate(`/hubs`)
										} else {
											navigate(`/`)
										}
									}}
									src={props.theme.logo}
									className="App-logo"
									alt="logo"
									height="20"
									style={{ marginRight: '50px' }}
								/>
							)}
						</>
					)}
				</div>
				<div className="flex flex-alignItems-center">
					{props.user && (
						<>
							<NotificationsButton />

							<NavLink to="/search">
								<IconButton
									tooltip="Search"
									tooltipPlacement="bottom"
									icon={'search'}
									color={props.theme.appHeaderTextColor}
									className="ml-10"
									id="desktop-app-header__search__btn"
								/>
							</NavLink>
						</>
					)}

					<UserDropdownMenu />
				</div>
			</div>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		user: state.user,
		theme: state.theme,
		isTrayDocked: state.ui.isTrayDocked,
	}
}

export const DesktopAppHeader = connect(mapStateToProps)(DesktopAppHeaderPrototype)
