import { ComponentImpl } from '@components/component.types'
import { Utilities } from 'src/services/utilities.service'

export namespace TransactionMgmt__ContactService {
	export namespace Validations {
		export function fullName(): ComponentImpl.Validation<string> {
			return {
				isValid: (updatedValue) => {
					return { isValid: updatedValue.trim().length > 0, showValidationFlag: true }
				},
				message: `Cannot be empty`,
			}
		}

		export function title(): ComponentImpl.Validation<string> {
			return {
				isValid: (updatedValue) => {
					return { isValid: updatedValue.trim().length > 0, showValidationFlag: true }
				},
				message: `Cannot be empty`,
			}
		}

		export function organization(): ComponentImpl.Validation<string> {
			return {
				isValid: (updatedValue) => {
					return { isValid: updatedValue.trim().length > 0, showValidationFlag: true }
				},
				message: `Cannot be empty`,
			}
		}

		export function email(): ComponentImpl.Validation<string> {
			return {
				isValid: (updatedValue) => {
					return { isValid: Utilities.isEmailValid(updatedValue), showValidationFlag: true }
				},
				message: `Must be a valid email address`,
			}
		}

		export function phone(): ComponentImpl.Validation<number | null> {
			return {
				isValid: (updatedValue) => {
					if (!updatedValue) {
						return {
							isValid: false,
							showValidationFlag: true,
						}
					}

					return {
						isValid: Utilities.isPhoneNumberValid(updatedValue, 'us'),
						showValidationFlag: true,
					}
				},
				message: `Must be a valid phone number`,
			}
		}
	}
}
