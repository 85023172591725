import { AgentContractCard } from '@components/agent-contract-card/agent-contract-card'
import { AgentContractSignatureCard } from '@components/agent-contract-signature-card/agent-contract-signature-card'
import { Typography } from '@components/text/text'
import { useEffect, useState } from 'react'

import { useRegistration, useRegistrationDispatch } from '../../state/registration__state'

export function RegistrationContracts() {
	const regState = useRegistration()
	const regDispatch = useRegistrationDispatch()
	const [showSignatureError, setShowSignatureError] = useState(false)

	useEffect(() => {
		validateSection()
	}, [regState.fields])

	function validateSection(): void {
		/** Ensure user has agreed to all contracts */
		const allLicenses = regState.fields.licenseInfo
		let isValid = true
		const areAllContractsAgreedOn = allLicenses.every((license) => {
			const matchingAgreement = regState.licenseAgreements.find(
				(agreement) => agreement.stateId === license.licensedState?.licensedStateId,
			)
			if (matchingAgreement) {
				return matchingAgreement.hasAgreed
			}
			return false
		})
		if (!areAllContractsAgreedOn) {
			isValid = false
		}

		/** Make sure user has a signature */
		if (!regState.signature) {
			isValid = false
			setShowSignatureError(true)
		}
		if (regState.signature) {
			setShowSignatureError(false)
		}

		if (isValid !== regState.isSectionValidated) {
			regDispatch({ type: 'set-validation-state', payload: isValid })
		}
	}

	return (
		<div className="flex flex-column flex-alignItems-start">
			<Typography type="h5" margins={['bottom']}>
				Please sign an agreement for each of the states you are licensed in
			</Typography>

			{regState.fields.licenseInfo.map((license) => {
				function getHasUserAgreed(): boolean {
					const licenseAgreementState = regState.licenseAgreements.find(
						(agreement) => agreement.stateId === license.licensedState?.licensedStateId,
					)
					if (licenseAgreementState) {
						return licenseAgreementState.hasAgreed
					}
					return false
				}

				return (
					<AgentContractCard
						license={license}
						firstName={regState.fields.firstName}
						lastName={regState.fields.lastName}
						signature={regState.signature}
						hasUserClickedNavButton={regState.hasUserClickedNavButton}
						hasAgreed={getHasUserAgreed()}
						onChange={(newState) => {
							if (license.licensedState) {
								regDispatch({
									type: 'set-contract-agreement-state',
									payload: {
										stateId: license.licensedState.licensedStateId,
										hasAgreed: newState,
									},
								})
							}
						}}
					/>
				)
			})}

			<AgentContractSignatureCard
				firstName={regState.fields.firstName}
				lastName={regState.fields.lastName}
				hasUserClickedNavButton={regState.hasUserClickedNavButton}
				signature={regState.signature}
				onChange={(newState) => {
					regDispatch({ type: 'update-signature', payload: newState })
				}}
				showSignatureError={showSignatureError}
			/>
		</div>
	)
}
