import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'
import { DomainTheme } from 'src/services/theme/theme.types'

import { EmptyStatePlaceholderTypes } from './empty-state-placeholder.types'

type ConnectedProps = {
	theme: DomainTheme.CompleteTheme
}

/** Displays a placeholder in place of content when nothing is rendered */
function EmptyStatePlaceholder__Prototype(props: EmptyStatePlaceholderTypes.Component & ConnectedProps) {
	const renderSize = getRenderSize()
	const isSubtle = getIsSubtle()

	function getRenderSize(): EmptyStatePlaceholderTypes.Size {
		if (props.size) {
			return props.size
		}
		return 'sm'
	}

	function getIsSubtle(): boolean {
		if (typeof props.subtle === 'boolean') {
			return props.subtle
		}
		return true
	}

	return (
		<div className="flex flex-alignItems-center flex-justifyContent-center flex-column">
			<FontAwesomeIcon
				icon={['fas', props.icon]}
				className={`${renderSize === 'lg' ? 'mb-40' : 'mb-10'}`}
				style={{
					width: '100px',
					height: '100px',
					color: isSubtle ? props.theme.colorAdjustAlpha30 : props.theme.colorAccent,
				}}
			/>
			{renderSize === 'lg' && (
				<Typography type="h3" variant={['center']} className="color__adjust-alpha-60">
					{props.title}
				</Typography>
			)}
			{renderSize === 'sm' && (
				<Typography type="h5" variant={['center']} className="color__adjust-alpha-60">
					{props.title}
				</Typography>
			)}
			{props.description && <div className="text-center mt-10">{props.description}</div>}
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		theme: state.theme,
	}
}

export const EmptyStatePlaceholder = connect(mapStateToProps)(EmptyStatePlaceholder__Prototype)
