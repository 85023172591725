import { Checkbox } from '@components/checkbox/checkbox'
import { CollapsibleContent } from '@components/collapsible-content/collapsible-content'
import React from 'react'

import { TransactionDateFilterTypes } from './transaction-date-filter.types'

export type ComponentProps = {
	onUpdateProps: (updatedProps: TransactionDateFilterTypes.FilterProps) => void
	style?: React.CSSProperties
	className?: string
	expanded?: true
	filterProps: TransactionDateFilterTypes.FilterProps
}

export function TransactionDateFilter(props: ComponentProps) {
	function handleUpdateProps(updatedProps: Partial<TransactionDateFilterTypes.FilterProps>) {
		const completeUpdatedProps = { ...props.filterProps, ...updatedProps }
		props.onUpdateProps(completeUpdatedProps)
	}

	return (
		<div>
			<CollapsibleContent label="Date" controlled={true} id={`transaction__date`} headerPadding={['all']}>
				<>
					<Checkbox
						checked={props.filterProps.includeDateAdded}
						onChange={(updatedState) => {
							handleUpdateProps({ includeDateAdded: updatedState })
						}}
						className="mb-10"
					>
						Date Added
					</Checkbox>
					<Checkbox
						checked={props.filterProps.includeCloseDate}
						onChange={(updatedState) => {
							handleUpdateProps({ includeCloseDate: updatedState })
						}}
					>
						Close Date
					</Checkbox>
				</>
			</CollapsibleContent>
		</div>
	)
}
