import { SharedElementService } from '@components/shared/shared-element.service'

import { BoxTypes } from './box.types'

/** Generic container for grouping child components. Essentially a <div> but contains properties that act as shorthand for layouts. */
export function Box(props: BoxTypes.Component) {
	function getClass(): string {
		let classes: string[] = []

		if (props.padding) {
			classes = [...classes, ...SharedElementService.getPaddingClass({ padding: props.padding })]
		}

		if (props.margins) {
			classes = [
				...classes,
				...SharedElementService.getMarginClass({ margins: props.margins, marginSize: props.marginSize }),
			]
		}

		if (props.flex) {
			classes = [
				...classes,
				...SharedElementService.getFlexClasses({
					flex: props.flex,
					gap: props.gap,
					wrap: props.wrap,
					justifyContent: props.justifyContent,
					alignItems: props.alignItems,
				}),
			]
		}

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}

	function getStyle(): React.CSSProperties {
		const style: React.CSSProperties = { ...props.style }
		return style
	}

	return (
		<div className={getClass()} style={getStyle()}>
			{props.children}
		</div>
	)
}
