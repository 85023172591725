import { Box } from '@components/box/box'
import { Button } from '@components/button/button'
import { Paper } from '@components/paper/paper'
import { ProgressBar } from '@components/progress-bar/progress-bar'
import { RouteNavigationLink } from '@components/route-navigation-link/route-navigation-link'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { RoutingService } from 'src/services/routing/routing.service'

import { TransactionMgmtRouting } from '../transaction-mgmt.routing'
import { useTransaction } from './state/transaction__state'

/** https://reactrouter.com/en/main/hooks/use-outlet-context */
/** Next step, set up context for Transactions */

export function TransactionMgmt__Transaction__Route() {
	const transactionState = useTransaction()

	const navigate = useNavigate()
	const location = useLocation()
	const pathParts = location.pathname.split('/')
	const routeId = pathParts[pathParts.length - 1]
	console.info(location)

	function getNumCompletedTasks(): number {
		let numCompletedTasks = 0

		transactionState.transaction.tasks.forEach((task) => {
			if (task.status === 'COMPLETE') {
				numCompletedTasks++
			}
		})

		return numCompletedTasks
	}

	function getNumCompletedDocs(): number {
		let numCompletedDocs = 0

		transactionState.transaction.documents.forEach((document) => {
			if (document.status === 'APPROVED') {
				numCompletedDocs++
			}
		})

		return numCompletedDocs
	}

	return (
		<RouteWrapperV2>
			<RouteSubnavigation
				title={transactionState.transaction.name}
				backButton={{
					label: 'Transaction Management',
					onClick: () => {
						navigate(`${RoutingService.transactionMgmtRoutePath}`)
					},
				}}
				isCustom={true}
				className="flex flex-column"
			>
				<div className="flex-fillSpace">
					{TransactionMgmtRouting.getTransactionRouteProperties().map((route) => {
						return (
							<RouteNavigationLink
								label={route.label}
								key={route.label}
								isActive={routeId === route.href}
								onClick={() => {
									navigate(
										`${RoutingService.transactionMgmtRoutePath}/transaction/${transactionState.transaction.transactionId}/${route.href}`,
									)
								}}
								id={typeof route.href === 'function' ? route.href() : route.href}
							/>
						)
					})}
				</div>
				<div>
					<Paper
						bgColor="primary"
						elevate={false}
						className="bg-color__adjust-5"
						padding={['all']}
						gap={true}
						flex="column"
					>
						<Typography type="h6">At a glance</Typography>

						<Box margins={['bottom']}>
							<Box flex="row" justifyContent="space-between">
								<Typography type="strong">Completed Tasks</Typography>
								<span className="opacity-50">
									{getNumCompletedTasks()} / {transactionState.transaction.tasks.length}
								</span>
							</Box>
							<ProgressBar
								start={0}
								progress={getNumCompletedTasks()}
								end={transactionState.transaction.tasks.length}
							/>
						</Box>

						<Box>
							<Box flex="row" justifyContent="space-between">
								<Typography type="strong">Approved Documents</Typography>
								<span className="opacity-50">
									{getNumCompletedDocs()} / {transactionState.transaction.documents.length}
								</span>
							</Box>
							<ProgressBar
								start={0}
								progress={getNumCompletedDocs()}
								end={transactionState.transaction.documents.length}
							/>
						</Box>
					</Paper>

					<Button
						variant="outlined"
						label="Request Review"
						size="md"
						onClick={() => {
							alert(`empty`)
						}}
						margins={['top']}
					/>
				</div>
			</RouteSubnavigation>

			<RouteBodyV2 className="flex flex-column flex-alignItems-start">
				<Outlet />
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}
