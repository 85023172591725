import './chip.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { ColorService } from '../../services/color/color.service'

interface ChipProps {
	label: string
	color: string
	onClick?: () => void
	className?: string
	onClickRemove?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
	onMouseEnter?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
	onMouseLeave?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
	style?: React.CSSProperties
}

export const Chip = React.forwardRef((props: ChipProps, ref: React.ForwardedRef<HTMLDivElement>) => {
	function getStyle(): React.CSSProperties {
		let style: React.CSSProperties = {}
		style.borderColor = props.color

		if (ColorService.getColorTone(props.color) === 'light') {
			style.color = ColorService.adjustHexBrightness(props.color, -100)
		} else {
			style.color = props.color
		}

		if (props.style) {
			style = { ...style, ...props.style }
		}

		return style
	}

	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`chip`)

		if (props.onClick) {
			classes.push(`is-interactive`)
		}

		if (props.className) {
			classes.push(props.className)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	return (
		<div
			ref={ref}
			className={getClass()}
			style={getStyle()}
			onClick={() => {
				if (props.onClick) {
					props.onClick()
				}
			}}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
		>
			{props.label}
			{props.onClickRemove && (
				<FontAwesomeIcon
					icon={['fas', 'times']}
					className="ml-10 mr-5 chip__remove-btn"
					onClick={(event) => {
						if (props.onClickRemove) {
							props.onClickRemove()
							event.stopPropagation()
						}
					}}
				/>
			)}
		</div>
	)
})
