import { Button } from '@components/button/button'
import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'
import { Paper } from '@components/paper/paper'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { FAQAPI } from 'src/services/faq/faq.api'

import { FAQ } from '../../services/faq/faq.types'

export function SingleFAQRoute() {
	const [faq, setFaq] = useState<FAQ | null>(null)
	const params = useParams<{ faqId: string | undefined }>()

	useEffect(() => {
		if (!params.faqId) {
			return
		} else {
			FAQAPI.getFAQById(parseFloat(params.faqId)).then((res) => {
				if (res.data) {
					setFaq(res.data)
				}
			})
		}
	}, [params.faqId])

	if (!faq) {
		return <div>ERROR</div>
	}

	return (
		<RouteWrapperV2>
			<RouteBodyV2 className="mb-20 flex flex-column">
				<Button
					to="/faqs"
					margins={['bottom']}
					label="All Frequently Asked Questions"
					icon={'arrow-left'}
					iconPosition={'left'}
					variant={'text'}
					size={'lg'}
				/>
				<Paper bgColor="primary" padding={['all']} className="col-xs-12">
					<Typography type="h1">{faq.question}</Typography>
					<HorizontalRule className="my-20" />
					<div dangerouslySetInnerHTML={{ __html: faq.answer }} />
				</Paper>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}
