import { DropdownTypes } from '@components/dropdown/dropdown.types'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { AgentSearchState } from '../../lokation-routes/agent-search/state/agent-search__state.types'
import { CollapsibleContent } from '../collapsible-content/collapsible-content'
import { Dropdown } from '../dropdown/dropdown'
import { LocationAutocomplete } from '../google-autocomplete/location-autocomplete'
import { useGetDebouncedValue } from '../popover/use-get-debounced-value'

export interface LocationFilterProps {
	enabled: boolean
	expanded?: true
	onChangeLocation: (locationParams: AgentSearchState.LocationSearchParams) => void
	locationSearchParams: AgentSearchState.LocationSearchParams
}

export function LocationFilter(props: LocationFilterProps) {
	const debouncedState = useGetDebouncedValue(
		props.locationSearchParams,
		1000,
	) as AgentSearchState.LocationSearchParams
	const [lastDebouncedState, setLastDebouncedState] = useState<AgentSearchState.LocationSearchParams>(debouncedState)

	/** ===================================== */
	/** Effects */

	/** Attempt to geocode the users address once they stop typing into it */
	useEffect(() => {
		if (_.isEqual(debouncedState, lastDebouncedState)) {
			return
		}
		setLastDebouncedState(debouncedState)
		// updateLocationFilterProps(debouncedState)
	}, [debouncedState])

	/** ===================================== */
	/** Methods */

	function updateLocationSearchParams(params: Partial<AgentSearchState.LocationSearchParams>): void {
		const updatedLocationParams = {
			...props.locationSearchParams,
			...params,
		}
		props.onChangeLocation(updatedLocationParams)
	}

	function getRadiusOptions(): DropdownTypes.Option<number>[] {
		return [
			{
				label: '10 miles',
				value: 10,
			},
			{
				label: '25 miles',
				value: 25,
			},
			{
				label: '50 miles',
				value: 50,
			},
			{
				label: '100 miles',
				value: 100,
			},
		]
	}

	/** ===================================== */
	/** Render Component */

	return (
		<div>
			<CollapsibleContent label="Location" controlled={true} id={`location`} headerPadding={['all']}>
				<>
					<Dropdown<number>
						label="Maximum Distance"
						labelPlacement="left"
						value={[props.locationSearchParams.radius]}
						options={getRadiusOptions()}
						onSelect={(selectedValues) => {
							if (selectedValues.length > 0) {
								updateLocationSearchParams({ radius: selectedValues[0] })
							}
						}}
						width={'150px'}
					/>

					<LocationAutocomplete
						onPlaceSelected={(place) => {
							if (place.geometry && place.geometry.location) {
								const lat = place.geometry.location.lat
								const lng = place.geometry.location.lng
								const formattedAddress = place.formatted_address
								updateLocationSearchParams({ srcLat: lat, srcLong: lng, formattedAddress })
							}
						}}
					/>
				</>
			</CollapsibleContent>
		</div>
	)
}
