import { Button } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIState } from '@redux/reducers/ui-reducer'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'

import { EditFormRoute } from '../../../modals/admin-editors/edit-form/edit-form'
import { CustomFormsAPI } from '../../../services/custom-forms/custom-forms.api'
import { CustomForms } from '../../../services/custom-forms/custom-forms.types'
import { UserService } from '../../../services/user/user.service'
import { EndUserProps } from '../../../services/user/user.types'
import { useItemAdminHook } from '../use-item-admin-hook'
import { FormCard } from './form__card/form__card'

type ConnectedProps = {
	userProfile: EndUserProps | null
	ui: UIState
}

function FormAdministrationRoute__Prototype(props: ConnectedProps) {
	const navigate = useNavigate()
	const [selectedForm, updateSelectedForm] = useState<CustomForms.Form | 'NEW' | null>(null)
	const [isPending, setIsPending] = useState(true)

	const formAdminHook = useItemAdminHook<CustomForms.Form, CustomForms.SearchParams>({
		getItemsFunction: (page, size, sort, searchParams) => {
			return CustomFormsAPI.findForms({ page, size, sort }, {})
		},
		searchParams: {},
		itemIdKey: 'formId',
		itemTitleKey: 'formName',
		setIsPending: (state) => {
			setIsPending(state)
		},
	})

	const isUserAdmin = props.userProfile ? UserService.isUserAdmin(props.userProfile) : false

	useEffect(() => {
		if (!isUserAdmin) {
			navigate('/hubs')
		}
	}, [isUserAdmin])

	return (
		<>
			<RouteWrapperV2>
				<RouteSubnavigation
					title={`Form Administration`}
					isCustom={true}
					backButton={{
						label: `Administration`,
						onClick: () => {
							navigate('/administration')
						},
					}}
				>
					<></>
				</RouteSubnavigation>

				<RouteBodyV2 id="form-admin__route-body">
					<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-10">
						<Typography type="h1" margins={['bottom']}>
							Form Administration
						</Typography>
						<Button
							variant="outlined"
							label="Create Form"
							size={'md'}
							onClick={() => {
								updateSelectedForm('NEW')
							}}
						/>
					</div>
					{!isPending && !formAdminHook.hasMoreItems && formAdminHook.items.length === 0 && (
						<div className="flex flex-column flex-fillSpace flex-justifyContent-center flex-alignItems-center">
							<FontAwesomeIcon icon={['far', 'newspaper']} size="4x" className="mb-20" />
							<Typography type="h3">We cannot find any forms matching your criteria</Typography>
						</div>
					)}

					{formAdminHook.items && (
						<InfiniteScroll
							dataLength={formAdminHook.items.length}
							next={formAdminHook.loadNextPageOfItems}
							hasMore={formAdminHook.hasMoreItems}
							scrollableTarget={props.ui.viewMode === 'FULL' ? `form-admin__route-body` : undefined}
							loader={
								<>
									<GenericContentLoader width={'fill'} height={70} className="mb-10" />
									<GenericContentLoader width={'fill'} height={70} className="mb-10" />
									<GenericContentLoader width={'fill'} height={70} className="mb-10" />
								</>
							}
						>
							{formAdminHook.items.map((form) => {
								return (
									<FormCard
										{...form}
										key={form.formId}
										onClick={() => {
											updateSelectedForm(form)
										}}
									/>
								)
							})}
						</InfiniteScroll>
					)}
				</RouteBodyV2>
			</RouteWrapperV2>

			{selectedForm === 'NEW' && (
				<EditFormRoute
					dismissModal={() => {
						updateSelectedForm(null)
					}}
					onCreate={formAdminHook.handleCreateItem}
				/>
			)}
			{selectedForm && selectedForm !== 'NEW' && (
				<EditFormRoute
					item={selectedForm}
					dismissModal={() => {
						updateSelectedForm(null)
					}}
					onUpdate={formAdminHook.handleUpdateItem}
					onDelete={formAdminHook.handleDeleteItem}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
		ui: state.ui,
	}
}

export const FormAdministrationRoute = connect(mapStateToProps)(FormAdministrationRoute__Prototype)
