/* eslint-disable react/jsx-pascal-case */
import { ResourceCard__ExternalWebsiteWarning } from '@components/resource-card/components/resource-card__external-website-warning'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { YouTubeModal } from '@components/youtube-modal/youtube-modal'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useNavigate, useParams, useSearchParams } from 'react-router'
import { ResourceService } from 'src/services/resources/resources.service'

import { AnalyticsService } from '../../services/analytics/analytics.service'
import { ResourcesAPI } from '../../services/resources/resources.api'
import { LokationResource } from '../../services/resources/resources.types'

export function ResourceRoute() {
	const [searchParams, setSearchParams] = useSearchParams()
	let navigate = useNavigate()
	let params = useParams()
	const [resourceProps, setResourceProps] = useState<LokationResource | null>(null)

	const isExternalUrl = resourceProps ? ResourceService.getIsExternalUrl(resourceProps) : false

	const isExternalUrlYouTubeVideo = resourceProps
		? ResourceService.getIsExternalUrlYouTubeVideo(resourceProps)
		: false

	useEffect(() => {
		if (!params.slug) {
			return
		}
		ResourcesAPI.getResourceById(Number(params.slug))
			.then((resourceRes) => {
				if (resourceRes.data) {
					setResourceProps(resourceRes.data)
				} else {
					navigate('/')
				}
			})
			.catch((err: AxiosError) => {
				navigate('/')
				if (err.response?.status === 401 || err.response?.status === 404) {
					throw new Response('Not Found', { status: err.response?.status })
				}
			})
	}, [params])

	useEffect(() => {
		if (!resourceProps) {
			return
		}

		if (isExternalUrl && resourceProps.url && !isExternalUrlYouTubeVideo) {
			setTimeout(() => {
				window.open(resourceProps.url, '_blank')
			}, 1500)
		}

		if (!isExternalUrl && resourceProps.url?.includes('/resources')) {
			searchParams.set('resource', String(resourceProps.url))
			setSearchParams(searchParams)
		}

		AnalyticsService().pushEvent({
			event_category: 'navigation',
			event_label: 'open_resource',
			value: {
				resource_id: resourceProps.resourceId,
				resource_title: resourceProps.title,
			},
		})
	}, [resourceProps?.resourceId])

	return (
		<RouteWrapperV2 className="bg-color__app-header">
			{resourceProps && (
				<>
					{isExternalUrl && !isExternalUrlYouTubeVideo && (
						<>
							{ReactDOM.createPortal(
								<ResourceCard__ExternalWebsiteWarning url={resourceProps.url} />,
								document.body,
							)}
						</>
					)}
					{isExternalUrlYouTubeVideo && resourceProps.url && (
						<YouTubeModal
							onHide={() => {
								navigate('/')
							}}
							title={resourceProps.title}
							url={resourceProps.url}
							description={resourceProps.description}
						/>
					)}
				</>
			)}
		</RouteWrapperV2>
	)
}
