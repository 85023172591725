import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownTypes } from '@components/dropdown/dropdown.types'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'

import { useDomainAdmin, useDomainAdminDispatch } from '../state/domain-admin__state'

export function DomainAdminChatGptSettings() {
	const domainAdminState = useDomainAdmin()
	const domainAdminDispatch = useDomainAdminDispatch()

	function getGptModels(): DropdownTypes.Option<string>[] {
		return [
			{
				label: 'GPT 3.5 Turbo',
				value: 'GPT_3_5_TURBO',
			},
			{
				label: 'GPT 4',
				value: 'GPT_4',
			},
			{
				label: 'GPT 4o',
				value: 'GPT_4O',
			},
		]
	}

	return (
		<>
			<Typography type="h1" margins={['bottom']}>
				ChatGPT Settings
			</Typography>
			<Paper
				bgColor="primary"
				padding={['all']}
				marginSize="section"
				margins={['bottom']}
				flex="column"
				gap={true}
			>
				<Dropdown<string>
					value={
						domainAdminState.modified.chatGptSettings.gptModel
							? [domainAdminState.modified.chatGptSettings.gptModel]
							: []
					}
					label="GPT Model"
					searchable={true}
					options={getGptModels()}
					onSelect={(updatedValue) => {
						console.log('Updated Value: ', updatedValue[0])
						domainAdminDispatch({
							type: 'update-chat-gpt-settings',
							payload: [{ key: 'gptModel', value: updatedValue[0] }],
						})
					}}
					width={450}
				/>
				<TextInput
					width={450}
					dataType="text"
					label="Your ChatGPT API Key"
					value={domainAdminState.modified.chatGptSettings.apiKey}
					onChange={(updatedValue) => {
						domainAdminDispatch({
							type: 'update-chat-gpt-settings',
							payload: [{ key: 'apiKey', value: updatedValue }],
						})
					}}
				/>
				<TextInput
					width={450}
					dataType="text"
					rows={4}
					label="Enter the prompt for the listing generation (Note: you must include {address} and {reference} - this is where the address and user description will be populated in the query to ChatGPT"
					value={domainAdminState.modified.chatGptSettings.listingPrompt}
					onChange={(updatedValue) => {
						domainAdminDispatch({
							type: 'update-chat-gpt-settings',
							payload: [{ key: 'listingPrompt', value: updatedValue }],
						})
					}}
				/>
				<TextInput
					width={450}
					dataType="number"
					label="Max response tokens"
					value={domainAdminState.modified.chatGptSettings.maxResponseTokens}
					onChange={(updatedValue) => {
						domainAdminDispatch({
							type: 'update-chat-gpt-settings',
							payload: [{ key: 'maxResponseTokens', value: updatedValue }],
						})
					}}
				/>
			</Paper>
		</>
	)
}
