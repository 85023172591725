import { ModalTypes } from './modal.types'

export function ModalFooter(props: ModalTypes.Footer) {
	function getClass(): string {
		const classes: string[] = []

		classes.push(`modal__footer`)

		if (props.center) {
			classes.push(`flex-justifyContent-center`)
		} else {
			classes.push(`flex-justifyContent-end`)
		}

		if (props.gap) {
			classes.push(`std__gap`)
		}

		return classes.join(' ')
	}

	return <div className={getClass()}>{props.children}</div>
}
