import { Button } from '@components/button/button'
import { Checkbox } from '@components/checkbox/checkbox'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'
import { Typography } from '@components/text/text'
import { useState } from 'react'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

interface ComponentProps {
	task: TransactionMgmtTypes.TaskTypes.Task
	onDelete: (deleteChildDocs: boolean) => Promise<void>
	onClose: () => void
}

export function TransactionMgmt__DeleteTask__Modal(props: ComponentProps) {
	const [deleteChildDocs, setDeleteChildDocs] = useState(false)

	function hideModal(): void {
		props.onClose()
	}

	return (
		<Modal maxWidth={500} maxHeight={400} fixedHeight={false} onClose={hideModal}>
			<ModalHeader title={`Delete Task`} />
			<ModalBody>
				<>
					<Typography type="normal" margins={['bottom']}>
						This task has {props.task.childDocIds.length} child documents
					</Typography>
					<Checkbox
						checked={deleteChildDocs}
						onChange={(newState) => {
							setDeleteChildDocs(newState)
						}}
					>
						<Typography type="semibold">Delete child documents</Typography>
					</Checkbox>
				</>
			</ModalBody>
			<ModalFooter>
				<Button
					variant="outlined"
					size="lg"
					primary={false}
					label="Cancel"
					className="mr-10"
					onClick={hideModal}
				/>
				<Button
					variant="contained"
					size="lg"
					label="Delete"
					onClick={() => {
						return new Promise((resolve, reject) => {
							props
								.onDelete(deleteChildDocs)
								.then(() => {
									resolve()
								})
								.catch(() => {
									reject()
								})
						})
					}}
				/>
			</ModalFooter>
		</Modal>
	)
}
