import { Typography } from '@components/text/text'

import { useTransaction } from '../../state/transaction__state'

export function TransactionMgmt__Transaction__Commissions__Route() {
	const transactionState = useTransaction()

	return (
		<div className="col-xs-12" style={{ maxWidth: '1200px' }}>
			<Typography type="h1" margins={['bottom']}>
				Commissions
			</Typography>
			<div>{transactionState.transaction.name}</div>
		</div>
	)
}
