import { Button } from '@components/button/button'
import { Checkbox } from '@components/checkbox/checkbox'
import { FilteredOptionPanel } from '@components/filtered-option-panel/filtered-option-panel'
import { Paper } from '@components/paper/paper'
import { RichTextEditor } from '@components/rich-text-editor/rich-text-editor'
import { StateSelectorDropdown } from '@components/state-selector-dropdown/state-selector-dropdown'
import { Switch } from '@components/switch/switch'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'
import { RootState, store } from '@redux/store'
import { useState } from 'react'
import { connect } from 'react-redux'
import { HomepageModal } from 'src/routes/home/modal-notices/homepage.modal'
import { LicensedStatesService } from 'src/services/licensed-states/licensed-states.service'
import { LicensedState } from 'src/services/licensed-states/licenses-states.types'
import { LicensedRegion } from 'src/services/regions/regions.types'
import { SpecialtyService } from 'src/services/specialties/specialty.service'
import { LokationSpecialty } from 'src/services/specialties/specialty.types'

import { useDomainAdmin, useDomainAdminDispatch } from '../state/domain-admin__state'

type ConnectedProps = {
	specialties: LokationSpecialty[]
	licensedStates: LicensedState[]
	licensedRegions: LicensedRegion[]
}

function DomainAdminHomepageModalSettingsPrototype(props: ConnectedProps) {
	const domainAdminState = useDomainAdmin()
	const domainAdminDispatch = useDomainAdminDispatch()

	const [previewModal, setPreviewModal] = useState(false)

	const [isModalEnabled, setIsModalEnabled] = useState<boolean>(
		domainAdminState.modified.homepageModalSettings.modalEnabled,
	)
	const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(
		domainAdminState.modified.homepageModalSettings.buttonEnabled,
	)

	const selectedModalLocations = LicensedStatesService.getStatesFromIds(
		props.licensedStates,
		domainAdminState.modified.homepageModalSettings.includedLicensedStateIds,
	)

	const selectedSpecialties = SpecialtyService.getSpecialtiesFromIds(
		props.specialties,
		domainAdminState.modified.homepageModalSettings.specialtyIds,
	)

	const handleModalSwitchChange = () => {
		setIsModalEnabled((prevState) => !prevState)

		domainAdminDispatch({
			type: 'update-homepage-modal-settings',
			payload: [{ key: 'modalEnabled', value: !isModalEnabled }],
		})
	}

	const handleButtonSwitchChange = () => {
		setIsButtonEnabled((prevState) => !prevState)

		domainAdminDispatch({
			type: 'update-homepage-modal-settings',
			payload: [{ key: 'buttonEnabled', value: !isButtonEnabled }],
		})
	}

	return (
		<>
			<Typography type="h1" margins={['bottom']}>
				Homepage Modal Settings
			</Typography>

			<Paper bgColor="primary" padding={['all']} marginSize="section" margins={['bottom']}>
				<Typography type="normal" color="secondary" margins={['bottom']}>
					When enabled, this feature displays on the Sphere's homepage. You can use the Rich Text Editor to
					add content, including text and external images. Additionally, you can configure a customizable
					button to link to any internal or external URL.
				</Typography>

				<div className="flex flex-alignItems-center mb-20">
					<Switch className="mx-5" checked={isModalEnabled} onChange={handleModalSwitchChange} />
					<span>{isModalEnabled ? 'Enabled' : 'Disabled'}</span>
				</div>

				<TextInput
					width={300}
					dataType="text"
					label="Title"
					value={domainAdminState.modified.homepageModalSettings.title}
					onChange={(updatedValue) => {
						domainAdminDispatch({
							type: 'update-homepage-modal-settings',
							payload: [{ key: 'title', value: updatedValue }],
						})
					}}
					margins={['bottom', 'right']}
				/>

				<div style={{ maxWidth: '700px' }}>
					<RichTextEditor
						label={`Content`}
						className="mb-20"
						value={domainAdminState.modified.homepageModalSettings.content}
						rows={25}
						onChange={(updatedValue) => {
							domainAdminDispatch({
								type: 'update-homepage-modal-settings',
								payload: [{ key: 'content', value: updatedValue }],
							})
						}}
					/>
				</div>
				<div className="flex flex-alignItems-center mb-20">
					<strong>Add button?</strong>
					<Switch className="mx-5" checked={isButtonEnabled} onChange={handleButtonSwitchChange} />
					<span>{isButtonEnabled ? 'Enabled' : 'Disabled'}</span>
				</div>

				{isButtonEnabled && (
					<>
						<TextInput
							width={300}
							dataType="text"
							label="Button Label"
							value={domainAdminState.modified.homepageModalSettings.buttonLabel}
							onChange={(updatedValue) => {
								domainAdminDispatch({
									type: 'update-homepage-modal-settings',
									payload: [{ key: 'buttonLabel', value: updatedValue }],
								})
							}}
							margins={['bottom', 'right']}
						/>
						<TextInput
							width={300}
							dataType="text"
							label="Button Link"
							value={domainAdminState.modified.homepageModalSettings.buttonLink}
							onChange={(updatedValue) => {
								domainAdminDispatch({
									type: 'update-homepage-modal-settings',
									payload: [{ key: 'buttonLink', value: updatedValue }],
								})
							}}
							margins={['bottom', 'right']}
						/>
					</>
				)}

				<Typography type="h6" color="primary">
					Filtering Options
				</Typography>
				<Typography type="normal" color="secondary" margins={['bottom']}>
					Determine which users will see this modal after logging in.
				</Typography>

				<div className="mb-20 flex flex-column" style={{ maxWidth: '700px' }}>
					<strong className="mb-5">Applicable States</strong>
					<Checkbox
						checked={domainAdminState.modified.homepageModalSettings.includeAllStates}
						onChange={(checked) => {
							domainAdminDispatch({
								type: 'update-homepage-modal-settings',
								payload: [{ key: 'includeAllStates', value: checked }],
							})
						}}
						style={{ marginBottom: '8px' }}
					>
						This is applicable to users in all states
					</Checkbox>
					<StateSelectorDropdown
						width={`100%`}
						multiselect={true}
						selectedStates={selectedModalLocations}
						options={store.getState().licensedStates}
						onSelect={(selectedStates) => {
							const updatedStates = selectedStates
								.filter((state) => state.licensedStateId)
								.map((state) => state.licensedStateId)

							domainAdminDispatch({
								type: 'update-homepage-modal-settings',
								payload: [{ key: 'includedLicensedStateIds', value: updatedStates }],
							})
						}}
						disabled={domainAdminState.modified.homepageModalSettings.includeAllStates}
					/>
				</div>
				<div className="mb-20 flex flex-column" style={{ maxWidth: '300px' }}>
					<FilteredOptionPanel
						selectedSpecialties={selectedSpecialties}
						specialtyOptions={store.getState().specialties}
						selectedVisibility={domainAdminState.modified.homepageModalSettings.specialtyInclusion}
						onSpecialtySelect={(selectedSpecialties) => {
							const updatedSpecialties = selectedSpecialties
								.filter((specialty) => specialty.specialtyId)
								.map((specialty) => specialty.specialtyId)
							console.log('selected specialties: ', selectedSpecialties)
							domainAdminDispatch({
								type: 'update-homepage-modal-settings',
								payload: [{ key: 'specialtyIds', value: updatedSpecialties }],
							})
						}}
						onVisibilitySelect={(selectedVisibility) => {
							domainAdminDispatch({
								type: 'update-homepage-modal-settings',
								payload: [{ key: 'specialtyInclusion', value: selectedVisibility }],
							})
						}}
					/>
				</div>

				<Button
					variant={'outlined'}
					label={'Preview Modal'}
					size={'lg'}
					onClick={() => {
						setPreviewModal(true)
					}}
					margins={['bottom']}
				/>
			</Paper>
			{previewModal && (
				<>
					<HomepageModal
						dismissModal={() => {
							setPreviewModal(false)
						}}
						modalProps={domainAdminState.modified.homepageModalSettings}
					/>
				</>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		specialties: state.specialties,
		licensedStates: state.licensedStates,
		licensedRegions: state.licensedRegions,
	}
}

export const DomainAdminHomepageModalSettings = connect(mapStateToProps)(DomainAdminHomepageModalSettingsPrototype)
