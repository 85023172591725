import './dropdown.scss'

import { TextInputService } from '@components/text-input/text-input.service'
import { faLock } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import React from 'react'

import { UsePopoverOnEvent } from '../../services/hooks/use-popover-on-event'
import { Popover } from '../popover/popover'
import { TextInput } from '../text-input/text-input'
import { Tooltip } from '../tooltip/tooltip'
import { DropdownTypes } from './dropdown.types'
import { DropdownOption } from './dropdown-option'

export function Dropdown<T>(props: DropdownTypes.Component<T>) {
	/** ============================================= */
	/** Props and State */
	const [isUserInteracted, setIsUserInteracted] = useState(false)
	const [isValid, setIsValid] = useState(true)
	const [showValidationError, setShowValidationError] = useState(true)
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const [searchString, setSearchString] = useState('')
	const optionsMatchingString = returnOptionsMatchingString(searchString)

	/** ============================================= */
	/** Hooks */

	const dropdownEltRef = useRef<HTMLDivElement>(null)
	const popoverProps = UsePopoverOnEvent({
		isPopoverOpen: isDropdownOpen,
		setIsPopoverOpen: setIsDropdownOpen,
		delay: 0,
	})
	const searchInputRef = useRef<HTMLInputElement>(null)

	/** ============================================= */
	/** Effects */

	useEffect(() => {
		const updatedIsValid = isValueValid(props.value, isUserInteracted)
		if (updatedIsValid !== isValid) {
			setIsValid(updatedIsValid)
		}
	}, [props.value])

	/** Revalidate component when the value of 'forceRevalidation' is changed */
	useEffect(() => {
		const updatedIsValid = isValueValid(props.value, isUserInteracted)
		if (updatedIsValid !== isValid) {
			setIsValid(updatedIsValid)
		}
	}, [props.forceRevalidation])

	useEffect(() => {
		if (!isDropdownOpen) {
			setSearchString('')
			if (dropdownEltRef.current) {
				dropdownEltRef.current.focus()
			}
		}
	}, [isDropdownOpen])

	useEffect(() => {
		if (isDropdownOpen) {
			if (searchInputRef.current) {
				searchInputRef.current.focus()
			}
		}
	}, [popoverProps.showPopover])

	/** ============================================= */
	/** Methods */

	function isValueValid(value: T[], hasUserInteracted: boolean): boolean {
		if (props.validation) {
			const validationResult = props.validation.isValid(value, hasUserInteracted)

			if (typeof validationResult.showValidationFlag === 'boolean') {
				setShowValidationError(validationResult.showValidationFlag)
			}

			return validationResult.isValid
		}
		return true
	}

	function getDisplayedValue(): string {
		if (props.value.length === 0) {
			return `Select one`
		}
		if (props.value.length === 1) {
			return getLabelForValue(props.value[0])
		}
		if (props.value.length > 1) {
			return `${props.value.length} selected`
		}
		return ``
	}

	function flattenOptionsIntoArray(): DropdownTypes.Option<T>[] {
		const flattenedOptions: DropdownTypes.Option<T>[] = []

		props.options.forEach((parentOption) => {
			addOptionAndChildren(parentOption)
		})

		function addOptionAndChildren(parentOption: DropdownTypes.Option<T>): void {
			flattenedOptions.push(parentOption)
			if ('children' in parentOption) {
				parentOption.children.forEach((childOption) => {
					addOptionAndChildren(childOption)
				})
			}
		}

		return flattenedOptions
	}

	function getLabelForValue(value: T): string {
		const selectedOption = flattenOptionsIntoArray().find((option) => _.isEqual(option.value, value))
		if (selectedOption) {
			return selectedOption.label
		} else {
			return `Not found`
		}
	}

	function onOptionClick(clickedOption: T): void {
		if (!isUserInteracted) {
			setIsUserInteracted(true)
		}

		if (props.multiselect) {
			const isOptionSelected = props.value.find((thisOption) => {
				return thisOption === clickedOption
			})
			if (isOptionSelected) {
				const updatedSelectedOptions = props.value.filter((thisOption) => thisOption !== clickedOption)
				props.onSelect(updatedSelectedOptions)
				setIsValid(isValueValid(updatedSelectedOptions, true))
			} else {
				const updatedSelectedOptions = [...props.value, clickedOption]
				props.onSelect(updatedSelectedOptions)
				setIsValid(isValueValid(updatedSelectedOptions, true))
			}
		} else {
			props.onSelect([clickedOption])
			setIsValid(isValueValid([clickedOption], true))
			setIsDropdownOpen(false)
		}
	}

	function returnOptionsMatchingString(thisSearchString: string): DropdownTypes.ChildOption<T>[] {
		const matchingOptions: DropdownTypes.ChildOption<T>[] = []

		flattenOptionsIntoArray().forEach((option) => {
			if ('children' in option) {
				return
			}
			if (option.label.toLocaleLowerCase().includes(thisSearchString.toLocaleLowerCase())) {
				matchingOptions.push(option)
			}
		})

		return matchingOptions
	}

	function renderSelectInput(): React.ReactElement {
		const input: React.ReactNode = (
			<>
				{props.autoComplete && (
					<input
						autoComplete={props.autoComplete}
						onChange={(evt) => {
							if (evt.target.value) {
								props.onSelect([evt.target.value as T])
							}
						}}
						style={{ position: 'absolute', opacity: 0, pointerEvents: 'none' }}
						tabIndex={-1}
					/>
				)}
				<div
					tabIndex={0}
					ref={dropdownEltRef}
					className={getInputClass()}
					onClick={() => {
						setIsDropdownOpen(!isDropdownOpen)
					}}
					onKeyDown={(evt) => {
						if (evt.key === 'Enter') {
							setIsDropdownOpen(!isDropdownOpen)
						}
						if (evt.key.match(/^[0-9a-z]+$/)) {
							setIsDropdownOpen(!isDropdownOpen)
							if (props.searchable) {
								setSearchString(evt.key)
								if (searchInputRef.current) {
									searchInputRef.current.focus()
								}
							}
						}
					}}
				>
					<div className="text-input__inner-content-wrapper flex flex-alignItems-center flex-justifyContent-spaceBetween col-xs-12">
						<div className="dropdown__displayed-value">{getDisplayedValue()}</div>
						<div className="flex flex-alignItems-center">
							{!isValid && props.validation && showValidationError && (
								<Tooltip
									icon="warning"
									className="color__warning mr-10"
									body={props.validation.message}
								/>
							)}
							{props.disabled ? (
								<FontAwesomeIcon icon={faLock} />
							) : (
								<FontAwesomeIcon icon={['far', isDropdownOpen ? 'angle-up' : 'angle-down']} />
							)}
						</div>
					</div>
				</div>
			</>
		)

		if (props.label) {
			switch (props.labelPlacement) {
				case 'left':
					return (
						<div
							className={`flex flex-alignItems-center flex-justifyContent-spaceBetween ${TextInputService.getWrapperClass(
								{
									margins: props.margins,
									marginSize: props.marginSize,
								},
							)}`}
							style={TextInputService.getWrapperStyle({ style: props.style, width: props.width })}
						>
							<div className="flex flex-alignItems-center">
								<strong>{props.label}</strong>
								{props.tooltip && <Tooltip className="ml-5" body={props.tooltip} />}
							</div>
							{input}
						</div>
					)
				case 'top':
				default:
					return (
						<div
							className={`flex flex-column flex-alignItems-start ${TextInputService.getWrapperClass({
								margins: props.margins,
								marginSize: props.marginSize,
							})}`}
							style={TextInputService.getWrapperStyle({ style: props.style, width: props.width })}
						>
							<div className="flex flex-alignItems-center pb-5">
								<strong>{props.label}</strong>
								{props.tooltip && <Tooltip className="ml-5" body={props.tooltip} />}
							</div>
							{input}
						</div>
					)
			}
		} else {
			return (
				<div
					className={TextInputService.getWrapperClass({
						margins: props.margins,
						marginSize: props.marginSize,
					})}
					style={TextInputService.getWrapperStyle({ style: props.style, width: props.width })}
				>
					{input}
				</div>
			)
		}
	}

	function getInputClass(): string {
		const classes: string[] = []

		classes.push(`text-input col-xs-12 flex flex-alignItems-center`)

		if (props.disabled) {
			classes.push(`disabled`)
		}

		if (props.size && props.size === 'sm') {
			classes.push(`sm`)
		}

		if (!isValid && showValidationError) {
			classes.push(`is-error`)
		}

		return classes.join(' ')
	}

	/** ============================================= */
	/** Render Component */

	return (
		<>
			{renderSelectInput()}
			{dropdownEltRef.current && (
				<Popover
					hideOnMouseOut={false}
					hideOnClickOutside={true}
					isScrimVisible={true}
					{...popoverProps}
					refElement={dropdownEltRef.current}
					setShowPopover={(newState) => {
						setIsDropdownOpen(newState)
					}}
					options={{}}
				>
					<div
						style={{ maxHeight: '350px', height: props.maximizeHeight ? '100%' : '', width: '300px' }}
						className="context-menu__popover p-5"
					>
						{props.searchable && (
							<div className="mb-5">
								<TextInput
									ref={searchInputRef}
									placeholder="Search"
									dataType="text"
									value={searchString}
									width="100%"
									postpend={<FontAwesomeIcon icon={['far', 'search']} className="mr-10" />}
									onChange={(value) => {
										if (typeof value === 'string') {
											setSearchString(value)
										}
									}}
								/>
							</div>
						)}
						{searchString && optionsMatchingString.length === 0 && (
							<div>Could not find any options matching '{searchString}'</div>
						)}
						{searchString && optionsMatchingString.length > 0 && (
							<>
								{optionsMatchingString.map((option, index) => {
									let optionKey: string | number = index
									if (typeof option.value === 'string' || typeof option.value === 'number') {
										optionKey = option.value
									}

									return (
										<DropdownOption
											{...option}
											onOptionClick={onOptionClick}
											key={optionKey}
											multiselect={props.multiselect}
											isOnlyParent={props.onlyParent}
											selectedOptions={props.value}
											onHide={() => {
												setIsDropdownOpen(false)
											}}
										/>
									)
								})}
							</>
						)}
						{!searchString && (
							<>
								{props.options.map((option, index) => {
									let optionKey: string | number = index
									if (typeof option.value === 'string' || typeof option.value === 'number') {
										optionKey = option.value
									}

									return (
										<DropdownOption
											{...option}
											onOptionClick={onOptionClick}
											key={optionKey}
											multiselect={props.multiselect}
											isOnlyParent={props.onlyParent}
											selectedOptions={props.value}
											onHide={() => {
												setIsDropdownOpen(false)
											}}
										/>
									)
								})}
							</>
						)}
					</div>
				</Popover>
			)}
		</>
	)
}
