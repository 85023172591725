import { getCalendars } from '@redux/reducers/calendar-reducer'
import { CurrentSessionSliceProps } from '@redux/reducers/current-session-reducer'
import { getHubs } from '@redux/reducers/hubs-reducer'
import { UIState } from '@redux/reducers/ui-reducer'
import { getUserProfilePhoto } from '@redux/reducers/user-profile-photo-reducer'
import { getUserProps } from '@redux/reducers/user-reducer'
import { RootState, store } from '@redux/store'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { ToastService } from 'src/services/toast/toast.service'

import { AnalyticsService } from '../../services/analytics/analytics.service'
import { Domain } from '../../services/domain/domain.types'
import { EndUserProps } from '../../services/user/user.types'
import { PrimaryRouteChrome, RouteSection } from './primary-route-chrome'

interface ConnectedProps {
	currentSession: CurrentSessionSliceProps
	ui: UIState
	user: EndUserProps | null
	domain: Domain.Properties
	redirectIfNotLoggedIn?: boolean
}

/**  Wraps a route with the standard navigation used throughout the application (e.g. header, sidebar).
 * Includes logic that attempts to log the user in */
function PrimaryRootRoutePrototype(
	props: {
		routeSection: RouteSection
	} & ConnectedProps,
) {
	const navigate = useNavigate()
	const token = props.currentSession.token

	/** Attempt to log the user in */
	useEffect(() => {
		if (props.user) {
			console.info(`User is already logged in`)
			console.log('props.user: ', props.user.roles)
			if (props.user.roles.some((role) => role.roleType === 'PENDING_PAYMENT')) {
				console.info(`User role includes PENDING_PAYMENT`)
				if (!props.user.enabled) {
					navigate('/rejoin')
				} else {
					navigate('/registration')
				}
			} else {
				loadDataForAuthenticatedUser(props.user)
				identifyUserForAnalytics(props.user)
			}
		} else if (!token) {
			console.info(`User is not logged in`)
			if (typeof props.redirectIfNotLoggedIn === 'boolean' && props.redirectIfNotLoggedIn === false) {
				return
			}

			ToastService.create({ type: 'ERROR', body: `Please log in to access this page` })
			localStorage.setItem('redirectAfterLogin', window.location.pathname) // Save current URL for redirect after logging in
			navigate('/')
		} else {
			const userId = token.id
			store
				.dispatch(getUserProps({ userId }))
				.then((res) => {
					const payload = res.payload as EndUserProps
					if (payload.roles.some((role) => role.roleType === 'PENDING_PAYMENT')) {
						if (!payload.enabled) {
							navigate('/rejoin')
						} else {
							navigate('/registration')
						}
					} else {
						console.info(`User authentication successful`)
						loadDataForAuthenticatedUser(payload)
						identifyUserForAnalytics(payload)
					}
				})
				.catch((err) => {
					console.info(`User authentication failed`)
					ToastService.create({ type: 'ERROR', body: `Please log in to access this page` })
					navigate('/')
				})
		}
	}, [])

	function loadDataForAuthenticatedUser(user: EndUserProps): void {
		store.dispatch(getHubs())
		store.dispatch(getCalendars())

		if (user.hasProfilePicture) {
			store.dispatch(getUserProfilePhoto({ userId: user.endUserId }))
		}
	}

	function identifyUserForAnalytics(user: EndUserProps): void {
		const domainId = props.domain.domainId

		AnalyticsService().identifyUser(domainId, {
			email: user.email,
			firstName: user.firstName,
			lastName: user.lastName,
			endUserId: user.endUserId,
		})
	}

	return <PrimaryRouteChrome routeSection={props.routeSection} />
}

function mapStateToProps(state: RootState) {
	return {
		ui: state.ui,
		user: state.user,
		domain: state.domain,
		currentSession: state.currentSession,
	}
}

export const PrimaryRootRoute = connect(mapStateToProps)(PrimaryRootRoutePrototype)
