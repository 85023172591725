import './paper.scss'

import { SharedElementService } from '@components/shared/shared-element.service'
import React, { ForwardedRef } from 'react'

import { PaperTypes } from './paper.types'

/** A surface in which to render other components on top of */
export const Paper = React.forwardRef((props: PaperTypes.Component, externalRef: ForwardedRef<HTMLDivElement>) => {
	function getClass(): string {
		let classes: string[] = []
		let classString = ''

		classes.push(`paper`)

		if (props.interactive) {
			classes.push(`is-interactive`)
		}

		if (props.selected) {
			classes.push(`is-selected`)
		}

		if (props.elevate) {
			classes.push(`is-elevated`)
		}

		switch (props.bgColor) {
			case 'primary':
				classes.push(`bg-color__primary`)
				break
			case 'white':
				classes.push(`bg-color__white`)
				break
		}

		if (props.padding) {
			classes = [...classes, ...SharedElementService.getPaddingClass({ padding: props.padding })]
		}

		if (props.margins) {
			classes = [
				...classes,
				...SharedElementService.getMarginClass({ margins: props.margins, marginSize: props.marginSize }),
			]
		}

		if (props.flex) {
			classes = [
				...classes,
				...SharedElementService.getFlexClasses({
					flex: props.flex,
					gap: props.gap,
					wrap: props.wrap,
					justifyContent: props.justifyContent,
					alignItems: props.alignItems,
				}),
			]
		}

		if (props.className) {
			classes.push(props.className)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	function getStyle(): React.CSSProperties {
		const style: React.CSSProperties = { ...props.style }

		return style
	}

	return (
		<div
			tabIndex={props.interactive ? 0 : undefined}
			className={getClass()}
			style={getStyle()}
			onClick={(evt) => {
				if (props.onClick) {
					props.onClick()
				}
			}}
			onMouseEnter={() => {
				if (props.onMouseEnter) {
					props.onMouseEnter()
				}
			}}
			onMouseLeave={() => {
				if (props.onMouseLeave) {
					props.onMouseLeave()
				}
			}}
			onKeyDown={(evt) => {
				if (evt.key === 'Enter' && props.onClick) {
					props.onClick()
				}
			}}
			ref={externalRef}
		>
			{props.children}
		</div>
	)
})
