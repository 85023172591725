import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'

export function AdvertisingAccount__Route__Billing() {
	return (
		<>
			<Typography type="h2" margins={['bottom']}>
				Billing
			</Typography>
			<Paper
				bgColor="primary"
				padding={['all']}
				marginSize="section"
				margins={['bottom']}
				className="col-xs-12"
				style={{ maxWidth: `800px` }}
			>
				[STRIPE INTEGRATION GOES HERE]
			</Paper>
		</>
	)
}
