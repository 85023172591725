import { MultiColumnTableTypes } from '@components/multi-column-table/multi-column-table.types'
import { MultiColumnTable } from '@components/multi-column-table/multi-column-table__wrapper'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { RoutingService } from 'src/services/routing/routing.service'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'

import { TransactionMgmtTypes } from '../../../services/transaction-mgmt/transaction-mgmt.types'
import { TransactionMgmt__Task__SideSheet } from '../side-sheets/task/task.side-sheet'
import { TransactionMgmt__Tasks__SearchFilters } from './search-filters'
import { TransactionMgmt__Tasks__Types } from './tasks.types'

export function TransactionMgmt__Tasks__Route() {
	const navigate = useNavigate()

	const [searchFilters, setSearchFilters] = useState<TransactionMgmt__Tasks__Types.SearchFilters>({
		searchString: '',
		includeCloseDate: false,
		closeDateEnd: null,
		closeDateStart: null,
		includeDateAdded: false,
		dateAddedEnd: null,
		dateAddedStart: null,
		type: [],
	})
	const [tasks, setTasks] = useState<TransactionMgmtTypes.TaskTypes.Task[] | null>(null)
	const [selectedTask, setSelectedTask] = useState<TransactionMgmtTypes.TaskTypes.Task | null>(null)
	const [showTaskSideSheet, setShowTaskSideSheet] = useState(false)

	useEffect(() => {
		TransactionManagementAPI.findTasks().then((res) => {
			setTasks(res.data)
		})
	}, [])

	function getTableColumns(): MultiColumnTableTypes.Column<TransactionMgmtTypes.TaskTypes.Task>[] {
		const columns: MultiColumnTableTypes.Column<TransactionMgmtTypes.TaskTypes.Task>[] = []

		columns.push({
			label: 'Name',
			columnId: 'name',
			value: 'name',
			displayIndex: 0,
			visible: true,
			width: 'fill',
		})

		return columns
	}

	return (
		<>
			<RouteWrapperV2>
				<RouteSubnavigation
					isCustom={true}
					title={`Tasks`}
					backButton={{
						label: 'Transaction Management',
						onClick: () => {
							navigate(`${RoutingService.transactionMgmtRoutePath}`)
						},
					}}
				>
					<TransactionMgmt__Tasks__SearchFilters
						searchFilters={searchFilters}
						setSearchFilters={setSearchFilters}
					/>
				</RouteSubnavigation>

				<RouteBodyV2 className="flex flex-column flex-alignItems-start">
					<MultiColumnTable<TransactionMgmtTypes.TaskTypes.Task>
						columns={getTableColumns()}
						idKey={`taskId`}
						className="col-xs-12"
						items={tasks}
						isConfigurable={false}
						onSelect={(task) => {
							setSelectedTask(task[0])
							setShowTaskSideSheet(true)
						}}
						onDoubleClick={(transaction) => {
							/** empty */
						}}
						selectBehavior={'single'}
						selectedItems={[]}
						sortBehavior={{
							sortMethod: 'column-value',
							columnId: 'name',
						}}
						tableId={''}
					/>
				</RouteBodyV2>
			</RouteWrapperV2>
			{showTaskSideSheet && selectedTask && (
				<TransactionMgmt__Task__SideSheet
					mode={'EDITOR'}
					task={selectedTask}
					showParentTransaction={true}
					onClose={() => {
						setShowTaskSideSheet(false)
					}}
					onUpdate={(savedTask) => {
						setShowTaskSideSheet(false)
					}}
					onDelete={(taskId) => {
						setShowTaskSideSheet(false)
					}}
				/>
			)}
		</>
	)
}
