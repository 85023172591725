import { Button } from '@components/button/button'
import { Typography } from '@components/text/text'
import { useEffect, useState } from 'react'
import { StripeCouponAPI } from 'src/services/stripe/stripe.coupon.api'
import { StripeTypes } from 'src/services/stripe/stripe.types'

import { Stripe__Coupon__SideSheet } from './coupon__side-sheet'
// import { EmptyStatePlaceholder } from '@components/empty-state-placeholder/empty-state-placeholder'

export function Stripe__Route__Coupons() {
	const [coupons, setCoupons] = useState<StripeTypes.FetchCouponsResponse>()
	const [selectedCoupon, setSelectedCoupon] = useState<StripeTypes.CouponData | 'NEW' | null>()
	const [showCouponSideSheet, setShowCouponSideSheet] = useState<boolean>(false)

	/** ============================= */
	/** Methods */

	function fetchCoupons() {
		StripeCouponAPI.couponSearch().then((res) => {
			setCoupons(res)
		})
	}

	useEffect(() => {
		fetchCoupons()
	}, [])

	useEffect(() => {
		setTimeout(function () {
			fetchCoupons()
		}, 300)
	}, [showCouponSideSheet])

	/** ============================= */
	/** Render Component */

	return (
		<>
			<div className="flex flex-justifyContent-spaceBetween flex-alignItems-start col-xs-12 mb-20">
				<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center mb-10">
					<Typography type="h1">Coupons</Typography>
				</div>
				<Button
					label="Create Coupon"
					variant="outlined"
					size="md"
					onClick={() => {
						setSelectedCoupon('NEW')
						setShowCouponSideSheet(true)
					}}
				/>
			</div>
			<div className="flex flex-column-md-down">
				<div className="flex flex-column flex-fillSpace mt-20-md-down">
					{coupons &&
						coupons.map((coupon) => (
							<div
								className="form__card"
								onClick={() => {
									setSelectedCoupon(coupon)
									setShowCouponSideSheet(true)
								}}
							>
								<strong>{coupon.name}</strong>
								<span>
									{coupon.amountOff && (
										<div>
											Terms: {'$' + (coupon.amountOff / 100).toFixed(2)} {coupon.duration}
										</div>
									)}
									{coupon.percentOff && (
										<div>
											Terms: {coupon.percentOff + '%'} {coupon.duration}
										</div>
									)}
								</span>
							</div>
						))}
				</div>
			</div>

			{selectedCoupon && showCouponSideSheet && (
				// eslint-disable-next-line react/jsx-pascal-case
				<Stripe__Coupon__SideSheet
					couponToEdit={selectedCoupon}
					onClose={() => {
						setShowCouponSideSheet(false)
					}}
					onSave={() => {
						setShowCouponSideSheet(false)
					}}
				/>
			)}
		</>
	)
}
