import { Typography } from '@components/text/text'
import { useAppDispatch } from '@redux/hooks'
import { currentSessionSlice, CurrentSessionSliceProps } from '@redux/reducers/current-session-reducer'
import { userSlice } from '@redux/reducers/user-reducer'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import { AddOnServiceList } from '../../routes/administration/user-profile/components/subscription-components/add-on-service-list'
import { Domain } from '../../services/domain/domain.types'
import { useUserAuthentication } from '../../services/hooks/use-user-authentication'
import { LoginCarouselNode } from '../../services/login-carousel/login-carousel.types'
import { DomainTheme } from '../../services/theme/theme.types'
import { TokenService } from '../../services/token/token.service'
import { UserAPI } from '../../services/user/user.api'
import { EndUserProps } from '../../services/user/user.types'
import { Utilities } from '../../services/utilities.service'
import { Button } from '../button/button'
import { Modal } from '../modal/modal'
import { ModalBody } from '../modal/modal-body'
import { ModalFooter } from '../modal/modal-footer'
import { ModalHeader } from '../modal/modal-header'
import { LoginCarouselText } from './login-carousel-text/login-carousel-text'
import { LoginRouteBackground } from './login-route-background/login-route-background'

interface LoginRouteWrapperProps {
	isLoginRoute: boolean
	children: React.ReactElement
	bodyClassName?: string
	currentUser?: EndUserProps | null
}

let updateSlideTimer: NodeJS.Timeout | undefined

interface ConnectedProps {
	currentSession: CurrentSessionSliceProps
	domain: Domain.Properties
	carousel: LoginCarouselNode[]
	theme: DomainTheme.Theme
}

export function LoginRouteWrapperPrototype(
	props: LoginRouteWrapperProps &
		ConnectedProps & {
			currentUser?: EndUserProps | null
		},
) {
	const [currentSlide, setCurrentSlide] = useState(0)
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const location = useLocation()

	const { userIsLoggedIn } = useUserAuthentication({ token: props.currentSession.token })

	useEffect(() => {
		setCurrentSlide(Utilities.generateRandomInt(0, props.carousel.length - 1))
	}, [])

	/** Update Slide on interval */
	useEffect(() => {
		clearInterval(updateSlideTimer)

		let nextSlideIndex = currentSlide + 1

		updateSlideTimer = setInterval(() => {
			if (nextSlideIndex >= props.carousel.length) {
				nextSlideIndex = 0
			}
			setCurrentSlide(nextSlideIndex)
		}, 20000)

		return () => {
			clearInterval(updateSlideTimer)
		}
	}, [currentSlide])

	const [backToLogin, setBackToLogin] = useState<'LOGIN' | null>(null)

	function dismissModal() {
		setBackToLogin(null)
	}

	const logoImage = props.theme.mode === 'LIGHT' ? props.theme.logo : props.theme.logoWhite
	const currentCarouselSlide: Partial<LoginCarouselNode> = props.carousel[currentSlide]
		? props.carousel[currentSlide]
		: { imageUrl: undefined, body: undefined }

	return (
		<div className="flex p-20 col-xs-12 height__fill flex-justifyContent-center-sm-down">
			<div className="p-40 p-10-md-down flex flex-column flex-alignItems-start login__body">
				{props.isLoginRoute && <img src={logoImage} className="login__logo" alt="logo" />}
				{!props.isLoginRoute && (
					<Button
						margins={['top']}
						variant="text"
						label="Back to login"
						icon="arrow-circle-left"
						iconPosition="left"
						iconSize="2x"
						size={'md'}
						onClick={() => {
							if (location.pathname === '/forgot-password') {
								navigate(`/`)
							} else {
								setBackToLogin('LOGIN')
							}
						}}
					/>
				)}

				{backToLogin === 'LOGIN' && (
					<Modal
						onClose={dismissModal}
						maxWidth={800} // Choose appropriate size
						maxHeight={800} // Choose appropriate size
						fixedHeight={false} // Choose appropriate value
					>
						<ModalHeader title="Are you sure you want to leave?" />
						<ModalBody>
							<div>By going back to login, you will lose your current session.</div>
						</ModalBody>
						<ModalFooter>
							<Button
								variant="outlined"
								size="md"
								primary={false}
								label="Cancel"
								onClick={dismissModal}
								margins={['right']}
							/>

							<Button
								variant="contained"
								size="md"
								label="Back to login"
								onClick={() => {
									if (userIsLoggedIn) {
										UserAPI.logout().then(() => {
											dispatch(userSlice.actions.logout())
											TokenService().clear()
											dispatch(currentSessionSlice.actions.clearToken())
											navigate(`/`)
										})
									}
									navigate(`/`)
								}}
							/>
						</ModalFooter>
					</Modal>
				)}

				<div className={`${props.bodyClassName ? props.bodyClassName : ''} login-child-route-starts-here`}>
					{props.children}
				</div>
			</div>
			{location.pathname === '/create-account-success' ? (
				<div className="col-12 flex flex-alignItems-center show-md">
					<div className="col-10">
						<div className="p-10">
							<Typography type="h3" margins={['bottom']}>
								Level Up Your Experience!
							</Typography>
							<p className="mb-20">
								Don't miss out on our exclusive services designed to elevate your time in the Sphere.
								Take your journey to the next level by subscribing to these premium features:
							</p>
						</div>
						<AddOnServiceList serviceIds={['KV_CORE', 'HOME_SEARCH', 'DOTLOOP', 'BACK_AT_YOU']} />
					</div>
				</div>
			) : (
				<LoginRouteBackground
					outerClassName="flex-fillSpace show-lg"
					innerClassName="p-40 flex flex-column flex-alignItems-center"
					image={currentCarouselSlide.imageUrl}
				>
					<LoginCarouselText
						className="flex-fillSpace flex flex-column flex-justifyContent-center"
						text={currentCarouselSlide.body}
					/>
				</LoginRouteBackground>
			)}
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		domain: state.domain,
		theme: state.theme,
		carousel: state.loginCarousel,
		currentSession: state.currentSession,
	}
}

export const LoginRouteWrapper = connect(mapStateToProps)(LoginRouteWrapperPrototype)
