import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'

import { LicensedStateEnums } from '../../services/licensed-states/licenses-states.types'
import { EndUserProps } from '../../services/user/user.types'
import { AlabamaDepartmentContacts } from './alabama-department-contacts'
import { ColoradoDepartmentContacts } from './colorado-department-contacts'
import { FloridaDepartmentContacts } from './florida-department-contacts'
import { GeorgiaDepartmentContacts } from './georgia-department-contacts'
import { SouthCarolinaDepartmentContacts } from './south-carolina-department-contacts'
import { TexasDepartmentContacts } from './texas-department-contacts'

interface GetSupportProps {
	currentUser: EndUserProps | null
}

export function DepartmentContactsRoutePrototype({ currentUser }: GetSupportProps) {
	function getAlabamaInfo() {
		return (
			<>
				<AlabamaDepartmentContacts />
			</>
		)
	}

	function getColoradoInfo() {
		return (
			<>
				<ColoradoDepartmentContacts />
			</>
		)
	}

	function getFloridaInfo() {
		return (
			<>
				<FloridaDepartmentContacts />
			</>
		)
	}

	function getGeorgiaInfo() {
		return (
			<>
				<GeorgiaDepartmentContacts />
			</>
		)
	}

	function getSouthCarolinaInfo() {
		return (
			<>
				<SouthCarolinaDepartmentContacts />
			</>
		)
	}

	function getTexasInfo() {
		return (
			<>
				<TexasDepartmentContacts />
			</>
		)
	}

	function getDefaultInfo() {
		return (
			<>
				<div className="flex flex-column">
					<a
						href="mailto:support@lokationre.com"
						target="_blank"
						className="button outlined sm primary mb-10"
						rel="noreferrer"
					>
						<FontAwesomeIcon icon={faEnvelope} className="mr-10" /> support@lokationre.com
					</a>
				</div>
			</>
		)
	}

	function getStateComponent(abbreviation: LicensedStateEnums) {
		switch (abbreviation) {
			case 'AL':
				return getAlabamaInfo
			case 'CO':
				return getColoradoInfo
			case 'FL':
				return getFloridaInfo
			case 'GA':
				return getGeorgiaInfo
			case 'SC':
				return getSouthCarolinaInfo
			case 'TX':
				return getTexasInfo
			default:
				return getDefaultInfo
		}
	}

	return (
		<>
			<RouteWrapperV2>
				<RouteBodyV2>
					<Typography type="h1" margins={['bottom']}>
						Department Contacts
					</Typography>
					{currentUser && currentUser.licenseInformation.length > 0 ? (
						currentUser.licenseInformation.map((license) => {
							const stateAbbreviation = license.licensedState.abbreviation
							const stateName = license.licensedState.stateName
							const stateComponent = getStateComponent(stateAbbreviation)

							return (
								<div key={license.licenseInformationId} className="mb-20">
									<div className="mt-10 px-10">
										<>
											<Typography type="h3" margins={['bottom']}>
												{stateName}
											</Typography>
											{stateComponent && stateComponent()}
										</>
									</div>
								</div>
							)
						})
					) : (
						<div className="mt-10 px-10">{getDefaultInfo()}</div>
					)}
				</RouteBodyV2>
			</RouteWrapperV2>
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const DepartmentContactsRoute = connect(mapStateToProps)(DepartmentContactsRoutePrototype)
