import './services/style/style.scss'
import 'react-toastify/dist/ReactToastify.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { store } from '@redux/store'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router'
import { ToastContainer } from 'react-toastify'

import { DomainInitializationSteps } from './domain-initialization'
import reportWebVitals from './reportWebVitals'
import { GlobalHistory } from './routes/history-component'
import { ModalsWrapper } from './routes/modals-wrapper'
import { ApplicationRoutes } from './routes/root.routing'
import { BatchProcessor } from './services/batch-processor/batch-processor'
import { DomainThemeService } from './services/theme/theme.service'

/** @ts-ignore */
library.add(far, fat, fas, fab)

const root = document.getElementById('root')

document.addEventListener('DOMContentLoaded', () => {
	if (root) {
		createRoot(root).render(
			<>
				<ToastContainer position="top-center" />
				<Provider store={store}>
					<BrowserRouter>
						<ApplicationRoutes />
						<GlobalHistory />
						<ModalsWrapper />
					</BrowserRouter>
				</Provider>
			</>,
		)
	}

	const domainInit = BatchProcessor()
	const afterDomainInit = BatchProcessor()

	/** Actions that must occur before page can load */
	domainInit.registerStep(DomainInitializationSteps.loadCachedTheme())
	domainInit.registerStep(DomainInitializationSteps.getDomainTheme())
	domainInit.registerStep(DomainInitializationSteps.getDomainProps())
	domainInit.registerStep(DomainInitializationSteps.loadFonts())
	domainInit.registerStep(DomainInitializationSteps.getLoginCarousel())

	/** Actions can occur after the first page is rendered */
	afterDomainInit.registerStep(DomainInitializationSteps.getTags())
	afterDomainInit.registerStep(DomainInitializationSteps.getSpecialties())
	afterDomainInit.registerStep(DomainInitializationSteps.getLanguageSpecialties())
	afterDomainInit.registerStep(DomainInitializationSteps.getLicensedStates())
	afterDomainInit.registerStep(DomainInitializationSteps.getLicensedRegions())
	afterDomainInit.registerStep(DomainInitializationSteps.initializeAnalytics())

	domainInit.runAllSteps().then(() => {
		/** Set domain properties to browser tab */
		document.title = store.getState().domain.label

		/** Set up theme */
		const completeThemeProps = DomainThemeService.generateThemeProps(store.getState().theme)
		DomainThemeService.writeThemePropsToCSS(completeThemeProps)

		/** Get other data that will be needed */
		afterDomainInit.runAllSteps()
	})
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
