import './news-feed__story.scss'

import { Typography } from '@components/text/text'

import { NewsFeed } from '../../services/news-feed/news-feed.types'

export function NewsFeedStory(props: { story: NewsFeed.Story; className?: string }) {
	return (
		<div className={`news-feed__story ${props.className}`}>
			<Typography type="h1" margins={['bottom']}>
				{props.story.title}
			</Typography>
			<div dangerouslySetInnerHTML={{ __html: props.story.description }}></div>
		</div>
	)
}
