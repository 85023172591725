import { Button } from '@components/button/button'
import { Checkbox } from '@components/checkbox/checkbox'
import { Dropdown } from '@components/dropdown/dropdown'
import { Paper } from '@components/paper/paper'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Switch } from '@components/switch/switch'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { TransactionAdmin__ChecklistEditor__ChildNotifications } from './child-notifications'
import { TransactionAdmin__ChecklistEditor__ChildTasks } from './child-tasks'

export function TransactionAdmin__Route__ChecklistEditor() {
	const [data, setData] = useState<TransactionMgmtTypes.ChecklistTypes.Checklist | null>(null)
	const params = useParams<{ checklistId?: string }>()
	const [modifiedChecklist, setModifiedChecklist] = useState<TransactionMgmtTypes.ChecklistTypes.Checklist | null>(
		data,
	)

	useEffect(() => {
		if (!params.checklistId) {
			return
		}
		TransactionManagementAPI.getChecklistById(Number(params.checklistId)).then((res) => {
			if (res.data) {
				setData(res.data)
				setModifiedChecklist(res.data)
			}
		})
	}, [])

	function updateProps(updatedProps: Partial<TransactionMgmtTypes.ChecklistTypes.Checklist>): void {
		if (!modifiedChecklist) {
			return
		}
		const updatedChecklist: TransactionMgmtTypes.ChecklistTypes.Checklist = {
			...modifiedChecklist,
			...updatedProps,
		}
		setModifiedChecklist(updatedChecklist)
	}

	if (!data || !modifiedChecklist) {
		return (
			<RouteWrapperV2>
				<RouteBodyV2 className="flex flex-column flex-alignItems-start">ERROR</RouteBodyV2>
			</RouteWrapperV2>
		)
	}

	return (
		<RouteWrapperV2>
			<RouteBodyV2>
				<div style={{ maxWidth: '1200px' }}>
					<Button
						to="/transaction-management/admin/checklists"
						label="Checklists"
						icon={'angle-left'}
						iconPosition="left"
						variant="text"
						size="md"
					/>
					<Typography type="h2" margins={['bottom']} marginSize="section">
						Checklist Setup
					</Typography>

					<Typography type="h6" margins={['bottom']} color="secondary">
						General
					</Typography>
					<Paper
						elevate={true}
						bgColor="white"
						padding={['all']}
						marginSize="section"
						margins={['bottom']}
						className="col-xs-12"
					>
						<TextInput
							label="Name"
							width={'300px'}
							margins={['bottom']}
							dataType="text"
							value={modifiedChecklist.name}
							onChange={(updatedValue) => {
								updateProps({ name: updatedValue })
							}}
						/>
						<TextInput
							label="Description"
							width={'600px'}
							margins={['bottom']}
							rows={3}
							dataType="text"
							value={modifiedChecklist.description}
							onChange={(updatedValue) => {
								updateProps({ description: updatedValue })
							}}
						/>
						<Dropdown<TransactionMgmtTypes.Shared.Visibility>
							label="Visibility"
							value={[modifiedChecklist.visibility]}
							width={'300px'}
							options={[
								{
									label: 'Not Published',
									value: 'NOT_PUBLISHED',
								},
								{
									label: 'Published',
									value: 'PUBLISHED',
								},
							]}
							onSelect={(selectedOptions) => {
								updateProps({ visibility: selectedOptions[0] })
							}}
						/>
					</Paper>
					<Typography type="h6" margins={['bottom']} color="secondary">
						Child Tasks
					</Typography>
					<Paper
						elevate={true}
						bgColor="white"
						padding={['all']}
						marginSize="section"
						margins={['bottom']}
						className="col-xs-12 flex flex-column flex-alignItems-start"
						style={{ gap: '10px' }}
					>
						<TransactionAdmin__ChecklistEditor__ChildTasks taskIds={modifiedChecklist.childTaskIds} />
					</Paper>
					<Typography type="h6" margins={['bottom']} color="secondary">
						Child Notifications
					</Typography>
					<Paper
						elevate={true}
						bgColor="white"
						padding={['all']}
						marginSize="section"
						margins={['bottom']}
						className="col-xs-12 flex flex-column flex-alignItems-start"
						style={{ gap: '10px' }}
					>
						<TransactionAdmin__ChecklistEditor__ChildNotifications
							notificationIds={modifiedChecklist.childNotificationIds}
						/>
					</Paper>
					<Typography type="h6" margins={['bottom']} color="secondary">
						Automation
					</Typography>
					<Paper
						elevate={true}
						bgColor="white"
						padding={['all']}
						marginSize="section"
						margins={['bottom']}
						className="col-xs-12"
					>
						<div className="flex flex-alignItems-center">
							<Switch
								checked={modifiedChecklist.addToTransactionIfConditionsMet}
								onChange={(newState) => {
									updateProps({ addToTransactionIfConditionsMet: newState })
								}}
								className="mr-10"
							/>
							Automatically add this checklist to future transactions if conditions are met
						</div>
						{modifiedChecklist.addToTransactionIfConditionsMet && (
							<>
								<Typography type="strong">Status is:</Typography>
								<Checkbox
									checked={modifiedChecklist.addToTransactionStatus.includes('ACTIVE')}
									className="mb-5"
									onChange={(newState) => {
										const updatedStatus: TransactionMgmtTypes.TransactionTypes.Status[] =
											modifiedChecklist.addToTransactionStatus
										if (newState) {
											updatedStatus.push('ACTIVE')
										} else {
											updatedStatus.filter((status) => status === 'ACTIVE')
										}
										updateProps({ addToTransactionStatus: updatedStatus })
									}}
								>
									Active
								</Checkbox>
								<Checkbox
									checked={modifiedChecklist.addToTransactionStatus.includes('INACTIVE')}
									className="mb-5"
									onChange={(newState) => {
										const updatedStatus: TransactionMgmtTypes.TransactionTypes.Status[] =
											modifiedChecklist.addToTransactionStatus
										if (newState) {
											updatedStatus.push('INACTIVE')
										} else {
											updatedStatus.filter((status) => status === 'INACTIVE')
										}
										updateProps({ addToTransactionStatus: updatedStatus })
									}}
								>
									Inactive
								</Checkbox>
								<Typography type="strong" margins={['top']}>
									Side is:
								</Typography>
								<Checkbox
									checked={modifiedChecklist.addToTransactionSide.includes('BUYING')}
									className="mb-5"
									onChange={(newState) => {
										const updatedStatus: TransactionMgmtTypes.TransactionTypes.Side[] =
											modifiedChecklist.addToTransactionSide
										if (newState) {
											updatedStatus.push('BUYING')
										} else {
											updatedStatus.filter((status) => status === 'BUYING')
										}
										updateProps({ addToTransactionSide: updatedStatus })
									}}
								>
									Buying
								</Checkbox>
								<Checkbox
									checked={modifiedChecklist.addToTransactionSide.includes('SELLING')}
									onChange={(newState) => {
										const updatedStatus: TransactionMgmtTypes.TransactionTypes.Side[] =
											modifiedChecklist.addToTransactionSide
										if (newState) {
											updatedStatus.push('SELLING')
										} else {
											updatedStatus.filter((status) => status === 'SELLING')
										}
										updateProps({ addToTransactionSide: updatedStatus })
									}}
								>
									Selling
								</Checkbox>
								<Typography type="strong" margins={['top']}>
									Type is:
								</Typography>
								{TransactionManagementService.getAllTransactionTypes().map((type) => {
									return (
										<Checkbox
											checked={modifiedChecklist.addToTransactionType.includes(type.value)}
											className="mb-5"
											onChange={(newState) => {
												const updatedStatus: TransactionMgmtTypes.TransactionTypes.Type[] =
													modifiedChecklist.addToTransactionType
												if (newState) {
													updatedStatus.push(type.value)
												} else {
													updatedStatus.filter((status) => status === type.value)
												}
												updateProps({ addToTransactionType: updatedStatus })
											}}
										>
											{type.label}
										</Checkbox>
									)
								})}
							</>
						)}
					</Paper>
				</div>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}
