import { SharedElementService } from '@components/shared/shared-element.service'

import { CollapsibleContentTypes } from './collapsible-content.types'

export namespace CollapsibleContentService {
	export function getWrapperClass(props: { state: CollapsibleContentTypes.State }): string {
		let classes: string[] = []

		classes.push(`collapsible-content__wrapper`)

		if (props.state === 'OPEN') {
			classes.push(`open`)
		} else {
			classes.push(`closed`)
		}

		return classes.join(' ')
	}

	export function getHeaderClass(props: Pick<CollapsibleContentTypes.Component, 'headerPadding'>): string {
		let classes: string[] = []

		classes.push(`collapsible-content__header`)

		if (props.headerPadding && props.headerPadding.length > 0) {
			classes = [...classes, ...SharedElementService.getPaddingClass({ padding: props.headerPadding })]
		} else {
			classes.push(`p-5`)
		}

		return classes.join(' ')
	}
}
