import { MlsBoardDropdown } from '@components/mls-board-dropdown-menu/mls-board-dropdown-menu'
import { Paper } from '@components/paper/paper'
import { StateSelectorDropdown } from '@components/state-selector-dropdown/state-selector-dropdown'
import { TextInput } from '@components/text-input/text-input'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'

import { LicensedState } from '../../../services/licensed-states/licenses-states.types'
import { MLSBoard } from '../../../services/mls-board/mls-board.types'

interface OptionEditorCardProps {
	mlsBoard: MLSBoard.BoardOption
	onChange: (newValue: MLSBoard.BoardOption) => void
	onDelete: () => void
}

function MLSBoardOptionPrototype(props: OptionEditorCardProps & { licensedStates: LicensedState[] }) {
	function updateOption(key: keyof MLSBoard.BoardOption, value: unknown): void {
		const updatedOption: MLSBoard.BoardOption = { ...props.mlsBoard, [key]: value }
		props.onChange(updatedOption)
	}

	return (
		<Paper bgColor="primary" margins={['bottom']} className="flex flex-alignItems-center">
			<div className="col-xs-12">
				<TextInput
					dataType="text"
					value={props.mlsBoard.displayValue}
					width={'100%'}
					onChange={(newValue) => {
						updateOption('displayValue', newValue)
					}}
				/>
			</div>
			<StateSelectorDropdown
				style={{ marginLeft: '10px', flexShrink: '0' }}
				width={200}
				multiselect={false}
				selectedStates={props.mlsBoard.licensedState ? [props.mlsBoard.licensedState] : []}
				options={props.licensedStates}
				onSelect={(selectedOptions) => {
					if (selectedOptions.length > 0) {
						updateOption('licensedState', selectedOptions[0])
					}
				}}
			/>
			<div className="ml-10 mr-10">
				<MlsBoardDropdown
					mlsBoardId={props.mlsBoard.mlsBoardId}
					mlsBoardName={props.mlsBoard.displayValue}
					onDelete={props.onDelete}
				/>
			</div>
		</Paper>
	)
}

function mapStateToProps(state: RootState, ownProps: OptionEditorCardProps) {
	return {
		licensedStates: state.licensedStates,
	}
}

export const MLSBoardOption = connect(mapStateToProps)(MLSBoardOptionPrototype)
