import { Button } from '@components/button/button'
import { Typography } from '@components/text/text'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'

import { StripeAPI } from '../../../../services/stripe/stripe.api'
import { StripePublicKey } from '../../../../services/stripe/stripeConfig'
import { UserAPI } from '../../../../services/user/user.api'
import { UserService } from '../../../../services/user/user.service'
import { EndUserProps } from '../../../../services/user/user.types'
import { useUserProfile } from '../state/user-profile__state'
import { UserProfileService } from '../user-profile.service'
import { StripeAddPaymentMethodWrapper } from './billing-components/stripe-add-payment-method-wrapper'
import StripeInvoices from './billing-components/stripe-invoices'
import StripePaymentMethods from './billing-components/stripe-payment-methods'

const stripePromise = loadStripe(StripePublicKey)

interface BillingComponentProps {
	userToEdit: EndUserProps
	currentUser: EndUserProps | null
	fetchUserToEdit?: boolean
}

function BillingComponent(props: BillingComponentProps) {
	const userProfileState = useUserProfile()

	const mergedUserProps = UserProfileService().applyModifiedStateToOriginalProps(
		userProfileState.modifiedProps,
		props.userToEdit,
	)

	const canUserEdit = props.currentUser ? UserService.isUserAdmin(props.currentUser) : false

	const [isAddPaymentButtonVisible, setIsAddPaymentButtonVisible] = useState(true)
	const [paymentMethodAdded, setPaymentMethodAdded] = useState(false)
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<'NEW' | null>(null)

	const [customerCreated, setCustomerCreated] = useState(false)
	const [customerId, setCustomerId] = useState('')

	const handlePaymentMethodAddition = () => {
		// If the payment method addition is successful
		setIsAddPaymentButtonVisible(true)
		setPaymentMethodAdded(true)
	}

	async function createStripeCustomer() {
		try {
			await StripeAPI.createCustomerStripeInformation(mergedUserProps.endUserId)
		} catch (error) {
			console.error(error)
		} finally {
			setCustomerCreated(true)
		}
	}

	useEffect(() => {
		UserAPI.getUserDetails(mergedUserProps.endUserId).then((res) => {
			if (res.data.customerId) {
				setCustomerId(res.data.customerId)
			}
		})
	}, [customerCreated])

	return (
		<div>
			<>
				<Typography type="h2" margins={['bottom']}>
					Billing
				</Typography>
				{!canUserEdit ? null : mergedUserProps.customerId || customerId ? (
					<>
						<div style={{ maxWidth: `800px` }}>
							As an administrator, you are able to manage{' '}
							{mergedUserProps.firstName + ' ' + mergedUserProps.lastName}'s subscriptions and payment
							methods directly in stripe. Click below to access their account.
						</div>
						<Button
							margins={['top', 'bottom']}
							variant="outlined"
							label="Manage Customer in Stripe"
							size={'md'}
							onClick={() => {
								const idToUse = mergedUserProps.customerId ? mergedUserProps.customerId : customerId
								window.open(`https://dashboard.stripe.com/customers/${idToUse}`, '_blank')
							}}
						/>
					</>
				) : (
					<div className="mb-20">
						<div style={{ maxWidth: `800px` }}>
							{mergedUserProps.firstName + ' ' + mergedUserProps.lastName} has not logged in and has no
							stripe information. Do you wish to create them as a customer in Stripe?
						</div>
						<Button
							margins={['top', 'bottom']}
							variant="outlined"
							label="Create Customer"
							size={'md'}
							onClick={() => {
								createStripeCustomer()
							}}
						/>
					</div>
				)}

				<div style={{ maxWidth: `800px` }} className="mb-40">
					<Elements stripe={stripePromise}>
						<StripePaymentMethods
							setPaymentMethodAdded={setPaymentMethodAdded}
							paymentMethodAdded={paymentMethodAdded}
							userToEdit={props.userToEdit}
							currentUser={props.currentUser}
						/>
						{isAddPaymentButtonVisible && (
							<Button
								margins={['top']}
								variant="outlined"
								label="Add payment method"
								size={'md'}
								onClick={() => {
									setSelectedPaymentMethod('NEW')
								}}
							/>
						)}
						{selectedPaymentMethod === 'NEW' && (
							<StripeAddPaymentMethodWrapper
								dismissModal={() => setSelectedPaymentMethod(null)}
								onPaymentMethodAddition={handlePaymentMethodAddition}
								userToEdit={props.userToEdit}
							/>
						)}

						<StripeInvoices userToEdit={props.userToEdit} currentUser={props.currentUser} />
					</Elements>
				</div>
			</>
		</div>
	)
}

export default BillingComponent
