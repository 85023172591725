import {
	currentSessionSlice,
	CurrentSessionSliceProps,
	refreshTokenExpiration,
} from '@redux/reducers/current-session-reducer'
import { RootState, store } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { globalNavigate } from 'src/routes/history-component'
import { useInterval } from 'usehooks-ts'

import { ToastService } from '../toast/toast.service'
import { TokenService } from '../token/token.service'
import { EndUserProps } from '../user/user.types'

const warningSeconds = 330 // Number of seconds before timeout in which the application should alert users

interface ConnectedProps {
	currentSession: CurrentSessionSliceProps
	user: EndUserProps | null
}

/** Feature to track whether a users token is about to time out, and warn them */
function TokenTimeoutNotificationServicePrototype(props: ConnectedProps) {
	const [expirationMs, setExpirationMs] = useState<number | null>(null)
	const [toastId, setToastId] = useState<any>(null)

	/** Reset the expiration timestamp whenever the auth token is updated */
	useEffect(() => {
		const parsedJwt = props.currentSession.token
		if (typeof parsedJwt === 'object' && parsedJwt && 'exp' in parsedJwt && typeof parsedJwt.exp === 'number') {
			const expirationInMs = parsedJwt.exp * 1000
			setExpirationMs(expirationInMs)
			checkExpirationAndShowToast(expirationInMs)
		}
	}, [props.currentSession.token])

	/** Create interval to check expiration regularly */
	useInterval(() => {
		if (typeof expirationMs !== 'number') {
			return
		}
		checkExpirationAndShowToast(expirationMs)
	}, 30000)

	function checkExpirationAndShowToast(expirationInMs: number): void {
		if (expirationInMs === null) {
			return
		}

		const timeoutSeconds = secondsUntilTimeOut(expirationInMs)
		const timeoutMinutes = Math.floor(timeoutSeconds / 60)
		console.info(`Token times out in ${Math.floor(timeoutSeconds)} seconds`)

		if (timeoutSeconds > warningSeconds) {
			dismissToast()
			return
		}

		if (timeoutSeconds > 0 && timeoutSeconds < warningSeconds) {
			if (toastId) {
				ToastService.update({
					toastId,
					type: 'INFO',
					body: getToastBody(timeoutMinutes),
					buttons: [
						{
							variant: 'outlined',
							size: 'md',
							onClick: () => {
								return new Promise((resolve) => {
									if (!props.user) {
										return
									}
									store.dispatch(refreshTokenExpiration({ userId: props.user.endUserId }))
									dismissToast()
									resolve()
								})
							},
							label: `Extend`,
						},
					],
					options: {
						autoClose: false,
						closeButton: false,
						closeOnClick: false,
					},
				})
			} else {
				const toastInstance = ToastService.create({
					type: 'INFO',
					body: getToastBody(timeoutMinutes),
					buttons: [
						{
							variant: 'outlined',
							size: 'md',
							onClick: () => {
								return new Promise((resolve) => {
									if (!props.user) {
										return
									}
									store.dispatch(refreshTokenExpiration({ userId: props.user.endUserId }))
									dismissToast()
									resolve()
								})
							},
							label: `Extend`,
						},
					],
					options: {
						autoClose: false,
						closeButton: false,
						closeOnClick: false,
					},
				})
				setToastId(toastInstance.id as string)
			}
		}
		if (isNaN(timeoutSeconds) || timeoutSeconds < 40) {
			TokenService().clear()
			store.dispatch(currentSessionSlice.actions.clearToken())
			if (globalNavigate) {
				globalNavigate('/')
			}
			dismissToast()
		}
	}

	function dismissToast(): void {
		if (toastId) {
			toast.dismiss(toastId)
		}
		setToastId(null)
	}

	/** Get the number of seconds until application should log the user out */
	function secondsUntilTimeOut(expirationTimestamp: number): number {
		const currentTime = Date.now()
		return (expirationTimestamp - currentTime) / 1000
	}

	/** Generate body for toast message */
	function getToastBody(timeoutMinutes: number): string {
		return `Your session will expire in ${timeoutMinutes} ${
			timeoutMinutes === 1 ? 'minute' : 'minutes'
		}. Would you like to extend this time?`
	}

	return <></>
}

function mapStateToProps(state: RootState, ownProps: {}) {
	return {
		currentSession: state.currentSession,
		user: state.user,
	}
}

export const TokenTimeoutNotificationService = connect(mapStateToProps)(TokenTimeoutNotificationServicePrototype)
