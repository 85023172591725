import { RouteNavigationLinkTypes } from '@components/route-navigation-link/route-navigation-link.types'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { RoutingService } from 'src/services/routing/routing.service'
import { EndUserProps } from 'src/services/user/user.types'

function TransactionMgmt__AdminLanding__RoutePrototype(props: { userProfile: EndUserProps | null }) {
	const navigate = useNavigate()
	const location = useLocation()
	const currentRouteId = getCurrentRoute()

	/** ============================ */
	/** Methods */

	function getCurrentRoute(): string {
		const pathParts = location.pathname.split('/')
		const routeId = pathParts[pathParts.length - 1]
		return routeId
	}

	function getRoutes(): RouteNavigationLinkTypes.Component[] {
		const routes: RouteNavigationLinkTypes.Component[] = []

		routes.push({
			label: 'Locations',
			onClick: () => {
				navigate(`locations`)
			},
			isActive: currentRouteId === 'locations',
			id: `locations`,
		})

		routes.push({
			label: 'Checklists',
			onClick: () => {
				navigate(`checklists`)
			},
			isActive: currentRouteId === 'checklists',
			id: `checklists`,
		})

		routes.push({
			label: 'Notifications',
			onClick: () => {
				navigate(`notifications`)
			},
			isActive: currentRouteId === 'notifications',
			id: `notifications`,
		})

		routes.push({
			label: 'Tasks',
			onClick: () => {
				navigate(`tasks`)
			},
			isActive: currentRouteId === 'tasks',
			id: `tasks`,
		})

		return routes
	}

	return (
		<RouteWrapperV2>
			<RouteSubnavigation
				isCustom={false}
				title={`Administration`}
				backButton={{
					label: 'Transaction Management',
					onClick: () => {
						navigate(`${RoutingService.transactionMgmtRoutePath}`)
					},
				}}
				routes={getRoutes()}
			/>

			<RouteBodyV2 className="flex flex-column flex-alignItems-start">
				<Outlet />
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
	}
}

export const TransactionMgmt__AdminLanding__Route = connect(mapStateToProps)(
	TransactionMgmt__AdminLanding__RoutePrototype,
)
