import './quill.snow.css'
import './rich-text-editor.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'

import { Utilities } from '../../services/utilities.service'
import { ComponentImpl } from '../component.types'
import { RichTextEditorToolbar } from './rich-text-editor__toolbar'

interface RichTextEditorProps {
	className?: string
	value: string
	rows: number
	onChange: (updatedValue: string) => void
	placeholder?: string
	validation?: ComponentImpl.Validation<string>
	label?: string
	disabled?: boolean
}

/** Documentation: https://www.npmjs.com/package/react-quill */
export function RichTextEditor(props: RichTextEditorProps) {
	const [isUserInteracted, setIsUserInteracted] = useState(false)
	const [isValid, setIsValid] = useState(true)
	const [showValidationError, setShowValidationError] = useState(true)
	const [value, setValue] = useState(props.value)
	const quillRef = useRef<ReactQuill>(null)

	useEffect(() => {
		setValue(props.value)
	}, [props.value])

	function getEditorHeight(): number {
		return props.rows * 30
	}

	function getModules(): { [key: string]: any } {
		const modules = {
			toolbar: {
				container: '#rte__toolbar',
			},
		}
		return modules
	}

	function commitValue(value: string): void {
		setIsUserInteracted(true)

		setIsValid(isValueValid(value))

		setValue(value)
		props.onChange(value)
	}

	function isValueValid(value: string): boolean {
		if (props.validation) {
			const validationResult = props.validation.isValid(
				Utilities.stripHTMLTagsFromString(value),
				isUserInteracted,
			)

			if (typeof validationResult.showValidationFlag === 'boolean') {
				setShowValidationError(validationResult.showValidationFlag)
			}

			return validationResult.isValid
		}
		return true
	}

	return (
		<div style={{ position: 'relative' }} className={`${props.disabled ? 'disabled' : ''}`}>
			{props.label && (
				<div className="mb-5">
					<strong>{props.label}</strong>
				</div>
			)}
			<RichTextEditorToolbar quillRef={quillRef.current} />
			<ReactQuill
				theme="snow"
				ref={quillRef}
				modules={getModules()}
				value={value}
				className={props.className}
				style={{ height: `${getEditorHeight()}px` }}
				onChange={(value, delta, source, editor) => {
					commitValue(value)
				}}
				placeholder={props.placeholder}
			/>
			{!isValid && showValidationError && (
				<>
					<FontAwesomeIcon
						style={{ position: 'absolute', top: '60px', right: '10px' }}
						icon={['far', 'warning']}
						className="mr-10"
						title={props.validation?.message}
					/>
				</>
			)}
		</div>
	)
}
