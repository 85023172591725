import { TransactionMgmtTypes } from './transaction-mgmt.types'

export function getTransactionMgmt__Notifications(): TransactionMgmtTypes.NotificationTypes.Notification[] {
	return [
		{
			notificationId: 9001,
			name: 'Qui blanditiis praesentium voluptatum',
			description: `Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis`,
			completionDate: null,
			dueDate: null,
		},
		{
			notificationId: 9002,
			name: 'Compensation Agreement',
			description: `Necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus`,
			completionDate: null,
			dueDate: null,
		},
	]
}
export const TransactionMgmt__Locations: TransactionMgmtTypes.LocationTypes.Location[] = [
	{
		locationId: 7101,
		name: `Venture Realty Group — LoKation`,
		address1: `1500 E Atlantic Blvd, Ste B`,
		address2: ``,
		city: `Pompano Beach`,
		state: 'FL',
		zipCode: 33060,
		brokerFullName: `Chris Underwood`,
		brokerEmail: `broker@lokationre.com`,
		brokerPhone: 8441922,
	},
	{
		locationId: 7102,
		name: `Western Realty`,
		address1: `100 S 143 E`,
		address2: ``,
		city: `Denver`,
		state: 'CO',
		zipCode: 84012,
		brokerFullName: `Tyrell Owens`,
		brokerEmail: `owenst@westernrealty.com`,
		brokerPhone: 8441922,
	},
]

export function getTransactionMgmt__Tasks(): TransactionMgmtTypes.TaskTypes.Task[] {
	return [
		{
			taskId: 51001,
			name: 'Referral Agreement',
			completionDate: null,
			description: 'Lorem ipsum dolor iset enum',
			dueDate: null,
			childDocIds: [6601],
			visibility: 'PUBLISHED',
			ownerId: null,
			updates: [],
			status: 'INCOMPLETE',
		},
		{
			taskId: 51002,
			name: 'Commission Disbursement',
			completionDate: null,
			description: 'Lorem ipsum dolor iset enum',
			dueDate: null,
			childDocIds: [6602],
			visibility: 'PUBLISHED',
			ownerId: null,
			updates: [],
			status: 'INCOMPLETE',
		},
		{
			taskId: 51003,
			name: 'MLS Sheet',
			completionDate: null,
			description: 'Lorem ipsum dolor iset enum',
			dueDate: null,
			childDocIds: [],
			visibility: 'PUBLISHED',
			ownerId: null,
			updates: [],
			status: 'INCOMPLETE',
		},
		{
			taskId: 51004,
			name: 'Copy of Funds (Commission)',
			completionDate: null,
			description: 'Lorem ipsum dolor iset enum',
			dueDate: null,
			childDocIds: [],
			visibility: 'PUBLISHED',
			ownerId: null,
			updates: [],
			status: 'INCOMPLETE',
		},
		{
			taskId: 51005,
			name: 'Letter of Intent',
			completionDate: null,
			description: 'Offer and/or Executed Copy',
			dueDate: null,
			childDocIds: [],
			visibility: 'PUBLISHED',
			ownerId: null,
			updates: [],
			status: 'INCOMPLETE',
		},
		{
			taskId: 51006,
			name: 'Listing Agreement',
			completionDate: null,
			description: 'Only if the sellers were represented',
			dueDate: null,
			childDocIds: [],
			visibility: 'PUBLISHED',
			ownerId: null,
			updates: [],
			status: 'INCOMPLETE',
		},
		{
			taskId: 51007,
			name: 'Tenant Exclusive Brokerage Agreement',
			completionDate: null,
			description:
				'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
			dueDate: null,
			childDocIds: [6603, 6604, 6605],
			visibility: 'PUBLISHED',
			ownerId: null,
			updates: [],
			status: 'INCOMPLETE',
		},
		{
			taskId: 51008,
			name: 'Lease',
			completionDate: null,
			description: 'Fully Executed and Dated',
			dueDate: null,
			childDocIds: [],
			visibility: 'PUBLISHED',
			ownerId: null,
			updates: [],
			status: 'INCOMPLETE',
		},
	]
}

export function getTransactionMgmt__Documents(): TransactionMgmtTypes.DocumentTypes.Document[] {
	return [
		{
			documentId: 6601,
			name: `{Document Name 6601}`,
			description: `Lorem ipsum dolor iset`,
			completedBy: null,
			completedDate: null,
			formUrl: `www.google.com`,
			status: `PENDING`,
			type: `ONLINE_FORM`,
			reviewedByUserId: null,
			reviewedDate: null,
			createdDate: null,
			lastUpdatedDate: null,
			updates: [],
		},
		{
			documentId: 6602,
			name: `{Document Name 6602}`,
			description: `Lorem ipsum dolor iset`,
			completedBy: null,
			completedDate: null,
			formUrl: `www.google.com`,
			status: `PENDING`,
			type: `ONLINE_FORM`,
			reviewedByUserId: null,
			reviewedDate: null,
			createdDate: null,
			lastUpdatedDate: null,
			updates: [],
		},
		{
			documentId: 6603,
			name: `MLS Sheet (Full View with year built)`,
			description: `Ut enim ad minima veniam, quis nostrum exercitationem ullam`,
			completedBy: null,
			completedDate: null,
			formUrl: `www.google.com`,
			status: `PENDING`,
			type: `ONLINE_FORM`,
			reviewedByUserId: null,
			reviewedDate: null,
			createdDate: null,
			lastUpdatedDate: null,
			updates: [],
		},
		{
			documentId: 6604,
			name: `Listing Agreement - Rental (If Applicable)`,
			description: `Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur`,
			completedBy: null,
			completedDate: null,
			formUrl: `www.google.com`,
			status: `PENDING`,
			type: `ONLINE_FORM`,
			reviewedByUserId: null,
			reviewedDate: null,
			createdDate: null,
			lastUpdatedDate: null,
			updates: [],
		},
		{
			documentId: 6605,
			name: `Contract to Lease`,
			description: ` Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis`,
			completedBy: null,
			completedDate: null,
			status: `PENDING`,
			type: 'FILE_UPLOAD',
			reviewedByUserId: null,
			reviewedDate: null,
			createdDate: null,
			lastUpdatedDate: null,
			updates: [],
		},
	]
}

export function getTransactionMgmt__Checklists(): TransactionMgmtTypes.ChecklistTypes.Checklist[] {
	return [
		{
			checklistId: 4001,
			name: `Referral Commission Checklist`,
			description: `Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum`,
			visibility: 'PUBLISHED',
			addToTransactionIfConditionsMet: true,
			addToTransactionSide: ['BUYING'],
			addToTransactionStatus: [],
			addToTransactionType: ['REFERRAL_TRANSACTION'],
			childTaskIds: [51001, 51002],
			childNotificationIds: [9001],
		},
		{
			checklistId: 4002,
			name: `Rental Checklist — Commercial`,
			description: `Ut laoreet duis nisl eros purus. Fringilla class nisi; nunc etiam quis fusce. Eleifend laoreet hac laoreet molestie interdum? Accumsan etiam ad mollis dis vulputate vulputate aliquam. Rhoncus fermentum maecenas bibendum euismod rhoncus.`,
			visibility: 'PUBLISHED',
			addToTransactionIfConditionsMet: true,
			addToTransactionSide: ['BUYING'],
			addToTransactionStatus: [],
			addToTransactionType: ['RENTAL_COMMERCIAL'],
			childTaskIds: [51004, 51005, 51006],
			childNotificationIds: [],
		},
		{
			checklistId: 4003,
			name: `Rental Checklist — Residential`,
			description: `Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.`,
			visibility: 'PUBLISHED',
			addToTransactionIfConditionsMet: true,
			addToTransactionSide: ['BUYING', 'SELLING'],
			addToTransactionStatus: [],
			addToTransactionType: ['RENTAL_RESIDENTIAL'],
			childTaskIds: [51007, 51008],
			childNotificationIds: [9002],
		},
	]
}

export function getTransactionMgmt__Transactions(): TransactionMgmtTypes.TransactionTypes.Transaction[] {
	return [
		{
			transactionId: 100,
			name: '1115 Coyote Gulch Way, Bluffdale UT 84065',
			lastUpdatedDate: 1735234211518,
			createdDate: 1734734211518,
			type: `RENTAL_COMMERCIAL`,
			yearBuilt: 2005,
			status: 'ACTIVE',
			salePrice: 100000,
			closeDate: 1718060148000,
			acceptedDate: 1718080148000,
			mlsNumber: `A19220312`,
			locationId: 7101,
			contacts: [
				{
					fullName: `Lilian Giselle Madrid Montaldo`,
					title: `Listing Agent`,
					organization: `United Realty Group, Inc.`,
					email: `gmadridrealestate@yahoo.com`,
					phone: 9549912767,
					contactId: 5003,
					type: 'OTHER',
				},
				{
					fullName: `Lynn Magdali`,
					title: `Listing Agent`,
					organization: `Butler Title, LLC`,
					email: `lyn@butlertitle.com`,
					phone: 9546168735,
					contactId: 5004,
					type: 'OTHER',
				},
				{
					fullName: `Jose Damian Passarini`,
					title: `Owner`,
					organization: `Butler Title, LLC`,
					email: `jd@butlertitle.com`,
					phone: 2082062329,
					contactId: 5001,
					type: 'SELLER',
				},
				{
					fullName: `Eugenia Sanon / Jajah Tassay`,
					title: `Something`,
					organization: `Org Name`,
					email: `jtassay@gmail.com`,
					phone: 2083578819,
					contactId: 5002,
					type: 'BUYER',
				},
			],
			transactionOwnerIds: [4617, 1012],
			transactionWatcherIds: [4613, 4611],
			side: 'BUYING',
			tasks: [
				{
					taskId: 1001,
					name: `{Task Name 1}`,
					description: `[Task Description]`,
					dueDate: 1718080148000,
					completionDate: null,
					visibility: 'PUBLISHED',
					childDocIds: [1001, 1002],
					ownerId: 4617,
					status: 'INCOMPLETE',
					updates: [
						{
							updateId: 10001,
							type: 'TASK_REASSIGNED',
							senderEndUserId: 2001,
							createTimestamp: 1726442117349,
							newOwnerEndUserId: 1012,
							visibility: 'ALL',
							taskId: 1001,
						},
						{
							updateId: 10052,
							type: 'COMMENT',
							body: 'I dont feel like completing this task. Do we really have to do it?',
							createTimestamp: 1726442117349,
							senderEndUserId: 1012,
							visibility: 'ALL',
							taggedEndUserIds: [],
						},
					],
				},
				{
					taskId: 1002,
					name: `{Task Name 1.1}`,
					description: `[Task Description]`,
					dueDate: 1718080148000,
					completionDate: 1718080148000,
					visibility: 'PUBLISHED',
					childDocIds: [1003, 1004],
					ownerId: 4617,
					status: 'COMPLETE',
					updates: [],
				},
			],
			notifications: [
				{
					notificationId: 2001,
					name: `Call Jessica`,
					description: `Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud`,
					dueDate: 4355346645,
					completionDate: null,
				},
			],
			documents: [
				{
					documentId: 1001,
					name: `{Document Name 1}`,
					description: `Lorem ipsum dolor iset`,
					completedBy: 909,
					completedDate: 1718080148000,
					formUrl: `www.google.com`,
					status: `APPROVED`,
					type: `ONLINE_FORM`,
					reviewedByUserId: 20,
					reviewedDate: 123535456,
					createdDate: 1734636037725,
					lastUpdatedDate: 1734636037725,
					updates: [],
				},
				{
					documentId: 1002,
					name: `{Document Name 2}`,
					description: `Lorem ipsum dolor iset`,
					status: `PENDING`,
					type: `FILE_UPLOAD`,
					reviewedByUserId: 20,
					reviewedDate: 123535456,
					completedDate: null,
					completedBy: null,
					createdDate: 1734636037725,
					lastUpdatedDate: 1734636037725,
					updates: [
						{
							updateId: 10021,
							type: 'COMMENT',
							createTimestamp: 1734636037725,
							senderEndUserId: 20,
							visibility: 'ALL',
							body: 'You uploaded a picture of your vacation. Did you mean to send that file to us?',
							taggedEndUserIds: [],
						},
					],
				},

				{
					documentId: 1003,
					name: `{Document Name 1.1}`,
					description: `Lorem ipsum dolor iset`,
					completedBy: 909,
					completedDate: 1718080148000,
					formUrl: `www.google.com`,
					status: `APPROVED`,
					type: `ONLINE_FORM`,
					reviewedByUserId: 20,
					reviewedDate: 123535456,
					createdDate: 1734636037725,
					lastUpdatedDate: 1734636037725,
					updates: [],
				},
				{
					documentId: 1004,
					name: `{Document Name 2.1}`,
					description: `Lorem ipsum dolor iset`,
					status: `APPROVED`,
					type: `FILE_UPLOAD`,
					reviewedByUserId: 20,
					reviewedDate: 123535456,
					completedDate: 4355346645,
					completedBy: 10,
					createdDate: 1734636037725,
					lastUpdatedDate: 1734636037725,
					updates: [],
				},
				{
					documentId: 1005,
					name: `{Document Name Again}`,
					description: `Lorem ipsum dolor iset`,
					status: `APPROVED`,
					type: `FILE_UPLOAD`,
					reviewedByUserId: null,
					reviewedDate: null,
					completedDate: null,
					completedBy: null,
					createdDate: 1734636037725,
					lastUpdatedDate: 1734636037725,
					updates: [],
				},
			],
			updates: [
				{
					updateId: 10002,
					type: 'COMMENT',
					senderEndUserId: 2001,
					body: 'Why did you send me this task? Did you do this as a prank because you know Im illiterate?',
					createTimestamp: 1726436117349,
					visibility: 'ADMIN',
					taggedEndUserIds: [],
				},
				{
					updateId: 10003,
					type: 'CONTACT_ADDED',
					senderEndUserId: 2003,
					createTimestamp: 1726334117349,
					visibility: 'ALL',
					contactId: 5001,
				},
				{
					updateId: 10004,
					type: 'EMAIL_SENT',
					senderEndUserId: 2006,
					createTimestamp: 1726233117349,
					visibility: 'ALL',
					recipientEmail: `jmanwaring@lokation.com`,
				},
				{
					updateId: 10005,
					type: 'TASK_ADDED',
					senderEndUserId: 2009,
					createTimestamp: 1726136117349,
					visibility: 'ALL',
					taskId: 1001,
				},
			],
		},

		{
			transactionId: 101,
			name: '294 Apache Ave, Rexburg ID 83440',
			lastUpdatedDate: 1727109486470,
			createdDate: 1715309486470,
			type: `RENTAL_COMMERCIAL`,
			yearBuilt: 1992,
			status: 'ACTIVE',
			salePrice: 45000,
			closeDate: 1718060148000,
			acceptedDate: 1718080148000,
			mlsNumber: `A19220312`,
			locationId: 7102,
			contacts: [
				{
					fullName: `Amanda Frei`,
					title: `Listing Agent`,
					organization: `American Realty Group, Inc.`,
					email: `afrei@yahoo.com`,
					phone: 9549912767,
					contactId: 5010,
					type: 'OTHER',
				},
				{
					fullName: `Cole Orme`,
					title: `Listing Agent`,
					organization: `Orme Title, LLC`,
					email: `corme@ormetitle.com`,
					phone: 9546168735,
					contactId: 5011,
					type: 'OTHER',
				},
				{
					fullName: `Milo Vaughan`,
					title: ``,
					organization: ``,
					email: ``,
					phone: 2082062329,
					contactId: 5012,
					type: 'SELLER',
				},
				{
					fullName: `Chrissy Eagerton`,
					title: ``,
					organization: ``,
					email: ``,
					phone: 2083578819,
					contactId: 5013,
					type: 'BUYER',
				},
			],
			transactionOwnerIds: [4617, 1012],
			transactionWatcherIds: [4613, 4611],
			side: 'BUYING',
			tasks: [
				{
					taskId: 1011,
					name: `{Task Name 3}`,
					description: `[Task Description]`,
					dueDate: 1718080148000,
					completionDate: 1718080148000,
					visibility: 'PUBLISHED',
					childDocIds: [1011, 1012],
					ownerId: 4617,
					status: 'COMPLETE',
					updates: [
						{
							updateId: 10010,
							type: 'TASK_COMPLETED',
							senderEndUserId: 1012,
							createTimestamp: 1726642117349,
							visibility: 'ALL',
							taskId: 1011,
						},
						{
							updateId: 10011,
							type: 'TASK_REASSIGNED',
							senderEndUserId: 2001,
							createTimestamp: 1726442117349,
							newOwnerEndUserId: 1012,
							visibility: 'ALL',
							taskId: 1011,
						},
					],
				},
			],
			notifications: [
				{
					notificationId: 2011,
					name: `Call Jessica`,
					description: `Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud`,
					dueDate: 4355346645,
					completionDate: null,
				},
			],
			documents: [
				{
					documentId: 1011,
					name: `{Document Name 3}`,
					description: `Lorem ipsum dolor iset`,
					completedBy: 909,
					completedDate: 1718080148000,
					formUrl: `www.google.com`,
					status: `APPROVED`,
					type: `ONLINE_FORM`,
					reviewedByUserId: 20,
					reviewedDate: 123535456,
					createdDate: 1734636037725,
					lastUpdatedDate: 1734636037725,
					updates: [],
				},
				{
					documentId: 1012,
					name: `{Document Name 4}`,
					description: `Lorem ipsum dolor iset`,
					status: `PENDING`,
					type: `FILE_UPLOAD`,
					reviewedByUserId: 20,
					reviewedDate: 123535456,
					completedDate: 4355346645,
					completedBy: 10,
					createdDate: 1734636037725,
					lastUpdatedDate: 1734636037725,
					updates: [],
				},
			],
			updates: [
				{
					updateId: 10012,
					type: 'COMMENT',
					senderEndUserId: 2001,
					body: 'Why did you send me this task? Did you do this as a prank because you know Im illiterate?',
					createTimestamp: 1726436117349,
					visibility: 'ADMIN',
					taggedEndUserIds: [],
				},
			],
		},

		{
			transactionId: 102,
			name: '198 Magnolia Dr, Mission Viejo, CA 71662',
			lastUpdatedDate: 1727109486470,
			createdDate: 1715309486470,
			type: `SALE_NEW_CONSTRUCTION`,
			yearBuilt: 2024,
			status: 'ACTIVE',
			salePrice: 750000,
			closeDate: 1714060148000,
			acceptedDate: 1716080148000,
			mlsNumber: `A19220312`,
			locationId: 7102,
			contacts: [
				{
					fullName: `Kerime Lindquist`,
					title: `Listing Agent`,
					organization: `Golden State Realty, Inc.`,
					email: `klindquist@gstaterealty.com`,
					phone: 9549912767,
					contactId: 5020,
					type: 'OTHER',
				},
				{
					fullName: `Bryce Leonard`,
					title: `Listing Agent`,
					organization: `Freedom Title, LLC`,
					email: `bleonard@freedomtitle.com`,
					phone: 9546168735,
					contactId: 5021,
					type: 'OTHER',
				},
				{
					fullName: `Jared Eguizibal`,
					title: ``,
					organization: ``,
					email: ``,
					phone: 2082062329,
					contactId: 5022,
					type: 'SELLER',
				},
				{
					fullName: `Zach Lindstrom`,
					title: ``,
					organization: ``,
					email: ``,
					phone: 2083578819,
					contactId: 5023,
					type: 'BUYER',
				},
			],
			transactionOwnerIds: [4617, 1012],
			transactionWatcherIds: [4613, 4611],
			side: 'BUYING',
			tasks: [
				{
					taskId: 1021,
					name: `{Task Name 4}`,
					description: `[Task Description]`,
					dueDate: 1718080148000,
					completionDate: 1718080148000,
					visibility: 'PUBLISHED',
					childDocIds: [1011, 1012],
					ownerId: 4617,
					status: 'COMPLETE',
					updates: [
						{
							updateId: 10020,
							type: 'TASK_COMPLETED',
							senderEndUserId: 1012,
							createTimestamp: 1718080148000,
							visibility: 'ALL',
							taskId: 1021,
						},
						{
							updateId: 10021,
							type: 'TASK_REASSIGNED',
							senderEndUserId: 2001,
							createTimestamp: 1718070148000,
							newOwnerEndUserId: 1012,
							visibility: 'ALL',
							taskId: 1021,
						},
					],
				},
			],
			notifications: [
				{
					notificationId: 2021,
					name: `Call Jessica`,
					description: `Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud`,
					dueDate: 4355346645,
					completionDate: null,
				},
			],
			documents: [
				{
					documentId: 1011,
					name: `{Document Name 3}`,
					description: `Lorem ipsum dolor iset`,
					completedBy: 909,
					completedDate: 1718080148000,
					formUrl: `www.google.com`,
					status: `APPROVED`,
					type: `ONLINE_FORM`,
					reviewedByUserId: 20,
					reviewedDate: 123535456,
					createdDate: 1734636037725,
					lastUpdatedDate: 1734636037725,
					updates: [],
				},
				{
					documentId: 1012,
					name: `{Document Name 4}`,
					description: `Lorem ipsum dolor iset`,
					status: `PENDING`,
					type: `FILE_UPLOAD`,
					reviewedByUserId: 20,
					reviewedDate: 123535456,
					completedDate: 4355346645,
					completedBy: 10,
					createdDate: 1734636037725,
					lastUpdatedDate: 1734636037725,
					updates: [],
				},
			],
			updates: [
				{
					updateId: 10022,
					type: 'COMMENT',
					senderEndUserId: 2001,
					body: 'Why did you send me this task? Did you do this as a prank because you know Im illiterate?',
					createTimestamp: 1726436117349,
					visibility: 'ADMIN',
					taggedEndUserIds: [],
				},
			],
		},
	]
}
