import { LokationTag } from '../tags/tag.types'
import { ValidationResult } from '../validation/validation.types'
import { LokationResource, ResourcesOrganizedByTagDTO } from './resources.types'

export class ResourceService {
	public static validate(resource: Partial<LokationResource>): ValidationResult {
		let result: ValidationResult = {
			isValid: true,
			messages: [],
		}

		if (!resource.title || (typeof resource.title === 'string' && resource.title.length === 0)) {
			result.isValid = false
			result.messages.push(`Title cannot be empty`)
		}
		if (!resource.description || (typeof resource.description === 'string' && resource.description.length === 0)) {
			result.isValid = false
			result.messages.push(`Description cannot be empty`)
		}
		if (!resource.tags || (resource.tags && resource.tags.length === 0)) {
			result.isValid = false
			result.messages.push(`Must contain at least one tag`)
		}
		return result
	}

	public static organizeByTag(resources: LokationResource[]): ResourcesOrganizedByTagDTO[] {
		const groups: ResourcesOrganizedByTagDTO[] = []
		resources.forEach((resource) => {
			resource.tags.forEach((tag) => {
				const matchingGroup = groups.find((group) => group.value === tag.tagId)
				if (matchingGroup) {
					matchingGroup.resources.push(resource)
				} else {
					groups.push({
						value: tag.tagId,
						label: tag.label,
						resources: [resource],
					})
				}
			})
		})
		return groups
	}

	public static doesResourceIncludeTag(item: LokationResource, selectedTags: LokationTag[]): boolean {
		const selectedTagIds = selectedTags.map((tag) => tag.tagId)
		let isTagInItem = false
		item.tags.forEach((tag) => {
			if (selectedTagIds.includes(tag.tagId)) {
				isTagInItem = true
			}
		})
		if (isTagInItem) {
			return true
		}

		return false
	}

	public static getResourceFromId(
		allResources: LokationResource[],
		resourceId: number,
	): LokationResource | undefined {
		return allResources.find((resource) => resource.resourceId === resourceId)
	}

	public static getIsExternalUrl(resource: LokationResource): boolean {
		return typeof resource.url === 'string' && resource.url.includes(`http`)
	}

	public static getIsExternalUrlYouTubeVideo(resource: LokationResource): boolean {
		return typeof resource.url === 'string' && resource.url.includes(`youtu`)
	}
}
