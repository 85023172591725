import { Box } from '@components/box/box'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FullScreenSpinnerTypes } from './full-screen-spinner.types'

export function FullScreenSpinner(props: FullScreenSpinnerTypes.Component) {
	return (
		<Box flex="column" alignItems="center" justifyContent="center" style={{ width: '100vw', height: '100vh' }}>
			<FontAwesomeIcon icon={['far', 'spinner']} spin={true} size="4x" style={{ opacity: '0.75' }} />
			{props.label && (
				<Typography type="h4" color="secondary" margins={['top']} marginSize="section">
					{props.label}
				</Typography>
			)}
		</Box>
	)
}
