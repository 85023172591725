import { Button } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { Tooltip } from '@components/tooltip/tooltip'
import { useAppDispatch } from '@redux/hooks'
import { modalSlice } from '@redux/reducers/modal-reducer'
import { useCallback, useEffect, useRef, useState } from 'react'

import { StripeAPI } from '../../../../services/stripe/stripe.api'
import { StripeTypes } from '../../../../services/stripe/stripe.types'
import { EndUserProps } from '../../../../services/user/user.types'
import ManageSubscription from './subscription-components/manage-subscription'

const FETCH_LIMIT = 10

function Subscriptions(props: { userToEdit: EndUserProps; currentUser: EndUserProps | null }) {
	const lastId = useRef('')

	const [subscriptions, setSubscriptions] = useState<StripeTypes.SubscriptionData[]>([])
	const [subscriptionSchedules, setSubscriptionSchedules] = useState<StripeTypes.SubscriptionSchedule[]>([])
	const [scheduledProductNames, setScheduledProductNames] = useState<Map<string, string>>(new Map())

	const [subscriptionToManage, setSubscriptionToManage] = useState<string | null>(null)

	const [modalId, setModalId] = useState<string | null>(null)
	const [hasMore, setHasMore] = useState(false)

	const [loading, setLoading] = useState(true)

	const disabledProductIds = [
		'product.florida.blue.plan.compliance.fee',
		'product.florida.silver.plan.compliance.fee',
		'product.georgia.blue.plan.compliance.fee',
		'product.georgia.silver.plan.compliance.fee',
		'product.alabama.blue.plan.compliance.fee',
		'product.alabama.silver.plan.compliance.fee',
		'product.texas.blue.plan.compliance.fee',
		'product.texas.silver.plan.compliance.fee',
		'product.south.carolina.blue.plan.compliance.fee',
		'product.south.carolina.silver.plan.compliance.fee',
		'product.colorado.blue.plan.compliance.fee',
		'product.colorado.silver.plan.compliance.fee',
	]

	const manageSubscription = (subscriptionId: string) => {
		const id = `manageSubscriptionModal_${subscriptionId}`
		setModalId(id)
		setSubscriptionToManage(subscriptionId)
		dispatch(modalSlice.actions.updateVisibility({ id, state: true }))
	}

	const dispatch = useAppDispatch()

	const fetchSubscriptions = useCallback((endUserId?: number) => {
		StripeAPI.fetchSubscriptions(FETCH_LIMIT, lastId.current, endUserId)
			.then((res) => {
				const subscriptionsArray = res as StripeTypes.SubscriptionData[]
				if (subscriptionsArray.length > 0) {
					// Update lastId to the ID of the last subscription in the array
					lastId.current = subscriptionsArray[subscriptionsArray.length - 1].id
				}
				setLoading(false)
				// Add new subscriptions to the existing subscriptions
				setSubscriptions((prevSubscriptions) => [...prevSubscriptions, ...subscriptionsArray])

				// subscriptionsArray.forEach((subscription: StripeTypes.SubscriptionData) => {
				//     subscription.items.data.forEach((item: StripeTypes.SubscriptionItem) => {
				//         StripeAPI.fetchProductById(item.plan.product).then((res) => {
				//             setProductNames((prevProductNames) =>
				//                 new Map(prevProductNames).set(item.plan.product, res.name)
				//             );
				//         })
				//         .catch((error) => {
				//             console.log('Failed to fetch product details:', error);
				//         })
				//     });
				// });

				// If the size of subscriptionsArray is equal to FETCH_LIMIT, there might be more subscriptions
				setHasMore(subscriptionsArray.length === FETCH_LIMIT)
			})
			.catch((error) => {
				console.log('subscriptions were not fetched')
				setLoading(false)
				throw new Error(`Failed subscriptions: ${error.message}`)
			})
	}, [])

	const fetchSubscriptionSchedules = useCallback((endUserId?: number) => {
		StripeAPI.fetchSubscriptionSchedules(FETCH_LIMIT, lastId.current, endUserId)
			.then((res) => {
				let subscriptionsArray: StripeTypes.SubscriptionSchedule[]

				if (Array.isArray(res)) {
					subscriptionsArray = res
				} else {
					subscriptionsArray = [res]
				}

				if (subscriptionsArray.length > 0) {
					// Update lastId to the ID of the last subscription in the array
					lastId.current = subscriptionsArray[subscriptionsArray.length - 1].id
				}

				setLoading(false)
				// Add new schedules to the existing schedules
				setSubscriptionSchedules((prevSchedules) => [...prevSchedules, ...subscriptionsArray])

				subscriptionsArray.forEach((schedule: StripeTypes.SubscriptionSchedule) => {
					schedule.phases.forEach((phase: StripeTypes.Phase) => {
						phase.items.forEach((item: StripeTypes.PhaseItem) => {
							setScheduledProductNames((prevScheduledProductNames) =>
								new Map(prevScheduledProductNames).set(item.price, item.product.name),
							)
						})
					})
				})

				// If the size of subscriptionsArray is equal to FETCH_LIMIT, there might be more schedules
				setHasMore(subscriptionsArray.length === FETCH_LIMIT)
			})
			.catch((error) => {
				console.log('schedules were not fetched')
				throw new Error(`Failed schedules: ${error.message}`)
			})
	}, [])

	let endUserId: number | undefined
	useEffect(() => {
		setLoading(true)
		if (props.currentUser && props.userToEdit) {
			const isAdmin = props.currentUser.endUserId !== props.userToEdit.endUserId
			if (isAdmin) {
				endUserId = props.userToEdit.endUserId
			}
		}
		fetchSubscriptions(endUserId)
		fetchSubscriptionSchedules(endUserId)
	}, [props.currentUser])

	const loadMoreSubscriptions = () => {
		fetchSubscriptions(endUserId)
	}

	const loadMoreSubscriptionSchedules = () => {
		fetchSubscriptionSchedules(endUserId)
	}

	const updateSubscription = (updatedSubscription: StripeTypes.FetchSubscriptionsDetailsResponse) => {
		setSubscriptions((prevSubscriptions) => {
			const newSubscriptions = prevSubscriptions.map((subscription) =>
				subscription.id === updatedSubscription.id
					? { ...subscription, status: updatedSubscription.status }
					: subscription,
			)
			return newSubscriptions
		})
	}

	const handleDismissModal = () => {
		// When the modal is dismissed, refetch the managed subscription to make sure it's up to date
		if (subscriptionToManage) {
			StripeAPI.fetchSubscriptionDetails(subscriptionToManage)
				.then((res) => {
					updateSubscription(res)
				})
				.catch((error) => {
					console.log('Failed to fetch updated subscription details:', error)
				})
		}
		setSubscriptionToManage(null)
		setModalId(null)
		if (!modalId) {
			return
		}
		dispatch(modalSlice.actions.updateVisibility({ id: modalId, state: false }))
	}

	return (
		<>
			<div className="mb-20">
				<Typography type="h2" margins={['bottom']}>
					Subscriptions
				</Typography>
				{subscriptionSchedules && subscriptionSchedules.length > 0 && (
					<>
						<Typography type="h5" margins={['bottom']}>
							Upcoming subscriptions
						</Typography>
						<div style={{ maxWidth: `800px` }} className="mb-20">
							{loading ? (
								<>
									<div className="mb-10">
										<GenericContentLoader width={'fill'} height={112} />
									</div>
								</>
							) : (
								<div>
									{subscriptionSchedules &&
										subscriptionSchedules.map((subscription) => (
											<div key={subscription.id}>
												<Paper bgColor="primary" padding={['all']} margins={['bottom']}>
													<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center flex-column-md-down">
														<div className="flex flex-alignItems-center flex-column-md-down">
															<div className="flex flex-column flex-alignItems-center-md-down">
																<div
																	className={`subscription-status ${subscription.status === 'active' ? 'active' : subscription.status === 'not_started' ? 'scheduled' : 'canceled'}`}
																>
																	{subscription.status === 'not_started'
																		? 'Scheduled'
																		: subscription.status}
																</div>

																{/* Check if phases exist and if the first phase has a start_date. Adjust accordingly. */}
																<div className="invoice-date mb-5">
																	Next billing date:{' '}
																	{subscription.phases &&
																		subscription.phases[0] &&
																		new Date(
																			subscription.phases[0].startDate * 1000,
																		).toLocaleDateString()}
																</div>

																{/* You'll need to adjust this calculation based on your data structure. */}
																<div className="invoice-amount">
																	$
																	{(
																		subscription.phases &&
																		subscription.phases[0].items.reduce(
																			(total, item) =>
																				total +
																				item.product.price * item.quantity,
																			0,
																		) / 100
																	).toFixed(2)}
																</div>
															</div>

															<div className="product-name ml-20">
																{subscription.phases &&
																	subscription.phases[0].items.map((item, index) => (
																		<p key={index}>
																			{scheduledProductNames.get(item.price)}{' '}
																			{item.quantity > 1 && `x ${item.quantity}`}
																		</p>
																	))}
															</div>
														</div>

														<div>{/* ... */}</div>
													</div>
												</Paper>
											</div>
										))}
									{hasMore && (
										<div className="flex flex-justifyContent-center mt-20">
											<Button
												variant="contained"
												label="Load More"
												size={'md'}
												onClick={loadMoreSubscriptionSchedules}
											/>
										</div>
									)}
								</div>
							)}
						</div>
					</>
				)}

				<Typography type="h5" margins={['bottom']}>
					Current subscriptions
				</Typography>
				<div style={{ maxWidth: `800px` }}>
					{loading ? (
						<>
							<div className="mb-10">
								<GenericContentLoader width={'fill'} height={112} />
							</div>
							<div className="mb-10">
								<GenericContentLoader width={'fill'} height={112} />
							</div>
							<div className="mb-10">
								<GenericContentLoader width={'fill'} height={112} />
							</div>
							<div className="mb-10">
								<GenericContentLoader width={'fill'} height={112} />
							</div>
							<div className="mb-10">
								<GenericContentLoader width={'fill'} height={112} />
							</div>
						</>
					) : (
						<div>
							{subscriptions &&
								subscriptions
									.filter((subscription) => subscription.status !== 'canceled')
									.map((subscription) => (
										<div key={subscription.id}>
											<Paper bgColor="primary" padding={['all']} margins={['bottom']}>
												<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center flex-column-md-down">
													<div className="flex flex-alignItems-center flex-column-md-down">
														<div className="flex flex-column flex-alignItems-center-md-down">
															<div
																className={`subscription-status ${subscription.status === 'active' ? 'active' : 'canceled'}`}
															>
																{subscription.status}
															</div>
															<div className="invoice-date mb-5">
																Next billing date:{' '}
																{new Date(
																	subscription.currentPeriodEnd * 1000,
																).toLocaleDateString()}
															</div>
															<div className="invoice-amount flex flex-column">
																$
																{(() => {
																	let totalAmount =
																		subscription.items.data.reduce(
																			(total, item) =>
																				total +
																				item.plan.amount * item.quantity,
																			0,
																		) / 100

																	// Handle percent-off discount
																	if (subscription.discount?.coupon?.percentOff) {
																		return (
																			totalAmount *
																			(1 -
																				subscription.discount.coupon
																					.percentOff /
																					100)
																		)
																	}

																	// Handle fixed amount-off discount
																	if (subscription.discount?.coupon?.amountOff) {
																		return (
																			totalAmount -
																			subscription.discount.coupon.amountOff / 100
																		)
																	}

																	// No discount
																	return totalAmount
																})().toFixed(2)}
																{subscription.discount &&
																	subscription.discount.coupon.duration ===
																		'forever' && (
																		// <span className='multi-license-discount ml-5'> (Multi-License Discount)</span>
																		<div className="flex mt-5">
																			<Tooltip
																				body="(Multi-License Discount)"
																				icon="circle-info"
																			/>
																		</div>
																	)}
																{subscription.discount &&
																	subscription.discount.coupon.duration ===
																		'once' && (
																		// <span className='multi-license-discount ml-5'> (Multi-License Discount)</span>
																		<div className="flex mt-5">
																			<Tooltip
																				body="(One-Time Discount)"
																				icon="circle-info"
																			/>
																		</div>
																	)}
															</div>
														</div>
														<div className="product-name ml-20">
															{subscription.items.data.map((item, index) => (
																<p key={index}>
																	{item.plan.productDetails.name}{' '}
																	{item.quantity > 1 && `x ${item.quantity}`}
																</p>
															))}
														</div>
													</div>
													<div>
														<Button
															margins={['top']}
															variant="outlined"
															label="Manage"
															size={'md'}
															onClick={() => manageSubscription(subscription.id)}
															disabled={subscription.items.data.some((item) =>
																disabledProductIds.includes(
																	item.plan.productIdentifier ?? '',
																),
															)}
															// disabled={true}
														/>
													</div>
												</div>
											</Paper>
										</div>
									))}
							{hasMore && (
								<div className="flex flex-justifyContent-center mt-20">
									<Button
										variant="contained"
										label="Load More"
										size={'md'}
										onClick={loadMoreSubscriptions}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</div>

			{modalId && subscriptionToManage && (
				<ManageSubscription
					modalId={modalId}
					subscriptionId={subscriptionToManage}
					dismissModal={handleDismissModal}
					userToEdit={props.userToEdit}
					currentUser={props.currentUser}
				/>
			)}
		</>
	)
}

export default Subscriptions
