import { DropdownTypes } from '@components/dropdown/dropdown.types'
import { uniq } from 'lodash'

import { TransactionMgmtTypes } from './transaction-mgmt.types'

export namespace TransactionManagementService {
	export function getAllTransactionTypes(): DropdownTypes.ChildOption<TransactionMgmtTypes.TransactionTypes.Type>[] {
		return [
			{
				value: 'RENTAL_COMMERCIAL',
				label: getLabelForTransactionType('RENTAL_COMMERCIAL'),
			},
			{
				value: 'RENTAL_COMPLEX_INVOICE',
				label: getLabelForTransactionType('RENTAL_COMPLEX_INVOICE'),
			},
			{
				value: 'RENTAL_RESIDENTIAL',
				label: getLabelForTransactionType('RENTAL_RESIDENTIAL'),
			},
			{
				value: 'SALE_COMMERCIAL',
				label: getLabelForTransactionType('SALE_COMMERCIAL'),
			},
			{
				value: 'SALE_EQUITABLE_TITLE',
				label: getLabelForTransactionType('SALE_EQUITABLE_TITLE'),
			},
			{
				value: 'SALE_LISTING_SIDE_RESIDENTIAL',
				label: getLabelForTransactionType('SALE_LISTING_SIDE_RESIDENTIAL'),
			},
			{
				value: 'SALE_NEW_CONSTRUCTION',
				label: getLabelForTransactionType('SALE_NEW_CONSTRUCTION'),
			},
			{
				value: 'SALE_SALE_SIDE_RESIDENTIAL',
				label: getLabelForTransactionType('SALE_SALE_SIDE_RESIDENTIAL'),
			},
			{
				value: 'SALE_VACANT_LAND',
				label: getLabelForTransactionType('SALE_VACANT_LAND'),
			},
			{
				value: 'BUYER_REPRESENTATION_AGREEMENT_FOLDER',
				label: getLabelForTransactionType('BUYER_REPRESENTATION_AGREEMENT_FOLDER'),
			},
			{
				value: 'REFERRAL_TRANSACTION',
				label: getLabelForTransactionType('REFERRAL_TRANSACTION'),
			},
		]
	}

	export function getAllDocumentStatus(): DropdownTypes.ChildOption<TransactionMgmtTypes.DocumentTypes.Status>[] {
		return [
			{
				value: 'PENDING',
				label: getLabelForDocumentStatus('PENDING'),
			},
			{
				value: 'APPROVED',
				label: getLabelForDocumentStatus('APPROVED'),
			},
			{
				value: 'REJECTED',
				label: getLabelForDocumentStatus('REJECTED'),
			},
		]
	}

	export function getLabelForTransactionType(type: TransactionMgmtTypes.TransactionTypes.Type): string {
		switch (type) {
			case 'RENTAL_COMMERCIAL':
				return `Rental (Commercial)`
			case 'RENTAL_COMPLEX_INVOICE':
				return `Rental (Complex Invoice)`
			case 'RENTAL_RESIDENTIAL':
				return `Rental (Residential)`
			case 'SALE_COMMERCIAL':
				return `Sale (Commercial)`
			case 'SALE_EQUITABLE_TITLE':
				return `Sale (Equitable Title)`
			case 'SALE_LISTING_SIDE_RESIDENTIAL':
				return `Sale (Listing Side Residential)`
			case 'SALE_NEW_CONSTRUCTION':
				return `Sale (New Construction)`
			case 'SALE_SALE_SIDE_RESIDENTIAL':
				return `Sale (Sale Side Residential)`
			case 'SALE_VACANT_LAND':
				return `Sale (Vacant Land)`
			case 'BUYER_REPRESENTATION_AGREEMENT_FOLDER':
				return `Buyer Representation Agreement Folder`
			case 'REFERRAL_TRANSACTION':
				return `Referral Transaction`
		}
	}

	export function getLabelForDocumentStatus(status: TransactionMgmtTypes.DocumentTypes.Status): string {
		switch (status) {
			case 'APPROVED':
				return `Approved`
			case 'PENDING':
				return `Pending`
			case 'REJECTED':
				return `Rejected`
		}
	}

	export function getLabelForContactType(type: TransactionMgmtTypes.TransactionTypes.ContactType): string {
		switch (type) {
			case 'BUYER':
				return `Buyer`
			case 'SELLER':
				return `Seller`
			case 'OTHER':
				return `Other`
		}
	}

	export function getAllTransactionStatus(): DropdownTypes.ChildOption<TransactionMgmtTypes.TransactionTypes.Status>[] {
		return [
			{
				value: 'ACTIVE',
				label: getLabelForTransactionStatus('ACTIVE'),
			},
			{
				value: 'INACTIVE',
				label: getLabelForTransactionStatus('INACTIVE'),
			},
		]
	}

	export function getLabelForTransactionStatus(status: TransactionMgmtTypes.TransactionTypes.Status): string {
		switch (status) {
			case 'ACTIVE':
				return `Active`
			case 'INACTIVE':
				return `Inactive`
		}
	}

	export function getAllTransactionSides(): DropdownTypes.ChildOption<TransactionMgmtTypes.TransactionTypes.Side>[] {
		return [
			{
				value: 'BUYING',
				label: getLabelForTransactionSide('BUYING'),
			},
			{
				value: 'SELLING',
				label: getLabelForTransactionSide('SELLING'),
			},
		]
	}

	export function getAllUpdateTypes(): DropdownTypes.ChildOption<TransactionMgmtTypes.UpdateTypes.Type>[] {
		return [
			{
				value: 'COMMENT',
				label: getLabelForUpdateType('COMMENT'),
			},
			{
				value: 'CONTACT_ADDED',
				label: getLabelForUpdateType('CONTACT_ADDED'),
			},
			{
				value: 'EMAIL_SENT',
				label: getLabelForUpdateType('EMAIL_SENT'),
			},
			{
				value: 'TASK_ADDED',
				label: getLabelForUpdateType('TASK_ADDED'),
			},
			{
				value: 'TASK_COMPLETED',
				label: getLabelForUpdateType('TASK_COMPLETED'),
			},
			{
				value: 'TASK_REASSIGNED',
				label: getLabelForUpdateType('TASK_REASSIGNED'),
			},
		]
	}

	export function getLabelForUpdateType(side: TransactionMgmtTypes.UpdateTypes.Type): string {
		switch (side) {
			case 'COMMENT':
				return `Message`
			case 'CONTACT_ADDED':
				return `Contact Added`
			case 'EMAIL_SENT':
				return `Email Sent`
			case 'TASK_ADDED':
				return `Task Added`
			case 'TASK_COMPLETED':
				return `Task Completed`
			case 'TASK_REASSIGNED':
				return `Task Reassigned`
		}
	}

	export function getLabelForTransactionSide(side: TransactionMgmtTypes.TransactionTypes.Side): string {
		switch (side) {
			case 'BUYING':
				return `Buying`
			case 'SELLING':
				return `Selling`
		}
	}

	export function getLabelForTaskStatus(taskStatus: TransactionMgmtTypes.TaskTypes.Status): string {
		switch (taskStatus) {
			case 'COMPLETE':
				return `Complete`
			case 'INCOMPLETE':
				return `Incomplete`
		}
	}

	export function getDocumentById(
		transaction: TransactionMgmtTypes.TransactionTypes.Transaction,
		documentId: number,
	): TransactionMgmtTypes.DocumentTypes.Document | undefined {
		return transaction.documents.find((document) => document.documentId === documentId)
	}

	export function generateNewContactProps(
		type: TransactionMgmtTypes.TransactionTypes.ContactType,
	): TransactionMgmtTypes.TransactionTypes.Contact {
		const newContact: TransactionMgmtTypes.TransactionTypes.Contact = {
			fullName: '',
			title: '',
			organization: '',
			phone: 0,
			email: '',
			contactId: -1,
			type,
		}

		return newContact
	}

	export function getDefaultTaskProps(): TransactionMgmtTypes.TaskTypes.Task {
		const newTask: TransactionMgmtTypes.TaskTypes.Task = {
			taskId: -1,
			name: '',
			completionDate: null,
			description: '',
			dueDate: null,
			childDocIds: [],
			visibility: 'NOT_PUBLISHED',
			ownerId: null,
			updates: [],
			status: 'INCOMPLETE',
		}
		return newTask
	}

	export function getDefaultTransactionProps(): TransactionMgmtTypes.TransactionTypes.Transaction {
		const newTransaction: TransactionMgmtTypes.TransactionTypes.Transaction = {
			transactionId: -1,
			acceptedDate: 0,
			closeDate: 0,
			contacts: [],
			documents: [],
			mlsNumber: '',
			name: '',
			salePrice: 0,
			side: 'BUYING',
			status: 'ACTIVE',
			tasks: [],
			notifications: [],
			transactionOwnerIds: [],
			transactionWatcherIds: [],
			type: 'RENTAL_COMMERCIAL',
			locationId: 0,
			updates: [],
			lastUpdatedDate: 0,
			createdDate: 0,
			yearBuilt: 0,
		}
		return newTransaction
	}

	export function getDefaultDocumentProps(): TransactionMgmtTypes.DocumentTypes.Document {
		const newTask: TransactionMgmtTypes.DocumentTypes.Document = {
			documentId: -1,
			name: '',
			description: '',
			completedBy: null,
			completedDate: null,
			createdDate: Date.now(),
			type: 'FILE_UPLOAD',
			lastUpdatedDate: Date.now(),
			reviewedByUserId: null,
			reviewedDate: null,
			status: 'PENDING',
			updates: [],
		}
		return newTask
	}

	export function getDefaultNotificationProps(): TransactionMgmtTypes.NotificationTypes.Notification {
		const newNotification: TransactionMgmtTypes.NotificationTypes.Notification = {
			notificationId: -1,
			name: '',
			description: '',
			completionDate: null,
			dueDate: null,
		}
		return newNotification
	}

	export function getDefaultLocationProps(): TransactionMgmtTypes.LocationTypes.Location {
		const newLocation: TransactionMgmtTypes.LocationTypes.Location = {
			locationId: -1,
			name: '',
			address1: '',
			address2: '',
			city: '',
			state: 'FL',
			zipCode: 0,
			brokerFullName: '',
			brokerPhone: 0,
			brokerEmail: '',
		}
		return newLocation
	}

	export function getNumCommentUpdates(updates: TransactionMgmtTypes.UpdateTypes.Update[]): number {
		return updates.filter((update) => update.type === 'COMMENT').length
	}

	export function getTaskById(
		transaction: TransactionMgmtTypes.TransactionTypes.Transaction,
		taskId: number,
	): TransactionMgmtTypes.TaskTypes.Task | undefined {
		return transaction.tasks.find((task) => task.taskId === taskId)
	}

	/** Compares the transaction in its original state to its current state to determine whether the task passed in is new or not */
	export function isThisTaskNewSinceSessionStarted(props: {
		originalTransaction: TransactionMgmtTypes.TransactionTypes.Transaction
		modifiedTransaction: TransactionMgmtTypes.TransactionTypes.Transaction
		taskId: number
	}): boolean {
		const isTaskInOriginal = props.originalTransaction.tasks.find((thisTask) => thisTask.taskId === props.taskId)
		const isTaskInModified = props.modifiedTransaction.tasks.find((thisTask) => thisTask.taskId === props.taskId)

		if (!isTaskInOriginal && isTaskInModified) {
			return true
		}
		return false
	}

	/** Compares the transaction in its original state to its current state to determine whether the document passed in is new or not */
	export function isThisDocumentNewSinceSessionStarted(props: {
		originalTransaction: TransactionMgmtTypes.TransactionTypes.Transaction
		modifiedTransaction: TransactionMgmtTypes.TransactionTypes.Transaction
		documentId: number
	}): boolean {
		const isDocInOriginal = props.originalTransaction.documents.find(
			(thisDoc) => thisDoc.documentId === props.documentId,
		)
		const isDocInModified = props.modifiedTransaction.documents.find(
			(thisDoc) => thisDoc.documentId === props.documentId,
		)

		if (!isDocInOriginal && isDocInModified) {
			return true
		}
		return false
	}

	/** Compares the transaction in its original state to its current state to determine whether the notification passed in is new or not */
	export function isThisNotificationNewSinceSessionStarted(props: {
		originalTransaction: TransactionMgmtTypes.TransactionTypes.Transaction
		modifiedTransaction: TransactionMgmtTypes.TransactionTypes.Transaction
		notificationId: number
	}): boolean {
		const isNotificationInOriginal = props.originalTransaction.notifications.find(
			(thisNotification) => thisNotification.notificationId === props.notificationId,
		)
		const isNotificationInModified = props.modifiedTransaction.notifications.find(
			(thisNotification) => thisNotification.notificationId === props.notificationId,
		)

		if (!isNotificationInOriginal && isNotificationInModified) {
			return true
		}
		return false
	}

	/** Get all documents associated with a transaction that have no parent task */
	export function getOrphanedDocuments(
		transaction: TransactionMgmtTypes.TransactionTypes.Transaction,
	): TransactionMgmtTypes.DocumentTypes.Document[] {
		let docIdsOwnedByATask: number[] = []

		transaction.tasks.forEach((task) => {
			docIdsOwnedByATask = [...docIdsOwnedByATask, ...task.childDocIds]
		})

		docIdsOwnedByATask = uniq(docIdsOwnedByATask)

		return transaction.documents.filter((doc) => !docIdsOwnedByATask.includes(doc.documentId))
	}
}
