import { SharedElementService } from '@components/shared/shared-element.service'

import { TextInputTypes } from './text-input.types'

export namespace TextInputService {
	export function getWrapperClass(props: Pick<TextInputTypes.Component, 'marginSize' | 'margins'>): string {
		let classes: string[] = []

		if (props.margins) {
			classes = [
				...classes,
				...SharedElementService.getMarginClass({ margins: props.margins, marginSize: props.marginSize }),
			]
		}

		return classes.join(' ')
	}

	export function getWrapperStyle(props: Pick<TextInputTypes.Component, 'style' | 'width'>): React.CSSProperties {
		let style: React.CSSProperties = {}

		if (props.style) {
			style = { ...style, ...props.style }
		}

		const widthValue = typeof props.width === 'number' ? `${props.width}px` : props.width
		style.width = widthValue

		return style
	}

	export function getInputClassNames(
		props: Pick<TextInputTypes.Component, 'dataType' | 'disabled' | 'variant' | 'rows'> & {
			isValid: boolean
			showValidationError: boolean
		},
	): string {
		const classes: string[] = []
		classes.push('text-input col-xs-12')

		switch (props.dataType) {
			case 'number':
				classes.push('data-type__number')
				break
			case 'currency':
			case 'text':
				classes.push(`data-type__text`)
				break
		}

		if (typeof props.rows === 'number' && props.rows > 1) {
			classes.push(`is-multiple-rows`)
		}

		if (props.variant === 'unframed') {
			classes.push(`input__no-frame`)
		}

		if (props.disabled) {
			classes.push(`disabled`)
		} else {
			classes.push(`editable`)
		}

		if (!props.isValid && props.showValidationError) {
			classes.push(`is-error`)
		}

		return classes.join(' ')
	}

	export function getInputStyle(props: Pick<TextInputTypes.Component, 'inputStyle' | 'align'>): React.CSSProperties {
		let style: React.CSSProperties = {}

		if (props.inputStyle) {
			style = { ...style, ...props.inputStyle }
		}

		if (props.align) {
			switch (props.align) {
				case 'left':
					style.textAlign = 'left'
					break
				case 'right':
					style.textAlign = 'right'
					break
			}
		}

		return style
	}
}
