import './drag-and-drop-file-uploader.scss'

import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { FileManagementService } from 'src/services/file-management/file-management.service'
import { ToastService } from 'src/services/toast/toast.service'
import { Utilities } from 'src/services/utilities.service'

import { DragAndDropFileUploaderTypes } from './drag-and-drop-file-uploader.types'

export function DragAndDropFileUploader(props: DragAndDropFileUploaderTypes.Component) {
	const [dragOver, setDragOver] = useState<boolean>(false)
	const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(undefined)

	/** When the user uploads an image, load the URL for the image here so that the image can be displayed back to them */
	const [uploadedImageURL, setUploadedImageURL] = useState<string | null>(null)
	const [isFileUploaded, setIsFileUploaded] = useState(props.isFilePreviouslyUploaded)
	const supportedFormats = concatSupportedFormats()
	const allowMultiple = typeof props.allowMultiple === 'boolean' ? props.allowMultiple : false

	useEffect(() => {
		if (props.imgUrl) {
			setUploadedImageURL(props.imgUrl)
		}
	}, [props.imgUrl])

	/** ====================================================== */
	/** Methods */

	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`drag-and-drop-file-uploader fs-unmask`)

		if (dragOver) {
			classes.push(`drag-over`)
		}

		switch (props.heightVariant) {
			case 'SHORT':
				classes.push(`height__is-short`)
				break
			case 'STANDARD':
			default:
				classes.push(`height__is-standard`)
				break
		}

		if (props.className) {
			classes.push(props.className)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	function concatSupportedFormats(): string {
		return props.supportedFormats.join(', ')
	}

	function showImageToUser(file: File): void {
		if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {
			return
		}
		const objectURL = URL.createObjectURL(file)
		setUploadedImageURL(objectURL)
	}

	function handleClick(evt: React.DragEvent<HTMLDivElement>): void {
		evt.preventDefault()

		FileManagementService.uploadFileFromExplorer(supportedFormats, false, allowMultiple).then((uploadedFiles) => {
			let areAllFilesValid = true
			let fileNames = ''
			uploadedFiles.forEach((file, index) => {
				showImageToUser(file)

				if (!validateSelectedFile(file.name)) {
					areAllFilesValid = false
				}
				fileNames += `${file.name}${index < uploadedFiles.length - 1 ? `, ` : ``}`
			})
			setUploadedFileName(fileNames)
			setIsFileUploaded(true)

			if (uploadedFiles && areAllFilesValid) {
				props.onFileAdded(uploadedFiles)
				return
			}

			ToastService.create({ body: `Please provide a valid file type`, type: 'ERROR' })
		})
	}

	function handleDrop(evt: React.DragEvent<HTMLDivElement>): void {
		evt.preventDefault()
		setDragOver(false)

		const droppedFile = evt.dataTransfer.files[0]
		if (validateSelectedFile(droppedFile.name)) {
			setUploadedFileName(droppedFile.name)
			setIsFileUploaded(true)
			props.onFileAdded([droppedFile])
			ToastService.create({ body: `File added successfully`, type: 'SUCCESS' })
			return
		}

		ToastService.create({ body: `Please provide a valid file type`, type: 'ERROR' })
	}

	function validateSelectedFile(fileName: string): boolean {
		const lastPeriodIndex = fileName.lastIndexOf('.')
		const fileExtension = fileName.slice(lastPeriodIndex + 1)

		return props.supportedFormats.includes(fileExtension as DragAndDropFileUploaderTypes.FileExtensions)
	}

	function renderNullStateBody(): React.ReactNode {
		if (props.heightVariant === 'SHORT') {
			return (
				<div className="col-xs-12 flex flex-alignItems-center">
					<FontAwesomeIcon
						icon={['far', 'file-arrow-up']}
						style={{
							height: '32px',
							width: '32px',
						}}
						className="mr-20"
					/>
					<div>
						<strong>Drag and drop file here or choose file</strong>
						<div className="opacity-50">Supported formats: {supportedFormats}</div>
					</div>
				</div>
			)
		}

		return (
			<>
				<FontAwesomeIcon
					icon={['far', 'file-arrow-up']}
					style={{
						height: '64px',
						width: '64px',
					}}
				/>

				<Typography type="h5">Drag and drop file here or choose file</Typography>

				<div className="opacity-50 mt-20 text-center">Supported formats: {supportedFormats}</div>
			</>
		)
	}

	function renderUploadedStateBody(): React.ReactNode {
		if (props.heightVariant === 'SHORT') {
			return (
				<div className="col-xs-12 flex flex-alignItems-center">
					{uploadedImageURL && (
						<div
							style={{ width: '128px', height: '64px' }}
							className="bg-color__adjust-alpha-5 flex flex-alignItems-center flex-justifyContent-center mr-20"
						>
							<img src={uploadedImageURL} style={{ maxWidth: '100%', maxHeight: '100%' }} />
						</div>
					)}
					{!uploadedImageURL && (
						<FontAwesomeIcon
							icon={['far', 'file-check']}
							style={{
								height: '32px',
								width: '32px',
							}}
							className="mr-20"
						/>
					)}
					<div>
						<strong>Upload a different file</strong>
						{uploadedFileName && (
							<div className="opacity-50">{Utilities.truncateString(uploadedFileName, 50)}</div>
						)}
					</div>
				</div>
			)
		}

		return (
			<>
				{uploadedImageURL && (
					<div
						style={{ width: '128px', height: '64px' }}
						className="bg-color__adjust-alpha-5 flex flex-alignItems-center flex-justifyContent-center mr-20"
					>
						<img src={uploadedImageURL} style={{ maxWidth: '100%', maxHeight: '100%' }} />
					</div>
				)}
				{!uploadedImageURL && (
					<FontAwesomeIcon
						icon={['far', 'file-check']}
						style={{
							height: '64px',
							width: '64px',
						}}
					/>
				)}

				<Typography type="h5">Upload a different file</Typography>
				{uploadedFileName && (
					<div className="opacity-50 mt-20 text-center">{Utilities.truncateString(uploadedFileName, 50)}</div>
				)}
			</>
		)
	}

	/** ====================================================== */
	/** Render Component */
	return (
		<div
			className={getClass()}
			style={props.style}
			onClick={handleClick}
			onDragLeave={() => {
				setDragOver(false)
			}}
			onDragOver={(evt) => {
				evt.preventDefault()
				evt.stopPropagation()
				setDragOver(true)
			}}
			onDrop={handleDrop}
		>
			{!isFileUploaded && <>{renderNullStateBody()}</>}

			{isFileUploaded && <>{renderUploadedStateBody()}</>}
		</div>
	)
}
