import { Box } from '@components/box/box'
import { Button } from '@components/button/button'
import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'
import { Typography } from '@components/text/text'

import { FileBaseURL } from '../../services/axios/http-common.service'
import BrandSignatureDark from './images/brand-asset-email-signature-banner-dark-v1.png'
import BrandSignatureLight from './images/brand-asset-email-signature-banner-light-v1.png'
import ColoradoBannerLight from './images/email-banner-colorado-black.png'
import ColoradoBannerDark from './images/email-banner-colorado-blue.png'
import LuxuryEmailBannerLight from './images/lokation-email-banner.png'
import LuxuryEmailBannerDark from './images/luxury-city.png'

export function LuxuryAssetsEmailBanners() {
	function generateImageDownload(imagePath: string, imageName: string) {
		const link = document.createElement('a')
		link.href = imagePath
		link.download = `${imageName}.png`
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	return (
		<div className="flex-lg overflow-y__scroll flex-column">
			<div className="col-12 flex flex-justifyContent-center">
				<div className="col-xs-12 col-lg-8 pr-20-sm mb-20 flex flex-column flex-alignItems-center overflow-y__scroll flex-justifyContent-center">
					<Typography type="h3">Luxury Assets & Email Banners</Typography>
					<p style={{ textAlign: 'center' }}>
						These logos are the property of LoKation Real Estate and its subsidiaries.{' '}
						<span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
							These assets are to be used by subscribed LoKation Luxury Agents only.
						</span>
					</p>
					<Button
						size="md"
						variant="outlined"
						label="Download Logo Assets"
						onClick={() => {
							window.open(`${FileBaseURL}/images/luxury/Luxury-logos.zip`, '_blank')
						}}
					/>

					<HorizontalRule className="mt-20" />
				</div>
			</div>

			<Box flex="column" alignItems="center" className="col-12">
				<Typography type="h3" variant={['center']}>
					Email Signature & Newsletter Banners
				</Typography>
				<p style={{ textAlign: 'center' }}>
					The following banners can be copied, or downloaded, to be used for all emails and newsletters.
				</p>
			</Box>

			<div className="col-xs-12 mb-20 flex flex-wrap flex-justifyContent-spaceAround">
				<div className="col-12 col-md-6 p-10">
					<Box flex="column" alignItems="center" padding={['all']}>
						<img src={BrandSignatureDark} style={{ width: '100%' }} className="col-xs-12" />
						<Button
							size="md"
							variant="outlined"
							label="Download Banner"
							margins={['top']}
							onClick={() => {
								generateImageDownload(BrandSignatureDark, 'BrandSignatureDark')
							}}
						/>
					</Box>
					<Box flex="column" alignItems="center" padding={['all']}>
						<img src={ColoradoBannerDark} style={{ width: '100%' }} className="col-xs-12" />
						<Button
							size="md"
							variant="outlined"
							label="Download Banner"
							margins={['top']}
							onClick={() => {
								generateImageDownload(ColoradoBannerDark, 'ColoradoBannerDark')
							}}
						/>
					</Box>
					<Box flex="column" alignItems="center" padding={['all']}>
						<img src={LuxuryEmailBannerDark} style={{ width: '100%' }} className="col-xs-12" />
						<Button
							size="md"
							variant="outlined"
							label="Download Banner"
							margins={['top']}
							onClick={() => {
								generateImageDownload(LuxuryEmailBannerDark, 'LuxuryEmailBannerDark')
							}}
						/>
					</Box>
				</div>
				<div className="col-12 col-md-6 p-10">
					<Box flex="column" alignItems="center" padding={['all']}>
						<img src={BrandSignatureLight} style={{ width: '100%' }} className="col-xs-12" />
						<Button
							size="md"
							variant="outlined"
							label="Download Banner"
							margins={['top']}
							onClick={() => {
								generateImageDownload(BrandSignatureLight, 'BrandSignatureLight')
							}}
						/>
					</Box>
					<Box flex="column" alignItems="center" padding={['all']}>
						<img src={ColoradoBannerLight} style={{ width: '100%' }} className="col-xs-12" />
						<Button
							size="md"
							variant="outlined"
							label="Download Banner"
							margins={['top']}
							onClick={() => {
								generateImageDownload(ColoradoBannerLight, 'ColoradoBannerLight')
							}}
						/>
					</Box>
					<Box flex="column" alignItems="center" padding={['all']}>
						<img src={LuxuryEmailBannerLight} style={{ width: '100%' }} className="col-xs-12" />
						<Button
							size="md"
							variant="outlined"
							label="Download Banner"
							margins={['top']}
							onClick={() => {
								generateImageDownload(LuxuryEmailBannerLight, 'LuxuryEmailBannerLight')
							}}
						/>
					</Box>
				</div>
			</div>
		</div>
	)
}
