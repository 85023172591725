import { Badge } from '@components/badge/badge'
import { Button } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { Typography } from '@components/text/text'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { RoutingService } from 'src/services/routing/routing.service'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { TransactionMgmt__Document__SideSheet } from '../side-sheets/document/document.side-sheet'
import { Transaction__DocumentCard } from '../transaction/routes/overview/components/document-card'

export function TransactionMgmt__Landing__Section__UnreviewedDocs() {
	const navigate = useNavigate()

	const [unreviewedDocs, setUnreviewedDocs] = useState<TransactionMgmtTypes.DocumentTypes.Document[] | null>(null)
	const [showDocumentSideSheet, setShowDocumentSideSheet] = useState(false)
	const [selectedDocument, setSelectedDocument] = useState<TransactionMgmtTypes.DocumentTypes.Document | null>(null)

	useEffect(() => {
		TransactionManagementAPI.findDocuments().then((res) => {
			setUnreviewedDocs(res.data.filter((doc) => doc.status === 'PENDING'))
		})
	}, [])

	return (
		<>
			<div className="flex flex-alignItems-center mb-5">
				<Typography type="h6">Unreviewed Documents</Typography>
				<Badge value={55} size="sm" className="ml-10" />
			</div>
			{!unreviewedDocs && <GenericContentLoader height={55} width={'fill'} />}
			{unreviewedDocs &&
				unreviewedDocs.map((doc) => {
					return (
						<Transaction__DocumentCard
							document={doc}
							handleSelectItem={(thisDoc) => {
								setSelectedDocument(thisDoc)
								setShowDocumentSideSheet(true)
							}}
							removable={false}
							showNewFlag={false}
							key={doc.documentId}
						/>
					)
				})}
			<Button
				variant="text"
				label={'View All Documents'}
				size="md"
				className="mb-40"
				onClick={() => {
					navigate(`${RoutingService.transactionMgmtRoutePath}/forms`)
				}}
			/>
			{showDocumentSideSheet && selectedDocument && (
				<TransactionMgmt__Document__SideSheet
					document={selectedDocument}
					showParentTransaction={true}
					onClose={() => {
						setShowDocumentSideSheet(false)
					}}
					onUpdate={(savedTask) => {
						setShowDocumentSideSheet(false)
					}}
					onDelete={(taskId) => {
						setShowDocumentSideSheet(false)
					}}
				/>
			)}
		</>
	)
}
