import { Button } from '@components/button/button'
import { ColorChip } from '@components/color-chip/color-chip'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownTypes } from '@components/dropdown/dropdown.types'
import { ImageUploadTile } from '@components/image-upload-tile/image-upload-tile'
import { RadioGroup, RadioGroupOption } from '@components/radio-group/radio-group'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'

import { GoogleAPI } from '../../../services/google-api/google-api'
import { GoogleFont } from '../../../services/google-api/google-api.types'
import { DomainTheme } from '../../../services/theme/theme.types'
import { Utilities } from '../../../services/utilities.service'

interface ThemeAdministrationBodyProps {
	modifiedTheme: DomainTheme.Theme
	handleThemeModeChange: (mode: DomainTheme.ThemeMode) => void
	updateThemeProp: (key: keyof DomainTheme.Theme, value: string | number) => void
	handleResetTheme: () => void
}

export function ThemeAdministrationBody(props: ThemeAdministrationBodyProps) {
	const [availableFonts, setAvailableFonts] = useState<GoogleFont[] | null>(null)
	useEffect(() => {
		GoogleAPI.getFonts().then((res) => {
			res = res.slice(0, 100).sort((a, b) => (a.family > b.family ? 1 : -1))
			setAvailableFonts(res)
		})
	}, [])

	function mapFontsToOptions(): DropdownTypes.Option<string>[] {
		const options: DropdownTypes.Option<string>[] = []

		if (availableFonts) {
			availableFonts.forEach((font) => {
				const parentCategoryForFont = options.find((parentCategory) => parentCategory.value === font.category)

				/** Organize fonts into categories */
				if (!parentCategoryForFont) {
					options.push({
						label: Utilities.toTitleCase(font.category),
						value: font.category,
						children: [
							{
								label: font.family,
								value: font.family,
							},
						],
					})
				} else {
					if ('children' in parentCategoryForFont) {
						parentCategoryForFont.children.push({
							label: font.family,
							value: font.family,
						})
					}
				}
			})
		}

		return options
	}

	function mapFontWeightsToOptions(): DropdownTypes.Option<string>[] {
		const options: DropdownTypes.Option<string>[] = []

		options.push({
			label: 'Light',
			value: '300',
		})
		options.push({
			label: 'Normal',
			value: '400',
		})
		options.push({
			label: 'Medium',
			value: '500',
		})
		options.push({
			label: 'Semi-Bold',
			value: '600',
		})
		options.push({
			label: 'Bold',
			value: '700',
		})

		return options
	}

	function getModeOptions(): RadioGroupOption<DomainTheme.ThemeMode>[] {
		return [
			{
				value: 'LIGHT',
				label: (
					<div className="flex flex-column flex-justifyContent-center flex-alignItems-center">
						<FontAwesomeIcon
							icon={['far', 'sun']}
							style={{ width: '40px', height: '40px' }}
							className="mb-10 opacity-40"
						/>
						Light
					</div>
				),
			},
			{
				value: 'DARK',
				label: (
					<div className="flex flex-column flex-justifyContent-center flex-alignItems-center">
						<FontAwesomeIcon
							icon={['far', 'moon']}
							style={{ width: '40px', height: '40px' }}
							className="mb-10 opacity-40"
						/>
						Dark
					</div>
				),
			},
		]
	}

	function getBorderStyleOptions(): RadioGroupOption<DomainTheme.BorderStyle>[] {
		return [
			{
				value: 'SQUARE',
				label: (
					<div className="flex flex-column flex-justifyContent-center flex-alignItems-center">
						<div className="theme-admin__border-style square"></div>
						Square
					</div>
				),
			},
			{
				value: 'SOFT',
				label: (
					<div className="flex flex-column flex-justifyContent-center flex-alignItems-center">
						<div className="theme-admin__border-style soft"></div>
						Soft
					</div>
				),
			},
			{
				value: 'ROUND',
				label: (
					<div className="flex flex-column flex-justifyContent-center flex-alignItems-center">
						<div className="theme-admin__border-style round"></div>
						Round
					</div>
				),
			},
		]
	}

	function getBorderWidthOptions(): RadioGroupOption<DomainTheme.BorderWidth>[] {
		return [
			{
				value: 'THIN',
				label: (
					<div className="flex flex-column flex-justifyContent-center flex-alignItems-center">
						<div className="theme-admin__border-width thin"></div>
						Thin
					</div>
				),
			},
			{
				value: 'THICK',
				label: (
					<div className="flex flex-column flex-justifyContent-center flex-alignItems-center">
						<div className="theme-admin__border-width thick"></div>
						Thick
					</div>
				),
			},
		]
	}

	return (
		<>
			<strong>Mode</strong>
			<div className="flex mb-40">
				<RadioGroup<DomainTheme.ThemeMode>
					variant="LARGE"
					options={getModeOptions()}
					optionClassName="mr-10"
					value={props.modifiedTheme.mode}
					onChange={(value) => {
						props.handleThemeModeChange(value)
					}}
				/>
			</div>

			<strong>Border Style</strong>
			<div className="flex mb-40">
				<RadioGroup<DomainTheme.BorderStyle>
					variant="LARGE"
					options={getBorderStyleOptions()}
					optionClassName="mr-10"
					value={props.modifiedTheme.borderStyle}
					onChange={(value) => {
						props.updateThemeProp('borderStyle', value)
					}}
				/>
			</div>

			<strong>Border Width</strong>
			<div className="flex mb-40">
				<RadioGroup<DomainTheme.BorderWidth>
					variant="LARGE"
					options={getBorderWidthOptions()}
					optionClassName="mr-10"
					value={props.modifiedTheme.borderWidth}
					onChange={(value) => {
						props.updateThemeProp('borderWidth', value)
					}}
				/>
			</div>

			<Typography type="h4" margins={['bottom']}>
				Color
			</Typography>
			<div className="flex flex-wrap mb-40">
				<div className="mb-10">
					<strong>App Header</strong>
					<ColorChip
						color={props.modifiedTheme.appHeaderBgColor}
						handleChange={(color) => {
							props.updateThemeProp('appHeaderBgColor', color)
						}}
					/>
				</div>

				<div className="mb-10">
					<strong>App Tray</strong>
					<ColorChip
						color={props.modifiedTheme.appTrayBgColor}
						handleChange={(color) => {
							props.updateThemeProp('appTrayBgColor', color)
						}}
					/>
				</div>

				<div className="mb-10">
					<strong>Page Background</strong>
					<ColorChip
						color={props.modifiedTheme.pageBgColor}
						handleChange={(color) => {
							props.updateThemeProp('pageBgColor', color)
						}}
					/>
				</div>

				<div className="mb-10">
					<strong>Modal Background</strong>
					<ColorChip
						color={props.modifiedTheme.modalBgColor}
						handleChange={(color) => {
							props.updateThemeProp('modalBgColor', color)
						}}
					/>
				</div>

				<div className="mb-10">
					<strong>Resource Card</strong>
					<ColorChip
						color={props.modifiedTheme.resourceCardBgColor}
						handleChange={(color) => {
							props.updateThemeProp('resourceCardBgColor', color)
						}}
					/>
				</div>

				<div className="mb-10">
					<strong>Content Block</strong>
					<ColorChip
						color={props.modifiedTheme.contentBlockBgColor}
						handleChange={(color) => {
							props.updateThemeProp('contentBlockBgColor', color)
						}}
					/>
				</div>

				<div className="mb-10">
					<strong>Button</strong>
					<ColorChip
						color={props.modifiedTheme.buttonBgColor}
						handleChange={(color) => {
							props.updateThemeProp('buttonBgColor', color)
						}}
					/>
				</div>

				<div className="mb-10">
					<strong>Accent Color</strong>
					<ColorChip
						color={props.modifiedTheme.colorAccent}
						handleChange={(color) => {
							props.updateThemeProp('colorAccent', color)
						}}
					/>
				</div>

				<div className="mb-10">
					<strong>Warning Color</strong>
					<ColorChip
						color={props.modifiedTheme.colorWarning}
						handleChange={(color) => {
							props.updateThemeProp('colorWarning', color)
						}}
					/>
				</div>
			</div>

			<Typography type="h4" margins={['bottom']}>
				Fonts
			</Typography>
			{!availableFonts && (
				<div className="flex flex-alignItems-center p-20">
					<ContentLoader viewBox="0 0 380 80" backgroundColor="var(--colorAdjust40)">
						<rect x="0" y="0" rx="5" ry="5" width="100%" height="10" />
						<rect x="0" y="20" rx="5" ry="5" width="100%" height="10" />
						<rect x="0" y="40" rx="5" ry="5" width="100%" height="10" />
					</ContentLoader>
				</div>
			)}
			{availableFonts && (
				<>
					<Typography type="h6" margins={['bottom']}>
						Header
					</Typography>
					<div className="flex flex-wrap mb-20">
						<div className="mb-10 mr-5">
							<strong>Family</strong>
							<Dropdown
								value={[props.modifiedTheme.headerFontFamily]}
								width={200}
								searchable={true}
								options={mapFontsToOptions()}
								onSelect={(selectedFamilies) => {
									if (selectedFamilies && selectedFamilies.length > 0) {
										props.updateThemeProp('headerFontFamily', selectedFamilies[0])
									}
								}}
								maximizeHeight={true}
							/>
						</div>
						<div className="mb-10 mr-5">
							<strong>Size</strong>
							<TextInput
								dataType="number"
								minValue={6}
								maxValue={80}
								value={parseFloat(props.modifiedTheme.h1FontSize)}
								width={'100px'}
								postpend={<div>pt</div>}
								onChange={(value) => {
									props.updateThemeProp('h1FontSize', `${value}pt`)
								}}
							/>
						</div>

						<div className="mb-10 mr-5">
							<strong>Weight</strong>
							<Dropdown<string>
								value={[props.modifiedTheme.h1FontWeight]}
								width={150}
								options={mapFontWeightsToOptions()}
								onSelect={(selectedFamilies) => {
									if (selectedFamilies && selectedFamilies.length > 0) {
										props.updateThemeProp('h1FontWeight', selectedFamilies[0])
									}
								}}
							/>
						</div>
					</div>

					<Typography type="h6" margins={['bottom']}>
						Body
					</Typography>
					<div className="flex flex-wrap mb-40">
						<div className="mb-10 mr-5">
							<strong>Family</strong>
							<Dropdown
								value={[props.modifiedTheme.bodyFontFamily]}
								width={200}
								searchable={true}
								options={mapFontsToOptions()}
								onSelect={(selectedFamilies) => {
									if (selectedFamilies && selectedFamilies.length > 0) {
										props.updateThemeProp('bodyFontFamily', selectedFamilies[0])
									}
								}}
								maximizeHeight={true}
							/>
						</div>
						<div className="mb-10 mr-5">
							<strong>Size</strong>
							<TextInput
								dataType="number"
								minValue={6}
								maxValue={80}
								value={parseFloat(props.modifiedTheme.bodyFontSize)}
								width={'100px'}
								postpend={<div>pt</div>}
								onChange={(value) => {
									props.updateThemeProp('bodyFontSize', `${value}pt`)
								}}
							/>
						</div>

						<div className="mb-10 mr-5">
							<strong>Weight</strong>
							<Dropdown<string>
								value={[props.modifiedTheme.bodyFontWeight]}
								width={150}
								options={mapFontWeightsToOptions()}
								onSelect={(selectedFamilies) => {
									if (selectedFamilies && selectedFamilies.length > 0) {
										props.updateThemeProp('bodyFontWeight', selectedFamilies[0])
									}
								}}
							/>
						</div>
					</div>
				</>
			)}

			<Typography type="h6" margins={['bottom']}>
				Logo
			</Typography>
			<div className="flex flex-wrap mb-40">
				<div className="mr-10 mb-20">
					<strong>Logo</strong>
					<ImageUploadTile
						fileTypes="*.svg, *.png"
						style={{ backgroundColor: '#ffffff' }}
						image={props.modifiedTheme.logo}
						onUpload={(image) => {
							props.updateThemeProp('logo', image)
						}}
						onRemove={() => {
							props.updateThemeProp('logo', '')
						}}
					/>
				</div>

				<div className="mr-10 mb-20">
					<strong>Logo, White variant</strong>
					<ImageUploadTile
						fileTypes="*.svg, *.png"
						style={{ backgroundColor: '#666666' }}
						image={props.modifiedTheme.logoWhite}
						onUpload={(image) => {
							props.updateThemeProp('logoWhite', image)
						}}
						onRemove={() => {
							props.updateThemeProp('logoWhite', '')
						}}
					/>
				</div>

				<div className="mr-10 mb-20">
					<strong>Mark</strong>
					<ImageUploadTile
						fileTypes="*.svg, *.png"
						style={{ backgroundColor: '#ffffff' }}
						image={props.modifiedTheme.logoMark}
						onUpload={(image) => {
							props.updateThemeProp('logoMark', image)
						}}
						onRemove={() => {
							props.updateThemeProp('logoMark', '')
						}}
					/>
				</div>

				<div className="mr-10 mb-20">
					<strong>Mark, White variant</strong>
					<ImageUploadTile
						fileTypes="*.svg, *.png"
						style={{ backgroundColor: '#666666' }}
						image={props.modifiedTheme.logoMarkWhite}
						onUpload={(image) => {
							props.updateThemeProp('logoMarkWhite', image)
						}}
						onRemove={() => {
							props.updateThemeProp('logoMarkWhite', '')
						}}
					/>
				</div>
			</div>

			<Button
				variant="outlined"
				label="Reset Theme"
				size="md"
				marginSize="section"
				margins={['bottom']}
				onClick={() => {
					props.handleResetTheme()
				}}
			/>
		</>
	)
}
