import { Button } from '@components/button/button'
import { ContextMenu } from '@components/context-menu/context-menu'
import { ContextMenuTypes } from '@components/context-menu/context-menu.types'
import { CopyToClipboard } from 'src/services/copy-to-clipboard/copy-to-clipboard'
import { useContextMenu } from 'src/services/hooks/use-context-menu'

import { apiVersionURL, RequestBaseURL } from '../../../../services/axios/http-common.service'

interface DownloadCalendarButtonProps {
	selectedCalendarId: number | null
}

export function DownloadCalendarButton(props: DownloadCalendarButtonProps) {
	const { contextMenu } = useContextMenu()
	const googleCalUrl = 'https://calendar.google.com/calendar/u/0/r/settings/addbyurl?cid='

	function getOptions(): ContextMenuTypes.Option[] {
		const options: ContextMenuTypes.Option[] = []
		let calUrl: string
		if (props.selectedCalendarId == null) {
			calUrl = `${RequestBaseURL}${apiVersionURL}/calendars/calendar.ics`
		} else {
			calUrl = `${RequestBaseURL}${apiVersionURL}/calendars/${props.selectedCalendarId}/calendar.ics`
		}

		const handleCalendarClick = (url: string, calendarType: string) => {
			const webCalFormattedUrl = url.replace('http://', 'webcal://').replace('https://', 'webcal://')
			switch (calendarType) {
				case 'Apple':
					window.open(webCalFormattedUrl, '_blank')
					break
				case 'Google':
					window.open(googleCalUrl + webCalFormattedUrl, '_blank')
					break
				case 'default':
					CopyToClipboard(calUrl, 'STRING', 'Calendar URL copied to your clipboard!')
					break
			}
		}

		options.push({
			label: 'Apple',
			//icon: 'apple',
			onClick: () => handleCalendarClick(`${calUrl}`, 'Apple'),
		})

		options.push({
			label: 'Google Calendar',
			//icon: 'google',
			onClick: () => handleCalendarClick(`${calUrl}`, 'Google'),
		})

		options.push({
			label: 'Copy link to clipboard',
			//icon: 'copy',
			onClick: () => handleCalendarClick(`${calUrl}`, 'default'),
		})

		return options
	}

	return (
		<>
			<div className="mt-5">
				<Button
					variant="text"
					size="md"
					label="Sync with your calendar"
					onClick={(evt) => {
						contextMenu.set({ isVisible: true, x: evt.pageX, y: evt.pageY, clickTarget: evt.target })
					}}
				/>
			</div>
			<ContextMenu
				visible={contextMenu.isVisible}
				x={contextMenu.x}
				y={contextMenu.y}
				position={'absolute'}
				options={getOptions()}
				onDismiss={() => {
					contextMenu.setIsVisible(false)
				}}
			/>
		</>
	)
}
