import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import { ThinkificAPI } from '../../services/thinkific/thinkific.api'
import { ThinkificTypes } from '../../services/thinkific/thinkific.types'
import { Button } from '../button/button'
import { Paper } from '../paper/paper'

interface ThinkificExternalSSOProps {
	returnTo: ThinkificTypes.returnToOptions
	buttonLabel?: string
	description: string
	heading: string
}

function ThinkificExternalSSO(props: ThinkificExternalSSOProps) {
	const [externalLinkForSso, setExternalLinkForSso] = useState('')

	async function getSSO(returnTo: ThinkificTypes.returnToOptions) {
		try {
			const ssoUrl = await ThinkificAPI.getSsoUrl(returnTo)
			if (ssoUrl) {
				setExternalLinkForSso(ssoUrl)
				window.open(ssoUrl, '_blank')
			} else {
				console.log('No state has been selected or no SSO URLs were returned')
			}
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		if (externalLinkForSso) {
			setTimeout(() => {
				setExternalLinkForSso('')
			}, 3000)
		}
	}, [externalLinkForSso, props.returnTo])

	return (
		<>
			<Paper
				bgColor="primary"
				padding={['all']}
				margins={['bottom', 'right']}
				className="flex flex-column flex-alignItems-center"
			>
				<Typography type="h3">{props.heading}</Typography>
				<p>{props.description}</p>
				<Button
					variant="contained"
					size="lg"
					primary={true}
					label={props.buttonLabel || 'Sign On'}
					onClick={() => {
						getSSO(props.returnTo)
					}}
				/>
			</Paper>

			{externalLinkForSso &&
				ReactDOM.createPortal(
					<div className="modal__scrim">
						<div className="external-site-msg__wrapper">
							<FontAwesomeIcon icon={['far', 'globe']} size={`5x`} className={`mb-20 opacity-50`} />
							<Typography type="h5">Loading External Website</Typography>
							<div className="mt-10">
								If you are not redirected within 5 seconds,{' '}
								<a href={externalLinkForSso} target="_blank" rel="noopener noreferrer">
									click here
								</a>
								.
							</div>
						</div>
					</div>,
					document.body,
				)}
		</>
	)
}

export default ThinkificExternalSSO
