import { ContextMenuTypes } from '@components/context-menu/context-menu.types'
import { useNavigate } from 'react-router'
import { useContextMenu } from 'src/services/hooks/use-context-menu'

import { ContextMenu } from '../../context-menu/context-menu'
import { AppTrayTypes } from '../app-tray.types'
import { DesktopAppTrayButton } from './app-tray__button'

interface DesktopAppTrayOverflowHubsButtonProps {
	isTrayExpanded: boolean
	isTrayDocked: boolean
	hubLinks: AppTrayTypes.Link[]
}

export function DesktopAppTrayOverflowHubsButton(props: DesktopAppTrayOverflowHubsButtonProps) {
	const { contextMenu } = useContextMenu()
	const navigate = useNavigate()

	function getOverflowContextMenuOptions(): ContextMenuTypes.Option[] {
		return props.hubLinks.map((link) => {
			return {
				label: link.label,
				onClick: () => {
					if ('path' in link) {
						navigate(link.path)
					}
				},
			}
		})
	}

	return (
		<div>
			<DesktopAppTrayButton
				icon={'ellipsis'}
				label={'More'}
				expanded={props.isTrayExpanded || props.isTrayDocked}
				onClick={(evt) => {
					contextMenu.set({ isVisible: true, x: evt.pageX, y: evt.pageY })
				}}
			/>

			<ContextMenu
				position={`absolute`}
				x={contextMenu.x}
				y={contextMenu.y}
				visible={contextMenu.isVisible}
				options={getOverflowContextMenuOptions()}
				onDismiss={() => {
					contextMenu.setIsVisible(false)
				}}
			/>
		</div>
	)
}
