import { Button } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { SideSheet } from '@components/side-sheet/side-sheet'
import { SideSheetServices } from '@components/side-sheet/side-sheet.service'
import { SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import { SideSheet__Row } from '@components/side-sheet/side-sheet__row'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { PhoneInput } from '@components/text-input/phone-input'
import { TextInput } from '@components/text-input/text-input'
import { useState } from 'react'
import { GenericDeleteConfirmationModal } from 'src/modals/generic-delete-confirmation/generic-delete-confirmation'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { TransactionMgmt__ContactService } from './contact.service'

export namespace TransactionMgmt__TransactionContact__SideSheetTypes {
	export type Component = {
		onClose: () => void
		onUpdate: (updatedContact: TransactionMgmtTypes.TransactionTypes.Contact) => void
		onDelete: (contactId: number) => void
		contact: TransactionMgmtTypes.TransactionTypes.Contact
		parentTransactionId: number
	}
}

export function TransactionMgmt__TransactionContact__SideSheet(
	props: TransactionMgmt__TransactionContact__SideSheetTypes.Component,
) {
	const [modifiedProps, setModifiedProps] = useState<Partial<TransactionMgmtTypes.TransactionTypes.Contact>>({})
	const mergedModifiedProps = { ...props.contact, ...modifiedProps }
	const didUserModifyProps = Object.keys(modifiedProps).length > 0
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
	const isValidated = getIsValidated()

	function createFooter(): React.ReactNode {
		return (
			<>
				<Button
					variant={'outlined'}
					label={'Cancel'}
					size={'md'}
					className="mr-10"
					onClick={async () => {
						props.onClose()
					}}
				/>
				<Button
					variant={'contained'}
					label={'Save and Close'}
					size={'md'}
					disabled={!isValidated}
					onClick={async () => {
						const res = await handleSaveContact()
						props.onUpdate(res)
					}}
				/>
			</>
		)
	}

	function createActions(): SideSheetTypes.Action[] {
		const actions: SideSheetTypes.Action[] = []

		if (!modifiedProps) {
			return []
		}

		if (modifiedProps.contactId !== -1) {
			actions.push({
				label: `Delete`,
				icon: 'trash',
				onClick: () => {
					setShowDeleteConfirmationModal(true)
				},
			})
		}

		return actions
	}

	function onScrimClick(): void {
		if (!didUserModifyProps) {
			props.onClose()
			return
		}

		SideSheetServices.showDismissConfirmationToast({
			onDismiss: () => {
				return new Promise((resolve) => {
					resolve()
					props.onClose()
				})
			},
			onSaveAndClose: async () => {
				const res = await handleSaveContact()
				props.onUpdate(res)
			},
		})
	}

	function handleSaveContact(): Promise<TransactionMgmtTypes.TransactionTypes.Contact> {
		return new Promise((resolve, reject) => {
			if (!mergedModifiedProps) {
				reject()
				return
			}

			if (mergedModifiedProps.contactId < 0) {
				TransactionManagementAPI.addContactToTransaction(props.parentTransactionId, mergedModifiedProps).then(
					(res) => {
						resolve(res.data)
					},
				)
			} else {
				TransactionManagementAPI.updateContactOnTransaction(
					props.parentTransactionId,
					mergedModifiedProps,
				).then((res) => {
					resolve(res.data)
				})
			}
		})
	}

	function updateProperties(updatedProps: Partial<TransactionMgmtTypes.TransactionTypes.Contact>): void {
		const updatedContact: Partial<TransactionMgmtTypes.TransactionTypes.Contact> = {
			...modifiedProps,
			...updatedProps,
		}
		setModifiedProps(updatedContact)
	}

	function getIsValidated(): boolean {
		if (!mergedModifiedProps) {
			return false
		}

		if (
			!TransactionMgmt__ContactService.Validations.fullName().isValid(mergedModifiedProps.fullName, false).isValid
		) {
			return false
		}

		if (!TransactionMgmt__ContactService.Validations.title().isValid(mergedModifiedProps.title, false).isValid) {
			return false
		}

		if (
			!TransactionMgmt__ContactService.Validations.organization().isValid(mergedModifiedProps.organization, false)
				.isValid
		) {
			return false
		}

		if (!TransactionMgmt__ContactService.Validations.email().isValid(mergedModifiedProps.email, false).isValid) {
			return false
		}

		if (!TransactionMgmt__ContactService.Validations.phone().isValid(mergedModifiedProps.phone, false).isValid) {
			return false
		}

		return true
	}

	return (
		<>
			<SideSheet
				title={'Contact'}
				actions={createActions()}
				onClose={props.onClose}
				persistent={false}
				footer={createFooter()}
				preventDefaultScrimClick={didUserModifyProps}
				onScrimClick={onScrimClick}
			>
				{mergedModifiedProps && (
					<>
						<SideSheet__Section
							label="General"
							icon={'cog'}
							mode="expandable"
							sectionKey="contact__general"
						>
							<SideSheet__Row label="Full Name" direction="column">
								<TextInput
									type="text"
									dataType="text"
									value={mergedModifiedProps.fullName}
									width={'380px'}
									onChange={(updatedValue) => {
										updateProperties({ fullName: updatedValue })
									}}
									validation={TransactionMgmt__ContactService.Validations.fullName()}
								/>
							</SideSheet__Row>
							<SideSheet__Row label="Title" direction="column">
								<TextInput
									type="text"
									dataType="text"
									value={mergedModifiedProps.title}
									width={'380px'}
									onChange={(updatedValue) => {
										updateProperties({ title: updatedValue })
									}}
									validation={TransactionMgmt__ContactService.Validations.title()}
								/>
							</SideSheet__Row>
							<SideSheet__Row label="Organization" direction="column">
								<TextInput
									type="text"
									dataType="text"
									value={mergedModifiedProps.organization}
									width={'380px'}
									onChange={(updatedValue) => {
										updateProperties({ organization: updatedValue })
									}}
									validation={TransactionMgmt__ContactService.Validations.organization()}
								/>
							</SideSheet__Row>
							<SideSheet__Row label="Email Address" direction="column">
								<TextInput
									type="text"
									dataType="text"
									value={mergedModifiedProps.email}
									width={'380px'}
									onChange={(updatedValue) => {
										updateProperties({ email: updatedValue })
									}}
									validation={TransactionMgmt__ContactService.Validations.email()}
								/>
							</SideSheet__Row>
							<SideSheet__Row label="Phone Number" direction="column">
								<PhoneInput
									type="text"
									value={mergedModifiedProps.phone}
									width={'380px'}
									onChange={(updatedValue) => {
										if (updatedValue) {
											updateProperties({ phone: updatedValue })
										}
									}}
									dataType={'number'}
									validation={TransactionMgmt__ContactService.Validations.phone()}
								/>
							</SideSheet__Row>
							<SideSheet__Row label="Type" direction="column">
								<Dropdown<TransactionMgmtTypes.TransactionTypes.ContactType>
									width={'380px'}
									options={[
										{
											label: TransactionManagementService.getLabelForContactType('BUYER'),
											value: 'BUYER',
										},
										{
											label: TransactionManagementService.getLabelForContactType('SELLER'),
											value: 'SELLER',
										},
										{
											label: TransactionManagementService.getLabelForContactType('OTHER'),
											value: 'OTHER',
										},
									]}
									value={[mergedModifiedProps.type]}
									onSelect={(selectedType) => {
										updateProperties({ type: selectedType[0] })
									}}
								/>
							</SideSheet__Row>
						</SideSheet__Section>
					</>
				)}
			</SideSheet>

			{showDeleteConfirmationModal && mergedModifiedProps && (
				<GenericDeleteConfirmationModal
					itemLabel={mergedModifiedProps.fullName}
					onDelete={async () => {
						await TransactionManagementAPI.deleteContactFromTransaction(
							props.parentTransactionId,
							mergedModifiedProps.contactId,
						)
						setShowDeleteConfirmationModal(false)
						props.onDelete(mergedModifiedProps.contactId)
					}}
					onClose={() => {
						setShowDeleteConfirmationModal(false)
					}}
				/>
			)}
		</>
	)
}
