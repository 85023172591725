/* eslint-disable react/jsx-pascal-case */
import { Route } from 'react-router'
import { UserService } from 'src/services/user/user.service'
import { EndUserProps } from 'src/services/user/user.types'

import { RoutingService } from '../../services/routing/routing.service'
import { RouteProperties } from '../../services/routing/routing.types'
import { PrimaryRootRoute } from '../authenticated-root/authenticated-root'
import { ErrorPage } from '../error.page'
import { AdvertisingAccountRouteWrapper } from './ad-account/ad-account'
import { AdvertisingAccount__Route__Advertisements } from './ad-account/routes/advertisements/ad-account__advertisements'
import { AdvertisingAccount__Route__Billing } from './ad-account/routes/billing/ad-account__billing'
import { AdvertisingAccount__Route__General } from './ad-account/routes/general/ad-account__general'
import { AdministrationHomeRoute } from './administration-home'
import { AdvertisingAdministrationRoute } from './advertising/advertising__admin'
import { ApiStatusAdministrationRoute } from './api-status/api-status-administration'
import { CalendarAdministrationRoute } from './calendar/calendar-administration'
import { DomainAdminProviderWrapper } from './domain/domain-provider'
import { FAQAdministrationRoute } from './faq/faq-administration'
import { FormAdministrationRoute } from './form/form-administration'
import { HubAdministrationRoute } from './hub/hub-administration'
import { LeadsAdministrationRoute } from './lead/lead-administration'
import { MessagesAdminProviderWrapper } from './messages/messages-admin__provider'
import { NewsFeedAdministrationRoute } from './news-feed/news-feed-administration'
import { ResourceAdministrationRoute } from './resources/resources-administration'
import { StripeAdministrationRoute } from './stripe/stripe-administration'
import { TagAdministrationRoute } from './tags/tag-administration'
import { TeamMembersRouteWrapper } from './team/members/team-members-wrapper'
import { TeamsAdministrationRoute } from './team/teams___admin'
import { ThemeAdministrationRoute } from './theme/theme-administration'
import { UserAdministrationRoute } from './user/user-administration'
import { UserProfileRoute } from './user-profile/user-profile-provider'

export namespace AdministrationRouting {
	/** All of the top-level routes inside Administration */
	export function getRouteProperties(props: { user: EndUserProps | null }): RouteProperties[] {
		return [
			{
				label: 'Advertising',
				description: 'Manage accounts and advertisements shown on this site',
				href: `${RoutingService.settingsRoutePath}/advertising`,
				icon: 'money-bill',
				routeBody: <AdvertisingAdministrationRoute />,
				visible: props.user ? UserService.isUserSuperAdmin(props.user) : false,
			},
			{
				label: 'API Status Management',
				description: 'Monitor the status of API integrations',
				href: `${RoutingService.settingsRoutePath}/api-status-management`,
				icon: 'circle-nodes',
				routeBody: <ApiStatusAdministrationRoute />,
				visible: props.user ? UserService.isUserSuperAdmin(props.user) : false,
			},
			{
				label: 'Stripe',
				description: 'Manage Stripe elements, such as coupons, etc.',
				href: `${RoutingService.settingsRoutePath}/stripe/coupons`,
				icon: 'credit-card-front',
				routeBody: <StripeAdministrationRoute />,
				visible: props.user ? UserService.isUserSuperAdmin(props.user) : false,
			},
			{
				label: 'Calendar',
				description: 'Create events for the community calendar',
				href: `${RoutingService.settingsRoutePath}/calendar`,
				icon: 'calendar',
				routeBody: <CalendarAdministrationRoute />,
				visible: props.user ? UserService.isUserAdmin(props.user) : false,
			},
			{
				label: 'Domain',
				description: 'Edit general domain settings',
				href: `${RoutingService.settingsRoutePath}/domain`,
				icon: 'globe',
				routeBody: <DomainAdminProviderWrapper />,
				visible: props.user ? UserService.isUserSuperAdmin(props.user) : false,
			},
			{
				label: 'FAQs',
				description: 'Manage Frequently Asked Questions',
				href: `${RoutingService.settingsRoutePath}/faq`,
				icon: 'question-circle',
				routeBody: <FAQAdministrationRoute />,
				visible: props.user ? UserService.isUserAdmin(props.user) : false,
			},
			{
				label: 'Forms',
				description: 'Manage forms',
				href: `${RoutingService.settingsRoutePath}/forms`,
				icon: 'input-text',
				routeBody: <FormAdministrationRoute />,
				visible: props.user ? UserService.isUserSuperAdmin(props.user) : false,
			},
			{
				label: 'Hubs',
				description: 'Manage hubs',
				href: `${RoutingService.settingsRoutePath}/hubs`,
				icon: 'anchor',
				routeBody: <HubAdministrationRoute />,
				visible: props.user ? UserService.isUserSuperAdmin(props.user) : false,
			},
			{
				label: 'Leads',
				description: 'Manage leads and conversions',
				href: `${RoutingService.settingsRoutePath}/leads`,
				icon: 'chart-line',
				routeBody: <LeadsAdministrationRoute />,
				visible: props.user ? UserService.isUserAdmin(props.user) : false,
			},
			{
				label: 'Messages',
				description: 'Send messages to users in your domain',
				href: `${RoutingService.settingsRoutePath}/messages`,
				icon: 'message',
				routeBody: <MessagesAdminProviderWrapper />,
				visible: props.user ? UserService.isUserSuperAdmin(props.user) : false,
			},

			{
				label: 'News Feed',
				description: 'Create and edit stories shown in the news feed',
				href: `${RoutingService.settingsRoutePath}/news-feed`,
				icon: 'newspaper',
				routeBody: <NewsFeedAdministrationRoute />,
				visible: props.user ? UserService.isUserSuperAdmin(props.user) : false,
			},
			{
				label: 'Resources',
				description: 'Manage resources',
				href: `${RoutingService.settingsRoutePath}/resources`,
				icon: 'link',
				routeBody: <ResourceAdministrationRoute />,
				visible: props.user ? UserService.isUserSuperAdmin(props.user) : false,
			},

			{
				label: 'Tags',
				description: 'Manage tags applied to resources, FAQs, etc.',
				href: `${RoutingService.settingsRoutePath}/tag`,
				icon: 'tag',
				routeBody: <TagAdministrationRoute />,
				visible: props.user ? UserService.isUserSuperAdmin(props.user) : false,
			},
			{
				label: 'Teams',
				description: 'Manage teams and users who are associated with teams.',
				href: `${RoutingService.settingsRoutePath}/teams`,
				icon: 'people-group',
				routeBody: <TeamsAdministrationRoute />,
				visible: props.user ? UserService.isUserSuperAdmin(props.user) : false,
			},
			{
				label: 'Theme',
				description: 'Update application themed used throughout platform',
				href: `${RoutingService.settingsRoutePath}/theme`,
				icon: 'palette',
				routeBody: <ThemeAdministrationRoute />,
				visible: props.user ? UserService.isUserSuperAdmin(props.user) : false,
			},
			{
				label: 'Users',
				description: 'Manage users',
				href: `${RoutingService.settingsRoutePath}/users`,
				icon: 'users',
				routeBody: <UserAdministrationRoute />,
				visible: props.user ? UserService.isUserAdmin(props.user) : false,
			},
		]
	}

	export function getRoutes(props: { user: EndUserProps | null }): React.ReactElement {
		return (
			<Route
				path="/administration"
				element={<PrimaryRootRoute routeSection="SETTINGS" />}
				errorElement={<ErrorPage />}
			>
				<Route index={true} path="" element={<AdministrationHomeRoute />}></Route>
				<Route path="users/:userId" element={<UserProfileRoute />}></Route>
				<Route path="ad-account/:accountId" element={<AdvertisingAccountRouteWrapper />}>
					<Route path="general" element={<AdvertisingAccount__Route__General />}></Route>
					<Route path="advertisements" element={<AdvertisingAccount__Route__Advertisements />}></Route>
					<Route path="billing" element={<AdvertisingAccount__Route__Billing />}></Route>
				</Route>
				<Route path="team/:realEstateTeamId" element={<TeamMembersRouteWrapper />} />
				<>
					{getRouteProperties({ user: props.user }).map((route) => {
						const href = typeof route.href === 'string' ? route.href : route.href()
						return (
							<Route
								path={href}
								element={route.routeBody}
								loader={route.loader ? route.loader : undefined}
								key={href}
							></Route>
						)
					})}
				</>
			</Route>
		)
	}
}
