import './toast.scss'

import { Button } from '@components/button/button'
import { ButtonTypes } from '@components/button/button.types'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { toast, ToastOptions, UpdateOptions } from 'react-toastify'

import { Utilities } from '../utilities.service'
import { ToastServiceTypes } from './toast.types'

export namespace ToastService {
	/** Updates an existing toast */
	export function update(props: {
		toastId: string
		type: ToastServiceTypes.ToastType
		body: string | React.ReactNode
		buttons?: ButtonTypes.Component[]
		options?: ToastOptions
	}): ToastServiceTypes.ToastInstance {
		const toastBody = createToastBody({
			type: props.type,
			body: props.body,
			buttons: props.buttons,
		})

		toast.update(props.toastId, {
			render: toastBody,
		})

		return {
			id: props.toastId,
			dismiss: () => {
				toast.dismiss(props.toastId)
			},
			update: (options: UpdateOptions) => toast.update(props.toastId, options),
		}
	}

	/** Creates a new toast */
	export function create(props: {
		type: ToastServiceTypes.ToastType
		body: string | React.ReactNode | string[]
		buttons?: ButtonTypes.Component[]
		options?: ToastOptions
	}): ToastServiceTypes.ToastInstance {
		const toastBody = createToastBody({
			type: props.type,
			body: props.body,
			buttons: props.buttons,
		})

		const toastOptions: ToastOptions = {
			position: 'bottom-right',
			hideProgressBar: true,
			...props.options,
		}

		const toastId = toast(toastBody, toastOptions)

		return {
			id: toastId,
			dismiss: () => {
				toast.dismiss(toastId)
			},
			update: (options: UpdateOptions) => toast.update(toastId, options),
		}
	}

	/** Creates a toast that notifies the user of what just happened, giving them the ability to undo that action */
	export function createUndoToast(props: { body: string; onUndo: () => void }): void {
		/** Dismiss all previous toasts so the user can't accidentally undo the wrong version of the previous state */
		toast.dismiss()

		const toastId = Utilities.generateRandomInt(1, 100000)
		const toastBody = (
			<div className="toast__body flex flex-alignItems-center flex-justifyContent-spaceBetween">
				<div>{props.body}</div>
				<Button
					variant="text"
					size="md"
					className="is-on-toast"
					label={`Undo`}
					onClick={async () => {
						props.onUndo()
						toast.dismiss(toastId)
					}}
				/>
			</div>
		)

		toast(toastBody, { toastId })
	}

	function createToastBody(props: {
		type: ToastServiceTypes.ToastType
		body: string | React.ReactNode
		buttons?: ButtonTypes.Component[]
	}): React.ReactNode {
		let icon: IconName
		let color: string
		switch (props.type) {
			case 'INFO':
				icon = 'circle-info'
				color = 'var(--colorAdjust40)'
				break
			case 'SUCCESS':
				icon = 'circle-check'
				color = 'var(--paletteColorSuccessLight)'
				break
			case 'WARNING':
				icon = 'triangle-exclamation'
				color = 'var(--paletteColorWarningLight)'
				break
			case 'ERROR':
				icon = 'circle-exclamation'
				color = 'var(--paletteColorWarningLight)'
				break
			case 'SPINNER':
				icon = 'spinner'
				color = 'var(--colorAdjust40)'
				break
		}

		return (
			<div className="toast__body">
				<div className="toast__icon">
					<FontAwesomeIcon icon={['far', icon]} spin={props.type === 'SPINNER'} color={color} />
				</div>
				<div className="flex-fillSpace">
					{Array.isArray(props.body) && <>{props.body.join(', ')}</>}
					{!Array.isArray(props.body) && <div>{props.body}</div>}
					{props.buttons && props.buttons.length > 0 && (
						<div className="flex flex-wrap mt-10">
							{props.buttons.map((buttonProps) => {
								return (
									<Button
										margins={['right']}
										className={'low-contrast-with-theme'}
										{...buttonProps}
									/>
								)
							})}
						</div>
					)}
				</div>
			</div>
		)
	}
}
