import { StateFilter } from '@components/state-filter/state-filter'
import { TextInput } from '@components/text-input/text-input'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'
import { LicensedStatesService } from 'src/services/licensed-states/licensed-states.service'
import { LicensedState } from 'src/services/licensed-states/licenses-states.types'

import { DocumentStatusFilter } from '../shared/document-status-filter/document-status-filter'
import { TransactionDateFilter } from '../shared/transaction-date-filter/transaction-date-filter'
import { TransactionMgmt__Documents__Types } from './documents.types'

type ComponentProps = {
	setSearchFilters: React.Dispatch<React.SetStateAction<TransactionMgmt__Documents__Types.SearchFilters>>
	searchFilters: TransactionMgmt__Documents__Types.SearchFilters
}

type ConnectedProps = {
	licensedStates: LicensedState[]
}

function TransactionMgmt__Forms__SearchFilters__Prototype(props: ComponentProps & ConnectedProps) {
	const selectedStates = LicensedStatesService.getStatesFromIds(
		props.licensedStates,
		props.searchFilters.licensedStateIds,
	)

	function updateSearchFilters(updatedProps: Partial<TransactionMgmt__Documents__Types.SearchFilters>): void {
		const updatedFilters: TransactionMgmt__Documents__Types.SearchFilters = {
			...props.searchFilters,
			...updatedProps,
		}
		props.setSearchFilters(updatedFilters)
	}

	return (
		<>
			<TextInput
				label="Search"
				width={`100%`}
				dataType={'text'}
				onChange={(updatedValue) => {
					updateSearchFilters({ searchString: updatedValue })
				}}
				value={props.searchFilters.searchString}
				margins={['bottom']}
				placeholder="Address, Agent, Etc."
			/>

			<TransactionDateFilter
				filterProps={{
					includeCloseDate: props.searchFilters.includeCloseDate,
					includeDateAdded: props.searchFilters.includeDateAdded,
					dateAddedEnd: props.searchFilters.dateAddedEnd,
					dateAddedStart: props.searchFilters.dateAddedStart,
					closeDateEnd: props.searchFilters.closeDateEnd,
					closeDateStart: props.searchFilters.closeDateStart,
				}}
				onUpdateProps={(updatedProps) => {
					updateSearchFilters(updatedProps)
				}}
			/>

			<StateFilter
				selectedStates={selectedStates}
				onSelect={(selectedStates) => {
					updateSearchFilters({ licensedStateIds: selectedStates.map((state) => state.licensedStateId) })
				}}
				isMulti={false}
				className="mb-20"
			/>

			<DocumentStatusFilter
				selectedStatus={props.searchFilters.status}
				onSelect={(selectedStatus) => {
					updateSearchFilters({ status: selectedStatus })
				}}
				isMulti={false}
				className="mb-20"
			/>
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		licensedStates: state.licensedStates,
	}
}

export const TransactionMgmt__Forms__SearchFilters = connect(mapStateToProps)(
	TransactionMgmt__Forms__SearchFilters__Prototype,
)
