import { Button } from '@components/button/button'

import { TransactionMgmt__TaskCard } from '../../shared/task-card/task-card'

type ComponentProps = {
	taskIds: number[]
}

export function TransactionAdmin__ChecklistEditor__ChildTasks(props: ComponentProps) {
	return (
		<>
			{props.taskIds.map((taskId) => {
				return <TransactionMgmt__TaskCard taskId={taskId} />
			})}
			<Button
				variant="outlined"
				size="md"
				label="Link Task"
				onClick={() => {
					alert(`Link Task`)
				}}
			/>
		</>
	)
}
