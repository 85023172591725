import { Chip } from '@components/chip/chip'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

type ComponentProps = {
	locationId: number
}

/** Chip that receives the ID of a location and queries for complete information about that location. Opens a popover on click */
export function LocationChip(props: ComponentProps) {
	const [searchParams, setSearchParams] = useSearchParams()
	const [locationProps, setLocationProps] = useState<null | TransactionMgmtTypes.LocationTypes.Location>(null)

	useEffect(() => {
		TransactionManagementAPI.getLocationById(props.locationId).then((res) => {
			setLocationProps(res.data)
		})
	}, [])

	return (
		<Chip
			label={locationProps ? `${locationProps.name}` : `...`}
			color="var(--colorAdjust50)"
			onClick={() => {
				if (locationProps) {
					searchParams.set('location', String(props.locationId))
					setSearchParams(searchParams)
				}
			}}
		/>
	)
}
