import { Button } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownTypes } from '@components/dropdown/dropdown.types'
import { SideSheet } from '@components/side-sheet/side-sheet'
import { SideSheetServices } from '@components/side-sheet/side-sheet.service'
import { SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import { SideSheet__Row } from '@components/side-sheet/side-sheet__row'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { TextInput } from '@components/text-input/text-input'
import { ToggleButton } from '@components/toggle-button/toggle-button'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

export namespace TransactionMgmt__GeneralInfo__SideSheetTypes {
	export type Component = {
		onClose: () => void
		onUpdate: (updatedContact: TransactionMgmtTypes.TransactionTypes.Transaction) => void
		onDelete: () => void
		transaction: TransactionMgmtTypes.TransactionTypes.Transaction
	}
}

export function TransactionMgmt__GeneralInfo__SideSheet(props: TransactionMgmt__GeneralInfo__SideSheetTypes.Component) {
	const [modifiedTransaction, setModifiedTransction] = useState<
		Partial<TransactionMgmtTypes.TransactionTypes.Transaction>
	>({})
	const [allLocations, setAllLocations] = useState<TransactionMgmtTypes.LocationTypes.Location[] | null>(null)

	const mergedModifiedAndOriginalProps = { ...props.transaction, ...modifiedTransaction }
	const didUserModifyProps = Object.keys(modifiedTransaction).length > 0
	const acceptedDateAsISO = DateTime.fromMillis(mergedModifiedAndOriginalProps.acceptedDate).toISODate()
	const closeDateAsISO = DateTime.fromMillis(mergedModifiedAndOriginalProps.closeDate).toISODate()

	useEffect(() => {
		TransactionManagementAPI.findLocations().then((res) => {
			setAllLocations(res.data)
		})
	}, [])

	function createFooter(): React.ReactNode {
		return (
			<>
				<Button
					variant={'outlined'}
					label={'Cancel'}
					size={'md'}
					className="mr-10"
					onClick={async () => {
						props.onClose()
					}}
				/>
				<Button
					variant={'contained'}
					label={'Save and Close'}
					size={'md'}
					onClick={async () => {
						await handleSaveTransaction()
						if (mergedModifiedAndOriginalProps) {
							props.onClose()
							props.onUpdate(mergedModifiedAndOriginalProps)
						}
					}}
				/>
			</>
		)
	}

	function createActions(): SideSheetTypes.Action[] {
		const actions: SideSheetTypes.Action[] = []
		return actions
	}

	function onScrimClick(): void {
		if (!didUserModifyProps) {
			props.onClose()
			return
		}

		SideSheetServices.showDismissConfirmationToast({
			onDismiss: () => {
				return new Promise((resolve) => {
					resolve()
					props.onClose()
				})
			},
			onSaveAndClose: () => {
				return handleSaveTransaction().then(() => {
					if (mergedModifiedAndOriginalProps) {
						props.onClose()
						props.onUpdate(mergedModifiedAndOriginalProps)
					}
				})
			},
		})
	}

	function handleSaveTransaction(): Promise<void> {
		return new Promise((resolve) => {
			if (mergedModifiedAndOriginalProps && mergedModifiedAndOriginalProps.transactionId < 0) {
				/** Create New Transaction */
			} else {
				TransactionManagementAPI.patchTransaction(
					props.transaction.transactionId,
					mergedModifiedAndOriginalProps,
				).then((res) => {
					resolve()
					props.onUpdate(res.data)
					props.onClose()
				})
			}
		})
	}

	function updateTransaction(updatedProps: Partial<TransactionMgmtTypes.TransactionTypes.Transaction>): void {
		if (!mergedModifiedAndOriginalProps) {
			return
		}
		const updatedTransaction: TransactionMgmtTypes.TransactionTypes.Transaction = {
			...mergedModifiedAndOriginalProps,
			...updatedProps,
		}
		setModifiedTransction(updatedTransaction)
	}

	function getLocationOptions(): DropdownTypes.Option<number>[] {
		const options: DropdownTypes.Option<number>[] = []

		if (allLocations) {
			allLocations.forEach((location) => {
				options.push({
					label: location.name,
					value: location.locationId,
				})
			})
		}

		return options
	}

	function getTransactionTypeOptions(): DropdownTypes.Option<TransactionMgmtTypes.TransactionTypes.Type>[] {
		const options: DropdownTypes.Option<TransactionMgmtTypes.TransactionTypes.Type>[] = []
		TransactionManagementService.getAllTransactionTypes().forEach((type) => {
			options.push({
				label: type.label,
				value: type.value,
			})
		})
		return options
	}

	function getTransactionSideOptions(): DropdownTypes.Option<TransactionMgmtTypes.TransactionTypes.Side>[] {
		const options: DropdownTypes.Option<TransactionMgmtTypes.TransactionTypes.Side>[] = []
		TransactionManagementService.getAllTransactionSides().forEach((side) => {
			options.push({
				label: side.label,
				value: side.value,
			})
		})
		return options
	}

	return (
		<>
			<SideSheet
				title={'General Info'}
				actions={createActions()}
				onClose={props.onClose}
				persistent={false}
				footer={createFooter()}
				preventDefaultScrimClick={didUserModifyProps}
				onScrimClick={onScrimClick}
			>
				{mergedModifiedAndOriginalProps && (
					<>
						<SideSheet__Section
							label="General"
							icon={'cog'}
							mode="expandable"
							sectionKey="transaction__general"
						>
							<SideSheet__Row label="Name" direction="column">
								<TextInput
									type="text"
									dataType="text"
									value={mergedModifiedAndOriginalProps.name}
									width={'100%'}
									onChange={(updatedValue) => {
										updateTransaction({ name: updatedValue })
									}}
								/>
							</SideSheet__Row>
							<SideSheet__Row label="Status" direction="row">
								<ToggleButton<TransactionMgmtTypes.TransactionTypes.Status>
									options={[
										{
											label: 'Active',
											value: 'ACTIVE',
										},
										{
											label: 'Inactive',
											value: 'INACTIVE',
										},
									]}
									selectedOption={mergedModifiedAndOriginalProps.status}
									onSelect={(newState) => {
										updateTransaction({ status: newState })
									}}
								/>
							</SideSheet__Row>
							<SideSheet__Row label="Location" direction="row">
								<Dropdown<number>
									value={[mergedModifiedAndOriginalProps.locationId]}
									width={'300px'}
									options={getLocationOptions()}
									onSelect={(selectedOptions) => {
										updateTransaction({ locationId: selectedOptions[0] })
									}}
								/>
							</SideSheet__Row>
							<SideSheet__Row label="Type" direction="row">
								<Dropdown<TransactionMgmtTypes.TransactionTypes.Type>
									value={[mergedModifiedAndOriginalProps.type]}
									width={'300px'}
									options={getTransactionTypeOptions()}
									onSelect={(selectedOptions) => {
										updateTransaction({ type: selectedOptions[0] })
									}}
								/>
							</SideSheet__Row>
							<SideSheet__Row label="Side" direction="row">
								<Dropdown<TransactionMgmtTypes.TransactionTypes.Side>
									value={[mergedModifiedAndOriginalProps.side]}
									width={'300px'}
									options={getTransactionSideOptions()}
									onSelect={(selectedOptions) => {
										updateTransaction({ side: selectedOptions[0] })
									}}
								/>
							</SideSheet__Row>
							<SideSheet__Row label="MLS Number" direction="row">
								<TextInput
									type="text"
									dataType="text"
									value={mergedModifiedAndOriginalProps.mlsNumber}
									width={'200px'}
									onChange={(updatedValue) => {
										updateTransaction({ mlsNumber: updatedValue })
									}}
								/>
							</SideSheet__Row>
							<SideSheet__Row label="Accepted Date" direction="row">
								<input
									type="date"
									className="text-input"
									value={acceptedDateAsISO ? acceptedDateAsISO : ''}
									onChange={(evt) => {
										updateTransaction({ acceptedDate: evt.target.valueAsNumber })
									}}
								/>
							</SideSheet__Row>
							<SideSheet__Row label="Close Date" direction="row">
								<input
									type="date"
									className="text-input"
									value={closeDateAsISO ? closeDateAsISO : ''}
									onChange={(evt) => {
										updateTransaction({ closeDate: evt.target.valueAsNumber })
									}}
								/>
							</SideSheet__Row>
						</SideSheet__Section>
					</>
				)}
			</SideSheet>
		</>
	)
}
