import { NewsFeedStoryList } from '@components/news-feed__story-list/news-feed__story-list'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import InfiniteScroll from 'react-infinite-scroll-component'

import { NewsFeedAPI } from '../../services/news-feed/news-feed.api'
import { NewsFeed } from '../../services/news-feed/news-feed.types'

const NEWS_FEED_PAGE_SIZE = 10

export default function AllStoriesRoute() {
	const [newsFeedStories, setNewsFeedStories] = useState<NewsFeed.Story[]>([])
	const [newsFeedPage, setNewsFeedPage] = useState<number>(0)
	const [hasMore, setHasMore] = useState(true)

	/** Fetch results whenever page changes */
	useEffect(() => {
		sendSearchQuery()
	}, [newsFeedPage])

	function sendSearchQuery(): void {
		NewsFeedAPI.getStories({
			page: newsFeedPage,
			size: NEWS_FEED_PAGE_SIZE,
			sort: [{ property: 'createdTimestamp', direction: 'desc' }],
		}).then((res) => {
			setNewsFeedStories([...newsFeedStories, ...res.data.items])
			if (res.data.items.length < NEWS_FEED_PAGE_SIZE) {
				setHasMore(false)
			}
		})
	}

	function loadNextPageOfResults(): void {
		setNewsFeedPage(newsFeedPage + 1)
	}

	return (
		<RouteWrapperV2>
			<RouteBodyV2 id="all-stories__route-body">
				<Typography type="h1" margins={['bottom']}>
					All News
				</Typography>

				<div style={{ maxWidth: '800px' }}>
					<InfiniteScroll
						dataLength={newsFeedStories.length}
						next={loadNextPageOfResults}
						hasMore={hasMore}
						scrollableTarget={`all-stories__route-body`}
						loader={
							<ContentLoader viewBox="0 0 380 70" backgroundColor="var(--colorAdjust40)">
								<rect x="0" y="0" rx="5" ry="5" width="100%" height="10" />
							</ContentLoader>
						}
						endMessage={<Typography type="h4">No more items</Typography>}
					>
						{newsFeedStories && <NewsFeedStoryList stories={newsFeedStories} viewMode="FULL" />}
					</InfiniteScroll>
				</div>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}
