import { Button } from '@components/button/button'
import {
	closestCenter,
	DndContext,
	DragEndEvent,
	MouseSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { CustomForms } from '../../../../../services/custom-forms/custom-forms.types'
import { Utilities } from '../../../../../services/utilities.service'
import { SortableFieldCard } from './sortable-field-card'

interface FormFieldEditorProps {
	fields: CustomForms.Field[]
	updateFields: (fields: CustomForms.Field[]) => void
	className?: string
}

export function FormFieldEditor(props: FormFieldEditorProps) {
	const [orderedFieldIds, setOrderedFieldIds] = useState<number[]>([])

	useEffect(() => {
		const sortedFields = props.fields.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
		setOrderedFieldIds(sortedFields.map((field) => field.formFieldId))
	}, [props.fields])

	const mouseSensor = useSensor(MouseSensor, {
		activationConstraint: {
			distance: 40,
		},
	})
	const pointerSensor = useSensor(PointerSensor)

	const sensors = useSensors(pointerSensor, mouseSensor)

	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event
		let updatedFieldRefs = _.cloneDeep(props.fields)

		if (!over) {
			return
		}

		if (active.id !== over.id) {
			const oldIndex = updatedFieldRefs.findIndex((fieldRef) => fieldRef.formFieldId === active.id)
			const newIndex = updatedFieldRefs.findIndex((fieldRef) => fieldRef.formFieldId === over.id)

			updatedFieldRefs = arrayMove(updatedFieldRefs, oldIndex, newIndex)
			updatedFieldRefs.forEach((ref, index) => {
				updatedFieldRefs[index].displayOrder = index
			})
			setOrderedFieldIds(updatedFieldRefs.map((ref) => ref.formFieldId))
			props.updateFields(updatedFieldRefs)
		}
	}

	function handleRemoveField(fieldId: number): void {
		let updatedState = _.cloneDeep(props.fields)
		updatedState = updatedState.filter((field) => field.formFieldId !== fieldId)
		props.updateFields(updatedState)
	}

	function handleUpdateField(
		fieldId: number,
		key: keyof CustomForms.StandardField | keyof CustomForms.SelectField,
		value: unknown,
	): void {
		let updatedState = _.cloneDeep(props.fields)
		const indexOfField = updatedState.findIndex((thisField) => thisField.formFieldId === fieldId)

		if (indexOfField > -1) {
			const updatedField = { ...updatedState[indexOfField], [key]: value }
			updatedState[indexOfField] = updatedField
		}

		props.updateFields(updatedState)
	}

	function handleAddField(): void {
		let updatedState = _.cloneDeep(props.fields)
		updatedState.push({
			fieldType: 'STRING',
			formFieldId: 0 - Utilities.generateRandomInt(1, 1000000),
			fieldLabel: '',
			fieldKey: '',
			defaultValue: '',
			required: false,
			displayOrder: props.fields.length,
		})
		props.updateFields(updatedState)
	}

	return (
		<div className={`${props.className ? props.className : ''}`}>
			<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
				<SortableContext items={orderedFieldIds} strategy={verticalListSortingStrategy}>
					{props.fields.map((field) => {
						return (
							<SortableFieldCard
								key={field.formFieldId}
								field={field}
								onUpdate={(key, value) => {
									handleUpdateField(field.formFieldId, key, value)
								}}
								onDelete={() => {
									handleRemoveField(field.formFieldId)
								}}
							/>
						)
					})}
				</SortableContext>
			</DndContext>

			{props.fields.length === 0 && (
				<div className="bg-color__adjust-alpha-5 p-10 mb-10">No fields have been defined</div>
			)}
			<Button
				icon={'plus'}
				iconPosition="left"
				label="Add field"
				variant="outlined"
				size="md"
				onClick={() => {
					handleAddField()
				}}
			/>
		</div>
	)
}
