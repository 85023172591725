import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { UserService } from '../user/user.service'
import { EndUserProps } from '../user/user.types'

// Custom hook to redirect non-admin users
const useAdminRedirect = (currentUser: EndUserProps | null) => {
	const navigate = useNavigate()

	useEffect(() => {
		if (currentUser !== null) {
			const checkUserAdmin = async () => {
				try {
					const isUserAdmin = await UserService.isUserAdmin(currentUser)

					if (!isUserAdmin) {
						navigate('/hubs')
					}
				} catch (error) {
					console.error('Error while checking user admin status:', error)
				}
			}

			checkUserAdmin()
		}
	}, [currentUser, navigate])
}

export default useAdminRedirect
