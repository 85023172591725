import { Button } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'
import { useState } from 'react'
import { UserSelectorModal } from 'src/modals/user-selector/user-selector'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

type ComponentProps = {
	onClose: () => void
	onAddComment: (
		commentBody: string,
		taggedUsers: number[],
		visibility: TransactionMgmtTypes.UpdateTypes.Visibility,
	) => Promise<void>
}

export function TransactionMgmt__AddCommentModal(props: ComponentProps) {
	const [showSelectUsersModal, setShowSelectUsersModal] = useState(false)
	const [taggedUsers, setTaggedUsers] = useState<number[]>([])
	const [commentBody, setCommentBody] = useState('')
	const [visibility, setVisibility] = useState<TransactionMgmtTypes.UpdateTypes.Visibility>('ALL')

	return (
		<>
			<Modal
				maxHeight={800}
				maxWidth={800}
				fixedHeight={false}
				className="flex flex-column"
				onClose={() => {
					props.onClose()
				}}
			>
				<>
					<ModalHeader title={`Add Comment`} />
					<ModalBody>
						<Paper bgColor="white" elevate={false} padding={['all']}>
							<div className="mb-5 flex flex-justifyContent-end">
								<Button
									variant="outlined"
									size="md"
									label="Tag Users"
									onClick={() => {
										setShowSelectUsersModal(true)
									}}
									icon="person"
									iconPosition="right"
									className="mr-10"
								/>
								<Button
									variant="outlined"
									size="md"
									label={visibility === 'ALL' ? 'Public' : 'Admins Only'}
									onClick={() => {
										if (visibility === 'ALL') {
											setVisibility('ADMIN')
										} else {
											setVisibility('ALL')
										}
									}}
									icon={visibility === 'ALL' ? 'lock-open' : 'lock'}
									iconPosition="right"
								/>
							</div>
							<TextInput
								value={commentBody}
								width={'100%'}
								dataType="text"
								margins={['bottom']}
								placeholder="Enter comment"
								rows={3}
								onChange={(updatedValue) => {
									setCommentBody(updatedValue)
								}}
							/>

							<div className="flex flex-justifyContent-spaceBetween">
								{taggedUsers.length === 0 && (
									<Typography type="normal" variant={['italic']} color="disabled">
										No users are tagged
									</Typography>
								)}
								{taggedUsers.length === 1 && (
									<Typography type="normal" variant={['italic']} color="disabled">
										1 user is tagged
									</Typography>
								)}
								{taggedUsers.length > 1 && (
									<Typography type="normal" variant={['italic']} color="disabled">
										{taggedUsers.length} users are tagged
									</Typography>
								)}
							</div>
						</Paper>
					</ModalBody>
					<ModalFooter>
						<Button
							variant="outlined"
							size="md"
							primary={false}
							className="mr-10"
							label={`Cancel`}
							onClick={async () => {
								props.onClose()
							}}
						/>
						<Button
							variant="contained"
							size="md"
							label={`Add Comment`}
							onClick={async () => {
								await props.onAddComment(commentBody, taggedUsers, visibility)
							}}
						/>
					</ModalFooter>
				</>
			</Modal>

			{showSelectUsersModal && (
				<UserSelectorModal
					selectedRecipients={{
						endUsers: taggedUsers,
						licensedStates: [],
						specialties: [],
						licensedRegions: [],
						endUsersByPaymentMethod: [0],
					}}
					zIndex={10000}
					onHide={() => {
						setShowSelectUsersModal(false)
					}}
					onSelectUsers={async (updatedRecipients) => {
						setTaggedUsers(updatedRecipients.endUsers)
						setShowSelectUsersModal(false)
					}}
					displayOptions={['user']}
				/>
			)}
		</>
	)
}
