import { Button } from '@components/button/button'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'
import { UserAPI } from 'src/services/user/user.api'
import { EndUserProps } from 'src/services/user/user.types'

type ComponentProps = {
	taskId: number
	parentTransaction: TransactionMgmtTypes.TransactionTypes.Transaction
	update:
		| TransactionMgmtTypes.UpdateTypes.TaskAddedUpdate
		| TransactionMgmtTypes.UpdateTypes.TaskCompletedUpdate
		| TransactionMgmtTypes.UpdateTypes.TaskReassignedUpdate
}

export function UpdateBody__Task(props: ComponentProps) {
	const [searchParams, setSearchParams] = useSearchParams()
	const [newOwnerEndUserProps, setNewOwnerEndUserProps] = useState<EndUserProps | null>(null)
	const taskProps = getTaskById(props.taskId)

	useEffect(() => {
		if (props.update.type === 'TASK_REASSIGNED') {
			UserAPI.getUserDetails(props.update.newOwnerEndUserId).then((res) => {
				if (res.data) {
					setNewOwnerEndUserProps(res.data)
				}
			})
		}
	}, [])

	function getTaskById(taskId: number): TransactionMgmtTypes.TaskTypes.Task | undefined {
		return props.parentTransaction.tasks.find((task) => task.taskId === taskId)
	}

	switch (props.update.type) {
		case 'TASK_ADDED':
			return <>added task '{taskProps ? taskProps.name : 'UNKNOWN'}'</>
		case 'TASK_COMPLETED':
			return <>completed task '{taskProps ? taskProps.name : 'UNKNOWN'}'</>
		case 'TASK_REASSIGNED':
			return (
				<>
					re-assigned task '{taskProps ? taskProps.name : 'UNKNOWN'}' to{' '}
					<Button
						variant="text"
						size="md"
						label={
							newOwnerEndUserProps
								? `${newOwnerEndUserProps.firstName} ${newOwnerEndUserProps.lastName}`
								: `Unknown`
						}
						onClick={() => {
							if (props.update.type === 'TASK_REASSIGNED') {
								searchParams.set('user', String(props.update.newOwnerEndUserId))
								setSearchParams(searchParams)
							}
						}}
					/>
				</>
			)
	}
}
