import { DesktopAppHeader } from '@components/desktop-app-header/desktop-app-header'
import { AppTrayTypes } from '@components/desktop-app-tray/app-tray.types'
import { DesktopAppTray } from '@components/desktop-app-tray/desktop-app-tray'
import { MobileAppHeader } from '@components/mobile-app-header/mobile-app-header'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { useAppDispatch } from '@redux/hooks'
import { uiSlice, UIState } from '@redux/reducers/ui-reducer'
import { RootState } from '@redux/store'
import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Outlet } from 'react-router'
import { NewUserOnboardingModal } from 'src/modals/new-user-onboarding/new-user-onboarding__provider'
import { LicensedStateEnums } from 'src/services/licensed-states/licenses-states.types'
import { TokenTimeoutNotificationService } from 'src/services/token-timeout-notifier/token-timeout-notifier'
import { UserService } from 'src/services/user/user.service'

import { HubsService } from '../../services/hubs/hubs.service'
import { Hub } from '../../services/hubs/hubs.types'
import { RoutingService } from '../../services/routing/routing.service'
import { EndUserProps } from '../../services/user/user.types'
import { AdministrationRouting } from '../administration/admin.routing'
import { TransactionMgmtRouting } from '../transaction-mgmt/transaction-mgmt.routing'

export type RouteSection = 'HUBS' | 'SETTINGS' | 'TRANSACTION_MGMT'

type ComponentProps = {
	routeSection: RouteSection
	children?: React.ReactElement
}

type ConnectedProps = {
	hubs: Hub[]
	user: EndUserProps | null
	ui: UIState
}

/** Wraps a route with the standard navigation used throughout the application (e.g. header, sidebar).
 * Note: You should probably not be implementing this component directly.
 */
function PrimaryRouteChromePrototype(props: ComponentProps & ConnectedProps) {
	const viewMode = useSelector((state: RootState) => state.ui.viewMode)
	const appDispatch = useAppDispatch()

	const userLicensedStates: LicensedStateEnums[] = props.user
		? props.user.licenseInformation.map((license) => license.licensedState.abbreviation)
		: []
	const isUserAdmin = props.user ? UserService.isUserAdmin(props.user) : false
	const isUserSuperAdmin = props.user ? UserService.isUserSuperAdmin(props.user) : false

	/** ====================================== */
	/** Effects */

	/** Remove scrollbar when mobile app tray is open */
	useEffect(() => {
		const body = document.body
		if (props.ui.isMobileTrayOpen && props.ui.viewMode === 'MOBILE') {
			body.style.overflow = 'hidden'
		} else {
			body.style.overflow = ''
		}
	}, [props.ui.isMobileTrayOpen, props.ui.viewMode])

	/** Toggle between modal and desktop headers */
	useEffect(() => {
		window.addEventListener('resize', determineHeaderStyle)
		determineHeaderStyle()
		return () => {
			window.removeEventListener('resize', determineHeaderStyle)
		}
	}, [])

	function getIsHubVisible(hub: Hub): boolean {
		let isVisible = false

		if (!hub.published && !isUserSuperAdmin) {
			isVisible = false
		} else if (hub.stateAgnostic) {
			isVisible = true
		} else if (hub.licensedStates.some((state) => userLicensedStates.includes(state.abbreviation))) {
			isVisible = true
		} else if (isUserAdmin) {
			isVisible = true
		}

		return isVisible
	}

	/** ====================================== */
	/** Methods */

	function determineHeaderStyle(): void {
		if (window.innerWidth > 1000) {
			appDispatch(uiSlice.actions.setViewModeFull())
		} else {
			appDispatch(uiSlice.actions.setViewModeMobile())
		}
	}

	function getAppTrayLinks(): AppTrayTypes.Element[] {
		const appTrayLinks: AppTrayTypes.Element[] = []

		if (props.routeSection === 'HUBS' || (props.routeSection === 'SETTINGS' && !isUserAdmin)) {
			HubsService.sortHubsByDisplayOrder(props.hubs).forEach((hub, index) => {
				if (getIsHubVisible(hub)) {
					appTrayLinks.push({
						displayOrder: index + 1,
						label: hub.title,
						path: `${RoutingService.hubRoutePath}/${hub.slug}`,
						icon: hub.icon ? (hub.icon as IconName) : 'question-circle',
						linkType: 'hub',
					})
				}
			})

			appTrayLinks.push({
				displayOrder: appTrayLinks.length + 2,
				label: `Agent Referral Network`,
				path: `/user-search`,
				icon: 'user-magnifying-glass',
				linkType: 'other',
				id: 'desktop-app-tray__agent-search-btn',
			})

			// appTrayLinks.push({
			// 	displayOrder: appTrayLinks.length + 3,
			// 	label: `Transaction Management`,
			// 	path: `/transaction-management`,
			// 	icon: 'handshake',
			// 	linkType: 'other',
			// 	id: 'desktop-app-tray__transaction-mgmt-btn',
			// })
		}
		if (props.routeSection === 'SETTINGS' && isUserAdmin) {
			if (isUserAdmin) {
				AdministrationRouting.getRouteProperties({ user: props.user }).forEach((route, groupIndex) => {
					groupIndex = groupIndex + 10

					if (RoutingService.getIsRouteVisible(route)) {
						appTrayLinks.push({
							displayOrder: groupIndex + 1,
							label: route.label,
							path: typeof route.href === 'string' ? route.href : route.href(),
							icon: route.icon,
							linkType: 'admin',
						})
					}
				})
			}
		}

		if (props.routeSection === 'TRANSACTION_MGMT') {
			TransactionMgmtRouting.getRouteProperties().forEach((route, groupIndex) => {
				groupIndex = groupIndex + 10

				if (RoutingService.getIsRouteVisible(route)) {
					appTrayLinks.push({
						displayOrder: groupIndex + 1,
						label: route.label,
						path: typeof route.href === 'string' ? route.href : route.href(),
						icon: route.icon,
						linkType: 'admin',
					})
				}
			})
		}

		return appTrayLinks.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
	}

	/** ====================================== */
	/** Render Component */

	return (
		<>
			{viewMode === 'FULL' && (
				<>
					{props.user && <DesktopAppTray links={getAppTrayLinks()} />}
					<DesktopAppHeader />
				</>
			)}
			{viewMode === 'MOBILE' && (
				<>
					<MobileAppHeader headerStyle="NORMAL" />
				</>
			)}

			<TokenTimeoutNotificationService />
			<Outlet />
			{props.children && <>{props.children}</>}

			{props.ui.showUserOnboardingModal && (
				<NewUserOnboardingModal
					onClose={() => {
						appDispatch(uiSlice.actions.setShowUserOnboardingModal(false))
					}}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		hubs: state.hubs,
		user: state.user,
		ui: state.ui,
	}
}

export const PrimaryRouteChrome = connect(mapStateToProps)(PrimaryRouteChromePrototype)
