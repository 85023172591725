import { Button } from '@components/button/button'
import { useState } from 'react'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { TransactionMgmt__TransactionContact__SideSheet } from '../../../components/contact-side-sheet/contact.side-sheet'
import { useTransaction } from '../../../state/transaction__state'

type ComponentProps = {
	update: TransactionMgmtTypes.UpdateTypes.ContactAddedUpdate
}

export function UpdateBody__AddContact(props: ComponentProps) {
	const [showSideSheet, setShowSideSheet] = useState(false)
	const transactionState = useTransaction()
	const contactProps = getContactById(props.update.contactId)

	function getContactById(contactId: number): TransactionMgmtTypes.TransactionTypes.Contact | undefined {
		return transactionState.transaction.contacts.find((contact) => contact.contactId === contactId)
	}

	return (
		<>
			added a contact for{' '}
			{contactProps && (
				<Button
					variant="text"
					size="md"
					label={`${contactProps.fullName}`}
					onClick={() => {
						setShowSideSheet(true)
					}}
				/>
			)}
			{contactProps && showSideSheet && (
				<TransactionMgmt__TransactionContact__SideSheet
					onClose={() => {
						setShowSideSheet(false)
					}}
					onUpdate={(updatedContact) => {
						setShowSideSheet(false)
					}}
					onDelete={() => {
						setShowSideSheet(false)
					}}
					contact={contactProps}
					parentTransactionId={transactionState.transaction.transactionId}
				/>
			)}
		</>
	)
}
