import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'
import { Typography } from '@components/text/text'

import { AddOnServiceList } from '../../routes/administration/user-profile/components/subscription-components/add-on-service-list'
import Logo from './dotloop-blue.png'
import { DotloopIconGrid } from './dotloop-grid'

export function LoKationResourceDotloopFl() {
	return (
		<div className="flex flex-column overflow-y__scroll">
			<div className="flex flex-wrap">
				<div className="col-xs-12 pr-20-sm flex flex-column flex-alignItems-start">
					<div className="text-center col-xs-12">
						<img src={Logo} style={{ height: '75px' }} />
						<Typography type="h2">For Real Estate Agents Who Want to Accelerate Transactions</Typography>
					</div>
					<HorizontalRule className="my-20" />
					<p>
						Dotloop is a complete real estate transaction management software that enables brokers, agents,
						transaction coordinators and clients to collaborate virtually. It handles the entire deal from
						offer to close.
					</p>
					<p>
						Empower your agents with software that provides eSignatures, forms, sharing, text tracking,
						storage & more!
					</p>
					<DotloopIconGrid />
					<strong>Sign up for Dotloop Now, $20 month</strong>

					<AddOnServiceList serviceIds={['DOTLOOP']} />
				</div>
			</div>
		</div>
	)
}
