import { Chip } from '@components/chip/chip'
import { MultiColumnCard } from '@components/multi-column-card/multi-column-card'
import { MultiColumnCard__Column } from '@components/multi-column-card/multi-column-card__column'
import { Typography } from '@components/text/text'
import { Tooltip } from '@components/tooltip/tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateService } from 'src/services/date.service'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

type ComponentProps = {
	task: TransactionMgmtTypes.TaskTypes.Task
	handleSelectItem: (task: TransactionMgmtTypes.TaskTypes.Task) => void
	/** Adds a flag to show this task is new */
	showNewFlag: boolean
}

/** Creates a card for a single task */
export function Transaction__TaskCard(props: ComponentProps) {
	const numCommentUpdates = TransactionManagementService.getNumCommentUpdates(props.task.updates)

	return (
		<MultiColumnCard
			size="md"
			onClick={() => {
				props.handleSelectItem(props.task)
			}}
		>
			<MultiColumnCard__Column width="fill" align="left">
				<div style={{ width: '25px' }} className="flex flex-alignItems-center">
					<Tooltip
						icon={props.task.status === 'COMPLETE' ? 'square-check' : 'square'}
						body={TransactionManagementService.getLabelForTaskStatus(props.task.status)}
					/>
				</div>
				<Typography type="strong">{props.task.name}</Typography>
				{props.showNewFlag && (
					<Chip label="NEW" color="var(--colorAccent)" className="ml-10" style={{ minWidth: 'inherit' }} />
				)}
			</MultiColumnCard__Column>
			<MultiColumnCard__Column width={200} align="right">
				<Typography
					type="normal"
					variant={['italic']}
					color={props.task.completionDate === null && props.task.dueDate ? 'warning' : 'secondary'}
				>
					{props.task.completionDate === null && props.task.dueDate && (
						<>Due {DateService.getFormattedDateFromDateObj(new Date(props.task.dueDate))}</>
					)}

					{props.task.completionDate === null && props.task.dueDate === null && <>—</>}
				</Typography>
			</MultiColumnCard__Column>
			<MultiColumnCard__Column width={50} align="right" className="opacity-50">
				{numCommentUpdates > 0 && (
					<>
						<FontAwesomeIcon icon={['far', 'comment']} />
						<div className="ml-5">{numCommentUpdates}</div>
					</>
				)}
			</MultiColumnCard__Column>
		</MultiColumnCard>
	)
}
