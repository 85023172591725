import { Box } from '@components/box/box'
import { Button } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownTypes } from '@components/dropdown/dropdown.types'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'
import { ToggleButton } from '@components/toggle-button/toggle-button'
import { useEffect, useState } from 'react'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import HouseAerialImg from './house-aerial.jpg'

type ComponentProps = {
	onCancel: () => void
	onCreate: (transaction: TransactionMgmtTypes.TransactionTypes.Transaction) => void
}

type NewTransactionState = Omit<TransactionMgmtTypes.TransactionTypes.Transaction, 'type' | 'locationId' | 'side'> &
	Partial<Pick<TransactionMgmtTypes.TransactionTypes.Transaction, 'type' | 'locationId' | 'side'>>

export function Transaction__CreateTransactionModal(props: ComponentProps) {
	const [transaction, setTransaction] = useState(getDefaultState())
	const [allLocations, setAllLocations] = useState<TransactionMgmtTypes.LocationTypes.Location[] | null>(null)

	useEffect(() => {
		TransactionManagementAPI.findLocations().then((res) => {
			setAllLocations(res.data)
		})
	}, [])

	function getDefaultState() {
		const defaultState: NewTransactionState = TransactionManagementService.getDefaultTransactionProps()

		defaultState.type = undefined
		defaultState.side = undefined
		defaultState.locationId = undefined

		return defaultState
	}

	function createTransaction(): Promise<TransactionMgmtTypes.TransactionTypes.Transaction> {
		return new Promise((resolve, reject) => {
			if (transaction.type === undefined || transaction.status === undefined || transaction.side === undefined) {
				reject()
				return
			}
			const transactionToCreate = transaction as TransactionMgmtTypes.TransactionTypes.Transaction

			TransactionManagementAPI.createTransaction(transactionToCreate).then((res) => {
				resolve(res.data)
			})
		})
	}

	function updateProperties(updatedProps: Partial<NewTransactionState>): void {
		const updatedTransaction: NewTransactionState = {
			...transaction,
			...updatedProps,
		}
		setTransaction(updatedTransaction)
	}

	function getLocationOptions(): DropdownTypes.Option<number>[] {
		const options: DropdownTypes.Option<number>[] = []

		if (allLocations) {
			allLocations.forEach((location) => {
				options.push({
					label: location.name,
					value: location.locationId,
				})
			})
		}

		return options
	}

	function getTransactionTypeOptions(): DropdownTypes.Option<TransactionMgmtTypes.TransactionTypes.Type>[] {
		const options: DropdownTypes.Option<TransactionMgmtTypes.TransactionTypes.Type>[] = []
		TransactionManagementService.getAllTransactionTypes().forEach((type) => {
			options.push({
				label: type.label,
				value: type.value,
			})
		})
		return options
	}

	function getTransactionSideOptions(): DropdownTypes.Option<TransactionMgmtTypes.TransactionTypes.Side>[] {
		const options: DropdownTypes.Option<TransactionMgmtTypes.TransactionTypes.Side>[] = []
		TransactionManagementService.getAllTransactionSides().forEach((side) => {
			options.push({
				label: side.label,
				value: side.value,
			})
		})
		return options
	}

	function arePropsValid(): boolean {
		if (transaction.name.length < 1) {
			return false
		}
		if (!transaction.side) {
			return false
		}
		if (!transaction.type) {
			return false
		}
		if (!transaction.locationId) {
			return false
		}
		return true
	}

	return (
		<>
			<Modal
				maxHeight={800}
				maxWidth={1000}
				fixedHeight={false}
				className="flex flex-column"
				onClose={() => {
					props.onCancel()
				}}
			>
				<>
					<ModalHeader title={`Create Transaction`} />
					<ModalBody className="flex">
						<Box flex="column" gap={true} padding={['right']} className="col-xs-12 col-md-6">
							<TextInput
								label="Address"
								value={transaction.name}
								placeholder="123 Charleston Ave., Orlando FL 12345"
								dataType="text"
								width={'100%'}
								onChange={(updatedValue) => {
									updateProperties({ name: updatedValue })
								}}
							/>

							<Box>
								<Typography type="strong">Status</Typography>
								<ToggleButton<TransactionMgmtTypes.TransactionTypes.Status>
									options={[
										{
											label: 'Active',
											value: 'ACTIVE',
										},
										{
											label: 'Inactive',
											value: 'INACTIVE',
										},
									]}
									selectedOption={transaction.status}
									onSelect={(newState) => {
										updateProperties({ status: newState })
									}}
								/>
							</Box>

							<Dropdown<number>
								label="Location"
								value={transaction.locationId ? [transaction.locationId] : []}
								width={'100%'}
								options={getLocationOptions()}
								onSelect={(selectedOptions) => {
									updateProperties({ locationId: selectedOptions[0] })
								}}
							/>

							<Dropdown<TransactionMgmtTypes.TransactionTypes.Type>
								label="Type"
								value={transaction.type ? [transaction.type] : []}
								width={'100%'}
								options={getTransactionTypeOptions()}
								onSelect={(selectedOptions) => {
									updateProperties({ type: selectedOptions[0] })
								}}
							/>

							<Dropdown<TransactionMgmtTypes.TransactionTypes.Side>
								label="Side"
								value={transaction.side ? [transaction.side] : []}
								width={'100%'}
								options={getTransactionSideOptions()}
								onSelect={(selectedOptions) => {
									updateProperties({ side: selectedOptions[0] })
								}}
							/>

							<TextInput
								label="MLS Number"
								type="text"
								dataType="text"
								value={transaction.mlsNumber}
								width={'100%'}
								onChange={(updatedValue) => {
									updateProperties({ mlsNumber: updatedValue })
								}}
							/>
						</Box>
						<Box flex="column" gap={true} padding={['left']} className="col-md-6 hide-md">
							<img src={HouseAerialImg} className="col-xs-12 border-radius__std" />
						</Box>
					</ModalBody>
					<ModalFooter>
						<Button
							variant="outlined"
							size="md"
							primary={false}
							label="Cancel"
							className="mr-10"
							onClick={props.onCancel}
						/>
						<Button
							variant="contained"
							size="md"
							primary={true}
							label="Create"
							disabled={!arePropsValid()}
							onClick={async () => {
								const res = await createTransaction()
								props.onCreate(res)
							}}
						/>
					</ModalFooter>
				</>
			</Modal>
		</>
	)
}
