import { Button } from '@components/button/button'
import { Checkbox } from '@components/checkbox/checkbox'
import { CollapsibleContent } from '@components/collapsible-content/collapsible-content'
import _ from 'lodash'
import React from 'react'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

export type ComponentProps = {
	selectedSides: TransactionMgmtTypes.TransactionTypes.Side[]
	onSelect: (selectedSides: TransactionMgmtTypes.TransactionTypes.Side[]) => void
	style?: React.CSSProperties
	className?: string
	isMulti: boolean
	expanded?: true
}

export function TransactionSideFilter(props: ComponentProps) {
	function handleUpdateSelectedTags(
		newState: TransactionMgmtTypes.TransactionTypes.Side,
		isStateSelected: boolean,
	): void {
		let updatedState = _.cloneDeep(props.selectedSides)
		if (isStateSelected === true) {
			if (props.isMulti) {
				updatedState.push(newState)
				props.onSelect(updatedState)
			} else {
				props.onSelect([newState])
			}
		}
		if (isStateSelected === false) {
			if (props.isMulti) {
				props.onSelect(
					updatedState.filter((existingTag) => {
						return existingTag !== newState
					}),
				)
			} else {
				props.onSelect([])
			}
		}
	}

	function createFilterSide(sideLabel: string, sideValue: TransactionMgmtTypes.TransactionTypes.Side): JSX.Element {
		const isChecked = props.selectedSides.includes(sideValue)
		return (
			<div className="search-filter__option" key={sideValue}>
				<Checkbox
					checked={isChecked}
					onChange={(checkedState) => {
						handleUpdateSelectedTags(sideValue, checkedState)
					}}
				>
					{sideLabel}
				</Checkbox>
			</div>
		)
	}

	return (
		<div>
			<CollapsibleContent label="Side" controlled={true} id={`transaction__side`} headerPadding={['all']}>
				<>
					<div className={`base-search-filter ${props.className ? props.className : ''}`} style={props.style}>
						{TransactionManagementService.getAllTransactionSides().map((side) => {
							return createFilterSide(side.label, side.value)
						})}
						{props.isMulti && (
							<div>
								<Button
									variant="text"
									size="md"
									label="Select None"
									className="mr-20"
									onClick={() => {
										props.onSelect([])
									}}
								/>
								<Button
									variant="text"
									size="md"
									label="Select All"
									onClick={() => {
										props.onSelect(
											TransactionManagementService.getAllTransactionSides().map(
												(side) => side.value,
											),
										)
									}}
								/>
							</div>
						)}
					</div>
				</>
			</CollapsibleContent>
		</div>
	)
}
