import { SharedElementService } from '@components/shared/shared-element.service'

import { ButtonTypes } from './button.types'

export namespace ButtonService {
	export function getClass(
		props: Pick<
			ButtonTypes.Component,
			'variant' | 'size' | 'icon' | 'className' | 'primary' | 'margins' | 'marginSize'
		>,
	): string {
		let classes: string[] = []

		classes.push(`button`)

		classes.push(props.variant)

		switch (props.size) {
			case 'sm':
				classes.push(`is-sm`)
				break
			case 'md':
				classes.push(`is-md`)
				break
			case 'lg':
				classes.push(`is-lg`)
				break
		}

		if ((typeof props.primary === 'boolean' && props.primary === true) || typeof props.primary === 'undefined') {
			classes.push(`primary`)
		} else {
			classes.push(`secondary`)
		}

		if (props.icon) {
			classes.push(`flex flex-alignItems-center`)
		}

		if (props.margins) {
			classes = [
				...classes,
				...SharedElementService.getMarginClass({ margins: props.margins, marginSize: props.marginSize }),
			]
		}

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}
}
