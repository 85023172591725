import { Button } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { EndUserChip } from '@components/end-user-chip/end-user-chip'
import { IconButton } from '@components/icon-button/icon-button'
import { SideSheet } from '@components/side-sheet/side-sheet'
import { SideSheetServices } from '@components/side-sheet/side-sheet.service'
import { SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import { SideSheet__Row } from '@components/side-sheet/side-sheet__row'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import React from 'react'
import { DateService } from 'src/services/date.service'
import { ToastService } from 'src/services/toast/toast.service'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { TransactionMgmt__DeleteTask__Modal } from '../../shared/delete-task-confirmation-modal/delete-task-confirmation-modal'
import { Transaction__LinkDocumentToTaskModal } from '../../shared/link-document-modal/link-document-modal'
import { TransactionManagementTransactionChip } from '../../shared/transaction-chip/transaction-chip'
import { Transaction__DocumentCard } from '../../transaction/routes/overview/components/document-card'
import { TransactionMgmt__Document__SideSheet } from '../document/document.side-sheet'
import { TransactionMgmt__SideSheet__Section__Updates } from '../shared/side-sheet-section__updates'

export namespace TransactionMgmt__Task__SideSheetTypes {
	export type Component = {
		onClose: () => void
		onUpdate: (updatedTask: TransactionMgmtTypes.TaskTypes.Task) => void
		onDelete: (taskId: number) => void
		task: TransactionMgmtTypes.TaskTypes.Task | null
		mode: 'EDITOR' | 'END_USER'
		showParentTransaction: boolean
		parentTransaction?: TransactionMgmtTypes.TransactionTypes.Transaction
	}
}

export function TransactionMgmt__Task__SideSheet(props: TransactionMgmt__Task__SideSheetTypes.Component) {
	const [originalTask] = useState<TransactionMgmtTypes.TaskTypes.Task>(getDefaultTaskProperties())
	const [modifiedTask, setModifiedTask] = useState<TransactionMgmtTypes.TaskTypes.Task>(getDefaultTaskProperties())
	const [showLinkDocumentModal, setShowLinkDocumentModal] = useState(false)

	const [showDeleteTaskConfirmationModal, setShowDeleteTaskConfirmationModal] = useState(false)
	const didUserModifyProps = !_.isEqual(modifiedTask, originalTask)
	const [parentTransaction, setParentTransaction] =
		useState<TransactionMgmtTypes.TransactionTypes.Transaction | null>(
			props.parentTransaction ? props.parentTransaction : null,
		)
	const [showDocumentSideSheet, setShowDocumentSideSheet] = useState(false)
	const [selectedDocument, setSelectedDocument] = useState<TransactionMgmtTypes.DocumentTypes.Document | null>(null)

	useEffect(() => {
		if (!props.parentTransaction) {
			TransactionManagementAPI.getParentTransactionOfTask(originalTask.taskId).then((res) => {
				if (res.data) {
					setParentTransaction(res.data)
				}
			})
		}
	}, [originalTask])

	/** If no task is passed in, assume that the user is creating a new task and provide default properties for it  */
	function getDefaultTaskProperties(): TransactionMgmtTypes.TaskTypes.Task {
		return props.task ? props.task : TransactionManagementService.getDefaultTaskProps()
	}

	function createFooter(): React.ReactNode {
		return (
			<>
				<Button
					variant={'outlined'}
					label={'Cancel'}
					size={'md'}
					className="mr-10"
					onClick={async () => {
						props.onClose()
					}}
				/>
				<Button
					variant={'contained'}
					label={'Save and Close'}
					size={'md'}
					onClick={async () => {
						const res = await handleSaveTask()
						props.onClose()
						props.onUpdate(res)
					}}
				/>
			</>
		)
	}

	function createActions(): SideSheetTypes.Action[] {
		const actions: SideSheetTypes.Action[] = []

		if (modifiedTask && modifiedTask.taskId > 0) {
			actions.push({
				label: `Delete`,
				icon: 'trash',
				onClick: () => {
					setShowDeleteTaskConfirmationModal(true)
				},
			})
		}

		return actions
	}

	function onScrimClick(): void {
		if (!didUserModifyProps) {
			props.onClose()
			return
		}

		SideSheetServices.showDismissConfirmationToast({
			onDismiss: () => {
				return new Promise((resolve) => {
					resolve()
					props.onClose()
				})
			},
			onSaveAndClose: () => {
				return handleSaveTask().then((res) => {
					props.onClose()
					props.onUpdate(res)
				})
			},
		})
	}

	function handleSaveTask(): Promise<TransactionMgmtTypes.TaskTypes.Task> {
		return new Promise((resolve, reject) => {
			if (!parentTransaction) {
				reject()
				return
			}

			if (modifiedTask.taskId < 0) {
				TransactionManagementAPI.addTaskToTransaction(parentTransaction.transactionId, modifiedTask).then(
					(res) => {
						resolve(res.data)
					},
				)
			} else {
				TransactionManagementAPI.updateTaskOnTransaction(parentTransaction.transactionId, modifiedTask).then(
					(res) => {
						resolve(res.data)
					},
				)
			}
		})
	}

	function updateTask(updatedProps: Partial<TransactionMgmtTypes.TaskTypes.Task>): void {
		if (!modifiedTask) {
			return
		}
		const updatedTask: TransactionMgmtTypes.TaskTypes.Task = { ...modifiedTask, ...updatedProps }
		setModifiedTask(updatedTask)
	}

	function getAreAllDocumentsComplete(): boolean {
		if (!parentTransaction) {
			return false
		}
		let numCompleteDocs = 0

		modifiedTask.childDocIds.forEach((docId) => {
			const docProps = TransactionManagementService.getDocumentById(parentTransaction, docId)
			if (docProps && docProps.status === 'APPROVED') {
				numCompleteDocs++
			}
		})
		return modifiedTask.childDocIds.length === numCompleteDocs
	}

	return (
		<>
			<SideSheet
				title={'Task'}
				actions={createActions()}
				onClose={props.onClose}
				persistent={false}
				footer={createFooter()}
				preventDefaultScrimClick={didUserModifyProps}
				onScrimClick={onScrimClick}
			>
				{modifiedTask && (
					<>
						<SideSheet__Section label="Details" icon={'cog'} mode="expandable" sectionKey="task__general">
							<SideSheet__Row label="Name" direction="column">
								<TextInput
									type="text"
									dataType="text"
									value={modifiedTask.name}
									width={'100%'}
									onChange={(updatedValue) => {
										updateTask({ name: updatedValue })
									}}
								/>
							</SideSheet__Row>
							<SideSheet__Row label="Description" direction="column">
								<TextInput
									type="text"
									dataType="text"
									rows={3}
									value={modifiedTask.description}
									width={'100%'}
									onChange={(updatedValue) => {
										updateTask({ description: updatedValue })
									}}
								/>
							</SideSheet__Row>
							{props.mode === 'EDITOR' && (
								<SideSheet__Row label="Visibility" direction="row">
									<Dropdown<TransactionMgmtTypes.Shared.Visibility>
										value={[modifiedTask.visibility]}
										width={200}
										options={[
											{
												label: 'Not Published',
												value: 'NOT_PUBLISHED',
											},
											{
												label: 'Published',
												value: 'PUBLISHED',
											},
										]}
										onSelect={(selectedOptions) => {
											updateTask({ visibility: selectedOptions[0] })
										}}
									/>
								</SideSheet__Row>
							)}
							{props.mode === 'EDITOR' && (
								<SideSheet__Row label="Status" direction="row">
									<Dropdown<TransactionMgmtTypes.TaskTypes.Status>
										value={[modifiedTask.status]}
										width={200}
										options={[
											{
												label: 'Complete',
												value: 'COMPLETE',
											},
											{
												label: 'Incomplete',
												value: 'INCOMPLETE',
											},
										]}
										onSelect={(selectedOptions) => {
											if (selectedOptions[0] === 'COMPLETE' && !getAreAllDocumentsComplete()) {
												ToastService.create({
													type: 'ERROR',
													body: `This task cannot be marked as complete. It contains documents that have not been approved`,
												})
												return
											}

											updateTask({ status: selectedOptions[0] })
										}}
									/>
								</SideSheet__Row>
							)}
							{props.mode === 'END_USER' && (
								<>
									<SideSheet__Row label="Owner" direction="row">
										<div className="flex flex-alignItems-center">
											{modifiedTask.ownerId && <EndUserChip endUserId={modifiedTask.ownerId} />}
											{!modifiedTask.ownerId && (
												<Typography type="normal" variant={['italic']}>
													None
												</Typography>
											)}
											<IconButton
												icon={'pencil'}
												className="ml-10"
												onClick={() => {
													/** empty */
												}}
											/>
										</div>
									</SideSheet__Row>
									{props.showParentTransaction && parentTransaction && (
										<SideSheet__Row label="Parent Transaction" direction="row">
											<TransactionManagementTransactionChip
												style={{ maxWidth: '200px' }}
												transactionId={parentTransaction.transactionId}
											/>
										</SideSheet__Row>
									)}
									<SideSheet__Row label="Due Date" direction="row">
										{modifiedTask.dueDate
											? DateService.getFormattedDateFromDateObj(new Date(modifiedTask.dueDate))
											: `N/A`}
									</SideSheet__Row>
									<SideSheet__Row label="Completion Date" direction="row">
										<input
											type="date"
											className="text-input"
											style={{ width: '200px' }}
											value={
												modifiedTask.completionDate ? modifiedTask.completionDate : undefined
											}
											onChange={(evt) => {
												/** empty */
											}}
										/>
									</SideSheet__Row>
								</>
							)}
						</SideSheet__Section>

						<SideSheet__Section
							label="Required Documents"
							icon={'cog'}
							mode="expandable"
							sectionKey="task__req-documents"
						>
							{modifiedTask.childDocIds.map((docId) => {
								const docProps = parentTransaction
									? TransactionManagementService.getDocumentById(parentTransaction, docId)
									: undefined

								if (!docProps) {
									return <React.Fragment key={docId}>ERROR</React.Fragment>
								}

								return (
									<Transaction__DocumentCard
										document={docProps}
										key={docId}
										handleSelectItem={(thisDoc) => {
											setSelectedDocument(thisDoc)
											setShowDocumentSideSheet(true)
										}}
										removable={true}
										removeConfirmationHeader={`Are you sure you want to unlink this document from its parent task?`}
										onRemove={() => {
											return new Promise((resolve) => {
												updateTask({
													childDocIds: modifiedTask.childDocIds.filter(
														(thisDocId) => thisDocId !== docId,
													),
												})
												resolve()
											})
										}}
										showNewFlag={false}
									/>
								)
							})}
							<Button
								variant="outlined"
								size="md"
								className="col-xs-12"
								label="Link Document"
								onClick={() => {
									setShowLinkDocumentModal(true)
								}}
							/>
						</SideSheet__Section>

						{parentTransaction && (
							<TransactionMgmt__SideSheet__Section__Updates
								parentTaskId={modifiedTask.taskId}
								parentDocumentId={null}
								parentTransaction={parentTransaction}
								updates={modifiedTask.updates}
								onAddComment={(newComment) => {
									updateTask({ updates: [...modifiedTask.updates, newComment] })
								}}
							/>
						)}
					</>
				)}
			</SideSheet>

			{showDeleteTaskConfirmationModal && modifiedTask && (
				<TransactionMgmt__DeleteTask__Modal
					task={modifiedTask}
					onDelete={(deleteChildDocs) => {
						return new Promise((resolve) => {
							if (!parentTransaction) {
								return
							}
							TransactionManagementAPI.deleteTaskFromTransaction(
								parentTransaction.transactionId,
								modifiedTask.taskId,
								deleteChildDocs,
							).then(() => {
								resolve()
								props.onDelete(modifiedTask.taskId)
								setShowDeleteTaskConfirmationModal(false)
							})
						})
					}}
					onClose={() => {
						setShowDeleteTaskConfirmationModal(false)
					}}
				/>
			)}

			{showDocumentSideSheet && selectedDocument && (
				<TransactionMgmt__Document__SideSheet
					document={selectedDocument}
					showParentTransaction={true}
					onClose={() => {
						setShowDocumentSideSheet(false)
					}}
					onUpdate={(savedTask) => {
						setShowDocumentSideSheet(false)
					}}
					onDelete={(taskId) => {
						setShowDocumentSideSheet(false)
					}}
				/>
			)}

			{showLinkDocumentModal && parentTransaction && (
				<Transaction__LinkDocumentToTaskModal
					parentTransaction={parentTransaction}
					onCancel={() => {
						setShowLinkDocumentModal(false)
					}}
					onLinkDocument={(document) => {
						updateTask({ childDocIds: [...modifiedTask.childDocIds, document.documentId] })
						setShowLinkDocumentModal(false)
					}}
				/>
			)}
		</>
	)
}
