import { Button } from '@components/button/button'
import { Typography } from '@components/text/text'
import { RootState } from '@redux/store'
import { useRef, useState } from 'react'
import { connect } from 'react-redux'
import SignatureCanvas from 'react-signature-canvas'
import { ToastService } from 'src/services/toast/toast.service'

import { ContractTemplateAddition } from '../../routes/registration/routes/contracts/contracts.service'
import { FileBaseURL } from '../../services/axios/http-common.service'
import { DateService } from '../../services/date.service'
import { EmailNotificationAPI } from '../../services/email-notification/email-notification.api'
import { PDFGenerationService } from '../../services/pdf-generation/pdf-generation.service'
import { EndUserProps } from '../../services/user/user.types'
import AgreementThumbnail from './licensing-agreement-thumb.png'

function PropertyMgmtLicenseAgreementRoutePrototype(props: { user: EndUserProps | null }) {
	const [signature, setSignature] = useState<null | Blob>(null)
	const signatureRef = useRef<SignatureCanvas>(null)
	const [sentSuccess, setSentSuccess] = useState(false)

	function generatePDF(): void {
		signatureRef.current?.getCanvas().toBlob((signatureBlob) => {
			if (!signatureBlob) {
				ToastService.create({
					type: 'ERROR',
					body: `Contract could not be generated`,
				})
				return
			}
			const userName = `${props.user?.firstName} ${props.user?.lastName}`
			const todaysDate = DateService.getCurrentDateString(0)

			const basePDF = `${FileBaseURL}/pdf/Licensing_Agreement.pdf`
			const additions: ContractTemplateAddition[] = [
				{
					page: 3,
					text: userName,
					options: {
						x: 85,
						y: 270,
						size: 12,
					},
				},
				{
					page: 3,
					text: todaysDate,
					options: {
						x: 320,
						y: 270,
						size: 12,
					},
				},
				{
					page: 3,
					image: signatureBlob,
					options: {
						x: 85,
						y: 345,
						width: 200,
						height: 50,
					},
				},
			]

			PDFGenerationService()
				.modifyPDF(basePDF, additions)
				.then((uintArray) => {
					const filename = `${DateService.getCurrentDateString(0)}__license-agreement.pdf`
					PDFGenerationService().downloadBlob(uintArray, filename, `application/octet-stream`)
				})
				.catch((err) => {
					ToastService.create({
						type: 'ERROR',
						body: `Document could not be generated`,
					})
				})
		})
	}

	function validateAgreement(): boolean {
		if (signature) {
			return true
		} else {
			return false
		}
	}

	function emailGeneratedPDF() {
		signatureRef.current?.getCanvas().toBlob((signatureBlob) => {
			if (!signatureBlob) {
				ToastService.create({
					type: 'ERROR',
					body: `Contract could not be generated`,
				})
				return
			}
			const userName = `${props.user?.firstName} ${props.user?.lastName}`
			const todaysDate = DateService.getCurrentDateString(0)

			const basePDF = `${FileBaseURL}/pdf/Licensing_Agreement.pdf`
			const additions: ContractTemplateAddition[] = [
				{
					page: 3,
					text: userName,
					options: {
						x: 85,
						y: 270,
						size: 12,
					},
				},
				{
					page: 3,
					text: todaysDate,
					options: {
						x: 320,
						y: 270,
						size: 12,
					},
				},
				{
					page: 3,
					image: signatureBlob,
					options: {
						x: 85,
						y: 345,
						width: 200,
						height: 50,
					},
				},
			]

			PDFGenerationService()
				.modifyPDF(basePDF, additions)
				.then((uintArray) => {
					const filename = `${DateService.getCurrentDateString(0)}__license-agreement.pdf`

					const blob = new Blob([uintArray], { type: 'application/pdf' })

					PDFGenerationService().downloadBlob(uintArray, filename, `application/octet-stream`)
					EmailNotificationAPI.propertyManagementNotification(blob)
						.then((response) => {
							console.log(response)
							setSentSuccess(true)
						})
						.catch((error) => {
							console.log(error)
						})
				})
				.catch((err) => {
					ToastService.create({
						type: 'ERROR',
						body: `Document could not be generated`,
					})
				})
		})
	}

	return (
		<div className="flex-lg overflow-y__scroll">
			<div className="col-xs-12 col-lg-8 pr-20-sm flex flex-column flex-alignItems-start flex-justifyContent-start overflow-y__scroll">
				<Typography type="h3">Property Management Licensing Agreement</Typography>
				<p>
					Please review and sign the licensing agreement attached. Once signed and the completed agreement is
					submitted, you will receive a request to active your Appfolio account. Take some time to enter the
					platform and familiarize yourself with some of the training before a LoKation Admin reviews the
					platform and usage with you. As an Independent Contractor for LoKation Real Estate, you'll have
					access to the LPM platform to manage Tenants and Landlords.{' '}
				</p>
				<p>
					Your responsibilities include handling communications with Tenants and Landlords, while LPM manages
					financial aspects. Agent fees will be charged monthly based on the number of active units in your
					profile. Any charged management fees are collected from the Landlord funds and are Direct Deposited
					to you each month.
				</p>
				<p>
					We appreciate your partnership and look forward to providing exceptional property management
					services together. Contact <a href="mailto:info@lokationpm.com">info@lokationpm.com</a> for any
					questions or more information or view{' '}
					<a href="lokationpm.com" target="_blank">
						lokationpm.com
					</a>
					.
				</p>
				<Button
					size="md"
					variant="outlined"
					label="View Licensing Agreement"
					margins={['right', 'bottom']}
					onClick={() => {
						generatePDF()
					}}
				/>

				<div className="mt-20 mb-20 flex flex-column">
					<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-5">
						<strong>Signature</strong>
						<Button
							variant="text"
							size="md"
							label="Clear"
							onClick={() => {
								if (signatureRef.current) {
									signatureRef.current.clear()
									setSignature(null)
								}
							}}
						/>
					</div>

					<SignatureCanvas
						ref={signatureRef}
						penColor="black"
						onEnd={(signature) => {
							const signatureCanvasElt = signature.target as HTMLCanvasElement
							signatureCanvasElt.toBlob((blob) => {
								setSignature(blob)
							})
						}}
						canvasProps={{ width: 600, height: 150, className: 'sigCanvas bg-color__adjust-0 border-thin' }}
					/>
				</div>

				{!sentSuccess ? (
					<Button
						variant="contained"
						size="md"
						label="Submit Agreement"
						disabled={!validateAgreement()}
						onClick={() => {
							emailGeneratedPDF()
						}}
					/>
				) : (
					<div className="mb-20">Your form has been submitted.</div>
				)}
			</div>
			<div className="col-xs-12 col-lg-4 p-20 flex flex-alignItems-center">
				<img src={AgreementThumbnail} className="col-xs-12" />
			</div>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		user: state.user,
	}
}

export const PropertyMgmtLicenseAgreementRoute = connect(mapStateToProps)(PropertyMgmtLicenseAgreementRoutePrototype)
