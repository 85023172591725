import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { TeamsAPI } from 'src/services/teams/teams.api'
import { TeamTypes } from 'src/services/teams/teams.types'

import { TeamMembersRoute } from './team-members'

export function TeamMembersRouteWrapper() {
	const [data, setData] = useState<TeamTypes.TeamWithMembers | null>(null)
	const params = useParams<{ realEstateTeamId: string | undefined }>()

	useEffect(() => {
		if (!params.realEstateTeamId) {
			return
		}
		TeamsAPI.getTeamMembers(parseFloat(params.realEstateTeamId)).then((res) => {
			setData(res.data)
		})
	}, [])

	console.log(data)
	if (!data) {
		return (
			<RouteWrapperV2>
				<RouteBodyV2>Cannot load account</RouteBodyV2>
			</RouteWrapperV2>
		)
	}

	return <TeamMembersRoute team={data} />
}
