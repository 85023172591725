import './progress-bar.scss'

interface ProgressBarProps {
	start: number
	end: number
	progress: number
}

export function ProgressBar(props: ProgressBarProps) {
	const pctComplete = props.progress / (props.end - props.start)

	function getBarStyle(): React.CSSProperties {
		const style: React.CSSProperties = {}

		style.width = `${pctComplete * 100}%`

		if (pctComplete <= 0.3) {
			style.backgroundColor = `var(--colorWarning)`
		}
		if (pctComplete > 0.3 && pctComplete < 0.7) {
			style.backgroundColor = `#4F473F`
		}
		if (pctComplete >= 0.7) {
			style.backgroundColor = `var(--colorSuccess)`
		}

		return style
	}

	return (
		<div className={`progress-bar`}>
			<div className="progress-bar__pct-complete" style={getBarStyle()}></div>
		</div>
	)
}
