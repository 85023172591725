import { Button } from '@components/button/button'
import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'

import { FileBaseURL } from '../../services/axios/http-common.service'
import LuxurySignageImg from './lokation-Luxury-Signage.png'

export function CustomizableSignageTemplates() {
	return (
		<div className="flex-lg overflow-y__scroll flex-justifyContent-center">
			<div className="col-xs-12 col-lg-8 pr-20-sm mb-20 flex flex-column flex-alignItems-start overflow-y__scroll flex-justifyContent-center">
				<div className="text-center col-xs-12">
					<img src={LuxurySignageImg} style={{ maxWidth: '400px' }} className="col-xs-12" />
				</div>
				<HorizontalRule className="mt-20" />
				<p>
					LoKation Luxury provides customizable signage templates, optimized for a recommended size of 24×36.
					This ensures a high-resolution, professional appearance for your signs.
				</p>
				<div className="col-12 flex flex-justifyContent-center">
					<Button
						size="md"
						variant="outlined"
						label="Download Templates"
						marginSize="section"
						margins={['top']}
						onClick={() => {
							window.open(`${FileBaseURL}/images/luxury/luxeforsalesignpdfs.zip`, '_blank')
						}}
					/>
				</div>
			</div>
		</div>
	)
}
