import { Button } from '@components/button/button'
import { IconButton } from '@components/icon-button/icon-button'
import { Modal } from '@components/modal/modal'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'
import { useState } from 'react'

interface ImageSelectorModalProps {
	onSelectImage: (url: string) => void
	onCloseModal: () => void
}

export function ImageSelectorModal(props: ImageSelectorModalProps) {
	const [urlValue, setUrlValue] = useState('')

	return (
		<Modal
			maxHeight={250}
			maxWidth={500}
			fixedHeight={true}
			className="flex flex-column p-20"
			onClose={props.onCloseModal}
		>
			<>
				<IconButton
					icon={'xmark'}
					style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 100 }}
					onClick={props.onCloseModal}
				/>

				<div className="text-center flex-fillSpace flex flex-column flex-alignItems-center flex-justifyContent-center col-xs-12">
					<Typography type="h2">Enter Image URL</Typography>
					<TextInput
						margins={['top']}
						dataType="text"
						value={urlValue}
						onChange={(value) => {
							setUrlValue(value)
						}}
						width={'100%'}
					/>
				</div>

				<div className="flex">
					<Button
						onClick={() => {
							props.onCloseModal()
						}}
						variant={'outlined'}
						label={'Cancel'}
						size={'lg'}
						margins={['right']}
					/>
					<Button
						onClick={() => {
							props.onSelectImage(urlValue)
							props.onCloseModal()
						}}
						variant={'contained'}
						label={'Insert Image'}
						size={'lg'}
					/>
				</div>
			</>
		</Modal>
	)
}
