import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router'
import { RouteProperties } from 'src/services/routing/routing.types'

type ComponentProps = {
	route: RouteProperties
}

/** Card used to display various routes within a landing page */
export function LandingPageRouteCard(props: ComponentProps) {
	const navigate = useNavigate()
	const href = typeof props.route.href === 'function' ? props.route.href() : props.route.href

	return (
		<Paper
			bgColor="primary"
			padding={['all']}
			margins={['right', 'bottom']}
			interactive={true}
			className="flex flex-alignItems-center col-xs-12 col-sm-5"
			onClick={() => {
				navigate(href)
			}}
			key={href}
		>
			<FontAwesomeIcon
				icon={['far', props.route.icon]}
				className="mr-20 opacity-50"
				size="2x"
				style={{ width: '50px' }}
			/>
			<div>
				<Typography type="h6" color="primary">
					{props.route.label}
				</Typography>
				<Typography type="normal" color="secondary">
					{props.route.description}
				</Typography>
			</div>
		</Paper>
	)
}
