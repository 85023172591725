import { Chip } from '@components/chip/chip'
import { IconButton } from '@components/icon-button/icon-button'
import { MultiColumnCard } from '@components/multi-column-card/multi-column-card'
import { MultiColumnCard__Column } from '@components/multi-column-card/multi-column-card__column'
import { Tooltip } from '@components/tooltip/tooltip'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { GenericConfirmationModal } from 'src/modals/generic-confirmation/generic-confirmation'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

namespace DocumentCardTypes {
	export type Component = StandardCard | RemovableCard

	type BaseComponent = {
		document: TransactionMgmtTypes.DocumentTypes.Document
		handleSelectItem: (task: TransactionMgmtTypes.DocumentTypes.Document) => void
		removable: boolean
		className?: string
		/** Adds a flag to indicate this document is new */
		showNewFlag: boolean
	}

	type StandardCard = BaseComponent & {
		removable: false
	}
	type RemovableCard = BaseComponent & {
		removable: true
		removeConfirmationHeader: string
		onRemove: () => Promise<void>
	}
}

/** Creates a card for a single task */
export function Transaction__DocumentCard(props: DocumentCardTypes.Component) {
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)

	let docStatusIcon: IconName = 'check'
	const numCommentUpdates = TransactionManagementService.getNumCommentUpdates(props.document.updates)

	switch (props.document.status) {
		case 'APPROVED':
			docStatusIcon = 'square-check'
			break
		case 'PENDING':
			docStatusIcon = 'square'
			break
		case 'REJECTED':
			docStatusIcon = 'ban'
			break
	}

	return (
		<>
			<MultiColumnCard
				size="md"
				className={props.className}
				onClick={() => {
					props.handleSelectItem(props.document)
				}}
			>
				<MultiColumnCard__Column width="fill" align="left">
					<div style={{ width: '25px' }} className="flex flex-alignItems-center">
						<Tooltip
							icon={docStatusIcon}
							body={TransactionManagementService.getLabelForDocumentStatus(props.document.status)}
						/>
					</div>
					{props.document.name}
					{props.showNewFlag && (
						<Chip
							label="NEW"
							color="var(--colorAccent)"
							className="ml-10"
							style={{ minWidth: 'inherit' }}
						/>
					)}
				</MultiColumnCard__Column>
				<MultiColumnCard__Column width={50} align="right" className="opacity-50">
					{numCommentUpdates > 0 && (
						<>
							<FontAwesomeIcon icon={['far', 'comment']} />
							<div className="ml-5">{numCommentUpdates}</div>
						</>
					)}
				</MultiColumnCard__Column>
				{props.removable && (
					<MultiColumnCard__Column width={50} align="right">
						<IconButton
							icon="times"
							variant="inline"
							onClick={(evt) => {
								evt.stopPropagation()
								setShowDeleteConfirmationModal(true)
							}}
						/>
					</MultiColumnCard__Column>
				)}
			</MultiColumnCard>

			{showDeleteConfirmationModal && props.removable && (
				<GenericConfirmationModal
					header={props.removeConfirmationHeader}
					confirmLabel={`Remove`}
					escapable={false}
					onConfirm={async () => {
						if (!props.removable) {
							return
						}
						await props.onRemove()
						setShowDeleteConfirmationModal(false)
					}}
					onClose={() => {
						setShowDeleteConfirmationModal(false)
					}}
				/>
			)}
		</>
	)
}
