import { Button } from '@components/button/button'
import { CollapsibleContent } from '@components/collapsible-content/collapsible-content'
import { EndUserChip } from '@components/end-user-chip/end-user-chip'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { useState } from 'react'
import { UserSelectorModal } from 'src/modals/user-selector/user-selector'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'

import { useTransaction, useTransactionDispatch } from '../../state/transaction__state'

export function TransactionMgmt__Transaction__Overview__OwnersWatchers() {
	const transactionState = useTransaction()
	const transactionDispatch = useTransactionDispatch()
	const [showSelectOwnersModal, setShowSelectOwnersModal] = useState(false)
	const [showSelectWatchersModal, setShowSelectWatchersModal] = useState(false)

	return (
		<>
			<CollapsibleContent
				label={`Management`}
				labelSize="h5"
				controlled={true}
				id={`transaction__management`}
				headerPadding={[]}
			>
				<Paper elevate={true} bgColor="white" padding={['all']} gap={true} className="col-xs-12">
					<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-10 pb-10 border-bottom-thin">
						<div className="flex flex-alignItems-center" style={{ gap: '10px' }}>
							<Typography type="strong" style={{ width: '100px' }}>
								Owners
							</Typography>

							{transactionState.transaction.transactionOwnerIds.length === 0 && (
								<Typography type="normal" variant={['italic']} color="disabled">
									This transaction has no owners
								</Typography>
							)}
							{transactionState.transaction.transactionOwnerIds.map((user) => {
								return <EndUserChip endUserId={user} key={user} />
							})}
						</div>
						<Button
							variant="text"
							size="md"
							label="Edit"
							onClick={() => {
								setShowSelectOwnersModal(true)
							}}
						/>
					</div>

					<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween">
						<div className="flex flex-alignItems-center" style={{ gap: '10px' }}>
							<Typography type="strong" style={{ width: '100px' }}>
								Watchers
							</Typography>

							{transactionState.transaction.transactionWatcherIds.length === 0 && (
								<Typography type="normal" variant={['italic']} color="disabled">
									This transaction has no watchers
								</Typography>
							)}
							{transactionState.transaction.transactionWatcherIds.map((user) => {
								return <EndUserChip endUserId={user} key={user} />
							})}
						</div>
						<Button
							variant="text"
							size="md"
							label="Edit"
							onClick={() => {
								setShowSelectWatchersModal(true)
							}}
						/>
					</div>
				</Paper>
			</CollapsibleContent>

			{showSelectOwnersModal && (
				<UserSelectorModal
					selectedRecipients={{
						endUsers: transactionState.transaction.transactionOwnerIds,
						licensedStates: [],
						specialties: [],
						licensedRegions: [],
						endUsersByPaymentMethod: [0],
					}}
					zIndex={10000}
					onHide={() => {
						setShowSelectOwnersModal(false)
					}}
					onSelectUsers={async (updatedRecipients) => {
						TransactionManagementAPI.patchTransaction(transactionState.transaction.transactionId, {
							transactionOwnerIds: updatedRecipients.endUsers,
						}).then((res) => {
							transactionDispatch({ type: 'update-transaction', payload: res.data })
							setShowSelectOwnersModal(false)
						})
					}}
					displayOptions={['user']}
				/>
			)}

			{showSelectWatchersModal && (
				<UserSelectorModal
					selectedRecipients={{
						endUsers: transactionState.transaction.transactionWatcherIds,
						licensedStates: [],
						specialties: [],
						licensedRegions: [],
						endUsersByPaymentMethod: [0],
					}}
					zIndex={10000}
					onHide={() => {
						setShowSelectWatchersModal(false)
					}}
					onSelectUsers={async (updatedRecipients) => {
						TransactionManagementAPI.patchTransaction(transactionState.transaction.transactionId, {
							transactionWatcherIds: updatedRecipients.endUsers,
						}).then((res) => {
							transactionDispatch({ type: 'update-transaction', payload: res.data })
							setShowSelectWatchersModal(false)
						})
					}}
					displayOptions={['user']}
				/>
			)}
		</>
	)
}
