import { Chip } from '@components/chip/chip'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router'
import { UserAPI } from 'src/services/user/user.api'
import { EndUserProps } from 'src/services/user/user.types'

type ComponentProps = {
	endUserId: number
}

/** Chip that receives the ID of an end user and queries for complete information about that user. Opens a popover on click */
export function EndUserChip(props: ComponentProps) {
	const [searchParams, setSearchParams] = useSearchParams()
	const [endUserProps, setEndUserProps] = useState<null | EndUserProps>(null)

	useEffect(() => {
		UserAPI.getUserDetails(props.endUserId).then((res) => {
			setEndUserProps(res.data)
		})
	}, [])

	return (
		<Chip
			label={endUserProps ? `${endUserProps.firstName} ${endUserProps.lastName}` : `...`}
			color="var(--colorAdjust50)"
			onClick={() => {
				if (endUserProps) {
					searchParams.set('user', String(props.endUserId))
					setSearchParams(searchParams)
				}
			}}
		/>
	)
}
