import { Button } from '@components/button/button'
import { SideSheet } from '@components/side-sheet/side-sheet'
import { SideSheetServices } from '@components/side-sheet/side-sheet.service'
import { SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import { SideSheet__Row } from '@components/side-sheet/side-sheet__row'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { TextInput } from '@components/text-input/text-input'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { GenericDeleteConfirmationModal } from 'src/modals/generic-delete-confirmation/generic-delete-confirmation'
import { DateService } from 'src/services/date.service'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

export type ComponentProps = {
	onClose: () => void
	onUpdate: (updatedNotification: TransactionMgmtTypes.NotificationTypes.Notification) => void
	onDelete: (notificationId: number) => void
	notification: TransactionMgmtTypes.NotificationTypes.Notification | null
	showParentTransaction: boolean
	parentTransaction?: TransactionMgmtTypes.TransactionTypes.Transaction
	mode: 'EDITOR' | 'END_USER'
}

export function TransactionMgmt__Notification__SideSheet(props: ComponentProps) {
	const [originalNotification] = useState<TransactionMgmtTypes.NotificationTypes.Notification>(
		getDefaultNotificationProperties(),
	)
	const [modifiedNotification, setModifiedNotification] =
		useState<TransactionMgmtTypes.NotificationTypes.Notification>(getDefaultNotificationProperties())
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
	const [, setParentChecklists] = useState<TransactionMgmtTypes.ChecklistTypes.Checklist[] | null>(null)

	const [parentTransaction, setParentTransaction] =
		useState<TransactionMgmtTypes.TransactionTypes.Transaction | null>(
			props.parentTransaction ? props.parentTransaction : null,
		)

	const didUserModifyProps = !_.isEqual(modifiedNotification, originalNotification)

	useEffect(() => {
		if (!props.parentTransaction) {
			TransactionManagementAPI.getParentTransactionOfNotification(originalNotification.notificationId).then(
				(res) => {
					if (res.data) {
						setParentTransaction(res.data)
					}
				},
			)
		}

		if (typeof originalNotification.notificationId === 'number') {
			TransactionManagementAPI.findAllParentChecklistsOfNotification(originalNotification.notificationId).then(
				(res) => {
					setParentChecklists(res.data)
				},
			)
		}
	}, [originalNotification])

	/** If no task is passed in, assume that the user is creating a new task and provide default properties for it  */
	function getDefaultNotificationProperties(): TransactionMgmtTypes.NotificationTypes.Notification {
		return props.notification ? props.notification : TransactionManagementService.getDefaultNotificationProps()
	}

	function createFooter(): React.ReactNode {
		return (
			<>
				<Button
					variant={'outlined'}
					label={'Cancel'}
					size={'md'}
					className="mr-10"
					onClick={async () => {
						props.onClose()
					}}
				/>
				<Button
					variant={'contained'}
					label={'Save and Close'}
					size={'md'}
					onClick={async () => {
						const res = await handleSaveNotification()
						if (modifiedNotification) {
							props.onClose()
							props.onUpdate(res)
						}
					}}
				/>
			</>
		)
	}

	function createActions(): SideSheetTypes.Action[] {
		const actions: SideSheetTypes.Action[] = []

		actions.push({
			label: `Delete`,
			icon: 'trash',
			onClick: () => {
				setShowDeleteConfirmationModal(true)
			},
		})

		return actions
	}

	function onScrimClick(): void {
		if (!didUserModifyProps) {
			props.onClose()
			return
		}

		SideSheetServices.showDismissConfirmationToast({
			onDismiss: () => {
				return new Promise((resolve) => {
					resolve()
					props.onClose()
				})
			},
			onSaveAndClose: () => {
				return handleSaveNotification().then((res) => {
					if (modifiedNotification) {
						props.onClose()
						props.onUpdate(res)
					}
				})
			},
		})
	}

	function handleSaveNotification(): Promise<TransactionMgmtTypes.NotificationTypes.Notification> {
		return new Promise((resolve, reject) => {
			if (!parentTransaction) {
				reject()
				return
			}

			if (modifiedNotification.notificationId < 0) {
				TransactionManagementAPI.addNotificationToTransaction(
					parentTransaction.transactionId,
					modifiedNotification,
				).then((res) => {
					resolve(res.data)
				})
			} else {
				TransactionManagementAPI.updateNotificationOnTransaction(
					parentTransaction.transactionId,
					modifiedNotification,
				).then((res) => {
					resolve(res.data)
				})
			}
		})
	}

	function updateNotification(updatedProps: Partial<TransactionMgmtTypes.NotificationTypes.Notification>): void {
		if (!modifiedNotification) {
			return
		}
		const updatedNotification: TransactionMgmtTypes.NotificationTypes.Notification = {
			...modifiedNotification,
			...updatedProps,
		}
		setModifiedNotification(updatedNotification)
	}

	return (
		<>
			<SideSheet
				title={'Notification'}
				actions={createActions()}
				onClose={props.onClose}
				persistent={false}
				footer={createFooter()}
				preventDefaultScrimClick={didUserModifyProps}
				onScrimClick={onScrimClick}
			>
				{modifiedNotification && (
					<>
						<SideSheet__Section
							label="Details"
							icon={'cog'}
							mode="expandable"
							sectionKey="notification__general"
						>
							<SideSheet__Row label="Name" direction="row">
								<TextInput
									type="text"
									dataType="text"
									value={modifiedNotification.name}
									width={'300px'}
									onChange={(updatedValue) => {
										updateNotification({ name: updatedValue })
									}}
								/>
							</SideSheet__Row>
							<SideSheet__Row label="Description" direction="column">
								<TextInput
									type="text"
									dataType="text"
									rows={3}
									value={modifiedNotification.description}
									width={'100%'}
									onChange={(updatedValue) => {
										updateNotification({ description: updatedValue })
									}}
								/>
							</SideSheet__Row>
							{props.mode === 'END_USER' && (
								<>
									{props.showParentTransaction && (
										<SideSheet__Row label="Parent Transaction" direction="row">
											[Transaction Name]
										</SideSheet__Row>
									)}
									<SideSheet__Row label="Due Date" direction="row">
										{modifiedNotification.dueDate
											? DateService.getFormattedDateFromDateObj(
													new Date(modifiedNotification.dueDate),
												)
											: `N/A`}
									</SideSheet__Row>
									<SideSheet__Row label="Completion Date" direction="row">
										<input
											type="date"
											className="text-input"
											style={{ width: '200px' }}
											value={
												modifiedNotification.completionDate
													? modifiedNotification.completionDate
													: undefined
											}
											onChange={(evt) => {
												/** empty */
											}}
										/>
									</SideSheet__Row>
								</>
							)}
						</SideSheet__Section>
					</>
				)}
			</SideSheet>

			{showDeleteConfirmationModal && modifiedNotification && (
				<GenericDeleteConfirmationModal
					itemLabel={modifiedNotification.name}
					onDelete={async () => {
						props.onDelete(modifiedNotification.notificationId)
						setShowDeleteConfirmationModal(false)
					}}
					onClose={() => {
						setShowDeleteConfirmationModal(false)
					}}
				/>
			)}
		</>
	)
}
