/* eslint-disable react/jsx-pascal-case */
import { RootState } from '@redux/store'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router'
import { AgentSearchRoute } from 'src/lokation-routes/agent-search/agent-search__route-provider'
import { CalendarFullSizeRoute } from 'src/lokation-routes/calendar/calendar-full'
import { DepartmentContactsRoute } from 'src/lokation-routes/department-contacts/department-contacts'
import { UpdatePaymentMethodRoute } from 'src/lokation-routes/update-payment-method/update-payment-method'
import { EndUserProps } from 'src/services/user/user.types'

import { AdministrationRouting } from './administration/admin.routing'
import AllHubsRoute from './all-hubs/all-hubs'
import { PrimaryRootRoute } from './authenticated-root/authenticated-root'
import CreateAccountSuccessRoute from './create-account-success/create-account-success'
import { ErrorPage } from './error.page'
import { FAQsRoute } from './faqs/faqs'
import { SingleFAQRoute } from './faqs/single-faq'
import ForgotPasswordRoute from './forgot-password/forgot-password'
import { HomeRoute } from './home/home'
import { HubRoute } from './hub/hub'
import { LoginRoute } from './login/login'
import { MessagesRoute } from './messages/messages'
import { MessagesRouteBody } from './messages/messages__body'
import { MessagesRouteNoSelectedChatPlaceholder } from './messages/messages__no-selected-chat-placeholder'
import AllStoriesRoute from './news-feed/all-stories'
import { SingleNewsFeedStoryRoute } from './news-feed/single-news-feed-story'
import { RegistrationProviderWrapper } from './registration/registration-provider'
import { RejoinRoute } from './rejoin/rejoin-provider'
import ResetPasswordRoute from './reset-password/reset-password'
import { ResourceRoute } from './resource/resource'
import { SitewideSearchRoute } from './sitewide-search/sitewide-search'
import { SupportRoute } from './support/support'
import { TransactionMgmtRouting } from './transaction-mgmt/transaction-mgmt.routing'

type ConnectedProps = {
	user: EndUserProps | null
}

function ApplicationRoutesPrototype(props: ConnectedProps) {
	return (
		<Routes>
			<Route path="/" element={<LoginRoute />}></Route>
			<Route path="/registration" element={<RegistrationProviderWrapper />}></Route>
			<Route path="/forgot-password" element={<ForgotPasswordRoute />}></Route>
			<Route path="/resetPassword" element={<ResetPasswordRoute />}></Route>
			<Route path="/create-account-success" element={<CreateAccountSuccessRoute />}></Route>
			<Route path="/rejoin" element={<RejoinRoute />}></Route>
			<Route path="/search" element={<PrimaryRootRoute routeSection="HUBS" />}>
				<Route path="" index={true} element={<SitewideSearchRoute />}></Route>
			</Route>
			<Route path="/user-search" element={<PrimaryRootRoute routeSection="HUBS" />}>
				<Route path="" index={true} element={<AgentSearchRoute />}></Route>
			</Route>
			<Route path="/messages" element={<PrimaryRootRoute routeSection="HUBS" />}>
				<Route path="" element={<MessagesRoute />}>
					<Route index={true} element={<MessagesRouteNoSelectedChatPlaceholder />}></Route>
					<Route path={':chatGroupId'} element={<MessagesRouteBody />}></Route>
				</Route>
			</Route>
			<Route path="/department-contacts" element={<PrimaryRootRoute routeSection="HUBS" />}>
				<Route path="" index={true} element={<DepartmentContactsRoute />}></Route>
			</Route>
			<Route path="/calendar" element={<PrimaryRootRoute routeSection="HUBS" />}>
				<Route path="" index={true} element={<CalendarFullSizeRoute />}></Route>
			</Route>
			<Route path="/faqs" element={<PrimaryRootRoute routeSection="HUBS" />}>
				<Route path="" index={true} element={<FAQsRoute />}></Route>
				<Route path=":faqId" element={<SingleFAQRoute />}></Route>
			</Route>
			<Route path="/support" element={<PrimaryRootRoute routeSection="HUBS" />}>
				<Route path="" index={true} element={<SupportRoute />}></Route>
			</Route>
			<Route path="/news-feed" element={<PrimaryRootRoute routeSection="HUBS" />} errorElement={<ErrorPage />}>
				<Route path="" index={true} element={<AllStoriesRoute />}></Route>
				<Route path=":storyId" element={<SingleNewsFeedStoryRoute />}></Route>
			</Route>

			<Route path="/hubs">
				<Route element={<PrimaryRootRoute routeSection="HUBS" />}>
					<Route index={true} path="" element={<HomeRoute />}></Route>
					<Route path="explore" element={<AllHubsRoute />}></Route>
				</Route>
				<Route element={<PrimaryRootRoute redirectIfNotLoggedIn={false} routeSection="HUBS" />}>
					<Route path=":slug" element={<HubRoute />}></Route>
				</Route>
			</Route>
			<Route path="/resource">
				<Route element={<PrimaryRootRoute redirectIfNotLoggedIn={false} routeSection="HUBS" />}>
					<Route path=":slug" element={<ResourceRoute />}></Route>
				</Route>
			</Route>
			{AdministrationRouting.getRoutes({ user: props.user })}
			{TransactionMgmtRouting.getRoutes()}
			<Route path="/update-payment-method" element={<PrimaryRootRoute routeSection="HUBS" />}>
				<Route path="" index={true} element={<UpdatePaymentMethodRoute />}></Route>
			</Route>
		</Routes>
	)
}

function mapStateToProps(state: RootState) {
	return {
		user: state.user,
	}
}

export const ApplicationRoutes = connect(mapStateToProps)(ApplicationRoutesPrototype)
