import { FullScreenSpinner } from '@components/full-screen-spinner/full-screen-spinner'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { TransactionProvider } from './state/transaction__state'
import { TransactionMgmt__Transaction__Route } from './transaction.route'

/** Wrapper for all of the "Transaction" routes. Provides a context wrapper to centrally manage the transaction state */
export function TransactionMgmt__Transaction__ContextWrapper() {
	const [data, setData] = useState<TransactionMgmtTypes.TransactionTypes.Transaction | null>(null)
	const params = useParams()

	useEffect(() => {
		if (!params.transactionId) {
			return
		}
		TransactionManagementAPI.getTransactionById(Number(params.transactionId)).then((res) => {
			if (res.data) {
				setData(res.data)
			}
		})
	}, [params.transactionId])

	if (!data) {
		return <FullScreenSpinner label="Loading Transaction" />
	}

	return (
		<TransactionProvider transaction={cloneDeep(data)}>
			<TransactionMgmt__Transaction__Route />
		</TransactionProvider>
	)
}
