import { Button } from '@components/button/button'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { PaperlessPipelineAPI } from 'src/services/paperless-pipeline/paperless-pipeline.api'
import { EndUserProps } from 'src/services/user/user.types'

type ConnectedProps = {
	currentUser: EndUserProps | null
}

function PaperlessPipelineSSOCardsPrototype(props: ConnectedProps) {
	const [externalLinkForSso, setExternalLinkForSso] = useState('')
	async function getSSO(ppUserId: string) {
		try {
			const ssoUrls = await PaperlessPipelineAPI.getSsoUrls()
			if (ppUserId && ssoUrls) {
				const ssoUrl = ssoUrls.find((url) => {
					console.log('URL:', url)
					console.log('ppUserId:', ppUserId)
					return url.includes(ppUserId)
				})
				if (ssoUrl) {
					setExternalLinkForSso(ssoUrl)
					console.log(externalLinkForSso)
					window.open(ssoUrl, '_blank')
				} else {
					console.log('No SSO URL found for the selected state')
				}
			} else {
				console.log('No state has been selected or no SSO URLs were returned')
			}
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		if (externalLinkForSso) {
			setTimeout(() => {
				setExternalLinkForSso('')
			}, 3000)
		}
	}, [externalLinkForSso])

	return (
		<>
			{props.currentUser &&
				props.currentUser.licenseInformation.map((license, index) => (
					<Paper bgColor="primary" padding={['all']} margins={['right', 'bottom']}>
						<div key={license.licenseInformationId || index} style={{ maxWidth: '300px' }}>
							<Typography type="h5">
								Login to Paperless Pipeline for {license.licensedState.stateName}
							</Typography>
							<p>
								Click the button below to sign on to Paperless Pipeline for{' '}
								{license.licensedState.stateName}:
							</p>
							<Button
								variant="contained"
								size="lg"
								primary={true}
								label={`Login`}
								onClick={() => getSSO(license.ppUserId)}
							/>
							{externalLinkForSso && (
								<>
									{ReactDOM.createPortal(
										<div className="modal__scrim">
											<div className="external-site-msg__wrapper">
												<FontAwesomeIcon
													icon={['far', 'globe']}
													size={`5x`}
													className={`mb-20 opacity-50`}
												/>
												<Typography type="h5">Loading External Website</Typography>
												<div className="mt-10">
													If you are not redirected within 5 seconds,{' '}
													<a href={externalLinkForSso} target="_blank" rel="noreferrer">
														click here
													</a>
													.
												</div>
											</div>
										</div>,
										document.body,
									)}
								</>
							)}
						</div>
					</Paper>
				))}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const PaperlessPipelineSSOCards = connect(mapStateToProps)(PaperlessPipelineSSOCardsPrototype)
