import { Box } from '@components/box/box'
import { Button } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownTypes } from '@components/dropdown/dropdown.types'
import { Paper } from '@components/paper/paper'
import { TextInput } from '@components/text-input/text-input'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'

import { LicensedState } from '../../../../services/licensed-states/licenses-states.types'
import { LicensedRegionsService } from '../../../../services/regions/regions.service'
import { LicensedRegion } from '../../../../services/regions/regions.types'
import { AgentLicense } from '../../../../services/user/user.types'
import { ResolvedFormValidationField } from '../../../../services/validation/use-form-validation'

interface RegistrationAgentLicenseProps {
	license: AgentLicense.Entry
	onUpdate: (key: keyof AgentLicense.Entry, value: any) => void
	onRemove: () => void
	getField: (name: string) => ResolvedFormValidationField<string, any>
	hasUserClickedNavButton: boolean
	index: number
}

function RegistrationAgentLicensePrototype(props: RegistrationAgentLicenseProps & { licensedStates: LicensedState[] }) {
	/** ========================================== */
	/** Props and State */

	const regionsUnderSelectedState = props.license.licensedState
		? LicensedRegionsService.getRegionsOfState(props.license.licensedState.licensedStateId)
		: []

	/** ========================================== */
	/** Methods */

	function getLicensedStateOptions(): DropdownTypes.Option<LicensedState>[] {
		return props.licensedStates.map((state) => {
			return {
				value: state,
				label: state.stateName,
			}
		})
	}

	function getPlanOptions(): DropdownTypes.Option<AgentLicense.PlanType>[] {
		return [
			{
				value: 'BLUE',
				label: 'Blue',
			},
			{
				value: 'SILVER',
				label: 'Silver',
			},
		]
	}

	function getRegionOptions(): DropdownTypes.Option<LicensedRegion>[] {
		return regionsUnderSelectedState.map((region) => {
			return {
				value: region,
				label: region.regionName,
			}
		})
	}

	/** ========================================== */
	/** Render Component */

	return (
		<Paper bgColor="primary" padding={['all']} margins={['bottom']} flex="column" className="col-xs-12">
			<Box flex="row" wrap={true} gap={true} margins={['bottom']}>
				<TextInput
					width={200}
					dataType="text"
					label="License number"
					placeholder="License number"
					value={props.license.licenseNumber}
					onChange={(updatedValue) => {
						props.onUpdate('licenseNumber', updatedValue)
					}}
					validation={{
						isValid: (value, isUserInteracted) => {
							return {
								isValid: props.getField(`${props.index}/licenseNumber`).isValid,
								showValidationFlag: isUserInteracted || props.hasUserClickedNavButton,
							}
						},

						message: `You must include a license number`,
					}}
					forceRevalidation={props.hasUserClickedNavButton}
					tooltip={
						<div style={{ maxWidth: '300px' }}>
							<p>
								If you have not been issued a license number, please enter 000000 as your license number
							</p>
						</div>
					}
				/>

				<Dropdown<AgentLicense.PlanType>
					label="Plan"
					width={150}
					tooltip={
						<div style={{ textAlign: 'left' }}>
							<strong>Blue Plan</strong>
							<ul>
								<li>$99 / transaction</li>
								<li>100% Commission, Always</li>
								<li>$99 per Month</li>
								<li>$99 per Rental Transaction</li>
								<li>$0 Annual Fee</li>
								<li>$50 Quarterly Compliance Fee</li>
							</ul>
							<p>
								<strong>Silver Plan</strong>
								<ul>
									<li>$499 / transaction</li>
									<li>100% Commission, Always</li>
									<li>$0 per Month</li>
									<li>$99 per Rental Transaction</li>
									<li>$99 Annual Fee</li>
									<li>$50 Quarterly Compliance Fee</li>
								</ul>
							</p>
						</div>
					}
					value={props.license.plan ? [props.license.plan] : []}
					options={getPlanOptions()}
					onSelect={(selectedValues) => {
						if (selectedValues.length > 0) {
							props.onUpdate('plan', selectedValues[0])
						}
					}}
					validation={{
						isValid: (value, isUserInteracted) => {
							return {
								isValid: props.getField(`${props.index}/plan`).isValid,
								showValidationFlag: isUserInteracted || props.hasUserClickedNavButton,
							}
						},

						message: `You must select a plan`,
					}}
					forceRevalidation={props.hasUserClickedNavButton}
				/>

				<Dropdown<LicensedState>
					label="Licensed State"
					width={150}
					value={props.license.licensedState ? [props.license.licensedState] : []}
					options={getLicensedStateOptions()}
					onSelect={(selectedValues) => {
						if (selectedValues.length > 0) {
							props.onUpdate('licensedState', selectedValues[0])
						}
					}}
					validation={{
						isValid: (value, isUserInteracted) => {
							return {
								isValid: props.getField(`${props.index}/licensedStateId`).isValid,
								showValidationFlag: isUserInteracted || props.hasUserClickedNavButton,
							}
						},

						message: `You must select a state`,
					}}
					forceRevalidation={props.hasUserClickedNavButton}
				/>

				{regionsUnderSelectedState.length > 0 && (
					<Dropdown<LicensedRegion>
						label="Region"
						width={175}
						value={props.license.region ? [props.license.region] : []}
						options={getRegionOptions()}
						onSelect={(selectedValues) => {
							if (selectedValues.length > 0) {
								props.onUpdate('region', selectedValues[0])
							}
						}}
						validation={{
							isValid: (value, isUserInteracted) => {
								return {
									isValid: props.getField(`${props.index}/regionId`).isValid,
									showValidationFlag: isUserInteracted || props.hasUserClickedNavButton,
								}
							},

							message: `You must select a region`,
						}}
						forceRevalidation={props.hasUserClickedNavButton}
					/>
				)}
			</Box>
			<div className="flex flex-justifyContent-end">
				<Button
					variant={'text'}
					label={'Remove License'}
					size={'lg'}
					onClick={() => {
						props.onRemove()
					}}
				/>
			</div>
		</Paper>
	)
}

function mapStateToProps(state: RootState) {
	return {
		licensedStates: state.licensedStates,
	}
}

export const RegistrationAgentLicense = connect(mapStateToProps)(RegistrationAgentLicensePrototype)
