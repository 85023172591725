import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { AddOnServiceList } from '../../routes/administration/user-profile/components/subscription-components/add-on-service-list'
import CoverImg from './cover-img.jpg'
import { HomeSearchGrid } from './home-search__grid'

export default function LokationResourceHomeSearchAppFl() {
	return (
		<div className="flex-lg overflow-y__scroll">
			<div className="col-xs-12 col-lg-8 pr-40-md-down pr-20-lg flex flex-column flex-alignItems-start overflow-y__scroll">
				<img src={CoverImg} className="mb-20 col-xs-12" />
				<div className="text-center col-xs-12">
					<Typography type="h2">Finally, a tool with longevity!</Typography>
				</div>
				<HorizontalRule className="my-20" />
				<p>
					Give your clients a tool they can use for years to come. Your app is buyer, seller, renter, and
					investory friendly. They can search, save and set notifications for themselves for any and all
					searches their path may lead them down. All while being one touch away from you!
				</p>

				<HomeSearchGrid />

				<Typography type="h3">Speed and Accuracy</Typography>
				<p>
					One of the biggest complaints in the industry is that consumers are not always getting the most
					accurate or speediest delivery of data on some of the most popular real estate apps. Look no
					further! Your app will be updated within minutes of new listings hitting the market and the power of
					the MLS will be in the palm of your client's hands.
				</p>

				<Typography type="h3">Analytics that lead to more closed business</Typography>

				<div className="flex flex-wrap text-center col-xs-12 mb-10">
					<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
						<FontAwesomeIcon icon={['far', 'address-book']} size="3x" className="color__accent mb-10" />
						<strong>App user contact info (name, phone & email)</strong>
					</div>
					<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
						<FontAwesomeIcon
							icon={['far', 'heart-circle-check']}
							size="3x"
							className="color__accent mb-10"
						/>
						<strong>Properties viewed, favorited and shared</strong>
					</div>
					<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
						<FontAwesomeIcon icon={['far', 'note-sticky']} size="3x" className="color__accent mb-10" />
						<strong>Notes saved on properties</strong>
					</div>
					<div className="col-xs-12 col-lg-3 p-20-lg flex flex-column flex-alignItems-center">
						<FontAwesomeIcon icon={['far', 'circle-ellipsis']} size="3x" className="color__accent mb-10" />
						<strong>& much more!</strong>
					</div>
				</div>

				<p>
					Earn your client's trust by delivering a quality experience. These analytics create intimite
					knowledge of your user's behaviors and allows you to accelerate the conversion of your sales.
				</p>

				<Typography type="h5" variant={['center']}>
					Get started with your custom real estate app today for $15 / Month
				</Typography>

				<AddOnServiceList serviceIds={['HOME_SEARCH']} />
			</div>
			<div
				className="col-xs-12 col-lg-4 show-lg border-radius__std bg-color__adjust-alpha-10"
				style={{ backgroundImage: `url()`, backgroundSize: 'cover', overflow: 'hidden' }}
			></div>
		</div>
	)
}
