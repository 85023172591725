import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router'

import { HubsAPI } from '../../services/hubs/hubs.api'
import { Hub, HubSearchParams } from '../../services/hubs/hubs.types'
import { useItemAdminHook } from '../administration/use-item-admin-hook'
import { HubCard } from './hub__card/hub__card'

export default function AllHubsRoute() {
	const [, setIsHubLoadPending] = useState(true)
	const navigate = useNavigate()

	const hubHook = useItemAdminHook<Hub, HubSearchParams>({
		getItemsFunction: (page, size, sort, hubSearchParams) => {
			return HubsAPI.getAllHubs({ page, size, sort: [{ property: 'displayOrder', direction: 'asc' }] })
		},
		searchParams: {},
		itemIdKey: 'hubId',
		itemTitleKey: 'title',
		pageSize: 20,
		setIsPending: (state) => {
			setIsHubLoadPending(state)
		},
	})

	return (
		<RouteWrapperV2>
			<RouteBodyV2 id="all-hubs__route-body">
				<Typography type="h1" margins={['bottom']}>
					All Hubs
				</Typography>

				<div style={{ maxWidth: '800px' }}>
					<InfiniteScroll
						dataLength={hubHook.items.length}
						next={hubHook.loadNextPageOfItems}
						hasMore={hubHook.hasMoreItems}
						scrollableTarget={`all-hubs__route-body`}
						loader={
							<>
								<GenericContentLoader width={'fill'} height={60} className="mb-10" />
								<GenericContentLoader width={'fill'} height={60} className="mb-10" />
								<GenericContentLoader width={'fill'} height={60} className="mb-10" />
							</>
						}
					>
						{hubHook.items.map((hub) => {
							return (
								<HubCard
									hub={hub}
									onClick={() => {
										navigate(`/hubs/${hub.slug}`)
									}}
								/>
							)
						})}
					</InfiniteScroll>
				</div>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}
