import { Button } from '@components/button/button'
import { NewsFeedStory } from '@components/news-feed__story/news-feed__story'
import { Paper } from '@components/paper/paper'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { NewsFeedAPI } from 'src/services/news-feed/news-feed.api'

import { NewsFeed } from '../../services/news-feed/news-feed.types'

export function SingleNewsFeedStoryRoute() {
	const [newsFeedStory, setNewsFeedStory] = useState<NewsFeed.Story | null>(null)
	const params = useParams<{ storyId: string | undefined }>()

	useEffect(() => {
		if (!params.storyId) {
			return
		}
		NewsFeedAPI.getStory(parseFloat(params.storyId)).then((res) => {
			if (res.data) {
				setNewsFeedStory(res.data)
			}
		})
	}, [params.storyId])

	if (!newsFeedStory) {
		return <div>ERROR</div>
	}

	return (
		<RouteWrapperV2>
			<RouteBodyV2 className="mb-20 flex flex-column">
				<Button
					to="/news-feed"
					margins={['bottom']}
					label="All News"
					icon={'arrow-left'}
					iconPosition={'left'}
					variant={'text'}
					size={'lg'}
				/>
				<Paper bgColor="primary" padding={['all']} className="col-xs-12">
					<NewsFeedStory story={newsFeedStory} />
				</Paper>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}
