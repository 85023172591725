import { EmailNotificationComponent } from '@components/email-notification/email-notification'
import { Typography } from '@components/text/text'

import CoverImg from './free-transaction-coordinator.png'

export function FreeTransactionCoordinator() {
	return (
		<div className="flex flex-column overflow-y__scroll">
			<div className="flex flex-justifyContent-center mb-20">
				<div className="col-12 col-md-6">
					<img src={CoverImg} width="100%" />
				</div>
			</div>

			<div className="col-12 flex flex-column-xs-down flex-columnReverse-xs-down">
				<div className="col-12 col-md-3">
					<div className="col-12 mb-20 pr-10">
						<div className="col-12 mb-20">
							<Typography type="h6">The Closing Team</Typography>
						</div>
						<div className="mb-10">
							<strong>Use this Contact Information on Page 1 of the Contract</strong>
						</div>
						<div>1500 E Atlantic Blvd</div>
						<div>Pompano Beach, Florida 33060</div>
						<div>
							Phone: <a href="tel:954-289-1466">954-289-1466</a>
						</div>
						<div>
							Email: <a href="mailto:info@closingteamfl.com">info@closingteamfl.com</a>
						</div>
						<div>Fax: 954-301-3750</div>
					</div>
				</div>

				<div className="col-12 col-md-9">
					<div className="mb-10">
						It is scary to think of letting go, but it is essential in order for you to grow your business.
						You will love the level of service we bring to the table. A great deal of your time is spent
						researching and presenting homes for your customers and negotiating on their behalf.
					</div>
					<div className="mb-10">
						With the average real estate agent spending nearly 15 hours processing a contract to close
						instead of generating more deals... What many professionals have found is that investing those
						15 hours towards the generating and conversion of another happy homeowner is a better use of
						their time.
					</div>
					<div className="mb-10">
						Our job is to eliminate the administrative work for you, so you can market your business
						effectively without worrying about the details. Our coordinators have decades of experience and
						will work on your behalf to make sure every transaction proceeds smoothly. We are a team of
						professionals who care about your success and know the more you are out in the field driving new
						business, the more files we will handle for you.
					</div>

					<EmailNotificationComponent
						serviceToNotify={'freeTransactionCoordinator'}
						title="Are you interested?"
						buttonLabel="Get More Info"
						description="Sign up now, and LoKation will reach out to you shortly to discuss how we can elevate your success and drive your real estate business forward."
					/>
				</div>
			</div>
		</div>
	)
}
