import { CollapsibleContent } from '@components/collapsible-content/collapsible-content'

import { FAQ } from '../../services/faq/faq.types'

export function HubFAQ(props: FAQ) {
	return (
		<CollapsibleContent label={props.question} controlled={true} id={`faq__${props.faqId}`} headerPadding={['all']}>
			<div dangerouslySetInnerHTML={{ __html: props.answer }} />
		</CollapsibleContent>
	)
}
