import { Badge } from '@components/badge/badge'
import { Button } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { Typography } from '@components/text/text'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { RoutingService } from 'src/services/routing/routing.service'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { TransactionMgmt__Task__SideSheet } from '../side-sheets/task/task.side-sheet'
import { Transaction__TaskCard } from '../transaction/routes/overview/components/task-card'

export function TransactionMgmt__Landing__Section__DueTasks() {
	const navigate = useNavigate()

	const [dueTasks, setDueTasks] = useState<TransactionMgmtTypes.TaskTypes.Task[] | null>(null)
	const [showTaskSideSheet, setShowTaskSideSheet] = useState(false)
	const [selectedTask, setSelectedTask] = useState<TransactionMgmtTypes.TaskTypes.Task | null>(null)

	useEffect(() => {
		TransactionManagementAPI.findTasks().then((res) => {
			setDueTasks(res.data)
		})
	}, [])

	return (
		<>
			<div className="flex flex-alignItems-center mb-5">
				<Typography type="h6">Tasks due this week</Typography>
				<Badge value={12} size="sm" className="ml-10" />
			</div>
			{!dueTasks && <GenericContentLoader height={55} width={'fill'} />}
			{dueTasks &&
				dueTasks.map((task) => {
					return (
						<Transaction__TaskCard
							task={task}
							handleSelectItem={(thisTask) => {
								setSelectedTask(thisTask)
								setShowTaskSideSheet(true)
							}}
							showNewFlag={false}
							key={task.taskId}
						/>
					)
				})}
			<Button
				variant="text"
				label={'View All Tasks'}
				size="md"
				className="mb-40"
				onClick={() => {
					navigate(`${RoutingService.transactionMgmtRoutePath}/tasks`)
				}}
			/>
			{showTaskSideSheet && selectedTask && (
				<TransactionMgmt__Task__SideSheet
					mode={'EDITOR'}
					task={selectedTask}
					showParentTransaction={true}
					onClose={() => {
						setShowTaskSideSheet(false)
					}}
					onUpdate={(savedTask) => {
						setShowTaskSideSheet(false)
					}}
					onDelete={(taskId) => {
						setShowTaskSideSheet(false)
					}}
				/>
			)}
		</>
	)
}
