import { Typography } from '@components/text/text'

import toolkitCMA from '../../../../../lokation-routes/toolkit-cma/tkcma.png'

export function RegistrationToolkitCmaDesc() {
	return (
		<div className="col-xs-12">
			<img src={toolkitCMA} style={{ width: '200px' }} className="mb-20" />
			<Typography type="h6">
				Compelling presentations that provide you with attractive and effective, branded marketing material for
				every listing and selling occasion - created on demand in mere minutes via the Internet.
			</Typography>
		</div>
	)
}
