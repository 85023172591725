import { Button } from '@components/button/button'
import { ColorChip } from '@components/color-chip/color-chip'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { TextInput } from '@components/text-input/text-input'
import { getTags } from '@redux/reducers/tags-reducer'
import { store } from '@redux/store'
import { useState } from 'react'
import { ToastService } from 'src/services/toast/toast.service'

import { TagAPI } from '../../../services/tags/tag.api'
import { LokationTag } from '../../../services/tags/tag.types'
import { AdminItemEditorProps } from '../../../services/utility.types'
import { AdminEditorTitle, AdminEditorTitleProps } from '../components/editor-title'

export function EditTagRoute(props: AdminItemEditorProps<LokationTag>) {
	const [modifiedTagProps, setModifiedTagProps] = useState<Partial<LokationTag>>({})
	const mergedTagProps: LokationTag | Omit<LokationTag, 'tagId'> = { ...getOriginalTagProps(), ...modifiedTagProps }

	function getOriginalTagProps(): LokationTag | Omit<LokationTag, 'tagId'> {
		return props.item ? props.item : newTagProps()
	}

	function updateTagProp(key: keyof LokationTag, value: any): void {
		const updatedState = { ...modifiedTagProps, [key]: value }
		setModifiedTagProps(updatedState)
	}

	function newTagProps(): Omit<LokationTag, 'tagId'> {
		return {
			label: '',
			color: store.getState().theme.colorAccent,
		}
	}

	function createTag(): void {
		TagAPI.createTag(mergedTagProps).then((newTagRes) => {
			store.dispatch(getTags())
			ToastService.create({ type: 'SUCCESS', body: `Tag has been created` })
			if (props.onCreate) {
				props.onCreate(newTagRes.data)
			}
			props.dismissModal()
		})
	}

	function updateTag(): void {
		if ('tagId' in mergedTagProps) {
			const tagId = mergedTagProps.tagId
			TagAPI.updateTag(tagId, modifiedTagProps).then((updatedTagRes) => {
				store.dispatch(getTags())
				ToastService.create({ type: 'SUCCESS', body: `Tag has been updated` })
				if (props.onUpdate) {
					props.onUpdate(updatedTagRes.data)
				}
				props.dismissModal()
			})
		}
	}

	function getEditorTitleProps(): AdminEditorTitleProps<LokationTag> {
		return {
			itemLabel: 'Tag',
			item: props.item,
			itemIdKey: 'tagId',
			deleteItemFunction: TagAPI.deleteTag,
			onDelete: (item) => {
				if (props.onDelete) {
					props.onDelete(item)
				}
				props.dismissModal()
			},
		}
	}

	return (
		<Modal
			maxWidth={500}
			maxHeight={400}
			fixedHeight={false}
			className="flex flex-column"
			onClose={props.dismissModal}
		>
			<>
				<AdminEditorTitle {...getEditorTitleProps()} />

				<ModalBody>
					<TextInput
						label="Label"
						dataType="text"
						value={mergedTagProps.label}
						width="100%"
						margins={['bottom']}
						onChange={(updatedValue) => {
							updateTagProp('label', updatedValue)
						}}
					/>

					<div className="mb-20 flex flex-alignItems-center">
						<strong className="mr-10">Color</strong>
						<ColorChip
							color={mergedTagProps.color}
							handleChange={(color) => {
								updateTagProp('color', color)
							}}
							showSuggestions={true}
						/>
					</div>
				</ModalBody>

				<ModalFooter>
					<Button
						variant="outlined"
						size="md"
						primary={false}
						label="Cancel"
						margins={['right']}
						onClick={props.dismissModal}
					/>
					{props.item && <Button variant="contained" size="md" label="Update" onClick={updateTag} />}
					{!props.item && <Button variant="contained" size="md" label="Save" onClick={createTag} />}
				</ModalFooter>
			</>
		</Modal>
	)
}
