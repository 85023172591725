import './user-profile.scss'

import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'
import { IconButton } from '@components/icon-button/icon-button'
import { Modal } from '@components/modal/modal'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { UserAPI } from '../../services/user/user.api'
import { EndUserProps } from '../../services/user/user.types'
import { Utilities } from '../../services/utilities.service'
import lokationPin from './../../images/logo/lokation__mark.svg'

export function UserProfileModal(props: { userId: number; dismissModal?: () => void }) {
	const [userProps, setUserProps] = useState<EndUserProps | null>(null)
	const [showPhoto, setShowPhoto] = useState<boolean>(false)

	const navigate = useNavigate()

	useEffect(() => {
		UserAPI.getUserDetails(props.userId).then((res) => {
			setUserProps(res.data)
			setShowPhoto(res.data.hasProfilePicture)
		})
	}, [])

	function closeModal(): void {
		if (props.dismissModal) {
			props.dismissModal()
		} else {
			navigate(-1)
		}
	}

	function buildSocialMediaUrl(username: string, baseUrl: string) {
		// Check if the input is already a full URL
		if (username.startsWith('http') || username.startsWith('https')) {
			return username
		}
		// If it's just a username, append it to the base URL
		return `${baseUrl}${username}`
	}

	return (
		<Modal
			maxHeight={800}
			maxWidth={500}
			fixedHeight={false}
			className="flex flex-column p-20"
			onClose={closeModal}
		>
			<>
				<IconButton
					icon={'xmark'}
					style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 100 }}
					onClick={closeModal}
				/>
				{userProps && (
					<div className="flex flex-column flex-alignItems-center">
						{!showPhoto && (
							<div className="user-profile__photo mb-20">
								<img
									src={lokationPin}
									style={{ maxWidth: '50%', maxHeight: '50%', filter: 'saturate(0)', opacity: '0.5' }}
								/>
							</div>
						)}
						{showPhoto && (
							<div
								className="user-profile__photo mb-20"
								style={{ backgroundImage: `url(${UserAPI.getUserPhotoURL(props.userId)})` }}
							></div>
						)}
						<div className="col-12 text-center">
							<Typography type="h3">
								{userProps.firstName} {userProps.lastName}
							</Typography>
						</div>
						<div className="flex flex-alignItems-center">
							{userProps.city}{' '}
							<FontAwesomeIcon icon={['fas', 'circle']} style={{ width: '4px' }} className="mx-5" />{' '}
							{userProps.mailingState}
						</div>

						{userProps.bio && <div className="col-12 my-20 text-center">{userProps.bio}</div>}

						<HorizontalRule className="my-10" />
						<div className="flex flex-alignItems-center flex-column-md-down">
							<div className="flex flex-alignItems-center mr-30 mr-0-md-down mb-5-md-down">
								<FontAwesomeIcon icon={['fas', 'phone']} className="mr-5" />
								<span>{Utilities.formatPhoneNum(userProps.phone)}</span>
							</div>
							<div className="flex flex-alignItems-center">
								<FontAwesomeIcon icon={['fas', 'envelope']} className="mr-5" />
								<span>{userProps.email}</span>
							</div>
						</div>
						<HorizontalRule className="my-10" />
						{userProps.specialties && userProps.specialties.length > 0 && (
							<>
								<div className="flex flex-alignItems-center">
									<div className="flex flex-alignItems-center flex-column">
										<strong>Specialties</strong>
										<div className="flex flex-wrap flex-column-xs-down flex-alignItems-center flex-justifyContent-center">
											{userProps.specialties
												.sort((a, b) => a.specialtyName.localeCompare(b.specialtyName))
												.map((specialty, index) => (
													<span
														key={index}
														className={
															index !== userProps.specialties.length - 1 ? 'mr-5' : ''
														}
													>
														{specialty.specialtyName}
														{index !== userProps.specialties.length - 1 && (
															<span className="show-sm">, </span>
														)}
													</span>
												))}
										</div>
									</div>
								</div>
								<HorizontalRule className="my-10" />
							</>
						)}
						{userProps.languageSpecialties && userProps.languageSpecialties.length > 0 && (
							<>
								<div className="flex flex-alignItems-center">
									<div className="flex flex-alignItems-center flex-column">
										<strong>Languages</strong>
										<div className="flex flex-wrap flex-column-xs-down flex-alignItems-center flex-justifyContent-center">
											{userProps.languageSpecialties
												.sort((a, b) => a.languageName.localeCompare(b.languageName))
												.map((languageSpecialty, index) => (
													<span
														key={index}
														className={
															index !== userProps.specialties.length - 1 ? 'mr-5' : ''
														}
													>
														{languageSpecialty.languageName}
														{index !== userProps.languageSpecialties.length - 1 && (
															<span className="show-sm">, </span>
														)}
													</span>
												))}
										</div>
									</div>
								</div>
								<HorizontalRule className="my-10" />
							</>
						)}
						<div className="flex flex-wrap flex-justifyContent-center col-12 ml-5">
							{userProps.websiteUrl && (
								<a
									className="flex flex-alignItems-center mb-10 mr-10"
									href={userProps.websiteUrl}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon
										icon={['fas', 'globe']}
										style={{ width: '20px', height: '20px' }}
										className="mr-10 opacity-50"
									/>
									<span>Website</span>
								</a>
							)}
							{userProps.facebookUsername && (
								<a
									className="flex flex-alignItems-center mb-10 mr-10"
									href={buildSocialMediaUrl(userProps.facebookUsername, 'https://www.facebook.com/')}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon
										icon={['fab', 'facebook']}
										style={{ width: '20px', height: '20px' }}
										className="mr-10 opacity-50"
									/>
									<span>Facebook</span>
								</a>
							)}
							{userProps.linkedInUsername && (
								<a
									className="flex flex-alignItems-center mb-10 mr-10"
									href={buildSocialMediaUrl(
										userProps.linkedInUsername,
										'https://www.linkedin.com/in/',
									)}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon
										icon={['fab', 'linkedin']}
										style={{ width: '20px', height: '20px' }}
										className="mr-10 opacity-50"
									/>
									<span>LinkedIn</span>
								</a>
							)}
							{userProps.twitterUsername && (
								<a
									className="flex flex-alignItems-center mb-10 mr-10"
									href={buildSocialMediaUrl(userProps.twitterUsername, 'https://twitter.com/')}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon
										icon={['fab', 'x-twitter']}
										style={{ width: '20px', height: '20px' }}
										className="mr-10 opacity-50"
									/>
									<span>Twitter ("X")</span>
								</a>
							)}
							{userProps.instagramUsername && (
								<a
									className="flex flex-alignItems-center mb-10 mr-10"
									href={buildSocialMediaUrl(
										userProps.instagramUsername,
										'https://www.instagram.com/',
									)}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon
										icon={['fab', 'instagram']}
										style={{ width: '20px', height: '20px' }}
										className="mr-10 opacity-50"
									/>
									<span>Instagram</span>
								</a>
							)}
						</div>
					</div>
				)}
				{!userProps && (
					<>
						<GenericContentLoader width={'fill'} height={10} className="mb-10" />
						<GenericContentLoader width={'fill'} height={10} className="mb-10" />
						<GenericContentLoader width={'fill'} height={10} className="mb-10" />
					</>
				)}
			</>
		</Modal>
	)
}
