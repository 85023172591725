import { Button } from '@components/button/button'
import { RootState } from '@redux/store'
import { useEffect, useRef } from 'react'
import { QRCode } from 'react-qrcode-logo'
import { connect } from 'react-redux'
import { DomainTheme } from 'src/services/theme/theme.types'

import { EndUserProps } from '../../services/user/user.types'
import QRLogo from './lokation-qr-logo.png'

interface QRCodeProps {
	currentUser: EndUserProps | null
	theme: DomainTheme.CompleteTheme
}

function QRCodePrototype({ currentUser, theme }: QRCodeProps) {
	const canvasRef = useRef<HTMLCanvasElement | null>(null)

	const handleDownload = () => {
		if (canvasRef.current) {
			const link = document.createElement('a')
			link.href = canvasRef.current.toDataURL('image/png')
			link.download = 'qr-code.png'
			link.click()
		}
	}

	useEffect(() => {
		if (canvasRef.current && currentUser) {
			const canvas = canvasRef.current
			const context = canvas.getContext('2d')
			const qrCodeCanvas = document.querySelector('canvas')
			if (context && qrCodeCanvas) {
				// Clear the canvas
				canvas.width = qrCodeCanvas.width
				canvas.height = qrCodeCanvas.height
				context.clearRect(0, 0, canvas.width, canvas.height)

				// Draw the QR code
				context.drawImage(qrCodeCanvas, 0, 0)

				// Draw the logo with a white border
				const logo = new Image()
				logo.src = QRLogo
				logo.onload = () => {
					const logoSize = 200
					const borderSize = 10
					const logoX = (canvas.width - logoSize) / 2
					const logoY = (canvas.height - logoSize) / 2
					const borderX = logoX - borderSize
					const borderY = logoY - borderSize
					const borderSizeTotal = logoSize + 2 * borderSize

					// Draw white border
					context.fillStyle = 'white'
					context.fillRect(borderX, borderY, borderSizeTotal, borderSizeTotal)

					// Draw logo on top of the white border
					context.drawImage(logo, logoX, logoY, logoSize, logoSize)
				}
			}
		}
	}, [currentUser])

	return (
		<div className="flex-lg overflow-y__scroll flex flex-justifyContent-center flex-column flex-alignItems-center">
			<div style={{ maxWidth: '400px' }}>
				{currentUser && (
					<>
						<QRCode
							size={1000}
							style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
							value={`https://joinlokation.com/referral/?fname=${currentUser.firstName}&lname=${currentUser.lastName}&referral=${currentUser.endUserId}`}
							logoPaddingStyle="square"
							logoPadding={10}
							logoHeight={200}
							logoWidth={200}
							fgColor={theme.appHeaderBgColor}
							removeQrCodeBehindLogo={true}
							logoImage={QRLogo}
							id="canvas"
						/>
						<canvas ref={canvasRef} style={{ display: 'none' }} />
					</>
				)}
			</div>
			<p style={{ maxWidth: '600px' }}>
				Use your personalized QR code to refer new agents instantly! Simply open your QR code and let them scan
				it with their smartphone to start the joining process. You can also click "Download QR Code" to save and
				share it via email or text. Keep it handy for quick sharing at meetings and networking events!
			</p>
			<Button
				variant="contained"
				label="Download QR Code"
				size={'md'}
				onClick={handleDownload}
				margins={['top']}
				style={{ width: `200px` }}
			/>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
		theme: state.theme,
	}
}

export const QrCodeRoute = connect(mapStateToProps)(QRCodePrototype)
