import { Button } from '@components/button/button'
import { IconButton } from '@components/icon-button/icon-button'
import { TextInput } from '@components/text-input/text-input'
import _ from 'lodash'

interface LoginCarouselEditorProps {
	emailAddresses: string[]
	updateAddresses: (emailAddresses: string[]) => void
	className?: string
}

export function EmailAddressEditor(props: LoginCarouselEditorProps) {
	function handleRemoveAddress(index: number): void {
		let updatedState = _.cloneDeep(props.emailAddresses)
		updatedState.splice(index, 1)
		props.updateAddresses(updatedState)
	}

	function handleUpdateText(index: number, text: string): void {
		let updatedState = _.cloneDeep(props.emailAddresses)
		updatedState[index] = text
		props.updateAddresses(updatedState)
	}

	function handleAddAddress(): void {
		let updatedState = _.cloneDeep(props.emailAddresses)
		updatedState.push('')
		props.updateAddresses(updatedState)
	}

	function createNode(address: string, index: number): React.ReactElement {
		return (
			<div
				className={`bg-color__adjust-alpha-5 p-10 mb-10 flex flex-alignItems-center flex-justifyContent-spaceBetween`}
				key={index}
			>
				<TextInput
					placeholder="Enter text here"
					dataType="text"
					value={address}
					width="100%"
					onChange={(value) => {
						handleUpdateText(index, value)
					}}
					variant="unframed"
					margins={['right']}
				/>
				<IconButton
					icon={'trash'}
					onClick={() => {
						handleRemoveAddress(index)
					}}
				/>
			</div>
		)
	}

	return (
		<div className={`${props.className ? props.className : ''}`}>
			{props.emailAddresses.map((address, index) => {
				return createNode(address, index)
			})}
			{props.emailAddresses.length === 0 && (
				<div className="bg-color__adjust-alpha-5 p-10 mb-10">No email addresses have been defined</div>
			)}
			<Button
				icon={'plus'}
				iconPosition="left"
				label="Add address"
				variant="outlined"
				size="md"
				onClick={() => {
					handleAddAddress()
				}}
			/>
		</div>
	)
}
