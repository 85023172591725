import { Button } from '@components/button/button'
import { MultiColumnTableTypes } from '@components/multi-column-table/multi-column-table.types'
import { MultiColumnTable } from '@components/multi-column-table/multi-column-table__wrapper'
import { Typography } from '@components/text/text'
import { useState } from 'react'
import { TransactionMgmt__Locations } from 'src/services/transaction-mgmt/transaction-mgmt.static-data'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { TransactionMgmt__Location__SideSheet } from '../../side-sheets/location/location.side-sheet'

export function TransactionAdmin__Route__LocationAdmin() {
	const [selectedLocationId, setSelectedLocationId] = useState<number | 'NEW' | null>(null)
	const [showSideSheet, setShowSideSheet] = useState(false)

	function getTransactionTableColumns(): MultiColumnTableTypes.Column<TransactionMgmtTypes.LocationTypes.Location>[] {
		const columns: MultiColumnTableTypes.Column<TransactionMgmtTypes.LocationTypes.Location>[] = []

		columns.push({
			label: 'Name',
			columnId: 'name',
			value: 'name',
			displayIndex: 0,
			visible: true,
			width: 'fill',
		})

		return columns
	}

	return (
		<>
			<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween col-xs-12">
				<Typography type="h2">Locations</Typography>
				<Button variant="outlined" label="Create Location" size="md" />
			</div>
			<MultiColumnTable<TransactionMgmtTypes.LocationTypes.Location>
				columns={getTransactionTableColumns()}
				idKey={'locationId'}
				className="col-xs-12"
				items={TransactionMgmt__Locations}
				isConfigurable={false}
				onSelect={(location) => {
					setSelectedLocationId(location[0].locationId)
					setShowSideSheet(true)
				}}
				selectBehavior={'single'}
				selectedItems={[]}
				sortBehavior={{
					sortMethod: 'column-value',
					columnId: 'name',
				}}
				tableId={''}
			/>

			{showSideSheet && selectedLocationId && (
				<TransactionMgmt__Location__SideSheet
					mode={'EDITOR'}
					locationId={selectedLocationId}
					showParentTransaction={false}
					onClose={() => {
						setShowSideSheet(false)
					}}
					onUpdate={() => {
						/** empty */
					}}
					onDelete={() => {
						/** empty */
					}}
				/>
			)}
		</>
	)
}
