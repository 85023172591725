import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'

export function AlabamaDepartmentContacts() {
	return (
		<Paper bgColor="primary" padding={['all']}>
			<div className="flex flex-wrap flex-justifyContent-center mb-20">
				<div className="col-12 col-md-4 pr-20 pr-0-md-down mb-20-md-down">
					<div>
						<div className="col-12 my-20 text-center">
							<Typography type="h6">Department Email Contacts</Typography>
						</div>
						<ul>
							<li>
								Compliance / Support - <a href="mailto:romany@lokationre.com">romany@lokationre.com</a>{' '}
								/ <a href="mailto:doris@lokationre.com">doris@lokationre.com</a>
							</li>
							<li>
								Broker Specific Needs - <a href="mailto:doris@lokationre.com">doris@lokationre.com</a>
							</li>
							<li>
								Document Submission - <a href="mailto:docs-ga@lokationre.com">docs-ga@lokationre.com</a>
							</li>
							<li>
								Commission / Payments -{' '}
								<a href="mailto:payments@lokationre.com">payments@lokationre.com</a>
							</li>
							<li>
								Billing Inquiries - <a href="mailto:billing@lokationre.com">billing@lokationre.com</a>
							</li>
							<li>
								The Mortgage Center -{' '}
								<a href="mailto:mortgage@lokationre.com">mortgage@lokationre.com</a>
							</li>
							<li>
								Programs - <a href="maillto:programs@lokationre.com">programs@lokationre.com</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="col-12 col-md-4 pr-20 pr-0-md-down mb-20-md-down">
					<div>
						<div className="col-12 my-20 text-center">
							<Typography type="h6">Support</Typography>
						</div>
						<ul>
							<li>
								Phone: <a href="tel:205-775-1628">205-775-1628</a>
							</li>
							<li>Online Chat - Available in the bottom right corner of your window</li>
							<li>
								Set an Appointment with an Online Calendar for Agents:{' '}
								<a
									href="https://appt.link/dir-of-operations-se-region-ga-al-sc"
									target="_blank"
									rel="noreferrer"
								>
									Click to make appointment
								</a>
							</li>
							<li>
								Live Zoom Room for Support questions (available M-F 9am-5pm){' '}
								<a href="https://lokationsupport.com" target="_blank" rel="noreferrer">
									Click for Zoom
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="col-12 col-md-4">
					<div className="col-12 my-20 text-center">
						<div className="col-12 my-20 text-center">
							<Typography type="h6">Mailing Address</Typography>
						</div>
						<div>4000 Eagle Point Corporate Dr., Suite 550</div>
						<div>Birmingham, Alabama 35242</div>
						<div>
							<a href="tel:205-775-1628">205-775-1628</a>
						</div>
						<div className="flex flex-justifyContent-center flex-column-md-down">
							<a href="https://WWW.LOKATIONRE.COM" target="_blank" rel="noreferrer">
								WWW.LOKATIONRE.COM
							</a>{' '}
							<span className="show-lg mx-5">|</span>{' '}
							<a href="mailto:INFO@LOKATIONRE.COM">INFO@LOKATIONRE.COM</a>
						</div>
					</div>
				</div>
			</div>
		</Paper>
	)
}
