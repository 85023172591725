import './switch.scss'

import { Typography } from '@components/text/text'
import { Tooltip } from '@components/tooltip/tooltip'

import { SwitchTypes } from './switch.types'

/** A pill-shaped boolean switch */
export function Switch(props: SwitchTypes.Component) {
	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push('switch')

		if (props.disabled) {
			classes.push(`is-disabled`)
		}

		if (props.checked) {
			classes.push('is-checked')
		}

		if (!props.label && !props.tooltip) {
			if (props.className) {
				classes.push(props.className)
			}
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	const TOGGLE_SWITCH_ELT = (
		<div
			className={getClass()}
			onClick={() => {
				if (props.disabled) {
					return
				}

				props.onChange(!props.checked)
			}}
			onKeyDown={(evt) => {
				if (props.disabled) {
					return
				}
				if (evt.key === 'Enter') {
					evt.stopPropagation()
					props.onChange(!props.checked)
				}
			}}
			tabIndex={0}
		>
			<div className="switch__handle"></div>
		</div>
	)

	if (props.label || props.tooltip) {
		return (
			<div className={`flex flex-alignItems-center ${props.className ? props.className : ''}`}>
				{TOGGLE_SWITCH_ELT}
				{props.label && (
					<Typography type="semibold" className="ml-10">
						{props.label}
					</Typography>
				)}
				{props.tooltip && <Tooltip className="ml-5" body={props.tooltip} />}
			</div>
		)
	} else {
		return TOGGLE_SWITCH_ELT
	}
}
