import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'

import { useDomainAdmin, useDomainAdminDispatch } from '../state/domain-admin__state'

export function DomainAdminGeneralSection() {
	const domainAdminState = useDomainAdmin()
	const domainAdminDispatch = useDomainAdminDispatch()

	return (
		<>
			<Typography type="h1" margins={['bottom']}>
				General
			</Typography>
			<Paper bgColor="primary" padding={['all']} marginSize="section" margins={['bottom']}>
				<TextInput
					width={300}
					dataType="text"
					label="Label"
					value={domainAdminState.modified.general.label}
					onChange={(updatedValue) => {
						domainAdminDispatch({
							type: 'update-general-fields',
							payload: [{ key: 'label', value: updatedValue }],
						})
					}}
					margins={['bottom', 'right']}
				/>
			</Paper>
		</>
	)
}
