import './resource-card.scss'

import { Popover } from '@components/popover/popover'
import { ResourceCardResource } from '@components/resource-card-list/resource-card-list'
import { Typography } from '@components/text/text'
import { TooltipPopover } from '@components/tooltip/tooltip-popover'
import { YouTubeModal } from '@components/youtube-modal/youtube-modal'
import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useNavigate, useSearchParams } from 'react-router'
import { UsePopoverOnEvent } from 'src/services/hooks/use-popover-on-event'
import { ResourceService } from 'src/services/resources/resources.service'

import { AnalyticsService } from '../../services/analytics/analytics.service'
import { Utilities } from '../../services/utilities.service'
import { Paper } from '../paper/paper'
import { ResourceCard__ExternalWebsiteWarning } from './components/resource-card__external-website-warning'
import { ResourceCard__FloatingTags } from './components/resource-card__floating-tags'
import { ResourceCard__Image } from './components/resource-card__image'

export namespace ResourceCardTypes {
	export type Style = 'STANDARD_CARD' | 'LIST' | 'WIDE_CARD'
}

export interface ComponentProps {
	cardStyle: ResourceCardTypes.Style
	resource: ResourceCardResource
}

export function ResourceCard(props: ComponentProps) {
	const [searchParams, setSearchParams] = useSearchParams()
	const navigate = useNavigate()
	const [isTooltipPopoverOpen, setIsTooltipPopoverOpen] = useState(false)
	const [hasUserClicked, setHasUserClicked] = useState(false)
	const [showYouTubeModal, setShowYouTubeModal] = useState(false)
	const isExternalUrl = ResourceService.getIsExternalUrl(props.resource)
	const isExternalUrlYouTubeVideo = ResourceService.getIsExternalUrlYouTubeVideo(props.resource)
	const resourceCardRef = useRef<HTMLDivElement>(null)
	const popoverProps = UsePopoverOnEvent({
		isPopoverOpen: isTooltipPopoverOpen,
		setIsPopoverOpen: setIsTooltipPopoverOpen,
		delay: 500,
	})

	/** Remove click scrim */
	useEffect(() => {
		if (setHasUserClicked) {
			setTimeout(() => {
				setHasUserClicked(false)
			}, 3000)
		}
	}, [hasUserClicked])

	function pushClickEventToAnalytics(): void {
		AnalyticsService().pushEvent({
			event_category: 'navigation',
			event_label: 'open_resource',
			value: {
				resource_id: props.resource.resourceId,
				resource_title: props.resource.title,
			},
		})
	}

	function openInternalResource(): void {
		if (typeof props.resource.url !== 'string') {
			return
		}

		if (props.resource.url.includes('/resources')) {
			searchParams.set('resource', String(props.resource.url))
			setSearchParams(searchParams)
		} else {
			navigate(props.resource.url)
		}
	}

	function handleExternalUrlClick() {
		setHasUserClicked(true)
		pushClickEventToAnalytics()

		if (props.resource.onClick) {
			props.resource.onClick()
		}

		if (isExternalUrl && props.resource.url && !isExternalUrlYouTubeVideo) {
			setTimeout(() => {
				window.open(props.resource.url, '_blank')
			}, 1500)
		}

		if (isExternalUrl && props.resource.url && isExternalUrlYouTubeVideo) {
			setShowYouTubeModal(true)
		}

		if (!isExternalUrl && props.resource.url && !isExternalUrlYouTubeVideo) {
			window.open(props.resource.url, '_self')
		}
	}

	function handleClick(): void {
		pushClickEventToAnalytics()
		if (isExternalUrl) {
			handleExternalUrlClick()
		}

		if (!isExternalUrl) {
			openInternalResource()
		}

		if (props.resource.onClick) {
			props.resource.onClick()
		}
	}

	function handleKeyDown(evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void {
		if (evt.key === 'Enter') {
			if (isExternalUrl) {
				handleExternalUrlClick()
			}
		}
	}

	function renderCard(): React.ReactElement {
		switch (props.cardStyle) {
			case 'STANDARD_CARD':
				return (
					<Paper
						bgColor="primary"
						tabIndex={0}
						className={`resource-card large`}
						onClick={handleClick}
						onKeyDown={handleKeyDown}
						onMouseEnter={() => {
							setIsTooltipPopoverOpen(true)
						}}
						onMouseLeave={() => {
							setIsTooltipPopoverOpen(false)
						}}
						ref={resourceCardRef}
					>
						<ResourceCard__FloatingTags
							isExternalUrl={isExternalUrl}
							isExternalUrlYouTubeVideo={isExternalUrlYouTubeVideo}
							isSponsored={
								typeof props.resource.isSponsored === 'boolean' ? props.resource.isSponsored : false
							}
							isPublished={props.resource.published}
						/>
						<ResourceCard__Image resourceCard={props.resource} />
						<Typography type="h6" color="primary">
							{props.resource.title}
						</Typography>
						<Typography type="normal" color="secondary">
							{Utilities.truncateString(props.resource.description, 120)}
						</Typography>
					</Paper>
				)
			case 'WIDE_CARD':
				return (
					<Paper
						bgColor="primary"
						tabIndex={0}
						padding={['all']}
						className={`resource-card subresource col2`}
						onClick={handleClick}
						onKeyDown={handleKeyDown}
						onMouseEnter={() => {
							setIsTooltipPopoverOpen(true)
						}}
						onMouseLeave={() => {
							setIsTooltipPopoverOpen(false)
						}}
						ref={resourceCardRef}
					>
						<ResourceCard__Image resourceCard={props.resource} />
						<div className="resource-card__description">
							<Typography type="h6" color="primary">
								{props.resource.title}
							</Typography>
							<Typography type="normal" color="secondary">
								{props.resource.description}
							</Typography>
						</div>
					</Paper>
				)
			case 'LIST':
				return (
					<Paper
						bgColor="primary"
						margins={['bottom']}
						padding={['all']}
						className="resource-card search-results col-xs-12 flex flex-alignItems-center"
						onClick={handleClick}
						onKeyDown={handleKeyDown}
						onMouseEnter={() => {
							setIsTooltipPopoverOpen(true)
						}}
						onMouseLeave={() => {
							setIsTooltipPopoverOpen(false)
						}}
						style={{ minHeight: '90px' }}
						ref={resourceCardRef}
					>
						<ResourceCard__FloatingTags
							isExternalUrl={isExternalUrl}
							isExternalUrlYouTubeVideo={isExternalUrlYouTubeVideo}
							isSponsored={
								typeof props.resource.isSponsored === 'boolean' ? props.resource.isSponsored : false
							}
							isPublished={props.resource.published}
						/>
						<div className={`resource-card__description ${!props.resource.published ? 'opacity-50' : ''}`}>
							<Typography type="h6" color="primary">
								{props.resource.title}
							</Typography>
							<Typography type="normal" color="secondary">
								{Utilities.truncateString(props.resource.description, 200)}
							</Typography>
						</div>
					</Paper>
				)
		}
	}

	return (
		<>
			{renderCard()}
			{hasUserClicked && !isExternalUrlYouTubeVideo && (
				<>
					{ReactDOM.createPortal(
						<ResourceCard__ExternalWebsiteWarning url={props.resource.url} />,
						document.body,
					)}
				</>
			)}
			{showYouTubeModal && props.resource.url && (
				<YouTubeModal
					onHide={() => {
						setShowYouTubeModal(false)
					}}
					title={props.resource.title}
					url={props.resource.url}
					description={props.resource.description}
				/>
			)}
			{resourceCardRef.current && (
				<Popover
					hideOnMouseOut={true}
					hideOnClickOutside={true}
					isScrimVisible={false}
					{...popoverProps}
					refElement={resourceCardRef.current}
					setShowPopover={(newState) => {
						setIsTooltipPopoverOpen(newState)
					}}
					options={{ placement: 'top-start', strategy: 'absolute' }}
				>
					<TooltipPopover>
						<div className="col-xs-12 text-center">{props.resource.description}</div>
					</TooltipPopover>
				</Popover>
			)}
		</>
	)
}
