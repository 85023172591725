import { Button } from '@components/button/button'
import { MultiColumnTableTypes } from '@components/multi-column-table/multi-column-table.types'
import { MultiColumnTable } from '@components/multi-column-table/multi-column-table__wrapper'
import { Typography } from '@components/text/text'
import { useEffect, useState } from 'react'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { TransactionMgmt__Task__SideSheet } from '../../side-sheets/task/task.side-sheet'

export function TransactionAdmin__Route__TaskAdmin() {
	const [selectedTask, setSelectedTask] = useState<TransactionMgmtTypes.TaskTypes.Task | 'NEW' | null>(null)
	const [showSideSheet, setShowSideSheet] = useState(false)
	const [allTasks, setAllTasks] = useState<TransactionMgmtTypes.TaskTypes.Task[] | null>(null)

	useEffect(() => {
		TransactionManagementAPI.findLibraryTasks().then((res) => {
			setAllTasks(res.data)
		})
	}, [])

	function getTransactionTableColumns(): MultiColumnTableTypes.Column<TransactionMgmtTypes.TaskTypes.Task>[] {
		const columns: MultiColumnTableTypes.Column<TransactionMgmtTypes.TaskTypes.Task>[] = []

		columns.push({
			label: 'Name',
			columnId: 'name',
			value: 'name',
			displayIndex: 0,
			visible: true,
			width: 'fill',
		})

		return columns
	}

	return (
		<>
			<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween col-xs-12">
				<Typography type="h2">Tasks</Typography>
				<Button variant="outlined" label="Create Task" size="md" />
			</div>
			<MultiColumnTable<TransactionMgmtTypes.TaskTypes.Task>
				columns={getTransactionTableColumns()}
				idKey={'taskId'}
				className="col-xs-12"
				items={allTasks}
				isConfigurable={false}
				onSelect={(task) => {
					setSelectedTask(task[0])
					setShowSideSheet(true)
				}}
				selectBehavior={'single'}
				selectedItems={[]}
				sortBehavior={{
					sortMethod: 'column-value',
					columnId: 'name',
				}}
				tableId={''}
			/>

			{showSideSheet && selectedTask && (
				<TransactionMgmt__Task__SideSheet
					mode={'EDITOR'}
					task={typeof selectedTask !== 'string' ? selectedTask : null}
					showParentTransaction={false}
					onClose={() => {
						setShowSideSheet(false)
					}}
					onUpdate={() => {
						/** empty */
					}}
					onDelete={() => {
						/** empty */
					}}
				/>
			)}
		</>
	)
}
