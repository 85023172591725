import { Box } from '@components/box/box'
import { store } from '@redux/store'
import React from 'react'

import { LokationTag } from '../../services/tags/tag.types'
import { Button } from '../button/button'
import { CheckboxList } from '../checkbox-list/checkbox-list'
import { CollapsibleContent } from '../collapsible-content/collapsible-content'

export interface ItemSearchTagFilterProps {
	selectedTags: LokationTag[]
	onChange: (selectedTags: LokationTag[]) => void
	style?: React.CSSProperties
	className?: string
}

export function ItemSearchTagFilter(props: ItemSearchTagFilterProps) {
	return (
		<CollapsibleContent label="Tags" controlled={true} id={`item-search__tags`} headerPadding={['all']}>
			<>
				<div className={`base-search-filter ${props.className ? props.className : ''}`} style={props.style}>
					<CheckboxList
						onChange={props.onChange}
						selectedItems={props.selectedTags}
						allItems={store.getState().tags}
						itemIdKey={'tagId'}
						itemLabelKey={'label'}
						optionClassName="col-xs-12"
					/>
				</div>
				<Box flex="row" gap={true}>
					<Button
						variant="text"
						size="md"
						label="Select None"
						onClick={() => {
							props.onChange([])
						}}
					/>
					<Button
						variant="text"
						size="md"
						label="Select All"
						onClick={() => {
							props.onChange(store.getState().tags)
						}}
					/>
				</Box>
			</>
		</CollapsibleContent>
	)
}
