import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { Utilities } from '../utilities.service'
import { GetItemsParams } from '../utility.types'
import { MessageDraftTypes } from './message-drafts.types'

export function MessageDraftsAPI() {
	function createDraft(
		messageDraft: MessageDraftTypes.CreateMessageDraftDTO,
	): Promise<AxiosResponse<MessageDraftTypes.MessageDraft>> {
		const headers = {}
		/** Set params */
		const params = {}
		let updatedMessageDraft

		// Convert endUsersByPaymentMethod from number array to boolean (0 to false, 1 to true)
		if (messageDraft.endUsersByPaymentMethod && messageDraft.endUsersByPaymentMethod.length > 0) {
			updatedMessageDraft = {
				...messageDraft,
				endUsersByPaymentMethod: Boolean(messageDraft.endUsersByPaymentMethod[0]),
			}
		} else {
			updatedMessageDraft = {
				...messageDraft,
				endUsersByPaymentMethod: null,
			}
		}

		const requestPromise = httpRequest.post(
			`${RequestBaseURL}${apiVersionURL}/chatMessageDrafts/`,
			updatedMessageDraft,
			{
				headers,
				params,
			},
		)
		return requestPromise
	}

	function updateDraft(
		messageDraft: MessageDraftTypes.MessageDraft,
	): Promise<AxiosResponse<MessageDraftTypes.MessageDraft>> {
		const headers = {}
		/** Set params */
		const params = {}
		let updatedMessageDraft

		// Convert endUsersByPaymentMethod from number array to boolean (0 to false, 1 to true)
		if (messageDraft.endUsersByPaymentMethod && messageDraft.endUsersByPaymentMethod.length > 0) {
			updatedMessageDraft = {
				...messageDraft,
				endUsersByPaymentMethod: Boolean(messageDraft.endUsersByPaymentMethod[0]),
			}
		} else {
			updatedMessageDraft = {
				...messageDraft,
				endUsersByPaymentMethod: null,
			}
		}

		const requestPromise = httpRequest.put(
			`${RequestBaseURL}${apiVersionURL}/chatMessageDrafts/${messageDraft.chatMessageDraftId}`,
			updatedMessageDraft,
			{
				headers,
				params,
			},
		)
		return requestPromise
	}

	function sendDraft(draftId: number): Promise<AxiosResponse<MessageDraftTypes.SentMessageDraftResponeDTO>> {
		const headers = {}

		const requestPromise = httpRequest.post(`${RequestBaseURL}${apiVersionURL}/chatMessageDrafts/${draftId}/send`, {
			headers,
		})
		return requestPromise
	}

	function getDrafts(
		includeSent: boolean,
		props: GetItemsParams<MessageDraftTypes.MessageDraft>,
	): Promise<AxiosResponse<MessageDraftTypes.MessageDraft[]>> {
		const headers = {}
		/** Set params */
		const params = Utilities.createSearchParamsFromObj({ ...props })
		params.append('includeSent', String(includeSent))

		const requestPromise = httpRequest.get(`${RequestBaseURL}${apiVersionURL}/chatMessageDrafts`, {
			headers,
			params,
		})
		return requestPromise
	}

	function deleteDraft(props: { messageDraftId: number }): Promise<AxiosResponse<null>> {
		const headers = {}
		const requestPromise = httpRequest.delete(
			`${RequestBaseURL}${apiVersionURL}/chatMessageDrafts/${props.messageDraftId}`,
			{
				headers,
			},
		)
		return requestPromise
	}

	return { createDraft, updateDraft, sendDraft, deleteDraft, getDrafts }
}
