import _ from 'lodash'
import { createContext, useContext, useEffect, useReducer } from 'react'
import { useSearchParams } from 'react-router'
import { ToastService } from 'src/services/toast/toast.service'

import { LeadCaptureAPI } from '../../../services/lead-capture/lead-capture.api'
import { LeadCaptureTypes } from '../../../services/lead-capture/lead-capture.types'
import { LicensedState } from '../../../services/licensed-states/licenses-states.types'
import { MLSBoardAPI } from '../../../services/mls-board/mls-board.api'
import { MLSBoard } from '../../../services/mls-board/mls-board.types'
import { ReferralTypeAPI } from '../../../services/registration/referral-type.api'
import { AgentLicense } from '../../../services/user/user.types'
import { RegistrationService } from '../registration.service'
import { RegistrationState } from './registration__state.types'

function defaultEmptyState(): RegistrationState.LocalState {
	return {
		activeStep: 0,
		profilePhoto: null,
		fields: {
			birthDate: '',
			address1: '',
			address2: '',
			city: '',
			email: '',
			expDate: '',
			firstName: '',
			referralTypeId: null,
			lastName: '',
			licenseInfo: [],
			mlsBoard: [],
			numYearsLicensed: '',
			optionalServices: [],
			notificationsText: false,
			notificationsEmail: false,
			password: '',
			phone: null,
			repeatPassword: '',
			state: '',
			zip: null,
			bio: '',
			twitterUsername: '',
			facebookUsername: '',
			instagramUsername: '',
			linkedInUsername: '',
			profilePicture: null,
			referralAdditionalInformation: '',
			businessName: '',
			endUserId: null,
			middleName: '',
			referrerId: '',
		},
		isSectionValidated: false,
		clientSecret: null,
		customerId: null,
		mlsOptions: [],
		referralOptions: [],
		licenseAgreements: [],
		isAccountCreated: false,
		paymentFormSubmit: false,
		isPaymentSuccessful: 'default',
		signature: '',
		isLuxuryServiceSelected: false,
		hasUserClickedNavButton: false,
		utmParameters: {
			promoCode: '',
			utmSource: '',
			utmMedium: '',
			utmCampaign: '',
			utmContent: '',
			utmTerm: '',
		},
	}
}

const RegistrationContext = createContext(defaultEmptyState())
const RegistrationDispatchContext = createContext({} as RegistrationState.DispatchParams)

function RegistrationReducer(state: RegistrationState.LocalState, action: RegistrationState.Action) {
	switch (action.type) {
		case 'update-fields': {
			let updatedState = _.cloneDeep(state)
			action.payload.forEach((fieldChange) => {
				updatedState.fields = { ...updatedState.fields, [fieldChange.key]: fieldChange.value }
			})
			return updatedState
		}
		case 'show-page': {
			let updatedState = _.cloneDeep(state)
			updatedState.activeStep = action.payload
			return updatedState
		}
		case 'set-validation-state': {
			let updatedState = _.cloneDeep(state)
			updatedState.isSectionValidated = action.payload
			return updatedState
		}
		case 'set-profile-photo': {
			let updatedState = _.cloneDeep(state)
			updatedState.profilePhoto = action.payload
			return updatedState
		}
		case 'set-stripe-client-secret': {
			let updatedState = _.cloneDeep(state)
			updatedState.clientSecret = action.payload
			return updatedState
		}
		case 'set-stripe-customer-id': {
			let updatedState = _.cloneDeep(state)
			updatedState.customerId = action.payload
			return updatedState
		}
		case 'set-referral-type-options': {
			let updatedState = _.cloneDeep(state)
			updatedState.referralOptions = action.payload
			return updatedState
		}
		case 'set-mls-options': {
			let updatedState = _.cloneDeep(state)
			updatedState.mlsOptions = action.payload
			return updatedState
		}
		case 'set-contract-agreement-state': {
			let updatedState = _.cloneDeep(state)
			const licenseIndex = updatedState.licenseAgreements.findIndex(
				(agreement) => agreement.stateId === action.payload.stateId,
			)
			if (licenseIndex > -1) {
				updatedState.licenseAgreements[licenseIndex] = action.payload
			} else {
				updatedState.licenseAgreements.push(action.payload)
			}
			return updatedState
		}

		case 'set-is-account-created': {
			let updatedState = _.cloneDeep(state)
			updatedState.isAccountCreated = action.payload
			return updatedState
		}
		case 'set-payment-form-submit': {
			let updatedState = _.cloneDeep(state)
			updatedState.paymentFormSubmit = action.payload
			return updatedState
		}
		case 'set-is-payment-successful': {
			let updatedState = _.cloneDeep(state)
			updatedState.isPaymentSuccessful = action.payload
			return updatedState
		}
		case 'update-signature': {
			let updatedState = _.cloneDeep(state)
			updatedState.signature = action.payload
			return updatedState
		}
		case 'set-is-luxury-service-selected': {
			let updatedState = _.cloneDeep(state)
			updatedState.isLuxuryServiceSelected = action.payload
			return updatedState
		}
		case 'set-has-user-clicked-nav-button': {
			let updatedState = _.cloneDeep(state)
			updatedState.hasUserClickedNavButton = action.payload
			return updatedState
		}
		case 'set-utm-parameters': {
			let updatedState = _.cloneDeep(state)
			action.payload.forEach((UtmParameterChange) => {
				updatedState.utmParameters = {
					...updatedState.utmParameters,
					[UtmParameterChange.key]: UtmParameterChange.value,
				}
			})
			return updatedState
		}
	}
}

export function RegistrationProvider(props: { licensedStates: LicensedState[]; children: React.ReactNode }) {
	const [state, dispatch] = useReducer(RegistrationReducer, loadInitialState())
	/** Prefill the registration form if search params are passed into the URL */
	let [searchParams] = useSearchParams()

	/** ========================== */
	/** Effects */

	useEffect(() => {
		localStorage.setItem('registrationState', JSON.stringify(state))
	}, [state])

	useEffect(() => {
		const fieldChanges: RegistrationState.FieldChange[] = []
		const fieldChangePromises: Promise<void>[] = []
		const utmParameterChanges: RegistrationState.UtmParameterChange[] = []

		let stateSearchParamValue = null
		let fnameSearchParamValue = null
		let lnameSearchParamValue = null
		let emailSearchParamValue = null
		let phoneSearchParamValue = null
		let promotionSearchParamValue = null
		let referralSearchParamValue = null
		let utmSourceSearchParamValue = null
		let utmMediumSearchParamValue = null
		let utmCampaignSearchParamValue = null
		let utmContentSearchParamValue = null
		let utmTermSearchParamValue = null

		/** Populate FieldChanges */
		searchParams.forEach((value, key) => {
			const fieldChangePromise = new Promise<void>((resolve) => {
				if (key === 'state') {
					stateSearchParamValue = searchParams.get('state')
					if (typeof stateSearchParamValue === 'string') {
						fieldChanges.push({ key: 'state', value: stateSearchParamValue })
					}

					/** Select the plan indicated in search params, if one is provided */
					let selectedPlan: AgentLicense.PlanType = 'BLUE'
					const planSearchParamValue = searchParams.get('plan')
					if (planSearchParamValue) {
						if (planSearchParamValue.toLocaleLowerCase() === 'blue') {
							selectedPlan = 'BLUE'
						}
						if (planSearchParamValue.toLocaleLowerCase() === 'silver') {
							selectedPlan = 'SILVER'
						}
					}

					RegistrationService()
						.createNewLicenseProps(props.licensedStates, value, selectedPlan)
						.then((newLicense) => {
							fieldChanges.push({ key: 'licenseInfo', value: [newLicense] })
							resolve()
						})
				} else if (key === 'service') {
					if (value === 'LUXURY') {
						dispatch({ type: 'set-is-luxury-service-selected', payload: true })
					} else {
						fieldChanges.push({ key: 'optionalServices', value: [value] })
					}
					resolve()
				} else if (key === 'fname') {
					fnameSearchParamValue = searchParams.get('fname')
					if (typeof fnameSearchParamValue === 'string') {
						fieldChanges.push({ key: 'firstName', value: fnameSearchParamValue })
					}
					resolve()
				} else if (key === 'lname') {
					lnameSearchParamValue = searchParams.get('lname')
					if (typeof lnameSearchParamValue === 'string') {
						fieldChanges.push({ key: 'lastName', value: lnameSearchParamValue })
					}
					resolve()
				} else if (key === 'email') {
					emailSearchParamValue = searchParams.get('email')
					if (typeof emailSearchParamValue === 'string') {
						fieldChanges.push({ key: 'email', value: emailSearchParamValue })
					}
					resolve()
				} else if (key === 'state') {
					stateSearchParamValue = searchParams.get('state')
					if (typeof stateSearchParamValue === 'string') {
						fieldChanges.push({ key: 'state', value: stateSearchParamValue })
					}
					resolve()
				} else if (key === 'phone') {
					phoneSearchParamValue = searchParams.get('phone')
					console.log('Phone: ', phoneSearchParamValue)
					if (typeof phoneSearchParamValue === 'string') {
						const formattedPhone = phoneSearchParamValue.replace(/[-()\s]/g, '')
						if (typeof formattedPhone === 'string') {
							fieldChanges.push({ key: 'phone', value: formattedPhone })
						}
					}
					resolve()
				} else if (key === 'promotion') {
					promotionSearchParamValue = searchParams.get('promotion')
					if (typeof promotionSearchParamValue === 'string') {
						utmParameterChanges.push({ key: 'promoCode', value: promotionSearchParamValue })
						localStorage.setItem('promotionCode', promotionSearchParamValue)
						console.log(localStorage.getItem('promotionCode'))
					}
				} else if (key === 'referral') {
					referralSearchParamValue = searchParams.get('referral')
					if (typeof referralSearchParamValue === 'string') {
						fieldChanges.push({ key: 'referrerId', value: referralSearchParamValue })
					}
					resolve()
				} else if (key === 'utm_source') {
					utmSourceSearchParamValue = searchParams.get('utm_source')
					if (typeof utmSourceSearchParamValue === 'string') {
						utmParameterChanges.push({ key: 'utmSource', value: utmSourceSearchParamValue })
					}
					resolve()
				} else if (key === 'utm_medium') {
					utmMediumSearchParamValue = searchParams.get('utm_medium')
					if (typeof utmMediumSearchParamValue === 'string') {
						utmParameterChanges.push({ key: 'utmMedium', value: utmMediumSearchParamValue })
					}
					resolve()
				} else if (key === 'utm_campaign') {
					utmCampaignSearchParamValue = searchParams.get('utm_campaign')
					if (typeof utmCampaignSearchParamValue === 'string') {
						utmParameterChanges.push({ key: 'utmCampaign', value: utmCampaignSearchParamValue })
					}
					resolve()
				} else if (key === 'utm_content') {
					utmContentSearchParamValue = searchParams.get('utm_content')
					if (typeof utmContentSearchParamValue === 'string') {
						utmParameterChanges.push({ key: 'utmContent', value: utmContentSearchParamValue })
					}
					resolve()
				} else if (key === 'utm_term') {
					utmTermSearchParamValue = searchParams.get('utm_term')
					if (typeof utmTermSearchParamValue === 'string') {
						utmParameterChanges.push({ key: 'utmTerm', value: utmTermSearchParamValue })
					}
					resolve()
				} else {
					/** If the key isn't valid, resolve it immediately */
					resolve()
				}
			})
			fieldChangePromises.push(fieldChangePromise)
		})

		if (emailSearchParamValue) {
			const formData: LeadCaptureTypes.LeadCaptureInfo = {
				email: emailSearchParamValue,
				firstName: fnameSearchParamValue ? fnameSearchParamValue : '',
				lastName: lnameSearchParamValue ? lnameSearchParamValue : '',
				state: stateSearchParamValue ? stateSearchParamValue : '',
				phone: phoneSearchParamValue ? phoneSearchParamValue : '',
				referrerId: referralSearchParamValue ? referralSearchParamValue : '',
				utmSource: utmSourceSearchParamValue ? utmSourceSearchParamValue : '',
				utmMedium: utmMediumSearchParamValue ? utmMediumSearchParamValue : '',
				utmCampaign: utmCampaignSearchParamValue ? utmCampaignSearchParamValue : '',
				utmContent: utmContentSearchParamValue ? utmContentSearchParamValue : '',
				utmTerm: utmTermSearchParamValue ? utmTermSearchParamValue : '',
			}
			LeadCaptureAPI.capturelead(formData)
				.then(() => {})
				.catch((error) => {
					console.log(error)
				})
		}

		/** Once the fieldChanges variable is constructed, execute the change */
		Promise.all(fieldChangePromises).then(() => {
			if (utmParameterChanges.length > 0) {
				dispatch({ type: 'set-utm-parameters', payload: utmParameterChanges })
			}
			dispatch({ type: 'update-fields', payload: fieldChanges })
		})
	}, [])

	useEffect(() => {
		ReferralTypeAPI()
			.getAllOptions({ page: 0, size: 20, sort: [{ property: 'displayValue', direction: 'asc' }] })
			.then((res) => {
				dispatch({ type: 'set-referral-type-options', payload: res.data.items })
			})
			.catch(() => {
				ToastService.create({ type: 'ERROR', body: `Setup for registration has failed` })
			})

		async function getAllMlsBoardOptions(page: number = 0, size: number = 20): Promise<MLSBoard.Complete[]> {
			try {
				const response = await MLSBoardAPI().getAllOptions({
					page,
					size,
					sort: [{ property: 'displayValue', direction: 'asc' }],
				})
				if (response.data.totalPages > page + 1) {
					return response.data.items.concat(await getAllMlsBoardOptions(page + 1, size))
				}
				return response.data.items
			} catch {
				ToastService.create({ type: 'ERROR', body: `Setup for registration has failed` })
			}
			return []
		}

		getAllMlsBoardOptions()
			.then((items: MLSBoard.Complete[]) => {
				dispatch({ type: 'set-mls-options', payload: items })
			})
			.catch(() => {
				ToastService.create({ type: 'ERROR', body: `Setup for registration has failed` })
			})
	}, [])

	/** ========================== */
	/** Methods */

	/** Get the initial state for the registration route. State will be reloaded from localStorage if possible */
	function loadInitialState(): RegistrationState.LocalState {
		const storedState = localStorage.getItem('registrationState')
		if (storedState) {
			const parsedState = JSON.parse(storedState)
			// Assuming 'fields' is a mandatory part of your state structure,
			// adjust the condition based on your actual state structure.
			if (parsedState && Object.keys(parsedState).length) {
				return parsedState
			}
		}

		return defaultEmptyState()
	}

	/** ========================== */
	/** Render Component */

	return (
		<RegistrationContext.Provider value={state}>
			<RegistrationDispatchContext.Provider value={dispatch}>
				{props.children}
			</RegistrationDispatchContext.Provider>
		</RegistrationContext.Provider>
	)
}

export function useRegistration() {
	return useContext(RegistrationContext)
}

export function useRegistrationDispatch() {
	return useContext(RegistrationDispatchContext)
}
