import { RouteProperties } from './routing.types'

export namespace RoutingService {
	export const settingsRoutePath = `/administration`
	export const hubRoutePath = `/hubs`
	export const transactionMgmtRoutePath = `/transaction-management`
	export const transactionMgmtAdminPath = `/transaction-management/admin`

	export function getIsRouteVisible(route: RouteProperties): boolean {
		let isVisible = true
		if (typeof route.visible === 'boolean') {
			isVisible = route.visible
		} else if (typeof route.visible === 'function') {
			isVisible = route.visible()
		}

		return isVisible
	}
}
