import { Button } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { useState } from 'react'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

type ComponentProps = {
	onCancel: () => void
	onLinkDocument: (document: TransactionMgmtTypes.DocumentTypes.Document) => void
	parentTransaction: TransactionMgmtTypes.TransactionTypes.Transaction
}

export function Transaction__LinkDocumentToTaskModal(props: ComponentProps) {
	const [selectedDoc, setSelectedDoc] = useState<TransactionMgmtTypes.DocumentTypes.Document | null>(null)
	const orphanedDocuments = TransactionManagementService.getOrphanedDocuments(props.parentTransaction)

	return (
		<>
			<Modal
				maxHeight={800}
				maxWidth={800}
				fixedHeight={true}
				className="flex flex-column"
				onClose={() => {
					props.onCancel()
				}}
			>
				<>
					<ModalHeader title={`Link Document`} description="Link a document to this task" icon={'file'} />
					<ModalBody>
						<div className="flex flex-column" style={{ rowGap: '10px' }}>
							{orphanedDocuments.map((document) => {
								return (
									<Paper
										bgColor="white"
										padding={['all']}
										onClick={() => {
											setSelectedDoc(document)
										}}
										interactive={true}
										selected={selectedDoc?.documentId === document.documentId}
									>
										<Typography type="strong">{document.name}</Typography>
										<Typography type="normal" margins={['bottom']}>
											{document.description}
										</Typography>
									</Paper>
								)
							})}
						</div>
					</ModalBody>
					<ModalFooter gap={true}>
						<Button variant="outlined" size="md" primary={false} label="Cancel" onClick={props.onCancel} />
						<Button
							variant="contained"
							size="md"
							primary={true}
							label="Add Checklist"
							disabled={!selectedDoc}
							onClick={async () => {
								if (!selectedDoc) {
									return
								}
								props.onLinkDocument(selectedDoc)
							}}
						/>
					</ModalFooter>
				</>
			</Modal>
		</>
	)
}
