import { currentSessionSlice, setToken } from '@redux/reducers/current-session-reducer'
import { store } from '@redux/store'
import axios, { AxiosError, AxiosInstance } from 'axios'
import { globalNavigate } from 'src/routes/history-component'

import { ToastService } from '../toast/toast.service'
import { TokenService } from '../token/token.service'
import { RequestErrorHandlingService } from './request-error-handling.service'

export const RequestBaseURL = process.env.REACT_APP_LOKATION_API_BASE_URL
export const webSocketBaseURL = process.env.REACT_APP_LOKATION_WEB_SOCKETS_BASE_URL
export const apiVersionURL = `/api/v1`
export const FileBaseURL = ``
export const mfKey = 'mMBzb9wb8oZgbc553akLsXdd'
export const httpRequest = axios.create()
export const httpRequestNoErrors = axios.create()
export const httpRequestNoToken = axios.create()
export const FrontEndBaseURL = getFrontEndBaseUrl()
interface ErrorResponseData {
	error: { path?: string; message: string }[] | string
	status?: number
	path?: string
	timestamp?: string
}

function parseErrorMessageFromResponse(responseData: ErrorResponseData): string {
	// Response data for validation errors etc (Example: Email: Already exists)
	if (responseData.error && Array.isArray(responseData.error)) {
		return responseData.error
			.map((err: { path?: string; message: string }) => {
				if (err.path) {
					const pathSentenceCase = err.path.charAt(0).toUpperCase() + err.path.slice(1).toLowerCase()
					const messageSentenceCase = err.message.charAt(0).toUpperCase() + err.message.slice(1).toLowerCase()
					return `${pathSentenceCase}: ${messageSentenceCase}`
				} else {
					const messageSentenceCase = err.message.charAt(0).toUpperCase() + err.message.slice(1).toLowerCase()
					return `${messageSentenceCase}`
				}
			})
			.join(', ')
	}

	// Handle ErrorResponseData for generic errors
	if (responseData.status && responseData.path && responseData.error) {
		// URL path of error is commented out
		// const pathSentenceCase = responseData.path.charAt(0).toUpperCase() + responseData.path.slice(1).toLowerCase();
		const messageSentenceCase =
			responseData.error.charAt(0).toUpperCase() + responseData.error.slice(1).toLowerCase()
		return `${messageSentenceCase}`
	}

	// If the error doesn't match any of the formats, return a default message
	return 'An unexpected error occurred.'
}

function addInterceptors(instance: AxiosInstance, handleErrors = true, requireToken = true) {
	instance.interceptors.request.use((requestConfig) => {
		const token = store.getState().currentSession.token

		if (requireToken && token && TokenService().isTokenExpired(token)) {
			ToastService.create({ type: 'INFO', body: 'Your session has expired. Please log back in.' })
			TokenService().clear()
			store.dispatch(currentSessionSlice.actions.clearToken())
			if (globalNavigate) {
				globalNavigate('/')
			}
		}

		if (requestConfig && requestConfig.headers) {
			requestConfig.headers['x-mf-key'] = mfKey

			if (requestConfig.headers.Authorization === undefined && token) {
				requestConfig.headers['Authorization'] = `Bearer ${token.jwt}`
			}
		}
		return requestConfig
	})

	instance.interceptors.response.use(
		(res) => {
			if ('headers' in res && 'token' in res.headers) {
				const token = res.headers.token
				if (token) {
					store.dispatch(setToken(token))
					TokenService().update(token)
				}
			}
			return res
		},
		(err: AxiosError) => {
			let errorMessage

			/** If the error is a 401, redirect the user to the login page */
			if (err.response?.status === 401) {
				ToastService.create({ type: 'ERROR', body: `Please log in to access this page` })
				// localStorage.setItem('redirectAfterLogin', window.location.pathname) // Save current URL for redirect after logging in
				// if (globalNavigate) {
				// 	globalNavigate('/')
				// }
			}

			if (err.response && (err.response.data as ErrorResponseData).error) {
				errorMessage = parseErrorMessageFromResponse(err.response.data as ErrorResponseData)
			} else {
				errorMessage = RequestErrorHandlingService.getErrorMessage(err)
			}

			if (handleErrors) {
				ToastService.create({ type: 'ERROR', body: errorMessage })
			}
			return Promise.reject(err)
		},
	)
}

function getFrontEndBaseUrl() {
	const { protocol, hostname, port } = window.location
	return `${protocol}//${hostname}${port ? `:${port}` : ''}`
}

// Apply interceptors to httpRequest and httpRequestNoErrors
addInterceptors(httpRequest, true, true)
addInterceptors(httpRequestNoErrors, false, true)
addInterceptors(httpRequestNoToken, false, false)
