import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type ComponentProps = {
	url?: string
}

export function ResourceCard__ExternalWebsiteWarning(props: ComponentProps) {
	return (
		<div className="modal__scrim">
			<div className="external-site-msg__wrapper">
				<FontAwesomeIcon icon={['far', 'globe']} size={`5x`} className={`mb-20 opacity-50`} />
				<Typography type="h5">Loading External Website</Typography>
				<div className="mt-10">
					If you are not redirected within 5 seconds,{' '}
					<a href={props.url} target="_blank" rel="noreferrer">
						click here
					</a>
					.
				</div>
			</div>
		</div>
	)
}
