import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { AdAccountAPI } from 'src/services/advertising/ad-account.api'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'

import { AdvertisingAccountIndexRoute } from './ad-account__index'
import { AdvertisingAccountProvider } from './state/ad-account__state'

export function AdvertisingAccountRouteWrapper() {
	const [data, setData] = useState<AdvertisingTypes.Account | null>(null)
	const params = useParams<{ accountId: string | undefined }>()

	useEffect(() => {
		if (!params.accountId) {
			return
		}

		AdAccountAPI.getAccountById(parseFloat(params.accountId)).then((res) => {
			setData(res.data)
		})
	}, [params.accountId])

	if (!data) {
		return (
			<RouteWrapperV2>
				<RouteBodyV2>Cannot load account</RouteBodyV2>
			</RouteWrapperV2>
		)
	}

	return (
		<AdvertisingAccountProvider accountToEdit={data}>
			<AdvertisingAccountIndexRoute />
		</AdvertisingAccountProvider>
	)
}
