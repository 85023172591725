import { SearchResult } from '@components/search-result/search-result'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContentLoader from 'react-content-loader'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router'

import { DateService } from '../../services/date.service'
import { FAQ } from '../../services/faq/faq.types'
import { NewsFeed } from '../../services/news-feed/news-feed.types'
import { LokationResource } from '../../services/resources/resources.types'
import { LokationTag } from '../../services/tags/tag.types'
import { Utilities } from '../../services/utilities.service'
import { SitewideSearchResultTypes } from './sitewide-search'

interface SitewideSearchResultsProps {
	results: SitewideSearchResultTypes[]
	hasMore: boolean
	searchString: string
	updateSearchString: React.Dispatch<React.SetStateAction<string>>
	updateSelectedTags: React.Dispatch<React.SetStateAction<LokationTag[]>>
	loadNextPageOfResults: () => void
}

export function SitewideSearchResults(props: SitewideSearchResultsProps) {
	function getNumResults(): number {
		return props.results.length
	}

	const navigate = useNavigate()

	function renderResult(result: LokationResource | FAQ | NewsFeed.Story): JSX.Element {
		if ('url' in result) {
			return (
				<SearchResult
					title={result.title}
					description={Utilities.truncateString(result.description, 200)}
					to={result.url ? result.url : undefined}
					key={`resource__${result.resourceId}`}
				/>
			)
		}
		if ('question' in result) {
			return (
				<SearchResult
					title={result.question}
					description={''}
					onClick={() => {
						navigate(`/faqs/${result.faqId}`)
					}}
					key={`faq__${result.faqId}`}
				/>
			)
		}
		if ('newsStoryId' in result) {
			return (
				<SearchResult
					title={result.title}
					description={DateService.getFormattedDateFromISO8601(result.createdTimestamp)}
					onClick={() => {
						navigate(`/news-feed/${result.newsStoryId}`)
					}}
					key={`news__${result.newsStoryId}`}
				/>
			)
		}
		return <></>
	}

	const numResults = getNumResults()

	return (
		<>
			{numResults > 0 && (
				<InfiniteScroll
					dataLength={props.results.length}
					next={props.loadNextPageOfResults}
					hasMore={props.hasMore}
					scrollableTarget={`search__route-body`}
					loader={
						<ContentLoader viewBox="0 0 380 70" backgroundColor="var(--colorAdjust40)">
							<rect x="0" y="0" rx="5" ry="5" width="100%" height="10" />
						</ContentLoader>
					}
					endMessage={<></>}
				>
					<div className="mb-40">
						<Typography type="h5" margins={['bottom']}>
							Results
						</Typography>
						{props.results.map((result) => {
							return renderResult(result)
						})}
					</div>
				</InfiniteScroll>
			)}
			{numResults === 0 && (
				<div className="height__fill flex flex-column flex-alignItems-center flex-justifyContent-center text-center">
					<FontAwesomeIcon icon={['far', 'search']} size="6x" className="mb-20" />
					<Typography type="h3" margins={['bottom']}>
						We could not find any results matching your search criteria
					</Typography>
				</div>
			)}
		</>
	)
}
