import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIState } from '@redux/reducers/ui-reducer'
import { RootState } from '@redux/store'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'

import { FindUserDTO } from '../../services/user/user.types'
import { AgentSearchState } from './state/agent-search__state.types'
import { UserCard } from './user__card/user__card'

type AgentSearchResultsProps = {
	endUsers: FindUserDTO[] | null
	clearSearch: () => void
	hasMore: boolean
	/** A function which must be called after reaching the bottom. It must trigger some sort of action which fetches the next data. The data is passed as children to the InfiniteScroll component and the data should contain previous items too. */
	loadNextPageOfResults: () => void
	params: AgentSearchState.SearchParams
}

type ConnectedProps = {
	ui: UIState
}

export function AgentSearchResults__Prototype(props: AgentSearchResultsProps & ConnectedProps) {
	const [allowResultsToShow, setAllowResultsToShow] = useState(false)
	const [lastSearchParams, setLastSearchParams] = useState<AgentSearchState.SearchParams | null>(null)

	useEffect(() => {
		/** Check if params have changed before allowing the loading spinner to show */
		if (_.isEqual(lastSearchParams, props.params)) {
			return
		}
		setLastSearchParams(props.params)
		setAllowResultsToShow(false)

		setTimeout(() => {
			setAllowResultsToShow(true)
		}, 1000)
	}, [props.params])

	return (
		<>
			{(props.endUsers === null || !allowResultsToShow) && (
				<div className="col-xs-12 flex flex-column flex-alignItems-center flex-justifyContent-center flex-fillSpace">
					<FontAwesomeIcon icon={['fas', 'spinner']} spin={true} size={'5x'} />
					<Typography type="h5" margins={['top']}>
						Searching
					</Typography>
				</div>
			)}

			{props.endUsers && props.endUsers.length > 0 && allowResultsToShow && (
				<InfiniteScroll
					dataLength={props.endUsers.length}
					next={props.loadNextPageOfResults}
					scrollableTarget={props.ui.viewMode === 'FULL' ? 'agent-search__body' : undefined}
					hasMore={props.hasMore}
					loader={
						<ContentLoader viewBox="0 0 380 70" backgroundColor="var(--colorAdjust40)">
							<rect x="0" y="0" rx="5" ry="5" width="100%" height="10" />
						</ContentLoader>
					}
					endMessage={
						<div className="opacity-50">
							<em>There are no more results</em>
						</div>
					}
				>
					<div className="mb-20">
						{props.endUsers.map((user) => {
							return <UserCard {...user} key={user.endUserId} />
						})}
					</div>
				</InfiniteScroll>
			)}
			{props.endUsers?.length === 0 && (
				<div className="height__fill flex flex-column flex-alignItems-center flex-justifyContent-center text-center">
					<FontAwesomeIcon icon={['far', 'search']} size="6x" className="mb-20" />
					<Typography type="h3" margins={['bottom']}>
						We could not find any results matching your search criteria
					</Typography>
				</div>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		ui: state.ui,
	}
}

export const AgentSearchResults = connect(mapStateToProps)(AgentSearchResults__Prototype)
