import { Button } from '@components/button/button'
import { RootState } from '@redux/store'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useState } from 'react'
import { FormEvent } from 'react'
import { connect } from 'react-redux'

import { EndUserProps } from '../../../../../services/user/user.types'

interface StripeAddPaymentMethodPrototypeProps {
	onPaymentMethodAddition?: () => void
	dismissModal?: () => void
	newPaymentMethodId?: (paymentMethodId: string) => void
	currentUser: EndUserProps | null
	userToEdit: EndUserProps | undefined
}

function StripeAddPaymentMethodPrototype({
	onPaymentMethodAddition,
	dismissModal,
	newPaymentMethodId,
}: StripeAddPaymentMethodPrototypeProps) {
	const [displayAddPaymentMethodForm, setDisplayAddPaymentMethodForm] = useState(true)

	const stripe = useStripe()
	const elements = useElements()

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		if (!stripe || !elements) {
			return
		}

		try {
			const result = await stripe.confirmSetup({
				elements,
				confirmParams: {},
				redirect: 'if_required',
			})

			if (result.error) {
				console.log('Failed!')
				console.log(result.error)
			} else {
				console.log('Setup Intent Created!')

				const paymentMethodId = result.setupIntent.payment_method as string
				if (paymentMethodId) {
					if (newPaymentMethodId) {
						newPaymentMethodId(paymentMethodId)
					}

					setDisplayAddPaymentMethodForm(false)
					if (onPaymentMethodAddition) {
						onPaymentMethodAddition()
					}
					if (dismissModal) {
						dismissModal()
					}
				}
			}
		} catch (error) {
			console.log('Error in confirmSetup:', error)
		}
	}

	return (
		<>
			{displayAddPaymentMethodForm && (
				<div className="mb-20">
					<form onSubmit={handleSubmit}>
						<PaymentElement
							options={{
								paymentMethodOrder: ['us_bank_account', 'card', 'apple_pay', 'google_pay'],
								layout: {
									type: 'tabs',
									defaultCollapsed: false,
									radios: true,
									spacedAccordionItems: false,
								},
							}}
						/>
						<div className="flex flex-justifyContent-end mt-20">
							{onPaymentMethodAddition && dismissModal ? (
								<>
									<Button
										variant="outlined"
										size="md"
										primary={false}
										label="Cancel"
										margins={['right']}
										onClick={dismissModal}
									/>
									<Button
										variant="contained"
										size="md"
										primary={true}
										label="Save and close"
										onClick={onPaymentMethodAddition}
									/>
								</>
							) : (
								<>
									<Button
										variant="contained"
										size="md"
										primary={true}
										label="Add payment"
										onClick={onPaymentMethodAddition}
									/>
								</>
							)}
						</div>
					</form>
				</div>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const StripeAddPaymentMethod = connect(mapStateToProps)(StripeAddPaymentMethodPrototype)
