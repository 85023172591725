import './button.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { NavLink } from 'react-router'

import { ButtonService } from './button.service'
import { ButtonTypes } from './button.types'

export function Button(props: ButtonTypes.Component) {
	const [isPending, setIsPending] = useState(false)

	function handleClick(evt: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void {
		if (isPending || props.isPending) {
			return
		}

		evt.stopPropagation()
		if (props.onClick) {
			const clickResponse = props.onClick(evt)
			if (clickResponse) {
				setIsPending(true)
				clickResponse.finally(() => {
					setIsPending(false)
				})
			}
		}
	}

	function renderButtonContents(): JSX.Element {
		const showSpinner = isPending || props.isPending

		return (
			<>
				{showSpinner && (
					<FontAwesomeIcon icon={['fas', 'spinner']} spin={true} className="button__spinner__in-progress" />
				)}

				<div
					className="flex flex-alignItems-center col-xs-12 flex-justifyContent-center"
					style={{ opacity: showSpinner ? 0 : 1 }}
				>
					{props.icon && props.iconPosition === 'left' && (
						<FontAwesomeIcon
							icon={['far', props.icon]}
							size={props.iconSize}
							className="mr-10"
						></FontAwesomeIcon>
					)}
					{props.label}
					{props.icon && props.iconPosition === 'right' && (
						<FontAwesomeIcon
							icon={['far', props.icon]}
							size={props.iconSize}
							className="ml-10"
						></FontAwesomeIcon>
					)}
				</div>
			</>
		)
	}

	/** ======================================= */
	/** Render Component */

	if (props.to) {
		return (
			<NavLink
				to={props.to}
				style={props.style}
				onClick={handleClick}
				className={({ isActive }) =>
					`${ButtonService.getClass({
						className: props.className,
						icon: props.icon,
						primary: props.primary,
						size: props.size,
						margins: props.margins,
						marginSize: props.marginSize,
						variant: props.variant,
					})} ${isActive ? 'active' : ''}`
				}
			>
				{renderButtonContents()}
			</NavLink>
		)
	} else {
		return (
			<button
				style={props.style}
				onClick={handleClick}
				disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
				className={ButtonService.getClass({
					className: props.className,
					icon: props.icon,
					primary: props.primary,
					size: props.size,
					margins: props.margins,
					marginSize: props.marginSize,
					variant: props.variant,
				})}
			>
				{renderButtonContents()}
			</button>
		)
	}
}
