import { Typography } from '@components/text/text'
import { useAppDispatch } from '@redux/hooks'
import { uiSlice } from '@redux/reducers/ui-reducer'
import { useEffect } from 'react'

import { UserOnboardingAPI } from '../../../../services/user-onboarding/user-onboarding.api'
import { UserOnboardingService } from '../../../../services/user-onboarding/user-onboarding.service'
import { UserOnboardingTypes } from '../../../../services/user-onboarding/user-onboarding.types'
import { useNewUserOnboarding, useNewUserOnboardingDispatch } from '../../state/new-user-onboarding__state'
import { OnboardingTask } from './onboarding-task'

interface NUOTasksRouteProps {
	onClose: () => void
}

export function NUOTasksRoute(props: NUOTasksRouteProps) {
	const dispatch = useAppDispatch()
	const NUOState = useNewUserOnboarding()
	const NUODispatch = useNewUserOnboardingDispatch()

	/** ========================================= */
	/** Effects */

	/** When user reaches this page, aggregate their answers to determine what tasks and actions need to be performed. Commit this on the server  */
	useEffect(() => {
		if (!NUOState.config) {
			return
		}

		const userSelectedTasksAndOptions = UserOnboardingService().aggregateUserTasksAndActions(NUOState.config)

		UserOnboardingAPI()
			.getDefaultTasks()
			.then((defaultTaskRes) => {
				UserOnboardingAPI()
					.addUserTasks([...defaultTaskRes.data, ...userSelectedTasksAndOptions.tasks])
					.then((res) => {
						NUODispatch([{ type: 'set-user-tasks', payload: res.data }])
					})
			})
	}, [])

	/** ========================================= */
	/** Methods */

	function getOnClickActionForTaskType(taskType: UserOnboardingTypes.TaskTypeEnums): void {
		switch (taskType) {
			case 'SITE_TOUR':
				dispatch(uiSlice.actions.setShowUserOnboardingTutorial(true))
				props.onClose()
				break
			case 'CONNECT_SOCIAL_MEDIA_ACCOUNTS':
			case 'SELECT_ADD_ONS':
			case 'UPLOAD_PROFILE_PHOTO':
				break
		}
	}

	/** ========================================= */
	/** Render Component */

	return (
		<>
			<div className="flex-fillSpace flex flex-column flex-justifyContent-center">
				<Typography type="h5" margins={['bottom']}>
					You are almost there! Make the most of your account by taking these steps:
				</Typography>

				{NUOState.userTasks && (
					<div className="onboarding-task__wrapper">
						{NUOState.userTasks.map((task) => {
							return (
								<OnboardingTask
									{...task}
									onClick={() => {
										getOnClickActionForTaskType(task.type)
									}}
								/>
							)
						})}
					</div>
				)}
			</div>
		</>
	)
}
