import { Button } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { ItemSearchTagFilter } from '@components/item-search__tag-filter/item-search__tag-filter'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIState } from '@redux/reducers/ui-reducer'
import { RootState, store } from '@redux/store'
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'

import EditNewsFeedStoryRoute from '../../../modals/admin-editors/edit-news-feed-story/edit-news-feed-story'
import useAdminRedirect from '../../../services/hooks/user-admin-redirect'
import { NewsFeedAPI } from '../../../services/news-feed/news-feed.api'
import { NewsFeedService } from '../../../services/news-feed/news-feed.service'
import { NewsFeed, NewsFeedFilters } from '../../../services/news-feed/news-feed.types'
import { LokationTag } from '../../../services/tags/tag.types'
import { EndUserProps } from '../../../services/user/user.types'
import { useItemAdminHook } from '../use-item-admin-hook'
import { NewsFeedCard } from './news-feed__card/news-feed__card'

type ConnectedProps = {
	userProfile: EndUserProps | null
	ui: UIState
}

function NewsFeedAdministrationRoute__Prototype(props: ConnectedProps) {
	const navigate = useNavigate()
	useAdminRedirect(props.userProfile)
	const [selectedTags, updateSelectedTags] = useState<LokationTag[]>(store.getState().tags)
	const [selectedStory, updateSelectedStory] = useState<NewsFeed.Story | 'NEW' | null>(null)
	const [isPending, setIsPending] = useState(true)

	const newsFeedAdminHook = useItemAdminHook<NewsFeed.Story, NewsFeedFilters>({
		getItemsFunction: (page, size, sort, searchParams) => {
			return NewsFeedAPI.findStories({ page, size, sort }, { tags: searchParams.tags })
		},
		searchParams: { tags: selectedTags.map((tag) => tag.tagId) },
		itemIdKey: 'newsStoryId',
		itemTitleKey: 'title',
		setIsPending: (state) => {
			setIsPending(state)
		},
		isItemShownInList: (item) => {
			return NewsFeedService.doesStoryIncludeTag(item, selectedTags)
		},
	})

	return (
		<>
			<RouteWrapperV2>
				<RouteSubnavigation
					title={`News Feed Administration`}
					backButton={{
						label: 'Administration',
						onClick: () => {
							navigate('/administration')
						},
					}}
					isCustom={true}
				>
					<ItemSearchTagFilter
						selectedTags={selectedTags}
						onChange={(value) => {
							updateSelectedTags(value)
						}}
					/>
				</RouteSubnavigation>

				<RouteBodyV2 id="news-feed-admin__route-body">
					<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-10">
						<Typography type="h1">News Feed Administration</Typography>
						<Button
							variant="outlined"
							label="Create Story"
							size={'md'}
							onClick={() => {
								updateSelectedStory('NEW')
							}}
						/>
					</div>
					<div className="mb-20 opacity-50">{newsFeedAdminHook.totalElements} Results</div>
					{!isPending && !newsFeedAdminHook.hasMoreItems && newsFeedAdminHook.items.length === 0 && (
						<div className="flex flex-column flex-fillSpace flex-justifyContent-center flex-alignItems-center">
							<FontAwesomeIcon icon={['far', 'newspaper']} size="4x" className="mb-20" />
							<Typography type="h3">We cannot find any stories matching your criteria</Typography>
						</div>
					)}
					{newsFeedAdminHook.items && (
						<InfiniteScroll
							dataLength={newsFeedAdminHook.items.length}
							next={newsFeedAdminHook.loadNextPageOfItems}
							hasMore={newsFeedAdminHook.hasMoreItems}
							scrollableTarget={props.ui.viewMode === 'FULL' ? `news-feed-admin__route-body` : undefined}
							loader={
								<>
									<GenericContentLoader width={'fill'} height={70} className="mb-10" />
									<GenericContentLoader width={'fill'} height={70} className="mb-10" />
									<GenericContentLoader width={'fill'} height={70} className="mb-10" />
								</>
							}
						>
							{newsFeedAdminHook.items.map((story) => {
								return (
									<NewsFeedCard
										{...story}
										key={story.newsStoryId}
										onClick={() => {
											updateSelectedStory(story)
										}}
									/>
								)
							})}
						</InfiniteScroll>
					)}
				</RouteBodyV2>
			</RouteWrapperV2>

			{selectedStory === 'NEW' && (
				<EditNewsFeedStoryRoute
					dismissModal={() => {
						updateSelectedStory(null)
					}}
					onCreate={newsFeedAdminHook.handleCreateItem}
				/>
			)}
			{selectedStory && selectedStory !== 'NEW' && (
				<EditNewsFeedStoryRoute
					item={selectedStory}
					dismissModal={() => {
						updateSelectedStory(null)
					}}
					onUpdate={newsFeedAdminHook.handleUpdateItem}
					onDelete={(item) => {
						newsFeedAdminHook.handleDeleteItem(item)
					}}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
		ui: state.ui,
	}
}

export const NewsFeedAdministrationRoute = connect(mapStateToProps)(NewsFeedAdministrationRoute__Prototype)
