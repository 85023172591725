import { Button } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'
import { useAppDispatch } from '@redux/hooks'
import { deleteCalendar } from '@redux/reducers/calendar-reducer'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'

import { Calendar } from '../../../services/calendar/calendar.types'

interface DeleteCalendarConfirmationModalProps {
	calendar: Calendar.Calendar
	onClose: () => void
	onDelete: () => void
	zIndex?: number
}

interface ConnectedProps {
	calendars: Calendar.Calendar[]
}

function DeleteCalendarConfirmationModalPrototype(props: DeleteCalendarConfirmationModalProps & ConnectedProps) {
	const reduxDispatch = useAppDispatch()
	// Commented out until the feature to remap calendar events works
	// const [calendarToMapTo, setCalendarToMapTo] = useState<number | null>(null)

	/** ========================================= */
	/** Methods */

	function hideModal(): void {
		props.onClose()
	}

	function onDelete(): void {
		reduxDispatch(deleteCalendar({ calendarId: props.calendar.calendarId }))
			.unwrap()
			.then(() => {
				props.onDelete()
			})
	}

	// Commented out until the feature to remap calendar events works
	// function getCalendarOptions(): DropdownOptionProps<number>[] {
	// 	const calendarOptions: DropdownOptionProps<number>[] = []
	// 	props.calendars.forEach((calendar) => {
	// 		if (calendar.calendarId === props.calendar.calendarId) {
	// 			return
	// 		}
	// 		calendarOptions.push({
	// 			label: calendar.name,
	// 			value: calendar.calendarId,
	// 		})
	// 	})
	// 	return calendarOptions
	// }

	/** ========================================= */
	/** Render Component */

	return (
		<Modal maxWidth={500} maxHeight={400} fixedHeight={false} onClose={hideModal} zIndex={props.zIndex}>
			<ModalHeader title={`Delete Calendar`} />
			{/* <ModalBody>
                <p>Select a new calendar to map all events in <em>{props.calendar.name}</em> to:</p>
                <Dropdown<number>
                    value={calendarToMapTo ? [calendarToMapTo] : []}
                    options={getCalendarOptions()}
                    onSelect={(selectedOptions) => {
                        if (selectedOptions && selectedOptions.length > 0) {
                            setCalendarToMapTo(selectedOptions[0])
                        }
                    }} />
            </ModalBody> */}
			<ModalBody>
				<p>
					Are you sure you would like to delete <em>{props.calendar.name}</em>?
				</p>
			</ModalBody>
			<ModalFooter>
				<Button
					variant="outlined"
					size="lg"
					primary={false}
					label="Cancel"
					margins={['right']}
					onClick={hideModal}
				/>
				{/* <Button variant="contained" size="lg" label="Delete" onClick={onDelete} disabled={calendarToMapTo === null} /> */}
				<Button variant="contained" size="lg" label="Delete" onClick={onDelete} />
			</ModalFooter>
		</Modal>
	)
}

function mapStateToProps(state: RootState, ownProps: DeleteCalendarConfirmationModalProps) {
	return {
		calendars: state.calendar,
	}
}

export const DeleteCalendarConfirmationModal = connect(mapStateToProps)(DeleteCalendarConfirmationModalPrototype)
