import { Box } from '@components/box/box'
import { Button } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownTypes } from '@components/dropdown/dropdown.types'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { ModalHeader } from '@components/modal/modal-header'
import { TextInput } from '@components/text-input/text-input'
import { useState } from 'react'
import { ToastService } from 'src/services/toast/toast.service'

import { EmailNotificationAPI } from '../../services/email-notification/email-notification.api'
import { EmailNotificationTypes } from '../../services/email-notification/email-notification.types'

interface FeedbackModalProps {
	dismissModal: () => void
}

function FeedbackModal(props: FeedbackModalProps) {
	const [submitted, setSubmitted] = useState(false)
	const [formFields, setFormFields] = useState<EmailNotificationTypes.EmailSubmitFeedback>({
		requestType: '',
		priorityLevel: '',
		moreInformation: '',
	})
	const [selectedRequestType, setSelectedRequestType] = useState<(string | null)[]>([])
	const [selectedPriorityLevel, setSelectedPriorityLevel] = useState<(string | null)[]>([])

	const [errors, setErrors] = useState({
		requestType: '',
		priorityLevel: '',
		moreInformation: '',
	})

	function validateSimpleString(value: string): boolean {
		return value.length > 0
	}

	// Validation function
	const validateFormFields = () => {
		let formErrors = { ...errors }

		formErrors.requestType = validateSimpleString(formFields.requestType) ? '' : 'You must select a request type'
		formErrors.priorityLevel = validateSimpleString(formFields.priorityLevel)
			? ''
			: 'You must select a priority level'
		formErrors.moreInformation = validateSimpleString(formFields.moreInformation) ? '' : 'You must provide details'

		setErrors(formErrors)
		return Object.values(formErrors).every((x) => x === '')
	}

	async function sendNotification() {
		if (!validateFormFields()) {
			ToastService.create({
				type: 'ERROR',
				body: 'Please fill out all fields',
			})
			return
		}

		const formData: EmailNotificationTypes.EmailSubmitFeedback = {
			requestType: formFields.requestType,
			priorityLevel: formFields.priorityLevel,
			moreInformation: formFields.moreInformation,
		}

		console.log(formData)

		EmailNotificationAPI.feedbackNotification(formData)
			.then((res) => {
				setSubmitted(true)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	function getRequestType(): DropdownTypes.Option<string | null>[] {
		return [
			{ value: 'Feature/Bug', label: 'Feature/Bug' },
			{ value: 'Support/Help', label: 'Support/Help' },
		]
	}

	function getPriorityLevel(): DropdownTypes.Option<string | null>[] {
		return [
			{ value: 'High', label: 'High' },
			{ value: 'Medium', label: 'Medium' },
			{ value: 'Low', label: 'Low' },
		]
	}

	const handleChange = (name: string) => (value: string | number | null) => {
		setFormFields((prevState) => ({ ...prevState, [name]: value ? value.toString() : '' }))
	}
	return (
		<>
			<Modal onClose={props.dismissModal} maxWidth={750} maxHeight={800} fixedHeight={false}>
				<ModalHeader title="Do you have feedback?" />
				<ModalBody>
					{!submitted && (
						<Box flex="column" gap={true}>
							<Box flex="row" gap={true}>
								<Dropdown<string | null>
									label="Request Type"
									width={300}
									value={selectedRequestType}
									options={getRequestType()}
									onSelect={(selectedValues) => {
										setSelectedRequestType(selectedValues)
										if (selectedValues && selectedValues.length > 0 && selectedValues[0]) {
											const updatedRequestType = selectedValues[0]
											setFormFields((prevState) => ({
												...prevState,
												requestType: updatedRequestType ? updatedRequestType.toString() : '',
											}))
										}
									}}
								/>

								<Dropdown<string | null>
									label="Priority"
									width={300}
									value={selectedPriorityLevel}
									options={getPriorityLevel()}
									onSelect={(selectedValues) => {
										setSelectedPriorityLevel(selectedValues)
										if (selectedValues && selectedValues.length > 0 && selectedValues[0]) {
											const updatedPriorityLevel = selectedValues[0]
											setFormFields((prevState) => ({
												...prevState,
												priorityLevel: updatedPriorityLevel
													? updatedPriorityLevel.toString()
													: '',
											}))
										}
									}}
								/>
							</Box>

							<TextInput
								label={'Please provide some details...'}
								rows={4}
								dataType="text"
								value={formFields.moreInformation}
								width="100%"
								onChange={handleChange('moreInformation')}
							/>
						</Box>
					)}
					{submitted && (
						<>
							<div className="mb-20">Your request has been submitted.</div>
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<Button variant="contained" size="md" primary={true} label={'Submit'} onClick={sendNotification} />
				</ModalFooter>
			</Modal>
		</>
	)
}

export default FeedbackModal
