import './event-calendar.scss'

import { EventMountArg } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import multiMonthPlugin from '@fullcalendar/multimonth'
import FullCalendar from '@fullcalendar/react'
import rrulePlugin from '@fullcalendar/rrule'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'

import { EditCalendarEventRoute } from '../../modals/admin-editors/edit-calendar-event/edit-calendar-event__wrapper'
import { CalendarEventModal } from '../../modals/calendar-event__modal/calendar-event__modal'
import { CalendarAPI } from '../../services/calendar/calendar.api'
import { Calendar } from '../../services/calendar/calendar.types'
import { CalendarEventAPI } from '../../services/calendar/calendar-event.api'
import { Button } from '../button/button'
import { EventCalendarTypes } from './event-calendar.types'
import { icalPlugin } from './ical-integration'

export function EventCalendar(props: EventCalendarTypes.Component) {
	const [selectedEvent, setSelectedEvent] = useState<
		(Calendar.Event & { instanceStartDate: Date | null; instanceEndDate: Date | null }) | 'new' | null
	>(null)
	const [calendarICSData, setCalendarICSData] = useState<string | null>(null)

	/** ================================================ */
	/** Effects */

	/** Get calendar ICS file when selected calendar changes */
	useEffect(() => {
		getCalendarICSData()
	}, [props.calendarId, props.filterByTags])

	/** ================================================ */
	/** Methods */

	function getCalendarICSData(): void {
		if (props.calendarId === 'all') {
			setCalendarICSData(CalendarAPI.getMergedCalendarsICSURL())
		} else {
			setCalendarICSData(CalendarAPI.getCalendarICSURL(props.calendarId))
		}
	}

	function getHeight(): string | undefined {
		if (props.style && props.style.height) {
			return String(props.style.height)
		}
		return undefined
	}

	function shouldEventBeVisible(mountArg: EventMountArg): boolean {
		const eventTags = mountArg.event.extendedProps['x-tags'] as number[]
		const eventLicensedStates = mountArg.event.extendedProps['x-states'] as number[]

		try {
			/** Filter out events that do not contain the tags that were passed into this function */
			let doesEventMeetTagReqs = false
			if (props.filterByTags.length > 0) {
				props.filterByTags.forEach((lokationTag) => {
					if (eventTags.includes(lokationTag.tagId)) {
						doesEventMeetTagReqs = true
					}
				})
			} else {
				doesEventMeetTagReqs = true
			}

			/** Filter out events that do not contain the licensed state IDs that were passed into this function */
			let doesEventMeetLicensedStateReqs = false
			if (props.filterByLicensedStates.length > 0) {
				props.filterByLicensedStates.forEach((licensedStateId) => {
					if (eventLicensedStates && eventLicensedStates.includes(licensedStateId)) {
						doesEventMeetLicensedStateReqs = true
					}
				})
			} else {
				doesEventMeetLicensedStateReqs = true
			}

			if (doesEventMeetTagReqs && doesEventMeetLicensedStateReqs) {
				return true
			}
			return false
		} catch (err) {
			console.error(err)
			return true
		}
	}

	/** ================================================ */
	/** Render Component */

	// if (!normalizedCalendarEvents) {
	//     return <div className="p-20">
	//         <GenericContentLoader width={'fill'} height={20} className="mb-5" />
	//         <GenericContentLoader width={'fill'} height={20} className="mb-5" />
	//         <GenericContentLoader width={'fill'} height={20} className="mb-5" />
	//     </div>
	// }

	return (
		<>
			{(props.headerBody || props.includeCreateButton) && (
				<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-10">
					{props.headerBody && <>{props.headerBody}</>}
					{props.includeCreateButton && (
						<div className="flex flex-alignItems-center">
							<Button
								variant="outlined"
								label="Create Event"
								size={'md'}
								onClick={() => {
									setSelectedEvent('new')
								}}
							/>
						</div>
					)}
				</div>
			)}
			{calendarICSData && (
				<FullCalendar
					height={getHeight()}
					plugins={[dayGridPlugin, rrulePlugin, multiMonthPlugin, listPlugin, icalPlugin]}
					initialView={props.initialView}
					events={{
						format: 'ics',
						url: calendarICSData,
						eventDataTransform: (thisEvent) => {
							try {
								if (!thisEvent.start || !thisEvent.end) {
									return thisEvent
								}
								/** Note: FullCalendar doesn't recofgnize the TZID parameter that is part of DTSTART and DTEND, so I am
								 * manually telling it here that every event it receives will be in EST
								 */
								const updatedStart = DateTime.fromISO(String(thisEvent.start), {
									zone: 'America/New_York',
								}).toISO()
								const updatedEnd = DateTime.fromISO(String(thisEvent.end), {
									zone: 'America/New_York',
								}).toISO()

								if (!updatedStart || !updatedEnd) {
									return thisEvent
								}

								thisEvent.start = updatedStart
								thisEvent.end = updatedEnd
								/** Get event ID */
								if (
									thisEvent.extendedProps &&
									'x-event-id' in thisEvent.extendedProps &&
									typeof thisEvent.extendedProps['x-event-id'] === 'string'
								) {
									const xEventIdOriginalValue = thisEvent.extendedProps['x-event-id']
									thisEvent.extendedProps['x-event-id'] = parseFloat(xEventIdOriginalValue)
								}

								/** Normalize x-tags into array */
								if (
									thisEvent.extendedProps &&
									'x-tags' in thisEvent.extendedProps &&
									typeof thisEvent.extendedProps['x-tags'] === 'string'
								) {
									const xTagsOriginalValue = thisEvent.extendedProps['x-tags']
									try {
										const splitValue = xTagsOriginalValue.replace(`\\`, '').split(',')
										const eventTagIds: number[] = splitValue.map((string) => parseFloat(string))
										thisEvent.extendedProps['x-tags'] = eventTagIds
									} catch (err) {
										console.error(`Could not get tags for calendar event`)
										thisEvent.extendedProps['x-tags'] = []
									}
								}

								/** Normalize x-tags into array */
								if (
									thisEvent.extendedProps &&
									'x-states' in thisEvent.extendedProps &&
									typeof thisEvent.extendedProps['x-states'] === 'string'
								) {
									const xStatesOriginalValue = thisEvent.extendedProps['x-states']
									try {
										const splitValue = xStatesOriginalValue.replace(`\\`, '').split(',')
										const eventTagIds: number[] = splitValue.map((string) => parseFloat(string))
										thisEvent.extendedProps['x-states'] = eventTagIds
									} catch (err) {
										console.error(`Could not get tags for calendar event`)
										thisEvent.extendedProps['x-states'] = []
									}
								}

								return thisEvent
							} catch (err) {
								return thisEvent
							}
						},
					}}
					eventClick={(evt) => {
						CalendarEventAPI.getEventById(evt.event.extendedProps['x-event-id']).then((res) => {
							if (!res.data) {
								return
							}
							setSelectedEvent({
								...res.data,
								instanceStartDate: evt.event.start,
								instanceEndDate: evt.event.end,
							})
						})
					}}
					eventDidMount={(mountArg) => {
						const isEventVisible = shouldEventBeVisible(mountArg)
						if (!isEventVisible) {
							mountArg.el.style.display = 'none'
						}
					}}
				/>
			)}

			{selectedEvent && props.onEventClick === 'open-editor' && selectedEvent !== 'new' && (
				<EditCalendarEventRoute
					event={selectedEvent}
					dismissModal={() => {
						setSelectedEvent(null)
					}}
					onUpdate={(event) => {
						getCalendarICSData()
					}}
					onDelete={() => {
						getCalendarICSData()
					}}
				/>
			)}
			{selectedEvent && props.onEventClick === 'open-details' && selectedEvent !== 'new' && (
				<CalendarEventModal
					event={selectedEvent}
					instanceStartDate={selectedEvent.instanceStartDate}
					instanceEndDate={selectedEvent.instanceEndDate}
					dismissModal={() => {
						setSelectedEvent(null)
					}}
				/>
			)}

			{selectedEvent === 'new' && (
				<EditCalendarEventRoute
					event={null}
					dismissModal={() => {
						setSelectedEvent(null)
					}}
					onCreate={() => {
						getCalendarICSData()
					}}
				/>
			)}
		</>
	)
}
