import './desktop-app-tray.scss'

import { useAppDispatch } from '@redux/hooks'
import { uiSlice } from '@redux/reducers/ui-reducer'
import { RootState } from '@redux/store'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import { EndUserProps } from '../../services/user/user.types'
import { AppTrayTypes } from './app-tray.types'
import { DesktopAppTrayButton } from './components/app-tray__button'
import { DesktopAppTrayHeader } from './components/app-tray__header'
import { DesktopAppTrayHomeButton } from './components/app-tray__home-button'
import { DesktopAppTrayOverflowHubsButton } from './components/app-tray__overflow-hubs-button'

export interface DesktopAppTrayProps {
	links: AppTrayTypes.Element[]
}

interface OrganizedLinks {
	visible: AppTrayTypes.Link[]
	overflow: AppTrayTypes.Link[]
}

interface DesktopAppTrayUserProps {
	currentUser: EndUserProps | null
}
type CombinedProps = DesktopAppTrayProps & DesktopAppTrayUserProps

function DesktopAppTrayPrototype(props: CombinedProps) {
	/** Refs */
	const appTrayElt = useRef<HTMLDivElement>(null)
	const appTrayHubWrapper = useRef<HTMLDivElement>(null)
	const isTrayDocked = useSelector((state: RootState) => state.ui.isTrayDocked)

	/** State */
	const [isTrayExpanded, updateIsTrayExpanded] = useState<boolean>(false)
	const [appTrayHeight, setAppTrayHeight] = useState(10)

	/** Variables */
	const isTrayOpen = isTrayExpanded || isTrayDocked
	const nonHubTrayLinks = getNonHubLinks()
	const groupedTrayElts = groupTrayEltsByVisibleAndHidden()

	/** ======================================= */
	/** Effects */

	/** Get usable height of app tray hub wrapper */
	useEffect(() => {
		window.addEventListener('resize', () => {
			const height = getAppTrayHeight()
			setAppTrayHeight(height)
		})

		const height = getAppTrayHeight()
		setAppTrayHeight(height)
	}, [])

	const dispatch = useAppDispatch()

	useEffect(() => {
		if (appTrayElt.current) {
			appTrayElt.current.addEventListener('mouseenter', () => {
				function expandTray() {
					updateIsTrayExpanded(true)
				}

				const expandTrayTimer = setTimeout(expandTray, 50)

				appTrayElt.current?.addEventListener('mouseleave', () => {
					clearTimeout(expandTrayTimer)
				})
			})
			appTrayElt.current.addEventListener('mouseleave', () => {
				updateIsTrayExpanded(false)
			})
		}
	}, [])

	/** ======================================= */
	/** Methods */

	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		if (isTrayExpanded || isTrayDocked) {
			classes.push(`expanded`)
		} else {
			classes.push(`collapsed`)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	function getNonHubLinks(): AppTrayTypes.Link[] {
		let foundLinks: AppTrayTypes.Link[] = []
		props.links.forEach((link) => {
			if ('linkType' in link && link.linkType === 'other') {
				foundLinks.push(link)
			}
		})
		return foundLinks
	}

	/** Sort links into viewable and overflowed links (to deal with a situation where a user is subscribed to more links than they have space for) */
	function groupTrayEltsByVisibleAndHidden(): OrganizedLinks {
		const maxViewableLinks = getMaxViewableLinks() - nonHubTrayLinks.length
		const viewableLinks: AppTrayTypes.Link[] = []
		const overflowedLinks: AppTrayTypes.Link[] = []

		let updatedLinks: AppTrayTypes.Element[] = _.cloneDeep(props.links)

		/** Filter out links inside the app tray that are not links to hubs */
		updatedLinks = updatedLinks.filter((link) => 'linkType' in link && link.linkType !== 'other')

		/** Sort links by display order */
		updatedLinks = updatedLinks.sort((a, b) => {
			if (a.displayOrder !== b.displayOrder) {
				return a.displayOrder > b.displayOrder ? 1 : -1
			}
			return a.label.localeCompare(b.label)
		})

		/** Group links into 'viewable' or 'overflow' groups */
		updatedLinks.forEach((link, index) => {
			if ('linkType' in link) {
				if (index < maxViewableLinks) {
					viewableLinks.push(link)
				} else {
					overflowedLinks.push(link)
				}
			}
		})

		return {
			visible: viewableLinks,
			overflow: overflowedLinks,
		}
	}

	function getAppTrayHeight(): number {
		if (appTrayHubWrapper.current) {
			return appTrayHubWrapper.current.clientHeight
		} else {
			return 100
		}
	}

	function getMaxViewableLinks(): number {
		const chromeHeight = 150
		const buttonHeight = 42

		return Math.floor((appTrayHeight - chromeHeight) / buttonHeight)
	}

	/** ======================================= */
	/** Render Component */

	return (
		<div ref={appTrayElt} id="desktop-app-tray" className={getClass()} tabIndex={1}>
			<DesktopAppTrayHomeButton isTrayOpen={isTrayOpen} />
			<div ref={appTrayHubWrapper} className="flex-fillSpace col-xs-12" style={{ overflow: 'hidden' }}>
				<div id="desktop-app-tray__hub-links">
					{groupedTrayElts.visible.map((appTrayElt) => {
						if ('path' in appTrayElt) {
							return (
								<DesktopAppTrayButton
									label={appTrayElt.label}
									path={appTrayElt.path}
									icon={appTrayElt.icon}
									id={appTrayElt.id}
									expanded={isTrayExpanded || isTrayDocked}
									key={appTrayElt.label}
								/>
							)
						} else {
							return <DesktopAppTrayHeader header={appTrayElt} isTrayOpen={isTrayOpen} />
						}
					})}
					{groupedTrayElts.overflow.length > 0 && (
						<DesktopAppTrayOverflowHubsButton
							hubLinks={groupedTrayElts.overflow}
							isTrayDocked={isTrayDocked}
							isTrayExpanded={isTrayExpanded}
						/>
					)}
				</div>
				{nonHubTrayLinks.length > 0 && <div className="app-tray__divider"></div>}
				{nonHubTrayLinks.map((link) => {
					return (
						<DesktopAppTrayButton
							label={link.label}
							path={link.path}
							icon={link.icon}
							id={link.id}
							expanded={isTrayExpanded || isTrayDocked}
							key={link.label}
						/>
					)
				})}
			</div>
			<DesktopAppTrayButton
				label={`${isTrayDocked ? 'Undock' : 'Dock'} Tray`}
				icon={'thumbtack'}
				expanded={isTrayExpanded || isTrayDocked}
				onClick={() => {
					if (!isTrayDocked) {
						localStorage.setItem(`desktop-tray__is-expanded`, 'true')
						dispatch(uiSlice.actions.dockTray())
					} else {
						localStorage.setItem(`desktop-tray__is-expanded`, 'false')
						dispatch(uiSlice.actions.undockTray())
					}
				}}
			/>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const DesktopAppTray = connect(mapStateToProps)(DesktopAppTrayPrototype)
