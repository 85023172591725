import { Button } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { ItemSearchTagFilter } from '@components/item-search__tag-filter/item-search__tag-filter'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIState } from '@redux/reducers/ui-reducer'
import { RootState, store } from '@redux/store'
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'

import { EditFAQRoute } from '../../../modals/admin-editors/edit-faq/edit-faq'
import { FAQAPI } from '../../../services/faq/faq.api'
import { FAQService } from '../../../services/faq/faq.service'
import { FAQ, FAQSearchParams } from '../../../services/faq/faq.types'
import useAdminRedirect from '../../../services/hooks/user-admin-redirect'
import { LokationTag } from '../../../services/tags/tag.types'
import { EndUserProps } from '../../../services/user/user.types'
import { useItemAdminHook } from '../use-item-admin-hook'
import { FAQCard } from './faq__card/faq__card'

type ConnectedProps = {
	userProfile: EndUserProps | null
	ui: UIState
}

function FAQAdministrationRoute__Prototype(props: ConnectedProps) {
	const navigate = useNavigate()
	const [selectedTags, updateSelectedTags] = useState<LokationTag[]>(store.getState().tags)
	const [selectedFaq, updateSelectedFaq] = useState<FAQ | 'NEW' | null>(null)
	const [isPending, setIsPending] = useState(true)
	useAdminRedirect(props.userProfile)

	const faqAdminHook = useItemAdminHook<FAQ, FAQSearchParams>({
		getItemsFunction: (page, size, sort, searchParams) => {
			return FAQAPI.findFAQs({ page, size, sort }, { tags: searchParams.tags })
		},
		searchParams: { tags: selectedTags.map((tag) => tag.tagId) },
		itemIdKey: 'faqId',
		itemTitleKey: 'question',
		setIsPending: (state) => {
			setIsPending(state)
		},
		isItemShownInList: (item) => {
			return FAQService.doesFAQIncludeTag(item, selectedTags)
		},
	})

	return (
		<>
			<RouteWrapperV2>
				<RouteSubnavigation
					title={`FAQ Administration`}
					backButton={{
						label: 'Administration',
						onClick: () => {
							navigate(`/administration`)
						},
					}}
					isCustom={true}
				>
					<ItemSearchTagFilter
						selectedTags={selectedTags}
						onChange={(value) => {
							updateSelectedTags(value)
						}}
					/>
				</RouteSubnavigation>

				<RouteBodyV2 id="faq-admin__route-body">
					<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-10">
						<Typography type="h1">FAQ Administration</Typography>
						<Button
							variant="outlined"
							label="Create FAQ"
							size={'md'}
							onClick={() => {
								updateSelectedFaq('NEW')
							}}
						/>
					</div>
					<div className="mb-20 opacity-50">{faqAdminHook.totalElements} Results</div>
					{!isPending && !faqAdminHook.hasMoreItems && faqAdminHook.items.length === 0 && (
						<div className="flex flex-column flex-fillSpace flex-justifyContent-center flex-alignItems-center">
							<FontAwesomeIcon icon={['far', 'newspaper']} size="4x" className="mb-20" />
							<Typography type="h3">We cannot find any FAQs matching your criteria</Typography>
						</div>
					)}

					{faqAdminHook.items && (
						<InfiniteScroll
							dataLength={faqAdminHook.items.length}
							next={faqAdminHook.loadNextPageOfItems}
							hasMore={faqAdminHook.hasMoreItems}
							scrollableTarget={props.ui.viewMode === 'FULL' ? `faq-admin__route-body` : undefined}
							loader={
								<>
									<GenericContentLoader width={'fill'} height={70} className="mb-10" />
									<GenericContentLoader width={'fill'} height={70} className="mb-10" />
									<GenericContentLoader width={'fill'} height={70} className="mb-10" />
								</>
							}
						>
							{faqAdminHook.items.map((faq) => {
								return (
									<FAQCard
										{...faq}
										key={faq.faqId}
										onClick={() => {
											updateSelectedFaq(faq)
										}}
									/>
								)
							})}
						</InfiniteScroll>
					)}
				</RouteBodyV2>
			</RouteWrapperV2>

			{selectedFaq === 'NEW' && (
				<EditFAQRoute
					dismissModal={() => {
						updateSelectedFaq(null)
					}}
					onCreate={faqAdminHook.handleCreateItem}
				/>
			)}
			{selectedFaq && selectedFaq !== 'NEW' && (
				<EditFAQRoute
					item={selectedFaq}
					dismissModal={() => {
						updateSelectedFaq(null)
					}}
					onUpdate={faqAdminHook.handleUpdateItem}
					onDelete={faqAdminHook.handleDeleteItem}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
		ui: state.ui,
	}
}

export const FAQAdministrationRoute = connect(mapStateToProps)(FAQAdministrationRoute__Prototype)
