import { Box } from '@components/box/box'
import { Button } from '@components/button/button'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ModalTypes } from './modal.types'

export function ModalHeader(props: ModalTypes.Header) {
	function getClass(): string {
		const classes: string[] = []

		classes.push(`modal__header`)

		if (props.center) {
			classes.push(`flex-justifyContent-center`)
		} else {
			classes.push(`flex-justifyContent-spaceBetween`)
		}

		return classes.join(' ')
	}

	return (
		<div className={getClass()}>
			<Box flex="row" alignItems="center" margins={['right']}>
				{props.icon && (
					<Paper
						bgColor="primary"
						style={{
							borderRadius: '50%',
							width: '52px',
							height: '52px',
							backgroundColor: `var(--colorAdjustAlpha10)`,
						}}
						margins={['right']}
						flex="row"
						alignItems="center"
						justifyContent="center"
					>
						<FontAwesomeIcon
							icon={['far', props.icon]}
							style={{ width: '32px', height: '32px' }}
							className="opacity-50"
						/>
					</Paper>
				)}
				<Box>
					<Typography
						type="h3"
						color="primary"
						margins={props.center ? [] : ['right']}
						variant={props.center ? ['center'] : []}
						marginSize="section"
					>
						{props.title}
					</Typography>
					{props.description && (
						<Typography type="strong" variant={props.center ? ['center'] : []} color="secondary">
							{props.description}
						</Typography>
					)}
				</Box>
			</Box>
			<Box flex="row" gap={true} style={{ paddingRight: props.center ? '0px' : '40px' }}>
				{props.buttons?.map((button) => {
					return <Button {...button} key={button.label} />
				})}
			</Box>
		</div>
	)
}
