import { CollapsibleContent } from '@components/collapsible-content/collapsible-content'
import { ItemSearchTagFilter } from '@components/item-search__tag-filter/item-search__tag-filter'
import { RadioGroup } from '@components/radio-group/radio-group'
import { TextInput } from '@components/text-input/text-input'
import { DebouncedFunc } from 'lodash'

import { LokationTag } from '../../services/tags/tag.types'
import { SitewideSearchTypes } from './sitewide-search'

interface SitewideSearchFiltersProps {
	handleUpdateSearch: DebouncedFunc<any>
	selectedTags: LokationTag[]
	searchString: string
	selectedType: SitewideSearchTypes
	updateSelectedType: (value: SitewideSearchTypes) => void
	updateSelectedTags: React.Dispatch<React.SetStateAction<LokationTag[]>>
}

export function SitewideSearchFilters(props: SitewideSearchFiltersProps) {
	return (
		<>
			<TextInput
				label="Keywords"
				width={`100%`}
				dataType={'text'}
				onChange={props.handleUpdateSearch}
				value={props.searchString}
				margins={['bottom']}
				placeholder="Search"
			/>

			<CollapsibleContent label="Type" controlled={true} id={`sitewide-search__type`} headerPadding={['all']}>
				<div className="mb-20">
					<RadioGroup<SitewideSearchTypes>
						variant="SMALL"
						optionClassName="mb-5"
						options={[
							{
								label: 'Resource',
								value: 'RESOURCE',
							},
							{
								label: 'News',
								value: 'NEWS',
							},
							{
								label: 'FAQs',
								value: 'FAQ',
							},
						]}
						value={props.selectedType}
						onChange={(value) => {
							props.updateSelectedType(value)
						}}
					/>
				</div>
			</CollapsibleContent>

			<ItemSearchTagFilter
				selectedTags={props.selectedTags}
				onChange={(selectedTags) => {
					props.updateSelectedTags(selectedTags)
				}}
			/>
		</>
	)
}
