import { Dropdown } from '@components/dropdown/dropdown'
import { SideSheet__Row } from '@components/side-sheet/side-sheet__row'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

type ComponentProps<T extends TransactionMgmtTypes.DocumentTypes.Document> = {
	status: TransactionMgmtTypes.DocumentTypes.Status
	isEditable: boolean
	onUpdateDocument: (updatedProps: Partial<T>) => void
}

export function TransactionMgmt__Document__SideSheetSection__Status<
	T extends TransactionMgmtTypes.DocumentTypes.Document,
>(props: ComponentProps<T>) {
	return (
		<SideSheet__Section label={`Status`} mode="expandable" sectionKey="document__status">
			{props.isEditable && (
				<>
					<SideSheet__Row label={`Status`} direction="row">
						<Dropdown<TransactionMgmtTypes.DocumentTypes.Status>
							value={[props.status]}
							width={200}
							options={[
								{
									label: TransactionManagementService.getLabelForDocumentStatus('APPROVED'),
									value: 'APPROVED',
								},
								{
									label: TransactionManagementService.getLabelForDocumentStatus('PENDING'),
									value: 'PENDING',
								},
								{
									label: TransactionManagementService.getLabelForDocumentStatus('REJECTED'),
									value: 'REJECTED',
								},
							]}
							onSelect={(selectedOptions) => {
								const updatedProps = { status: selectedOptions[0] } as Partial<T>
								props.onUpdateDocument(updatedProps)
							}}
						/>
					</SideSheet__Row>
				</>
			)}
			{!props.isEditable && (
				<>
					<SideSheet__Row label={`Status`} direction="row">
						{TransactionManagementService.getLabelForDocumentStatus(props.status)}
					</SideSheet__Row>
				</>
			)}
		</SideSheet__Section>
	)
}
