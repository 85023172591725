import './route-v2.scss'

type RouteWrapperWithSubNavProps = {
	children: React.ReactNode
	className?: string
}

export function RouteWrapperV2(props: RouteWrapperWithSubNavProps) {
	return <div className={`v2-route-wrapper ${props.className}`}>{props.children}</div>
}
