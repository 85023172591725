import { Button } from '@components/button/button'
import { SideSheet } from '@components/side-sheet/side-sheet'
import { SideSheetServices } from '@components/side-sheet/side-sheet.service'
import { SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import _ from 'lodash'
import { useState } from 'react'
import { GenericDeleteConfirmationModal } from 'src/modals/generic-delete-confirmation/generic-delete-confirmation'
import { AdvertisementAPI } from 'src/services/advertising/advertisement.api'
import { AdvertisementImageAPI } from 'src/services/advertising/advertisement-image.api'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'

import { Ad__SideSheetService } from './ad__side-sheet.service'
import { Ad__SideSheet__Tab__Audience } from './ad__side-sheet__audience'
import { Ad__SideSheet__Tab__Overview } from './ad__side-sheet__overview'
import { Ad__SideSheet__Tab__Budget } from './sections/budget/ad__side-sheet__budget'
import { Ad__SideSheet__Tab__Design } from './sections/design/ad__side-sheet__design'
import { useAdEditor, useAdEditorDispatch } from './state/ad-editor__state'
import { AdEditorState } from './state/ad-editor__state.types'

export type AdTabProps = {
	modifiedAd: AdvertisingTypes.Advertisement
	setModifiedAd: (updatedAd: Partial<AdvertisingTypes.Advertisement>) => void
}

export type AdvertisingAccount__Ad__SideSheet__InnerProps = {
	onClose: () => void
	onUpdate: (updatedAd: AdvertisingTypes.Advertisement) => void
	onDelete: () => void
}

export function AdvertisingAccount__Ad__SideSheet__Inner(props: AdvertisingAccount__Ad__SideSheet__InnerProps) {
	const adEditorState = useAdEditor()
	const adEditorDispatch = useAdEditorDispatch()
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
	const didUserModifyProps = !_.isEqual(adEditorState.modifiedAd, adEditorState.originalAd)
	const isAdValid = Ad__SideSheetService.validateAd(adEditorState.modifiedAd, adEditorState.modifiedAdImages)

	function createFooter(): React.ReactNode {
		return (
			<>
				<Button
					variant={'outlined'}
					label={'Cancel'}
					size={'md'}
					margins={['right']}
					onClick={() => {
						props.onClose()
					}}
				/>
				<Button
					variant={'contained'}
					label={'Save and Close'}
					size={'md'}
					disabled={isAdValid.length > 0}
					onClick={() => {
						handleSaveAd().then(() => {
							props.onClose()
							props.onUpdate(adEditorState.modifiedAd)
						})
					}}
				/>
			</>
		)
	}

	function createActions(): SideSheetTypes.Action[] {
		const actions: SideSheetTypes.Action[] = []

		actions.push({
			label: `Delete`,
			icon: 'trash',
			onClick: () => {
				setShowDeleteConfirmationModal(true)
			},
		})

		return actions
	}

	function onScrimClick(): void {
		if (!didUserModifyProps) {
			props.onClose()
			return
		}

		SideSheetServices.showDismissConfirmationToast({
			onDismiss: () => {
				return new Promise((resolve) => {
					resolve()
					props.onClose()
				})
			},
			onSaveAndClose: () => {
				return handleSaveAd().then(() => {
					props.onClose()
					props.onUpdate(adEditorState.modifiedAd)
				})
			},
		})
	}

	function handleSaveAd(): Promise<void> {
		return new Promise((resolve) => {
			if (adEditorState.modifiedAd.advertisementId < 0) {
				AdvertisementAPI.createAd(adEditorState.modifiedAd).then((newAdRes) => {
					saveAdImageChanges(newAdRes.data.advertisementId).then(() => {
						resolve()
					})
				})
			} else {
				console.log(adEditorState.modifiedAd)
				AdvertisementAPI.updateAd(adEditorState.modifiedAd).then(() => {
					saveAdImageChanges(adEditorState.modifiedAd.advertisementId).then(() => {
						resolve()
					})
					resolve()
				})
			}
		})
	}

	function saveAdImageChanges(advertisementId: number): Promise<void> {
		return new Promise((resolve) => {
			const newAdImagePromises: Promise<unknown>[] = []

			/** Reconcile original and updated state for ad images */
			const originalAdImageIds = adEditorState.originalAdImages.map(
				(originalAdImage) => originalAdImage.advertisementImageId,
			)
			const modifiedAdImageIds = adEditorState.modifiedAdImages.map(
				(modifiedAdImage) => modifiedAdImage.advertisementImageId,
			)
			const adsToCreate: AdvertisingTypes.AdvertisementImage[] = []
			const adsToDelete: AdvertisingTypes.AdvertisementImage[] = []
			const adsToUpdate: AdvertisingTypes.AdvertisementImage[] = []

			/** Find images to delete */
			adEditorState.originalAdImages.forEach((originalAdImage) => {
				if (!modifiedAdImageIds.includes(originalAdImage.advertisementImageId)) {
					adsToDelete.push(originalAdImage)
				}
			})

			/** Find images to add */
			adEditorState.modifiedAdImages.forEach((modifiedAdImage) => {
				if (!originalAdImageIds.includes(modifiedAdImage.advertisementImageId)) {
					adsToCreate.push(modifiedAdImage)
				}
			})

			/** Find images to update */
			adEditorState.modifiedAdImages.forEach((modifiedAdImage) => {
				const originalVersionOfAdImage = adEditorState.originalAdImages.find(
					(image) => image.advertisementImageId === modifiedAdImage.advertisementImageId,
				)
				if (!originalVersionOfAdImage) {
					return
				}
				if (originalVersionOfAdImage.link !== modifiedAdImage.link || modifiedAdImage.base64Image) {
					adsToUpdate.push(modifiedAdImage)
				}
			})

			/** Make a request for each ad image that needs to be created */
			adsToCreate.forEach((adImage) => {
				const newAdImagePromise = AdvertisementImageAPI.createAdImage(advertisementId, adImage)
				newAdImagePromises.push(newAdImagePromise)
			})

			/** Make a request for each ad image that needs to be removed */
			adsToDelete.forEach((adImage) => {
				const newAdImagePromise = AdvertisementImageAPI.deleteAdImage(
					advertisementId,
					adImage.advertisementImageId,
				)
				newAdImagePromises.push(newAdImagePromise)
			})

			/** Make a request for each ad image that needs to be created */
			adsToUpdate.forEach((adImage) => {
				const newAdImagePromise = AdvertisementImageAPI.updateAdImage(advertisementId, adImage)
				newAdImagePromises.push(newAdImagePromise)
			})

			/** Resolve once all requests are completed */
			Promise.all(newAdImagePromises).then(() => {
				resolve()
			})
		})
	}

	return (
		<>
			<SideSheet
				title={'Ad'}
				actions={createActions()}
				onClose={props.onClose}
				persistent={false}
				footer={createFooter()}
				width="wide"
				preventDefaultScrimClick={didUserModifyProps}
				onScrimClick={onScrimClick}
				tabs={{
					options: [
						{
							name: 'Overview',
							id: 'overview',
							width: 'evenly-distributed',
						},
						{
							name: 'Audience',
							id: 'audience',
							width: 'evenly-distributed',
						},
						{
							name: 'Design',
							id: 'design',
							width: 'evenly-distributed',
						},
						{
							name: 'Budget',
							id: 'budget',
							width: 'evenly-distributed',
						},
					],
					selectedOptionId: adEditorState.currentTab,
					handleUpdateSelectedOption: (option) => {
						adEditorDispatch({ type: 'set-tab', payload: option as AdEditorState.Tabs })
					},
				}}
			>
				{adEditorState.currentTab === 'overview' && <Ad__SideSheet__Tab__Overview />}
				{adEditorState.currentTab === 'audience' && <Ad__SideSheet__Tab__Audience />}
				{adEditorState.currentTab === 'design' && <Ad__SideSheet__Tab__Design />}
				{adEditorState.currentTab === 'budget' && <Ad__SideSheet__Tab__Budget />}
			</SideSheet>

			{showDeleteConfirmationModal && (
				<GenericDeleteConfirmationModal
					itemLabel={adEditorState.modifiedAd.name}
					onDelete={async () => {
						await AdvertisementAPI.deleteAd(adEditorState.modifiedAd)
						setShowDeleteConfirmationModal(false)
						props.onDelete()
						props.onClose()
					}}
					onClose={() => {
						setShowDeleteConfirmationModal(false)
					}}
				/>
			)}
		</>
	)
}
