import { Typography } from '@components/text/text'

import accessadoctorLogo from '../../../../../lokation-routes/accessadoctor/accessadoctor-logo.png'

export function RegistrationAccessadoctorDesc() {
	return (
		<div className="col-xs-12">
			<img src={accessadoctorLogo} style={{ width: '200px' }} className="mb-20" />
			<Typography type="h6">
				Doctors can be hard to reach, illnesses can occur in the middle of the night, and sometimes you just
				have a question...
			</Typography>
			<p>
				In all of those circumstances — and many more — Access a Doctor by MyTelemedicine is a convenient and
				affordable solution.
			</p>
			<div className="col-xs-12 flex mt-20">
				<div className="col-xs-12 col-lg-4 pr-20-lg">
					<strong>Connect</strong>
					<p>
						Patient calls 1.800.611.5601 or logs on to their member portal to schedule a constulation with a
						physician licensed in their state.
					</p>
				</div>
				<div className="col-xs-12 col-lg-4 pl-20-lg pr-20-lg">
					<strong>Triage</strong>
					<p>
						Member speaks to a Care Coordinator who will triage and update the patient's Electronic Health
						Record (EHR) along with all symptoms.
					</p>
				</div>
				<div className="col-xs-12 col-lg-4 pl-20-lg pr-20-lg">
					<strong>Consult</strong>
					<p>
						Member consults with Physician who recommends a treatment plan. If a prescription is necessary,
						it's sent to the pharmacyt of your choice.
					</p>
				</div>
			</div>
			<p>
				We offer a very reduced rate for a convenient and affordable option for U.S. Board-Certified Doctors who
				are available 24/7/365. The service is provided by Access a Doctor and allows nationwide Doctor access
				via website or mobile app. Consult with specialist via Secure Messaging (telephone and/or video) for
				common ailments that may need a prescription. This saves you the time, effort, cost and exposure of
				going to your Primary.
			</p>
		</div>
	)
}
