import { Typography } from '@components/text/text'

import { AddOnServiceList } from '../../routes/administration/user-profile/components/subscription-components/add-on-service-list'

export function LoKationResourceLuxuryMembershipForm() {
	return (
		<div className="flex flex-column overflow-y__scroll">
			<div className="flex flex-wrap">
				<div className="col-xs-12 pr-20-sm flex flex-column flex-alignItems-start mb-20">
					<Typography type="h2">
						<strong>INDEPENDENT CONTRACTOR AGREEMENT</strong>
					</Typography>
					<Typography type="h4" margins={['bottom']}>
						LOKATION LUXURY ADDENDUM
					</Typography>

					<p>
						Effective upon your acceptance of this addendum, the terms of the executed Independent
						Contractor Agreement between Broker and Agent shall be modified to include the terms of this
						Addendum as a single document. Any terms of this Addendum that conflict with terms of the
						initial Agreement, the terms in this Addendum will take precedent. The modified terms are as
						follows:
					</p>
					<ol>
						<li>
							<strong>Obligations of Associate</strong>
							<ol style={{ listStyleType: 'lower-alpha' }}>
								<li>
									Associate agrees to follow the usage restrictions as set forth by this Addendum. Any
									usage outside of explicit authorization and the terms of this agreements are grounds
									for removal from the program and no refund for payments made will be issued. This
									Addendum shall be valid for 1 year from the registration date and will be renewed
									and billed automatically until Termination is completed as referenced in this
									document. [Addendum 4(a)]
								</li>
								<li>
									<strong>Restrictions on Logo Usage.</strong> The LoKation Luxury marketing materials
									and logo may be used for branding and marketing purposes and for display when
									membership in the program is in good standing and all fees due for membership in the
									LoKation Luxury program are current. The logo and materials may only be used on
									advertising and marketing of properties $1 Million Listing Price and up. Geographic
									exceptions and special circumstances may apply, Associate will obtain explicit
									permission from an administrator of LoKation Luxury to utilize the branding outside
									of these permissible uses.
								</li>
								<li>
									<strong>Referral Program. </strong>The Lokation Luxury Referral Program is an opt-in
									program. Membership in LoKation Luxury is required and 2 transactions over the most
									recent trailing 12 months must have been completed regardless of the side of the
									transaction. Certain exceptions may apply, at the sole discretion of a LoKation
									Luxury Administrator.
								</li>
							</ol>
						</li>
						<li>
							&nbsp;<strong>Obligations of Company</strong>
							<ol style={{ listStyleType: 'lower-alpha' }}>
								<li>
									<strong>Provided Tools.</strong> Upon successful payment and signing of this
									Addendum. Broker will provide the following included with their initial registration
									payment.
									<ol style={{ listStyleType: 'lower-rown' }}>
										<li>
											Exclusive Usage of LoKation Luxury Logos in authorized variations in
											accordance with Restrictions on Logo Usage.
										</li>
										<li>Registration Links for kvCore and Luxury Home Search App</li>
										<li>Custom LoKation Luxury Social Media Platform</li>
										<li>
											Luxury Marketing Consultation for creations of:
											<ol style={{ listStyleType: 'lower-alpha' }}>
												<li>Single Page - Custom Realtor Bio</li>
												<li>Single Page - Testimonials</li>
												<li>Single Page – Marketing Process</li>
												<li>Single Page - Just Listed / Just Sold</li>
												<li>Single Page - Past Years Sales</li>
												<li>Email Signature & Banners</li>
												<li>Luxury Specific Branding and Fonts</li>
												<li>Yard Sign Templates</li>
												<li>Access to Luxury On-Demand Training and Set-Up Guides</li>
											</ol>
										</li>
										<li>Branding Workshop Consultation with Convergence Marketing</li>
										<li>Access to Training Options form Various Providers</li>
									</ol>
								</li>
								<li>
									<strong>Optional Services</strong>
									<ol style={{ listStyleType: 'lower-roman' }}>
										<li>
											Additional Custom “White Glove” Services are available through LoKation
											Luxury’s Approved Provider for discounted Print and Digital services with 6
											month commitments. These are offered for LoKation Luxury and are only
											available while an active member of the LoKation Luxury Program at
											Associate’s discretion.
										</li>
									</ol>
								</li>
							</ol>
						</li>
						<li>
							<strong>Termination</strong>
							<ol style={{ listStyleType: 'lower-alpha' }}>
								<li>
									<strong>Terms of Termination of Luxury</strong>. This Addendum may be terminated no
									later than 15 days before the anniversary of the registration date each year.
								</li>
								<li>
									<strong>Logo Usage. </strong>If Associate is no longer an Active Member of the
									Lokation Luxury Program, no usage of the branding or marketing materials from and/or
									representing LoKation Luxury is permitted for use.
								</li>
							</ol>
						</li>
					</ol>
					<p>
						By signing this addendum, you indicate you have read, understand and agree to be bound by this
						agreement. You also agree to the terms and conditions of the entire agreement with no
						modifications.
					</p>

					<AddOnServiceList
						serviceIds={['LUXURY_NONMEMBER_ANNUAL', 'LUXURY_NONMEMBER_MONTHLY']}
						disableAllIfSubscribed={true}
					/>

					<p>
						Have a Team? Add Team Members below at a fraction of the cost. They will receive the same Tech
						Stack of kvCore, Luxury Home Search App, Lokation Luxury Marketing Center and Social Media
						Platforms. This includes full use of the logos and all other Lux branding. Please note they will
						not get the custom Listing Package or Onboarding, but will receive a one-page Bio to highlight
						them as a Team Member of yours. This is required if the Team Leader is in Luxury.
					</p>

					<p>
						<strong>
							Looking to add more than one team member? Email{' '}
							<a href="mailto:info@lokationluxury.com">info@lokationluxury.com</a>
						</strong>
					</p>

					<AddOnServiceList serviceIds={['LUXURY_MEMBER_ANNUAL', 'LUXURY_MEMBER_MONTHLY']} />
				</div>
			</div>
		</div>
	)
}
