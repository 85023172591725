import { IconButton } from '@components/icon-button/icon-button'
import { Modal } from '@components/modal/modal'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { EndUserProps } from 'src/services/user/user.types'

type ComponentProps = {
	children: React.ReactNode
	title: string
	fixedHeight?: boolean
}

type ConnectedProps = {
	currentUser: EndUserProps | null
}

function InternalResourceModalPrototype(props: ComponentProps & ConnectedProps) {
	const navigate = useNavigate()

	function closeModal(): void {
		navigate(-1)
	}

	return (
		<Modal
			maxHeight={1400}
			maxWidth={1200}
			fixedHeight={typeof props.fixedHeight === 'boolean' ? props.fixedHeight : false}
			className="flex flex-column p-20"
			onClose={closeModal}
		>
			<>
				<IconButton
					icon={'xmark'}
					style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 100 }}
					onClick={closeModal}
				/>
				<div className="mb-10">
					<strong className="color__adjust-40">{props.title}</strong>
				</div>
				{props.children}
			</>
		</Modal>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const InternalResourceModal = connect(mapStateToProps)(InternalResourceModalPrototype)
