import { Button } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { Typography } from '@components/text/text'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { LicensedStatesService } from 'src/services/licensed-states/licensed-states.service'

import { FileBaseURL } from '../../services/axios/http-common.service'
import { LicensedStateEnums } from '../../services/licensed-states/licenses-states.types'
import { EndUserProps } from '../../services/user/user.types'
import Thumbnail from './policy-procedures-thumb.png'

function PolicyProceduresRoutePrototype(props: { user: EndUserProps | null }) {
	const [selectedState, setSelectedState] = useState<LicensedStateEnums | null>(null)

	useEffect(() => {
		if (props.user && props.user.licenseInformation.length > 0) {
			const firstLicensedState = props.user.licenseInformation[0].licensedState.abbreviation
			setSelectedState(firstLicensedState)
		}
	}, [])

	function getDocumentURLByState(state: LicensedStateEnums): string {
		switch (state) {
			case 'AL':
				return `${FileBaseURL}/pdf/Policy and Procedures - AL.pdf`
			case 'CO':
				return `${FileBaseURL}/pdf/Policy and Procedures - CO.pdf`
			case 'FL':
				return `${FileBaseURL}/pdf/Policy and Procedures - FL.pdf`
			case 'GA':
				return `${FileBaseURL}/pdf/Policy and Procedures - GA.pdf`
			case 'SC':
				return `${FileBaseURL}/pdf/Policy and Procedures - SC.pdf`
			case 'TX':
				return `${FileBaseURL}/pdf/Policy and Procedures - TX.pdf`
		}
	}

	return (
		<div className="flex-lg overflow-y__scroll">
			<div className="col-xs-12 col-lg-8 pr-20-sm flex flex-column flex-alignItems-start flex-justifyContent-center overflow-y__scroll">
				<Typography type="h3">Policy and Procedures Manual</Typography>
				<p>Select the manual that applies to the state you are licensed in</p>

				<div className="flex flex-column">
					<Dropdown<LicensedStateEnums>
						width={200}
						options={LicensedStatesService.getStateOptions()}
						value={selectedState ? [selectedState] : []}
						onSelect={(options) => {
							if (options && options.length > 0) {
								setSelectedState(options[0])
							}
						}}
					/>

					<Button
						onClick={() => {
							if (selectedState) {
								window.open(getDocumentURLByState(selectedState))
							}
						}}
						margins={['top']}
						variant={'outlined'}
						label={'Download Manual'}
						size={'md'}
						disabled={selectedState === null}
					/>
				</div>
			</div>
			<div className="col-xs-12 col-lg-4 p-20 flex flex-alignItems-center">
				<img src={Thumbnail} className="col-xs-12" />
			</div>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		user: state.user,
	}
}

export const PolicyProceduresRoute = connect(mapStateToProps)(PolicyProceduresRoutePrototype)
