import { Button } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { ListItem } from '@components/list-item/list-item'
import { ModalBodyStickyHeader } from '@components/modal/modal-body__sticky-header'
import { Typography } from '@components/text/text'
import { TextInput } from '@components/text-input/text-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { UserAPI } from 'src/services/user/user.api'
import { EndUserProps } from 'src/services/user/user.types'

import { OnClickOptionProps, UserSelectorModalRecipients } from '../user-selector'

interface UserExplorerProps {
	onClickOption: (props: OnClickOptionProps) => void
	selectedUsers: UserSelectorModalRecipients
}

export function UserExplorer(props: UserExplorerProps) {
	const [searchFirstName, setSearchFirstName] = useState('')
	const [searchLastName, setSearchLastName] = useState('')
	const [foundUsers, setFoundUsers] = useState<EndUserProps[] | null>(null)
	const [isSearchPending, setIsSearchPending] = useState(false)

	/** ============================= */
	/** Effects */

	/** ============================= */
	/** Methods */

	function getHasUserEnteredSearchParams(): boolean {
		if (searchFirstName) {
			return true
		}
		if (searchLastName) {
			return true
		}
		return false
	}

	function initiateUserSearch(): void {
		const hasUserEnteredSearchParams = getHasUserEnteredSearchParams()

		if (!hasUserEnteredSearchParams) {
			return
		}

		setIsSearchPending(true)
		findUsers(searchFirstName, searchLastName).then((endUsers) => {
			setFoundUsers(endUsers)
			setIsSearchPending(false)
		})
	}

	/** Makes as many requests is needed to return a list of all users matching the search criteria */
	function findUsers(firstName: string, lastName: string): Promise<EndUserProps[]> {
		const SEARCH_SIZE = 100

		/** Creates a search request and does not resolve until all pages have returned */
		function createSearchUserRequest(
			resolve: (value: EndUserProps[] | PromiseLike<EndUserProps[]>) => void,
			previousResults: EndUserProps[],
			searchPage: number,
		): void {
			UserAPI.findUsersInDomain({
				query: { first: firstName, last: lastName },
				page: searchPage,
				size: SEARCH_SIZE,
				sort: [{ property: 'lastName', direction: 'asc' }],
			}).then((res) => {
				const updatedResults = [...previousResults, ...res.data.items]
				if (res.data.items.length === SEARCH_SIZE) {
					createSearchUserRequest(resolve, updatedResults, searchPage + 1)
				} else {
					resolve(updatedResults)
				}
			})
		}

		/** Initialize the search on page 0 */
		const findUsersPromise = new Promise<EndUserProps[]>((resolve) => {
			return createSearchUserRequest(resolve, [], 0)
		})

		return findUsersPromise
	}

	/** ============================= */
	/** Render Component */

	if (isSearchPending) {
		return (
			<div className="flex flex-column  modal-body__pl modal-body__pr modal-body__pt">
				<GenericContentLoader width={'fill'} height={50} className="mb-10" />
				<GenericContentLoader width={'fill'} height={50} className="mb-10" />
				<GenericContentLoader width={'fill'} height={50} className="mb-10" />
			</div>
		)
	}

	if (foundUsers === null) {
		return (
			<div className="flex flex-column flex-alignItems-center flex-justifyContent-center flex-fillSpace">
				<FontAwesomeIcon
					icon={['fas', 'user-magnifying-glass']}
					style={{ width: '75px', height: '75px' }}
					className="mb-40 opacity-50"
				/>
				<Typography type="h5">Search for a user</Typography>
				<TextInput
					margins={['top']}
					dataType={'text'}
					placeholder="First Name"
					onChange={(updatedValue) => {
						setSearchFirstName(updatedValue)
					}}
					onEnterKeyDown={() => {
						initiateUserSearch()
					}}
					value={searchFirstName}
					width={300}
				/>
				<TextInput
					margins={['top']}
					dataType={'text'}
					placeholder="Last Name"
					onChange={(updatedValue) => {
						setSearchLastName(updatedValue)
					}}
					onEnterKeyDown={() => {
						initiateUserSearch()
					}}
					value={searchLastName}
					width={300}
				/>
				<Button
					variant="contained"
					size="lg"
					label="Search"
					style={{ width: '300px' }}
					margins={['top']}
					onClick={() => {
						initiateUserSearch()
					}}
				/>
			</div>
		)
	}

	return (
		<>
			<ModalBodyStickyHeader className="flex flex-alignItems-center flex-justifyContent-spaceBetween modal-body__pl modal-body__pr pt-10 pb-10">
				<div>
					<strong>{foundUsers?.length} results</strong>
				</div>
				<div className="flex flex-alignItems-center">
					<TextInput
						dataType={'text'}
						placeholder="First Name"
						margins={['right']}
						onChange={(updatedValue) => {
							setSearchFirstName(updatedValue)
						}}
						onEnterKeyDown={() => {
							initiateUserSearch()
						}}
						value={searchFirstName}
						width={200}
					/>
					<TextInput
						dataType={'text'}
						placeholder="Last Name"
						margins={['right']}
						onChange={(updatedValue) => {
							setSearchLastName(updatedValue)
						}}
						onEnterKeyDown={() => {
							initiateUserSearch()
						}}
						value={searchLastName}
						width={200}
					/>
					<Button
						variant="outlined"
						size="md"
						label="Search"
						onClick={() => {
							initiateUserSearch()
						}}
					/>
				</div>
			</ModalBodyStickyHeader>
			{foundUsers.length > 0 && (
				<div className="modal-body__p">
					{foundUsers.map((user) => {
						const isSelected = props.selectedUsers.endUsers.includes(user.endUserId)

						return (
							<ListItem
								key={`user__${user.endUserId}__${user.firstName}-${user.lastName}`}
								body={`${user.firstName} ${user.lastName} (${user.email})`}
								includeCheckbox={true}
								selected={isSelected}
								onClick={(newState) => {
									props.onClickOption({ category: 'user', optionId: user.endUserId, state: newState })
								}}
							/>
						)
					})}
				</div>
			)}
			{foundUsers.length === 0 && <div className="modal-body__p">No users found</div>}
		</>
	)
}
