import { PaperlessPipelineSSOCards } from './paperless-pipeline-cards'

export function PaperlessPipelineRoute() {
	return (
		<div className="flex-lg overflow-y__scroll" style={{ width: '100%' }}>
			<div className="col-xs-12 col-lg-12 pr-20-sm flex flex-wrap flex-alignItems-start flex-justifyContent-start overflow-y__scroll">
				<p>
					Access your Paperless Pipeline account quickly and securely through the Single Sign-On (SSO)
					feature. Choose your licensed state below to sign on:
				</p>
				<PaperlessPipelineSSOCards />
			</div>
		</div>
	)
}
