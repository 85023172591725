import '../luxury-branding-styles.scss'

import { Button } from '@components/button/button'
import { Typography } from '@components/text/text'

export function LuxuryBrandingFonts() {
	return (
		<div className="flex-lg overflow-y__scroll flex-justifyContent-center">
			<div className="col-xs-12 col-lg-8 pr-20-sm mb-20 flex flex-column flex-alignItems-start overflow-y__scroll flex-justifyContent-center">
				<div className="col-12 flex flex-justifyContent-center">
					<Typography type="h3">Approved Font Selection for LoKation Luxury Branding</Typography>
				</div>
				<p>
					At LoKation Luxury, we understand the importance of cohesive and elegant branding in the luxury real
					estate market. That's why we have chosen 'Fira Sans' as our official font family. This modern and
					versatile font is not only appealing to the eye but also reflects the sophistication and
					professionalism that LoKation Luxury embodies. Whether it's for email communication, marketing
					materials, or digital presentations, 'Fira Sans' ensures consistency and enhances our brand's visual
					identity. Our agents are encouraged to use 'Fira Sans' in all their professional interactions to
					maintain the high standard of LoKation Luxury's branding.
				</p>

				<div className="col-12 p-20 font-wrapper flex flex-wrap">
					<div className="col-12 col-md-6">
						<div className="col-12 flex flex-justifyContent-center">
							<Typography type="h3">Elegance in Every Style</Typography>
						</div>
						<div className="col-12 flex flex-justifyContent-center">
							<Button
								size="md"
								variant="outlined"
								label="Download Fira Sans"
								margins={['top']}
								onClick={() => {
									window.open('https://fonts.google.com/specimen/Fira+Sans', '_blank')
								}}
							/>
						</div>
					</div>
					<div className="col-12 col-md-6 font-examples">
						<div className="light">Fira Sans Light</div>
						<div className="regular">Fira Sans</div>
						<div className="condensed">Fira Sans Condensed</div>
					</div>
				</div>

				<div className="col-12 flex flex-justifyContent-center">
					<div className="flex flex-wrap mt-20"></div>
				</div>
			</div>
		</div>
	)
}
