import _ from 'lodash'
import { createContext, useContext, useReducer } from 'react'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'

import { AdEditorState } from './ad-editor__state.types'

function initialState(props: {
	originalAd: AdvertisingTypes.Advertisement
	originalAdImages: AdvertisingTypes.AdvertisementImage[]
	account: AdvertisingTypes.Account
}): AdEditorState.LocalState {
	return {
		originalAd: _.cloneDeep(props.originalAd),
		modifiedAd: _.cloneDeep(props.originalAd),
		originalAdImages: _.cloneDeep(props.originalAdImages),
		modifiedAdImages: _.cloneDeep(props.originalAdImages),
		account: props.account,
		currentTab: 'overview',
	}
}

const AdEditorContext = createContext(
	initialState({
		originalAd: {
			advertisementId: -1,
			accountId: -1,
			applicableHubs: [],
			audienceLocations: [],
			audienceSpecialties: [],
			specialtyInclusion: 'ALL',
			audienceRegions: [],
			enabled: false,
			name: '',
			endDate: '',
			startDate: '',
			hubHeaderAdvertisement: {},
			placement: 'HUB_HEADER',
		},
		originalAdImages: [],
		account: {
			accountId: 0,
			address1: '',
			address2: '',
			zip: '',
			city: '',
			contactEmail: '',
			contactFullname: '',
			contactPhone: '',
			organizationName: '',
			mailingState: '',
			stripeAccountId: '',
		},
	}),
)
const AdEditorDispatchContext = createContext({} as AdEditorState.DispatchParams)

function AdEditorReducer(state: AdEditorState.LocalState, action: AdEditorState.Action) {
	const updatedState = _.cloneDeep(state)

	switch (action.type) {
		case 'change-ad-type':
			switch (action.payload) {
				case 'HUB_HEADER':
					const hubHeaderAd: AdvertisingTypes.HubHeaderAd & AdvertisingTypes.Advertisement = {
						...updatedState.modifiedAd,
						placement: 'HUB_HEADER',
						hubHeaderAdvertisement: {},
					}
					// @ts-ignore, we have to remove this property when the ad type changes
					delete hubHeaderAd.sponsoredResourceAdvertisement
					updatedState.modifiedAd = hubHeaderAd
					return updatedState

				case 'SPONSORED_RESOURCE':
					const sponsoredResourceAd: AdvertisingTypes.SponsoredResourceAd & AdvertisingTypes.Advertisement = {
						...updatedState.modifiedAd,
						placement: 'SPONSORED_RESOURCE',
						sponsoredResourceAdvertisement: {},
					}
					// @ts-ignore, we have to remove this property when the ad type changes
					delete sponsoredResourceAd.hubHeaderAdvertisement
					updatedState.modifiedAd = sponsoredResourceAd
					return updatedState
			}
			break
		case 'update-property': {
			if (updatedState.modifiedAd.placement === 'HUB_HEADER') {
				if ('sponsoredResourceAdvertisement' in action.payload && action.payload.placement === undefined) {
					return updatedState
				}
				if (
					action.payload.placement === 'SPONSORED_RESOURCE' &&
					action.payload.sponsoredResourceAdvertisement === undefined
				) {
					console.error(`Mismatch in advertisement types`)
					return updatedState
				}
			}

			if (updatedState.modifiedAd.placement === 'SPONSORED_RESOURCE') {
				if ('hubHeaderAdvertisement' in action.payload && action.payload.placement === undefined) {
					return updatedState
				}
				if (action.payload.placement === 'HUB_HEADER' && action.payload.hubHeaderAdvertisement === undefined) {
					console.error(`Mismatch in advertisement types`)
					return updatedState
				}
			}

			updatedState.modifiedAd = {
				...updatedState.modifiedAd,
				...action.payload,
			} as AdvertisingTypes.Advertisement
			break
		}
		case 'set-tab': {
			updatedState.currentTab = action.payload
			break
		}
		case 'create-ad-image':
			updatedState.modifiedAdImages.push(action.payload)
			break
		case 'update-ad-image':
			updatedState.modifiedAdImages = updatedState.modifiedAdImages.filter(
				(adImage) => adImage.advertisementImageId !== action.payload.advertisementImageId,
			)
			updatedState.modifiedAdImages.push(action.payload)
			break
		case 'delete-ad-image':
			updatedState.modifiedAdImages = updatedState.modifiedAdImages.filter(
				(adImage) => adImage.advertisementImageId !== action.payload.advertisementImageId,
			)
			break
		case 'clear-ad-images':
			updatedState.modifiedAdImages = []
			break
	}
	return updatedState
}

export function AdEditorProvider(props: {
	advertisementToEdit: AdvertisingTypes.Advertisement
	adImagesToEdit: AdvertisingTypes.AdvertisementImage[]
	account: AdvertisingTypes.Account
	children: React.ReactNode
}) {
	const [state, dispatch] = useReducer(
		AdEditorReducer,
		initialState({
			originalAd: props.advertisementToEdit,
			originalAdImages: props.adImagesToEdit,
			account: props.account,
		}),
	)

	return (
		<AdEditorContext.Provider value={state}>
			<AdEditorDispatchContext.Provider value={dispatch}>{props.children}</AdEditorDispatchContext.Provider>
		</AdEditorContext.Provider>
	)
}

export function useAdEditor() {
	return useContext(AdEditorContext)
}

export function useAdEditorDispatch() {
	return useContext(AdEditorDispatchContext)
}
