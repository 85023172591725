import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { UserAPI } from 'src/services/user/user.api'

import { EndUserProps } from '../../../services/user/user.types'
import { UserProfileProvider } from './state/user-profile__state'
import { UserProfileEditor } from './user-profile'

export function UserProfileRoute() {
	const [data, setData] = useState<EndUserProps | null>(null)
	const params = useParams<{ userId: string | undefined }>()

	useEffect(() => {
		if (!params.userId) {
			return
		}
		UserAPI.getUserDetails(parseFloat(params.userId)).then((res) => {
			setData(res.data)
		})
	}, [])

	if (!data) {
		return <></>
	}

	return (
		<UserProfileProvider userToEdit={data}>{data && <UserProfileEditor userToEdit={data} />}</UserProfileProvider>
	)
}
