import { DropdownTypes } from '@components/dropdown/dropdown.types'

import { LicensedState, LicensedStateEnums } from './licenses-states.types'

export namespace LicensedStatesService {
	export function getStateFromId(allLocations: LicensedState[], locationId: number): LicensedState | undefined {
		return allLocations.find((state) => state.licensedStateId === locationId)
	}

	export function getStatesFromIds(allLocations: LicensedState[], locationIds: number[]): LicensedState[] {
		const foundLocations: LicensedState[] = []

		locationIds.forEach((locationId) => {
			const foundLocation = allLocations.find((state) => state.licensedStateId === locationId)
			if (foundLocation) {
				foundLocations.push(foundLocation)
			}
		})

		return foundLocations
	}

	export function getStateOptions(): DropdownTypes.Option<LicensedStateEnums>[] {
		const options: DropdownTypes.Option<LicensedStateEnums>[] = []

		options.push({
			label: 'Alabama',
			value: 'AL',
		})

		options.push({
			label: 'Colorado',
			value: 'CO',
		})

		options.push({
			label: 'Florida',
			value: 'FL',
		})

		options.push({
			label: 'Georgia',
			value: 'GA',
		})

		options.push({
			label: 'South Carolina',
			value: 'SC',
		})

		options.push({
			label: 'Texas',
			value: 'TX',
		})

		return options
	}
}
