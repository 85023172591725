import { CollapsibleContent } from '@components/collapsible-content/collapsible-content'
import { LabelValueDisplay } from '@components/label-value-display/label-value-display'
import { Paper } from '@components/paper/paper'
import { useState } from 'react'
import { LocationChip } from 'src/routes/transaction-mgmt/shared/location-chip/location-chip'
import { DateService } from 'src/services/date.service'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'

import { TransactionMgmt__GeneralInfo__SideSheet } from '../../components/general-info-side-sheet/general-info.side-sheet'
import { useTransaction, useTransactionDispatch } from '../../state/transaction__state'

export function TransactionMgmt__Transaction__Overview__General() {
	const transactionState = useTransaction()
	const transactionDispatch = useTransactionDispatch()
	const [showGeneralInfoSideSheet, setShowGeneralInfoSideSheet] = useState(false)

	return (
		<>
			<CollapsibleContent
				label="General"
				labelSize="h5"
				controlled={true}
				id="transaction__general"
				headerPadding={[]}
				buttons={[
					{
						variant: 'outlined',
						size: 'sm',
						label: 'Edit',
						onClick: () => {
							setShowGeneralInfoSideSheet(true)
						},
					},
				]}
			>
				<Paper elevate={true} bgColor="white" padding={['all']} className="col-xs-12 flex flex-wrap ">
					<div className="col-xs-12 col-sm-6">
						<LabelValueDisplay
							label="Status"
							value={TransactionManagementService.getLabelForTransactionStatus(
								transactionState.transaction.status,
							)}
						/>
						<LabelValueDisplay
							label="Location"
							value={<LocationChip locationId={transactionState.transaction.locationId} />}
						/>
						<LabelValueDisplay
							label="Type"
							value={TransactionManagementService.getLabelForTransactionType(
								transactionState.transaction.type,
							)}
						/>
						<LabelValueDisplay label="Year Built" value={transactionState.transaction.yearBuilt} />
						<LabelValueDisplay
							label="Side"
							value={TransactionManagementService.getLabelForTransactionSide(
								transactionState.transaction.side,
							)}
						/>
					</div>

					<div className="col-xs-12 col-sm-6">
						<LabelValueDisplay label="MLS Number" value={transactionState.transaction.mlsNumber} />
						<LabelValueDisplay
							label="Accepted Date"
							value={DateService.getFormattedDateFromDateObj(
								new Date(transactionState.transaction.acceptedDate),
							)}
						/>
						<LabelValueDisplay
							label="Close Date"
							value={DateService.getFormattedDateFromDateObj(
								new Date(transactionState.transaction.closeDate),
							)}
						/>
					</div>
				</Paper>
			</CollapsibleContent>

			{showGeneralInfoSideSheet && (
				<TransactionMgmt__GeneralInfo__SideSheet
					onClose={() => {
						setShowGeneralInfoSideSheet(false)
					}}
					onUpdate={(updatedTransaction) => {
						transactionDispatch({ type: 'update-transaction', payload: updatedTransaction })
					}}
					onDelete={() => {
						setShowGeneralInfoSideSheet(false)
					}}
					transaction={transactionState.transaction}
				/>
			)}
		</>
	)
}
