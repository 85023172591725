import { Button } from '@components/button/button'
import { MultiColumnTableTypes } from '@components/multi-column-table/multi-column-table.types'
import { MultiColumnTable } from '@components/multi-column-table/multi-column-table__wrapper'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { RootState } from '@redux/store'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { DateService } from 'src/services/date.service'
import { RoutingService } from 'src/services/routing/routing.service'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { EndUserProps } from '../../../services/user/user.types'
import { BackToHomeButton } from '../../administration/components/back-to-home-button'
import { Transaction__CreateTransactionModal } from '../shared/create-transaction/create-transaction-modal'
import { TransactionMgmtRouting } from '../transaction-mgmt.routing'
import { TransactionMgmt__Landing__Section__DueTasks } from './landing__section__due-tasks'
import { TransactionMgmt__Landing__Section__RecentlyViewed } from './landing__section__recently-viewed'
import { TransactionMgmt__Landing__Section__UnreviewedDocs } from './landing__section__unreviewed-docs'

function TransactionMgmt__Landing__RoutePrototype(props: { userProfile: EndUserProps | null }) {
	const navigate = useNavigate()

	const [allTransactions, setAllTransactions] = useState<TransactionMgmtTypes.TransactionTypes.Transaction[] | null>(
		null,
	)
	const [showCreateTransactionModal, setShowCreateTransactionModal] = useState(false)

	useEffect(() => {
		TransactionManagementAPI.findTransactions().then((res) => {
			setAllTransactions(res.data)
		})
	}, [])

	function getTransactionTableColumns(): MultiColumnTableTypes.Column<TransactionMgmtTypes.TransactionTypes.Transaction>[] {
		const columns: MultiColumnTableTypes.Column<TransactionMgmtTypes.TransactionTypes.Transaction>[] = []

		columns.push({
			label: 'Name',
			columnId: 'name',
			value: 'name',
			displayIndex: 0,
			visible: true,
			width: 'fill',
		})

		columns.push({
			label: 'Close Date',
			columnId: 'closeDate',
			value: (transaction) => {
				return DateService.getFormattedDateFromDateObj(new Date(transaction.closeDate))
			},
			displayIndex: 4,
			visible: true,
			align: 'left',
			width: 200,
		})

		return columns
	}

	return (
		<>
			<RouteWrapperV2>
				<RouteBodyV2 className="flex-column">
					<BackToHomeButton />
					<div className="flex flex-justifyContent-spaceBetween flex-alignItems-start">
						<Typography type="h1" margins={['bottom']}>
							Transaction Management
						</Typography>
						<Button
							size="md"
							label="Add new Transaction"
							icon="file"
							iconPosition="left"
							className="mr-10"
							variant="contained"
							onClick={() => {
								setShowCreateTransactionModal(true)
							}}
						/>
					</div>
					<div className="flex flex-wrap mb-20">
						{TransactionMgmtRouting.getRouteProperties().map((route) => {
							if (RoutingService.getIsRouteVisible(route)) {
								return (
									<Button
										size="md"
										label={route.label}
										icon={route.icon}
										iconPosition="left"
										className="mr-10"
										variant="contained"
										onClick={() => {
											const href = typeof route.href === 'function' ? route.href() : route.href
											navigate(href)
										}}
										key={route.label}
									/>
								)
							}
							return <React.Fragment key={route.label}></React.Fragment>
						})}
					</div>
					<div className="flex">
						<div className="col-xs-12 col-md-7 pr-20">
							<Typography type="h6">Transactions closing this week</Typography>
							<MultiColumnTable<TransactionMgmtTypes.TransactionTypes.Transaction>
								columns={getTransactionTableColumns()}
								idKey={'transactionId'}
								className="col-xs-12"
								items={allTransactions}
								isConfigurable={false}
								onSelect={(transaction) => {
									navigate(
										`${RoutingService.transactionMgmtRoutePath}/transaction/${transaction[0].transactionId}`,
									)
								}}
								selectBehavior={'single'}
								selectedItems={[]}
								sortBehavior={{
									sortMethod: 'column-value',
									columnId: 'name',
								}}
								tableId={''}
							/>
							<Button
								variant="text"
								label={'View All Transactions'}
								size="md"
								className="mt-10"
								onClick={() => {
									navigate(`${RoutingService.transactionMgmtRoutePath}/transactions`)
								}}
							/>
						</div>
						<div className="col-xs-12 col-md-5">
							<TransactionMgmt__Landing__Section__DueTasks />
							<TransactionMgmt__Landing__Section__UnreviewedDocs />
							<TransactionMgmt__Landing__Section__RecentlyViewed />
						</div>
					</div>
				</RouteBodyV2>
			</RouteWrapperV2>
			{showCreateTransactionModal && (
				<Transaction__CreateTransactionModal
					onCancel={() => {
						setShowCreateTransactionModal(false)
					}}
					onCreate={(transaction: TransactionMgmtTypes.TransactionTypes.Transaction) => {
						setShowCreateTransactionModal(false)
						navigate(`${RoutingService.transactionMgmtRoutePath}/transaction/${transaction.transactionId}`)
					}}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
	}
}

export const TransactionMgmt__Landing__Route = connect(mapStateToProps)(TransactionMgmt__Landing__RoutePrototype)
