/* eslint-disable react/jsx-pascal-case */
import { Button } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownTypes } from '@components/dropdown/dropdown.types'
import { ReferralSearchAutocomplete } from '@components/referral-search-autocomplete/referral-search-autocomplete'
import { SideSheet } from '@components/side-sheet/side-sheet'
import { SideSheetServices } from '@components/side-sheet/side-sheet.service'
import { SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import { SideSheet__Row } from '@components/side-sheet/side-sheet__row'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { TextInput } from '@components/text-input/text-input'
import { RootState } from '@redux/store'
import _ from 'lodash'
import { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { GenericDeleteConfirmationModal } from 'src/modals/generic-delete-confirmation/generic-delete-confirmation'
import { LicensedState } from 'src/services/licensed-states/licenses-states.types'
import { LicensedRegionsService } from 'src/services/regions/regions.service'
import { LicensedRegion } from 'src/services/regions/regions.types'
import { RoutingService } from 'src/services/routing/routing.service'
import { TeamsAPI } from 'src/services/teams/teams.api'
import { TeamTypes } from 'src/services/teams/teams.types'
import { EndUserFirstLast } from 'src/services/user/user.types'

interface TeamsSideSheetProps {
	team: TeamTypes.Team | 'NEW'
	onClose: () => void
	onDelete: () => void
	onUpdate: (updatedAccount: TeamTypes.Team) => void
	licensedStates: LicensedState[]
}

export function TeamsSideSheetPrototype(props: TeamsSideSheetProps) {
	const navigate = useNavigate()
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
	const [modifiedState, setModifiedState] = useState(getInitialState())
	const didUserModifyProps = !_.isEqual(props.team, modifiedState)
	const isAccountValid = getIsTeamValid()
	const [referralSearchValue, setReferralSearchValue] = useState<string>('')
	const [listVisible, setListVisible] = useState(true)
	const licensedState = getStateForLicense()
	const regionsUnderSelectedState = licensedState
		? LicensedRegionsService.getRegionsOfState(licensedState.licensedStateId)
		: []

	function getStateForLicense(): LicensedState | null {
		return modifiedState.licensedState
		//return props.licensedStates.find((licensedState) => licensedState.licensedStateId === props.license.licensedStateId)
	}

	function getInitialState(): TeamTypes.Team {
		if (props.team === 'NEW') {
			const defaultTeam: TeamTypes.Team = {
				name: '',
				teamLeaderEndUserId: -1,
				realEstateTeamId: -1,
				teamLeaderName: '',
				licensedState: null,
				region: null,
			}
			return defaultTeam
		}
		return props.team
	}

	function createFooter(): React.ReactNode {
		return (
			<>
				<Button
					variant={'outlined'}
					label={'Cancel'}
					size={'md'}
					margins={['right']}
					onClick={() => {
						props.onClose()
					}}
				/>
				<Button
					variant={'contained'}
					label={'Save and Close'}
					disabled={!isAccountValid}
					size={'md'}
					onClick={() => {
						handleSaveAccount().then(() => {
							props.onClose()
							props.onUpdate(modifiedState)
						})
					}}
				/>
			</>
		)
	}

	function createActions(): SideSheetTypes.Action[] {
		const actions: SideSheetTypes.Action[] = []

		if (props.team !== 'NEW') {
			actions.push({
				label: `Delete Team`,
				icon: 'trash',
				onClick: () => {
					setShowDeleteConfirmationModal(true)
				},
			})
		}

		return actions
	}

	function onScrimClick(): void {
		if (!didUserModifyProps) {
			props.onClose()
			return
		}

		SideSheetServices.showDismissConfirmationToast({
			onDismiss: () => {
				return new Promise((resolve) => {
					resolve()
					props.onClose()
				})
			},
			onSaveAndClose: () => {
				return handleSaveAccount().then(() => {
					props.onClose()
					props.onUpdate(modifiedState)
				})
			},
		})
	}

	function handleSaveAccount(): Promise<void> {
		return new Promise((resolve) => {
			if (modifiedState.realEstateTeamId < 0) {
				TeamsAPI.createTeam(modifiedState).then(() => {
					resolve()
				})
			} else {
				TeamsAPI.updateTeam(modifiedState.realEstateTeamId, modifiedState).then(() => {
					resolve()
				})
			}
		})
	}

	function updateProperties(changes: Partial<TeamTypes.Team>): void {
		const updatedProps: TeamTypes.Team = { ...modifiedState, ...changes }
		setModifiedState(updatedProps)
	}

	function getIsTeamValid(): boolean {
		if (modifiedState.name.length === 0) {
			return false
		}

		return true
	}

	const handleUserSelected = (user: EndUserFirstLast) => {
		setListVisible(false)
		if (user && user.endUserId > 0) {
			updateProperties({ teamLeaderEndUserId: user.endUserId })
			setReferralSearchValue(user.firstName + ' ' + user.lastName)
		}
	}

	function getLicensedStateOptions(): DropdownTypes.Option<LicensedState>[] {
		return props.licensedStates.map((state) => {
			return {
				value: state,
				label: state.stateName,
			}
		})
	}

	function getRegionOptions(): DropdownTypes.Option<LicensedRegion>[] {
		return regionsUnderSelectedState.map((region) => {
			return {
				value: region,
				label: region.regionName,
			}
		})
	}

	return (
		<>
			<SideSheet
				title={'Team'}
				actions={createActions()}
				onClose={props.onClose}
				persistent={false}
				footer={createFooter()}
				preventDefaultScrimClick={didUserModifyProps}
				onScrimClick={onScrimClick}
			>
				<SideSheet__Section label="General" mode="expandable" sectionKey="account__general">
					<SideSheet__Row direction="column" label="Name">
						<TextInput
							type="text"
							dataType="text"
							value={modifiedState.name}
							width={'100%'}
							onChange={(updatedValue) => {
								updateProperties({ name: updatedValue })
							}}
						/>
					</SideSheet__Row>
					<SideSheet__Row direction="column" label="">
						<div className="col-xs-12">
							<TextInput
								width={`100%`}
								dataType={'text'}
								label={'Team Leader'}
								value={referralSearchValue}
								onChange={(updatedValue) => {
									setListVisible(true)
									setReferralSearchValue(updatedValue)
								}}
							/>

							{listVisible && (
								<ReferralSearchAutocomplete
									onUserSelected={handleUserSelected}
									searchValue={referralSearchValue}
									endUserId={modifiedState.teamLeaderEndUserId}
								/>
							)}
						</div>
					</SideSheet__Row>

					<SideSheet__Row direction="column" label="">
						<div className="col-xs-12">
							<Dropdown<LicensedState>
								label="Licensed State"
								width={'100%'}
								value={licensedState ? [licensedState] : []}
								options={getLicensedStateOptions()}
								onSelect={(selectedValues) => {
									updateProperties({ licensedState: selectedValues[0] })
								}}
							/>
						</div>
					</SideSheet__Row>

					<SideSheet__Row direction="column" label="">
						<div className="col-xs-12">
							{regionsUnderSelectedState.length > 0 && (
								<Dropdown<LicensedRegion>
									label="Region"
									width={'100%'}
									value={modifiedState.region ? [modifiedState.region] : []}
									options={getRegionOptions()}
									onSelect={(selectedValues) => {
										updateProperties({ region: selectedValues[0] })
									}}
								/>
							)}
						</div>
					</SideSheet__Row>
				</SideSheet__Section>

				<Button
					className="col-xs-12"
					variant={'contained'}
					label={'Open Team'}
					disabled={modifiedState.realEstateTeamId < 0}
					size={'lg'}
					onClick={() => {
						navigate(`${RoutingService.settingsRoutePath}/team/${modifiedState.realEstateTeamId}`)
					}}
				/>
			</SideSheet>
			{showDeleteConfirmationModal && (
				<GenericDeleteConfirmationModal
					itemLabel={modifiedState.name}
					onDelete={async () => {
						await TeamsAPI.deleteTeam(modifiedState)
						setShowDeleteConfirmationModal(false)
						props.onDelete()
						props.onClose()
					}}
					onClose={() => {
						setShowDeleteConfirmationModal(false)
					}}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		licensedStates: state.licensedStates,
	}
}

export const TeamsSideSheet = connect(mapStateToProps)(TeamsSideSheetPrototype)
