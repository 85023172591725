import { Button } from '@components/button/button'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import { EndUserProps } from '../../services/user/user.types'
import RSPInfo from './rsp_img.png'

interface RealEstateLeadsPrototypeProps {
	currentUser: EndUserProps | null
}

function RealEstateLeadsPrototype({ currentUser }: RealEstateLeadsPrototypeProps) {
	const [externalLinkForSso, setExternalLinkForSso] = useState('')

	// useEffect(() => {
	// 	if (currentUser) {
	// 		setExternalLinkForSso(`https://www.rspusa.com/index.php?FID=FL06_9536&AgencyID=${currentUser.endUserId}`)
	// 	}
	// }, [currentUser])

	function navigateToRsp() {
		if (currentUser) {
			window.open(`https://www.rspusa.com/index.php?FID=FL06_9536&AgencyID=${currentUser.endUserId}`, '_blank')
			setExternalLinkForSso(`https://www.rspusa.com/index.php?FID=FL06_9536&AgencyID=${currentUser.endUserId}`)
		}
	}

	useEffect(() => {
		if (externalLinkForSso) {
			navigateToRsp()
			setTimeout(() => {
				setExternalLinkForSso('')
			}, 3000)
		}
	}, [externalLinkForSso])

	return (
		<div className="overflow-y__scroll">
			<div className="col-12 flex flex-justifyContent-center mb-20">
				<Typography type="h1">Direct Mail Marketing ... Done For You!</Typography>
			</div>
			<div className="col-12 flex flex-justifyContent-center mb-20">
				<div className="col-12 col-md-8">
					<img src={RSPInfo} width={'100%'} />
				</div>
			</div>
			<Paper bgColor="primary" padding={['all']} marginSize="section" margins={['bottom']} elevate={false}>
				<div className="col-12 flex flex-wrap mb-20">
					<div className="col-12 col-md-3 pl-10-md pr-10-md pr-0-md-down pl-0-md-down">
						<Typography type="h4">Personalized Service</Typography>
						<p>
							From helping you target the right homeowners to providing solutions for proofreading your
							property descriptions and choosing the best photos, we're there for you like you're there
							for your clients.
						</p>
					</div>
					<div className="col-12 col-md-3 pl-10-md pr-10-md pr-0-md-down pl-0-md-down">
						<Typography type="h4">Data-Driven Marketing</Typography>
						<p>
							RSP leverages the most recent and relevant data to make getting your name and face in front
							of the right people in the right place at the right time as easy as sticking a pin in a map.
						</p>
					</div>
					<div className="col-12 col-md-3 pl-10-md pr-10-md pr-0-md-down pl-0-md-down">
						<Typography type="h4">Smart Targeted Mailing</Typography>
						<p>
							Our smart targeted mailing lists—based on specific local neighborhoods with similar
							profiles—filter out the fluff to reveal the things that matter most for getting hot leads.
						</p>
					</div>
					<div className="col-12 col-md-3 pl-10-md pr-10-md pr-0-md-down pl-0-md-down">
						<Typography type="h4">The Agent is The Star</Typography>
						<p>
							By helping you consistently provide valuable information to homeowners, we build on your
							brand, enhance your reputation, and make you the local go-to real estate expert.
						</p>
					</div>
				</div>
				<div className="col-12 flex flex-justifyContent-center mb-20">
					<Typography type="h3">Transform Your Marketing Today</Typography>
				</div>
				<div className="col-12 flex flex-justifyContent-center">
					<Button
						variant="contained"
						size="lg"
						primary={true}
						label={'Access Your Tools'}
						onClick={navigateToRsp}
					/>
				</div>
			</Paper>

			{externalLinkForSso &&
				ReactDOM.createPortal(
					<div className="modal__scrim">
						<div className="external-site-msg__wrapper">
							<FontAwesomeIcon icon={['far', 'globe']} size={`5x`} className={`mb-20 opacity-50`} />
							<Typography type="h5">Loading External Website</Typography>
							<div className="mt-10">
								If you are not redirected within 5 seconds,{' '}
								<a href={externalLinkForSso} target="_blank" rel="noopener noreferrer">
									click here
								</a>
								.
							</div>
						</div>
					</div>,
					document.body,
				)}
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const RealEstateLeadsRoute = connect(mapStateToProps)(RealEstateLeadsPrototype)
