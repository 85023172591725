import { Button } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { tagsSlice } from '@redux/reducers/tags-reducer'
import { UIState } from '@redux/reducers/ui-reducer'
import { RootState, store } from '@redux/store'
import { AxiosResponse } from 'axios'
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'

import { EditTagRoute } from '../../../modals/admin-editors/edit-tag/edit-tag'
import useAdminRedirect from '../../../services/hooks/user-admin-redirect'
import { TagAPI } from '../../../services/tags/tag.api'
import { LokationTag } from '../../../services/tags/tag.types'
import { EndUserProps } from '../../../services/user/user.types'
import { GetItemsResponse, SortParam } from '../../../services/utility.types'
import { useItemAdminHook } from '../use-item-admin-hook'
import { TagCard } from './tag__card/tag__card'

type ConnectedProps = {
	userProfile: EndUserProps | null
	tags: LokationTag[]
	ui: UIState
}

function TagAdministrationRoute__Prototype(props: ConnectedProps) {
	const navigate = useNavigate()
	const [selectedTag, updateSelectedTag] = useState<LokationTag | 'NEW' | null>(null)
	const [isPending, setIsPending] = useState(true)
	useAdminRedirect(props.userProfile)

	function getTags(
		page: number,
		size: number,
		sort: SortParam<LokationTag>[],
	): Promise<AxiosResponse<GetItemsResponse<LokationTag>>> {
		const gatTagsPromise = TagAPI.getAllTags({ page, size, sort })
		gatTagsPromise.then((res) => {
			store.dispatch(tagsSlice.actions.setTags(res.data.items))
		})
		return gatTagsPromise
	}

	const tagAdminHook = useItemAdminHook<LokationTag, null>({
		getItemsFunction: getTags,
		searchParams: null,
		itemIdKey: 'tagId',
		itemTitleKey: 'label',
		pageSize: 100,
		setIsPending: (state) => {
			setIsPending(state)
		},
	})

	return (
		<>
			<RouteWrapperV2>
				<RouteSubnavigation
					title={`Tag Administration`}
					backButton={{
						label: 'Administration',
						onClick: () => {
							navigate('/administration')
						},
					}}
					isCustom={true}
				>
					<></>
				</RouteSubnavigation>

				<RouteBodyV2 id="tag-admin__route-body">
					<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween mb-10">
						<Typography type="h1">Tag Administration</Typography>
						<Button
							variant="outlined"
							label="Create Tag"
							size={'md'}
							onClick={() => {
								updateSelectedTag('NEW')
							}}
						/>
					</div>
					<div className="mb-20 opacity-50">{tagAdminHook.totalElements} Results</div>
					{!isPending && !tagAdminHook.hasMoreItems && tagAdminHook.items.length === 0 && (
						<div className="flex flex-column flex-fillSpace flex-justifyContent-center flex-alignItems-center">
							<FontAwesomeIcon icon={['far', 'tag']} size="4x" className="mb-20" />
							<Typography type="h3">We cannot find any tags matching your criteria</Typography>
						</div>
					)}

					{tagAdminHook.items && (
						<InfiniteScroll
							dataLength={tagAdminHook.items.length}
							next={tagAdminHook.loadNextPageOfItems}
							hasMore={tagAdminHook.hasMoreItems}
							scrollableTarget={props.ui.viewMode === 'FULL' ? `tag-admin__route-body` : undefined}
							loader={
								<>
									<GenericContentLoader width={'fill'} height={70} className="mb-10" />
									<GenericContentLoader width={'fill'} height={70} className="mb-10" />
									<GenericContentLoader width={'fill'} height={70} className="mb-10" />
								</>
							}
						>
							{tagAdminHook.items.map((tag) => {
								return (
									<TagCard
										{...tag}
										key={tag.tagId}
										onClick={() => {
											updateSelectedTag(tag)
										}}
									/>
								)
							})}
						</InfiniteScroll>
					)}
				</RouteBodyV2>
			</RouteWrapperV2>

			{selectedTag === 'NEW' && (
				<EditTagRoute
					dismissModal={() => {
						updateSelectedTag(null)
					}}
					onCreate={tagAdminHook.handleCreateItem}
				/>
			)}
			{selectedTag && selectedTag !== 'NEW' && (
				<EditTagRoute
					item={selectedTag}
					dismissModal={() => {
						updateSelectedTag(null)
					}}
					onUpdate={tagAdminHook.handleUpdateItem}
					onDelete={tagAdminHook.handleDeleteItem}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
		tags: state.tags,
		ui: state.ui,
	}
}

export const TagAdministrationRoute = connect(mapStateToProps)(TagAdministrationRoute__Prototype)
