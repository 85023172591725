import React from 'react'

import { MultiColumnCardTypes } from './multi-column-card.types'

/** A single column within a multi-column card */
export function MultiColumnCard__Column(props: MultiColumnCardTypes.ColumnComponent) {
	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`multi-column-table__column flex flex-alignItems-center`)

		if (props.className) {
			classes.push(props.className)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	function getStyle(): React.CSSProperties {
		const style: React.CSSProperties = {}

		if (props.width === 'fill') {
			style.flexGrow = '1'
		} else {
			style.width = `${props.width}px`
			style.flexShrink = '0'
		}

		switch (props.align) {
			case 'left':
				style.textAlign = 'left'
				style.justifyContent = 'start'
				break
			case 'center':
				style.textAlign = 'center'
				style.justifyContent = 'center'
				break
			case 'right':
				style.textAlign = 'right'
				style.justifyContent = 'end'
				break
		}

		return style
	}

	return (
		<div style={getStyle()} className={getClass()}>
			{props.children}
		</div>
	)
}
