import { ToastService } from '../toast/toast.service'
import { ValidationResult } from './validation.types'

export class ValidationService {
	public static showValidationErrors(result: ValidationResult): void {
		ToastService.create({
			type: 'ERROR',
			body: (
				<>
					{result.messages.map((message) => {
						return <div>{message}</div>
					})}
				</>
			),
		})
	}
}
