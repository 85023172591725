import { Button } from '@components/button/button'
import { RootState } from '@redux/store'
import { useEffect, useRef, useState } from 'react'
import { QRCode } from 'react-qrcode-logo'
import { connect } from 'react-redux'
import { ContractTemplateAddition } from 'src/routes/registration/routes/contracts/contracts.service'
import { FileBaseURL } from 'src/services/axios/http-common.service'
import { PDFGenerationService } from 'src/services/pdf-generation/pdf-generation.service'
import { DomainTheme } from 'src/services/theme/theme.types'
import { ToastService } from 'src/services/toast/toast.service'
import { EndUserProps } from 'src/services/user/user.types'

import QRLogo from './../../modals/qr-quick-add/lokation-qr-logo.png'
import QRTemplateImg from './LoKation_Agent_Shareable_Template.jpg'

interface QRCodePDFProps {
	currentUser: EndUserProps | null
	theme: DomainTheme.CompleteTheme
}

function QRCodePDFPrototype({ currentUser, theme }: QRCodePDFProps) {
	const canvasRef = useRef<HTMLCanvasElement | null>(null)
	const [qrCodeBlob, setQrCodeBlob] = useState<null | Blob>(null)

	const generateQRCodeBlob = () => {
		const qrCodeCanvas = document.getElementById('qrCodeCanvas') as HTMLCanvasElement
		if (qrCodeCanvas) {
			qrCodeCanvas.toBlob((blob) => {
				setQrCodeBlob(blob)
			})
		}
	}

	useEffect(() => {
		if (currentUser) {
			generateQRCodeBlob()
		}
	}, [currentUser])

	function generatePDF(): void {
		if (!qrCodeBlob) {
			ToastService.create({
				type: 'ERROR',
				body: `QR Code could not be generated`,
			})
			return
		}

		const basePDF = `${FileBaseURL}/pdf/LoKation_Agent_Shareable_Template.pdf`
		const additions: ContractTemplateAddition[] = [
			{
				page: 0,
				image: qrCodeBlob,
				options: {
					x: 33,
					y: 673,
					width: 120,
					height: 120,
				},
			},
		]

		PDFGenerationService()
			.modifyPDF(basePDF, additions)
			.then((uintArray) => {
				const filename = `${currentUser?.firstName}_${currentUser?.lastName}_Referral.pdf`
				PDFGenerationService().downloadBlob(uintArray, filename, `application/octet-stream`)
			})
			.catch((err) => {
				ToastService.create({
					type: 'ERROR',
					body: `Document could not be generated`,
				})
			})
	}

	return (
		<div className="flex-lg overflow-y__scroll" style={{ width: '100%' }}>
			<div className="col-xs-12 col-lg-12 pr-20-sm flex flex-column flex-alignItems-center">
				<p>Generate a PDF template with your personal QR code to send to your fellow agents!</p>
				<div style={{ maxWidth: '500px' }}>
					<img src={QRTemplateImg} width={'100%'} />
				</div>
				<div style={{ display: 'none' }}>
					{currentUser && (
						<>
							<QRCode
								id="qrCodeCanvas"
								size={1000}
								style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
								value={`https://joinlokation.com/referral/?fname=${currentUser.firstName}&lname=${currentUser.lastName}&referral=${currentUser.endUserId}`}
								logoPaddingStyle="square"
								logoPadding={10}
								logoHeight={200}
								logoWidth={200}
								fgColor={theme.appHeaderBgColor}
								removeQrCodeBehindLogo={true}
								logoImage={QRLogo}
							/>
							<canvas ref={canvasRef} style={{ display: 'none' }} />
						</>
					)}
				</div>
				<Button
					variant="contained"
					label="Generate PDF with QR Code"
					size={'md'}
					onClick={generatePDF}
					margins={['top']}
					style={{ width: `200px` }}
				/>
				<canvas ref={canvasRef} style={{ display: 'none' }} />
			</div>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
		theme: state.theme,
	}
}

export const QRCodePDFRoute = connect(mapStateToProps)(QRCodePDFPrototype)
