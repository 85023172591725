import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { useState } from 'react'
import { useLocation } from 'react-router'

import { LicensedStateEnums } from '../../services/licensed-states/licenses-states.types'
import { SupportRouteAlabama } from './support__al'
import { SupportRouteColorado } from './support__co'
import { SupportRouteFlorida } from './support__fl'
import { SupportRouteGeorgia } from './support__ga'
import { SupportRouteSouthCarolina } from './support__sc'
import { SupportRouteTexas } from './support__tx'

export function SupportRoute() {
	const location = useLocation()
	const initialState = location.state?.selectedState ?? 'FL'
	const [selectedState, setSelectedState] = useState<LicensedStateEnums>(initialState)

	return (
		<RouteWrapperV2>
			<RouteSubnavigation
				title={`Support`}
				isCustom={false}
				routes={[
					{
						label: 'Florida',
						onClick: () => {
							setSelectedState('FL')
						},
						isActive: selectedState === 'FL',
						id: 'FL',
					},
					{
						label: 'Colorado',
						onClick: () => {
							setSelectedState('CO')
						},
						isActive: selectedState === 'CO',
						id: 'CO',
					},
					{
						label: 'Georgia',
						onClick: () => {
							setSelectedState('GA')
						},
						isActive: selectedState === 'GA',
						id: 'GA',
					},
					{
						label: 'Texas',
						onClick: () => {
							setSelectedState('TX')
						},
						isActive: selectedState === 'TX',
						id: 'TX',
					},
					{
						label: 'Alabama',
						onClick: () => {
							setSelectedState('AL')
						},
						isActive: selectedState === 'AL',
						id: 'AL',
					},
					{
						label: 'South Carolina',
						onClick: () => {
							setSelectedState('SC')
						},
						isActive: selectedState === 'SC',
						id: 'SC',
					},
				]}
			/>

			<RouteBodyV2>
				<Typography type="h1" margins={['bottom']}>
					Support
				</Typography>

				{selectedState === 'FL' && <SupportRouteFlorida />}
				{selectedState === 'CO' && <SupportRouteColorado />}
				{selectedState === 'GA' && <SupportRouteGeorgia />}
				{selectedState === 'TX' && <SupportRouteTexas />}
				{selectedState === 'AL' && <SupportRouteAlabama />}
				{selectedState === 'SC' && <SupportRouteSouthCarolina />}
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}
