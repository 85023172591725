import { AgentLicenseCard } from '@components/agent-license-card/agent-license-card'
import { Typography } from '@components/text/text'
import { useAppDispatch } from '@redux/hooks'
import { userSlice } from '@redux/reducers/user-reducer'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { ToastService } from 'src/services/toast/toast.service'
import { UserAPI } from 'src/services/user/user.api'

import { Button } from '../../../../components/button/button'
import { Paper } from '../../../../components/paper/paper'
import { UserService } from '../../../../services/user/user.service'
import { AgentLicense, EndUserProps } from '../../../../services/user/user.types'
import { useUserProfile, useUserProfileDispatch } from '../state/user-profile__state'
import { AddNewAgentLicenseModal } from './add-new-license/add-new-agent-license'

interface AgentLicensesProps {
	userToEdit: EndUserProps
	currentUser: EndUserProps | null
	rejoin?: boolean
}

function AgentLicenses({ rejoin = false, ...props }: AgentLicensesProps) {
	const userProfileState = useUserProfile()
	const userProfileDispatch = useUserProfileDispatch()
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const canUserEdit = props.currentUser ? UserService.isUserAdmin(props.currentUser) : false
	const [addLicenseModal, setAddLicenseModal] = useState<'ADD' | 'UPDATE' | null>(null)
	const [licenseToEdit, setLicenseToEdit] = useState<number | null>(null)
	const [modifiedUserState, setModifiedUserState] = useState<AgentLicense.CompleteEntry[]>([])

	/** ==================================== */
	/** Effects */

	useEffect(() => {
		if (!_.isEqual(props.userToEdit.licenseInformation, modifiedUserState)) {
			setModifiedUserState(props.userToEdit.licenseInformation)
		}
	}, [props.userToEdit.licenseInformation])

	/** ==================================== */
	/** Methods */

	function dismissModal() {
		setAddLicenseModal(null)
	}

	function sortLicenses(): AgentLicense.Entry[] {
		return _.cloneDeep(userProfileState).agentLicenses.sort((a, b) => {
			if (!a.licenseInformationId || !b.licenseInformationId) {
				return -1
			}

			return a.licenseInformationId > b.licenseInformationId ? 1 : -1
		})
	}

	async function updateUserState() {
		const res = await UserAPI.getUserDetails(props.userToEdit.endUserId)
		await dispatch(userSlice.actions.setUserProps(res.data))
		if (rejoin) {
			navigate('/hubs')
		}
	}

	/** ==================================== */
	/** Render Component */

	return (
		<>
			<Typography type="h2" margins={['bottom']}>
				Agent Licenses
			</Typography>

			<div className="flex flex-wrap mt-10">
				{userProfileState.agentLicenses.length === 0 && (
					<div className="mt-10" style={{ width: `100%` }}>
						<em>No licenses have been added</em>
					</div>
				)}
				{userProfileState.agentLicenses.length > 0 && (
					<>
						{sortLicenses().map((license) => {
							return (
								<Paper
									bgColor="primary"
									padding={['all']}
									marginSize="section"
									margins={['bottom']}
									className="col-xs-12"
									style={{ maxWidth: `800px` }}
								>
									<AgentLicenseCard
										key={license.licenseInformationId}
										license={license}
										canUserEdit={canUserEdit}
										userToEdit={props.userToEdit}
										onUpdateLicense={(license) => {
											userProfileDispatch({
												type: 'update-license',
												payload: { licenseId: license.licenseInformationId, license },
											})
										}}
										onDeleteLicense={() => {
											userProfileDispatch({
												type: 'remove-license',
												payload: license.licenseInformationId,
											})
										}}
										userEditPlan={(license: AgentLicense.CompleteEntry) => {
											setLicenseToEdit(license.licenseInformationId)
											setAddLicenseModal('UPDATE')
										}}
										rejoin={rejoin}
									/>
								</Paper>
							)
						})}
					</>
				)}
			</div>

			{/* <Button icon={'plus'} size="sm" variant={'outlined'} disabled={!canUserEdit} label="Add Additional License" className='mt-10' onClick={() => {
                    userProfileDispatch({ type: 'add-license', payload: null })
                }} /> */}

			{!rejoin && (
				<Button
					icon={'plus'}
					size="md"
					variant={'outlined'}
					label={userProfileState.agentLicenses.length > 0 ? 'Add additional license' : 'Add license'}
					margins={['top', 'bottom']}
					onClick={() => {
						setAddLicenseModal('ADD')
					}}
				/>
			)}

			{addLicenseModal === 'ADD' && (
				<>
					<AddNewAgentLicenseModal
						dismissModal={dismissModal}
						canUserEdit={canUserEdit}
						userToEdit={props.userToEdit}
						currentUser={props.currentUser}
						existingLicenses={userProfileState.agentLicenses}
						onAddLicense={(license) => {
							userProfileDispatch({ type: 'add-license', payload: license })
							ToastService.create({
								type: 'SUCCESS',
								body: `New license has been added`,
							})
							updateUserState()
							// setAddLicenseModal(null)
						}}
						onUpdateLicense={(license) => {
							/** EMPTY */
							// setAddLicenseModal(null)
						}}
						rejoin={rejoin}
					/>
				</>
			)}

			{addLicenseModal === 'UPDATE' && licenseToEdit && (
				<>
					<AddNewAgentLicenseModal
						dismissModal={dismissModal}
						canUserEdit={canUserEdit}
						userToEdit={props.userToEdit}
						currentUser={props.currentUser}
						existingLicenses={userProfileState.agentLicenses}
						onAddLicense={(license) => {
							userProfileDispatch({ type: 'add-license', payload: license })
							ToastService.create({
								type: 'SUCCESS',
								body: `Your license has been updated`,
							})
							updateUserState()
							// setAddLicenseModal(null)
						}}
						onUpdateLicense={(license) => {
							userProfileDispatch({ type: 'add-license', payload: license })
							ToastService.create({
								type: 'SUCCESS',
								body: `Your license has been updated`,
							})
							updateUserState()
							// setAddLicenseModal(null)
						}}
						licenseToEdit={licenseToEdit}
						rejoin={rejoin}
					/>
				</>
			)}
		</>
	)
}

export default AgentLicenses
